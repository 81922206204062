import React, { Component } from "react";
import {
  Select,
  FormControl,
  MenuItem,
  Button,
  Checkbox,
  Typography,
  InputLabel,
  TextField,
  FormHelperText,
  Backdrop,
  CircularProgress,
  Tooltip,
  Chip,
  Autocomplete,
  FormGroup,
  FormControlLabel,
  Stack,
  DialogContent,
  DialogTitle,
  Dialog,
  IconButton,
  Badge,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { parseISO } from "date-fns";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchPreviousValues,
  getDropdownOptions,
  getIdentifiersDynamicValues,
  rfqSubmit,
  getFileSpecifications,
  getTermsAndConditions,
  updateFileSpecifications,
  sendMailUri,
  prRfqSubmit,
  updateFileSpecificationswithmultiplefiles,
  pr_status_integration,
  modify_edit_qcs_values,
  postFileSpecifications,
  devTemplateURi,
  templateURi,
  vendor_QPlus,
  downloadmaterialDescription,
  // insertPrRfq,
} from "../../../config/apiUrl";
import axios from "axios";
import { styles } from "./style.js";
// import "../../../pages/style.css";
import CardTemplate from "../../Card/CardTemplate";
import VendorRecipient from "../../Dialogs/VendorRecipints/VendorRecipient";
import RFQStatic from "../../Card/RFQStaticTemplate";
import AlarmIcon from "@mui/icons-material/Alarm";
import UploadIcon from "@mui/icons-material/CloudUploadRounded";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
// import ReminderTemporaryDrawer from "../ReminderTemporaryDrawer";
import { OpenInNew } from "@mui/icons-material";
import Details from "../../Dialogs/Details/Details";
import { openSnackbar } from "../../../redux/actions/snackbarAction";
import _ from "lodash";
import UploadFileDialog from "../../Dialogs/Upload/Upload";
import RFQSubmit from "../../Dialogs/RFQSubmit";
import {
  setReminderSettings,
  resetReminderSettings,
  setRFQSettings,
} from "../../../redux/actions/drawerActions";
import { setPrRfqs } from "../../../redux/actions/prrfqactions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import UploadDynamic from "../../Dialogs/Upload/UploadDynamic";
import { resetCAT } from "../../../redux/resetApp";
import ConfirmAlert from "../../Dialogs/ConfirmAlert.js";
import TermsAndConditions from "../../Dialogs/Upload/TermsAndConditions";
import { setFileSpecification } from "../../../redux/actions/categoryAction";
import { setItemSpecifications } from "../../../redux/actions/itemActions";
import {
  setPrlinktoitemsforspecification,
  setPRselectedChange,
  setDefaultValues,
  setTemplateValues,
  setTermsAndConditions,
  setFileSpecificationFromApi,
} from "../../../redux/actions/prrfqactions";
import PrRFQSettings from "../../PRRFQ/RfqSettings";
import axiosInstance from "../../../interceptor/axiosInstance";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import QuotationFormSectionTable from "../QuotationTemplateSectionTable/QuotationTemplateSectionTable.js";
import QuotationFormSectionTableView from "../QuotationTemplateSectionTable/QuotationTemplateSectionTableView.js";

import QuotationCardTemplate from "../../Card/QuotationCardTemplate.js";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import QuotationDetailsSectionView from "../QuotationDetailsSection/QuotationDetailsSectionView";
import UploadDynamicView from "../../Dialogs/Upload/UploadDynamicView";
import DestinationDetailsDialogView from "../DestinationDetailsDialog/DestinationDetailsDialogView";
import dayjs from "dayjs";

let Date_Time_Not_Null = function (value1) {
  //return value1 && value1.isValid() ? true : false;
  //let date1 = new Date(value1);
  return typeof value1 == "object"
    ? value1.isValid()
    : !isNaN(new Date(value1));
};

let Greater_Than_Date_Time = function (value1, value2) {
  let date1 = new Date(value1);
  let date2 = new Date(value2);

  return date1 > date2;
};

let Greater_Than_Current_Date_Time = function (value1) {
  let date1 = new Date(value1);
  let Today = new Date();

  return date1 > Today;
};
let prconfig = {};

let slab = {
  //has to be dynamic
  58: {
    isOpen: false,
  },
};

class QuotationTemplateView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: "",
      template: "",
      loading: false,
      quotationInitializeFlag: true,
      mainTemplateQuotation: {},
      mainMSTemplateQuotation: {},
      slabMainTemplateQuotation: {},
      isLoading: false,
      errorArray: [],
      additionalInfoTemplate: {},
      DifferenceAdditionalFieldId: [],
      DifferenceMainTemplateFieldId: {},
      DifferenceSlabMainTemplateFieldId: {},
      IntlTemplate: {},
      shipment_mark: "",
      MaterialDescription: false,
      RFQStatic: {
        lock: false,
        star: false,
        RFQValidity: {
          HTMLAttributeName: "RFQValidity",
          FieldLabel: "RFQValidity",
          Validation: "",
          error: false,
          ErrorMessage: "Invalid Date",
          value: "",
          maxDays: 0,
        },
        RFQValidityTime: {
          HTMLAttributeName: "RFQValidityTime",
          FieldLabel: "RFQValidityTime",
          Validation: "",
          error: false,
          ErrorMessage: "Invalid Time",
          value: "23:30",
        },
        TBValidity: {
          HTMLAttributeName: "TBValidity",
          FieldLabel: "TBValidity",
          Validation: "",
          error: false,
          ErrorMessage: "Invalid TB Date",
          value: "",
        },
        TBValidityTime: {
          HTMLAttributeName: "TBValidityTime",
          FieldLabel: "TBValidityTime",
          Validation: "",
          error: false,
          ErrorMessage: "Invalid TB Time",
          value: "23:30",
        },
        RFQspecifications: {
          HTMLAttributeName: "RFQspecifications",
          FieldLabel: "RFQspecifications",
          Validation: "",
          error: false,
          ErrorMessage: "RFQspecifications is required",
          value: "",
        },
        RFQCCUsersmails: {
          HTMLAttributeName: "RFQCCUsersmails",
          FieldLabel: "RFQCCUsersmails",
          Validation: "",
          error: false,
          ErrorMessage: "Invalid!",
          value: "",
        },
        RFQContactCheck: {
          HTMLAttributeName: "RFQContactCheck",
          FieldLabel: "RFQContactCheck",
          Validation: "",
          error: false,
          ErrorMessage: "",
          value: false,
        },
        RFQUpdateCCUsersCheck: {
          HTMLAttributeName: "RFQUpdateCCUsersCheck",
          FieldLabel: "RFQUpdateCCUsersCheck",
          Validation: "",
          error: false,
          ErrorMessage: "",
          value: false,
        },
      },
      RFQDynamic: {},
      vendorRecipintOpen: false,
      vendorRecipintType: "",
      reminderDrawerOpen: false,
      specificationsOpen: false,
      RFQSubmitDialogOpen: false,
      rfqSubmitMsg: "",
      startDate: "",
      startDateTB: true,
      qty: "",
      rate: "",
      unit: "",
      rfqSubmitLoading: false,
      loaderArray: [],
      vendorType: "",
      vendorTerms: "",
      vendorTermsOpen: false,
      currentFieldID: "",
      incoTermError: false,
      paymentTermError: false,
      dynamicFileUploadOpen: false,
      dynamicFileUploadItemOpen: false,

      currentFieldId: null,
      currentTemplate: null,
      categorySpecificationFiles: [],
      jsonTemplateloading: false,
      prevRFQLoading: false,
      prevQuotationFormLoading: false,
      prevRFQ: {},
      randomId: null,
      usedAttributesId: [],
      confirmDialogOpen: false,
      confirmAlertMsg: "",
      confirmAlertTitle: "",
      tandcDialogOpen: false,
      termsAndConditionsData: {},
      applyToAll: {},
      commonOptions: {},
      localPaymentFields: [],
      localIncoFields: [],
      intlPaymentFields: [],
      intlIncoFields: [],
      submitDisable: false,
      templateValue: {},
      slab: {},
      slabKey: 1,
      applyToAllSlab: false,
      quotationTemplateJson: [],
      openConfirmBox: false,
      selectedCurrency: "",
      mainSelectedInitiallyN: false,
      attachments: {},
      Loading: true,
      fieldIdentifierMapping: {},
      isDestinationDetailsDialogOpen: false,
      allItemsTemplate: [],
      selectedMasterCurrency: {},
    };
    this._isMounted = false;
    this.setIsDestinationDetailsDialogOpen =
      this.setIsDestinationDetailsDialogOpen.bind(this);
  }

  componentDidMount() {
    this.props.quotationJsonTemplate.ITEMIZE[0]?.map((cell, index) => {
      if (cell.VisibilityFlag === "Y") {
        if (cell.Identifier === "matspecghclfile") {
          this.setState({
            MaterialDescription: true,
          });
        }
      }
    });
    this.fetchPrevTemplate();
    // this.props.setClick(this.fetchPrevious);
    // this.initializeLocalRecipientsState();
    // this.initializeState();
    // this.initializeIntlRecipientsState();
    this.initializeRFQDynamicState();
    // this.fetchTermsAndCondtions();
  }

  handleCurrencyChange = (event) => {
    this.setState({ selectedCurrency: event.target.value });
  };

  handleMasterCurrencyChange = (value, cell) => {
    let field_id = cell.FieldId || cell.HTMLAttributeName;
    if (!cell || !field_id) {
      return;
    }
    field_id = field_id.match(/\d+/)?.[0];
    this.setState((prevState) => ({
      selectedMasterCurrency: {
        ...prevState.selectedMasterCurrency,
        [field_id]: value || "",
      }
    }));
  }

  getMasterCurrencyByFieldId = (fieldId) => {
    if(!fieldId){
      return "";
    }
    const id = fieldId.match(/\d+/)?.[0] || "";
    const { selectedMasterCurrency } = this.state;
    const currency_name = selectedMasterCurrency[id] || "";
    return currency_name;
  }

  handleConfirmBoxOpen = () => {
    this.setState({
      openConfirmBox: true,
    });
  };

  handleConfirmBoxClose = () => {
    this.setState({
      openConfirmBox: false,
    });
  };

  handleUploadOpen = (value) => {
    // setCurrentFieldId(cell.FieldId);
    this.setState({
      // currentFieldId: cell.FieldId,
      dynamicFileUploadOpen: true,
      currentTemplate: "additionalInfoTemplate",
      // attachments: value,
    });
    // setDynamicFileUploadOpen(true);
    this.getExistingFiles(value);
    // setCurrentTemplate("IntlTemplate");
  };

  uploadItemFiles = (FieldId, Identifier, itemId, index, value) => {
    if (value.value.length > 0) {
      this.setState({
        dynamicFileUploadItemOpen: true,
      });
      this.getExistingFiles(value.value);
    }
  };

  getExistingFiles = (file_id) => {
    const queryString = file_id
      .map((file) => `file_ids[]=${file.id}`)
      .join("&");
    const url = `${postFileSpecifications}?${queryString}`;
    axios
      .get(url)
      .then((res) => {
        this.setState({
          // currentFieldId: cell.FieldId,

          attachments: res.data,
          Loading: false,
        });
      })
      .catch((err) => {
        console.log(err, " downloadFile error");
        this.props.openSnackbar(
          "error",
          err.response.data?.message
            ? err.response.data?.message
            : "Something went wrong!"
        );
        this.setState({
          Loading: false,
        });
      });
  };

  downloadTransactionExcel = (format) => {
    // Implement your downloadTransactionExcel function logic here
  };

  validateFileExistance = () => {
    // Implement your validateFileExistance function logic here
  };

  handleImport = () => {
    // Implement your import logic here
    // ...
    this.handleConfirmBoxOpen();
    // Close the dialog after handling the import
    // this.handleConfirmBoxClose();
  };

  //slab functions start
  handleSlab = (evt, item) => {
    this.setState({
      slab: { ...this.state.slab, [item.id]: { isOpen: evt.target.checked } }, //set is open for item
    });
    if (this.state.applyToAllSlab == true) {
      this.setState({
        applyToAllSlab: false,
      });
    }
    //remove value if slab is closed
    if (!evt.target.checked) {
      this.setState((prevState) => ({
        ...prevState,
        mainTemplate1: {
          ...prevState.mainTemplateQuotation,
          [item.id]: {
            ...prevState.mainTemplateQuotation[item.id],
            slab: [
              { id: 1, start: "1", end: "", error: false },
              { id: 2, start: "More Than", end: "", error: false },
            ],
          },
        },
      }));
    }
  };
  //add row in slab
  slabAddRow = (idx, index) => {
    const re = /((\b|\+|-)(0|([1-9][0-9]*))(\.[0-9]+)?)\b/;
    let allSlab =
      this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
        "slab"
      ];
    if (!re.test(allSlab[allSlab.length - 1].end)) {
      this.props.openSnackbar(
        "error",
        ` Please provide a valid ${this.props?.displayOptions["label"]} for ${this.props.selectedItems[index].code}`
      );
      return;
    } else if (
      parseFloat(allSlab[allSlab.length - 2].start) >=
      parseFloat(allSlab[allSlab.length - 2].end)
    ) {
      this.props.openSnackbar(
        "error",
        `  Please provide a valid ${this.props?.displayOptions["label"]} for ${this.props.selectedItems[index].code}`
      );
      return;
    } else if (allSlab.some((obj) => obj.end == "" || obj.error == true)) {
      this.props.openSnackbar(
        "error",
        `Please provide a valid ${this.props?.displayOptions["label"]} for${this.props.selectedItems[index].code}.`
      );
      return;
    } else if (
      allSlab.length ==
      parseInt(this.props.rfqSettingValues?.allowed_slab_limit)
    ) {
      this.props.openSnackbar(
        "error",
        `Maximum ${this.props.rfqSettingValues.allowed_slab_limit} ${this.props?.displayOptions["label"]} allowed per item`
      );
      return;
    }
    let value = allSlab[idx].end;
    let val = allSlab[idx].end.toString().split(".")[1];
    let isAllZero = val && val.split("").every((char) => char == "0");
    if (val && val.length && !isAllZero) {
      if (val.length == 1) {
        value = parseFloat(value) + 0.1;
        value = value.toFixed(1);
      } else if (val.length == 2) {
        value = parseFloat(value) + 0.01;
        value = value.toFixed(2);
      } else if (val.length == 3) {
        value = parseFloat(value) + 0.001;
        value = value.toFixed(3);
      }
    } else {
      value = parseInt(value) + 1;
    }
    let newSlab = {
      id: Math.random().toString(36).slice(2),
      start: `${parseFloat(value)}`,
      end: "",
      error: false,
    };
    let popped = allSlab.pop(); //remove last element from array
    popped = { ...popped, end: "" };
    let updateSlab = [...allSlab, newSlab];
    updateSlab.push(popped);
    this.setState(
      (prevState) => ({
        ...prevState,
        mainTemplateQuotation: {
          ...prevState.mainTemplateQuotation,
          [this.props.selectedItems[index].id]: {
            ...prevState.mainTemplateQuotation[
              this.props.selectedItems[index].id
            ],
            slab: updateSlab,
          },
        },
      }),
      () => {
        this.handleKey(index);
      }
    );
  };
  //remove row from slab
  slabRemoveRow = (idx, index) => {
    if (this.state.applyToAllSlab == true) {
      this.setState({
        applyToAllSlab: false,
      });
    }
    let allSlab =
      this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
        "slab"
      ];
    let updateSlab = allSlab.filter((row, i) => i != idx);
    let popped = updateSlab.pop();
    popped = { ...popped, end: updateSlab[updateSlab.length - 1].end };
    if (updateSlab[idx]) {
      updateSlab[idx] = { ...updateSlab[idx], start: allSlab[idx].start };
    }
    updateSlab.push(popped);
    this.setState(
      (prevState) => ({
        ...prevState,
        mainTemplateQuotation: {
          ...prevState.mainTemplateQuotation,
          [this.props.selectedItems[index].id]: {
            ...prevState.mainTemplateQuotation[
              this.props.selectedItems[index].id
            ],
            slab: updateSlab,
          },
        },
      }),
      () => {
        this.handleKey(index);
      }
    );
  };
  //handle input in row
  handleRowInput = (evt, row, id, index) => {
    if (this.state.applyToAllSlab == true) {
      this.setState({
        applyToAllSlab: false,
      });
    }
    let allSlab =
      this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
        "slab"
      ];
    let popped = allSlab.pop();
    let updateSlab = allSlab.map((obj, i) => {
      let newObj = { ...obj };
      const re = /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/;
      if (i == id) {
        if (
          parseFloat(evt.target.value) <= parseFloat(newObj.start) ||
          !re.test(evt.target.value)
        ) {
          newObj.error = true;
          newObj.end = "";
        } else {
          newObj.end = parseFloat(evt.target.value);
          newObj.error = false;
        }
      } else if (i == id + 1) {
        if (evt.target.value != "" && re.test(evt.target.value)) {
          let value = evt.target.value;
          let val = evt.target.value.toString().split(".")[1];
          let isAllZero = val && val.split("").every((char) => char == "0");
          if (val && val.length && !isAllZero) {
            if (val.length == 1) {
              value = parseFloat(value) + 0.1;
              value = value.toFixed(1);
            } else if (val.length == 2) {
              value = parseFloat(value) + 0.01;
              value = value.toFixed(2);
            } else if (val.length == 3) {
              value = parseFloat(value) + 0.001;
              value = value.toFixed(3);
            }
          } else {
            value = parseInt(value) + 1;
          }
          newObj.start = `${parseFloat(value)}`;
          if (parseFloat(newObj.end) <= parseFloat(newObj.start)) {
            newObj.error = true;
          } else {
            newObj.error = false;
          }
        } else if (evt.target.value == "") {
          newObj.start = "";
        }
      }
      return newObj;
    });
    popped = { ...popped, end: updateSlab[updateSlab.length - 1].end };
    updateSlab.push(popped);
    this.setState((prevState) => ({
      ...prevState,
      mainTemplateQuotation: {
        ...prevState.mainTemplateQuotation,
        [this.props.selectedItems[index].id]: {
          ...prevState.mainTemplateQuotation[
            this.props.selectedItems[index].id
          ],
          slab: updateSlab,
        },
      },
    }));
  };
  //for apply to all in slab
  handleApplyToAllSlab = (evt, index, itemId) => {
    var found = this.props.selectedItems.find((element) => {
      if (this.state.slab[element.id].isOpen) {
        return element;
      }
    });
    let allSlab = this.state.mainTemplateQuotation[found.id]["slab"];
    if (allSlab.some((obj) => obj.error == true || obj.end == "")) {
      this.props.openSnackbar(
        "error",
        `Please provide a valid ${this.props?.displayOptions["label"]} for ${found.code}.`
      );
      return;
    }
    this.setState({
      applyToAllSlab: evt.target.checked,
    });
    this.props.selectedItems.forEach((item) => {
      if (this.state.slab[item.id].isOpen) {
        this.setState((prevState) => ({
          ...prevState,
          mainTemplateQuotation: {
            ...prevState.mainTemplateQuotation,
            [item.id]: {
              ...prevState.mainTemplateQuotation[item.id],
              slab: allSlab.map((obj, i) => {
                return { ...obj, id: Math.random().toString(36).slice(2) };
              }),
            },
          },
        }));
      }
    });
  };
  //change key for render
  handleKey = (index) => {
    let allSlab =
      this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
        "slab"
      ];
    let updateSlab = allSlab.map((obj, i) => {
      return { ...obj, id: Math.random().toString(36).slice(2) };
    });
    this.setState((prevState) => ({
      ...prevState,
      mainTemplateQuotation: {
        ...prevState.mainTemplateQuotation,
        [this.props.selectedItems[index].id]: {
          ...prevState.mainTemplateQuotation[
            this.props.selectedItems[index].id
          ],
          slab: updateSlab,
        },
      },
    }));
  };
  //slab functions end

  handleChange = (evt, index, FieldId, Identifier, FieldLabel, ElementType) => {
    const { value, type, checked } = evt.target;
    const { mainTemplateQuotation } = this?.state;
    const isFieldEditableCondition =
      ElementType === "TA" || ElementType === "TB" || ElementType === "S";

    const dynamicKey = Object.keys(this.props?.selectedQuotedItems?.items)[
      index
    ];
    if (!this.props.quotationFormFlag) {
      if (
        mainTemplateQuotation[this.props.selectedItems[index].id][FieldId][
          "value"
        ] !== value
      ) {
        this.setState({
          applyToAll: {
            ...this.state.applyToAll,
            [`${FieldId}_applyToAll`]: false,
            [`${FieldId}_applyToAll`]: false,
            [`${FieldId}_applyToAll`]: false,
          },
        });
      }
      // this.setState(
      //   (prevState) => (
      //     console.log("prevState in handleChange accountTemplate", prevState),
      //     console.log("selectedItems in handleChange accountTemplate",this.props.selectedItems[index].id),
      //     console.log("...prevState.mainTemplateQuotation in handleChange accountTemplate",...prevState.mainTemplateQuotation[this.props.selectedItems[index].id][FieldId]),
      //     {
      //     ...prevState,
      //     mainTemplateQuotation: {
      //       ...prevState.mainTemplateQuotation,
      //       [this.props.selectedItems[index].id]: {
      //         ...prevState.mainTemplateQuotation[this.props.selectedItems[index].id],
      //         [FieldId]: {
      //           ...prevState.mainTemplateQuotation[this.props.selectedItems[index].id][
      //             FieldId
      //           ],
      //           value: type !== "checkbox" ? evt.target.value : checked,
      //         },
      //       },
      //     },
      //   }),
      //   () => {
      //     this.validateInput(FieldId, index);
      //     if (Identifier === "Budgetedrate") {
      //       this.setState((prevState) => ({
      //         ...prevState,
      //         mainTemplateQuotation: {
      //           ...prevState.mainTemplateQuotation,
      //           [this.props.selectedItems[index].id]: {
      //             ...prevState.mainTemplateQuotation[this.props.selectedItems[index].id],
      //             rate: value,
      //           },
      //         },
      //       }));
      //     } else if (Identifier === "ProductQuantity") {
      //       this.setState((prevState) => ({
      //         ...prevState,
      //         mainTemplateQuotation: {
      //           ...prevState.mainTemplateQuotation,
      //           [this.props.selectedItems[index].id]: {
      //             ...prevState.mainTemplateQuotation[this.props.selectedItems[index].id],
      //             qty: value,
      //           },
      //         },
      //       }));
      //     }
      //   }
      // );
      this.setState(
        (prevState) => {
          // Construct the new state object
          return {
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              // [this.props.selectedItems[index].id]: {
              //   ...prevState.mainTemplateQuotation[this.props.selectedItems[index].id],
              //   [FieldId]: {
              //     ...prevState.mainTemplateQuotation[this.props.selectedItems[index].id][FieldId],
              //     value: type !== "checkbox" ? evt.target.value : checked,
              //   },
              // },
            },
          };
        },
        () => {
          // Callback function for actions after state update
          this.validateInput(FieldId, index);
          if (
            Identifier === "Budgetedrate" ||
            Identifier === "ProductQuantity"
          ) {
            const fieldName = Identifier === "Budgetedrate" ? "rate" : "qty";
            this.setState((prevState) => ({
              ...prevState,
              mainTemplateQuotation: {
                ...prevState.mainTemplateQuotation,
                [this.props.selectedItems[index].id]: {
                  ...prevState.mainTemplateQuotation[
                    this.props.selectedItems[index].id
                  ],
                  [fieldName]: value,
                },
              },
            }));
          }
        }
      );
      window.localStorage.setItem(
        "maintemplate",
        JSON.stringify(this.state.mainTemplateQuotation)
      );
    } else {
      if (
        // mainTemplateQuotation[this.props.selectedQuotedItems.items[index].id][FieldId]["value"] !==value
        true
      ) {
        this.setState({
          applyToAll: {
            ...this.state.applyToAll,
            [`${FieldId}_applyToAll`]: false,
            [`${FieldId}_applyToAll`]: false,
            [`${FieldId}_applyToAll`]: false,
          },
        });
      }
      // this.setState(
      //   (prevState) => (
      //     console.log("prevState in handleChange accountTemplate",...prevState),
      //     {
      //     ...prevState,
      //     mainTemplateQuotation: {
      //       ...prevState.mainTemplateQuotation,
      //       [this.props.selectedQuotedItems.items[index].id]: {
      //         ...prevState.mainTemplateQuotation[this.props.selectedQuotedItems.items[index].id],
      //         [FieldId]: {
      //           ...prevState.mainTemplateQuotation[this.props.selectedQuotedItems.items[index].id][
      //             FieldId
      //           ],
      //           value: type !== "checkbox" ? evt.target.value : checked,
      //         },
      //       },
      //     },
      //   }),
      //   () => {
      //     this.validateInput(FieldId, index);
      //     if (Identifier === "Budgetedrate") {
      //       this.setState((prevState) => ({
      //         ...prevState,
      //         mainTemplateQuotation: {
      //           ...prevState.mainTemplateQuotation,
      //           [this.state.selectedItems[index].id]: {
      //             ...prevState.mainTemplateQuotation[this.state.selectedItems[index].id],
      //             rate: value,
      //           },
      //         },
      //       }));
      //     } else if (Identifier === "ProductQuantity") {
      //       this.setState((prevState) => ({
      //         ...prevState,
      //         mainTemplateQuotation: {
      //           ...prevState.mainTemplateQuotation,
      //           [this.state.selectedItems[index].id]: {
      //             ...prevState.mainTemplateQuotation[this.state.selectedItems[index].id],
      //             qty: value,
      //           },
      //         },
      //       }));
      //     }
      //   }
      // );   sudo apt update   sudo apt update   sudo apt update
      // this.setState(
      //   (prevState) => {
      //     console.log("prevState in handleChange accountTemplate", ...prevState);
      //     return {
      //       ...prevState,
      //       mainTemplateQuotation: {
      //         ...prevState.mainTemplateQuotation,
      //         [this.props.selectedQuotedItems.items[index].id]: {
      //           ...prevState.mainTemplateQuotation[this.props.selectedQuotedItems.items[index].id],
      //           [FieldId]: {
      //             ...prevState.mainTemplateQuotation[this.props.selectedQuotedItems.items[index].id][FieldId],
      //             value: type !== "checkbox" ? evt.target.value : checked,
      //           },
      //         },
      //       },
      //     };
      //   },
      //   () => {
      //     this.validateInput(FieldId, index);
      //     if (Identifier === "Budgetedrate") {
      //       this.setState((prevState) => ({
      //         ...prevState,
      //         mainTemplateQuotation: {
      //           ...prevState.mainTemplateQuotation,
      //           [this.state.selectedItems[index].id]: {
      //             ...prevState.mainTemplateQuotation[this.state.selectedItems[index].id],
      //             rate: value,
      //           },
      //         },
      //       }));
      //     } else if (Identifier === "ProductQuantity") {
      //       this.setState((prevState) => ({
      //         ...prevState,
      //         mainTemplateQuotation: {
      //           ...prevState.mainTemplateQuotation,
      //           [this.state.selectedItems[index].id]: {
      //             ...prevState.mainTemplateQuotation[this.state.selectedItems[index].id],
      //             qty: value,
      //           },
      //         },
      //       }));
      //     }
      //   }
      // );
      this.setState(
        (prevState) => {
          // console.log(
          //   "selectedQuotedItems props",
          //   this.props?.selectedQuotedItems.transaction_thread[0].template
          //     .itemize
          // );
          // const updatedQuotation = {
          //   ...prevState.mainTemplateQuotation,
          //   [this.props?.selectedQuotedItems?.items[index]?.id]: {
          //     ...prevState.mainTemplateQuotation[this.props.selectedQuotedItems.items[index].id],
          //     [FieldId]: {
          //       // ...prevState.mainTemplateQuotation[this?.props?.selectedQuotedItems?.items[index]?.id][FieldId],
          //       value: type !== "checkbox" ? evt.target.value : checked,
          //     },
          //   },
          // };
          // console.log("updateQuotation in account template:",updatedQuotation);
          //   return {
          //     ...prevState,
          //     mainTemplateQuotation: updatedQuotation,
          //   };
          // },
          return {
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [dynamicKey]: {
                ...prevState.mainTemplateQuotation[dynamicKey],
                [FieldId]: {
                  // ...prevState.mainTemplateQuotation[dynamicKey][FieldId],
                  value: type !== "checkbox" ? evt.target.value : checked,
                  FieldLabelName: FieldLabel,
                  isFieldEditable: isFieldEditableCondition ? "Y" : "N",
                },
              },
            },
          };
        },
        () => {
          if (
            Identifier === "Budgetedrate" ||
            Identifier === "ProductQuantity"
          ) {
            //To set the unit value in quotation
            const keyToUpdate = Identifier === "Budgetedrate" ? "rate" : "qty";
            const updatedState = {
              ...this.state,
              mainTemplateQuotation: {
                ...this.state.mainTemplateQuotation,
                [dynamicKey]: {
                  ...this.state.mainTemplateQuotation[dynamicKey],
                  [keyToUpdate]: value,
                },
              },
            };
            this.setState(updatedState);
          }
        }
      );
      window.localStorage.setItem(
        "mainTemplateQuotation",
        JSON.stringify(this.state.mainTemplateQuotation)
      );
    }
  };

  fetchprlineitemsfiles = () => {
    const { prlineitems, selectedItems } = this.props;
    let prno_items_mapping = {};
    let categoryarray = [];
    selectedItems.forEach((items, item_index) => {
      const itemid = items.id;
      categoryarray = this.Fetchprlineitemspecificationfiles(
        prlineitems[itemid],
        itemid,
        prno_items_mapping,
        categoryarray,
        item_index
      );
    });

    // if(categoryarray?.length > 0){
    this.getprlineitemspecificationfiles(categoryarray, "category");
    // }
    this.props.setPrlinktoitemsforspecification(prno_items_mapping);
  };
  Fetchprlineitemspecificationfiles = (
    pr_lineitemdata,
    itemid,
    prno_items_mapping,
    categoryarray,
    item_index
  ) => {
    let itemarray = [];
    pr_lineitemdata.forEach((object, i) => {
      const items_attechment = object.attachment || [];
      const category_attechment = object.pr?.attachment || [];
      if (category_attechment.length !== 0) {
        if (prno_items_mapping.hasOwnProperty(object.pr.number)) {
          if (!prno_items_mapping[object.pr.number].hasOwnProperty(itemid)) {
            prno_items_mapping[object.pr.number][itemid] = category_attechment;
          }
        } else {
          prno_items_mapping[object.pr.number] = {};
          prno_items_mapping[object.pr.number][itemid] = [];
          prno_items_mapping[object.pr.number][itemid] = category_attechment;
        }
      }
      if (items_attechment) itemarray = [...itemarray, ...items_attechment];
      if (category_attechment)
        categoryarray = [...categoryarray, ...category_attechment];
    });
    this.getprlineitemspecificationfiles(
      itemarray,
      "items",
      itemid,
      item_index
    );
    return categoryarray;
  };
  getprlineitemspecificationfiles = async (
    files_ids,
    type,
    itemid,
    item_index
  ) => {
    let response = {};
    if (files_ids.length > 0) {
      const url = getFileSpecifications(files_ids);
      response = await axiosInstance.get(url);
    }
    if (type === "category") {
      this.fetchCategorySpecs((files) => {
        let selected_category_idres = this.props.selectedCategory?.id;
        const categoryarray = response.data?.data || [];
        const old_files = files?.[selected_category_idres] || [];
        let all_files = [...categoryarray, ...old_files];
        this.props.setFileSpecification(all_files || []);
        //setting flile specification data into redux
        // this.props.setFileSpecificationFromApi({[selected_category_idres]:all_files || []})
      });
    } else {
      let itemmasterfiles = {};

      this.getitemspecificfiles(item_index, (files) => {
        itemmasterfiles = files;
        const itemarray = response.data?.data || [];
        const old_files = files?.[itemid] || [];
        let all_files = [...itemarray, ...old_files];
        this.props.setItemSpecifications({ [itemid]: all_files });
      });
    }
  };
  getitemspecificfiles = async (item_index, callback) => {
    const url = updateFileSpecificationswithmultiplefiles(
      [this.props.selectedItems[item_index]],
      "items"
    );
    let response = await axiosInstance.get(url);
    const nearresponse = response.data?.data;
    callback(nearresponse);
  };

  fetchCategorySpecs = async (callback) => {
    const url = updateFileSpecifications(
      this.props.selectedCategory?.id,
      "categories"
    );

    let response = await axios.get(url);
    const nearresponse = response.data?.data;
    callback(nearresponse);
  };

  extractAndFormat = (inputString) => {
    const pattern = /(\d+)\.\w\.\((f\d+)\)/g;
    const seen = new Set(); // Set to keep track of seen values
    let matches;
    const results = [];

    while ((matches = pattern.exec(inputString)) !== null) {
      const value = `${matches[1]}.${matches[2]}`;
      if (!seen.has(value)) {
        // Check if value is not already in the set
        results.push(value);
        seen.add(value); // Add value to the set
      }
    }

    return results;
  };

  generateRandom = (min, max) => {
    const timestring = new Date().getTime();
    const numstring = Math.floor(Math.random() * (max - min + 1)) + min;
    return timestring + "." + numstring;
  };

  fetchPrevious = () => {
    const { quotationJsonTemplate, lastTransactionData } = this.props;
    if (quotationJsonTemplate?.ITEMIZE?.length && lastTransactionData) {
      this.setState({
        prevQuotationFormLoading: true,
      });

      let mainTemplateQuotation = _.cloneDeep(this.state.mainTemplateQuotation);
      let slabMainTemplateQuotation = _.cloneDeep(
        this.state.mainTemplateQuotation
      );
      let additionalInfoTemplate;
      if (this.props.vendorType == "Local") {
        additionalInfoTemplate = this.initializeLocalRecipientsState();
      } else {
        additionalInfoTemplate = this.initializeIntlRecipientsState();
      }

      // Every---------------
      // for (const key in mainTemplateQuotation) {
      //   const currentItem = mainTemplateQuotation[key];

      //   for (const key2 in currentItem) {
      //     const currentField = currentItem[key2];
      //     const fieldValue =
      //       lastTransactionData.itemize[key]?.solution[0]?.data[0]?.[key2];
      //     if (fieldValue !== undefined) {
      //       // Check if fieldValue is defined
      //       if (
      //         typeof currentField === "object" &&
      //         currentField !== null &&
      //         currentField["Identifier"] !== "Product" &&
      //         currentField["Identifier"] !== "ProductForeign" &&
      //         currentField["Identifier"] !== "ProductCode"
      //         // && key3 === currentField["HTMLAttributeName"]
      //       ) {
      //         currentItem[key2]["value"] = fieldValue;
      //         currentItem[key2]["error"] = false;

      //         if (currentItem[key2]["Identifier"] === "ProductQuantity") {
      //           currentItem["qty"] = fieldValue;
      //         }
      //         if (
      //           currentItem[key2]["Identifier"] === "EstimatedCost" ||
      //           currentItem[key2]["Identifier"] === "EstimatedCostForeign" ||
      //           currentItem[key2]["Identifier"] === "Budgetedrate" ||
      //           currentItem[key2]["Identifier"] === "Rate"
      //         ) {
      //           currentItem["rate"] = fieldValue;
      //         }

      //         const attributeName = currentItem[key2]["HTMLAttributeName"];
      //         const index1 = this.state.errorArray.indexOf(attributeName);

      //         if (index1 !== -1) {
      //           this.setState((prevState) => ({
      //             errorArray: prevState.errorArray.filter(
      //               (item, index) => index !== index1
      //             ),
      //           }));
      //         }
      //       }
      //     }
      //   }
      // }

      // for (const key in mainTemplateQuotation) {
      //   const currentItem = mainTemplateQuotation[key];

      //   for (const key2 in currentItem) {
      //     const currentField = currentItem[key2];
      //     const defaultFormula = currentField["DefaultFormula"];

      //     if (defaultFormula) {
      //       // Extract field identifiers from DefaultFormula and remove 'f' prefix
      //       const fieldIdentifiers = defaultFormula
      //         .match(/f\d+/g)
      //         .map((identifier) => identifier.substring(1));

      //       if (fieldIdentifiers && fieldIdentifiers.length === 2) {

      //         // Fetch values for field identifiers from lastTransactionData
      //         const valuesFound = fieldIdentifiers.map((identifier) => {
      //           const value =
      //             lastTransactionData.itemize[key]?.solution[0]?.data[0]?.[
      //               identifier
      //             ];
      //           return value !== undefined ? value : null;
      //         });
      //         // Check if any value is found for any of the field identifiers
      //         if (valuesFound.some((value) => value !== null)) {
      //           // Set value in currentItem based on DefaultFormula
      //           currentItem[key2]["value"] = valuesFound
      //             .join("")
      //             .replace(/\|\|/g, ""); // Replace || with empty string
      //           currentItem[key2]["error"] = false;

      //           // If it's ProductQuantity, set it in 'qty'
      //           if (currentItem[key2]["Identifier"] === "ProductQuantity") {
      //             currentItem["qty"] = valuesFound
      //               .join("")
      //               .replace(/\|\|/g, ""); // Replace || with empty string
      //           }

      //           // If it's any of these identifiers, set it in 'rate'
      //           if (
      //             currentItem[key2]["Identifier"] === "EstimatedCost" ||
      //             currentItem[key2]["Identifier"] === "EstimatedCostForeign" ||
      //             currentItem[key2]["Identifier"] === "Budgetedrate" ||
      //             currentItem[key2]["Identifier"] === "Rate"
      //           ) {
      //             currentItem["rate"] = valuesFound
      //               .join("")
      //               .replace(/\|\|/g, ""); // Replace || with empty string
      //           }

      //           // Remove attributeName from errorArray if exists
      //           const attributeName = currentItem[key2]["HTMLAttributeName"];
      //           const index1 = this.state.errorArray.indexOf(attributeName);

      //           if (index1 !== -1) {
      //             this.setState((prevState) => ({
      //               errorArray: prevState.errorArray.filter(
      //                 (item, index) => index !== index1
      //               ),
      //             }));
      //           }
      //         }
      //       }
      //     }
      //   }
      // }

      let ItemizeColorChange = {};
      let ItemizeSlabColorChange = {}; // For slab changes
      let mainTemp = {};
      let slabTemp = {};
      if (this.props.multipleSolutionFlag && this.props.slabFlag) {
        for (const key in slabMainTemplateQuotation) {
          const currentItem = slabMainTemplateQuotation[key];
          for (const key2 in currentItem) {
            const currentField = currentItem[key2];
            // const defaultFormula = currentField["DefaultFormula"];
            let attributeNameWithoutF = currentField?.HTMLAttributeName
              ? currentField.HTMLAttributeName.replace("f", "").split("_")[0]
              : null;
            attributeNameWithoutF = parseInt(attributeNameWithoutF);
            lastTransactionData?.itemize[key]?.solution[0].data.map(
              (solution, index) => {
                console.log("");
                const currentIndex = index.toString();
                if (solution?.[attributeNameWithoutF]) {
                  if (
                    this.state.fieldIdentifierMapping?.[currentField.Identifier]
                  ) {
                    if (
                      solution?.[attributeNameWithoutF] !==
                      this.props.PrevTransaction.template.itemize[key]
                        ?.solution[0]?.data[index][
                        this.state.fieldIdentifierMapping?.[
                          currentField.Identifier
                        ]
                      ]
                    ) {
                      if (!ItemizeSlabColorChange[key]) {
                        ItemizeSlabColorChange[key] = {};
                      }
                      if (!ItemizeSlabColorChange[key][index]) {
                        ItemizeSlabColorChange[key][index] = {};
                      }
                      ItemizeSlabColorChange[key][index][
                        attributeNameWithoutF
                      ] =
                        this.props.PrevTransaction.template.itemize[
                          key
                        ]?.solution[0]?.data[index][
                          this.state.fieldIdentifierMapping?.[
                            currentField.Identifier
                          ]
                        ];
                    }
                  }

                  // Ensure the key for the current index exists

                  // Assign currentField value to mainTemp
                  if (currentField.Identifier === "ProductQuantity") {
                    currentItem["qty"] = solution?.[attributeNameWithoutF];
                  }

                  // if (currentItem[key2]["Identifier"] === "ProductQuantity") {
                  //   currentItem["qty"] = finalValue;
                  // }

                  if (
                    currentItem[key2]["Identifier"] === "EstimatedCost" ||
                    currentItem[key2]["Identifier"] ===
                      "EstimatedCostForeign" ||
                    currentItem[key2]["Identifier"] === "Budgetedrate" ||
                    currentItem[key2]["Identifier"] === "Rate" ||
                    currentItem[key2]["Identifier"] === "CIFRate" ||
                    currentItem[key2]["Identifier"] === "rate"
                  ) {
                    currentItem["rate"] = solution?.[attributeNameWithoutF];
                  }
                  if (currentField.Identifier === "GSTType") {
                    let gstTypeValue = currentField.DefaultValue.find(
                      (item) => item.code === solution?.[attributeNameWithoutF]
                    )?.name;
                    currentField.value = gstTypeValue;
                  } else {
                    currentField.value = solution?.[attributeNameWithoutF]; // Assigning currentField value
                  }
                }
                if (!slabTemp[key]) {
                  slabTemp[key] = [];
                }

                if (lastTransactionData?.itemize[key]?.selected) {
                  slabTemp[key]["selected"] =
                    lastTransactionData?.itemize[key]?.selected;
                }

                if (!slabTemp[key][currentIndex]) {
                  slabTemp[key][currentIndex] = {};
                }
                if (attributeNameWithoutF) {
                  if (currentField.Identifier === "ProductQuantity") {
                    slabTemp[key][currentIndex]["qty"] =
                      solution?.[attributeNameWithoutF];
                  }
                  if (
                    currentField.Identifier === "EstimatedCost" ||
                    currentField.Identifier === "EstimatedCostForeign" ||
                    currentField.Identifier === "Budgetedrate" ||
                    currentField.Identifier === "Rate" ||
                    currentField.Identifier === "CIFRate" ||
                    currentField.Identifier === "rate"
                  ) {
                    slabTemp[key][currentIndex]["rate"] =
                      solution?.[attributeNameWithoutF];
                  }
                  if (solution?._range) {
                    slabTemp[key][currentIndex]["_range"] = solution?._range;
                  }
                  if (solution?.select_slab) {
                    slabTemp[key][currentIndex]["select_slab"] =
                      solution?.select_slab;
                  }
                  slabTemp[key][currentIndex][attributeNameWithoutF] = {
                    ...currentField,
                  };
                }
              }
            );
          }
        }
      } else {
        for (const key in mainTemplateQuotation) {
          const currentItem = mainTemplateQuotation[key];
          for (const key2 in currentItem) {
            const currentField = currentItem[key2];
            // const defaultFormula = currentField["DefaultFormula"];
            let attributeNameWithoutF = currentField?.HTMLAttributeName
              ? currentField.HTMLAttributeName.replace("f", "").split("_")[0]
              : null;
            attributeNameWithoutF = parseInt(attributeNameWithoutF);
            lastTransactionData?.itemize[key]?.solution.map(
              (solution, index) => {
                const currentIndex = index.toString();

                if (solution?.data[0][attributeNameWithoutF]) {
                  if (
                    this.state.fieldIdentifierMapping?.[currentField.Identifier]
                  ) {
                    if (
                      solution?.data?.[0][attributeNameWithoutF] !==
                      this.props.PrevTransaction.template.itemize[key]
                        ?.solution[index]?.data[0][
                        this.state.fieldIdentifierMapping?.[
                          currentField.Identifier
                        ]
                      ]
                    ) {
                      if (!ItemizeColorChange[key]) {
                        ItemizeColorChange[key] = {};
                      }
                      if (!ItemizeColorChange[key][index]) {
                        ItemizeColorChange[key][index] = {};
                      }
                      ItemizeColorChange[key][index][attributeNameWithoutF] =
                        this.props.PrevTransaction.template.itemize[
                          key
                        ]?.solution[index]?.data[0][
                          this.state.fieldIdentifierMapping?.[
                            currentField.Identifier
                          ]
                        ];
                    }
                  }

                  // Ensure the key for the current index exists

                  // Assign currentField value to mainTemp
                  if (currentField.Identifier === "ProductQuantity") {
                    currentItem["qty"] =
                      solution?.data[0][attributeNameWithoutF];
                  }

                  // if (currentItem[key2]["Identifier"] === "ProductQuantity") {
                  //   currentItem["qty"] = finalValue;
                  // }

                  if (
                    currentItem[key2]["Identifier"] === "EstimatedCost" ||
                    currentItem[key2]["Identifier"] ===
                      "EstimatedCostForeign" ||
                    currentItem[key2]["Identifier"] === "Budgetedrate" ||
                    currentItem[key2]["Identifier"] === "Rate" ||
                    currentItem[key2]["Identifier"] === "CIFRate" ||
                    currentItem[key2]["Identifier"] === "rate"
                  ) {
                    currentItem["rate"] =
                      solution?.data[0][attributeNameWithoutF];
                  }
                  if (currentField.Identifier === "GSTType") {
                    let gstTypeValue = currentField.DefaultValue.find(
                      (item) =>
                        item.code === solution?.data[0][attributeNameWithoutF]
                    )?.name;
                    currentField.value = gstTypeValue;
                  } else {
                    currentField.value =
                      solution?.data[0][attributeNameWithoutF]; // Assigning currentField value
                  }
                }
                if (!mainTemp[key]) {
                  mainTemp[key] = {};
                }

                if (!mainTemp[key][currentIndex]) {
                  mainTemp[key][currentIndex] = {};
                }
                if (attributeNameWithoutF) {
                  if (currentField.Identifier === "ProductQuantity") {
                    mainTemp[key][currentIndex]["qty"] =
                      solution?.data[0][attributeNameWithoutF];
                  }
                  if (
                    currentField.Identifier === "EstimatedCost" ||
                    currentField.Identifier === "EstimatedCostForeign" ||
                    currentField.Identifier === "Budgetedrate" ||
                    currentField.Identifier === "Rate" ||
                    currentField.Identifier === "CIFRate" ||
                    currentField.Identifier === "rate"
                  ) {
                    mainTemp[key][currentIndex]["rate"] =
                      solution?.data[0][attributeNameWithoutF];
                  }
                  if (solution?.name) {
                    mainTemp[key][currentIndex]["name"] = solution?.name;
                  }
                  mainTemp[key][currentIndex][attributeNameWithoutF] = {
                    ...currentField,
                  };
                }
              }
            );
          }
        }
      }
      let ColorChange = {};
      Object.entries(additionalInfoTemplate).forEach(([key, currentField]) => {
        const attributeNameWithoutF = currentField.HTMLAttributeName
          ? currentField.HTMLAttributeName.replace("f", "")
          : null;

        if (
          attributeNameWithoutF &&
          lastTransactionData.common.hasOwnProperty(attributeNameWithoutF)
        ) {
          // Set the value to the currentField
          if (this.state.fieldIdentifierMapping?.[currentField.Identifier]) {
            // if (this.props.PrevTransaction.template.common[this.state.fieldIdentifierMapping?.[currentField.Identifier]]) {
            if (
              lastTransactionData.common[attributeNameWithoutF] !==
              this.props.PrevTransaction.template.common[
                this.state.fieldIdentifierMapping?.[currentField.Identifier]
              ]
            ) {
              ColorChange[attributeNameWithoutF] =
                this.props.PrevTransaction.template.common[
                  this.state.fieldIdentifierMapping?.[currentField.Identifier]
                ];
            }
          }
          currentField.value =
            lastTransactionData.common[attributeNameWithoutF];
          currentField.error = false;
          let identifier = currentField?.Identifier || '';
          if(identifier && identifier.includes('CurrencyMaster')){
            this.handleMasterCurrencyChange(currentField?.value, currentField);
          }
          // Remove field from errorArray if present
          const index1 = this.state.errorArray.indexOf(attributeNameWithoutF);
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }

          // Set value to corresponding HTML element if it exists
          const element = document.getElementsByName(attributeNameWithoutF)[0];
          if (element) {
            element.value = currentField?.value;
          }
        } else if (
          currentField.DefaultFormula &&
          currentField.DefaultFormula.trim() !== ""
        ) {
          // Extract attribute codes from DefaultFormula
          const attributeCodes =
            currentField.DefaultFormula.match(/f\d+/g) || [];
          // Iterate over each attribute code
          attributeCodes.forEach((attributeCode) => {
            // Extract attribute key from attributeCode
            const attributeKey = attributeCode.substring(1);
            // Check if the attribute key exists in lastTransactionData.common
            if (lastTransactionData.common.hasOwnProperty(attributeKey)) {
              // Set the value to the currentField
              currentField.value = lastTransactionData.common[attributeKey];
              currentField.error = false;

              // Remove field from errorArray if present
              const index2 = this.state.errorArray.indexOf(
                currentField.HTMLAttributeName
              );
              if (index2 !== -1) {
                this.state.errorArray.splice(index2, 1);
              }

              // Set value to corresponding HTML element if it exists
              const element = document.getElementsByName(
                currentField.HTMLAttributeName
              )[0];
              if (element) {
                element.value = currentField.value;
              }
            }
          });
        }
      });
      this.setState({
        mainTemplateQuotation,
        additionalInfoTemplate,
        prevQuotationFormLoading: false, // Assuming loading is finished after updating the state
        DifferenceAdditionalFieldId: ColorChange,
        DifferenceMainTemplateFieldId: ItemizeColorChange,
        DifferenceSlabMainTemplateFieldId: ItemizeSlabColorChange,
        mainMSTemplateQuotation: mainTemp,
        slabMainTemplateQuotation: slabTemp,
      });
    }
  };

  handleCheckboxChange = (event, key) => {
    // Ensure that mainTemplateQuotation and lastTransactionTypeInThread exist before accessing their properties
    if (
      this.state.mainTemplateQuotation &&
      this.props.lastTransactionInThread &&
      this.state.mainTemplateQuotation[key] &&
      this.props.lastTransactionInThread[key]
    ) {
      const isNegotiation =
        this.props.lastTransactionTypeInThread === "Negotiation";
      const lastSelected =
        this.props.lastTransactionInThread[key].selected === "Y";
      const mainSelected =
        this.state.mainTemplateQuotation[key].selected === "Y";

      // Check if the transaction is negotiation and the last transaction selected the item
      if (isNegotiation && lastSelected) {
        // Disable input fields
        const updatedQuotation = {
          ...this.state.mainTemplateQuotation,
          [key]: {
            ...this.state.mainTemplateQuotation[key],
            selected: "Y",
            readOnly: true, // Set readOnly to true to disable input fields
          },
        };
        this.setState({ mainTemplateQuotation: updatedQuotation });
      } else if (!mainSelected) {
        // Allow toggling between 'Y' and 'N' when mainSelected is 'N'
        const updatedQuotation = {
          ...this.state.mainTemplateQuotation,
          [key]: {
            ...this.state.mainTemplateQuotation[key],
            selected: event.target.checked ? "Y" : "N",
            // readOnly: false, // Reset readOnly to false to enable input fields
          },
        };
        this.setState({ mainTemplateQuotation: updatedQuotation });
      } else {
        // Check if filteredSelectedNotData contains the key and its selected value is 'N'
        const filteredSelectedNotDataContainsKey =
          this.props.filteredSelectedNotData &&
          this.props.filteredSelectedNotData[key] &&
          this.props.filteredSelectedNotData[key].selected === "N";

        // If filteredSelectedNotData contains the key and its selected value is 'N', set selected to 'N'
        const selectedStatus = filteredSelectedNotDataContainsKey ? "N" : "Y";

        const updatedQuotation = {
          ...this.state.mainTemplateQuotation,
          [key]: {
            ...this.state.mainTemplateQuotation[key],
            selected: selectedStatus,
            readOnly: false, // Reset readOnly to false to enable input fields
          },
        };
        this.setState({ mainTemplateQuotation: updatedQuotation });
      }
    } else {
      console.error(
        `Template with key ${key} does not exist in either mainTemplateQuotation or lastTransactionInThread.`
      );
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    //handle multiplant on of condition
    let updateRFQDynamic = this.state.RFQDynamic;
    if (
      this.props.location?.pathname.indexOf("/prcombination") == -1 &&
      this.props.rfqSettingValues.allowMultiPlant !=
        nextProps.rfqSettingValues.allowMultiPlant
    ) {
      for (const key in updateRFQDynamic) {
        if (
          !nextProps.rfqSettingValues.allowMultiPlant &&
          updateRFQDynamic[key]["Identifier"] == "Destination"
        ) {
          updateRFQDynamic[key]["value"] = "";
          // updateRFQDynamic[key]["error"] = true;
        } else if (
          nextProps.rfqSettingValues.allowMultiPlant &&
          updateRFQDynamic[key]["Identifier"] == "Destination"
        ) {
          updateRFQDynamic[key]["value"] = [];
          // updateRFQDynamic[key]["error"] = true;
        }
      }
      this.setState({
        RFQDynamic: updateRFQDynamic,
      });
    }
    if (
      _.isEqual(nextProps.defaultValues, this.props.defaultValues) === false
    ) {
      let RFQStaticUpdate = this.state.RFQStatic;
      const { validityDate, priorityRfq, sealedGroupRfq, validityTime } =
        nextProps.defaultValues;
      if ((validityDate._d || validityDate) && validityDate !== null) {
        RFQStaticUpdate.RFQValidity.value = validityDate;
        RFQStaticUpdate.RFQValidityTime.value = validityTime;
        RFQStaticUpdate.TBValidity.value = validityDate;
        RFQStaticUpdate.TBValidityTime.value = validityTime;
      } else {
        RFQStaticUpdate.RFQValidity.value = "";
        RFQStaticUpdate.TBValidity.value = "";
      }
      RFQStaticUpdate.RFQValidity.error = false; //set rfqvalidity error false
      RFQStaticUpdate.lock = sealedGroupRfq;
      RFQStaticUpdate.star = priorityRfq;
      this.setState({
        RFQStatic: RFQStaticUpdate,
      });
    }
    if (nextProps.remiderDrawer?.startDate) {
      this.setState({
        startDate: nextProps.remiderDrawer?.startDate,
      });
    } else {
      this.setState({
        startDate: "",
      });
    }

    this.setState({
      startDateTB: nextProps.remiderDrawer?.includeReminderInterval,
    });

    if (nextProps.selectedItems?.length !== this.props.selectedItems?.length) {
      this.setState({
        errorArray: [],
      });
      if (nextProps.selectedItems.length > this.props.selectedItems.length) {
        //item added,
        let itemsToAdd = nextProps.selectedItems.filter(
          (newItem) => !this.props.selectedItems.includes(newItem)
        );

        for (let index = 0; index < itemsToAdd.length; index++) {
          const newItem = itemsToAdd[index];
          for (
            let j = 0;
            j < this.props.quotationJsonTemplate?.ITEMIZE.length;
            j++
          ) {
            const jsonRow = this.props.quotationJsonTemplate?.ITEMIZE[j];
            if (
              !this.state.usedAttributesId.includes(
                jsonRow[1]?.Child?.AttributeId
              )
            ) {
              // add item here and push it into the usedAttributesId
              this.state.usedAttributesId.push(jsonRow[1]?.Child?.AttributeId);
              this.setState(
                {
                  usedAttributesId: this.state.usedAttributesId,
                },
                () => {
                  let obj = {};
                  jsonRow.forEach((field) => {
                    if (this.state.applyToAll[`${field.FieldId}_applyToAll`]) {
                      this.setState({
                        applyToAll: {
                          ...this.state.applyToAll,
                          [`${field.FieldId}_applyToAll`]: false,
                        },
                      });
                    }
                    if (field.VisibilityFlag === "Y") {
                      if (
                        field.Identifier === "Product" ||
                        field.Identifier === "ProductForeign"
                      ) {
                        obj[field.FieldId] = {
                          HTMLAttributeName: field.HTMLAttributeName,
                          value: newItem.old_id,
                          Validation: "",
                          FieldLabel: field.FieldLabel,
                          error: false,
                          Identifier: field.Identifier,
                          ErrorMessage: "",
                        };
                        return;
                      }
                      if (field.Identifier === "ProductCode") {
                        obj[field.FieldId] = {
                          HTMLAttributeName: field.HTMLAttributeName,
                          value: newItem.code,
                          Validation: "",
                          FieldLabel: field.FieldLabel,
                          error: false,
                          Identifier: field.Identifier,
                          ErrorMessage: "",
                        };
                        return;
                      }
                      obj[field.FieldId] = {
                        HTMLAttributeName: field.HTMLAttributeName,
                        value: "",
                        Validation: field.Validation,
                        FieldLabel: field.FieldLabel,
                        error: false,
                        ErrorMessage: field.ErrorMessage,
                        Identifier: field.Identifier,
                        ElementType: field.ElementType,
                      };
                      if (
                        field.Identifier === "EstimatedCost" ||
                        field.Identifier === "EstimatedCostForeign" ||
                        field.Identifier === "Budgetedrate" ||
                        field.Identifier === "BudgetedrateForeign"
                      ) {
                        obj[field.FieldId][
                          `upd_budget_master_${newItem.id}`
                        ] = false;

                        if (
                          this.props.featurePermission
                            ?.Fetch_Budget_Value_in_RFQ_Form_from_Item_Master
                            ?.feature_value === "Y" &&
                          newItem?.budgeted_rate
                        ) {
                          if (parseFloat(newItem?.budgeted_rate)) {
                            obj[field.FieldId]["value"] = newItem.budgeted_rate;
                            obj["rate"] = newItem.budgeted_rate;
                          } else {
                            obj["rate"] = "";
                          }
                        } else {
                          obj["rate"] = "";
                        }
                      }
                      if (field.ElementType === "S") {
                        if (field.DropdownEdit === "Y") {
                          obj[field.FieldId]["options"] = "loading";
                          // obj[field.FieldId]["options"] =
                          //   this.getItemizeOptions(field);
                        } else {
                          obj[field.FieldId]["options"] = field.DefaultValue;
                        }
                      }
                      if (
                        field.Identifier === "BasicHistoricalPrice" ||
                        field.Identifier === "LandedHistoricalPrice" ||
                        field.Identifier === "DateHP"
                      ) {
                        obj[field.FieldId]["DynamicDefaultValue"] = "loading";
                        this.getDynamicDefaultValues(
                          field,
                          index,
                          field.DefaultValue
                        );
                      }
                    }
                    if (field.Identifier === "EstimatedValue") {
                      obj[field.FieldId] = {
                        HTMLAttributeName: field.HTMLAttributeName,
                        value: "",
                        Validation: field.Validation,
                        FieldLabel: field.FieldLabel,
                        error: false,
                        ErrorMessage: field.ErrorMessage,
                        Identifier: field.Identifier,
                      };
                    }
                    if (
                      Object.keys(this.props.prlineitems).length &&
                      prconfig &&
                      prconfig[field.Identifier] !== undefined
                    ) {
                      let readOnly = "";
                      let value =
                        eval(this[prconfig[field.Identifier].function_name])(
                          field.Identifier,
                          newItem
                        ) || "";

                      if (
                        prconfig[field.Identifier]?.non_editable_function &&
                        prconfig[field.Identifier]?.non_editable_function !==
                          "0"
                      ) {
                        if (
                          prconfig[field.Identifier]?.non_editable_function ==
                          "1"
                        ) {
                          readOnly = true;
                        } else {
                          readOnly = eval(
                            this[
                              prconfig[field.Identifier]?.non_editable_function
                            ]
                          )(value);
                        }
                      }
                      obj[field.FieldId] = {
                        HTMLAttributeName: field.HTMLAttributeName,
                        value: value,
                        Validation: field.Validation,
                        FieldLabel: field.FieldLabel,
                        error: false,
                        ErrorMessage: field.ErrorMessage,
                        Identifier: field.Identifier,
                        ElementType: field.ElementType,
                        readOnly: readOnly,
                      };
                    }
                  });
                  obj.specification = "";
                  obj.qty = "";
                  if (!obj.hasOwnProperty("slab")) {
                    obj["slab"] = [
                      { id: 1, start: "1", end: "", error: false },
                      { id: 2, start: "More Than", end: "", error: false },
                    ];
                  }
                  this.setState((prevState) => ({
                    mainTemplateQuotation: {
                      ...prevState.mainTemplateQuotation,
                      [newItem.id]: obj,
                    },
                  }));
                  if (this.props.rfqSettingValues.includeSlab) {
                    this.setState((prevState) => ({
                      slab: {
                        ...prevState.slab,
                        [newItem.id]: { isOpen: true },
                      },
                    }));
                  } else {
                    this.setState((prevState) => ({
                      slab: {
                        ...prevState.slab,
                        [newItem.id]: { isOpen: false },
                      },
                    }));
                  }
                  if (this.state.applyToAllSlab == true) {
                    this.setState({
                      applyToAllSlab: false,
                    });
                  }
                }
              );
              break;
            }
          }
        }
      } else if (
        nextProps.selectedItems.length < this.props.selectedItems.length
      ) {
        //item removed,
        let itemsToRemove = this.props.selectedItems.filter(
          (newItem) => !nextProps.selectedItems.includes(newItem)
        );
        itemsToRemove.forEach((item) => {
          let index = this.state.usedAttributesId?.indexOf(
            this.state.mainTemplateQuotation[item.id][
              Object.keys(this.state.mainTemplateQuotation[item.id])[0]
            ]?.HTMLAttributeName?.split("_")[1]
          );
          this.state.usedAttributesId.splice(index, 1);
          this.setState({
            usedAttributesId: this.state.usedAttributesId,
          });
          delete this.state.mainTemplateQuotation[item.id];
          delete this.state.slab[item.id];
        });
        this.setState({
          mainTemplateQuotation: this.state.mainTemplateQuotation,
          slab: this.state.slab,
        });
      }
    }
    if (
      nextProps.selectedVendors?.length !== this.props.selectedVendors?.length
    ) {
      setTimeout(() => {
        for (const key in this.state.additionalInfoTemplate) {
          const element = this.state.additionalInfoTemplate[key];
          if (element.value) {
            if (document.getElementsByName(element.HTMLAttributeName)[0]) {
              document.getElementsByName(element.HTMLAttributeName)[0].value =
                element.value;
            }
          }
        }
        for (const key in this.state.IntlTemplate) {
          const element = this.state.IntlTemplate[key];
          if (element.value) {
            if (document.getElementsByName(element.HTMLAttributeName)[0]) {
              document.getElementsByName(element.HTMLAttributeName)[0].value =
                element.value;
            }
          }
        }
      });
    }
    //slab remove if not include slab
    // if(this.props.rfqSettingValues.includeSlab !=nextProps.rfqSettingValues.includeSlab){
    if (
      this.props.rfqSettingValues.includeSlab !=
        nextProps.rfqSettingValues.includeSlab &&
      this.props.location.pathname.indexOf("/prcombination") == -1
    ) {
      if (!nextProps.rfqSettingValues.includeSlab) {
        this.props.selectedItems.forEach((item) => {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [item.id]: {
                ...prevState.mainTemplateQuotation[item.id],
                slab: [
                  { id: 1, start: 1, end: "", error: false },
                  { id: 2, start: "More Than", end: "", error: false },
                ],
              },
            },
          }));
          this.setState((prevState) => ({
            ...prevState,
            slab: {
              ...prevState.slab,
              [item.id]: {
                ...prevState.slab[item.id],
                isOpen: false,
              },
            },
          }));
        });
      } else {
        this.props.selectedItems.forEach((item) => {
          this.setState((prevState) => ({
            ...prevState,
            slab: {
              ...prevState.slab,
              [item.id]: {
                ...prevState.slab[item.id],
                isOpen: true,
              },
            },
          }));
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the quotationInitializeFlag has changed
    if (
      this.state.quotationInitializeFlag !== prevState.quotationInitializeFlag
    ) {
      // If it has changed, conditionally initialize the state
      if (this.state.quotationInitializeFlag) {
        this.initializeState();
      }
    }
  }

  toggleLoaderArray = (arg) => {
    let temp = this.state.loaderArray;
    let index = temp.indexOf(arg);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(arg);
    }
    this.setState({
      loaderArray: temp,
    });
  };

  SUM = (field, item) => {
    let sum = 0;
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = 0;
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) {}
      sum += Number(expression_val);
    });
    return sum;
  };

  // LATEST_Plant = (field,item) => {
  //   let plant_code = "";
  //   plant_code = this.LATEST(field,item);
  //   let plant_name = this.props.pr_dropdown_values.plant[plant_code]['Dropdown_Element_Name'];
  //   let value = `${plant_code}-${plant_name}`;
  //   if( this.props.companyInfo?.default_dropdown_type === "10"  ){
  //     value = `${plant_name}-${plant_code}`;
  //   }
  //   return value;
  // };

  PRLongText = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    //let replacedStr= pritems[pritems.length-1][prconfig[field].response_key].replace(/\^/,"\n");
    return pritems[pritems?.length - 1][prconfig[field].response_key];
  };

  COMMA = (field, item) => {
    let str = "";
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = "";
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) {}
      str += expression_val + ",";
    });
    return str.slice(0, -1);
  };

  COMMA_WITH_SPACE = (field, item) => {
    let str = "";
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = "";
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) {}
      str += expression_val + ", ";
    });
    return str.slice(0, -2);
  };

  EstimatedCost = (field, item) => {
    let value = item.estimated_cost;
    if (
      this.props.featurePermission
        ?.Fetch_Budget_Value_in_RFQ_Form_from_Item_Master?.feature_value === "N"
    ) {
      value = this.LATEST(field, item);
    }
    return value;
  };

  MIN = (field, item) => {
    let value = 0;
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = 0;
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) {}

      if (expression_val < value) {
        value = expression_val;
      }
    });

    return value;
  };

  MIN_DATE = (field, item) => {
    let value = 0;

    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = 0;
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
        if (!value || moment(value).isAfter(moment(expression_val))) {
          value = expression_val;
        }
      } catch (e) {}

      if (expression_val < value) {
        value = expression_val;
      }
    });

    return value;
  };

  LATEST_Remove_Circumflex = (field, item) => {
    let value = this.LATEST(field, item);
    return value ? value.replace(/\^\^\^/g, "\n") : "";
  };

  PRLongText = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    return (
      this.LATEST_Remove_Circumflex(field, item) ||
      pritems[pritems.length - 1]["material_short_text"] ||
      ""
    );
  };

  DeliverySchedule = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    let pr_delivery_schedule = "";
    pritems.forEach(function (item, index) {
      let quantity = item.quantity;
      let delivery_date = item.delivery_date;
      let date_arr = delivery_date.split("-");
      date_arr = date_arr.reverse();
      //delivery_date = delivery_date.replace(/-/g, "\/");
      delivery_date = date_arr.join("-");
      let line_item = item.pr_line_item;
      let pr_number = item.pr.number;
      pr_delivery_schedule +=
        delivery_date +
        "," +
        quantity +
        "," +
        pr_number +
        "(" +
        line_item +
        ");";
    });
    pr_delivery_schedule = pr_delivery_schedule.slice(0, -1);
    return pr_delivery_schedule;
  };

  PRNoLineItem = (field, item) => {
    let str = "";
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      str += prlineitem.pr.number + "(" + prlineitem.pr_line_item + "), ";
    });
    return str.slice(0, -2);
  };

  ReqDeliveryDate = (field, item) => {};

  LATEST = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    //return pritems[pritems?.length-1][prconfig[field].response_key] || '';
    let pr_line_item_object = pritems[pritems.length - 1];
    let expression = "pr_line_item_object" + "." + prconfig[field].response_key;
    let result = "";
    try {
      result = eval(expression);
    } catch (e) {}

    return result;
  };

  LATEST_PO_DATE = (field, item) => {
    let value = "";
    value = this.LATEST(field, item);
    if (value) {
      let pattern = /\b(\d{4})(\d{2})(\d{2})\b/;
      let result = value.match(pattern);
      if (result) {
        result.shift();
        value = result.join("-");
      }
    }
    return value;
  };

  QTY_Check = (qty) => {
    let $moq_permission =
      this.props.accountModulePermission[
        "Allow more Quantity in PR Qty Distribution"
      ]?.module_activated;
    let $service_rfq_permission =
      this.props.accountModulePermission["Service PR"]?.module_activated;
    let readOnly = true;
    if ($moq_permission === "Y" || $service_rfq_permission === "Y") {
      readOnly = false;
    }
    return readOnly;
  };

  COMPANY_CODE_Check = (company_code) => {
    return company_code ? true : false;
  };

  initializeState = async () => {
    let initialSlab = {};
    let finalState = {};
    if (this.props.quotationJsonTemplate?.ITEMIZE?.length) {
      for (
        let index = 0;
        index < Object.keys(this.props.selectedQuotedItems.items).length;
        index++
      ) {
        let obj = {};
        this.state.usedAttributesId.push(
          this.props.quotationJsonTemplate?.ITEMIZE[index][1]?.Child
            ?.AttributeId
        );
        this.setState({
          usedAttributesId: this.state.usedAttributesId,
        });
        for (
          let fieldIndex = 0;
          fieldIndex < this.props.quotationJsonTemplate?.ITEMIZE[index].length;
          fieldIndex++
        ) {
          const row =
            this.props.quotationJsonTemplate?.ITEMIZE[index][fieldIndex];
          if (row.VisibilityFlag === "Y") {
            if (
              row.Identifier === "Product" ||
              row.Identifier === "ProductForeign"
            ) {
              obj[row.FieldId] = {
                HTMLAttributeName: row.HTMLAttributeName,
                value: Object.keys(this.props.selectedQuotedItems.items)[index],
                Validation: "",
                FieldLabel: row.FieldLabel,
                error: false,
                Identifier: row.Identifier,
                ErrorMessage: "",
                DefaultFormula: row.DefaultFormula,
                DefaultValue: row.DefaultValue,
              };
              continue;
            }
            if (row.Identifier === "ProductCode") {
              obj[row.FieldId] = {
                HTMLAttributeName: row.HTMLAttributeName,
                value: Object.values(this.props.selectedQuotedItems.items)[
                  index
                ].code,
                Validation: "",
                FieldLabel: row.FieldLabel,
                error: false,
                Identifier: row.Identifier,
                ErrorMessage: "",
                DefaultFormula: row.DefaultFormula,
                DefaultValue: row.DefaultValue,
              };
              continue;
            }

            let value = "";
            if (row.Identifier === "MasterSpecification") {
              value =
                this.props.selectedQuotedItems.items[index]?.specifications;
            } else if (row.Identifier === "MasterHSNCode") {
              value = this.props.selectedQuotedItems.items[index]?.hsn_code;
            }

            let readOnly = false;
            if (
              Object.keys(this.props.prlineitems).length &&
              prconfig &&
              prconfig[row.Identifier] !== undefined
            ) {
              value =
                eval(this[prconfig[row.Identifier].function_name])(
                  row.Identifier,
                  this.props.items.selectedQuotedItems[index]
                ) || "";
              if (
                prconfig[row.Identifier]?.non_editable_function &&
                prconfig[row.Identifier]?.non_editable_function !== "0"
              ) {
                if (prconfig[row.Identifier]?.non_editable_function == "1") {
                  readOnly = true;
                } else {
                  readOnly = eval(
                    this[prconfig[row.Identifier]?.non_editable_function]
                  )(value);
                }
              }
            }
            obj[row.FieldId] = {
              HTMLAttributeName: row.HTMLAttributeName,
              value: value,
              Validation: row.Validation,
              FieldLabel: row.FieldLabel,
              error: false,
              ErrorMessage: row.ErrorMessage,
              Identifier: row.Identifier,
              ElementType: row.ElementType,
              readOnly: readOnly,
              DefaultFormula: row.DefaultFormula,
              DefaultValue: row.DefaultValue,
            };
            if (
              row.Identifier === "EstimatedCost" ||
              row.Identifier === "EstimatedCostForeign" ||
              row.Identifier === "Budgetedrate" ||
              row.Identifier === "BudgetedrateForeign"
            ) {
              obj[row.FieldId][
                `upd_budget_master_${this.props.selectedQuotedItems.items[index].id}`
              ] = false;

              if (
                this.props.featurePermission
                  ?.Fetch_Budget_Value_in_RFQ_Form_from_Item_Master
                  ?.feature_value === "Y" &&
                this.props.selectedQuotedItems.items[index]?.budgeted_rate
              ) {
                if (
                  parseFloat(
                    this.props.selectedQuotedItems.items[index]?.budgeted_rate
                  )
                ) {
                  obj[row.FieldId]["value"] =
                    this.props.selectedQuotedItems.items[index].budgeted_rate;
                  obj["rate"] =
                    this.props.selectedQuotedItems.items[index].budgeted_rate;
                } else {
                  obj["rate"] = "";
                }
              } else {
                obj["rate"] = value;
              }
            }

            if (row.ElementType === "S") {
              if (row.DropdownEdit === "Y") {
                obj[row.FieldId]["options"] = [];
                //getting options data from redux if it is available else calling api
                let optionsData = this.getOptionsFromRedux(row, "itemize");
                if (optionsData) {
                  obj[row.FieldId]["options"] = optionsData;
                  this.setState({
                    commonOptions: {
                      ...this.state.commonOptions,
                      [row.Identifier]: optionsData,
                    },
                  });
                } else {
                  // obj[row.FieldId]["options"] = await this.getItemizeOptions(
                  //   row,
                  //   index
                  // );
                }
              } else {
                obj[row.FieldId]["options"] = row.DefaultValue;
              }
            }
            if (
              row.Identifier === "BasicHistoricalPrice" ||
              row.Identifier === "LandedHistoricalPrice" ||
              row.Identifier === "DateHP"
            ) {
              obj[row.FieldId]["DynamicDefaultValue"] = "loading";
              this.getDynamicDefaultValues(row, index, row.DefaultValue);
            }
          }
          if (row.Identifier === "EstimatedValue") {
            obj[row.FieldId] = {
              HTMLAttributeName: row.HTMLAttributeName,
              value: "",
              Validation: row.Validation,
              FieldLabel: row.FieldLabel,
              error: false,
              ErrorMessage: row.ErrorMessage,
              Identifier: row.Identifier,
              DefaultFormula: row.DefaultFormula,
              DefaultValue: row.DefaultValue,
            };
          }
          // if ( row.Identifier === "ProductQuantity") obj.qty =  obj[row.FieldId].value
          if (row.Identifier === "ProductQuantity" && obj[row.FieldId])
            obj.qty = obj[row.FieldId].value;
        }
        obj.specification = "";
        const itemize = this.props.lastTransactionData.itemize;
        const keys = Object.keys(itemize);
        const key = keys[index]; // Get the key at the specified index
        obj.added_after_rfq_created =
          this.props.items[key]?.added_after_rfq_created;
        obj.selected = Object.values(this.props.lastTransactionData.itemize)[
          index
        ]?.selected;
        if (!obj.hasOwnProperty("qty")) {
          obj.qty = "";
        }
        if (!obj.hasOwnProperty("slab")) {
          obj["slab"] = [
            { id: 1, start: "1", end: "", error: false },
            { id: 2, start: "More Than", end: "", error: false },
          ];
        }
        finalState[Object.keys(this.props.selectedQuotedItems.items)[index]] =
          obj;
        if (this.props.rfqSettingValues.includeSlab) {
          initialSlab[
            Object.keys(this.props.selectedQuotedItems.items)[index]
          ] = {
            isOpen: true,
          };
        } else {
          initialSlab[
            Object.keys(this.props.selectedQuotedItems.items)[index]
          ] = {
            isOpen: false,
          };
        }
      }
      this.setState(
        {
          mainTemplateQuotation: finalState,
          slab: initialSlab,
        },
        () => {
          if (
            this.props.featurePermission?.prev_tran_value?.feature_value ===
              "Y" &&
            this.props.prplants.length === 0
          ) {
            this.fetchPrevious();
          } else {
            this.fetchPrevious();
          }
        }
      );
    }
  };

  fetchPrevTemplate = async () => {
    let templateURL = "";
    let transaction_id;
    if (this.props.PrevTransaction?.type == "Inquiry") {
      transaction_id = 1;
    } else if (this.props.PrevTransaction?.type === "Quotation") {
      transaction_id = 3;
    } else if (this.props.PrevTransaction?.type === "Negotiation") {
      transaction_id = 4;
    } else if (this.props.PrevTransaction?.type === "Requote") {
      transaction_id = 5;
    }
    if (process.env.NODE_ENV === "production") {
      templateURL = templateURi(
        this.props.userInfo.division_id,
        this.props.companyInfo.display_name,
        this.props.selectedQuotedItems.template.id,
        this.props.selectedQuotedItems.template.batch_id,
        transaction_id == 1
          ? transaction_id
          : transaction_id + (this.props.vendorType === "Local" ? "_L" : "_I")
      );
    } else {
      templateURL = devTemplateURi(
        this.props.userInfo.division_id,
        this.props.companyInfo.display_name,
        this.props.selectedQuotedItems.template.id,
        this.props.selectedQuotedItems.template.batch_id,
        transaction_id == 1
          ? transaction_id
          : transaction_id + (this.props.vendorType === "Local" ? "_L" : "_I")

        // (this.props.PrevTransaction?.type === "Inquiry"
        //   ? 1
        //   : this.props.PrevTransaction?.type === "Quotation"
        //   ? 3 + this.props.vendorType == "Local" ? '_L' : "_I"
        //   : 5 + this.props.vendorType == "Local" ? '_L' : "_I")
      );
    }
    await axios
      .get(templateURL)
      .then((response) => {
        let FieldIDIdentifierMapping = this.convertToFieldIdIdentifierPairs(
          response.data
        );
        this.setState(
          {
            fieldIdentifierMapping: FieldIDIdentifierMapping,
          },
          () => {
            this.initializeState();
          }
        );
      })
      .catch((err) => {
        console.log(err, "main json err");
      });
  };
  convertToFieldIdIdentifierPairs = (jsonData) => {
    const fieldIdIdentifierPairs = {};

    // Loop through FOREIGN section
    if (jsonData.FOREIGN) {
      jsonData.FOREIGN.forEach((obj) => {
        fieldIdIdentifierPairs[obj.Identifier] = obj.FieldId;
      });
    }

    // Consider only the first array inside ITEMIZE section if present
    if (jsonData.ITEMIZE && jsonData.ITEMIZE.length > 0) {
      jsonData.ITEMIZE[0].forEach((obj) => {
        fieldIdIdentifierPairs[obj.Identifier] = obj.FieldId;
      });
    }

    // Loop through LOCAL section if present
    if (jsonData.LOCAL) {
      jsonData.LOCAL.forEach((obj) => {
        fieldIdIdentifierPairs[obj.Identifier] = obj.FieldId;
      });
    }

    // Loop through COMMON section if present
    if (jsonData.COMMON) {
      jsonData.COMMON.forEach((obj) => {
        fieldIdIdentifierPairs[obj.Identifier] = obj.FieldId;
      });
    }

    return fieldIdIdentifierPairs;
  };

  initializeLocalRecipientsState = () => {
    let row = {};
    const combinedTemplate = this.props.quotationJsonTemplate?.COMMON.concat(
      this.props.quotationJsonTemplate?.LOCAL
    );
    combinedTemplate.forEach((cell) => {
      row[cell.FieldId] = {
        HTMLAttributeName: cell.HTMLAttributeName,
        value: "",
        Validation: cell.Validation,
        FieldLabel: cell.FieldLabel,
        error: false,
        ErrorMessage: cell.ErrorMessage,
        Identifier: cell.Identifier,
        ElementType: cell.ElementType,
        DefaultFormula: cell.DefaultFormula,
        DefaultValue: cell.DefaultValue,
      };
      if (cell.ElementType === "S") {
        if (cell.DropdownEdit === "Y") {
          row[cell.FieldId]["options"] = "loading";
          //getting options data from redux if it is available else calling api
          let optionsData = this.getOptionsFromRedux(cell, "additionalInfo");
          if (optionsData) {
            row[cell.FieldId]["options"] = optionsData;
          } else {
            // this.getOptionsFromApi(cell, "additionalInfo");
          }
        } else {
          row[cell.FieldId]["options"] = cell.DefaultValue;
        }
      } else {
        row[cell.FieldId]["value"] = cell.DefaultValue || "";
      }
    });
    this.setState({
      additionalInfoTemplate: row,
    });
    return row;
  };

  initializeIntlRecipientsState = () => {
    let row = {};

    const combinedTemplate = this.props.quotationJsonTemplate?.COMMON.concat(
      this.props.quotationJsonTemplate?.FOREIGN
    );

    combinedTemplate.forEach((cell) => {
      row[cell.FieldId] = {
        HTMLAttributeName: cell.HTMLAttributeName,
        value: "",
        Validation: cell.Validation,
        FieldLabel: cell.FieldLabel,
        error: false,
        ErrorMessage: cell.ErrorMessage,
        Identifier: cell.Identifier,
        ElementType: cell.ElementType,
        DefaultFormula: cell.DefaultFormula,
        DefaultValue: cell.DefaultValue,
      };
      if (cell.ElementType === "S") {
        if (cell.DropdownEdit === "Y") {
          row[cell.FieldId]["options"] = "loading";
          //getting options data from redux if it is available else calling api
          let optionsData = this.getOptionsFromRedux(cell, "intl");
          if (optionsData) {
            row[cell.FieldId]["options"] = optionsData;
          } else {
            // this.getOptionsFromApi(cell, "intl");
          }
        } else {
          row[cell.FieldId]["options"] = cell.DefaultValue || "";
        }
      }
    });

    this.setState({
      additionalInfoTemplate: row,
    });
    return row;
  };

  // getItemizeOptions = (cell, index) => {
  //   console.log("getItemizeOptions Triggered", cell, index);
  //   let templateId = this.props.quotationJsonTemplate?.PARAM[0]?.template_id;
  //   if (index !== 0) {
  //     return this.state.commonOptions[cell.Identifier];
  //   }
  //   axios.defaults.headers.common[
  //     "Authorization"
  //   ] = `Bearer ${this.props.sessionId}`;
  //   const url = getDropdownOptions(
  //     cell.Identifier,
  //     this.props.quotationJsonTemplate?.PARAM[0]?.template_id
  //   );
  //   this.toggleLoaderArray(
  //     index ? `${cell.Identifier}_${index}` : cell.Identifier
  //   );
  //   return axios
  //     .get(url)
  //     .then((res) => {
  //       this.toggleLoaderArray(
  //         index ? `${cell.Identifier}_${index}` : cell.Identifier
  //       );
  //       if (res.data.dropdown_mapping_status !== false) {
  //         this.setState({
  //           commonOptions: {
  //             ...this.state.commonOptions,
  //             [cell.Identifier]: res.data.data,
  //           },
  //         });
  //         //setting itemize data into redux
  //         this.setOptionsInRedux(
  //           templateId,
  //           cell.Identifier,
  //           res.data.data,
  //           "itemize"
  //         );
  //         return res.data.data;
  //       } else {
  //         this.setState({
  //           commonOptions: {
  //             ...this.state.commonOptions,
  //             [cell.Identifier]: cell.DefaultValue,
  //           },
  //         });
  //         this.setOptionsInRedux(
  //           templateId,
  //           cell.Identifier,
  //           cell.DefaultValue,
  //           "itemize"
  //         );
  //         return cell.DefaultValue;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, "options error");
  //       this.toggleLoaderArray(
  //         index ? `${cell.Identifier}_${index}` : cell.Identifier
  //       );
  //       return [];
  //     });
  // };

  getItemizeOptions = async (cell, index) => {
    let templateId = this.props.quotationJsonTemplate?.PARAM[0]?.template_id;
    if (index !== 0) {
      return this.state.commonOptions[cell.Identifier];
    }
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    // const url = getDropdownOptions(
    //   cell.Identifier,
    //   this.props.quotationJsonTemplate?.PARAM[0]?.template_id
    // );
    // this.toggleLoaderArray(
    //   index ? `${cell.Identifier}_${index}` : cell.Identifier
    // );
    // try {
    //   // setIsLoading(true);
    //   const res = await axios.get(url);
    //   this.toggleLoaderArray(
    //     index ? `${cell.Identifier}_${index}` : cell.Identifier
    //   );
    //   if (res.data.dropdown_mapping_status !== false) {
    //     this.setState({
    //       commonOptions: {
    //         ...this.state.commonOptions,
    //         [cell.Identifier]: res.data.data,
    //       },
    //     });
    //     //setting itemize data into redux
    //     this.setOptionsInRedux(
    //       templateId,
    //       cell.Identifier,
    //       res.data.data,
    //       "itemize"
    //     );
    //     return res.data.data;
    //   } else {
    //     this.setState({
    //       commonOptions: {
    //         ...this.state.commonOptions,
    //         [cell.Identifier]: cell.DefaultValue,
    //       },
    //     });
    //     this.setOptionsInRedux(
    //       templateId,
    //       cell.Identifier,
    //       cell.DefaultValue,
    //       "itemize"
    //     );
    //     return cell.DefaultValue;
    //   }
    // } catch (err) {
    //   console.log(err, "options error");
    //   this.toggleLoaderArray(
    //     index ? `${cell.Identifier}_${index}` : cell.Identifier
    //   );
    //   return [];
    // }
  };

  // getOptionsFromApi = (cell, template) => {
  //   console.log("getOptionsFromApi Triggered", cell, template);
  //   console.log("this.sessionID", this.sessionID);
  //   // let templateId = this.props.quotationJsonTemplate?.PARAM[0]?.template_id;
  //   let templateId = this.props.quotationJsonTemplate?.PARAM[0]?.template_id;
  //   console.log("templateId == ", templateId);
  //   axios.defaults.headers.common[
  //     "Authorization"
  //   ] = `Bearer ${this.props.sessionId}`;
  //   const url = getDropdownOptions(
  //     cell.Identifier,
  //     this.props.quotationJsonTemplate?.PARAM[0]?.template_id
  //   );
  //   //  console.log(" Index == getItemizeOptions",cell.Identifier,template);
  //   this.toggleLoaderArray(cell.Identifier);
  //   axios
  //     .get(url)
  //     .then((res) => {
  //       console.log("res.data", res.data);
  //       if (template === "additionalInfo") {
  //         if (res.data.dropdown_mapping_status !== false) {
  //           this.setState((prevState) => ({
  //             ...prevState,
  //             additionalInfoTemplate: {
  //               ...prevState.additionalInfoTemplate,
  //               [cell.FieldId]: {
  //                 ...prevState.additionalInfoTemplate[cell.FieldId],
  //                 options: res.data?.data,
  //               },
  //             },
  //           }));
  //           //setting additionalInfo data into redux
  //           this.setOptionsInRedux(
  //             templateId,
  //             cell.Identifier,
  //             res.data.data,
  //             template
  //           );
  //         } else {
  //           this.setState((prevState) => ({
  //             ...prevState,
  //             additionalInfoTemplate: {
  //               ...prevState.additionalInfoTemplate,
  //               [cell.FieldId]: {
  //                 ...prevState.additionalInfoTemplate[cell.FieldId],
  //                 options: cell.DefaultValue,
  //               },
  //             },
  //           }));
  //         }
  //       }
  //       this.toggleLoaderArray(cell.Identifier);
  //     })
  //     .catch((err) => {
  //       console.log(err, "options error");
  //       if (template === "additionalInfo") {
  //         this.setState((prevState) => ({
  //           ...prevState,
  //           additionalInfoTemplate: {
  //             ...prevState.additionalInfoTemplate,
  //             [cell.FieldId]: {
  //               ...prevState.additionalInfoTemplate[cell.FieldId],
  //               options: [],
  //             },
  //           },
  //         }));
  //       }
  //       this.toggleLoaderArray(cell.Identifier);
  //     });
  // };

  // updateAdditionalInfoFromCurrencyOptions = (cell, template) => {
  //   console.log("getCurrencyOptionsFromApi Triggered", cell, template);
  //   console.log("this.sessionID", this.sessionID);
  //   let templateId = this.props.quotationJsonTemplate?.PARAM[0]?.template_id;
  //   console.log("templateId == ", templateId);
  //   axios.defaults.headers.common[
  //     "Authorization"
  //   ] = `Bearer ${this.props.sessionId}`;
  //   const url = "http://192.168.1.161:8888/v1/currencyDropdownList";
  //   this.toggleLoaderArray(cell.Identifier);

  //   axios
  //     .get(url)
  //     .then((res) => {
  //       console.log("res.data", res.data);
  //       if (template === "additionalInfo") {
  //         // Assuming the response is an array of currency objects
  //         const options = res.data.map((currency) => ({
  //           value: currency.CurrencyID,
  //           label: currency.Currency,
  //         }));

  //         console.log("options == ", options);

  //         // Update state with the options
  //         this.setState((prevState) => ({
  //           ...prevState,
  //           additionalInfoTemplate: {
  //             ...prevState.additionalInfoTemplate,
  //             [cell.FieldId]: {
  //               ...prevState.additionalInfoTemplate[cell.FieldId],
  //               options: options,
  //             },
  //           },
  //         }));

  //         // Assuming setOptionsInRedux function is defined
  //         this.setOptionsInRedux(
  //           templateId,
  //           cell.Identifier,
  //           options,
  //           template
  //         );
  //       }
  //       this.toggleLoaderArray(cell.Identifier);
  //     })
  //     .catch((err) => {
  //       console.log(err, "options error");
  //       if (template === "additionalInfo") {
  //         this.setState((prevState) => ({
  //           ...prevState,
  //           additionalInfoTemplate: {
  //             ...prevState.additionalInfoTemplate,
  //             [cell.FieldId]: {
  //               ...prevState.additionalInfoTemplate[cell.FieldId],
  //               options: [],
  //             },
  //           },
  //         }));
  //       }
  //       console.log(
  //         "additionalInfoTemplate0-0-0-0",
  //         this.state.additionalInfoTemplate
  //       );
  //       this.toggleLoaderArray(cell.Identifier);
  //     });
  // };

  //setting options data into redux

  getOptionsFromApi = async (cell, template) => {
    try {
      let templateId = this.props.quotationJsonTemplate?.PARAM[0]?.template_id;
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.sessionId}`;
      const url = getDropdownOptions(
        cell.Identifier,
        this.props.quotationJsonTemplate?.PARAM[0]?.template_id
      );
      this.toggleLoaderArray(cell.Identifier);
      const res = await axios.get(url);
      if (template === "additionalInfo") {
        if (res.data.dropdown_mapping_status !== false) {
          this.setState((prevState) => ({
            ...prevState,
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [cell.FieldId]: {
                ...prevState.additionalInfoTemplate[cell.FieldId],
                options: res.data?.data,
              },
            },
          }));
          this.setOptionsInRedux(
            templateId,
            cell.Identifier,
            res.data.data,
            template
          );
        } else {
          this.setState((prevState) => ({
            ...prevState,
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [cell.FieldId]: {
                ...prevState.additionalInfoTemplate[cell.FieldId],
                options: cell.DefaultValue,
              },
            },
          }));
        }
      }
      this.toggleLoaderArray(cell.Identifier);
    } catch (error) {
      console.log(error, "options error");
      if (template === "additionalInfo") {
        this.setState((prevState) => ({
          ...prevState,
          additionalInfoTemplate: {
            ...prevState.additionalInfoTemplate,
            [cell.FieldId]: {
              ...prevState.additionalInfoTemplate[cell.FieldId],
              options: [],
            },
          },
        }));
      }
      this.toggleLoaderArray(cell.Identifier);
    }
  };

  updateAdditionalInfoFromCurrencyOptions = (cell, template) => {
    let templateId = this.props.quotationJsonTemplate?.PARAM[0]?.template_id;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = "http://192.168.1.161:8888/v1/dropdowns-currency-list";
    this.toggleLoaderArray(cell.Identifier);

    axios
      .get(url)
      .then((res) => {
        if (cell.Identifier === "Currency" && template === "additionalInfo") {
          // Assuming the response is an array of currency objects
          const options = res.data.map((currency) => ({
            value: currency.CurrencyID,
            label: currency.Currency,
          }));

          // Update state with the options
          this.setState((prevState) => ({
            ...prevState,
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [cell.FieldId]: {
                ...prevState.additionalInfoTemplate[cell.FieldId],
                options: res.data,
              },
            },
          }));

          // Assuming setOptionsInRedux function is defined
          this.setOptionsInRedux(
            templateId,
            cell.Identifier,
            options,
            template
          );
        }
        this.toggleLoaderArray(cell.Identifier);
      })
      .catch((err) => {
        console.log(err, "options error");
        if (cell.Identifier === "Currency" && template === "additionalInfo") {
          this.setState((prevState) => ({
            ...prevState,
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [cell.FieldId]: {
                ...prevState.additionalInfoTemplate[cell.FieldId],
                options: [],
              },
            },
          }));
        }
        this.toggleLoaderArray(cell.Identifier);
      });
  };

  setOptionsInRedux = (templateId, name, data, template) => {
    let tempObject = {
      data,
      template,
    };
    let updateTemplateValues = this.props.templateValues;
    if (Object.keys(updateTemplateValues).length !== 0) {
      if (Object.keys(updateTemplateValues).some((val) => val == templateId)) {
        if (
          !Object.keys(updateTemplateValues[templateId]).some(
            (item) => item == name
          )
        ) {
          let newTemplateValues = {
            ...updateTemplateValues[templateId],
            [name]: tempObject,
          };
          this.props.setTemplateValues({ [templateId]: newTemplateValues });
        }
      } else {
        this.props.setTemplateValues({ [templateId]: { [name]: tempObject } });
      }
    } else {
      this.props.setTemplateValues({ [templateId]: { [name]: tempObject } });
    }
  };
  //getting options data from redux
  getOptionsFromRedux = (cell, template) => {
    let templateId = this.props.quotationJsonTemplate?.PARAM[0]?.template_id;
    let updateTemplateValues = this.props.templateValues;
    if (Object.keys(updateTemplateValues).length !== 0) {
      if (updateTemplateValues[templateId]) {
        let cellData = updateTemplateValues[templateId][cell.Identifier];
        if (cellData && cellData.template == template) return cellData.data;
      }
    }
  };

  getDynamicDefaultValues = (cell, index, DefaultValue) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = getIdentifiersDynamicValues(
      cell.Identifier,
      this.props.selectedItems[index].id,
      this.props.selectedCategory.id
    );
    this.toggleLoaderArray(
      `${cell.Identifier}_getDynamicDefaultValues_${index}`
    );
    axios
      .get(url)
      .then((res) => {
        if (res.data) {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              // [this.props.selectedItems[index]?.id]: {
              //   ...prevState.mainTemplateQuotation[this.props.selectedItems[index]?.id],
              //   [cell.FieldId]: {
              //     ...prevState.mainTemplateQuotation[
              //       this.props.selectedItems[index]?.id
              //     ][cell.FieldId],
              //     DynamicDefaultValue:
              //       res.data.path[0][0][
              //         `${
              //           cell.Identifier === "BasicHistoricalPrice"
              //             ? "last_basic_rate"
              //             : cell.Identifier === "LandedHistoricalPrice"
              //             ? "landed_cost"
              //             : cell.Identifier === "DateHP" && "update_timestamp"
              //         }`
              //       ],
              //   },
              // },
            },
          }));
        } else {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [this.props.selectedItems[index]?.id]: {
                ...prevState.mainTemplateQuotation[
                  this.props.selectedItems[index]?.id
                ],
                [cell.FieldId]: {
                  ...prevState.mainTemplateQuotation[
                    this.props.selectedItems[index]?.id
                  ][cell.FieldId],
                  DynamicDefaultValue: DefaultValue,
                },
              },
            },
          }));
        }

        this.toggleLoaderArray(
          `${cell.Identifier}_getDynamicDefaultValues_${index}`
        );
      })
      .catch((err) => {
        console.log(err, "getDynamicDefaultValues");
        if (this.props.selectedItems) {
          if (this.props.selectedItems[index]) {
            this.setState((prevState) => ({
              ...prevState,
              mainTemplateQuotation: {
                ...prevState.mainTemplateQuotation,
                [this.props.selectedItems[index]?.id]: {
                  ...prevState.mainTemplateQuotation[
                    this.props.selectedItems[index]?.id
                  ],
                  [cell.FieldId]: {
                    ...prevState.mainTemplateQuotation[
                      this.props.selectedItems[index]?.id
                    ][cell.FieldId],
                    options: [],
                  },
                },
              },
            }));
          }
        }

        this.toggleLoaderArray(
          `${cell.Identifier}_getDynamicDefaultValues_${index}`
        );
      });
  };

  checkIsLinkLocal = (cell) => {
    //first check if the vendor master check is present or not
    if (
      this.props.rfqSettingValues?.vendorMasterPaymentTerm &&
      this.props.rfqSettingValues?.vendorMasterIncoTerm
    ) {
      // check from both
      let str = this.props.companyInfo?.inco_term_identifier;
      let str1 = this.props.companyInfo?.payment_term_identifier;
      if (str) {
        let arr = str.split(";");
        if (arr.includes(cell.Identifier)) {
          return "inco";
        }
      }
      if (str1) {
        let arr1 = str1.split(";");
        if (arr1.includes(cell.Identifier)) {
          return "payment";
        }
      }
      return false;
    } else if (this.props.rfqSettingValues?.vendorMasterPaymentTerm) {
      // paymentterm array
      if (!this.props.companyInfo?.payment_term_identifier) return false;
      let str = this.props.companyInfo?.payment_term_identifier;
      let arr = str.split(";");
      // let arr = ["PaymentType", "PaymentTypeForeign"];
      if (arr.includes(cell.Identifier)) {
        return "payment";
      }
      return false;
    } else if (this.props.rfqSettingValues?.vendorMasterIncoTerm) {
      // incoterm array
      if (!this.props.companyInfo?.inco_term_identifier) return false;
      let str = this.props.companyInfo?.inco_term_identifier;
      let arr = str.split(";");
      // let arr = ["DeliveryBasis", "DeliveryRemarks"];
      if (arr.includes(cell.Identifier)) {
        return "inco";
      }
      return false;
    }
    return false;
  };

  download = () => {
    // setisLoading(true);
    this.setState({
      isLoading: true,
    });
    let destination = this.getDestinationValue();
    const dest = destination?.split("-");
    const code = Object.values(this.props.items).map((item) => item.code);

    // this.props.items.map((item) => {
    //   code.push(item.code);
    // });
    if (destination == "") {
      // dispatch({
      //   type: OPEN_SNACKBAR,
      //   payload: {
      //     type: "error",
      //     msg: "Please select destination first",
      //   },
      // });
      this.setState({
        isLoading: false,
      });
      // setisLoading(false);

      return false;
    }
    axios
      .post(
        downloadmaterialDescription,
        {
          buyerID: this.props.userInfo.division_id,
          pr_dest_code: dest[dest.length - 1],
          items: code,
        },
        {
          responseType: "blob", // Move responseType option inside the configuration object
        }
      )
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        const href = window.webkitURL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Material Description.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        // dispatch({
        //   type: OPEN_SNACKBAR,
        //   payload: {
        //     type: "error",
        //     msg: err?.response?.data.message
        //       ? err?.response?.data.message
        //       : err?.response?.statusText
        //       ? err?.response?.statusText
        //       : "Something went wrong!",
        //   },
        // });
      });
  };

  getFieldLocal = (cell) => {
    let error =
      this.state.additionalInfoTemplate[cell?.FieldId] &&
      this.state.additionalInfoTemplate[cell?.FieldId]["error"];
    let ErrorMessage =
      this.state.additionalInfoTemplate[cell?.FieldId] &&
      this.state.additionalInfoTemplate[cell?.FieldId]["ErrorMessage"];
    let name =
      this.state.additionalInfoTemplate[cell?.FieldId] &&
      this.state.additionalInfoTemplate[cell?.FieldId]["HTMLAttributeName"];
    let value =
      this.state.additionalInfoTemplate[cell?.FieldId] &&
      this.state.additionalInfoTemplate[cell?.FieldId]["value"];
    let isLink = this.checkIsLinkLocal(cell);
    if (isLink) {
      let index1 = this.state.errorArray.indexOf(name);
      if (index1 !== -1) {
        this.state.errorArray.splice(index1, 1);
        this.setState({ errorArray: this.state.errorArray });
      }
      return (
        <React.Fragment>
          <Button
            className="local_template_textfield"
            fullWidth
            variant="outlined"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              border: "1x solid",
              borderColor: (theme) => theme.palette.bluegrey[500],
              padding: "16.5px 14px",
              color: "text.primary",
              fontWeight: 400,
              fontSize:
                "calc(14px - (14 - 16) * ((100vw - 300px) / (1920 - 300)))",
              lineHeight: 1,
              textTransform: "capitalize",
            }}
            onClick={() => {
              this.handleLocalVendorTermsOpen(isLink, cell.FieldId);
            }}
            endIcon={
              <OpenInNew
                sx={{ color: (theme) => theme.palette.primary.main }}
              />
            }
          >
            {cell.FieldLabel}
          </Button>
        </React.Fragment>
      );
    } else if (cell?.ElementType === "F") {
      return (
        <FormControl error={error}>
          <Typography
            component="label"
            variant="body2"
            color={"grey.500"}
            mb={1}
          >
            {`View ${
              cell.FieldLabel +
              (cell.InstructionText ? ` (${cell.InstructionText})` : "")
            }`}
          </Typography>
          <Box>
            <Badge
              badgeContent={`${value?.length ? value?.length : "0"}`}
              color="error"
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  value?.length > 0 && this.handleUploadOpen(value);
                  // this.setState({
                  //   currentFieldId: cell.FieldId,
                  //   dynamicFileUploadOpen: true,
                  //   currentTemplate: "additionalInfoTemplate",
                  //   attachments: value,
                  // });
                }}
                startIcon={
                  <VisibilityIcon sx={{ fontSize: "22px !important" }} />
                }
              >
                {`View ${cell.FieldLabel}`}
              </Button>
            </Badge>
          </Box>
          {/* <Typography mt={1} variant="body2" color={"text.primary"}>Number Of Files {value?.length}</Typography> */}
          <Typography>
            {this.state.additionalInfoTemplate[cell.HTMLAttributeName]}
          </Typography>
          {/* <FormHelperText sx={{ ml: "auto" }}>
                Number Of Files {value?.length}
              </FormHelperText>
            <Typography variant="caption">
              {this.state.additionalInfoTemplate[cell.HTMLAttributeName]}
            </Typography> */}
          {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
        </FormControl>
      );
    } else if (cell.ElementType === "T" && Array.isArray(value)) {
      return (
        <FormControl error={error}>
          <Typography
            component="label"
            variant="body2"
            color={"grey.500"}
            mb={1}
          >
            View Attachment
          </Typography>
          <Box>
            <Badge
              badgeContent={`${value?.length ? value?.length : "0"}`}
              color="error"
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  value?.length > 0 && this.handleUploadOpen(value);
                  // this.setState({
                  //   currentFieldId: cell.FieldId,
                  //   dynamicFileUploadOpen: true,
                  //   currentTemplate: "additionalInfoTemplate",
                  //   attachments: value,
                  // });
                }}
                startIcon={
                  <VisibilityIcon sx={{ fontSize: "22px !important" }} />
                }
              >
                {`View ${cell.FieldLabel}`}
              </Button>
            </Badge>
          </Box>
          {/* <Typography mt={1} variant="body2" color={"text.primary"}>Number Of Files {value?.length}</Typography> */}
          <Typography>
            {this.state.additionalInfoTemplate[cell.HTMLAttributeName]}
          </Typography>
          {/* <FormHelperText sx={{ ml: "auto" }}>
                Number Of Files {value?.length}
              </FormHelperText>
            <Typography variant="caption">
              {this.state.additionalInfoTemplate[cell.HTMLAttributeName]}
            </Typography> */}
          {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
        </FormControl>
      );
    } else if (cell?.ElementType === "DT") {
      return (
        <>
          <Typography component="label" variant="body2" color={"grey.500"}>
            {cell.FieldLabel +
              (cell.ElementType == "T" && cell.InstructionText
                ? ` (${cell.InstructionText})`
                : "")}
          </Typography>
          <Tooltip
            title={
              this.state.DifferenceAdditionalFieldId.hasOwnProperty(
                cell.FieldId
              ) && value
                ? this.state.DifferenceAdditionalFieldId[cell.FieldId]
                  ? `Previous Value : ${dayjs(
                      this.state.DifferenceAdditionalFieldId[cell.FieldId],
                      "YYYY-MM-DD"
                    ).format(`DD/MM/YYYY [IST]`)}`
                  : "Previous Value : N/A"
                : dayjs(value, "YYYY-MM-DD").format(`DD/MM/YYYY [IST]`)
                ? dayjs(value, "YYYY-MM-DD").format(`DD/MM/YYYY [IST]`)
                : "N/A"
            }
            placement="bottom-start"
          >
            <Typography
              variant="body2"
              color={
                this.state.DifferenceAdditionalFieldId.hasOwnProperty(
                  cell.FieldId
                ) && value
                  ? "error.main"
                  : "text.primary"
              }
            >
              {value
                ? dayjs(value, "YYYY-MM-DD").format(`DD/MM/YYYY [IST]`)
                : "N/A"}
            </Typography>
          </Tooltip>
        </>
      );
    } else if (
      (this.props.PermissionInquiry?.is_payment_term === "Y" &&
        this.props.companyInfo.payment_term_identifier
          .split(";")
          .includes(cell.Identifier)) ||
      (this.props.PermissionInquiry?.is_inco_term === "Y" &&
        this.props.companyInfo.inco_term_identifier
          .split(";")
          .includes(cell.Identifier))
    ) {
      return (
        <>
          <Typography component="label" variant="body2" color={"grey.500"}>
            {/* {cell.FieldLabel +
                (cell.ElementType == "T" && cell.InstructionText
                  ? ` (${cell.InstructionText})`
                  : "")} */}
            {cell.FieldLabel +
              (cell.InstructionText ? ` (${cell.InstructionText})` : "")}
          </Typography>
          <Tooltip title={value ? value : "N/A"} placement="bottom-start">
            <Typography variant="body2" color={"text.primary"}>
              {value ? value : "N/A"}
            </Typography>
          </Tooltip>
        </>
      );
    } else if (cell?.ElementType === "S") {
      if (this.props.multiplePlantsFlag && cell.Identifier === "GSTType") {
        // GSTType will not shown in common in case of multiple plants
        return null;
      }
      let prevValue = this.state.DifferenceAdditionalFieldId[cell.FieldId];
      if (cell?.DefaultValue?.length > 0) {
        let dropdownName = cell?.DefaultValue.filter((option) => {
          return option.code === value;
        });
        if (dropdownName.length > 0) {
          value = dropdownName[0]?.name;
        }
        let dropdownPrevName = cell?.DefaultValue.filter((option) => {
          return option.code === prevValue;
        });
        if (dropdownPrevName.length > 0) {
          prevValue = dropdownPrevName[0]?.name;
        }
      }
      let sameCodeNameValue = "";
      if (value) {
        // console.log('value', (value?.split("-").length > 2 && (value?.split("-").length % 2) == 0) ?  : 'false', value?.split("-"))
        if (value?.split("-").length > 2 && value?.split("-").length % 2 == 0) {
          let chunkVal = _.chunk(
            value?.split("-"),
            value?.split("-").length / 2
          );
          sameCodeNameValue =
            JSON.stringify(chunkVal[0]) === JSON.stringify(chunkVal[1])
              ? chunkVal[0].join("-")
              : value;
        } else if (
          value &&
          value.split(/-(.+)/)[0] == value.split(/-(.+)/)[1]
        ) {
          sameCodeNameValue = value.split(/-(.+)/)[0];
        } else {
          sameCodeNameValue = value;
        }
      } else {
        sameCodeNameValue = "N/A";
      }
      return (
        <>
          <Typography component="label" variant="body2" color={"grey.500"}>
            {cell.FieldLabel +
              (cell.InstructionText ? ` (${cell.InstructionText})` : "")}
          </Typography>

          <Tooltip
            title={
              this.state.DifferenceAdditionalFieldId.hasOwnProperty(
                cell.FieldId
              ) && value
                ? this.state.DifferenceAdditionalFieldId[cell.FieldId]
                  ? `Previous Value : ${
                      prevValue ||
                      this.state.DifferenceAdditionalFieldId[cell.FieldId]
                    }`
                  : "Previous Value : N/A"
                : sameCodeNameValue
            }
            placement="bottom-start"
          >
            <Typography
              variant="body2"
              color={
                this.state.DifferenceAdditionalFieldId.hasOwnProperty(
                  cell.FieldId
                ) && value
                  ? "error.main"
                  : "text.primary"
              }
            >
              {sameCodeNameValue}
            </Typography>
          </Tooltip>
        </>
      );
    } else {
      if (
        (cell.Identifier === "Destination" ||
          cell.Identifier === "DestinationForeign") &&
        value?.includes("###")
      ) {
        return null;
      } else {
        return (
          <>
            <Typography component="label" variant="body2" color={"grey.500"}>
              {/* {cell.FieldLabel +
                (cell.ElementType == "T" && cell.InstructionText
                  ? ` (${cell.InstructionText})`
                  : "")} */}
              {cell.FieldLabel +
                (cell.InstructionText ? ` (${cell.InstructionText})` : "")}
            </Typography>
            <Tooltip
              title={
                this.state.DifferenceAdditionalFieldId.hasOwnProperty(
                  cell.FieldId
                ) && value
                  ? this.state.DifferenceAdditionalFieldId[cell.FieldId]
                    ? `Previous Value : ${
                        this.state.DifferenceAdditionalFieldId[cell.FieldId]
                      }`
                    : "Previous Value : N/A"
                  : value
                  ? value
                  : "N/A"
              }
              placement="bottom-start"
            >
              <Typography
                variant="body2"
                color={
                  this.state.DifferenceAdditionalFieldId.hasOwnProperty(
                    cell.FieldId
                  ) && value
                    ? "error.main"
                    : "text.primary"
                }
              >
                {value ? value : "N/A"}
              </Typography>
            </Tooltip>
          </>
        );
      }
      // return cell?.FieldLabel;
    }
  };

  finddependacy = (FieldId, validation) => {
    let field_ids = validation.match(/f(\d)*/g);
    let is_dependacy = 0;
    for (const field_id of field_ids) {
      if ("f" + FieldId != field_id) {
        is_dependacy = 1;
        break;
      }
    }
    return is_dependacy;
  };

  validateInputRFQ = (FieldId, submitting) => {
    if (submitting) {
      if (
        this.state.RFQDynamic[FieldId]["error"] &&
        !this.finddependacy(
          FieldId,
          this.state.RFQDynamic[FieldId]["Validation"]
        )
      )
        return;
    }
    if (this.state.RFQDynamic[FieldId]?.["Validation"]) {
      let temp = this.state.RFQDynamic[FieldId]["Validation"].replace(
        /f(\d)*/g,
        (match) => {
          if (this.state.RFQDynamic[match.replace("f", "")]) {
            return `this.state.RFQDynamic[${match.replace("f", "")}]["value"]`;
          } else if (
            this.state.additionalInfoTemplate[match.replace("f", "")]
          ) {
            return `this.state.additionalInfoTemplate[${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else if (this.state.IntlTemplate[match.replace("f", "")]) {
            return `this.state.IntlTemplate[${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else {
            return null;
          }
        }
      );
      if (temp !== null) {
        if (this.state.RFQDynamic[FieldId]["ElementType"] == "DT") {
          this.state.RFQDynamic[FieldId]["value"] = moment(
            this.state.RFQDynamic[FieldId]["value"]
          ).format("YYYY-MM-DD");
        }
        if (eval(temp)) {
          this.setState((prevState) => ({
            ...prevState,
            RFQDynamic: {
              ...prevState.RFQDynamic,
              [FieldId]: {
                ...prevState.RFQDynamic[FieldId],
                error: false,
              },
            },
          }));

          let index1 = this.state.errorArray.indexOf(
            this.state.RFQDynamic[FieldId]["HTMLAttributeName"]
          );
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }
        } else {
          this.setState((prevState) => ({
            ...prevState,
            RFQDynamic: {
              ...prevState.RFQDynamic,
              [FieldId]: {
                ...prevState.RFQDynamic[FieldId],
                error: true,
              },
            },
          }));

          if (
            !this.state.errorArray.includes(
              this.state.RFQDynamic[FieldId]["HTMLAttributeName"]
            )
          ) {
            this.state.errorArray.push(
              this.state.RFQDynamic[FieldId]["HTMLAttributeName"]
            );
          }
        }
        this.setState({
          errorArray: this.state.errorArray,
        });
      } else {
        console.log("dependent field not found in template");
      }
    }
  };

  validateInputLocal = (FieldId, submitting) => {
    //function name has to be change
    if (submitting) {
      if (this.state.additionalInfoTemplate[FieldId]["error"]) {
        if (
          !this.state.errorArray.includes(
            this.state.additionalInfoTemplate[FieldId]["HTMLAttributeName"]
          )
        ) {
          this.state.errorArray.push(
            this.state.additionalInfoTemplate[FieldId]["HTMLAttributeName"]
          );
        }
        return;
      }
    }
    // if (this?.state?.additionalInfoTemplate[FieldId]["Validation"]) {
    //   let temp = this.state?.additionalInfoTemplate[FieldId]["Validation"].replace(
    //     /f(\d)+/g,
    //     (match) => {
    //       if (this.state.additionalInfoTemplate[match.replace("f", "")]) {
    //         return `this.state.additionalInfoTemplate[${match.replace(
    //           "f",
    //           ""
    //         )}]["value"]`;
    //       } else if (this.state.IntlTemplate[match.replace("f", "")]) {
    //         return `this.state.IntlTemplate[${match.replace(
    //           "f",
    //           ""
    //         )}]["value"]`;
    //       } else {
    //         return null;
    //       }
    //     }
    //   );
    //   if (temp !== null) {
    //     if (eval(temp)) {
    //       this.setState((prevState) => ({
    //         ...prevState,
    //         additionalInfoTemplate: {
    //           ...prevState.additionalInfoTemplate,
    //           [FieldId]: {
    //             ...prevState.additionalInfoTemplate[FieldId],
    //             error: false,
    //           },
    //         },
    //       }));
    //       let index1 = this.state.errorArray.indexOf(
    //         this.state.additionalInfoTemplate[FieldId]["HTMLAttributeName"]
    //       );
    //       if (index1 !== -1) {
    //         this.state.errorArray.splice(index1, 1);
    //       }
    //     } else {
    //       this.setState((prevState) => ({
    //         ...prevState,
    //         additionalInfoTemplate: {
    //           ...prevState.additionalInfoTemplate,
    //           [FieldId]: {
    //             ...prevState.additionalInfoTemplate[FieldId],
    //             error: true,
    //           },
    //         },
    //       }));
    //       if (
    //         !this.state.errorArray.includes(
    //           this.state.additionalInfoTemplate[FieldId]["HTMLAttributeName"]
    //         )
    //       ) {
    //         this.state.errorArray.push(
    //           this.state.additionalInfoTemplate[FieldId]["HTMLAttributeName"]
    //         );
    //       }
    //     }
    //     this.setState({
    //       errorArray: this.state.errorArray,
    //     });
    //   } else {
    //     console.log("dependent field not found in template");
    //   }
    // }
  };

  initializeRFQDynamicState = () => {
    let row = {};
    let cellsToUpdate = [];
    this.props.quotationJsonTemplate?.COMMON?.forEach((cell) => {
      row[cell.FieldId] = {
        HTMLAttributeName: cell.HTMLAttributeName,
        value: "",
        Validation: cell.Validation,
        FieldLabel: cell.FieldLabel,
        Identifier: cell.Identifier,
        error: false,
        ErrorMessage: cell.ErrorMessage,
        ElementType: cell.ElementType,
        InstructionText: cell.InstructionText,
      };
      if (cell.ElementType === "S") {
        if (cell.DropdownEdit === "Y") {
          row[cell.FieldId]["options"] = "loading";
          cellsToUpdate.push(cell);
          let optionsData = this.getOptionsFromRedux(cell, "rfq");
          //getting options data from redux if it is not available then calling api
          if (!optionsData) {
            // this.getOptionsFromApi(cell, "rfq");
          }
          // this.getOptionsFromApi(cell, "rfq");
        } else {
          row[cell.FieldId]["options"] = cell.DefaultValue;
        }
      } else {
        row[cell.FieldId]["value"] = cell.DefaultValue || "";
      }
      if (cell.ElementType === "T") {
        row[cell.FieldId]["value"] = cell.DefaultValue;
      }

      let readOnly = false;
      // let value = row[cell.FieldId]["value"];

      if (
        Object.keys(this.props.prlineitems).length &&
        prconfig &&
        prconfig[cell.Identifier] !== undefined
      ) {
        row[cell.FieldId]["value"] =
          eval(this[prconfig[cell.Identifier]?.function_name])(
            cell.Identifier,
            this.props.selectedItems[0]
          ) || "";
        if (cell.ElementType === "S" && cell.DropdownEdit !== "Y") {
          Object.keys(row[cell.FieldId]["options"]).map((key) => {
            const plant_arr =
              row[cell.FieldId]["options"][key]["code"].split("-");
            if (plant_arr[plant_arr.length - 1] == row[cell.FieldId]["value"]) {
              row[cell.FieldId]["value"] =
                row[cell.FieldId]["options"][key]["code"];
            }
          });
        }
        if (
          prconfig[cell.Identifier]?.non_editable_function &&
          prconfig[cell.Identifier]?.non_editable_function !== "0"
        ) {
          if (prconfig[cell.Identifier]?.non_editable_function == "1") {
            readOnly = true;
          } else {
            readOnly = eval(
              this[prconfig[cell.Identifier]?.non_editable_function]
            )(row[cell.FieldId]["value"]);
          }
        }
      }
      row[cell.FieldId]["readOnly"] = readOnly;
      row[cell.FieldId]["DefaultValue"] = cell["DefaultValue"];
    });
    this.setState(
      {
        RFQDynamic: row,
      },
      () => {
        //getting options data from redux if it is available setting rfq data
        cellsToUpdate.map((cell) => {
          let optionsData = this.getOptionsFromRedux(cell, "rfq");
          if (cell.ElementType === "S") {
            if (cell.DropdownEdit === "Y") {
              if (optionsData) {
                let value = this.state.RFQDynamic[cell.FieldId]["value"];
                if (value) {
                  if (optionsData) {
                    optionsData.forEach((option, id) => {
                      if (option.dropdown_element_code === value) {
                        value = `${option.dropdown_element_code}-${option.dropdown_element_name}`;
                        if (
                          this.props.companyInfo?.default_dropdown_type ===
                            "10" ||
                          cell.Identifier === "Destination" ||
                          cell.Identifier === "DestinationForeign"
                        ) {
                          value = `${option.dropdown_element_name}-${option.dropdown_element_code}`;
                        }
                      }
                    });
                    this.setState((prevState) => ({
                      ...prevState,
                      RFQDynamic: {
                        ...prevState.RFQDynamic,
                        [cell.FieldId]: {
                          ...prevState.RFQDynamic[cell.FieldId],
                          options: optionsData,
                          value,
                        },
                      },
                    }));
                  }
                } else {
                  this.setState((prevState) => ({
                    ...prevState,
                    RFQDynamic: {
                      ...prevState.RFQDynamic,
                      [cell.FieldId]: {
                        ...prevState.RFQDynamic[cell.FieldId],
                        options: optionsData,
                      },
                    },
                  }));
                }
              }
            }
          }
        });
      }
    );
  };

  validateInput = (FieldId, index, submitting) => {
    if (submitting) {
      if (
        this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
          FieldId
        ] &&
        this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
          FieldId
        ]["error"] &&
        this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
          FieldId
        ]["Validation"] &&
        !this.finddependacy(
          FieldId,
          this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
            FieldId
          ]["Validation"]
        )
      ) {
        if (
          !this.state.errorArray.includes(
            this.state.mainTemplateQuotation[
              this.props.selectedItems[index].id
            ][FieldId]["HTMLAttributeName"]
          )
        ) {
          this.state.errorArray.push(
            this.state.mainTemplateQuotation[
              this.props.selectedItems[index].id
            ][FieldId]["HTMLAttributeName"]
          );
          this.setState({
            errorArray: this.state.errorArray,
          });
        }
        return;
      }
    }
    if (
      this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
        FieldId
      ] &&
      this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
        FieldId
      ]["Validation"]
    ) {
      let temp = this.state.mainTemplateQuotation[
        this.props.selectedItems[index].id
      ][FieldId]["Validation"].replace(/f(\d)+/g, (match) => {
        if (
          this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
            match.replace("f", "")
          ]
        ) {
          return `this.state.mainTemplateQuotation[${
            this.props.selectedItems[index].id
          }][${match.replace("f", "")}]["value"]`;
        } else if (this.state.additionalInfoTemplate[match.replace("f", "")]) {
          return `this.state.additionalInfoTemplate[${match.replace(
            "f",
            ""
          )}]["value"]`;
        } else if (this.state.IntlTemplate[match.replace("f", "")]) {
          return `this.state.IntlTemplate[${match.replace("f", "")}]["value"]`;
        } else {
          return null;
        }
      });
      if (temp !== null) {
        if (eval(temp)) {
          // this.state.mainTemplateQuotation[this.props.selectedItems[index].id][FieldId][
          //   "error"
          // ] = false;

          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              // [this.props.selectedItems[index]?.id]: {
              //   ...prevState.mainTemplateQuotation[this.props.selectedItems[index]?.id],
              //   [FieldId]: {
              //     ...prevState.mainTemplateQuotation[
              //       this.props.selectedItems[index]?.id
              //     ][FieldId],
              //     error: false,
              //   },
              // },
            },
          }));

          let index1 = this.state.errorArray.indexOf(
            this.state.mainTemplateQuotation[
              this.props.selectedItems[index].id
            ][FieldId]["HTMLAttributeName"]
          );
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }
        } else {
          // this.state.mainTemplateQuotation[this.props.selectedItems[index].id][FieldId][
          //   "error"
          // ] = true;

          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [this.props.selectedItems[index]?.id]: {
                ...prevState.mainTemplateQuotation[
                  this.props.selectedItems[index]?.id
                ],
                [FieldId]: {
                  ...prevState.mainTemplateQuotation[
                    this.props.selectedItems[index]?.id
                  ][FieldId],
                  error: true,
                },
              },
            },
          }));

          if (
            !this.state.errorArray.includes(
              this.state.mainTemplateQuotation[
                this.props.selectedItems[index].id
              ][FieldId]["HTMLAttributeName"]
            )
          ) {
            this.state.errorArray.push(
              this.state.mainTemplateQuotation[
                this.props.selectedItems[index].id
              ][FieldId]["HTMLAttributeName"]
            );
          }
        }
        this.setState({
          // mainTemplateQuotation: this.state.mainTemplateQuotation,
          errorArray: this.state.errorArray,
        });
      } else {
        console.log("dependent field not found in template");
      }
    }
  };

  validateInputRFQStatic = (FieldId) => {
    if (FieldId === "RFQValidity") {
      if (this.state.RFQStatic.RFQValidity.value === "") {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            RFQValidity: {
              ...prevState.RFQStatic.RFQValidity,
              error: true,
            },
          },
        }));
        if (
          !this.state.errorArray.includes(
            this.state.RFQStatic.RFQValidity["HTMLAttributeName"]
          )
        ) {
          this.state.errorArray.push(
            this.state.RFQStatic.RFQValidity["HTMLAttributeName"]
          );
        }
      } else {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            RFQValidity: {
              ...prevState.RFQStatic.RFQValidity,
              error: false,
            },
          },
        }));
        let index1 = this.state.errorArray.indexOf(
          this.state.RFQStatic.RFQValidity["HTMLAttributeName"]
        );
        if (index1 !== -1) {
          this.state.errorArray.splice(index1, 1);
        }
      }
    }
    if (FieldId === "TBValidity") {
      if (this.state.RFQStatic.TBValidity.value === "") {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            TBValidity: {
              ...prevState.RFQStatic.TBValidity,
              error: true,
            },
          },
        }));
        if (
          !this.state.errorArray.includes(
            this.state.RFQStatic.TBValidity["HTMLAttributeName"]
          )
        ) {
          this.state.errorArray.push(
            this.state.RFQStatic.TBValidity["HTMLAttributeName"]
          );
        }
      } else {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            TBValidity: {
              ...prevState.RFQStatic.TBValidity,
              error: false,
            },
          },
        }));
        let index1 = this.state.errorArray.indexOf(
          this.state.RFQStatic.TBValidity["HTMLAttributeName"]
        );
        if (index1 !== -1) {
          this.state.errorArray.splice(index1, 1);
        }
      }
    }
    this.setState({
      errorArray: this.state.errorArray,
    });
  };

  handleLocalChange = (e, FieldId) => {
    const { value, type, checked } = e.target;

    const { additionalInfoTemplate } = this.state;
    // additionalInfoTemplate[FieldId]["value"] = type !== "checkbox" ? value : checked;
    // e.target.blur();
    this.setState({
      additionalInfoTemplate,
    });
  };

  setTermValue = (value, vendorType, FieldId) => {
    const { additionalInfoTemplate, IntlTemplate } = this.state;
    if (vendorType === true) {
      additionalInfoTemplate[FieldId]["value"] = "Hello";
      this.setState({
        additionalInfoTemplate,
      });
    } else {
      IntlTemplate[FieldId]["value"] = value;
      this.setState({
        IntlTemplate,
      });
    }
  };

  setTermError = () =>
    this.state.vendorTerms === "inco"
      ? this.setState({ incoTermError: true })
      : this.setState({ paymentTermError: true });

  setAttachmentsValue = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      [this.state.currentTemplate]: {
        ...prevState[this.state.currentTemplate],
        [this.state.currentFieldId]: {
          ...prevState[this.state.currentTemplate][this.state.currentFieldId],
          value: this.state[this.state.currentTemplate][
            this.state.currentFieldId
          ]["value"]
            ? [
                ...this.state[this.state.currentTemplate][
                  this.state.currentFieldId
                ]["value"],
                ...value,
              ]
            : value,
        },
      },
    }));
  };

  handleIntlChange = (e, FieldId) => {
    const { value, type, checked } = e.target;
    const { IntlTemplate } = this.state;
    IntlTemplate[FieldId]["value"] = type !== "checkbox" ? value : checked;
    // e.target.blur();
    this.setState({
      IntlTemplate,
    });
  };
  //added val for autocomplete multiplant
  handleRFQDynamicChange = (e, FieldId, val) => {
    const { RFQDynamic } = this.state;
    if (val) {
      RFQDynamic[FieldId]["value"] = val;
    } else {
      const { value, type, checked } = e.target;
      RFQDynamic[FieldId]["value"] = type !== "checkbox" ? value : checked;
    }
    if (RFQDynamic[FieldId]["Identifier"] === "LDDApplicable") {
      for (const common_field_id in RFQDynamic) {
        if (RFQDynamic[common_field_id]["Identifier"] === "LateDeliveryC") {
          RFQDynamic[common_field_id]["value"] =
            RFQDynamic[FieldId]["value"] == "Yes"
              ? RFQDynamic[common_field_id]["DefaultValue"]
              : "";
          break;
        }
      }
    }
    // e.target.blur();
    this.setState(
      {
        RFQDynamic,
      },
      () => {
        this.validateInputRFQ(FieldId);
      }
    );
  };

  handleRFQStaticChange = (e) => {
    const { name, value, type, checked } = e.target;

    const { RFQStatic } = this.state;
    RFQStatic[name]["value"] = type !== "checkbox" ? value : checked;
    // e.target.blur();
    this.setState({
      RFQStatic,
    });
    if (name === "RFQCCUsersmails") {
      let flag = false;
      if (value?.trim() === "") {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            RFQUpdateCCUsersCheck: {
              ...prevState.RFQStatic.RFQUpdateCCUsersCheck,
              value: false,
            },
          },
        }));
      } else {
        value
          ?.trim()
          .split(",")
          .forEach((email) => {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
              flag = true;
            }
          });
      }

      this.setState((prevState) => ({
        ...prevState,
        RFQStatic: {
          ...prevState.RFQStatic,
          RFQCCUsersmails: {
            ...prevState.RFQStatic.RFQCCUsersmails,
            error: flag,
          },
        },
      }));

      if (flag) {
        if (!this.state.errorArray.includes("RFQCCUsersmails")) {
          this.state.errorArray.push("RFQCCUsersmails");
        }
      } else {
        let index1 = this.state.errorArray.indexOf("RFQCCUsersmails");
        if (index1 !== -1) {
          this.state.errorArray.splice(index1, 1);
        }
      }
      this.setState({
        errorArray: this.state.errorArray,
      });
    }
  };

  handleDateChange = (value, name, state, index, Validation) => {
    if (!this.props.quotationFormFlag) {
      if (state === "mainTemplateQuotation") {
        if (
          value &&
          this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
            name
          ]["value"] !== value
        ) {
          this.setState({
            applyToAll: {
              ...this.state.applyToAll,
              [`${name}_applyToAll`]: false,
              [`${name}_applyToAll`]: false,
              [`${name}_applyToAll`]: false,
            },
          });
        }
        this.setState((prevState) => ({
          ...prevState,
          mainTemplateQuotation: {
            ...prevState.mainTemplateQuotation,
            [this.props.selectedItems[index]?.id]: {
              ...prevState.mainTemplateQuotation[
                this.props.selectedItems[index]?.id
              ],
              [name]: {
                ...prevState.mainTemplateQuotation[
                  this.props.selectedItems[index]?.id
                ][name],
                value: value ? value : "",
              },
            },
          },
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          [state]: {
            ...prevState[state],
            [name]: {
              ...prevState[state][name],
              value:
                new Date(value) && new Date(value._d) ? new Date(value._d) : "",
            },
          },
        }));
        if (name === "RFQValidity") {
          this.setState((prevState) => {
            return {
              ...prevState,
              RFQStatic: {
                ...prevState.RFQStatic,
                RFQValidityTime: {
                  ...prevState.RFQStatic.RFQValidityTime,
                  error: false,
                },
              },
            };
          });
        }
        if (name === "TBValidity") {
          this.setState((prevState) => {
            return {
              ...prevState,
              RFQStatic: {
                ...prevState.RFQStatic,
                TBValidityTime: {
                  ...prevState.RFQStatic.TBValidityTime,
                  error: false,
                },
              },
            };
          });
        }
        // if (name === 'RFQValidity') {
        //   let time;
        //   if (moment(value).isSame(moment(), 'date')) {
        //     var ch = new Date().getHours();
        //     var cm = new Date().getMinutes();
        //     if (cm > 30) {
        //       ch = ch + 1;
        //       cm = 0;
        //     } else {
        //       cm = 30;
        //     }
        //     time = `${ch > 9 ? ch : `0${ch}`}:${cm > 9 ? cm : `0${cm}`}`
        //     // time = ((moment('').hour() + 1) + '').length === 1 ? `0${(moment().hour() + 1)}:00` : `${(moment().hour() + 1)}:00`
        //   } else {
        //     time = "00:00"
        //   }
        //   this.setState((prevState) => {
        //     return {
        //       ...prevState,
        //       RFQStatic: {
        //         ...prevState.RFQStatic,
        //         RFQValidityTime: {
        //           ...prevState.RFQStatic.RFQValidityTime,
        //           value: time,
        //         },
        //       },
        //     };
        //   })
        // }
      }
      if (
        !(Validation?.trim() === "" && value === null) &&
        !(
          moment(value).isValid() &&
          (name.match(/Validity/i)
            ? moment(value).isSame(moment(), "day") ||
              moment(value).isAfter(moment())
            : 1)
        )
      ) {
        if (state === "mainTemplateQuotation") {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [this.props.selectedItems[index]?.id]: {
                ...prevState.mainTemplateQuotation[
                  this.props.selectedItems[index]?.id
                ],
                [name]: {
                  ...prevState.mainTemplateQuotation[
                    this.props.selectedItems[index]?.id
                  ][name],
                  error: true,
                },
              },
            },
          }));
          if (
            !this.state.errorArray.includes(
              this.state.mainTemplateQuotation[
                this.props.selectedItems[index].id
              ][name]["HTMLAttributeName"]
            )
          ) {
            this.state.errorArray.push(
              this.state.mainTemplateQuotation[
                this.props.selectedItems[index].id
              ][name]["HTMLAttributeName"]
            );
            this.setState({
              errorArray: this.state.errorArray,
            });
          }
        } else {
          this.setState((prevState) => ({
            ...prevState,
            [state]: {
              ...prevState[state],
              [name]: {
                ...prevState[state][name],
                error: true,
              },
            },
          }));
        }
      } else {
        if (state === "mainTemplateQuotation") {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [this.props.selectedItems[index]?.id]: {
                ...prevState.mainTemplateQuotation[
                  this.props.selectedItems[index]?.id
                ],
                [name]: {
                  ...prevState.mainTemplateQuotation[
                    this.props.selectedItems[index]?.id
                  ][name],
                  error: false,
                },
              },
            },
          }));
          let index1 = this.state.errorArray.indexOf(
            this.state.mainTemplateQuotation[
              this.props.selectedItems[index].id
            ][name]["HTMLAttributeName"]
          );
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }
        } else {
          this.setState((prevState) => ({
            ...prevState,
            [state]: {
              ...prevState[state],
              [name]: {
                ...prevState[state][name],
                error: false,
              },
            },
          }));
        }
      }
      if (name === "RFQValidity" && value) {
        if (
          this.props.featurePermission?.max_rfq_validity_days?.feature_value ===
          "Y"
        ) {
          let max =
            this.props.featurePermission?.max_rfq_validity_days
              ?.feature_permission_value;
          let given = moment(value);
          let current = moment().startOf("day");
          let result = moment.duration(given.diff(current)).asDays();
          if (max) {
            if (result > parseFloat(max)) {
              this.setState((prevState) => ({
                ...prevState,
                [state]: {
                  ...prevState[state],
                  [name]: {
                    ...prevState[state][name],
                    error: true,
                  },
                },
              }));
              if (!this.state.errorArray.includes(name)) {
                this.state.errorArray.push(name);
                this.setState({
                  errorArray: this.state.errorArray,
                });
              }
              this.props.openSnackbar(
                "error",
                `Maximum RFQ Validity is ${max} days`
              );
            } else {
              let index1 = this.state.errorArray.indexOf(name);
              if (index1 !== -1) {
                this.state.errorArray.splice(index1, 1);
              }
              this.setState({
                errorArray: this.state.errorArray,
              });
            }
          }
        }
      }
    } else {
      if (state === "mainTemplateQuotation") {
        if (
          value &&
          this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
            name
          ]["value"] !== value
        ) {
          this.setState({
            applyToAll: {
              ...this.state.applyToAll,
              [`${name}_applyToAll`]: false,
              [`${name}_applyToAll`]: false,
              [`${name}_applyToAll`]: false,
            },
          });
        }
        this.setState((prevState) => ({
          ...prevState,
          mainTemplateQuotation: {
            ...prevState.mainTemplateQuotation,
            [this.props.selectedItems[index]?.id]: {
              ...prevState.mainTemplateQuotation[
                this.props.selectedItems[index]?.id
              ],
              [name]: {
                ...prevState.mainTemplateQuotation[
                  this.props.selectedItems[index]?.id
                ][name],
                value: value ? value : "",
              },
            },
          },
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          [state]: {
            ...prevState[state],
            [name]: {
              ...prevState[state][name],
              value:
                new Date(value) && new Date(value._d) ? new Date(value._d) : "",
            },
          },
        }));
        if (name === "RFQValidity") {
          this.setState((prevState) => {
            return {
              ...prevState,
              RFQStatic: {
                ...prevState.RFQStatic,
                RFQValidityTime: {
                  ...prevState.RFQStatic.RFQValidityTime,
                  error: false,
                },
              },
            };
          });
        }
        if (name === "TBValidity") {
          this.setState((prevState) => {
            return {
              ...prevState,
              RFQStatic: {
                ...prevState.RFQStatic,
                TBValidityTime: {
                  ...prevState.RFQStatic.TBValidityTime,
                  error: false,
                },
              },
            };
          });
        }
        // if (name === 'RFQValidity') {
        //   let time;
        //   if (moment(value).isSame(moment(), 'date')) {
        //     var ch = new Date().getHours();
        //     var cm = new Date().getMinutes();
        //     if (cm > 30) {
        //       ch = ch + 1;
        //       cm = 0;
        //     } else {
        //       cm = 30;
        //     }
        //     time = `${ch > 9 ? ch : `0${ch}`}:${cm > 9 ? cm : `0${cm}`}`
        //     // time = ((moment('').hour() + 1) + '').length === 1 ? `0${(moment().hour() + 1)}:00` : `${(moment().hour() + 1)}:00`
        //   } else {
        //     time = "00:00"
        //   }
        //   this.setState((prevState) => {
        //     return {
        //       ...prevState,
        //       RFQStatic: {
        //         ...prevState.RFQStatic,
        //         RFQValidityTime: {
        //           ...prevState.RFQStatic.RFQValidityTime,
        //           value: time,
        //         },
        //       },
        //     };
        //   })
        // }
      }
      if (
        !(Validation?.trim() === "" && value === null) &&
        !(
          moment(value).isValid() &&
          (name.match(/Validity/i)
            ? moment(value).isSame(moment(), "day") ||
              moment(value).isAfter(moment())
            : 1)
        )
      ) {
        if (state === "mainTemplateQuotation") {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [this.props.selectedItems[index]?.id]: {
                ...prevState.mainTemplateQuotation[
                  this.props.selectedItems[index]?.id
                ],
                [name]: {
                  ...prevState.mainTemplateQuotation[
                    this.props.selectedItems[index]?.id
                  ][name],
                  error: true,
                },
              },
            },
          }));
          if (
            !this.state.errorArray.includes(
              this.state.mainTemplateQuotation[
                this.props.selectedItems[index].id
              ][name]["HTMLAttributeName"]
            )
          ) {
            this.state.errorArray.push(
              this.state.mainTemplateQuotation[
                this.props.selectedItems[index].id
              ][name]["HTMLAttributeName"]
            );
            this.setState({
              errorArray: this.state.errorArray,
            });
          }
        } else {
          this.setState((prevState) => ({
            ...prevState,
            [state]: {
              ...prevState[state],
              [name]: {
                ...prevState[state][name],
                error: true,
              },
            },
          }));
        }
      } else {
        if (state === "mainTemplateQuotation") {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [this.props.selectedQuotedItems.items[index]?.id]: {
                ...prevState.mainTemplateQuotation[
                  this.props.selectedQuotedItems.items[index]?.id
                ],
                [name]: {
                  ...prevState.mainTemplateQuotation[
                    this.props.selectedQuotedItems.items[index]?.id
                  ][name],
                  error: false,
                },
              },
            },
          }));
          let index1 = this.state.errorArray.indexOf(
            this.state.mainTemplateQuotation[
              this.props.selectedQuotedItems.items[index].id
            ][name]["HTMLAttributeName"]
          );
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }
        } else {
          this.setState((prevState) => ({
            ...prevState,
            [state]: {
              ...prevState[state],
              [name]: {
                ...prevState[state][name],
                error: false,
              },
            },
          }));
        }
      }
      if (name === "RFQValidity" && value) {
        if (
          this.props.featurePermission?.max_rfq_validity_days?.feature_value ===
          "Y"
        ) {
          let max =
            this.props.featurePermission?.max_rfq_validity_days
              ?.feature_permission_value;
          let given = moment(value);
          let current = moment().startOf("day");
          let result = moment.duration(given.diff(current)).asDays();
          if (max) {
            if (result > parseFloat(max)) {
              this.setState((prevState) => ({
                ...prevState,
                [state]: {
                  ...prevState[state],
                  [name]: {
                    ...prevState[state][name],
                    error: true,
                  },
                },
              }));
              if (!this.state.errorArray.includes(name)) {
                this.state.errorArray.push(name);
                this.setState({
                  errorArray: this.state.errorArray,
                });
              }
              this.props.openSnackbar(
                "error",
                `Maximum RFQ Validity is ${max} days`
              );
            } else {
              let index1 = this.state.errorArray.indexOf(name);
              if (index1 !== -1) {
                this.state.errorArray.splice(index1, 1);
              }
              this.setState({
                errorArray: this.state.errorArray,
              });
            }
          }
        }
      }
    }
  };

  handleLocalVendorRecipintsOpen = () => {
    this.setState({
      vendorRecipintType: "additionalInfo",
      vendorRecipintOpen: true,
    });
  };

  handleIntlVendorRecipintsOpen = () => {
    this.setState({
      vendorRecipintType: "foreign",
      vendorRecipintOpen: true,
    });
  };

  handleVendorRecipintsClose = () => {
    this.setState({
      vendorRecipintOpen: false,
    });
  };

  handleLocalVendorTermsOpen = (term, currentFieldID) => {
    this.setState({
      vendorType: "additionalInfo",
      vendorTerms: term,
      vendorTermsOpen: true,
      currentFieldID,
    });
  };

  handleIntlVendorTermsOpen = (term, currentFieldID) => {
    this.setState({
      vendorType: "foreign",
      vendorTerms: term,
      vendorTermsOpen: true,
      currentFieldID,
    });
  };

  handleVendorTermsClose = () => {
    this.setState({
      vendorTermsOpen: false,
      currentFieldID: "",
    });
  };

  allItemsHasVendors = () => {
    let result = true;
    this.props.selectedItems.forEach((item) => {
      if (!item.vendor_count?.total_count) {
        result = false;
      }
    });
    return result;
  };

  handleRFQSubmit = (event) => {
    if (this.props.location?.pathname.indexOf("/prcombination") > -1) {
      event.preventDefault();
    }

    let errCount = 0;
    if (this.props.itemizeTB) {
      if (Object.keys(this.props.checkTechnicalBidItems).length > 0) {
        Object.keys(this.props.checkTechnicalBidItems).forEach((key, i) => {
          if (this.props.checkTechnicalBidItems[key] === "ERROR") {
            errCount++;
          }
        });
      }
    } else {
      if (Object.keys(this.props.checkTechinicalBidCategory).length > 0) {
        Object.keys(this.props.checkTechinicalBidCategory).forEach((key) => {
          if (this.props.checkTechinicalBidCategory[key] === "ERROR") {
            errCount++;
          }
        });
      }
    }

    if (this.props.includeTB && errCount > 0) {
      this.props.openSnackbar(
        "error",
        "Please fill technical bid template values for Buyer."
      );
      return;
    }

    let finalData = {};
    finalData.specification_files = {
      category: [],
      item: {},
    };
    if (this.props.categorySpecFiles.length > 0) {
      this.props.categorySpecFiles.forEach((file, i) => {
        finalData.specification_files["category"].push(file.id);
      });
    }

    if (Object.keys(this.props.itemSpecificFiles).length > 0) {
      Object.keys(this.props.itemSpecificFiles).forEach((itemNo, i) => {
        if (this.props.itemSpecificFiles[itemNo].length > 0) {
          finalData.specification_files.item[itemNo] = [];
          this.props.itemSpecificFiles[itemNo].forEach((file, i) => {
            finalData.specification_files.item[itemNo].push(file.id);
          });
        }
      });
    }

    if (
      this.props.featurePermission?.project_selection?.feature_value === "Y"
    ) {
      if (!this.props.rfqSettingValues?.projects?.length) {
        this.props.openSnackbar("error", "Please select the Project(s)");
        return;
      }
    }
    this.setState({
      rfqSubmitLoading: true,
    });
    if (this.props.master_type === "category") {
      if (!this.props.selectedVendors?.length) {
        this.setState({
          rfqSubmitLoading: false,
        });
        this.props.openSnackbar(
          "error",
          "Please select atleast one vendor to continue"
        );
        return;
      }
    } else {
      if (!this.allItemsHasVendors()) {
        this.props.openSnackbar(
          "error",
          "Please remove items with no vendors to continue"
        );
        return;
      }
    }

    if (this.props?.includeTB) {
      let tb_date = this.state.RFQStatic.TBValidity.value;
      let tb_time = this.state.RFQStatic.TBValidityTime.value;

      let rfq_validity_date = this.state.RFQStatic.RFQValidity.value;
      let rfq_validity_time = this.state.RFQStatic.RFQValidityTime.value;

      if (
        !tb_date ||
        !moment(tb_date).isValid() ||
        !(
          moment(tb_date).isSame(moment(), "day") ||
          moment(tb_date).isAfter(moment())
        )
      ) {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            TBValidity: {
              ...prevState.RFQStatic.TBValidity,
              error: true,
            },
          },
        }));
        if (!this.state.errorArray.includes("TBValidity")) {
          this.state.errorArray.push("TBValidity");
          this.setState({
            errorArray: this.state.errorArray,
          });
        }
        this.props.openSnackbar("error", "TB Validity date is not valid..!");
        return;
      }

      if (
        tb_date &&
        moment(tb_date).isValid() &&
        moment(tb_date).isSame(moment(), "day")
      ) {
        // check here if the selected time is greater than current or not
        let beginningTime = moment();
        beginningTime.set(
          "hour",
          this.state.RFQStatic.TBValidityTime.value.split(":")[0]
        );
        beginningTime.set(
          "minute",
          this.state.RFQStatic.TBValidityTime.value.split(":")[1]
        );

        let endTime = moment();
        if (endTime.isAfter(beginningTime)) {
          this.setState((prevState) => ({
            ...prevState,
            RFQStatic: {
              ...prevState.RFQStatic,
              TBValidityTime: {
                ...prevState.RFQStatic.TBValidityTime,
                error: true,
              },
            },
          }));
          if (!this.state.errorArray.includes("TBValidityTime")) {
            this.state.errorArray.push("TBValidityTime");
            this.setState({
              errorArray: this.state.errorArray,
            });
          }
          this.props.openSnackbar(
            "error",
            `TB Validity date time is not valid!`
          );
          return;
        } else {
          let index1 = this.state.errorArray.indexOf("TBValidityTime");
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }
          this.setState({
            errorArray: this.state.errorArray,
          });
        }
      } else {
        let index1 = this.state.errorArray.indexOf("TBValidityTime");
        if (index1 !== -1) {
          this.state.errorArray.splice(index1, 1);
        }
        this.setState({
          errorArray: this.state.errorArray,
        });
      }

      let rfq_date_obj = moment(rfq_validity_date);
      let tb_validity_date = moment(tb_date);
      let dateDifference = rfq_date_obj.diff(tb_validity_date, "days");

      rfq_date_obj.set("hour", rfq_validity_time.split(":")[0]);
      rfq_date_obj.set("minute", rfq_validity_time.split(":")[1]);

      tb_validity_date.set("hour", tb_time.split(":")[0]);
      tb_validity_date.set("minute", tb_time.split(":")[1]);
      let dateTimeDifference = rfq_date_obj.diff(tb_validity_date, "minutes");
      if (dateDifference < 0) {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            TBValidity: {
              ...prevState.RFQStatic.TBValidity,
              error: true,
            },
          },
        }));
        if (!this.state.errorArray.includes("TBValidity")) {
          this.state.errorArray.push("TBValidity");
          this.setState({
            errorArray: this.state.errorArray,
          });
        }
        this.props.openSnackbar(
          "error",
          "Technical bid validity date should be less than or equal RFQ validity date"
        );
        return;
      } else {
        if (dateTimeDifference < 0) {
          this.setState((prevState) => ({
            ...prevState,
            RFQStatic: {
              ...prevState.RFQStatic,
              TBValidityTime: {
                ...prevState.RFQStatic.TBValidityTime,
                error: true,
              },
            },
          }));
          if (!this.state.errorArray.includes("TBValidityTime")) {
            this.state.errorArray.push("TBValidityTime");
            this.setState({
              errorArray: this.state.errorArray,
            });
          }
          this.props.openSnackbar(
            "error",
            "Technical bid validity time should be less than or equal RFQ validity time"
          );
          return;
        } else {
          let index1 = this.state.errorArray.indexOf("TBValidity");

          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }
          this.setState({
            errorArray: this.state.errorArray,
          });
        }
      }
    } else {
      //tb validity validation when tb is off
      let index1 = this.state.errorArray.indexOf("TBValidity");

      if (index1 !== -1) {
        this.state.errorArray.splice(index1, 1);
      }
      this.setState({
        errorArray: this.state.errorArray,
      });

      let index2 = this.state.errorArray.indexOf("TBValidityTime");
      if (index2 !== -1) {
        this.state.errorArray.splice(index2, 1);
      }
      this.setState({
        errorArray: this.state.errorArray,
      });
    }

    this.handleRFQStaticChange({
      target: {
        name: "RFQCCUsersmails",
        value: this.state.RFQStatic.RFQCCUsersmails.value,
      },
    });

    if (this.props.remiderDrawer?.startDate) {
      if (
        moment(this.props.remiderDrawer?.startDate).isAfter(
          this.state.RFQStatic.RFQValidity.value
        )
      ) {
        this.props.openSnackbar(
          "error",
          "Reminder date can't be after the RFQ validity date!"
        );
        return;
      }
    }

    this.props.selectedItems.forEach((item, index) => {
      for (const key in this.state.mainTemplateQuotation[item.id]) {
        this.validateInput(key, index, true);
      }
    });

    // if category RFQ then validate based on the vendors selections only
    let localIncoFields = [];
    let localPaymentFields = [];

    let intlIncoFields = [];
    let intlPaymentFields = [];

    if (this.props.master_type === "category") {
      if (this.handleVendorType("additionalInfo")) {
        for (const key in this.state.additionalInfoTemplate) {
          // if it is link then skip
          let result = this.checkIsLinkLocal(
            this.state.additionalInfoTemplate[key]
          );
          if (!result) {
            this.validateInputLocal(key, true);
          } else if (result === "inco") {
            localIncoFields.push(key);
          } else if (result === "payment") {
            localPaymentFields.push(key);
          }
        }
      }

      if (this.handleVendorType()) {
        for (const key in this.state.IntlTemplate) {
          let result = this.checkIsLinkLocal(this.state.IntlTemplate[key]);
          if (!result) {
            this.validateInputINTL(key, true);
          } else if (result === "inco") {
            intlIncoFields.push(key);
          } else if (result === "payment") {
            intlPaymentFields.push(key);
          }
        }
      }
    } else {
      if (this.vendorTypeExist("local_count")) {
        for (const key in this.state.additionalInfoTemplate) {
          let result = this.checkIsLinkLocal(
            this.state.additionalInfoTemplate[key]
          );
          // if it is link then skip
          if (!result) {
            this.validateInputLocal(key, true);
          } else if (result === "inco") {
            localIncoFields.push(key);
          } else if (result === "payment") {
            localPaymentFields.push(key);
          }
        }
      }
      if (this.vendorTypeExist("internation_count")) {
        for (const key in this.state.IntlTemplate) {
          let result = this.checkIsLinkLocal(this.state.IntlTemplate[key]);
          if (!result) {
            this.validateInputINTL(key, true);
          } else if (result === "inco") {
            intlIncoFields.push(key);
          } else if (result === "payment") {
            intlPaymentFields.push(key);
          }
        }
      }
    }

    let localVendors = this.props.selectedVendors.filter(
      (vendor) => vendor.country === this.props.accountlevelInfo.country
    );

    let intlVendors = this.props.selectedVendors.filter(
      (vendor) => vendor.country !== this.props.accountlevelInfo.country
    );

    let incoTermError = false;
    let paymentTermError = false;

    if (this.props.master_type === "category") {
      let allVendors = [...localVendors, ...intlVendors];

      let inco_temp = {};
      if (localIncoFields.length || intlIncoFields.length) {
        allVendors.forEach((vendor) => {
          if (vendor.inco_term_info.inco_term_code) {
            let obj = {
              element_code: vendor.inco_term_info.inco_term_code,
              element_name: vendor.inco_term_info.inco_term_name,
            };
            inco_temp[vendor.vendor_id] = obj;
          } else {
            incoTermError = true;
            // this.setState({ error: true });
          }
        });
      }

      let payment_temp = {};
      if (localPaymentFields.length || intlPaymentFields.length) {
        allVendors.forEach((vendor) => {
          if (vendor.payment_term_info.payment_term_code) {
            let obj = {
              element_code: vendor.payment_term_info.payment_term_code,
              element_name: vendor.payment_term_info.payment_term_name,
            };
            payment_temp[vendor.vendor_id] = obj;
          } else {
            paymentTermError = true;
          }
        });
      }

      // for additionalInfo inco terms
      if (localIncoFields) {
        localIncoFields.forEach((field) => {
          // creating the details to store for additionalInfo inco terms
          // set their inco term details into the field
          this.setState((prevState) => ({
            ...prevState,
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [field]: {
                ...prevState.additionalInfoTemplate[field],
                value: JSON.stringify(inco_temp),
              },
            },
          }));
        });
      }

      // for additionalInfo payment terms
      if (localPaymentFields) {
        localPaymentFields.forEach((field) => {
          // creating the details to store for additionalInfo payment terms

          // set their inco term details into the field
          this.setState((prevState) => ({
            ...prevState,
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [field]: {
                ...prevState.additionalInfoTemplate[field],
                value: JSON.stringify(payment_temp),
              },
            },
          }));
        });
      }

      // for intl inco terms
      if (intlIncoFields) {
        intlIncoFields.forEach((field) => {
          // creating the details to store for additionalInfo inco terms
          // set their inco term details into the field
          this.setState((prevState) => ({
            ...prevState,
            IntlTemplate: {
              ...prevState.IntlTemplate,
              [field]: {
                ...prevState.IntlTemplate[field],
                value: JSON.stringify(inco_temp),
              },
            },
          }));
        });
      }

      // for intl payment terms
      if (intlPaymentFields) {
        intlPaymentFields.forEach((field) => {
          // creating the details to store for additionalInfo inco terms
          // let temp = {};
          // intlVendors.forEach(vendor => {
          //   if (vendor.payment_term_info.payment_term_code){
          //     let obj = {
          //       element_code: vendor.payment_term_info.payment_term_code,
          //       element_name: vendor.payment_term_info.payment_term_name,
          //     };
          //     temp[vendor.vendor_id] = obj;
          //     } else {
          //       paymentTermError = true;
          //     }
          // })
          // set their inco term details into the field
          this.setState((prevState) => ({
            ...prevState,
            IntlTemplate: {
              ...prevState.IntlTemplate,
              [field]: {
                ...prevState.IntlTemplate[field],
                value: JSON.stringify(payment_temp),
              },
            },
          }));
        });
      }
    } else {
      this.setState({
        localIncoFields,
        localPaymentFields,
        intlIncoFields,
        intlPaymentFields,
      });
    }

    if (incoTermError || paymentTermError) {
      this.props.openSnackbar(
        "error",
        "Please remove vendor with no Payment Terms / Incoterms to continue"
      );
      return;
    }

    if (incoTermError) {
      this.props.openSnackbar(
        "error",
        "Please remove vendor with no Incoterms to continue"
      );
      return;
    }

    if (paymentTermError) {
      this.props.openSnackbar(
        "error",
        "Please remove vendor with no Payment Terms to continue"
      );
      return;
    }

    for (const key in this.state.RFQDynamic) {
      this.validateInputRFQ(key, true);
    }
    if (this.state.errorArray.length) {
      this.setState({
        rfqSubmitLoading: false,
      });
      this.props.openSnackbar(
        "error",
        "There are errors in your form. Please resolve in order to continue"
      );
      return false;
    }
    //for showing error in slab
    if (this.props.rfqSettingValues.includeSlab) {
      if (
        !Object.keys(this.state.slab).some(
          (key, i) => this.state.slab[key].isOpen == true
        )
      ) {
        this.props.openSnackbar(
          "error",
          `Please provide a ${this.props?.displayOptions["label"]} for at least one item to continue.`
        );
        return;
      }
      let error = [];
      const { mainTemplateQuotation } = this.state;
      const updateTemplate = { ...mainTemplateQuotation };
      for (const key in this.state.slab) {
        if (
          this.state.slab[key].isOpen &&
          mainTemplateQuotation[key].slab.some(
            (ele) => ele.start == "" || ele.end == ""
          )
        ) {
          updateTemplate[key].slab = updateTemplate[key].slab.map((obj) => {
            const tempItem = { ...obj };
            if (obj.end == "" && obj.start != "More Than") {
              tempItem.error = true;
            }
            return tempItem;
          });
          let item = this.props.selectedItems.find((ele) => ele.id == key);
          error.push(item.code);
        }
      }
      this.setState({
        mainTemplateQuotation: updateTemplate,
      });
      if (error.length) {
        let msg = error.map((item, i) => {
          return `${i + 1}.${item}`;
        });
        this.props.openSnackbar(
          "error",
          ` Please provide a valid ${
            this.props?.displayOptions["label"]
          } for items ${msg.join(",")} to continue`
        );
        return;
      }
    }

    if (this.props.location?.pathname.indexOf("/prcombination") === -1) {
      if (!this.state.categorySpecificationFiles?.length) {
        this.setState({
          confirmDialogOpen: true,
          confirmAlertTitle: "Specification(s) Not Found!",
          confirmAlertMsg:
            "Category specification file is not uploaded for category. Are you sure you want to submit this RFQ?",
        });
        return;
      }
    }

    // if (!this.state.categorySpecificationFiles?.length){
    //   this.setState({
    //     confirmDialogOpen: true,
    //     confirmAlertTitle:"Specification(s) Not Found!",
    //     confirmAlertMsg:"Category specification file is not uploaded for category. Are you sure you want to submit this RFQ?",
    //   })
    //   return;
    // }

    if (this.props.remiderDrawer?.startDate) {
      let rfqDate = moment(this.state.RFQStatic.RFQValidity.value);
      let reminderDate = moment(this.props.remiderDrawer.startDate);

      let rfqDate1 = new Date(rfqDate.format("YYYY-MM-DD"));
      let reminderDate1 = new Date(reminderDate.format("YYYY-MM-DD"));

      var Difference_In_Time = rfqDate1.getTime() - reminderDate1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      let diff = Difference_In_Days + 1;
      let msgArray = [];

      if (this.props.remiderDrawer?.intervalDays > diff) {
        this.props.openSnackbar(
          "error",
          `Inrtervals for sending new quotation reminder should be less than or equal to the diffrence between quotation reminder start date and validity date in days (${diff} days).`
        );
        return;
      }

      let count = Math.ceil(diff / this.props.remiderDrawer?.intervalDays);

      msgArray.push(
        `${count} Quotation reminders will be triggered during the given duration of ${diff} days (incl. the validity date)`
      );

      msgArray.push(
        `The 1st quotation reminder will be sent on the start date`
      );

      msgArray.push(`Are you sure you want to submit this RFQ?`);

      this.setState({
        confirmDialogOpen: true,
        confirmAlertTitle: "Submit?",
        confirmAlertMsg: msgArray,
      });
      return;
    }

    this.setState({
      confirmDialogOpen: true,
      confirmAlertTitle: "Submission",
      confirmAlertMsg: "Are you sure want to submit this RFQ?",
    });
  };

  rfqsubmitAPI = () => {
    const {
      RFQStatic,
      RFQDynamic,
      mainTemplateQuotation,
      additionalInfoTemplate,
      IntlTemplate,
    } = this.state;
    let finalData = {};
    finalData.product_id = this.props.selectedCategory.id;
    finalData.master_type = this.props.master_type;
    finalData.random_form_id = this.state.randomId;
    finalData.valid_date = `${moment(RFQStatic.RFQValidity.value).format(
      "YYYY-MM-DD"
    )} ${RFQStatic.RFQValidityTime.value}:00`;
    let display_valid_date = `${moment(RFQStatic.RFQValidity.value).format(
      "DD-MM-YYYY"
    )} ${RFQStatic.RFQValidityTime.value}:00`;
    if (this.props.rfqSettingValues?.projects) {
      finalData.project_ids = this.props.rfqSettingValues?.projects.map(
        (project) => project.id
      );
    }

    if (this.props.master_type === "category") {
      if (this.handleVendorType()) {
        finalData.shipment_mark = this.state.shipment_mark;
      }
    } else {
      if (this.vendorTypeExist("internation_count")) {
        finalData.shipment_mark = this.state.shipment_mark;
      }
    }

    finalData.technical_bid_enabled = this.props.includeTB ? "Y" : "N";

    if (this.props.includeTB) {
      finalData.valid_date_tb = `${moment(RFQStatic.TBValidity.value).format(
        "YYYY-MM-DD"
      )} ${RFQStatic.TBValidityTime.value}:00`;
      finalData.technical_bid_type = this.props.itemizeTB ? "item" : "category";
      finalData.daily_interval_reminder_checked_flag = this.props.remiderDrawer
        ?.includeReminderInterval
        ? "Y"
        : "N";
      finalData.tb_reminder_interval_days =
        this.props.remiderDrawer?.remiderDaysTB || 1;
      finalData.tbm_item_rfq_mapping_hourly = this.props.remiderDrawer
        ?.enableHourlyReminderTB
        ? "Y"
        : "N";
      finalData.tbm_item_rfq_mapping_hourly_interval =
        this.props.remiderDrawer?.hourlyReminderValueTB || 1;
      finalData.tbm_item_rfq_mapping_sms = this.props.remiderDrawer
        ?.sendSMSWithHourlyReminderTB
        ? "Y"
        : "N";
      finalData.hourly_tb_reminder_checked_flag = this.props.remiderDrawer
        ?.enableHourlyReminderTB
        ? "Y"
        : "N";

      if (this.props.itemizeTB) {
        if (Object.keys(this.props.checkTechnicalBidItems).length > 0) {
          let itemize_tb_data = [];
          Object.keys(this.props.checkTechnicalBidItems).forEach((key, i) => {
            if (parseInt(key) > 0) {
              itemize_tb_data.push({
                item_id: key,
                tb_template_id: this.props.checkTechnicalBidItems[key],
              });
            }
          });
          finalData.technical_bid_template = itemize_tb_data;
        }
      } else {
        if (Object.keys(this.props.checkTechinicalBidCategory).length > 0) {
          Object.keys(this.props.checkTechinicalBidCategory).forEach((key) => {
            finalData.technical_bid_template =
              this.props.checkTechinicalBidCategory[key];
          });
        }
      }
    }

    finalData.auto_inquiry_flag = "Y"; //na
    finalData.auto_inquiry_start = ""; //YYYY-MM-DD H:i:s //na
    finalData.auto_inquiry_end = ""; //YYYY-MM-DD H:i:s //na
    finalData.repetition_days = ""; // integer | digits:10 //na
    finalData.auto_inquiry_email_id = ""; //na
    finalData.auto_inquiry_sms_flag = ""; // Y/N //na
    finalData.auto_inquiry_validity = ""; // integer | digits:10 //na
    finalData.reminder_for_quote_flag = this.props.remiderDrawer?.startDate
      ? "Y"
      : "N"; // Y/N //Y if date is selected
    finalData.reminder_for_quote_start = this.props.remiderDrawer?.startDate
      ? moment(this.props.remiderDrawer.startDate).format("YYYY-MM-DD")
      : ""; // YYYY-MM-DD // startdate in reminder drawer
    finalData.reminder_repetition_days =
      this.props.remiderDrawer?.intervalDays || ""; // integer | digits:10 //reminder days intervals (int)
    finalData.reminder_for_quote_sms_flag = this.props.remiderDrawer?.sendSMS
      ? "Y"
      : "N"; // Y/N //send sms 1st remider
    finalData.reminder_for_quote_start_days = ""; // YYYY-MM-DD //to be checked
    finalData.template_id =
      this.props.quotationJsonTemplate?.PARAM[0]?.template_id;
    finalData.batch_id = this.props.quotationJsonTemplate?.PARAM[0]?.batch_id; // batch id of selected template
    finalData.hourly_reminders = this.props.remiderDrawer?.enableHourlyReminder
      ? "20"
      : "10"; // 10 / 20 // enable horly in reminder
    finalData.hourly_reminders_value =
      this.props.remiderDrawer?.hourlyReminderValue; // 10 / 20 // enable horly in reminder
    finalData.hourly_reminders_sms = this.props.remiderDrawer
      ?.sendSMSWithHourlyReminder
      ? "20"
      : "10"; // 10 / 20 // send sms enable horly in reminder
    finalData.cc_user_enable_flag = RFQStatic.RFQUpdateCCUsersCheck.value
      ? "Y"
      : "N"; // Y / N // update cc user master
    finalData.cc_user_email_id = RFQStatic.RFQCCUsersmails.value; //cc user textarea
    if (
      this.props.accountModulePermission["Contract Rfq"]?.module_activated ===
      "Y"
    ) {
      finalData.rfq_contract_flag = RFQStatic.RFQContactCheck.value
        ? "20"
        : "10"; // 10 / 20 //rfq cehck in last
    }
    let tempDisplayOptions = this.props.displayOptions;
    let tempRfqSettingValues = this.props.rfqSettingValues;
    finalData.multiple_solution_flag = tempDisplayOptions.allowed_slab
      ? tempRfqSettingValues.includeSlab
        ? "Y"
        : "N"
      : tempRfqSettingValues?.allowMultipleSolution
      ? "Y"
      : "N"; // Y / N //allow multiple solution check in setting
    finalData.sealed_rfq = RFQStatic.lock ? "20" : "10"; // 10 / 20 //lock btn
    finalData.transaction_type = "Inquiry"; // 10 / 20 //lock btn
    finalData.transaction_status = "Inquiry"; // 10 / 20 //lock btn
    finalData.inquiry_rules_tb_auto_approval_flag = "N"; // in case of pr RFQ it might be Y
    finalData.multiplant_permission_flag = this.props.rfqSettingValues
      ?.allowMultiPlant
      ? "Y"
      : "N";
    finalData.is_payment_term = this.props.rfqSettingValues
      ?.vendorMasterPaymentTerm
      ? "Y"
      : "N"; // Y / N //in setting
    finalData.is_inco_term = this.props.rfqSettingValues?.vendorMasterIncoTerm
      ? "Y"
      : "N"; // Y / N //in setting
    finalData.rfq_payment_identifier =
      finalData.is_payment_term == "Y"
        ? this.props.companyInfo?.payment_term_identifier
        : ""; //forward from comInfo
    finalData.rfq_inco_identifier =
      finalData.is_inco_term == "Y"
        ? this.props.companyInfo?.inco_term_identifier
        : ""; //forward from comInfo
    if (
      this.props.rfqSettingValues?.basicRate &&
      this.props.rfqSettingValues?.landedCost
    ) {
      finalData.display_rank_to_vendor_on = "30";
    } else if (this.props.rfqSettingValues?.landedCost) {
      finalData.display_rank_to_vendor_on = "10";
    } else if (this.props.rfqSettingValues?.basicRate) {
      finalData.display_rank_to_vendor_on = "20";
    } else {
      finalData.display_rank_to_vendor_on = "40";
    }
    finalData.is_priority = RFQStatic.star ? "Y" : "N"; // Y / N //star btn

    finalData.specification_files = {
      category: [],
      item: {},
    };
    //add final data if slab in included from rfq settings
    if (
      tempDisplayOptions.hasOwnProperty("allowed_slab") &&
      tempRfqSettingValues.includeSlab
    ) {
      finalData.slab = {};
    }
    if (this.props.categorySpecFiles.length > 0) {
      this.props.categorySpecFiles.forEach((file, i) => {
        finalData.specification_files["category"].push(file.id);
      });
    }

    if (Object.keys(this.props.itemSpecificFiles).length > 0) {
      Object.keys(this.props.itemSpecificFiles).forEach((itemNo, i) => {
        if (this.props.itemSpecificFiles[itemNo].length > 0) {
          finalData.specification_files.item[itemNo] = [];
          this.props.itemSpecificFiles[itemNo].forEach((file, i) => {
            finalData.specification_files.item[itemNo].push(file.id);
          });
        }
      });
    }

    let dynamic_fields = {};
    let itemize = _.cloneDeep(mainTemplateQuotation);
    let common = _.cloneDeep(RFQDynamic);
    for (const key in itemize) {
      const element = itemize[key]; //every item
      for (const key2 in element) {
        const element2 = element[key2]; //every field
        if (key2 !== "slab") {
          for (const key3 in element2) {
            // const element3 = element2[key3];
            if (
              key3 !== "HTMLAttributeName" &&
              key3 !== "value" &&
              key3 !== "Validation" &&
              !key3.includes("upd_budget_master")
            ) {
              if (typeof element2 === "object") {
                if (element2["ElementType"] === "DT") {
                  const DateObj = element2["value"];
                  const momentObj = moment(DateObj, "DD/MM/YYYY");
                  element2["value"] =
                    DateObj != ""
                      ? moment(element2["value"]).isValid()
                        ? moment(element2["value"]).format("YYYY-MM-DD")
                        : momentObj.format("YYYY-MM-DD")
                      : "";
                }

                if (element2["Identifier"] === "EstimatedValue") {
                  element2["value"] =
                    parseFloat(element["rate"] ? element["rate"] : 0) *
                    parseFloat(element["qty"] ? element["qty"] : 0);
                }
                delete element2[key3];
              }
            }
          }
        } else {
          if (this.state.slab[key].isOpen) {
            //for sending old id in slab
            let currentItem = this.props.selectedItems.find((x) => x.id == key);
            let oldId = currentItem.old_id;
            finalData.slab[oldId] = element2.map((obj, i) => {
              let value = "";
              if (i == element2.length - 1) {
                value = `${obj.start} ${obj.end}`;
              } else {
                value = `${obj.start}-${obj.end}`;
              }
              return value;
            });
            delete element[key2];
          } else {
            delete element[key2];
          }
        }
      }
    }
    dynamic_fields.itemize = itemize;

    if (this.props.master_type === "category") {
      if (this.handleVendorType("additionalInfo")) {
        let additionalInfo = _.cloneDeep(additionalInfoTemplate);
        for (const key in additionalInfo) {
          const element = additionalInfo[key];
          for (const key2 in element) {
            if (
              key2 !== "HTMLAttributeName" &&
              key2 !== "value" &&
              key2 !== "Validation"
            ) {
              delete element[key2];
            }
          }
        }
        dynamic_fields.additionalInfo = additionalInfo;
      }

      if (this.handleVendorType()) {
        let foreign = _.cloneDeep(IntlTemplate);
        for (const key in foreign) {
          const element = foreign[key];
          for (const key2 in element) {
            if (
              key2 !== "HTMLAttributeName" &&
              key2 !== "value" &&
              key2 !== "Validation"
            ) {
              delete element[key2];
            }
          }
        }
        dynamic_fields.foreign = foreign;
      }
    } else {
      if (this.vendorTypeExist("local_count")) {
        let additionalInfo = _.cloneDeep(additionalInfoTemplate);
        for (const key in additionalInfo) {
          const element = additionalInfo[key];
          for (const key2 in element) {
            if (
              key2 !== "HTMLAttributeName" &&
              key2 !== "value" &&
              key2 !== "Identifier" &&
              key2 !== "Validation"
            ) {
              delete element[key2];
            }
          }
        }
        additionalInfo["PaymentType"] = this.state.localPaymentFields[0];
        additionalInfo["IncoType"] = this.state.localIncoFields[0];
        dynamic_fields.additionalInfo = additionalInfo;
      }

      if (this.vendorTypeExist("internation_count")) {
        let foreign = _.cloneDeep(IntlTemplate);
        for (const key in foreign) {
          const element = foreign[key];
          for (const key2 in element) {
            if (
              key2 !== "HTMLAttributeName" &&
              key2 !== "value" &&
              key2 !== "Identifier" &&
              key2 !== "Validation"
            ) {
              delete element[key2];
            }
          }
        }
        foreign["PaymentType"] = this.state.intlPaymentFields[0];
        foreign["IncoType"] = this.state.intlIncoFields[0];
        dynamic_fields.foreign = foreign;
      }
    }

    for (const key in common) {
      const element = common[key];
      if (element.ElementType === "D") {
        element["value"] = this.findFormulaValues(element, itemize);
      }
      //convert multiplant array to ### saperated string
      if (
        element.ElementType === "S" &&
        element.Identifier === "Destination" &&
        this.props.rfqSettingValues.allowMultiPlant
      ) {
        element["value"] = element["value"].join("###");
      }
      for (const key2 in element) {
        if (
          key2 !== "HTMLAttributeName" &&
          key2 !== "value" &&
          key2 !== "Validation"
        ) {
          delete element[key2];
        }
      }
    }
    dynamic_fields.common = common;

    let vendors = [];
    this.props.selectedVendors?.forEach((vendor) => {
      vendors.push(vendor.id);
    });

    finalData.dynamic_fields = dynamic_fields;
    if (this.props.master_type === "category") {
      finalData.vendors = vendors;
    }
    if (this.props.master_type !== "category") {
      finalData.items = this.props.selectedItems?.map((item) => item.id);
    }
    let submitApi = rfqSubmit;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    this.toggleLoaderArray("rfqSubmit");
    let pr_ids_array = [];
    let pr_line_items_details = [];
    if (this.props.location?.pathname.indexOf("/prcombination") > -1) {
      finalData.inquiry_rules_tb_auto_approval_flag = "N";
      if (this.props.selectedPrCatagory?.auto_approval_flag === "Y") {
        finalData.inquiry_rules_tb_auto_approval_flag = "Y";
      }
      finalData.pr_has_tb_flag = 0;
      if (this.props.selectedPrCatagory?.tb_flag === 1) {
        finalData.pr_has_tb_flag = 1;
      }
      finalData.prs = {};

      this.props.selectedPrCatagory.items.forEach((item, i) => {
        finalData.prs[item.id] = item.prlineitems_ids;
        pr_line_items_details = [
          ...pr_line_items_details,
          ...this.props.prlineitems[item.id],
        ];
        pr_ids_array = [...pr_ids_array, ...item.prlineitems_ids];
      });

      if (
        finalData.master_type === "item" &&
        this.props.accountModulePermission[
          "Plant Specific Categroy Item Vendor Linking"
        ]?.module_activated === "Y"
      ) {
        finalData.plant = this.props.selectedPrCatagory.plant?.code;
      }
      submitApi = prRfqSubmit;
    }
    axios
      .post(submitApi, finalData)
      .then((res) => {
        this.setState({
          rfqSubmitLoading: false,
        });
        this.props.setPrRfqs({
          inquiry_id: res.data.inquiry_id,
          rfqNo: res.data.rfq_number,
          category: this.props.selectedPrCatagory.category,
          plant: this.props.selectedPrCatagory.plant,
          valid_date: display_valid_date,
          pr_line_items: pr_line_items_details,
          index: this.props.disableIndex,
        });
        // if(this.props.prplants.length){
        //   let formData = new FormData();
        //   formData.append('sessionID',this.props.sessionId);
        //   formData.append('inquriy_id',res.data.inquiry_id);
        //   axios.post(insertPrRfq,formData,{
        //     headers: []
        //   });
        // }
        if (
          this.props.prplants.length &&
          this.props.accountModulePermission["PR Status Integration"]
            ?.module_activated === "Y"
        ) {
          axios
            .get(
              pr_status_integration(
                this.props.userInfo?.division_id,
                this.props.companyInfo.business_gr_master_id,
                res.data.inquiry_id,
                pr_ids_array.join()
              )
            )
            .then((response) => {})
            .catch((err) => {
              //alert("error");
            });
        }
        axios.post(modify_edit_qcs_values(res.data.inquiry_id));
        this.toggleLoaderArray("rfqSubmit");

        if (res.data.mailBody) {
          delete axios.defaults.headers.common["Authorization"];
          let formData = new FormData();

          Object.keys(res.data.mailBody).forEach((key) => {
            formData.append(key, res.data.mailBody[key]);
          });
          formData.append("sessionID", this.props.sessionId);
          formData.append("q", this.props.sessionId);

          axios
            .post(sendMailUri, formData)
            .then(
              () =>
                (axios.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${this.props.sessionId}`)
            )
            .catch((err) => {
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${this.props.sessionId}`;
            });
        }
        this.handleRFQSubmitDialogOpen(res.data.rfq_number);
        //adding values of first submitted rfq in store
        // if(Object.keys(this.props.defaultValues).length === 0){
        //   const {includeTB,itemizeTB,master_type,rfqSettingValues,remiderDrawer,template,isAllVendorSelected}=this.props
        //   let payload={
        //     // includeTB:includeTB,
        //     // itemizeTB:itemizeTB,
        //     vendorsType:master_type,
        //     RFQStatic: this.state.RFQStatic,
        //     RFQDynamic:this.state.RFQDynamic,
        //     rfqSettingValues:rfqSettingValues,
        //     remiderDrawer:remiderDrawer,
        //     template:template,
        //     isAllVendorSelected:isAllVendorSelected,
        //   }
        //   this.props.setDefaultValues(payload);
        // }
      })
      .catch((err) => {
        this.setState({
          rfqSubmitLoading: false,
        });
        this.toggleLoaderArray("rfqSubmit");
        if (err.response) {
          if (err.response?.data?.validation_errors) {
            let errorObj = err.response.data.validation_errors;
            let errorMsg = "";
            for (const key in errorObj) {
              errorObj[key].forEach((msg) => {
                errorMsg =
                  errorMsg +
                  " " +
                  `${typeof msg === "object" ? msg.error : msg}`;
              });
            }
            this.props.openSnackbar("error", errorMsg);
          } else if (err.response.status === 500) {
            this.props.openSnackbar(
              "error",
              "Internal Server Error. Please try again later."
            );
          } else if (err.response.status === 503) {
            this.props.openSnackbar(
              "error",
              "Service temporarily unavailable. Please try again later."
            );
          } else {
            this.props.openSnackbar("error", `Something went wrong!`);
          }
        } else {
          this.props.openSnackbar("error", "Network Error");
        }
      });
  };
  findFormulaValues = (element, itemize) => {
    let hiddenLabelValue = 0;
    let itemFormula = element["value"].Formula;
    for (const key in itemize) {
      let object = itemize[key];
      for (const key1 in object) {
        if (itemFormula.includes(key1)) {
          hiddenLabelValue = hiddenLabelValue + Number(object[key1].value);
        }
      }
    }
    return hiddenLabelValue;
  };

  handleUplaod = (index, res) => {
    const { mainTemplateQuotation } = this.state;
    mainTemplateQuotation[this.props.selectedItems[index].id]["specification"] =
      res;
    this.setState({
      mainTemplateQuotation,
    });
  };

  handleRFQSubmitDialogOpen = (msg) => {
    this.setState({
      RFQSubmitDialogOpen: true,
      rfqSubmitMsg: msg,
    });
  };

  RFQSubmitDialogOnClose = () => {
    if (this.props.location?.pathname.indexOf("/prcombination") === -1) {
      this.props.resetSettingDrawer();
      this.props.resetReminderSettings();
      this.setState({
        RFQSubmitDialogOpen: false,
      });
      this.props.handleTBAction(
        "vendxRating",
        null, // itemId
        null, // oldItemId
        0,
        0
      );
    } else {
      this.setState({
        RFQSubmitDialogOpen: false,
      });
      this.props.setPRselectedChange();
    }
  };
  handleApplyToAllClick = (FieldId, textbox = false, Identifier) => {
    if (
      !this.state.mainTemplateQuotation[this.props.selectedItems[0].id][
        FieldId
      ]["value"]
    ) {
      this.props.openSnackbar("error", "please enter the value first");
      return;
    }
    if (
      this.state.mainTemplateQuotation[this.props.selectedItems[0].id][FieldId][
        "error"
      ]
    ) {
      this.props.openSnackbar("error", "please reslove error first");
      return;
    }
    this.props.selectedItems.forEach((item) => {
      let FieldIndex = this.state.errorArray.indexOf(
        this.state.mainTemplateQuotation[item.id][FieldId]["HTMLAttributeName"]
      );
      if (FieldIndex !== -1) {
        this.state.errorArray.splice(FieldIndex, 1);
      }
      this.setState((prevState) => ({
        ...prevState,
        mainTemplateQuotation: {
          ...prevState.mainTemplateQuotation,
          [item.id]: {
            ...prevState.mainTemplateQuotation[item.id],
            [FieldId]: {
              ...prevState.mainTemplateQuotation[item.id][FieldId],
              error: false,
              value:
                this.state.mainTemplateQuotation[
                  this.props.selectedItems[0].id
                ][FieldId]["value"],
            },
          },
        },
      }));

      if (Identifier === "ProductQuantity") {
        // then set rate for all
        this.setState((prevState) => ({
          ...prevState,
          mainTemplateQuotation: {
            ...prevState.mainTemplateQuotation,
            [item.id]: {
              ...prevState.mainTemplateQuotation[item.id],
              qty: this.state.mainTemplateQuotation[
                this.props.selectedItems[0].id
              ]["qty"],
            },
          },
        }));
      }
      if (Identifier === "Budgetedrate") {
        // then set rate for all
        this.setState((prevState) => ({
          ...prevState,
          mainTemplateQuotation: {
            ...prevState.mainTemplateQuotation,
            [item.id]: {
              ...prevState.mainTemplateQuotation[item.id],
              rate: this.state.mainTemplateQuotation[
                this.props.selectedItems[0].id
              ]["rate"],
            },
          },
        }));
      }
    });

    this.setState({
      applyToAll: {
        ...this.state.applyToAll,
        [`${FieldId}_applyToAll`]:
          !this.state.applyToAll[`${FieldId}_applyToAll`],
      },
    });
  };

  handleVendorType = (type) => {
    return type == "additionalInfo"
      ? this.props.selectedVendors?.some(
          (e) =>
            e.country?.toUpperCase() ===
            this.props.accountlevelInfo?.country?.toUpperCase()
        )
      : this.props.selectedVendors?.some(
          (e) =>
            e.country?.toUpperCase() !==
            this.props.accountlevelInfo?.country?.toUpperCase()
        );
  };

  vendorTypeExist = (type) => {
    // internation_count
    // local_count
    let result = false;
    this.props.selectedItems.forEach((item) => {
      if (item?.vendor_count?.[type]) {
        result = true;
      }
    });
    return result;
  };

  fetchTermsAndCondtions = () => {
    this.toggleLoaderArray("TermsAndCondtions");
    let newTermsAndConditions = this.props.termsAndConditions;
    let categoryId = this.props.selectedCategory?.id;
    if (
      Object.keys(newTermsAndConditions).length !== 0 &&
      newTermsAndConditions[categoryId]
    ) {
      this.setState({
        termsAndConditionsData: newTermsAndConditions[categoryId],
      });
      this.toggleLoaderArray("TermsAndCondtions");
    } else {
      const url = getTermsAndConditions(categoryId);
      axios
        .get(url)
        .then((res) => {
          this.setState({
            termsAndConditionsData: res.data?.data,
          });
          this.props.setTermsAndConditions({ [categoryId]: res.data?.data });
          this.toggleLoaderArray("TermsAndCondtions");
        })
        .catch((err) => {
          console.log(err, "getfilespecs error");
          this.toggleLoaderArray("TermsAndCondtions");
        });
    }
  };

  // getDestinationValue = () => {
  //   console.log('this.state.RFQDynamic',this.state.RFQDynamic);
  //   for (const key in this.state.RFQDynamic) {
  //     const element = this.state.RFQDynamic[key];y
  //     if (
  //       element.Identifier === "Destination" ||
  //       element.Identifier === "DestinationForeign"
  //     ) {
  //       return element.value;
  //     }
  //   }
  // };
  getDestinationValue = () => {
    for (const key in this.state.additionalInfoTemplate) {
      const element = this.state.additionalInfoTemplate[key];
      if (
        element.Identifier === "Destination" ||
        element.Identifier === "DestinationForeign"
      ) {
        return element.value;
      }
    }
  };

  handleUpdateInMasterClick = (item_id, FieldId) => {
    this.setState({
      mainTemplateQuotation: {
        ...this.state.mainTemplateQuotation,
        [item_id]: {
          ...this.state.mainTemplateQuotation[item_id],
          [FieldId]: {
            ...this.state.mainTemplateQuotation[item_id][FieldId],
            [`upd_budget_master_${item_id}`]:
              !this.state.mainTemplateQuotation[item_id][FieldId][
                `upd_budget_master_${item_id}`
              ],
          },
        },
      },
    });
  };

  updateDeliveryScheduleFieldValue = (value, item_id, FieldId) => {
    this.setState((prevState) => ({
      ...prevState,
      mainTemplateQuotation: {
        ...prevState.mainTemplateQuotation,
        [item_id]: {
          ...prevState.mainTemplateQuotation[item_id],
          [FieldId]: {
            ...prevState.mainTemplateQuotation[item_id][FieldId],
            value,
          },
        },
      },
    }));
  };

  updateDeliveryScheduleFieldValueSlab = (
    value,
    item_id,
    FieldId,
    RowIndex
  ) => {
    if (typeof RowIndex != "undefined") {
      this.setState((prevState) => ({
        slabMainTemplateQuotation: {
          ...prevState.slabMainTemplateQuotation,
          [item_id]: prevState.slabMainTemplateQuotation[item_id].map(
            (item, index) =>
              index === RowIndex
                ? {
                    ...item,
                    [FieldId]: {
                      ...item[FieldId],
                      value,
                    },
                  }
                : item
          ),
        },
      }));
    } else {
      this.setState((prevState) => ({
        slabMainTemplateQuotation: {
          ...prevState.slabMainTemplateQuotation,
          [item_id]: prevState.slabMainTemplateQuotation[item_id].map(
            (item, index) =>
              index === 0
                ? {
                    ...item,
                    [FieldId]: {
                      ...item[FieldId],
                      value,
                    },
                  }
                : item
          ),
        },
      }));
    }
  };

  handleSubmitQuotation = (currentTransactionGUID, quoteType) => {
    if (
      this.props.featurePermission?.is_mass_negotiation?.feature_value ===
        "Y" &&
      quoteType === "NEGOTIATION"
    ) {
      this.props.openSnackbar(
        "error",
        "Please submit the negotiation by using “mass negotiation” functionality"
      );
      return;
    }
    let url = vendor_QPlus;

    url +=
      "?call_from=qcs_plus&vendx_action=compose&ReverseAuction=n&perform=" +
      (quoteType === "NEGOTIATION" ? "negotiation" : "offline_quote") +
      "&inquiry_guid=" +
      currentTransactionGUID +
      "&qcs_plus=1&vendor_id=" +
      this.props.vendorId;
    let obj = {
      message: "update url",
      url: url,
    };
    window.parent.postMessage(obj, "*");
  };

  handleCloseDestinationDialog() {
    this.setState({ isDestinationDetailsDialogOpen: false });
  }

  handleOpenDestinationDialog() {
    this.setState({ isDestinationDetailsDialogOpen: true });
  }

  setIsDestinationDetailsDialogOpen(isOpen) {
    this.setState({ isDestinationDetailsDialogOpen: isOpen });
  }

  render() {
    return (
      <React.Fragment>
        <Backdrop
          style={{ color: "#fff", zIndex: "10000000000" }}
          open={
            this.state.loaderArray.length > 0 ||
            this.state.prevRFQLoading ||
            this.props.jsonTemplateloading
          }
        >
          <CircularProgress style={{ color: "#fff" }} />
        </Backdrop>
        <React.Fragment>
          <form
            id="novalidatedform"
            noValidate
            autoComplete="off"
            onSubmit={(event) => {
              if (
                this.props.location?.pathname.indexOf("/prcombination") > -1
              ) {
                event.preventDefault();
              }
            }}
          >
            {/* -----------------------Quotation Details----------------------- */}

            <Box mb={2} id="QuotationDetails">
              <QuotationDetailsSectionView
                title="Quotation Details"
                data={this.props.selectedQuotedItems}
                Alreadyexistingfiles={this.props.categorySpecFiles}
                getField={this.getFieldLocal}
                foreignData={this?.props?.quotationJsonTemplate?.COMMON?.concat(
                  this?.props?.quotationJsonTemplate?.FOREIGN
                )}
                localData={this?.props?.quotationJsonTemplate?.COMMON.concat(
                  this?.props?.quotationJsonTemplate?.LOCAL
                )}
                currencyOptions={this.props.currencyOptions}
                currencyDetails={this.props.currencyDetails}
                fileSpecificationData={this.props.fileSpecificationData}
                handleCurrencyChange={this.handleCurrencyChange}
                CurrentTransaction={this.props.CurrentTransaction}
                PrevTransaction={this.props.PrevTransaction}
                isServiceRfq={this.props.isServiceRfq}
                AlternativeNames={this.props.AlternativeNames}
              />
            </Box>

            {/* -----------------------Template Section----------------------- */}

            <Box mb={2} id="Template">
              <QuotationFormSectionTableView
                inquiryId={this.props.inquiryId}
                randomId={this.state.randomId}
                categoryId={this.props.categoryId}
                batch_id={
                  this.props.quotationJsonTemplate?.PARAM &&
                  this.props.quotationJsonTemplate?.PARAM[0]?.batch_id
                }
                data={this.props.quotationJsonTemplate?.ITEMIZE || []}
                mainTemplateQuotation={this.state.mainTemplateQuotation}
                diffrentiatedFields={this.state.DifferenceMainTemplateFieldId}
                diffrentiatedSlabFields={
                  this.state.DifferenceSlabMainTemplateFieldId
                }
                handleOpenDestinationDialog={this.handleOpenDestinationDialog}
                setIsDestinationDetailsDialogOpen={
                  this.setIsDestinationDetailsDialogOpen
                }
                selectedQuotedItems={this.props.selectedQuotedItems.items}
                // base_currency={this.props.accountlevelInfo?.base_currency}
                base_currency={this.props.currencyDetails}
                handleUplaod={this.handleUplaod}
                applyToAll={this.state.applyToAll}
                handleApplyToAllClick={this.handleApplyToAllClick}
                handleDateChange={this.handleDateChange}
                handleUpdateInMasterClick={this.handleUpdateInMasterClick}
                handleChange={this.handleChange}
                handleCheckboxChange={this.handleCheckboxChange}
                default_dropdown_type={
                  this.props.companyInfo?.default_dropdown_type
                }
                destination={this.getDestinationValue()}
                updateDeliveryScheduleFieldValue={
                  this.updateDeliveryScheduleFieldValue
                }
                updateDeliveryScheduleFieldValueSlab={
                  this.updateDeliveryScheduleFieldValueSlab
                }
                itemSpecificFiles={this.props.itemSpecificFiles}
                itemSpecific={this.props.itemSpecific}
                LATEST={this.LATEST}
                handleSlab={this.handleSlab}
                // slab={this.state.slab}
                slab={slab}
                mainMSTemplateQuotation={this.state.mainMSTemplateQuotation}
                slabMainTemplateQuotation={this.state.slabMainTemplateQuotation}
                // rfqSettingValues={this.props.rfqSettingValues}
                // rfqSettingValues={rfqSettingValues}
                // slabRows={this.state.slabRows}
                slabAddRow={this.slabAddRow}
                slabRemoveRow={this.slabRemoveRow}
                handleRowInput={this.handleRowInput}
                handleApplyToAllSlab={this.handleApplyToAllSlab}
                handleKey={this.handleKey}
                setItemsTemplate={this.setItemsTemplate}
                slabKey={this.state.slabKey}
                additionalInfoTemplate={this.state.additionalInfoTemplate}
                applyToAllSlab={this.state.applyToAllSlab}
                firstActiveSlab={this.props.selectedItems?.find((element) => {
                  if (
                    this.state?.slab[element?.id] &&
                    this.state?.slab[element?.id].isOpen
                  ) {
                    return element;
                  }
                })}
                displayOptions={this.props.displayOptions}
                selectedCurrency={this.state.selectedCurrency}
                lastTransactionInThread={this.props.lastTransactionInThread}
                lastTransactionTypeInThread={
                  this.props.lastTransactionTypeInThread
                }
                filteredSelectedNotData={this.props.filteredSelectedNotData}
                CurrentTransaction={this.props.CurrentTransaction}
                items={this.props.items}
                vendorType={this.props.vendorType}
                currencyDetails={this.props.currencyDetails}
                multiplePlantsFlag={this.props.multiplePlantsFlag}
                transactionItemSequence={this.props.transactionItemSequence}
                template={this.props.quotationJsonTemplate}
                quotedCurrency={this.props.quotedCurrency}
                multipleSolutionFlag={this.props.multipleSolutionFlag}
                slabFlag={this.props.slabFlag}
                inquiryItemsId={this.props.inquiryItemsId}
                isMultipleCurrency={this.props.isMultipleCurrency}
                uploadItemFiles={this.uploadItemFiles}
                getMasterCurrencyByFieldId={this.getMasterCurrencyByFieldId}
                AlternativeOptions={
                  this.props.AlternativeNames?.alternate_options
                }
              />
            </Box>
            {/*----------------------- Additional Info -----------------------*/}
            <>
              <Box mb={2} id="AdditionalInfo">
                <QuotationCardTemplate
                  title="Additional Info"
                  from="View"
                  state={this?.state?.lastTransactionData?.common}
                  data={this?.props?.quotationJsonTemplate?.COMMON.concat(
                    this?.props?.quotationJsonTemplate?.LOCAL
                  )}
                  foreignData={this?.props?.quotationJsonTemplate?.COMMON.concat(
                    this?.props?.quotationJsonTemplate?.FOREIGN
                  )}
                  localData={this?.props?.quotationJsonTemplate?.COMMON?.concat(
                    this?.props?.quotationJsonTemplate?.LOCAL
                  )}
                  getField={this.getFieldLocal}
                  handleChange={this.handleLocalChange}
                  vendorType={this?.props?.selectedQuotedItems?.vendors[0].type}
                  categoryId={this.props.categoryId}
                  fileSpecificationData={this.props.fileSpecificationData}
                  contract={this.props.selectedQuotedItems.filter_data.contract}
                  cc_user_email={
                    this.props.selectedQuotedItems.cc_user_email_id
                  }
                  MaterialDescription={this.state.MaterialDescription}
                  isLoading={this.state.isLoading}
                  materialDescriptionAll={this.download}
                  CurrentTransaction={this.props.CurrentTransaction}
                  multiplePlantsFlag={this.props.multiplePlantsFlag}
                  category={this.props.selectedQuotedItems.categories[0]}
                />
              </Box>

              {/* -----------------------Submit Quotattion Form Button----------------------- */}
              <Box className="Footer-QuotedForm" sx={{ mb: 2 }}>
                <FormGroup
                  sx={{
                    display: "inline-flex",
                    "& .MuiFormControlLabel-root": {
                      m: 0,
                    },
                    "& .MuiTypography-root": {
                      color: "var(--primary)",
                      textDecoration: "underline",
                      fontWeight: "500",
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.quotationChecked}
                        onChange={(event) =>
                          this.handleQuotationCheckboxChange(event)
                        }
                        color="primary"
                        sx={{ display: "none" }}
                      />
                    }
                    label={
                      <Typography
                        variant="subtitle1"
                        component="a"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            tandcDialogOpen: true,
                          });
                        }}
                      >
                        View Terms and Conditions
                      </Typography>
                    }
                  />
                </FormGroup>
                {/* <div style={{ display: "flex", alignItems: "center" }}> */}
                {/* <Checkbox
                      // checked={checked}
                      // onChange={handleCheckboxChange}
                      color="primary"
                    /> */}
                {/* <Typography
                      onClick={() => {
                        this.setState({
                          tandcDialogOpen: true,
                        });
                      }}
                      variant="body1"
                      sx={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      color={"primary.500"}
                      fontWeight={500}
                      mt={2}
                    >
                      View Terms and Conditions
                    </Typography>
                  </div> */}

                {this.props.IsLastTxn &&
                  (this.props.userInfo.type === "buyer" ? (
                    this.props.CurrentTransaction.type === "Requote" ||
                    this.props.CurrentTransaction.type === "Quotation" ? (
                      <Stack direction="row" spacing={2} mt={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          className="Upload_file_btn"
                          onClick={() =>
                            this.handleSubmitQuotation(
                              this.props.inquiryTransaction?.TranTypeGUID,
                              "OFF. REQUOTE"
                            )
                          }
                        >
                          OFF. REQUOTE
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() =>
                            this.handleSubmitQuotation(
                              this.props.inquiryTransaction?.TranTypeGUID,
                              "NEGOTIATION"
                            )
                          }
                          sx={{ backgroundColor: "grey.500" }}
                        >
                          NEGOTIATION
                        </Button>
                      </Stack>
                    ) : null
                  ) : (this.props.userInfo.type === "vendor" &&
                      this.props.CurrentTransaction.type === "Quotation") ||
                    this.props.CurrentTransaction.type === "Requote" ? (
                    <Stack direction="row" spacing={2} mt={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          this.handleSubmitQuotation(
                            this.props.inquiryTransaction?.TranTypeGUID,
                            "REQUOTE"
                          )
                        }
                      >
                        REQUOTE
                      </Button>
                    </Stack>
                  ) : null)}
              </Box>
              {/* <Box className="Footer-QuotedForm" sx={{ mb: 7 }}>
                {this.props.selectedQuotedItems._internal.current_user_id && (
                  <FormGroup
                    sx={{
                      "& .MuiTypography-root": {
                        color: "var(--primary)",
                        textDecoration: "underline",
                        fontWeight: "500",
                      },
                    }}
                  >
                    <FormControlLabel
                      // control={<Checkbox />}
                      label="Accept Terms and Contions"
                    />
                  </FormGroup>
                )}
              </Box> */}
            </>
          </form>
        </React.Fragment>
        {/* Modals */}

        {this.state.RFQSubmitDialogOpen && (
          <RFQSubmit
            open={this.state.RFQSubmitDialogOpen}
            selectedPrCategory={this.props.selectedPrCatagory}
            rfq_no={this.state.rfqSubmitMsg}
            handleClose={this.RFQSubmitDialogOnClose}
          />
        )}
        {this.state.specificationsOpen && (
          <UploadFileDialog
            product_id={this.props.selectedCategory?.id}
            randomId={this.state.randomId}
            master_type="category"
            handleUplaod={this.handleUplaod}
            open={this.state.specificationsOpen}
            Alreadyexistingfiles={this.props.categorySpecFiles}
            updateCategorySpecificationFiles={(newFiles) => {
              this.setState({
                categorySpecificationFiles: newFiles,
              });
            }}
            handleClose={() => {
              this.setState({
                specificationsOpen: false,
              });
            }}
          />
        )}

        {this.state.dynamicFileUploadOpen && (
          <UploadDynamicView
            sessionID={this.props.sessionId}
            Loading={this.state.Loading}
            uploadedFiles={this.state.attachments.data}
            open={this.state.dynamicFileUploadOpen}
            handleClose={() => {
              this.setState({
                dynamicFileUploadOpen: false,
                attachments: [],
                Loading: true,
              });
            }}
          />
        )}
        {this.state.dynamicFileUploadItemOpen && (
          <UploadDynamicView
            sessionID={this.props.sessionId}
            Loading={this.state.Loading}
            uploadedFiles={this.state.attachments.data}
            open={this.state.dynamicFileUploadItemOpen}
            handleClose={() => {
              this.setState({
                dynamicFileUploadItemOpen: false,
                attachments: [],
                Loading: true,
              });
            }}
          />
        )}

        {this.state.tandcDialogOpen && (
          <TermsAndConditions
            category_id={this.props.categoryId}
            open={this.state.tandcDialogOpen}
            termsAndConditionsData={this.props.termsAndConditionsDetails}
            handleClose={() => {
              this.setState({
                tandcDialogOpen: false,
              });
            }}
          />
        )}

        <ConfirmAlert
          open={this.state.confirmDialogOpen}
          handleClose={() => {
            this.setState({
              confirmDialogOpen: false,
            });
          }}
          handleSubmit={() => {
            this.setState({
              confirmDialogOpen: false,
            });
            this.rfqsubmitAPI();
          }}
          msg={this.state.confirmAlertMsg}
          title={this.state.confirmAlertTitle}
        />
        <Dialog
          open={this.state.openConfirmBox}
          onClose={this.handleConfirmBoxClose}
        >
          {/* <DialogTitle>Import {tranType} Form via Excel</DialogTitle> */}
          <DialogTitle>Import Form via Excel</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={this.handleConfirmBoxClose}
            sx={{ position: "absolute", right: 8, top: 13 }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Typography variant="h6">Download File Format:</Typography>
            {/* {masterType === 'category' ? ( */}
            {true ? (
              <>
                <Button onClick={() => this.downloadTransactionExcel(1)}>
                  Vertical
                </Button>
                <Button onClick={() => this.downloadTransactionExcel(2)}>
                  Horizontal
                </Button>
              </>
            ) : (
              <Button onClick={() => this.downloadTransactionExcel(1)}>
                Excel
              </Button>
            )}
            <br />
            <br />
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              {/* Upload file */}
              <input type="file" />
            </Button>
          </DialogContent>
        </Dialog>

        <DestinationDetailsDialogView
          handleClose={this.handleCloseDestinationDialog}
          open={this.state.isDestinationDetailsDialogOpen}
          lastTransactionInThreadCommon={
            this.props.lastTransactionInThreadCommon
          }
          lastTransactionInThreadItemize={
            this.props.lastTransactionData?.itemize
          }
          diffrentiatedFields={this.state.DifferenceMainTemplateFieldId}
          diffrentiatedSlabFields={this.state.DifferenceSlabMainTemplateFieldId}
          setIsDestinationDetailsDialogOpen={
            this.setIsDestinationDetailsDialogOpen
          }
          itemizeData={this?.props?.quotationJsonTemplate?.ITEMIZE[0]}
          foreignData={this?.props?.quotationJsonTemplate?.COMMON?.concat(
            this?.props?.quotationJsonTemplate?.FOREIGN
          )}
          localData={this?.props?.quotationJsonTemplate?.COMMON?.concat(
            this?.props?.quotationJsonTemplate?.LOCAL
          )}
          vendorType={this.props.vendorType}
          handleSave={this.handleSave}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  sessionId: state.sessionReducer.sessionId,
  companyInfo: state.companyInfoReducer.companyInfo,
  rfqSettingValues: state.drawerValuesReducer.rfqSettingValues,
  // reminderDrawer: state.drawerValuesReducer.reminderDrawer,
  selectedVendors: state.vendorReducer.selectedVendors,
  accountModulePermission: state.permissionsReducer.accountModulePermission,
  accountlevelInfo: state.permissionsReducer.accountlevelInfo,
  featurePermission: state.permissionsReducer.featurePermission,
  remiderDrawer: state.drawerValuesReducer.remiderDrawer,
  checkTechnicalBidItems: state.itemReducer.checkTechnicalBidItems,
  checkTechinicalBidCategory: state.categoryReducer.checkTechinicalBidCategory,
  categorySpecFiles: state.categoryReducer.fileSpecifications,
  itemSpecificFiles: state.itemReducer.itemSpecifications,
  prlineitems: state.prrfqreducer.setPrLineItemsData,
  prplants: state.prrfqreducer.plantcodes,
  selectedPrCatagory: state.prrfqreducer.selectedPrCategory,
  rfqSubmittedPrs: state.prrfqreducer.rfqSubmittedPrs,
  prCombinations: state.prrfqreducer.prCombinations,
  pr_dropdown_values: state.prrfqreducer.pr_dropdown_values,
  defaultValues: state.prrfqreducer.defaultValues,
  userInfo: state.userInfoReducer.userInfo,
  isAllVendorSelected: state.vendorReducer.isAllVendorSelected,
  disableIndex: state.prrfqreducer.disableIndex,
  templateValues: state.prrfqreducer.templateValues,
  termsAndConditions: state.prrfqreducer.termsAndConditions,
  fileSpecificationFromApi: state.prrfqreducer.fileSpecificationFromApi,
  selectedItems: state.itemReducer.selectedItems,
  displayOptions: state.prrfqreducer.displayOptions,
});

// export default connect(mapStateToProps, {
//   openSnackbar,
//   setReminderSettings,
//   resetReminderSettings,
//   resetCAT,
//   setFileSpecification,
//   setItemSpecifications,
//   setPrlinktoitemsforspecification,
//   setPrRfqs,
//   setPRselectedChange,
//   setDefaultValues,
//   setTemplateValues,
//   setTermsAndConditions,
//   setFileSpecificationFromApi,
//   setRFQSettings,
// })(AccountTemplate);

const mapDispatchToProps = {
  openSnackbar,
  setReminderSettings,
  resetReminderSettings,
  resetCAT,
  setFileSpecification,
  setItemSpecifications,
  setPrlinktoitemsforspecification,
  setPrRfqs,
  setPRselectedChange,
  setDefaultValues,
  setTemplateValues,
  setTermsAndConditions,
  setFileSpecificationFromApi,
  setRFQSettings,
};

const ConnectedQuotationTemplate = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuotationTemplateView);

const StyledAccountTemplate = withStyles(styles)(ConnectedQuotationTemplate);

export default StyledAccountTemplate;
