import React, { Component, createRef } from "react";
import {
  Typography,
  InputAdornment,
  IconButton,
  Button,
  InputBase,
  Avatar,
  Tooltip,
  Divider,
} from "@mui/material";
import { Box } from "@mui/system";
import PersonAddIcon from "@mui/icons-material/PersonAddAlt1Rounded";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import axios from "axios";
import {
  companyToAccountImport,
  getCompanyVendors,
  searchCompanyVendorsByNameOrCode,
} from "../../../config/apiUrl";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { openSnackbar } from "../../../redux/actions/snackbarAction";
import { debounce } from "lodash";
import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro";
import { getVendorGMPFlag } from "../../Vendors/VendorChip";

function QuickSearchToolbar(props) {
  return (
    <Box>
      <InputBase
        sx={{
          backgroundColor: "var(--grey-50)",
          width: "100%",
          height: "40px",
          borderRadius: "0",
          borderBottom: "1px solid var(--bluegrey-500)",
          padding: "0 16px",
          "& .MuiInputAdornment-positionStart .MuiSvgIcon-root": {
            color: (theme) => theme.palette.grey[600],
          },
          "& .search-clear svg": {
            width: "18px",
            height: "18px",
            color: (theme) => theme.palette.grey[500],
          },
        }}
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        }
        endAdornment={
          <IconButton
            className="search-clear"
            title="Clear"
            aria-label="Clear"
            size="small"
            style={{ visibility: props.value ? "visible" : "hidden" }}
            onClick={props.clearSearch}>
            <ClearIcon />
          </IconButton>
        }
      />
    </Box>
  );
}

class sendRfqToNewVendorCategory extends Component {
  constructor(props) {
    super(props);
    this.descriptionElementRef = createRef(null);
  }
  state = {
    rows: [],
    searchResult: [],
    selectionModel: [],
    currentPage: 1,
    searchResultCurrentPage: 1,
    lastPage: 1,
    searchResultLastPage: 1,
    total: 0,
    searchResultTotal: 0,
    loading: false,
    searchText: "",
    prevSearchText: "",
  };

  componentDidMount() {
    this.fetchCompanyVendor();
    // in order to wait for the element to be actually drawn, using setTimeout
    setTimeout(() => {
      if (this.descriptionElementRef.current !== null) {
        this.descriptionElementRef.current.focus();
      }
    }, 0);
  }
  fetchCompanyVendor = () => {
    this.setState({
      loading: true,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;

    axios
      .get(getCompanyVendors(this.state.currentPage))
      .then((res) => {
        //append rows, unique
        let finalRows = [...this.state.rows, ...res.data.data];
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueRows = Array.from(uniqueSet).map(JSON.parse);

        this.setState({
          loading: false,
          rows: uniqueRows,
          currentPage: res.data.meta.current_page,
          lastPage: res.data.meta.last_page,
          total: res.data.meta.total,
        });
      })
      .catch((err) => {
        this.props.openSnackbar("error", "something went wrong!");
        console.log(err, "getCompanyVendors Error");
        this.setState({
          loading: false,
        });
      });
  };

  handleScrollEnd = () => {
    if (this.state.searchText.trim() === "") {
      if (this.state.currentPage !== this.state.lastPage) {
        this.setState(
          {
            currentPage: this.state.currentPage + 1,
          },
          () => {
            this.fetchCompanyVendor();
          }
        );
      }
    } else {
      if (
        this.state.searchResultCurrentPage !== this.state.searchResultLastPage
      ) {
        this.setState(
          {
            searchResultCurrentPage: this.state.searchResultCurrentPage + 1,
            prevSearchText: this.state.searchText,
          },
          () => {
            this.searchCompanyVendor(this.state.searchText);
            // this.fetchCompanyVendor();
          }
        );
      }
    }
  };

  requestSearch = (searchValue) => {
    this.setState({
      loading: true,
      prevSearchText: this.state.searchText,
      searchText: searchValue,
    });

    if (searchValue.trim() !== "") {
      this.setState({
        searchResultCurrentPage: 1,
      });
      this.getSearchResult(searchValue);
    } else {
      this.setState({
        loading: false,
      });
    }
    //api call
  };

  getSearchResult = debounce((searchValue) => {
    // console.log("debounce");
    this.searchCompanyVendor(searchValue);
  }, 1000);

  searchCompanyVendor = (searchValue) => {
    this.setState({
      loading: true,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;

    const url = searchCompanyVendorsByNameOrCode(
      searchValue,
      this.state.searchResultCurrentPage
    );

    axios
      .get(url)
      .then((response) => {
        let final;

        // append or not respective to search is changes or not
        if (this.state.prevSearchText.trim() === searchValue) {
          final = [...this.state.searchResult, ...response.data.data];
        } else {
          final = response.data.data;
        }

        let jsonObject = final.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueRows = Array.from(uniqueSet).map(JSON.parse);

        this.setState({
          searchResult: uniqueRows,
          searchResultLastPage: response.data.meta?.last_page,
          searchResultCurrentPage: response.data.meta?.current_page,
          searchResultTotal: response.data.meta?.total,
          loading: false,
        });
      })
      .catch((er) => {
        this.props.openSnackbar("error", "something went wrong!");
        console.log(er);
      });
  };

  handleSubmit = () => {
    this.setState({
      loading: true,
    })
    const { selectionModel } = this.state;
    if (!selectionModel.length) {
      this.props.openSnackbar("error", "No vendor(s) selected");
      return;
    }
    let payload = {
      company_vendors: selectionModel,
      category_id: this.props.selectedCategory?.id || this.props.categoryId,
      are_approved: 20,
    };
    axios
      .post(companyToAccountImport, payload)
      .then((res) => {
        // this.props.openSnackbar("success", res.data.message);
        if (res.status === 200 || res.status === 201) {
          this.props.openSnackbar(
            "success",
            `${res.data.message
              ? res.data.message
              : "Vendors imported successfully"
            }`
          );

          // Reset the state and reload the data
          this.setState(
            {
              loading: false,
              rows: [],
              searchResult: [],
              selectionModel: [],
              currentPage: 1,
              searchResultCurrentPage: 1,
              lastPage: 1,
              searchResultLastPage: 1,
              total: 0,
              searchResultTotal: 0,
              searchText: "",
              prevSearchText: "",
            },
            () => {
              this.fetchCompanyVendor(); // Reload data
            }
          );
        }
        this.props.handleVendorsReload();
      })
      .catch((err) => {
        this.props.openSnackbar(
          "error",
          `${err.response.data.message
            ? err.response.data.message
            : "Failed to import vendors to your account"
          }`
        );
        this.setState({
          loading: false,
        })
        console.log(err, "companyToAccountImport");
      });
  };
  render() {
    const columns = [
      {
        field: "vendor_name",
        headerName: "VENDOR NAME",
        width: 185,
        align: "left",
        filterable: false,
        sortable: false,
        editable: false,

        renderCell: (params) => {
          return (
            <React.Fragment>
              <Avatar
                sx={{
                  width: 32, height: 32,
                  color: "text.primary",
                  fontSize: "12px",
                  bgcolor: "var(--grey-300)"
                }}
              >
                {params.row.vendor_name?.toUpperCase().slice(0, 1)}
              </Avatar>
              <Box sx={{ width: "calc(100% - 32px)", lineHeight: "normal", ml: 1 }}>
                <Tooltip
                  title={params.row.vendor_name}
                  disableInteractive
                  placement="bottom-start">
                  <Typography variant="body2" color="text.primary" lineHeight='normal' noWrap>
                    {params.row.vendor_name}
                  </Typography>
                </Tooltip>
                <Tooltip
                  title={params.row.vendor_code}
                  disableInteractive
                  placement="bottom-start"
                >
                  <Typography variant="caption" component="span" display="block" color="grey.500" lineHeight='normal' noWrap>
                    {params.row.vendor_code}
                  </Typography>
                </Tooltip>
              </Box>
            </React.Fragment>
          );
        },
      },
      {
        field: "vendor_status",
        headerName: "VENDOR STATUS",
        headerAlign: "left",
        align: "left",
        width: 150,
        filterable: false,
        sortable: false,
        editable: false,
        renderCell: (params) => {
          return (
            <React.Fragment>
              <Typography fontWeight={500}>
                {getVendorGMPFlag(this.props.companyInfo.gst_verification, params.row.gstin_number, params.row.gstin_status, 'G', "GSTIN")}
                {params.row.industry_type === "MSME" ?
                  getVendorGMPFlag(this.props.companyInfo.msme_verification, params.row.industry_type_value, params.row.msme_status, 'M', "MSME") :
                  getVendorGMPFlag(this.props.companyInfo.msme_verification, "", params.row.msme_status, 'M', "MSME")
                }
                {getVendorGMPFlag(this.props.companyInfo.pan_verification, params.row.pan_number, params.row.pan_status, 'P', "PAN")}
              </Typography>
            </React.Fragment>
          );
        },
      },
      {
        field: "email",
        headerName: "Email",
        headerAlign: "left",
        align: "left",
        width: 236,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return (
            <Tooltip
              title={params?.row?.email}
              disableInteractive
              placement="bottom-start"
            >
              <Typography variant="body2" color="text.primary" noWrap>
                {params?.row?.email}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "created_by_account",
        headerName: "Created From Account",
        headerAlign: "left",
        align: "left",
        width: 200,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return (
            <Tooltip
              title={params?.row?.created_by_account}
              disableInteractive
              placement="bottom-start"
            >
              <Typography variant="body2" color="text.primary" noWrap>
                {params?.row?.created_by_account}
              </Typography>
            </Tooltip>
          );
        },
      },
    ];
    return (
      <div>
        <Box>
          <QuickSearchToolbar
            value={this.state.searchText}
            onChange={(event) => this.requestSearch(event.target.value)}
            clearSearch={() => this.requestSearch("")}
          />
          <Box
            sx={{
              height: {
                xs: "calc(100vh - 258px)",
                sm: "calc(100vh - 208px)",
                md: "calc(100vh - 248px)",
              },
              width: "100%"
            }}
          >
            <DataGridPro
              sx={{
                border: "none",
              }}
              columns={columns}
              rows={
                this.state.searchText.trim() === ""
                  ? this.state.rows
                  : this.state.searchResult
              }
              disableColumnMenu
              loading={this.state.loading}
              headerHeight={40}
              columnHeaderHeight={40}
              rowHeight={48}
              slots={{
                noRowsOverlay: () => (
                  <GridOverlay>
                    <Typography>No vendor(s) found</Typography>
                  </GridOverlay>
                ),
                footer: () => (
                  <Box
                    sx={{
                      textAlign: "right",
                      padding: "16px",
                      borderTop: "1px solid var(--bluegrey-500)"
                    }}
                  >
                    <Typography>
                      {this.state.searchText.trim() === ""
                        ? `Showing ${this.state.rows.length} / ${this.state.total}`
                        : `Showing ${this.state.searchResult.length} / ${this.state.searchResultTotal}`}
                    </Typography>
                  </Box>
                ),
              }}
              disableColumnSelector
              hideFooterPagination
              onRowsScrollEnd={this.handleScrollEnd}
              checkboxSelection
              onRowSelectionModelChange={(newSelectionModel) => {
                this.setState({ selectionModel: newSelectionModel });
              }}
              rowSelectionModel={this.state.selectionModel}
            />
          </Box>
        </Box>
        <Divider />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={{ xs: 1, md: 2 }}
          p={2}
          flexWrap={{ xs: "wrap", sm: "unset" }}
        >
          <Typography
            variant="caption"
            color={"error"}
            lineHeight="normal"
            sx={{
              flex: { xs: "0 0 100%", sm: "unset" }
            }}
          >
            *Note: Importing A Vendor Will Only Update Or Exist In The Master Data Linking Or Unlinking Must Still Be Managed Within The Linked Vendor.
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            gap="8px"
          >
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => {
                this.props.handleClose();
                this.setState({
                  rows: [],
                });
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={this.handleSubmit}
            >
              Add
            </Button>
          </Box>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  sessionId: state.sessionReducer.sessionId,
  masterPermission: state.permissionsReducer.masterPermission,
  companyInfo: state.companyInfoReducer.companyInfo,
});

export default connect(mapStateToProps, { openSnackbar })(
  sendRfqToNewVendorCategory
);
