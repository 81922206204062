import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import _, { debounce } from "lodash";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Alert, Badge, ToggleButton, Tooltip } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ResizableBox } from "react-resizable";
import Upload from "../../Dialogs/Upload/Upload";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import NegotiationFormField from "../NegotiationFormField";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import UploadDynamicView from "../../Dialogs/Upload/UploadDynamicView";
import { postFileSpecifications } from "../../../config/apiUrl";
import axios from "axios";

export default function NegotiationTemplateSectionTable(props) {
  const [disabledProps, setDisabledProps] = useState({});
  const [popHash, setPopHash] = useState({});
  const [Toggle, setToggle] = useState({});
  const [ToggleMS, setToggleMS] = useState({});
  const [fileSpecificationsOpen, setFileSpecificationsOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [state, setState] = useState({
    isScrollActive: false,
    currentItemIndex: null,
    anchorEl: null,
    qty: "",
    rate: "",
    estimatedValueOpen: false,
  });
  const [Loading, setLoading] = useState(true);

  const [AllItemsTemplate, setAllItemsTemplate] = useState([]);
  const [specification, setSpecification] = useState([]);
  const [SpecificationText, setSpecificationText] = useState("");
  const [templateData, setTemplateData] = useState({});
  const [destinationCount, setDestinationCount] = useState(0);
  const [FilteredMainTemplate, setFilteredMainTemplate] = useState({});
  const [FilteredSlabMainTemplate, setFilteredSlabMainTemplate] = useState({});
  const [hoverTooltip, setHoverTooltip] = useState(false);
  const { multiSolmainTemplate, handleUpdateTxn } = props;
  const [iconState, setIconState] = useState({});
  const [iconStateMS, setIconStateMS] = useState({});
  const [destinationValues, setDestinationValues] = useState({});
  const [keyValueWithHashGSTType, setKeyValueWithHashGSTType] = useState({});

  const handleParentCheckboxChange = (event, key) => {
    // Ensure that mainTemplate and lastTransactionTypeInThread exist before accessing their properties

    let updatedTransactionData = { ...props.slabMainTemplate };

    if (
      props.lastTransactionInThread &&
      props.lastTransactionInThread[key] &&
      props.slabMainTemplate &&
      props.slabMainTemplate[key]
    ) {
      const isNegotiation = props.lastTransactionTypeInThread === "Negotiation";
      const lastSelected = props.lastTransactionInThread[key].selected === "Y";
      const lastTxn = props.lastTransactionInThread[key];
      const mainSelected = props.slabMainTemplate[key].selected === "Y";
      const is_deleted_from_master = props.items[key].is_deleted_from_master;
      const is_unlink_from_category = props.items[key].is_unlink_from_category;

      // Check if filteredSelectedNotData contains the key and its selected value is 'N'
      const filteredSelectedNotDataContainsKey =
        props.filteredSelectedNotData &&
        props.filteredSelectedNotData[key] &&
        props.filteredSelectedNotData[key].selected;

      if (is_deleted_from_master || is_unlink_from_category) {
        if (updatedTransactionData && updatedTransactionData[key]) {
          updatedTransactionData[key].selected = "N";
        }
        props.setSlabMainTemplate(updatedTransactionData);
      } else if (!lastSelected) {
        if (updatedTransactionData && updatedTransactionData[key]) {
          updatedTransactionData[key].selected = "N";
        }
        props.setSlabMainTemplate(updatedTransactionData);
      } else {
        if (!event.target.checked) {
          updatedTransactionData[key].selected = "N";
          updatedTransactionData[key].forEach((child) => {
            child.select_slab = event.target.checked ? "Y" : "N"; // Update all child checkboxes
          });

          props.setSlabMainTemplate(updatedTransactionData);
        } else {
          updatedTransactionData[key].selected = "Y";
          updatedTransactionData[key].forEach((child, index) => {
            if (lastTxn?.solution[0]?.data?.[index].select_slab === "Y") {
              child.select_slab = "Y"; // Update all child checkboxes
            }
          });
          props.setSlabMainTemplate(updatedTransactionData);
        }
      }
    }
    if (
      props.lastTransactionTypeInThread !== "Inquiry" &&
      props.multiplePlantsFlag
    ) {
      handleSaveClick();
    }
  };

  const handleChildCheckboxChange = (event, key, rowIndex, solutionRange) => {
    if (
      props.lastTransactionInThread &&
      props.lastTransactionInThread[key] &&
      props.slabMainTemplate &&
      props.slabMainTemplate[key]
    ) {
      const isNegotiation = props.lastTransactionTypeInThread === "Negotiation";
      const lastSelected =
        props.lastTransactionInThread[key].solution[0].data[rowIndex]
          .select_slab === "Y";
      const mainSelected =
        props.slabMainTemplate[key][rowIndex].select_slab === "Y";
      const is_deleted_from_master = props.items[key].is_deleted_from_master;
      const is_unlink_from_category = props.items[key].is_unlink_from_category;

      const filteredDataItems =
        props.lastTransactionData?.itemize[key].solution[0].data[rowIndex]
          .select_slab === "N";
      const filteredSelectedNotDataContainsKey = filteredDataItems;
      const slabChildStateToggleCheckMain =
        props.slabChildStateToggleCheck[key][rowIndex];

      let updatedTransaction = { ...props.slabMainTemplate };

      if (is_deleted_from_master || is_unlink_from_category) {
        if (updatedTransaction && updatedTransaction[key]) {
          updatedTransaction[key][rowIndex].select_slab = "N";
        }
        props.setSlabMainTemplate(updatedTransaction);
      } else if (!lastSelected) {
        if (updatedTransaction && updatedTransaction[key]) {
          updatedTransaction[key][rowIndex].select_slab = "N";
        }
        props.setSlabMainTemplate(updatedTransaction);
      } else {
        // console.log("updatedTransaction slab 5");
        if (!event.target.checked) {
          if (updatedTransaction && updatedTransaction[key]) {
            updatedTransaction[key][rowIndex].select_slab = "N";
          }
        } else {
          if (updatedTransaction && updatedTransaction[key]) {
            updatedTransaction[key][rowIndex].select_slab = "Y";
          }
        }
        props.setSlabMainTemplate(updatedTransaction);
      }
      // Check if all child checkboxes are unchecked
      const allUnchecked = updatedTransaction[key].every(
        (item) => item.select_slab !== "Y"
      );
      if (allUnchecked) {
        const parentUpdatedData = { ...props.slabMainTemplate };
        parentUpdatedData[key].selected = "N";
        props.setSlabMainTemplate(parentUpdatedData);
      } else {
        // Check if any child checkbox is checked
        const anyChecked = updatedTransaction[key].some(
          (item) => item.select_slab === "Y"
        );
        if (anyChecked) {
          const parentUpdatedData = { ...props.slabMainTemplate };
          parentUpdatedData[key].selected = "Y";
          props.setSlabMainTemplate(parentUpdatedData);
        }
      }

      // Update the disabledRows state
      const updatedDisabledRows = { ...props.disabledRows };
      if (!event.target.checked) {
        updatedDisabledRows[`${key}-${solutionRange}`] = true;
      } else {
        updatedDisabledRows[`${key}-${solutionRange}`] = false;
      }
      props.setDisabledRows(updatedDisabledRows);
    } else {
      if (props.slabMainTemplate[key]?.added_after_rfq_created == true) {
        const updatedTransaction = { ...props.slabMainTemplate };
        if (updatedTransaction && updatedTransaction[key]) {
          updatedTransaction[key][rowIndex].select_slab = event.target.checked
            ? "Y"
            : "N";
        }
        props.setSlabMainTemplate(updatedTransaction);
      }
      console.error(
        `Template with key ${key} does not exist in either mainTemplate or lastTransactionInThread.`
      );
    }
    if (
      props.lastTransactionTypeInThread !== "Inquiry" &&
      props.multiplePlantsFlag
    ) {
      handleSaveClick();
    }
  };

  const handleAddClick = (event, key) => {
    setIconState((prevState) => ({
      ...prevState,
      [key]: true,
    }));
    setToggle((prevState) => ({
      ...prevState,
      [key]: true,
    }));
  };

  const handleRemoveClick = (event, key) => {
    setIconState((prevState) => ({
      ...prevState,
      [key]: false,
    }));
    setToggle((prevState) => ({
      ...prevState,
      [key]: false,
    }));
  };

  const handleAddClickForMS = (event, key) => {
    setIconStateMS((prevState) => {
      const newState = { ...prevState, [key]: true };
      return newState;
    });

    setToggleMS((prevState) => {
      const newState = { ...prevState, [key]: true };
      return newState;
    });
  };

  const handleRemoveClickForMS = (event, key) => {
    setIconStateMS((prevState) => {
      const newState = { ...prevState, [key]: false };
      return newState;
    });

    setToggleMS((prevState) => {
      const newState = { ...prevState, [key]: false };
      return newState;
    });
  };

  useEffect(() => {
    if (props.destination) {
      const destinationsArray = props.destination.split("###");
      setDestinationCount(destinationsArray?.length);
    }
  }, [props.destination]);

  useEffect(() => {
    const filterMainTemplate = {};

    Object.keys(props.mainTemplate).forEach((key) => {
      if (props.mainTemplate[key]?.selected === "Y") {
        filterMainTemplate[key] = props.mainTemplate[key];
      }
    });
    setFilteredMainTemplate(filterMainTemplate);
  }, [props.mainTemplate]);

  useEffect(() => {
    const filterSlabMainTemplate = {};

    Object.keys(props.slabMainTemplate).forEach((key) => {
      if (props.slabMainTemplate[key]?.selected === "Y") {
        filterSlabMainTemplate[key] = props.slabMainTemplate[key];
      }
    });
    setFilteredSlabMainTemplate(filterSlabMainTemplate);
  }, [props.slabMainTemplate]);

  useEffect(() => {
    if (props.data && props.data[0]) {
      // Convert templateJson to key-value pairs
      const keyValueData = {};
      props.data[0].forEach((item) => {
        keyValueData[item.FieldId] = item;
      });
      // Update state with key-value data
      setTemplateData(keyValueData);
    }
  }, [props.data]);

  useEffect(() => {
    if (Object.keys(keyValueWithHashGSTType).length > 0) {
      let gstTypes = keyValueWithHashGSTType.value.split("###");
      const result = gstTypes.reduce((acc, current, index) => {
        acc[index] = current;
        return acc;
      }, {});
      setDestinationValues(result);
    }
  }, [keyValueWithHashGSTType]);

  useEffect(() => {
    const gstTypeField =
      props.itemizeData.find((item) => item.Identifier === "GSTType") ||
      props.foreignData.find((item) => item.Identifier === "GSTType");
    let selectedItems = Object.values(props.mainTemplate).filter((main) => {
      return main?.selected == "Y";
    });
    if (gstTypeField) {
      const key = gstTypeField.FieldId;
      let value = "";
      if (props.additionalInfoTemplate?.hasOwnProperty(key)) {
        value = props.additionalInfoTemplate[key]["value"];
      } else if (Object.values(selectedItems)[0]?.hasOwnProperty(key)) {
        value = Object.values(selectedItems)[0][key]["value"];
      }
      if (value) {
        setKeyValueWithHashGSTType({ key, value });
      }
    }
  }, [
    props.lastTransactionInThreadCommon,
    props.itemizeData,
    props.additionalInfoTemplate,
    props.mainTemplate,
  ]);

  const handleSaveClick = () => {
    try {
      const savedValues = Object.values(destinationValues).join("###");
      props.handleSave(savedValues);
    } catch (error) {
      console.error("Error in handleSaveClick:", error);
    }
  };

  function rearrangeArray(array) {
    // Step 1: Build a mapping of FieldId to their respective objects
    let fieldMap = {};
    array.forEach((item) => {
      fieldMap[item.FieldId] = item;
    });

    // Step 2: Initialize an empty array to store the rearranged items
    let rearranged = [];

    // Step 3: Helper function to recursively resolve dependencies
    const resolveDependency = (identifier, visited) => {
      if (visited.has(identifier)) {
        throw new Error(
          "Circular dependency detected for FieldId: " + identifier
        );
      }

      visited.add(identifier);

      // Get the item for the current identifier
      const item = fieldMap[identifier];

      // Resolve dependencies recursively
      const matches = item.DefaultValue?.Formula?.match(/\(f\d+\)/g);
      if (matches) {
        matches.forEach((dependency) => {
          const depIdentifier = dependency.substring(2, dependency.length - 1);
          if (fieldMap.hasOwnProperty(depIdentifier)) {
            resolveDependency(depIdentifier, visited);
          }
        });
      }

      // Add the item to the rearranged array if it's not already added
      if (!rearranged.some((i) => i.FieldId === item.FieldId)) {
        rearranged.push(item);
      }

      visited.delete(identifier);
    };

    // Step 4: Iterate over the array to start resolving dependencies
    array.forEach((item) => {
      if (!rearranged.some((i) => i.FieldId === item.FieldId)) {
        resolveDependency(item.FieldId, new Set());
      }
    });

    // Return the rearranged array
    return rearranged;
  }

  useEffect(() => {
    setTooltipOpen(true);
  }, []);

  useEffect(() => {
    let allItems = [];
    let templateData = props.template;
    let elementTypes = [];

    for (let section in templateData) {
      if (section !== "PARAM") {
        if (section === "ITEMIZE") {
          allItems = allItems.concat(templateData[section][0]);
        } else {
          allItems = allItems.concat(templateData[section]);
        }
      }
    }
    props.setItemsTemplate(allItems);
    setAllItemsTemplate(allItems);
    allItems.forEach((items) => {
      if (items?.ElementType === "D") {
        elementTypes.push(items);
      }
    });
    let rearrangedArray = rearrangeArray(elementTypes);
    props.setRearrangedTemplate(rearrangedArray);
  }, [props.template]);

  const handleUplaodSpecificationOpen = (
    index,
    specifications,
    specificationText
  ) => {
    setFileSpecificationsOpen(!fileSpecificationsOpen);
    setPopHash({ index: index, type: "specification" });
    getExistingFiles(specifications);
    setSpecificationText(specificationText);
  };

  const getExistingFiles = (file_id) => {
    const queryString = file_id
      .map((file) => `file_ids[]=${file.id}`)
      .join("&");
    const url = `${postFileSpecifications}?${queryString}`;
    axios
      .get(url)
      .then((res) => {
        setSpecification(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, " downloadFile error");
        this.props.openSnackbar(
          "error",
          err.response.data?.message
            ? err.response.data?.message
            : "Something went wrong!"
        );
        setLoading(false);
      });
  };
  const handleUploadClose = () => {
    setFileSpecificationsOpen(false);
    setSpecification([]);
    setLoading(true);
    setSpecificationText("");
  };

  const updateDisabledProps = () => {
    const disabledProps = {};
    for (const key in props.mainTemplate) {
      const isNegotiation = props.lastTransactionTypeInThread === "Negotiation";
      const lastSelected = props.lastTransactionInThread[key]?.selected === "Y";
      const mainSelected = props.mainTemplate[key]?.selected === "Y";
      const is_deleted_from_master =
        props.mainTemplate[key]?.is_deleted_from_master;
      const is_unlink_from_category =
        props.mainTemplate[key]?.is_unlink_from_category;
      const filteredSelectedNotDataSelected =
        props.filteredSelectedNotData[key]?.selected;

      // Check if either is_deleted_from_master or is_unlink_from_category is true, if so, disable the checkbox
      if (is_deleted_from_master || is_unlink_from_category) {
        disabledProps[key] = true;
      } else if (
        props.lastTransactionTypeInThread === "Inquiry" &&
        !mainSelected
      ) {
        disabledProps[key] = true;
      } else {
        // Your existing conditions
        if (isNegotiation) {
          if (lastSelected) {
            if (mainSelected) {
              disabledProps[key] = false;
            }
          } else {
            if (filteredSelectedNotDataSelected == "N") {
              if (mainSelected) {
                disabledProps[key] = false;
              } else {
                disabledProps[key] = true;
              }
            } else {
              disabledProps[key] = true;
            }
          }
        } else {
          if (!mainSelected && filteredSelectedNotDataSelected == "N") {
            // Allow toggling between 'Y' and 'N' when mainSelected is 'N' and
            // filteredSelectedNotDataSelected is 'N'
            disabledProps[key] = true;
          } else if (!mainSelected && filteredSelectedNotDataSelected !== "N") {
            disabledProps[key] = true;
          } else if (!mainSelected && filteredSelectedNotDataSelected !== "N") {
            disabledProps[key] = true;
          } else if (!mainSelected) {
            // nego = N , RFQ = Y
            // Enable item if mainSelected matches filteredSelectedNotDataSelected
            disabledProps[key] = false;
          }
          //    else if (mainSelected) {
          //     // nego = N , RFQ = Y
          //     // Enable item if mainSelected matches filteredSelectedNotDataSelected
          //     disabledProps[key] = true;
          //   }
          else if (mainSelected && filteredSelectedNotDataSelected == "N") {
            // nego = N , RFQ = Y
            // Enable item if mainSelected matches filteredSelectedNotDataSelected
            disabledProps[key] = false;
          } else {
            disabledProps[key] = false;
          }
        }
      }
    }
    props.disabledProps(disabledProps);
    setDisabledProps(disabledProps);
  };

  useEffect(() => {
    updateDisabledProps();
  }, [
    props.lastTransactionTypeInThread,
    props.lastTransactionInThread,
    props.mainTemplate,
    multiSolmainTemplate,
  ]);

  const getTableBody = () => {
    let itemsToRender;
    const { SlabTableToggle } = state;

    if (props.negotiationJsonData || props.negotiationJsonData[0]) {
      const { mainTemplate } = props;
      let filteredItems = {};
      if (props.itemSpecific) {
        filteredItems = Object.entries(props.negotiationJsonData)
          .filter(([key, value]) =>
            props.inquiryItemsId.includes(parseInt(key))
          )
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {});
      } else {
        filteredItems = props.negotiationJsonData;
      }
      const filteredItemsMap = new Map(
        Object.entries(filteredItems).map((item) => [item[0], item[1]])
      );
      const reorderedTransactionItemSequence = props?.transactionItemSequence
        .filter((key) => filteredItemsMap.has(key)) // Filter keys that are present in map
        .map((key) => [key, filteredItemsMap.get(key)]);
      // itemsToRender = Object.entries(filteredItems)
      //   .sort(([, a], [, b]) => a.name.localeCompare(b.name))
      itemsToRender = reorderedTransactionItemSequence.map(
        ([key, value], index) => {
          const solutions = props.multiSolmainTemplate[key] || [];
          const lastSelected =
            props.lastTransactionInThread[key]?.selected === "Y";
          const mainSelected = props.mainTemplate[key]?.selected === "Y";
          let hasError = false;
          if (
            props.multipleSolutionFlag &&
            !props.slabFlag &&
            props.switchState[key] &&
            mainTemplate[key]?.selected === "Y"
          ) {
            if (props.multiplePlantsFlag) {
              hasError =
                props.multiSolmainTemplate &&
                props.multiSolmainTemplate?.[key]?.some((item) => {
                  return Object.values(item)?.some(
                    (field) =>
                      field?.error === true && field?.Identifier != "GSTType"
                  );
                });
            } else {
              hasError =
                props.multiSolmainTemplate &&
                props.multiSolmainTemplate?.[key]?.some((item) => {
                  return Object.values(item)?.some(
                    (field) => field?.error === true
                  );
                });
            }
          }
          return (
            <>
              <TableRow key={key}>
                <TableCell
                  sx={{
                    WebkitBoxShadow: {
                      md: state.isScrollActive && "11px 0 19px -2px #bebebe54",
                      sm: "none",
                    },
                    boxShadow: {
                      md: state.isScrollActive && "11px 0 19px -2px #bebebe54",
                      sm: "none",
                    },
                  }}
                >
                  <Box display="flex" alignItems="center" gap="16px">
                    <Checkbox
                      checked={mainTemplate[key]?.selected === "Y"} // Assuming 'selected' indicates whether the item is selected
                      onChange={(event) =>
                        props.handleCheckboxChange(event, key)
                      } // Call a function to handle checkbox change
                      sx={{ "&.MuiCheckbox-root": { mr: 0, p: 0 } }}
                    />
                    {props.multipleSolutionFlag && !props.slabFlag && (
                      <div key={key}>
                        {/* {(props.multipleSolutionFlag && !props.slabFlag ) && ( */}
                        <>
                          {!iconStateMS[key] ? (
                            props.switchState[key] ? (
                              <Tooltip
                                title={`Click here to view ${props.alternativeNames?.alternate_options || "Multiple Solution"}`}
                                placement="bottom-start"
                                disableInteractive
                              >
                                <Badge
                                  color={"primary"}
                                  sx={{
                                    "& .MuiBadge-badge": {
                                      backgroundColor: "var(--secondary-500)",
                                    },
                                  }}
                                  badgeContent={solutions?.length}
                                >
                                  <AddIcon
                                    onClick={(event) =>
                                      handleAddClickForMS(event, key)
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </Badge>
                              </Tooltip>
                            ) : (
                              <AddIcon
                                // onClick={(event) => handleAddClickForMS(event, key)}
                                style={{ cursor: "pointer" }}
                              />
                            )
                          ) : props.switchState[key] ? (
                            <Tooltip
                              title={`Click here to close ${props.alternativeNames?.alternate_options || "Multiple Solution"}`}
                              placement="bottom-start"
                              disableInteractive
                            >
                              <Badge
                                color="primary"
                                sx={{
                                  "& .MuiBadge-badge": {
                                    backgroundColor: "var(--secondary-500)",
                                  },
                                }}
                                badgeContent={solutions?.length}
                              >
                                <RemoveIcon
                                  onClick={(event) =>
                                    handleRemoveClickForMS(event, key)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </Badge>
                            </Tooltip>
                          ) : (
                            <RemoveIcon
                              // onClick={(event) => handleRemoveClickForMS(event, key)}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </>
                        {/* )} */}
                      </div>
                    )}
                    {props.multipleSolutionFlag && !props.slabFlag && (
                      <Tooltip
                        title={props.alternativeNames?.alternate_options || "Multiple Solution"}
                        placement="bottom-start"
                        disableInteractive
                      >
                        <Switch
                          // checked={props.lastTransactionTypeInThread !== "Inquiry" ? props.switchState[key]: true}
                          checked={props.switchState[key]}
                          // disabled={props.lastTransactionTypeInThread !== "Inquiry"}
                          disabled={true}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Tooltip>
                    )}
                    <Tooltip // This is for Attachments specific files
                      title="Specification"
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Badge
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        // badgeContent={value?.files?.length}
                        badgeContent={`${value?.files?.length}`}
                        color="primary"
                      >
                        <AttachFileIcon
                          onClick={() =>
                            value?.files?.length > 0 &&
                            handleUplaodSpecificationOpen(
                              key,
                              value.files,
                              value.specification_text
                            )
                          }
                          sx={{ cursor: "pointer" }}
                        />
                      </Badge>
                    </Tooltip>
                    <Tooltip //Item name header starts from here
                      title={_.unescape(value.name)}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Badge
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        badgeContent={
                          props.mainTemplate[key]?.added_after_rfq_created
                            ? "New"
                            : null
                        }
                        color="primary"
                        sx={{
                          "& .MuiBadge-badge": {
                            top: "-5px",
                            height: "16px",
                            fontSize: "10px",
                          },
                        }}
                      >
                        <Typography
                          noWrap
                          variant="body2"
                          color="text.primary"
                          fontWeight={500}
                          sx={{
                            width: {
                              lg: `${
                                state.itemCellWidth > 300
                                  ? state.itemCellWidth
                                  : 300
                              }px`,
                              md: `${
                                state.itemCellWidth > 200
                                  ? state.itemCellWidth
                                  : 200
                              }px`,
                              sm: `${
                                state.itemCellWidth > 200
                                  ? state.itemCellWidth
                                  : 200
                              }px`,
                              xs: `${
                                state.itemCellWidth > 125
                                  ? state.itemCellWidth
                                  : 125
                              }px`,
                            },
                            // width: `${
                            //   state.itemCellWidth > 350
                            //     ? state.itemCellWidth
                            //     : 350
                            // }px`,
                            marginLeft: "10px",
                          }}
                        >
                          {_.unescape(value.name)}
                        </Typography>
                      </Badge>
                      <Typography
                        sx={{ color: "error.main", marginLeft: "10px" }}
                      >
                        {props.mainTemplate[key]?.is_deleted_from_master &&
                          "This item is deleted from item master."}
                      </Typography>
                      <Typography
                        sx={{ color: "error.main", marginLeft: "10px" }}
                      >
                        {props.mainTemplate[key]?.is_unlink_from_category &&
                          "This item is unlinked from category."}
                      </Typography>

                      {props.multipleSolutionFlag &&
                        !props.slabFlag &&
                        props.switchState[key] &&
                        (hasError ||
                          props.solutionNameErrors[
                            Object.keys(props.solutionNameErrors).find((k) =>
                              k.startsWith(key)
                            )
                          ]) && (
                          <Typography
                            sx={{ color: "error.main", marginLeft: "10px" }}
                          >
                            There are errors in solution
                          </Typography>
                        )}
                      {/* {props.mainTemplate[key]
                        ?.added_after_rfq_created && (
                        <Chip
                          label="New"
                          size="small"
                          color="primary"
                          sx={{ marginLeft: "10px", height: "16px" }}
                        />
                      )} */}
                    </Tooltip>
                  </Box>
                </TableCell>
                {props.data[0]?.find(
                  (obj) =>
                    obj.Identifier === "ProductCode" &&
                    obj.VisibilityFlag === "Y"
                ) && (
                  <TableCell
                  // sx={{
                  //   WebkitBoxShadow:
                  //     state.isScrollActive && "11px 0 19px -4px #bebebe54",
                  //   boxShadow:
                  //     state.isScrollActive && "11px 0 19px -4px #bebebe54",
                  // }}
                  >
                    <Tooltip //Item code header starts from here
                      title={_.unescape(value.code)}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Typography
                        noWrap
                        variant="body2"
                        color="text.primary"
                        fontWeight={500}
                        // sx={{
                        //   width: {
                        //     lg: `${state.itemCellWidth > 300
                        //       ? state.itemCellWidth
                        //       : 250
                        //       }px`,
                        //     md: `${state.itemCellWidth > 200
                        //       ? state.itemCellWidth
                        //       : 200
                        //       }px`,
                        //     sm: `${state.itemCellWidth > 200
                        //       ? state.itemCellWidth
                        //       : 200
                        //       }px`,
                        //     xs: `${state.itemCellWidth > 125
                        //       ? state.itemCellWidth
                        //       : 125
                        //       }px`,
                        //   },
                        // }}
                      >
                        {_.unescape(value.code)}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                )}
                {props.multipleSolutionFlag && !props.slabFlag && (
                  <TableCell>
                    <Tooltip //Item code header starts from here
                      title={props.lastTransactionInThread[key]?.name}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="text"
                        value={
                          _.unescape(props.solutionNames[`${key}-${0}`]) || ""
                        } // Always take value from props.solutionNames
                        error={!!props.errorArrayForMSParentName[key]}
                        helperText={props.errorArrayForMSParentName[key] || ""}
                        disabled={true}
                      />
                    </Tooltip>
                  </TableCell>
                )}
                {props?.data[index]?.map((cell) => {
                  const DefaultFormula = cell.DefaultFormula;

                  // Regular expression to extract field IDs
                  const regex = /f(\d+)/g;
                  let match;
                  const fieldIDs = [];

                  // Extract field IDs from DefaultFormula
                  while ((match = regex.exec(DefaultFormula)) !== null) {
                    fieldIDs.push(match[1]);
                  }

                  // Assuming you always have two field IDs in the DefaultFormula
                  const [field1, field2] = fieldIDs;

                  // Initialize FieldState
                  const FieldState = {};

                  // If field IDs are found, set data in FieldState
                  if (field1 && field2) {
                    // Assuming num1 and num2 are the values you want to set
                    //  const num1 = /* Your value for field1 */;
                    //  const num2 = /* Your value for field2 */;
                    // Setting data in FieldState for both field1 and field2
                    //  const num1 = FieldState[field1];
                    //  const num2 = FieldState[field2];
                  }
                  // All I/P fields comes from there in the template section table
                  if (
                    cell.VisibilityFlag === "Y" ||
                    (cell.DefaultValue &&
                      cell.DefaultValue.Button === "Y" &&
                      cell.ElementType === "D")
                  ) {
                    if (
                      props.multiplePlantsFlag &&
                      cell.Identifier === "GSTType"
                    ) {
                      return null;
                    }
                    if (
                      !(
                        cell.Identifier === "Product" ||
                        cell.Identifier === "ProductCode" ||
                        cell.Identifier === "ProductForeign"
                      )
                    ) {
                      return (
                        <TableCell
                          key={cell.FieldId}
                          // sx={{ minWidth: "120px" }}
                        >
                          <NegotiationFormField
                            inquiryId={props.inquiryId}
                            batch_id={props.batch_id}
                            LATEST={props.LATEST}
                            vendorType={props?.vendorType}
                            cell={cell}
                            item={value}
                            index={index}
                            FieldState={
                              mainTemplate[key] &&
                              mainTemplate[key][cell?.FieldId]
                            }
                            base_currency={props?.base_currency}
                            selectedCurrency={props?.selectedCurrency || ""}
                            updateDeliveryScheduleFieldValue={
                              props.updateDeliveryScheduleFieldValue
                            }
                            handleChange={(
                              evt,
                              FieldId,
                              Identifier,
                              FieldLabel,
                              ElementType,
                              key
                            ) =>
                              props.handleChange(
                                evt,
                                index,
                                FieldId,
                                Identifier,
                                FieldLabel,
                                ElementType,
                                key
                              )
                            }
                            handleDateChange={(newVal, FieldId, Validation) =>
                              props.handleDateChange(
                                newVal,
                                FieldId,
                                "mainTemplate",
                                index,
                                Validation,
                                key
                              )
                            }
                            default_dropdown_type={props.default_dropdown_type}
                            destination={props.destination}
                            SlabTableToggle={SlabTableToggle}
                            disabled={
                              Object.keys(disabledProps).length > 0 &&
                              disabledProps[key]
                            }
                            keys={key.length > 0 && key}
                            mainTemplate={props.mainTemplate}
                            multiSolmainTemplate={props.multiSolmainTemplate}
                            multiplePlantsFlag={props.multiplePlantsFlag}
                            isServiceRfq={props.isServiceRfq}
                            multipleSolutionFlag={props.multipleSolutionFlag}
                            slabFlag={props.slabFlag}
                            switchState={props.switchState[key]}
                            DetailsClickFlag={false}
                          />
                        </TableCell>
                      );
                    }
                  }
                })}
              </TableRow>
              {props.slab[key] && props.slab[key].isOpen && (
                <TableRow>
                  <TableCell style={{ padding: 0 }} colSpan={100}>
                    <TableContainer
                      className="quotation_template_table slab_range_table"
                      sx={{
                        maxHeight: "400px",
                        overflowY: "auto",
                        borderTop: "solid 1px",
                        borderColor: (theme) => theme.palette.bluegrey[500],
                        borderBottomRightRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                      onScroll={debounce((event) => {
                        if (
                          event.target.scrollLeft === 0 &&
                          state.isScrollActive
                        ) {
                          setState({
                            isScrollActive: false,
                          });
                        } else if (
                          event.target.scrollLeft !== 0 &&
                          !state.isScrollActive
                        ) {
                          setState({
                            isScrollActive: true,
                          });
                        }
                      }, 200)}
                    >
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                WebkitBoxShadow:
                                  state.isScrollActive &&
                                  "11px 0 19px -2px #bebebe54",
                                boxShadow:
                                  state.isScrollActive &&
                                  "11px 0 19px -2px #bebebe54",
                              }}
                            >
                              <Box
                                className=""
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Checkbox sx={{ visibility: "hidden" }} />
                                <Tooltip
                                  title={
                                    props.data[0]?.find(
                                      (obj) =>
                                        obj.Identifier === "Product" ||
                                        obj.Identifier === "ProductForeign"
                                    )?.FieldLabel
                                  }
                                >
                                  <Box
                                    sx={{
                                      minWidth: "350px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography fontWeight={500}>
                                      {
                                        props.data[0]?.find(
                                          (obj) =>
                                            obj.Identifier === "Product" ||
                                            obj.Identifier === "ProductForeign"
                                        )?.FieldLabel
                                      }
                                    </Typography>

                                    <ResizableBox
                                      axis="x"
                                      height={20}
                                      width={20}
                                      onResizeStop={(e, data) => {
                                        setState({
                                          itemCellWidth: data.size.width,
                                        });
                                      }}
                                      style={{
                                        textAlign: "left",
                                        width: "100%",
                                      }}
                                      minConstraints={[20, 20]}
                                    ></ResizableBox>
                                  </Box>
                                </Tooltip>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Tooltip
                                  title={
                                    props.data[0]?.find(
                                      (obj) => obj.Identifier === "ProductCode"
                                    )?.FieldLabel
                                  }
                                >
                                  <Typography fontWeight={500}>
                                    {
                                      props.data[0]?.find(
                                        (obj) =>
                                          obj.Identifier === "ProductCode"
                                      )?.FieldLabel
                                    }
                                  </Typography>
                                </Tooltip>
                                <ResizableBox
                                  axis="x"
                                  height={20}
                                  width={20}
                                  style={{
                                    textAlign: "left",
                                    width: "100%",
                                  }}
                                  minConstraints={[20, 20]}
                                ></ResizableBox>
                              </Box>
                            </TableCell>

                            {props.data[0]?.map((cell, index) => {
                              if (
                                props.multiplePlantsFlag === true &&
                                cell.Identifier === "GSTType"
                              ) {
                                return null;
                              }
                              if (
                                cell.VisibilityFlag === "Y" ||
                                (cell.DefaultValue &&
                                  cell.DefaultValue.Button === "Y" &&
                                  cell.ElementType === "D")
                              ) {
                                if (
                                  !(
                                    cell.Identifier === "Product" ||
                                    cell.Identifier === "ProductForeign" ||
                                    cell.Identifier === "ProductCode"
                                  )
                                ) {
                                  return (
                                    <TableCell key={cell.FieldId}>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Tooltip
                                          title="Apply to All"
                                          placement="bottom-start"
                                          disableInteractive
                                        >
                                          <ToggleButton
                                            value="check"
                                            selected={
                                              props.applyToAll[
                                                `${cell.FieldId}_applyToAll`
                                              ]
                                            }
                                            disabled={
                                              cell.Identifier ===
                                              "EstimatedValue"
                                            }
                                            // title="Apply to All"
                                            onClick={() => {
                                              //for not doing apply to all when field is read only
                                              let isReadOnly = false;
                                              if (!isReadOnly) {
                                                Object.keys(
                                                  props.mainTemplate
                                                ).map((key, i) => {
                                                  let element =
                                                    props.mainTemplate[key];
                                                  Object.keys(element).map(
                                                    (key1, i) => {
                                                      if (
                                                        key1 == cell.FieldId &&
                                                        typeof element[key1] ===
                                                          "object" &&
                                                        element[key1].readOnly
                                                      ) {
                                                        isReadOnly = key1;
                                                      }
                                                    }
                                                  );
                                                });
                                              }
                                              if (
                                                !props.applyToAll[
                                                  `${cell.FieldId}_applyToAll`
                                                ] &&
                                                isReadOnly != cell.FieldId
                                              ) {
                                                props.handleApplyToAllClick(
                                                  cell.FieldId,
                                                  cell.ElementType === "TB" &&
                                                    cell.ElementType === "TA",
                                                  cell.Identifier
                                                );
                                              }
                                            }}
                                            sx={{
                                              p: 0,
                                              mr: 1,
                                              border: 0,
                                            }}
                                          >
                                            {!["T", "D", "L"].includes(
                                              cell.ElementType
                                            ) ? (
                                              props.applyToAll[
                                                `${cell.FieldId}_applyToAll`
                                              ] ? (
                                                <CheckBoxIcon
                                                  sx={{
                                                    color: (theme) =>
                                                      theme.palette.primary
                                                        .main,
                                                  }}
                                                />
                                              ) : (
                                                <CheckBoxOutlineBlankIcon
                                                  sx={{
                                                    color: (theme) =>
                                                      theme.palette
                                                        .bluegrey[500],
                                                  }}
                                                />
                                              )
                                            ) : (
                                              ""
                                            )}
                                          </ToggleButton>
                                        </Tooltip>
                                        <Tooltip
                                          title={
                                            cell?.InstructionText === ""
                                              ? cell?.FieldLabel
                                              : cell?.InstructionText
                                          }
                                        >
                                          <Typography fontWeight={500}>
                                            {cell.FieldLabel}
                                          </Typography>
                                        </Tooltip>
                                        <ResizableBox
                                          axis="x"
                                          height={20}
                                          width={20}
                                          style={{
                                            textAlign: "left",
                                            width: "100%",
                                          }}
                                          minConstraints={[20, 20]}
                                        ></ResizableBox>
                                      </div>
                                    </TableCell>
                                  );
                                }
                              } else {
                                return "";
                              }
                            })}
                          </TableRow>
                        </TableHead>
                        {/* {getSecondTableBody()} */}
                      </Table>
                    </TableContainer>
                  </TableCell>
                </TableRow>
              )}
              {/* For Multiple Solution */}
              {props.multipleSolutionFlag &&
                !props.slabFlag &&
                props.multiSolmainTemplate[key] &&
                ToggleMS[key] &&
                getHeaders(props.multiSolmainTemplate[key], key, value)}
              {/* For Slab */}
              {/* {(props.slabFlag && props.multipleSolutionFlag) &&
                props.slabMainTemplate[key] &&
                getHeadersForSlab(props.slabMainTemplate[key], key)} */}
            </>
          );
        }
      );
    } else {
      itemsToRender = "loading...";
    }

    return (
      <TableBody className="MultiSolution-Body">{itemsToRender}</TableBody>
    );
  };

  const getTableSlabBody = () => {
    let itemsToRender;
    const { SlabTableToggle } = state;

    if (props.negotiationJsonData || props.negotiationJsonData[0]) {
      const { slabMainTemplate } = props;
      // const { slabMainTemplate } = props;
      let filteredItems = {};
      if (props.itemSpecific) {
        filteredItems = Object.entries(props.negotiationJsonData)
          .filter(([key, value]) =>
            props.inquiryItemsId.includes(parseInt(key))
          )
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {});
      } else {
        filteredItems = props.negotiationJsonData;
      }
      const filteredItemsMap = new Map(
        Object.entries(filteredItems).map((item) => [item[0], item[1]])
      );

      const reorderedTransactionItemSequence = props?.transactionItemSequence
        .filter((key) => filteredItemsMap.has(key)) // Filter keys that are present in map
        .map((key) => [key, filteredItemsMap.get(key)]);
      // itemsToRender = Object.entries(filteredItems)
      //   .sort(([, a], [, b]) => a.name.localeCompare(b.name))
      itemsToRender = reorderedTransactionItemSequence.map(
        ([key, value], index) => {
          const quantity = props.negotiationJsonData[key]?.quantity;

          // Get the solutions array from lastTransactionData
          const solutions = slabMainTemplate[key] || [];

          // Initialize an array to store the indices of filtered solutions
          let filteredSolutionIndices = [];

          // Filter selected slabs first
          const selectedSlabs = solutions.filter(
            (solution) => solution.select_slab === "Y"
          );

          // First, try to find an exact match
          let filteredSolutions = solutions.filter((solution, index) => {
            if (solution.select_slab === "Y") {
              const range = solution?._range;

              // Exact match logic for "More Than"
              if (range && range.includes("More Than")) {
                const min = parseInt(range.split(" ")[2], 10);
                if (quantity === min) {
                  filteredSolutionIndices.push(index); // Store the index
                  return true; // Exact match for "More Than"
                }
              }
              // Exact match logic for range
              else if (range) {
                const [min, max] = range.split("-").map(Number);
                if (quantity >= min && quantity <= max) {
                  filteredSolutionIndices.push(index); // Store the index
                  return true; // Exact match for range
                }
              }
            }
            return false; // Default return false if no conditions are met
          });

          // If no exact match is found, check for the next nearest slab
          if (filteredSolutions.length === 0) {
            filteredSolutions = solutions.filter((solution, index) => {
              if (solution.select_slab === "Y") {
                const range = solution?._range;

                // Logic for the next nearest "More Than"
                if (range && range.includes("More Than")) {
                  const min = parseInt(range.split(" ")[2], 10);
                  if (quantity < min) {
                    filteredSolutionIndices.push(index); // Store the index
                    return true; // Ensure the quantity is less than min for "More Than"
                  }
                }
                // Logic for the next nearest range
                else if (range) {
                  const [min] = range.split("-").map(Number);
                  if (quantity < min) {
                    filteredSolutionIndices.push(index); // Store the index
                    return true; // Ensure the quantity is less than min for the selected ranges
                  }
                }
              }
              return false; // Default return false if no conditions are met
            });

            // Sort by ascending order to get the next available slab
            filteredSolutions = filteredSolutions.sort((a, b) => {
              const rangeA = a._range.includes("More Than")
                ? parseInt(a._range.split(" ")[2], 10)
                : parseInt(a._range.split("-")[0], 10);
              const rangeB = b._range.includes("More Than")
                ? parseInt(b._range.split(" ")[2], 10)
                : parseInt(b._range.split("-")[0], 10);
              return rangeA - rangeB; // Sort by ascending order
            });

            // Update the indices accordingly after sorting
            filteredSolutionIndices = filteredSolutionIndices.sort((a, b) => {
              const rangeA = solutions[a]._range.includes("More Than")
                ? parseInt(solutions[a]._range.split(" ")[2], 10)
                : parseInt(solutions[a]._range.split("-")[0], 10);
              const rangeB = solutions[b]._range.includes("More Than")
                ? parseInt(solutions[b]._range.split(" ")[2], 10)
                : parseInt(solutions[b]._range.split("-")[0], 10);
              return rangeA - rangeB;
            });

            // Select the first (smallest) valid slab that is greater than the quantity
            if (filteredSolutions.length > 0) {
              filteredSolutions = filteredSolutions.slice(0, 1);
              filteredSolutionIndices = [filteredSolutionIndices[0]];
            }
          }

          // If still no solutions found, ensure to return the solution with select_slab "Y"
          if (filteredSolutions.length === 0) {
            // Try to find the largest available range
            filteredSolutions = solutions
              .filter((solution, index) => {
                if (solution.select_slab === "Y") {
                  filteredSolutionIndices.push(index); // Store the index
                  return true;
                }
                return false;
              })
              .sort((a, b) => {
                const rangeA = a._range.includes("More Than")
                  ? parseInt(a._range.split(" ")[2], 10)
                  : parseInt(a._range.split("-")[0], 10);
                const rangeB = b._range.includes("More Than")
                  ? parseInt(b._range.split(" ")[2], 10)
                  : parseInt(b._range.split("-")[0], 10);
                return rangeA - rangeB; // Sort by ascending order
              });

            // Select the last (largest) range
            filteredSolutions = filteredSolutions.slice(-1);
            filteredSolutionIndices = [filteredSolutionIndices.slice(-1)[0]];
          }
          let hasError;
          if (props.multiplePlantsFlag) {
            hasError =
              slabMainTemplate &&
              slabMainTemplate?.[key]?.some((item) => {
                if (slabMainTemplate?.[key].length > 1) {
                  return (
                    item.select_slab === "Y" &&
                    Object.values(item)?.some(
                      (field) =>
                        field?.error === true && field.Identifier != "GSTType"
                    )
                  );
                }
              });
          } else {
            hasError =
              slabMainTemplate &&
              slabMainTemplate?.[key]?.some((item) => {
                if (slabMainTemplate?.[key].length > 1) {
                  return (
                    item.select_slab === "Y" &&
                    Object.values(item)?.some((field) => field?.error === true)
                  );
                }
              });
          }

          return (
            <>
              <TableRow key={key}>
                <TableCell
                  sx={{
                    WebkitBoxShadow: {
                      md: state.isScrollActive && "11px 0 19px -2px #bebebe54",
                      sm: "none",
                    },
                    boxShadow: {
                      md: state.isScrollActive && "11px 0 19px -2px #bebebe54",
                      sm: "none",
                    },
                  }}
                >
                  <Box display="flex" alignItems="center" gap="16px">
                    <Checkbox
                      checked={props?.slabMainTemplate[key]?.selected === "Y"}
                      onChange={(e) => handleParentCheckboxChange(e, key)}
                      sx={{ "&.MuiCheckbox-root": { mr: 0, p: 0 } }}
                    />
                    {/* <div key={key}>
                            {(props.slabFlag && props.multipleSolutionFlag && solutions[0]?._range !== undefined) && (
                              <>
                                    {!iconState[key] ? (
                                       <Tooltip
                                       title={"Slab"}
                                       placement="bottom-start"
                                       disableInteractive
                                     >
                                      <Badge color="primary" badgeContent={solutions?.length}>
                                      <AddIcon
                                        onClick={(event) => handleAddClick(event, key)}
                                        style={{ cursor: 'pointer' }}
                                      />
                                      </Badge>
                                      </Tooltip>
                                    ) : (
                                      <>
                                  <Tooltip
                                    title={"Slab"}
                                    placement="bottom-start"
                                    disableInteractive
                                  >
                                      <Badge color="primary" badgeContent={solutions?.length}>
                                      <RemoveIcon
                                        onClick={(event) => handleRemoveClick(event, key)}
                                        style={{ cursor: 'pointer' }}
                                      />
                                      </Badge>
                                  </Tooltip>
                                    )}
                                  </>
                                )}
                            </div> */}

                    <div key={key}>
                      {props.slabFlag &&
                      props.multipleSolutionFlag &&
                      solutions[0]?._range !== undefined ? (
                        <>
                          {!iconState[key] ? (
                            <Tooltip
                              title={"Click here to View Slab"}
                              placement="bottom-start"
                              disableInteractive
                            >
                              <Badge
                                color="primary"
                                sx={{
                                  "& .MuiBadge-badge": {
                                    backgroundColor: "var(--secondary-500)",
                                  },
                                }}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                badgeContent={solutions?.length}
                              >
                                <AddIcon
                                  onClick={(event) =>
                                    handleAddClick(event, key)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </Badge>
                            </Tooltip>
                          ) : (
                            <>
                              <Tooltip
                                title={"Click here to close Slab"}
                                placement="bottom-start"
                                disableInteractive
                              >
                                <Badge
                                  color="primary"
                                  sx={{
                                    "& .MuiBadge-badge": {
                                      backgroundColor: "var(--secondary-500)",
                                    },
                                  }}
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                  badgeContent={solutions?.length}
                                >
                                  <RemoveIcon
                                    onClick={(event) =>
                                      handleRemoveClick(event, key)
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </Badge>
                              </Tooltip>
                            </>
                          )}
                        </>
                      ) : (
                        <AddIcon style={{ visibility: "hidden" }} />
                      )}
                    </div>

                    <Tooltip // This is for Attachments specific files
                      title="Specification"
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Badge
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        badgeContent={`${value?.files?.length}`}
                        color="primary"
                      >
                        <AttachFileIcon
                          onClick={() =>
                            value?.files?.length > 0 &&
                            handleUplaodSpecificationOpen(
                              key,
                              value.files,
                              value.specification_text
                            )
                          }
                          sx={{ cursor: "pointer" }}
                        />
                      </Badge>
                    </Tooltip>
                    <Tooltip //Item name header starts from here
                      title={_.unescape(value.name)}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Badge
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        badgeContent={
                          props.mainTemplate[key]?.added_after_rfq_created
                            ? "New"
                            : null
                        }
                        color="primary"
                        sx={{
                          "& .MuiBadge-badge": {
                            top: "-5px",
                            height: "16px",
                            fontSize: "10px",
                          },
                        }}
                      >
                        <Typography
                          noWrap
                          variant="body2"
                          color="text.primary"
                          fontWeight={500}
                          sx={{
                            width: {
                              lg: `${
                                state.itemCellWidth > 300
                                  ? state.itemCellWidth
                                  : 300
                              }px`,
                              md: `${
                                state.itemCellWidth > 200
                                  ? state.itemCellWidth
                                  : 200
                              }px`,
                              sm: `${
                                state.itemCellWidth > 200
                                  ? state.itemCellWidth
                                  : 200
                              }px`,
                              xs: `${
                                state.itemCellWidth > 125
                                  ? state.itemCellWidth
                                  : 125
                              }px`,
                            },
                            // width: `${
                            //   state.itemCellWidth > 350
                            //     ? state.itemCellWidth
                            //     : 350
                            // }px`,
                            marginLeft: "10px",
                          }}
                        >
                          {_.unescape(value.name)}
                        </Typography>
                      </Badge>
                      <Typography
                        sx={{ color: "error.main", marginLeft: "10px" }}
                      >
                        {props.mainTemplate[key]?.is_deleted_from_master &&
                          "This item is deleted from item master."}
                      </Typography>
                      <Typography sx={{ color: "red", marginLeft: "10px" }}>
                        {props.mainTemplate[key]?.is_unlink_from_category &&
                          "This item is unlinked from category."}
                      </Typography>
                      {props.multipleSolutionFlag &&
                        props.slabFlag &&
                        hasError &&
                        props?.slabMainTemplate[key]?.selected === "Y" && (
                          <Typography
                            sx={{ color: "error.main", marginLeft: "10px" }}
                          >
                            There are errors in slab range
                          </Typography>
                        )}
                      {/* {props.mainTemplate[key]
                        ?.added_after_rfq_created && (
                        <Chip
                          label="New"
                          size="small"
                          color="primary"
                          sx={{ marginLeft: "10px", height: "16px" }}
                        />
                      )} */}
                    </Tooltip>
                  </Box>
                </TableCell>
                {props.data[0]?.find(
                  (obj) =>
                    obj.Identifier === "ProductCode" &&
                    obj.VisibilityFlag === "Y"
                ) && (
                  <TableCell
                  // sx={{
                  //   WebkitBoxShadow:
                  //     state.isScrollActive && "11px 0 19px -4px #bebebe54",
                  //   boxShadow:
                  //     state.isScrollActive && "11px 0 19px -4px #bebebe54",
                  // }}
                  >
                    <Tooltip //Item code header starts from here
                      title={_.unescape(value.code)}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Typography
                        noWrap
                        variant="body2"
                        color="text.primary"
                        fontWeight={500}
                        // sx={{
                        //   width: {
                        //     lg: `${state.itemCellWidth > 300
                        //       ? state.itemCellWidth
                        //       : 250
                        //       }px`,
                        //     md: `${state.itemCellWidth > 200
                        //       ? state.itemCellWidth
                        //       : 200
                        //       }px`,
                        //     sm: `${state.itemCellWidth > 200
                        //       ? state.itemCellWidth
                        //       : 200
                        //       }px`,
                        //     xs: `${state.itemCellWidth > 125
                        //       ? state.itemCellWidth
                        //       : 125
                        //       }px`,
                        //   },
                        // }}
                      >
                        {_.unescape(value.code)}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                )}
                {props.slabFlag && props.multipleSolutionFlag && (
                  <TableCell>
                    <Tooltip //Item code header starts from here
                      title={solutions[key]?._range}
                      placement="bottom-start"
                      disableInteractive
                    >
                      {/* <Typography>{solutions[key]?._range}</Typography> */}
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="text"
                        disabled
                        // disabled={props.lastTransactionTypeInThread !== "Inquiry" || props.switchState[key]}
                        value={
                          filteredSolutions[0]?._range
                            ? filteredSolutions[0]?._range
                            : "N/A"
                        } // Use editable value from state or default value
                        // onChange={(e)=>handleInputChange(key,e)} // Pass key to identify which row is being updated
                      />
                    </Tooltip>
                  </TableCell>
                )}
                {props?.data[index]?.map((cell) => {
                  const DefaultFormula = cell.DefaultFormula;

                  // Regular expression to extract field IDs
                  const regex = /f(\d+)/g;
                  let match;
                  const fieldIDs = [];

                  // Extract field IDs from DefaultFormula
                  while ((match = regex.exec(DefaultFormula)) !== null) {
                    fieldIDs.push(match[1]);
                  }

                  // Assuming you always have two field IDs in the DefaultFormula
                  const [field1, field2] = fieldIDs;

                  // Initialize FieldState
                  const FieldState = {};

                  // If field IDs are found, set data in FieldState
                  if (field1 && field2) {
                    // Assuming num1 and num2 are the values you want to set
                    //  const num1 = /* Your value for field1 */;
                    //  const num2 = /* Your value for field2 */;
                    // Setting data in FieldState for both field1 and field2
                    //  const num1 = FieldState[field1];
                    //  const num2 = FieldState[field2];
                  }
                  // All I/P fields comes from there in the template section table
                  if (
                    cell.VisibilityFlag === "Y" ||
                    (cell.DefaultValue &&
                      cell.DefaultValue.Button === "Y" &&
                      cell.ElementType === "D")
                  ) {
                    if (
                      props.multiplePlantsFlag &&
                      cell.Identifier === "GSTType"
                    ) {
                      return null;
                    }
                    if (
                      !(
                        cell.Identifier === "Product" ||
                        cell.Identifier === "ProductCode" ||
                        cell.Identifier === "ProductForeign"
                      )
                    ) {
                      return (
                        <TableCell
                          key={cell.FieldId}
                          // sx={{ minWidth: "120px" }}
                        >
                          <NegotiationFormField
                            batch_id={props.batch_id}
                            LATEST={props.LATEST}
                            vendorType={props?.vendorType}
                            cell={cell}
                            item={value}
                            index={index}
                            FieldState={
                              solutions[0]?._range !== undefined
                                ? filteredSolutions?.[0]?.[cell?.FieldId] ??
                                  undefined
                                : solutions?.[0]?.[cell?.FieldId] ?? undefined
                            }
                            base_currency={props?.base_currency}
                            selectedCurrency={props?.selectedCurrency || ""}
                            updateDeliveryScheduleFieldValueSlab={
                              props.updateDeliveryScheduleFieldValueSlab
                            }
                            slabFlag={props.slabFlag}
                            multipleSolutionFlag={props.multipleSolutionFlag}
                            handleChange={(
                              evt,
                              FieldId,
                              Identifier,
                              FieldLabel,
                              // ElementType,
                              key,
                              index
                            ) =>
                              props.handleWithOutSlabChange(
                                evt,
                                FieldId,
                                Identifier,
                                FieldLabel,
                                // ElementType,
                                key,
                                index
                              )
                            }
                            handleDateChange={(newVal, FieldId, Validation) =>
                              props.handleDateWithOutSlabChange(
                                newVal,
                                FieldId,
                                "slabMainTemplate",
                                index,
                                Validation,
                                key
                              )
                            }
                            default_dropdown_type={props.default_dropdown_type}
                            destination={props.destination}
                            SlabTableToggle={SlabTableToggle}
                            disabled={
                              solutions[0]?._range !== undefined
                                ? true
                                : solutions["selected"] === "Y"
                                ? false
                                : true
                            }
                            keys={key.length > 0 && key}
                            rowIndex={
                              solutions[0]?._range !== undefined
                                ? filteredSolutionIndices
                                : 0
                            }
                            mainTemplate={props.mainTemplate}
                            multiplePlantsFlag={props.multiplePlantsFlag}
                          />
                        </TableCell>
                      );
                    }
                  }
                })}
              </TableRow>
              {/* For Slab */}
              {props.slabFlag &&
                props.multipleSolutionFlag &&
                solutions[0]?._range !== undefined &&
                Toggle[key] &&
                props.slabMainTemplate[key] &&
                getHeadersForSlab(props.slabMainTemplate[key], key, value)}
            </>
          );
        }
      );
    } else {
      itemsToRender = "loading...";
    }

    return <TableBody className="SlabRange-Body">{itemsToRender}</TableBody>;
  };

  function isInErrorArray(attributeName) {
    return props.errorArray.includes(attributeName);
  }

  // Function to render the error message based on condition
  // Function to render the error message based on condition
  function renderErrorMessage(attribute, identifier, errorDisplayedFlag) {
    if (
      identifier === "GSTType" &&
      isInErrorArray(attribute) &&
      !errorDisplayedFlag.errorDisplayed
    ) {
      errorDisplayedFlag.errorDisplayed = true; // Set the flag to true once the error is displayed
      return (
        <Alert
          variant="text"
          sx={{ color: "error.main", p: 0 }}
          severity="error"
        >
          Please Select GST Transition Type.
        </Alert>
      );
    }
    return null; // Return null if no error condition is met or if error is already displayed
  }

  const getHeaders = (solutions, key, value) => {
    if (
      !props.switchState[key] &&
      props.lastTransactionTypeInThread === "Inquiry"
    ) {
      return null;
    }

    return (
      <TableRow>
        <TableCell
          sx={{
            ".quotation_template_table .MuiTableBody-root &.MuiTableCell-root":
              {
                backgroundColor: "var(--grey-50)",
                padding: scrolledLeft
                  ? "0"
                  : {
                      lg: "0 0 0 526px",
                      md: "0 0 0 426px",
                      sm: "0 0 0 426px",
                      xs: "0 0 0 406px",
                    },
              },
          }}
          colSpan={100}
        >
          <Table
            className="MultiSolution-Table SlabDetailsForm"
            sx={{
              "&.MultiSolution-Table tr td:first-of-type, &.MultiSolution-Table tr th:first-of-type":
                {
                  borderLeft: scrolledLeft
                    ? "0"
                    : "1px solid var(--bluegrey-500)",
                  width: {
                    lg: "526px",
                    md: "426px",
                    sm: "426px",
                    xs: "426px",
                  },
                  position: {
                    md: "sticky",
                    sm: "static",
                    xs: "static",
                  },
                  left: "0",
                  boxShadow: {
                    md: scrolledLeft ? "inset -1px 0px 0 #a6c1d3" : "none",
                    sm: "none",
                  },
                },
              "&.MultiSolution-Table .MuiTableBody-root tr:last-of-type td": {
                borderBottom: "none",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell key={"M2"} align="left">
                  <Tooltip title={props.alternativeNames?.alternate_options || "Multiple Solution"} placement="bottom-start">
                    <Typography
                      variant="body2"
                      color={"grey.600"}
                      fontWeight={500}
                    >
                       {props.alternativeNames?.alternate_options || "Multiple Solution"}{" "}
                      <span style={{ color: "error.main" }}>*</span>
                    </Typography>
                  </Tooltip>
                </TableCell>
                {scrolledLeft && (
                  <>
                    <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                    <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                  </>
                )}
                {props.data[0]?.map((cell, index) => {
                  if (
                    props.multiplePlantsFlag === true &&
                    cell.Identifier === "GSTType"
                  ) {
                    return null;
                  }
                  if (
                    props.vendorType === "Local" ||
                    props.vendorType === "International" ||
                    props.vendorType === "Local Importer"
                      ? cell.VisibilityFlag === "Y" ||
                        (cell.DefaultValue &&
                          cell.DefaultValue.Button === "Y" &&
                          cell.ElementType === "D")
                      : cell.VisibilityFlag === "Y"
                  ) {
                    if (
                      !(
                        cell.Identifier === "Product" ||
                        cell.Identifier === "ProductForeign" ||
                        cell.Identifier === "ProductCode"
                      )
                    ) {
                      return (
                        <TableCell key={cell.FieldId}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip
                              title="Apply to All"
                              placement="bottom-start"
                              disableInteractive
                            >
                              <ToggleButton
                                value="check"
                                selected={
                                  props.multiSolApplyToAll[
                                    `${key}_${cell.FieldId}_multiSolApplyToAll`
                                  ]
                                }
                                disabled={cell.Identifier === "EstimatedValue"}
                                onClick={() => {
                                  let isReadOnly = false;
                                  if (!isReadOnly) {
                                    Object.keys(props.mainTemplate).forEach(
                                      (key) => {
                                        const element = props.mainTemplate[key];
                                        Object.keys(element).forEach((key1) => {
                                          if (
                                            key1 === cell.FieldId &&
                                            typeof element[key1] === "object" &&
                                            element[key1].readOnly
                                          ) {
                                            isReadOnly = key1;
                                          }
                                        });
                                      }
                                    );
                                  }
                                  if (
                                    !props.multiSolApplyToAll[
                                      `${key}_${cell.FieldId}_multiSolApplyToAll`
                                    ] &&
                                    isReadOnly !== cell.FieldId
                                  ) {
                                    props.handleMSApplyToAllClick(
                                      key,
                                      cell.FieldId,
                                      cell.ElementType === "TB" &&
                                        cell.ElementType === "TA",
                                      cell.Identifier
                                    );
                                  }
                                }}
                                sx={{
                                  p: 0,
                                  mr: 1,
                                  border: 0,
                                }}
                              >
                                {!["T", "D", "L"].includes(cell.ElementType) ? (
                                  props.multiSolApplyToAll[
                                    `${key}_${cell.FieldId}_multiSolApplyToAll`
                                  ] ? (
                                    <CheckBoxIcon
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.primary.main,
                                      }}
                                    />
                                  ) : (
                                    <CheckBoxOutlineBlankIcon
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.bluegrey[500],
                                      }}
                                    />
                                  )
                                ) : (
                                  ""
                                )}
                              </ToggleButton>
                            </Tooltip>
                            <Tooltip
                              title={
                                cell?.InstructionText === ""
                                  ? cell?.FieldLabel
                                  : cell?.InstructionText
                              }
                            >
                              <Typography fontWeight={500}>
                                {cell.FieldLabel}
                              </Typography>
                            </Tooltip>
                            <ResizableBox
                              axis="x"
                              height={20}
                              width={20}
                              style={{
                                textAlign: "left",
                                width: "100%",
                              }}
                              minConstraints={[20, 20]}
                            ></ResizableBox>
                          </div>
                        </TableCell>
                      );
                    }
                  } else {
                    return "";
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {solutions?.map((solution, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Box display="flex" gap="8px">
                      <Tooltip
                        title={solution.name}
                        placement="bottom-start"
                        disableInteractive
                      >
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          type="text"
                          value={
                            _.unescape(
                              props.solutionNames[`${key}-${index}`]
                            ) || ""
                          }
                          error={!!props.solutionNameErrors[`${key}-${index}`]}
                          disabled={true}
                          helperText={
                            <span style={{ color: "red" }}>
                              {props.solutionNameErrors[`${key}-${index}`]}
                            </span>
                          }
                        />
                      </Tooltip>
                    </Box>
                  </TableCell>
                  {scrolledLeft && (
                    <>
                      <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                      <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                    </>
                  )}
                  <TableCell sx={{ display: "none" }}>
                    <Tooltip
                      title={solution.name}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Typography noWrap>{solution.name}</Typography>
                    </Tooltip>
                  </TableCell>
                  {props?.data[index]?.map((cell) => {
                    const DefaultFormula = cell.DefaultFormula;
                    const regex = /f(\d+)/g;
                    let match;
                    const fieldIDs = [];

                    while ((match = regex.exec(DefaultFormula)) !== null) {
                      fieldIDs.push(match[1]);
                    }

                    const [field1, field2] = fieldIDs;
                    const FieldState = {};

                    if (field1 && field2) {
                    }

                    if (
                      cell.VisibilityFlag === "Y" ||
                      (cell.DefaultValue &&
                        cell.DefaultValue.Button === "Y" &&
                        cell.ElementType === "D")
                    ) {
                      if (
                        props.multiplePlantsFlag &&
                        cell.Identifier === "GSTType"
                      ) {
                        return null;
                      }
                      if (
                        !(
                          cell.Identifier === "Product" ||
                          cell.Identifier === "ProductCode" ||
                          cell.Identifier === "ProductForeign"
                        )
                      ) {
                        return (
                          <TableCell key={cell.FieldId}>
                            <NegotiationFormField
                              batch_id={props.batch_id}
                              LATEST={props.LATEST}
                              cell={cell}
                              index={index}
                              item={value}
                              vendorType={props?.vendorType}
                              FieldState={solution?.[cell?.FieldId]}
                              base_currency={props?.base_currency}
                              selectedCurrency={props?.selectedCurrency || ""}
                              handleChange={(
                                evt,
                                FieldId,
                                Identifier,
                                FieldLabel,
                                key,
                                index
                              ) => {
                                props.handleMSChange(
                                  evt,
                                  FieldId,
                                  Identifier,
                                  FieldLabel,
                                  key,
                                  index
                                );
                              }}
                              handleDateChange={(newVal, FieldId, Validation) =>
                                props.handleDateMSChange(
                                  newVal,
                                  FieldId,
                                  "multiSolmainTemplate",
                                  index,
                                  Validation,
                                  key
                                )
                              }
                              disabled={
                                Object.keys(disabledProps).length > 0 &&
                                disabledProps[key]
                              }
                              updateDeliveryScheduleFieldValue={
                                props.updateDeliveryScheduleFieldValue
                              }
                              default_dropdown_type={
                                props.default_dropdown_type
                              }
                              destination={props.destination}
                              rowIndex={index}
                              keys={key.length > 0 && key}
                              multiplePlantsFlag={props.multiplePlantsFlag}
                              multipleSolutionFlag={props.multipleSolutionFlag}
                              slabFlag={props.slabFlag}
                              switchState={props.switchState}
                              DetailsClickFlag={true}
                            />
                          </TableCell>
                        );
                      }
                    } else {
                      return "";
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    );
  };

  const getHeadersForSlab = (solutions, key, itemValue) => {
    return (
      <TableRow>
        <TableCell
          sx={{
            ".quotation_template_table .MuiTableBody-root &.MuiTableCell-root":
              {
                backgroundColor: "var(--grey-50)",
                padding: scrolledLeft
                  ? "0"
                  : {
                      lg: "0 0 0 450px",
                      md: "0 0 0 406px",
                      sm: "0 0 0 406px",
                      xs: "0 0 0 406px",
                    },
              },
          }}
          colSpan={100}
        >
          <Table
            className="MultiSolution-Table SlabDetailsForm"
            sx={{
              "&.MultiSolution-Table tr td:first-of-type, &.MultiSolution-Table tr th:first-of-type":
                {
                  borderLeft: scrolledLeft
                    ? "0"
                    : "1px solid var(--bluegrey-500)",
                  width: {
                    lg: "450px",
                    md: "406px",
                    sm: "406px",
                    xs: "406px",
                  },
                  position: {
                    md: "sticky",
                    sm: "static",
                    xs: "static",
                  },
                  left: "0",
                  boxShadow: {
                    md: scrolledLeft ? "inset -1px 0px 0 #a6c1d3" : "none",
                    sm: "none",
                  },
                },
              "&.MultiSolution-Table .MuiTableBody-root tr:last-of-type td": {
                borderBottom: "none",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell key={"M2"} align="left">
                  <Tooltip title="SLAB RANGE" placement="bottom-start">
                    <Typography
                      variant="body2"
                      color={"grey.600"}
                      fontWeight={500}
                    >
                      Slab Range
                    </Typography>
                  </Tooltip>
                </TableCell>
                {scrolledLeft && (
                  <>
                    <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                    <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                  </>
                )}

                {props.data[0]?.map((cell, index) => {
                  if (
                    props.multiplePlantsFlag === true &&
                    cell.Identifier === "GSTType"
                  ) {
                    return null;
                  }
                  if (
                    props.vendorType === "Local" ||
                    props.vendorType === "International" ||
                    props.vendorType === "Local Importer"
                      ? cell.VisibilityFlag === "Y" ||
                        (cell.DefaultValue &&
                          cell.DefaultValue.Button === "Y" &&
                          cell.ElementType === "D")
                      : cell.VisibilityFlag === "Y"
                  ) {
                    if (
                      !(
                        cell.Identifier === "Product" ||
                        cell.Identifier === "ProductForeign" ||
                        cell.Identifier === "ProductCode"
                      )
                    ) {
                      return (
                        <TableCell key={cell.FieldId}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip
                              title="Apply to All Slab"
                              placement="bottom-start"
                              disableInteractive
                            >
                              <ToggleButton
                                value="check"
                                selected={
                                  props.slabApplyToAll[
                                    `${key}_${cell.FieldId}_slabApplyToAll`
                                  ]
                                }
                                disabled={cell.Identifier === "EstimatedValue"}
                                title="Apply to All Slab"
                                onClick={() => {
                                  //for not doing apply to all when field is read only
                                  let isReadOnly = false;
                                  if (!isReadOnly) {
                                    Object.keys(props.mainTemplate).map(
                                      (key, i) => {
                                        let element = props.mainTemplate[key];
                                        Object.keys(element).map((key1, i) => {
                                          if (
                                            key1 == cell.FieldId &&
                                            typeof element[key1] === "object" &&
                                            element[key1].readOnly
                                          ) {
                                            isReadOnly = key1;
                                          }
                                        });
                                      }
                                    );
                                  }
                                  if (
                                    !props.slabApplyToAll[
                                      `${key}_${cell.FieldId}_slabApplyToAll`
                                    ] &&
                                    isReadOnly != cell.FieldId
                                  ) {
                                    props.handleSlabApplyToAllClick(
                                      key,
                                      cell.FieldId,
                                      cell.ElementType === "TB" &&
                                        cell.ElementType === "TA",
                                      cell.Identifier
                                    );
                                  }
                                }}
                                sx={{
                                  p: 0,
                                  mr: 1,
                                  border: 0,
                                }}
                              >
                                {!["T", "D", "L"].includes(cell.ElementType) ? (
                                  props.slabApplyToAll[
                                    `${key}_${cell.FieldId}_slabApplyToAll`
                                  ] ? (
                                    <CheckBoxIcon
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.primary.main,
                                      }}
                                    />
                                  ) : (
                                    <CheckBoxOutlineBlankIcon
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.bluegrey[500],
                                      }}
                                    />
                                  )
                                ) : (
                                  ""
                                )}
                              </ToggleButton>
                            </Tooltip>
                            <Tooltip
                              title={
                                cell?.InstructionText === ""
                                  ? cell?.FieldLabel
                                  : cell?.InstructionText
                              }
                            >
                              <Typography fontWeight={500}>
                                {cell.FieldLabel}
                              </Typography>
                            </Tooltip>
                            <ResizableBox
                              axis="x"
                              height={20}
                              width={20}
                              style={{
                                textAlign: "left",
                                width: "100%",
                              }}
                              minConstraints={[20, 20]}
                            ></ResizableBox>
                          </div>
                        </TableCell>
                      );
                    }
                  } else {
                    return "";
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {solutions?.map((solution, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Checkbox
                        checked={solution?.select_slab === "Y" || false}
                        onChange={(event) =>
                          handleChildCheckboxChange(
                            event,
                            key,
                            index,
                            solution._range
                          )
                        }
                        color="primary"
                      />
                      <Tooltip
                        title={solution._range}
                        placement="bottom-start"
                        disableInteractive
                      >
                        <Typography
                          fontWeight={500}
                          noWrap
                          sx={{
                            width: {
                              lg: scrolledLeft ? "394px" : "302px",
                              md: scrolledLeft ? "350px" : "260px",
                              sm: scrolledLeft ? "350px" : "260px",
                            },
                          }}
                        >
                          {solution._range}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  {scrolledLeft && (
                    <>
                      <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                      <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                    </>
                  )}
                  {props?.data[index]?.map((cell) => {
                    const DefaultFormula = cell.DefaultFormula;
                    const regex = /f(\d+)/g;
                    let match;
                    const fieldIDs = [];

                    while ((match = regex.exec(DefaultFormula)) !== null) {
                      fieldIDs.push(match[1]);
                    }

                    const [field1, field2] = fieldIDs;
                    const FieldState = {};

                    if (field1 && field2) {
                    }

                    if (
                      cell.VisibilityFlag === "Y" ||
                      (cell.DefaultValue &&
                        cell.DefaultValue.Button === "Y" &&
                        cell.ElementType === "D")
                    ) {
                      if (
                        props.multiplePlantsFlag &&
                        cell.Identifier === "GSTType"
                      ) {
                        return null;
                      }
                      if (
                        !(
                          cell.Identifier === "Product" ||
                          cell.Identifier === "ProductCode" ||
                          cell.Identifier === "ProductForeign"
                        )
                      ) {
                        return (
                          <TableCell key={cell.FieldId}>
                            <NegotiationFormField
                              batch_id={props.batch_id}
                              LATEST={props.LATEST}
                              cell={cell}
                              index={index}
                              item={itemValue}
                              vendorType={props?.vendorType}
                              FieldState={solution?.[cell?.FieldId]}
                              base_currency={props?.base_currency}
                              selectedCurrency={props?.selectedCurrency || ""}
                              handleChange={(
                                evt,
                                FieldId,
                                Identifier,
                                FieldLabel,
                                // ElementType,
                                key,
                                index
                              ) => {
                                props.handleSlabChange(
                                  evt,
                                  FieldId,
                                  Identifier,
                                  FieldLabel,
                                  // ElementType,
                                  key,
                                  index
                                );
                              }}
                              handleDateChange={(
                                newVal,
                                FieldId,
                                Validation
                              ) => {
                                props.handleDateSlabChange(
                                  newVal,
                                  FieldId,
                                  "slabMainTemplate",
                                  index,
                                  Validation,
                                  key
                                );
                              }}
                              disabled={solution?.select_slab === "N"}
                              updateDeliveryScheduleFieldValueSlab={
                                props.updateDeliveryScheduleFieldValueSlab
                              }
                              default_dropdown_type={
                                props.default_dropdown_type
                              }
                              rowIndex={index}
                              keys={key.length > 0 && key}
                              multiplePlantsFlag={props.multiplePlantsFlag}
                              multipleSolutionFlag={props.multipleSolutionFlag}
                              slabFlag={props.slabFlag}
                              switchState={props.switchState[key]}
                              destination={props.destination}
                            />
                          </TableCell>
                        );
                      }
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    );
  };

  // Start table duplicate synchronize scrollbar script
  const containerRef = useRef(null);
  const duplicateScrollbarRef = useRef(null);
  const [isVisibleScrollbar, setIsVisibleScrollbar] = useState(false);
  const [scrolledLeft, setScrolledLeft] = useState(false);

  const handleScrollSecond = (scroll) => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = scroll.target.scrollLeft;
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    const duplicateScrollbar = duplicateScrollbarRef.current;
    const boxElement = document.getElementById(`CardAdditional`);
    const scrollSection = document.getElementById(
      `ScrollAreaSection-${props.tabValue}`
    );

    const handleScroll = () => {
      if (duplicateScrollbar && container) {
        duplicateScrollbar.scrollLeft = container.scrollLeft;
      }

      if (!scrollSection) return;

      const sectionTop = scrollSection.getBoundingClientRect().top;
      const sectionBottom = scrollSection.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;

      const boxTop = boxElement?.getBoundingClientRect().top || 0;
      const boxBottom = boxElement?.getBoundingClientRect().bottom || 0;

      if (boxTop < windowHeight && boxBottom > 0) {
        setIsVisibleScrollbar(false);
        return;
      }

      if (sectionTop < windowHeight && sectionBottom > 100) {
        setIsVisibleScrollbar(true);
      } else {
        setIsVisibleScrollbar(false);
      }
    };

    const tableHandleScroll = () => {
      if (container) {
        setScrolledLeft(container.scrollLeft > 400);
      }
    };

    container?.addEventListener("scroll", handleScrollSecond);
    container?.addEventListener("scroll", tableHandleScroll);
    window.addEventListener("scroll", handleScroll);

    return () => {
      container?.removeEventListener("scroll", handleScrollSecond);
      container?.removeEventListener("scroll", tableHandleScroll);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [props.tabValue]);
  // End table duplicate synchronize scrollbar script

  // Start left scrollbar tablecell show hide & first child sticky script

  // Function to handle scroll event
  const tableHandleScroll = () => {
    if (containerRef.current) {
      // Check if the scroll position is greater than 0
      setScrolledLeft(containerRef.current.scrollLeft > 400);
    }
  };

  useEffect(() => {
    const tableScroll = containerRef.current;
    if (tableScroll) {
      tableScroll.addEventListener("scroll", tableHandleScroll);
      return () => {
        tableScroll.removeEventListener("scroll", tableHandleScroll);
      };
    }
  }, []);
  // End left scrollbar tablecell show hide & first child sticky script

  return (
    <>
      <Card variant="outlined" sx={{ mb: 2, mt: 3 }} id="Template">
        <CardHeader
          title={
            <Typography variant="subtitle2" component="h5" color={"grey.900"}>
              ITEM DETAILS
            </Typography>
          }
          action={
            props.multiplePlantsFlag && (
              <Box display="flex" gap="8px">
                {(() => {
                  let gstTypeCell = AllItemsTemplate.find((item) => {
                    return item.Identifier == "GSTType";
                  });
                  let errorDisplayedFlag = { errorDisplayed: false }; // Initialize the error display flag for the whole iteration
                  if (gstTypeCell?.HTMLAttributeName.includes("_")) {
                    return Object.values(props.mainTemplate).map((item) => {
                      const attributes = Object.values(item);
                      return (
                        <div key={item.formdetailRowId}>
                          {" "}
                          {/* Assuming formdetailRowId is unique */}
                          {attributes.map((attribute) =>
                            renderErrorMessage(
                              attribute?.HTMLAttributeName,
                              attribute?.Identifier,
                              errorDisplayedFlag
                            )
                          )}
                        </div>
                      );
                    });
                  } else {
                    if (
                      gstTypeCell &&
                      Object.keys(props.additionalInfoTemplate).length > 0 &&
                      props.additionalInfoTemplate.hasOwnProperty(
                        gstTypeCell.FieldId
                      )
                    ) {
                      if (
                        props.additionalInfoTemplate[gstTypeCell.FieldId]?.error
                      ) {
                        return (
                          <Alert
                            variant="text"
                            sx={{ color: "error.main", p: 0 }}
                            severity="error"
                          >
                            Please Select GST Transition Type.
                          </Alert>
                        );
                      }
                    }
                  }
                })()}
                <Tooltip
                  placement="top"
                  title="Review Auto generation of GST transition type"
                  arrow
                  open={tooltipOpen || hoverTooltip}
                  onOpen={() => setHoverTooltip(true)}
                  onClose={() => setHoverTooltip(false)}
                  PopperProps={{
                    sx: { zIndex: 98 },
                  }}
                >
                  <Badge
                    badgeContent={destinationCount}
                    color="primary"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    sx={{ mr: 2 }}
                  >
                    <Button
                      variant="text"
                      size="small"
                      startIcon={<LocationOnOutlinedIcon />}
                      onClick={() => {
                        setTooltipOpen(false);
                        props.setIsDestinationDetailsDialogOpen(true);
                      }}
                      sx={{ backgroundColor: "var(--primary-50)" }}
                    >
                      Destination
                    </Button>
                  </Badge>
                </Tooltip>
              </Box>
            )
          }
        />
        <CardContent sx={{ p: 0 }} id={`ScrollAreaSection-${props.tabValue}`}>
          <TableContainer
            className="quotation_template_table"
            ref={containerRef}
            // onScroll={handleScrollFirst}
            sx={{
              // maxHeight: "400px",
              // overflowY: "auto",
              overflow: "auto",
              borderTop: "solid 1px",
              borderColor: (theme) => theme.palette.bluegrey[500],
              borderBottomRightRadius: "4px",
              borderBottomLeftRadius: "4px",
            }}
            onScroll={debounce((event) => {
              if (event.target.scrollLeft === 0 && state.isScrollActive) {
                setState({
                  isScrollActive: false,
                });
              } else if (
                event.target.scrollLeft !== 0 &&
                !state.isScrollActive
              ) {
                setState({
                  isScrollActive: true,
                });
              }
            }, 200)}
          >
            <Table stickyHeader>
              <TableHead className="Itemize_headers">
                <TableRow>
                  <TableCell
                    sx={{
                      WebkitBoxShadow: {
                        md:
                          state.isScrollActive && "11px 0 19px -2px #bebebe54",
                        sm: "none",
                      },
                      boxShadow: {
                        md:
                          state.isScrollActive && "11px 0 19px -2px #bebebe54",
                        sm: "none",
                      },
                    }}
                  >
                    <Box
                      className=""
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox sx={{ visibility: "hidden" }} />
                      <Tooltip
                        title={
                          props.data[0]?.find(
                            (obj) =>
                              obj.Identifier === "Product" ||
                              obj.Identifier === "ProductForeign"
                          )?.FieldLabel
                        }
                      >
                        <Box
                          sx={{
                            minWidth: "350px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography fontWeight={500}>
                            {
                              props.data[0]?.find(
                                (obj) =>
                                  obj.Identifier === "Product" ||
                                  obj.Identifier === "ProductForeign"
                              )?.FieldLabel
                            }
                          </Typography>

                          <ResizableBox
                            axis="x"
                            height={20}
                            width={20}
                            onResizeStop={(e, data) => {
                              setState({
                                itemCellWidth: data.size.width,
                              });
                            }}
                            style={{
                              textAlign: "left",
                              width: "100%",
                            }}
                            minConstraints={[20, 20]}
                          ></ResizableBox>
                        </Box>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  {props.data[0]?.find(
                    (obj) =>
                      obj.Identifier === "ProductCode" &&
                      obj.VisibilityFlag === "Y"
                  ) && (
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Tooltip
                          title={
                            props.data[0]?.find(
                              (obj) => obj.Identifier === "ProductCode"
                            )?.FieldLabel
                          }
                        >
                          <Typography fontWeight={500}>
                            {
                              props.data[0]?.find(
                                (obj) => obj.Identifier === "ProductCode"
                              )?.FieldLabel
                            }
                          </Typography>
                        </Tooltip>
                        <ResizableBox
                          axis="x"
                          height={20}
                          width={20}
                          style={{
                            textAlign: "left",
                            width: "100%",
                          }}
                          minConstraints={[20, 20]}
                        ></ResizableBox>
                      </Box>
                    </TableCell>
                  )}

                  {props.multipleSolutionFlag && !props.slabFlag && (
                    <TableCell key={"M2"} align="left">
                      <Tooltip title={""}>
                        <Typography fontWeight={500}>
                          {props.alternativeNames?.alternate_options || "Multiple Solution"}{" "}
                          <span style={{ color: "error.main" }}>*</span>
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  )}
                  {props.slabFlag && props.multipleSolutionFlag && (
                    <TableCell key={"M2"} align="left">
                      <Tooltip title={""}>
                        <Typography fontWeight={500}>
                          SLAB RANGE NAME
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  )}
                  {props.data[0]?.map((cell, index) => {
                    if (
                      props.multiplePlantsFlag === true &&
                      cell.Identifier === "GSTType"
                    ) {
                      return null;
                    }
                    if (
                      props.vendorType === "Local" ||
                      props.vendorType === "International" ||
                      props.vendorType === "Local Importer"
                        ? cell.VisibilityFlag === "Y" ||
                          (cell.DefaultValue &&
                            cell.DefaultValue.Button === "Y" &&
                            cell.ElementType === "D")
                        : cell.VisibilityFlag === "Y"
                    ) {
                      if (
                        !(
                          cell.Identifier === "Product" ||
                          cell.Identifier === "ProductForeign" ||
                          cell.Identifier === "ProductCode"
                        )
                      ) {
                        return (
                          <TableCell key={cell.FieldId}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {props.multipleSolutionFlag && props.slabFlag ? (
                                <Tooltip
                                  title="Apply to All Parent Slab"
                                  placement="bottom-start"
                                  disableInteractive
                                >
                                  <ToggleButton
                                    value="check"
                                    selected={
                                      props.slabParentApplyToAll[
                                        `${cell.FieldId}_slabParentApplyToAll`
                                      ]
                                    }
                                    disabled={
                                      cell.Identifier === "EstimatedValue"
                                    }
                                    // title="Apply to All"
                                    onClick={() => {
                                      let isReadOnly = false;
                                      let foundKey = null;

                                      // Loop through slabMainTemplate to find the correct key
                                      Object.keys(
                                        props.slabMainTemplate
                                      ).forEach((key) => {
                                        let element =
                                          props.slabMainTemplate[key];

                                        // Check if _range is undefined in the first row (or other condition as per your use case)
                                        if (element[0]?._range === undefined) {
                                          // Loop through the inner object to check for readOnly status
                                          Object.keys(element).forEach(
                                            (key1) => {
                                              if (
                                                key1 === cell.FieldId &&
                                                typeof element[key1] ===
                                                  "object" &&
                                                element[key1].readOnly
                                              ) {
                                                isReadOnly = true; // Mark as read-only if found
                                              }
                                            }
                                          );

                                          // Only set foundKey if the field is not read-only
                                          if (
                                            !isReadOnly &&
                                            foundKey === null
                                          ) {
                                            foundKey = key;
                                          }
                                        }
                                      });

                                      // Ensure foundKey is valid and field is not read-only
                                      if (
                                        !props.slabParentApplyToAll[
                                          `${cell.FieldId}_slabParentApplyToAll`
                                        ] &&
                                        !isReadOnly &&
                                        foundKey !== null
                                      ) {
                                        props.handleSlabParentApplyToAllClick(
                                          foundKey, // Pass the correct found key here
                                          cell.FieldId,
                                          cell.ElementType === "TB" ||
                                            cell.ElementType === "TA", // Condition for TB/TA
                                          cell.Identifier
                                        );
                                      }
                                    }}
                                    sx={{
                                      p: 0,
                                      mr: 1,
                                      border: 0,
                                    }}
                                  >
                                    {!["T", "D", "L"].includes(
                                      cell.ElementType
                                    ) ? (
                                      props.slabParentApplyToAll[
                                        `${cell.FieldId}_slabParentApplyToAll`
                                      ] ? (
                                        <CheckBoxIcon
                                          sx={{
                                            color: (theme) =>
                                              theme.palette.primary.main,
                                          }}
                                        />
                                      ) : (
                                        <CheckBoxOutlineBlankIcon
                                          sx={{
                                            color: (theme) =>
                                              theme.palette.bluegrey[500],
                                          }}
                                        />
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </ToggleButton>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title="Apply to All"
                                  placement="bottom-start"
                                  disableInteractive
                                >
                                  <ToggleButton
                                    value="check"
                                    selected={
                                      props.applyToAll[
                                        `${cell.FieldId}_applyToAll`
                                      ]
                                    }
                                    disabled={
                                      cell.Identifier === "EstimatedValue"
                                    }
                                    // title="Apply to All"
                                    onClick={() => {
                                      //for not doing apply to all when field is read only
                                      let isReadOnly = false;
                                      if (!isReadOnly) {
                                        Object.keys(props.mainTemplate).map(
                                          (key, i) => {
                                            let element =
                                              props.mainTemplate[key];
                                            Object.keys(element).map(
                                              (key1, i) => {
                                                if (
                                                  key1 == cell.FieldId &&
                                                  typeof element[key1] ===
                                                    "object" &&
                                                  element[key1].readOnly
                                                ) {
                                                  isReadOnly = key1;
                                                }
                                              }
                                            );
                                          }
                                        );
                                      }
                                      if (
                                        !props.applyToAll[
                                          `${cell.FieldId}_applyToAll`
                                        ] &&
                                        isReadOnly != cell.FieldId
                                      ) {
                                        props.handleApplyToAllClick(
                                          cell.FieldId,
                                          cell.ElementType === "TB" &&
                                            cell.ElementType === "TA",
                                          cell.Identifier
                                        );
                                      }
                                    }}
                                    sx={{
                                      p: 0,
                                      mr: 1,
                                      border: 0,
                                    }}
                                  >
                                    {!["T", "D", "L"].includes(
                                      cell.ElementType
                                    ) ? (
                                      props.applyToAll[
                                        `${cell.FieldId}_applyToAll`
                                      ] ? (
                                        <CheckBoxIcon
                                          sx={{
                                            color: (theme) =>
                                              theme.palette.primary.main,
                                          }}
                                        />
                                      ) : (
                                        <CheckBoxOutlineBlankIcon
                                          sx={{
                                            color: (theme) =>
                                              theme.palette.bluegrey[500],
                                          }}
                                        />
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </ToggleButton>
                                </Tooltip>
                              )}
                              <Tooltip
                                title={
                                  cell?.InstructionText === ""
                                    ? cell?.FieldLabel
                                    : cell?.InstructionText
                                }
                              >
                                <Typography fontWeight={500}>
                                  {cell.FieldLabel}
                                </Typography>
                              </Tooltip>
                              <ResizableBox
                                axis="x"
                                height={20}
                                width={20}
                                style={{
                                  textAlign: "left",
                                  width: "100%",
                                }}
                                minConstraints={[20, 20]}
                              ></ResizableBox>
                            </div>
                          </TableCell>
                        );
                      }
                    } else {
                      return "";
                    }
                  })}
                </TableRow>
              </TableHead>
              {/* {props.multipleSolutionFlag && !props.slabFlag && getTableBody()} */}
              {props.slabFlag && props.multipleSolutionFlag
                ? getTableSlabBody()
                : getTableBody()}
            </Table>
          </TableContainer>
          {/* Start nego form Duplicate Scroll bar code */}
          <div
            ref={duplicateScrollbarRef}
            onScroll={handleScrollSecond}
            style={{
              display: isVisibleScrollbar ? "block" : "none",
              overflowX: "scroll",
              overflowY: "hidden",
              width: "calc(100% - 34px)",
              height: "18px",
              position: "fixed",
              bottom: "0",
              zIndex: "13",
            }}
          >
            <div
              style={{
                width: containerRef.current?.scrollWidth || 0,
                height: "18px",
              }}
            ></div>
          </div>
          {/* End nego form Duplicate Scroll bar code */}
        </CardContent>
      </Card>

      {state.specificationsOpen && (
        <Upload
          currentItemIndex={state.currentItemIndex}
          product_id={props.negotiationJsonData[state.currentItemIndex]?.id}
          randomId={props.randomId}
          master_type={"item"}
          handleUplaod={props.handleUplaod}
          open={state.specificationsOpen}
          handleClose={() => {
            setState({
              specificationsOpen: false,
            });
          }}
          Alreadyexistingfiles={
            props.itemSpecificFiles[
              props.negotiationJsonData[state.currentItemIndex]?.id
            ] || []
          }
        />
      )}
      {fileSpecificationsOpen && (
        <UploadDynamicView
          Loading={Loading}
          title="Specifications"
          SpecificationText={SpecificationText}
          uploadedFiles={specification?.data}
          open={fileSpecificationsOpen}
          handleClose={handleUploadClose}
        />
      )}
      {/* Switch Toggle Delete solutions in one go*/}
    </>
  );
}
