// src/redux/reducer.js
import { SET_DROPDOWN_DATA } from "../constants/type";

const initialState = {
  dropdownData: {
    plant: {},
    pgr: {},
    porg: {},
  },
};

const dropdownReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DROPDOWN_DATA:
      return {
        ...state,
        dropdownData: { ...state.dropdownData, ...action.payload },
      };
    default:
      return state;
  }
};

export default dropdownReducer;
