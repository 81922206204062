import React, { Component } from "react";
import {
  Select,
  FormControl,
  MenuItem,
  Button,
  Checkbox,
  Typography,
  InputLabel,
  TextField,
  FormHelperText,
  Backdrop,
  CircularProgress,
  Tooltip,
  Chip,
  Autocomplete,
} from "@mui/material";
import { Box } from "@mui/system";
import { parseISO } from "date-fns";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchPreviousValues,
  getDropdownOptions,
  getIdentifiersDynamicValues,
  rfqSubmit,
  getFileSpecifications,
  getTermsAndConditions,
  updateFileSpecifications,
  sendMailUri,
  prRfqSubmit,
  updateFileSpecificationswithmultiplefiles,
  pr_status_integration,
  modify_edit_qcs_values,
  activitySubmitUri,
  postAutoRFQTask,
  autoRFQTaskById,
  // insertPrRfq,
} from "../../config/apiUrl";

import axios from "axios";
import { styles } from "./style.js";
import "../../pages/style.css";
import AutoRFQCardTemplate from "./AutoRFQCardTemplate";
import VendorRecipient from "../Dialogs/VendorRecipints/VendorRecipient";
import RFQStatic from "../Card/RFQStaticTemplate";
import AlarmIcon from "@mui/icons-material/Alarm";
import UploadIcon from "@mui/icons-material/CloudUploadRounded";
// import ReminderTemporaryDrawer from "../ReminderTemporaryDrawer";
import { OpenInNew } from "@mui/icons-material";
import Details from "../Dialogs/Details/Details";
import { openSnackbar } from "../../redux/actions/snackbarAction";
import _ from "lodash";
import UploadFileDialog from "../Dialogs/Upload/Upload";
import RFQSubmit from "../Dialogs/RFQSubmit";
import {
  setReminderSettings,
  resetReminderSettings,
  setRFQSettings,
} from "../../redux/actions/drawerActions";
import { setPrRfqs } from "../../redux/actions/prrfqactions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import UploadDynamic from "../Dialogs/Upload/UploadDynamic";
import { resetCAT } from "../../redux/resetApp";
import ConfirmAlert from "../Dialogs/ConfirmAlert.js/index.js";
import TermsAndConditions from "../Dialogs/Upload/TermsAndConditions";
import ItemizeSectionTable from "./ItemizeSectionTable";
import TermDetailsItemize from "../Dialogs/Details/TermDetailsItemize";
import { setFileSpecification } from "../../redux/actions/categoryAction";
import {resetVendorLocationFlag} from '../../redux/actions/itemActions'
//import prconfig from "../../config/prconfig"

import { setItemSpecifications } from "../../redux/actions/itemActions";
import {
  setPrlinktoitemsforspecification,
  setPRselectedChange,
  setDefaultValues,
  setTemplateValues,
  setTermsAndConditions,
  setFileSpecificationFromApi,
} from "../../redux/actions/prrfqactions";
import PrRFQSettings from "../PRRFQ/RfqSettings";
import axiosInstance from "../../interceptor/axiosInstance";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

let prconfig = {};

class AutoRFQTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: "",
      template: "",
      loading: false,
      mainTemplate: {},
      errorArray: [],
      localTemplate: {},
      IntlTemplate: {},
      shipment_mark: "",
      RFQStatic: {
        lock: false,
        star: false,
        MaterialDescription: false,
        RFQValidity: {
          HTMLAttributeName: "RFQValidity",
          FieldLabel: "RFQValidity",
          Validation: "",
          error: false,
          ErrorMessage: "Invalid Date",
          value: "",
          maxDays: 0,
        },
        RFQValidityTime: {
          HTMLAttributeName: "RFQValidityTime",
          FieldLabel: "RFQValidityTime",
          Validation: "",
          error: false,
          ErrorMessage: "Invalid Time",
          value: "23:30",
        },
        TBValidity: {
          HTMLAttributeName: "TBValidity",
          FieldLabel: "TBValidity",
          Validation: "",
          error: false,
          ErrorMessage: "Invalid TB Date",
          value: "",
        },
        TBValidityTime: {
          HTMLAttributeName: "TBValidityTime",
          FieldLabel: "TBValidityTime",
          Validation: "",
          error: false,
          ErrorMessage: "Invalid TB Time",
          value: "23:30",
        },
        RFQspecifications: {
          HTMLAttributeName: "RFQspecifications",
          FieldLabel: "RFQspecifications",
          Validation: "",
          error: false,
          ErrorMessage: "RFQspecifications is required",
          value: "",
        },
        RFQCCUsersmails: {
          HTMLAttributeName: "RFQCCUsersmails",
          FieldLabel: "RFQCCUsersmails",
          Validation: "",
          error: false,
          ErrorMessage: "Invalid!",
          value: "",
        },
        RFQContactCheck: {
          HTMLAttributeName: "RFQContactCheck",
          FieldLabel: "RFQContactCheck",
          Validation: "",
          error: false,
          ErrorMessage: "",
          value: false,
        },
        RFQUpdateCCUsersCheck: {
          HTMLAttributeName: "RFQUpdateCCUsersCheck",
          FieldLabel: "RFQUpdateCCUsersCheck",
          Validation: "",
          error: false,
          ErrorMessage: "",
          value: false,
        },
      },
      RFQDynamic: {},
      vendorRecipintOpen: false,
      vendorRecipintType: "",
      reminderDrawerOpen: false,
      specificationsOpen: false,
      RFQSubmitDialogOpen: false,
      rfqSubmitMsg: "",
      startDate: "",
      startDateTB: true,
      qty: "",
      rate: "",
      unit: "",
      rfqSubmitLoading: false,
      loaderArray: [],
      vendorType: "",
      vendorTerms: "",
      vendorTermsOpen: false,
      currentFieldID: "",
      incoTermError: false,
      paymentTermError: false,
      dynamicFileUploadOpen: false,
      currentFieldId: null,
      currentTemplate: null,
      categorySpecificationFiles: [],
      jsonTemplateloading: false,
      prevRFQLoading: false,
      excelRFQLoading:false,
      prevRFQ: {},
      randomId: null,
      usedAttributesId: [],
      confirmDialogOpen: false,
      confirmAlertMsg: "",
      confirmAlertTitle: "",
      tandcDialogOpen: false,
      termsAndConditionsData: {},
      applyToAll: {},
      commonOptions: {},
      localPaymentFields: [],
      localIncoFields: [],
      intlPaymentFields: [],
      intlIncoFields: [],
      submitDisable: false,
      templateValue: {},
      slab: {},
      slabKey: 1,
      applyToAllSlab: false,
      DestinationCodes: [],
      fetchDataCalled: false,
      intervalDayError: false
    };
    this._isMounted = false;

  }

  componentDidMount() {
        this.props.jsonTemplate.ITEMIZE[0]?.map((cell, index) => {
      if (cell.VisibilityFlag === "Y") {
        if (cell.Identifier === "matspecghclfile") {
          let RFQStaticUpdate = this.state.RFQStatic;
          RFQStaticUpdate.MaterialDescription = true;
        }
      }
    });
    if (Object.keys(this.props.defaultValues).length !== 0) {
      let RFQStaticUpdate = this.state.RFQStatic;
      const { validityDate, priorityRfq, sealedGroupRfq, validityTime } =
        this.props.defaultValues;
      if ((validityDate._d || validityDate) && validityDate !== null) {
        RFQStaticUpdate.RFQValidity.value = validityDate;
        RFQStaticUpdate.RFQValidityTime.value = validityTime;
        RFQStaticUpdate.TBValidity.value = validityDate;
        RFQStaticUpdate.TBValidityTime.value = validityTime;
      }
      RFQStaticUpdate.lock = sealedGroupRfq;
      RFQStaticUpdate.star = priorityRfq;
      this.setState({
        RFQStatic: RFQStaticUpdate,
      });
    }
    this._isMounted = true;
    // if (this.props.prplants.length)
      // prconfig = this.props?.pr_dropdown_values.prconfig;
      prconfig = this.props?.dropdown_values;
    // this.props.setClick(this.fetchPrevious);

    if(this.props.editTask){
      this.editInitializeState();
      this.editInitializeLocalRecipientsState();
      this.editInitializeIntlRecipientsState();
      this.editInitializeRFQDynamicState();
    }else{
      this.initializeState();
      this.initializeLocalRecipientsState();
      this.initializeIntlRecipientsState();
      this.initializeRFQDynamicState();
    }
    
    // this.fetchTermsAndCondtions();

    if (this.props?.cc_emails) {
      let RFQStaticUpdate = { ...this.state.RFQStatic };
      RFQStaticUpdate.RFQCCUsersmails.value = this.props?.cc_emails;
      this.setState(
        {
          RFQStatic: RFQStaticUpdate,
        },
        () => {
          if (document.getElementsByName("RFQCCUsersmails")[0]) {
            document.getElementsByName("RFQCCUsersmails")[0].value =
              this.props?.cc_emails;
          }
        }
      );
    }

    if (this.props.featurePermission?.sealed_rfq?.feature_value === "Y") {
      let RFQStaticUpdate = this.state.RFQStatic;
      RFQStaticUpdate.lock = true;
      this.setState({
        RFQStatic: RFQStaticUpdate,
      });
    }

    if (
      this.props.featurePermission?.max_rfq_validity_days?.feature_value === "Y"
    ) {
      let days =
        this.props.featurePermission?.max_rfq_validity_days
          ?.feature_permission_value;
      let RFQStaticUpdate = { ...this.state.RFQStatic };
      RFQStaticUpdate.RFQValidity.maxDays = days;
      this.setState({
        RFQStatic: RFQStaticUpdate,
      });
    }
    if (
      this.props.featurePermission?.default_RFQ_validity_days?.feature_value === "Y" 
    ) {
      let days =
        this.props.featurePermission?.default_RFQ_validity_days
          ?.feature_permission_value ;
      let RFQStaticUpdate = { ...this.state.RFQStatic };
      RFQStaticUpdate.RFQValidity.value = moment().add(days, "days").format("MM/DD/YYYY");
      this.setState({
        RFQStatic: RFQStaticUpdate,
      });
    }
  //  let fileSpecificationFromApi = this.props.fileSpecificationFromApi;
   let categoryId = this.props.selectedCategory?.id;
    this.setState({ randomId : this.props?.randomId },()=>{
      if(Object.keys(this.props?.prlineitems).length !== 0){
          this.fetchprlineitemsfiles(); 
      }
      
    });

    if (this.props.rfqSettingValues.priorityRfq === true) {
      this.setState({
        RFQStatic: {
          ...this.state.RFQStatic,
          star: this.props.rfqSettingValues.priorityRfq,
        },
      });
    }
  }

  //slab functions start

  handleSlab = (evt, item) => {
    this.setState({
      slab: { ...this.state.slab, [item.id]: { isOpen: evt.target.checked } }, //set is open for item
    });
    if (this.state.applyToAllSlab == true) {
      this.setState({      
        applyToAllSlab:false
      });
    }
  //remove value if slab is closed
   if(!evt.target.checked){
    this.setState((prevState) => ({
      ...prevState,
      mainTemplate: {
        ...prevState.mainTemplate,
        [item.id]: {
          ...prevState.mainTemplate[item.id],
          slab: [{id:1,start:"1",end:"",error:false},{id:2,start:"More Than",end:"",error:false}]
        },
      },
    }))
   }
   }
   //add row in slab
   slabAddRow = (idx,index)=>{
    const re = /((\b|\+|-)(0|([1-9][0-9]*))(\.[0-9]+)?)\b/;
    let allSlab = this.state.mainTemplate[1]["slab"];
    if(!re.test(allSlab[allSlab.length-1].end)){
      this.props.openSnackbar("error", ` Please provide a valid ${this.props?.displayOptions["label"]} for ${this.props.selectedItems[index].code}`);
      return;
    }
    else if(parseFloat(allSlab[allSlab.length-2].start)>=parseFloat(allSlab[allSlab.length-2].end)){
      this.props.openSnackbar("error", `  Please provide a valid ${this.props?.displayOptions["label"]} for ${this.props.selectedItems[index].code}`);
      return;
    }else if(allSlab.some(obj=>(obj.end==""||obj.error==true))){
      this.props.openSnackbar("error", `Please provide a valid ${this.props?.displayOptions["label"]} for${this.props.selectedItems[index].code}.`);
      return;
    }else if(allSlab.length==parseInt(this.props.rfqSettingValues?.allowed_slab_limit)){
      this.props.openSnackbar("error", `Maximum ${this.props.rfqSettingValues.allowed_slab_limit} ${this.props?.displayOptions["label"]} allowed per item`);
      return;
    }
    let value = allSlab[idx].end;
    let val = allSlab[idx].end.toString().split(".")[1];
    let isAllZero = val && val.split("").every((char) => char == "0");
    if (val && val.length && !isAllZero) {
      if (val.length == 1) {
        value = parseFloat(value) + 0.1;
        value = value.toFixed(1);
      } else if (val.length == 2) {
        value = parseFloat(value) + 0.01;
        value = value.toFixed(2);
      } else if (val.length == 3) {
        value = parseFloat(value) + 0.001;
        value = value.toFixed(3);
      }
    } else {
      value = parseInt(value) + 1;
    }
    let newSlab = {
      id: Math.random().toString(36).slice(2),
      start: `${parseFloat(value)}`,
      end: "",
      error: false,
    };
    let popped = allSlab.pop(); //remove last element from array
    popped = { ...popped, end: "" };
    let updateSlab = [...allSlab, newSlab];
    updateSlab.push(popped);
    this.setState(
      (prevState) => ({
        ...prevState,
        mainTemplate: {
          ...prevState.mainTemplate,
          [1]: {
            ...prevState.mainTemplate[1],
            slab: updateSlab,
          },
        },
      }),
      () => {
        this.handleKey(index);
      }
    );
  };
  //remove row from slab
  slabRemoveRow = (idx, index) => {
    if (this.state.applyToAllSlab == true) {
      this.setState({
        applyToAllSlab: false,
      });
    }
    let allSlab =
      this.state.mainTemplate[1]["slab"];
    let updateSlab = allSlab.filter((row, i) => i != idx);
    let popped = updateSlab.pop();
    popped = { ...popped, end: updateSlab[updateSlab.length - 1].end };
    if (updateSlab[idx]) {
      updateSlab[idx] = { ...updateSlab[idx], start: allSlab[idx].start };
    }
    updateSlab.push(popped);
    this.setState(
      (prevState) => ({
        ...prevState,
        mainTemplate: {
          ...prevState.mainTemplate,
          [1]: {
            ...prevState.mainTemplate[1],
            slab: updateSlab,
          },
        },
      }),
      () => {
        this.handleKey(index);
      }
    );
  };
  //handle input in row
  handleRowInput = (evt, row, id, index) => {
    if (this.state.applyToAllSlab == true) {
      this.setState({
        applyToAllSlab: false,
      });
    }
    let allSlab =
      this.state.mainTemplate[1]["slab"];
    let popped = allSlab.pop();
    let updateSlab = allSlab.map((obj, i) => {
      let newObj = { ...obj };
      const re = /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/;
      if (i == id) {
        if (
          parseFloat(evt.target.value) <= parseFloat(newObj.start) ||
          !re.test(evt.target.value)
        ) {
          newObj.error = true;
          newObj.end = "";
        } else {
          newObj.end = parseFloat(evt.target.value);
          newObj.error = false;
        }
      } else if (i == id + 1) {
        if (evt.target.value != "" && re.test(evt.target.value)) {
          let value = evt.target.value;
          let val = evt.target.value.toString().split(".")[1];
          let isAllZero = val && val.split("").every((char) => char == "0");
          if (val && val.length && !isAllZero) {
            if (val.length == 1) {
              value = parseFloat(value) + 0.1;
              value = value.toFixed(1);
            } else if (val.length == 2) {
              value = parseFloat(value) + 0.01;
              value = value.toFixed(2);
            } else if (val.length == 3) {
              value = parseFloat(value) + 0.001;
              value = value.toFixed(3);
            }
          } else {
            value = parseInt(value) + 1;
          }
          newObj.start = `${parseFloat(value)}`;
          if (parseFloat(newObj.end) <= parseFloat(newObj.start)) {
            newObj.error = true;
          } else {
            newObj.error = false;
          }
        } else if (evt.target.value == "") {
          newObj.start = "";
        }
      }
      return newObj;
    });
    popped = { ...popped, end: updateSlab[updateSlab.length - 1].end };
    updateSlab.push(popped);
    this.setState((prevState) => ({
      ...prevState,
      mainTemplate: {
        ...prevState.mainTemplate,
        [1]: {
          ...prevState.mainTemplate[1],
          slab: updateSlab,
        },
      },
    }));
  };
  //for apply to all in slab
  handleApplyToAllSlab = (evt, index, itemId) => {
    var found = this.props.selectedItems.find((element) => {
      if (this.state.slab[element.id].isOpen) {
        return element;
      }
    });
    let allSlab = this.state.mainTemplate[found.id]["slab"];
    if(allSlab.some(obj=>obj.error==true||obj.end=="")){
      this.props.openSnackbar("error", `Please provide a valid ${this.props?.displayOptions["label"]} for ${found.code}.`);
      return;
    }
    this.setState({
      applyToAllSlab: evt.target.checked,
    });
    this.props.selectedItems.forEach((item) => {
      if (this.state.slab[item.id].isOpen) {
        this.setState((prevState) => ({
          ...prevState,
          mainTemplate: {
            ...prevState.mainTemplate,
            [item.id]: {
              ...prevState.mainTemplate[item.id],
              slab: allSlab.map((obj, i) => {
                return { ...obj, id: Math.random().toString(36).slice(2) };
              }),
            },
          },
        }));
      }
    });
  };
  //change key for render
  handleKey = (index) => {
    let allSlab =
      this.state.mainTemplate[1]["slab"];
    let updateSlab = allSlab.map((obj, i) => {
      return { ...obj, id: Math.random().toString(36).slice(2) };
    });
    this.setState((prevState) => ({
      ...prevState,
      mainTemplate: {
        ...prevState.mainTemplate,
        [1]: {
          ...prevState.mainTemplate[1],
          slab: updateSlab,
        },
      },
    }));
  };
  //slab functions end

  handleChange = (evt, index, FieldId, Identifier) => {
    const { value, type, checked } = evt.target;
    const { mainTemplate } = this.state;
    if (
      mainTemplate[index][FieldId]["value"] !==
      value
    ) {
      this.setState({
        applyToAll: {
          ...this.state.applyToAll,
          [`${FieldId}_applyToAll`]: false,
          [`${FieldId}_applyToAll`]: false,
          [`${FieldId}_applyToAll`]: false,
        },
      });
    }

    this.setState(
      (prevState) => ({
        ...prevState,
        mainTemplate: {
          ...prevState.mainTemplate,
          [index]: {
            ...prevState.mainTemplate[index],
            [FieldId]: {
              ...prevState.mainTemplate[index][
                FieldId
              ],
              value: type !== "checkbox" ? evt.target.value : checked,
            },
          },
        },
      }),
      () => {
        this.validateInput(FieldId, index);
        if (Identifier === "Budgetedrate") {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplate: {
              ...prevState.mainTemplate,
              [index]: {
                ...prevState.mainTemplate[index],
                rate: value,
              },
            },
          }));
        } else if (Identifier === "ProductQuantity") {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplate: {
              ...prevState.mainTemplate,
              [index]: {
                ...prevState.mainTemplate[index],
                qty: value,
              },
            },
          }));
        }
      }
    );

    window.localStorage.setItem(
      "maintemplate",
      JSON.stringify(this.state.mainTemplate)
    );
  };

  fetchprlineitemsfiles = () => {
    const { prlineitems, selectedItems } = this.props;
    let prno_items_mapping = {};
    let categoryarray = [];
    selectedItems.forEach((items, item_index) => {
      const itemid = items.id;
      categoryarray = this.Fetchprlineitemspecificationfiles(
        prlineitems[itemid],
        itemid,
        prno_items_mapping,
        categoryarray,
        item_index
      );
    });

    // if(categoryarray?.length > 0){
    // this.getprlineitemspecificationfiles(categoryarray, "category");
    // }
    this.props.setPrlinktoitemsforspecification(prno_items_mapping);
  };
  Fetchprlineitemspecificationfiles = (
    pr_lineitemdata,
    itemid,
    prno_items_mapping,
    categoryarray,
    item_index
  ) => {
    let itemarray = [];
    pr_lineitemdata.forEach((object, i) => {
      const items_attechment = object.attachment || [];
      const category_attechment = object.pr?.attachment || [];
      if (category_attechment.length !== 0) {
        if (prno_items_mapping.hasOwnProperty(object.pr.number)) {
          if (!prno_items_mapping[object.pr.number].hasOwnProperty(itemid)) {
            prno_items_mapping[object.pr.number][itemid] = category_attechment;
          }
        } else {
          prno_items_mapping[object.pr.number] = {};
          prno_items_mapping[object.pr.number][itemid] = [];
          prno_items_mapping[object.pr.number][itemid] = category_attechment;
        }
      }
      if (items_attechment) itemarray = [...itemarray, ...items_attechment];
      if (category_attechment)
        categoryarray = [...categoryarray, ...category_attechment];
    });
    // this.getprlineitemspecificationfiles(
    //   itemarray,
    //   "items",
    //   itemid,
    //   item_index
    // );
    return categoryarray;
  };

  generateRandom = (min, max) => {
    const timestring = new Date().getTime();
    const numstring = Math.floor(Math.random() * (max - min + 1)) + min;
    return timestring + "." + numstring;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    //handle multiplant on of condition
    let updateRFQDynamic = this.state.RFQDynamic;
    if (
      this.props.location?.pathname.indexOf("/prcombination") == -1 &&
      this.props.rfqSettingValues.allowMultiPlant !=
        nextProps.rfqSettingValues.allowMultiPlant
    ) {
      for (const key in updateRFQDynamic) {
        if (
          !nextProps.rfqSettingValues.allowMultiPlant &&
          updateRFQDynamic[key]["Identifier"] == "Destination"
        ) {
          updateRFQDynamic[key]["value"] = "";
          // updateRFQDynamic[key]["error"] = true;
        } else if (
          nextProps.rfqSettingValues.allowMultiPlant &&
          updateRFQDynamic[key]["Identifier"] == "Destination"
        ) {
          updateRFQDynamic[key]["value"] = [];
          // updateRFQDynamic[key]["error"] = true;
        }
      }
      this.setState({
        RFQDynamic: updateRFQDynamic,
      });
    }
    if (
      _.isEqual(nextProps.defaultValues, this.props.defaultValues) === false
    ) {
      let RFQStaticUpdate = this.state.RFQStatic;
      const { validityDate, priorityRfq, sealedGroupRfq, validityTime } =
        nextProps.defaultValues;
      if ((validityDate._d || validityDate) && validityDate !== null) {
        RFQStaticUpdate.RFQValidity.value = validityDate;
        RFQStaticUpdate.RFQValidityTime.value = validityTime;
        RFQStaticUpdate.TBValidity.value = validityDate;
        RFQStaticUpdate.TBValidityTime.value = validityTime;
      } else {
        RFQStaticUpdate.RFQValidity.value = "";
        RFQStaticUpdate.TBValidity.value = "";
      }
      RFQStaticUpdate.RFQValidity.error = false; //set rfqvalidity error false
      RFQStaticUpdate.lock = sealedGroupRfq;
      RFQStaticUpdate.star = priorityRfq;
      this.setState({
        RFQStatic: RFQStaticUpdate,
      });
    }
    if (nextProps.remiderDrawer?.startDate) {
      this.setState({
        startDate: nextProps.remiderDrawer?.startDate,
      });
    } else {
      this.setState({
        startDate: "",
      });
    }

    this.setState({
      startDateTB: nextProps.remiderDrawer?.includeReminderInterval,
    });

    if (nextProps.selectedItems?.length !== this.props.selectedItems?.length) {
      this.setState({
        errorArray: [],
      });
      if (nextProps.selectedItems.length > this.props.selectedItems.length) {
        //item added,
        let itemsToAdd = nextProps.selectedItems.filter(
          (newItem) => !this.props.selectedItems.includes(newItem)
        );

        for (let index = 0; index < itemsToAdd.length; index++) {
          const newItem = itemsToAdd[index];
          for (let j = 0; j < this.props.jsonTemplate?.ITEMIZE.length; j++) {
            const jsonRow = this.props.jsonTemplate?.ITEMIZE[j];
            if (
              !this.state.usedAttributesId.includes(
                jsonRow[1]?.Child?.AttributeId
              )
            ) {
              // add item here and push it into the usedAttributesId
              this.state.usedAttributesId.push(jsonRow[1]?.Child?.AttributeId);
              this.setState(
                {
                  usedAttributesId: this.state.usedAttributesId,
                },
                () => {
                  let obj = {};
                  jsonRow.forEach((field) => {
                    if (this.state.applyToAll[`${field.FieldId}_applyToAll`]) {
                      this.setState({
                        applyToAll: {
                          ...this.state.applyToAll,
                          [`${field.FieldId}_applyToAll`]: false,
                        },
                      });
                    }
                    if (field.VisibilityFlag === "Y") {
                      if (field.Identifier === "Product" || field.Identifier === "ProductForeign" ) {
                        obj[field.FieldId] = {
                          HTMLAttributeName: field.HTMLAttributeName,
                          value: newItem.old_id,
                          Validation: "",
                          FieldLabel: field.FieldLabel,
                          error: false,
                          Identifier: field.Identifier,
                          ErrorMessage: "",
                        };
                        return;
                      }
                      if (field.Identifier === "ProductCode") {
                        obj[field.FieldId] = {
                          HTMLAttributeName: field.HTMLAttributeName,
                          value: newItem.code,
                          Validation: "",
                          FieldLabel: field.FieldLabel,
                          error: false,
                          Identifier: field.Identifier,
                          ErrorMessage: "",
                        };
                        return;
                      }
                      obj[field.FieldId] = {
                        HTMLAttributeName: field.HTMLAttributeName,
                        value: "",
                        Validation: field.Validation,
                        FieldLabel: field.FieldLabel,
                        error: false,
                        ErrorMessage: field.ErrorMessage,
                        Identifier: field.Identifier,
                        ElementType: field.ElementType,
                      };
                      if (
                        field.Identifier === "EstimatedCost" ||
                        field.Identifier === "EstimatedCostForeign" ||
                        field.Identifier === "Budgetedrate" ||
                        field.Identifier === "BudgetedrateForeign"
                      ) {
                        obj[field.FieldId][
                          `upd_budget_master_${newItem.id}`
                        ] = false;

                        if (
                          this.props.featurePermission
                            ?.Fetch_Budget_Value_in_RFQ_Form_from_Item_Master
                            ?.feature_value === "Y" &&
                          newItem?.budgeted_rate
                        ) {
                          if (parseFloat(newItem?.budgeted_rate)) {
                            obj[field.FieldId]["value"] = newItem.budgeted_rate;
                            obj["rate"] = newItem.budgeted_rate;
                          } else {
                            obj["rate"] = "";
                          }
                        } else {
                          obj["rate"] = "";
                        }
                      }
                      if (field.ElementType === "S") {
                        if (field.DropdownEdit === "Y") {
                          obj[field.FieldId]["options"] = "loading";
                          obj[field.FieldId]["options"] =
                            this.getItemizeOptions(field);
                        } else {
                          obj[field.FieldId]["options"] = field.DefaultValue;
                        }
                      }
                      if (
                        field.Identifier === "BasicHistoricalPrice" ||
                        field.Identifier === "LandedHistoricalPrice" ||
                        field.Identifier === "DateHP"
                      ) {
                        obj[field.FieldId]["DynamicDefaultValue"] = "loading";
                        this.getDynamicDefaultValues(
                          field,
                          index,
                          field.DefaultValue
                        );
                      }
                    }
                    if (field.Identifier === "EstimatedValue") {
                      obj[field.FieldId] = {
                        HTMLAttributeName: field.HTMLAttributeName,
                        value: "",
                        Validation: field.Validation,
                        FieldLabel: field.FieldLabel,
                        error: false,
                        ErrorMessage: field.ErrorMessage,
                        Identifier: field.Identifier,
                      };
                    }
                    if (
                      Object.keys(this.props.prlineitems).length &&
                      prconfig &&
                      prconfig[field.Identifier] !== undefined
                    ) {
                      let readOnly = "";
                      let value =
                        eval(this[prconfig[field.Identifier].function_name])(
                          field.Identifier,
                          newItem
                        ) || "";

                      if (
                        prconfig[field.Identifier]?.non_editable_function &&
                        prconfig[field.Identifier]?.non_editable_function !==
                          "0"
                      ) {
                        if (
                          prconfig[field.Identifier]?.non_editable_function ==
                          "1"
                        ) {
                          readOnly = true;
                        } else {
                          readOnly = eval(
                            this[
                              prconfig[field.Identifier]?.non_editable_function
                            ]
                          )(value);
                        }
                      }
                      obj[field.FieldId] = {
                        HTMLAttributeName: field.HTMLAttributeName,
                        value: value,
                        Validation: field.Validation,
                        FieldLabel: field.FieldLabel,
                        error: false,
                        ErrorMessage: field.ErrorMessage,
                        Identifier: field.Identifier,
                        ElementType: field.ElementType,
                        readOnly: readOnly,
                      };
                    }
                  });
                  obj.specification = "";
                  obj.qty = "";
                  if (!obj.hasOwnProperty("slab")) {
                    obj["slab"] = [
                      { id: 1, start: "1", end: "", error: false },
                      { id: 2, start: "More Than", end: "", error: false },
                    ];
                  }
                  this.setState((prevState) => ({
                    mainTemplate: {
                      ...prevState.mainTemplate,
                      [newItem.id]: obj,
                    },
                  }));
                  if (this.props.rfqSettingValues.includeSlab && this.props.location.pathname.indexOf("/prcombination") == -1) {
                    this.setState((prevState) => ({
                      slab: {
                        ...prevState.slab,
                        [newItem.id]: { isOpen: true },
                      },
                    }));
                  } else {
                    this.setState((prevState) => ({
                      slab: {
                        ...prevState.slab,
                        [newItem.id]: { isOpen: false },
                      },
                    }));
                  }
                  if (this.state.applyToAllSlab == true) {
                    this.setState({
                      applyToAllSlab: false,
                    });
                  }
                }
              );
              break;
            }
          }
        }
      } else if (
        nextProps.selectedItems.length < this.props.selectedItems.length
      ) {
        //item removed,
        let itemsToRemove = this.props.selectedItems.filter(
          (newItem) => !nextProps.selectedItems.includes(newItem)
        );

          itemsToRemove.forEach((item) => {
            let index = this?.state?.usedAttributesId?.indexOf(
              this?.state?.mainTemplate[item.id][
                Object.keys(this.state.mainTemplate[item.id])[0]
              ]?.HTMLAttributeName?.split("_")[1]
            );
            this.state.usedAttributesId.splice(index, 1);
            this.setState({
              usedAttributesId: this.state.usedAttributesId,
            });
            delete this.state.mainTemplate[item.id];
            delete this.state.slab[item.id];
          });
        this.setState({
          mainTemplate: this.state.mainTemplate,
          slab: this.state.slab,
        });
      }
    }
    if (
      nextProps.selectedVendors?.length !== this.props.selectedVendors?.length
    ) {
      setTimeout(() => {
        for (const key in this.state.localTemplate) {
          const element = this.state.localTemplate[key];
          if (element.value) {
            if (document.getElementsByName(element.HTMLAttributeName)[0]) {
              document.getElementsByName(element.HTMLAttributeName)[0].value =
                element.value;
            }
          }
        }
        for (const key in this.state.IntlTemplate) {
          const element = this.state.IntlTemplate[key];
          if (element.value) {
            if (document.getElementsByName(element.HTMLAttributeName)[0]) {
              document.getElementsByName(element.HTMLAttributeName)[0].value =
                element.value;
            }
          }
        }
      });
    }
    
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    let newVendors = this.props.selectedVendors;
    let prevVendors = prevProps.selectedVendors;

    
    ;
    if (
      prevProps.submit !== this.props?.submit
    ) {
        this.handleRFQSubmit();
    }
    

    // check if selected vendors are same or not
    if (document.getElementsByName("RFQCCUsersmails")[0]) {
      document.getElementsByName("RFQCCUsersmails")[0].value =
        this.state.RFQStatic.RFQCCUsersmails.value;
    }
    if (prevVendors?.length !== newVendors?.length) {
      this.setState({
        errorArray: [],
      });
    }

    if (Object.keys(this.props.checkTechnicalBidItems).length > 0) {
      let errCount = 0;
    }

    if (
      this.props.rfqSettingValues.priorityRfq !==
      prevProps.rfqSettingValues.priorityRfq
    ) {
      this.setState({
        RFQStatic: {
          ...this.state.RFQStatic,
          star: this.props.rfqSettingValues.priorityRfq,
        },
      });
    }

    if (
      this.props.rfqSettingValues.sealedGroupRfq !==
      prevProps.rfqSettingValues.sealedGroupRfq
    ) {
      this.setState({
        RFQStatic: {
          ...this.state.RFQStatic,
          lock: this.props.rfqSettingValues.sealedGroupRfq,
        },
      });
    }

    if (
      prevProps.jsonTemplate === "" &&
      prevProps.jsonTemplate !== this.props?.jsonTemplate
    ) {
      this.initializeState();
      this.initializeLocalRecipientsState();
      this.initializeIntlRecipientsState();
      this.initializeRFQDynamicState();
    }

    if (
      prevProps.jsonTemplate.length !== 0 &&
      this.props?.jsonTemplate?.length !== 0
    ) {
      if (
        prevProps.jsonTemplate.PARAM[0].template_id !==
        this.props.jsonTemplate.PARAM[0].template_id
      ) {
        this.initializeState();
        this.initializeLocalRecipientsState();
        this.initializeIntlRecipientsState();
        this.initializeRFQDynamicState();
      }
    }
    const rfqDynamicChanged = !_.isEqual(prevState.RFQDynamic, this.state.RFQDynamic);
    const importExcelRfqChanged = !_.isEqual(prevProps.ImportExcelRfq, this.props.ImportExcelRfq);
    if (rfqDynamicChanged || importExcelRfqChanged) {
      // Ensure RFQDynamic is not empty and ImportExcelRfq is not empty
      if (Object.keys(this.state.RFQDynamic).length !== 0) {
        let destinationOptions = Object.values(this.state.RFQDynamic).filter(destination => {
          return (destination.Identifier === "Destination"|| destination.Identifier === "DestinationForeign") && destination?.options !== 'loading';
        });
        if (destinationOptions.length !== 0 && Object.keys(this.props.ImportExcelRfq).length !== 0 ) {
            if (!this.state.fetchDataCalled) {
              this.setState({ fetchDataCalled: true,excelRFQLoading: true }, () => {
                setTimeout(() => {
                  this.fetchDataFromExcel();
                }, 2000);
              });
            }
        }
        else if(Object.keys(this.props.ImportExcelRfq).length !== 0){
          if (!this.state.fetchDataCalled) {
            this.setState({ fetchDataCalled: true,excelRFQLoading: true }, () => {
              setTimeout(() => {
                this.fetchDataFromExcel();
              }, 10000);
            });
          }
        }
      }
    }
  }

  toggleLoaderArray = (arg) => {
    let temp = this.state.loaderArray;
    let index = temp.indexOf(arg);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(arg);
    }
    this.setState({
      loaderArray: temp,
    });
  };

  SUM = (field, item) => {
    let sum = 0;
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = 0;
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) {}
      sum += Number(expression_val);
    });
    return sum;
  };

  // LATEST_Plant = (field,item) => {
  //   let plant_code = "";
  //   plant_code = this.LATEST(field,item);
  //   let plant_name = this.props.pr_dropdown_values.plant[plant_code]['Dropdown_Element_Name'];
  //   let value = `${plant_code}-${plant_name}`;
  //   if( this.props.companyInfo?.default_dropdown_type === "10"  ){
  //     value = `${plant_name}-${plant_code}`;
  //   }
  //   return value;
  // };

  PRLongText = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    //let replacedStr= pritems[pritems.length-1][prconfig[field].response_key].replace(/\^/,"\n");
    return pritems[pritems?.length - 1][prconfig[field].response_key];
  };

  COMMA = (field, item) => {
    let str = "";
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = "";
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) {}
      str += expression_val + ",";
    });
    return str.slice(0, -1);
  };

  COMMA_WITH_SPACE = (field, item) => {
    let str = "";
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = "";
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) {}
      str += expression_val + ", ";
    });
    return str.slice(0, -2);
  };

  EstimatedCost = (field, item) => {
    let value = item.estimated_cost;
    if (
      this.props.featurePermission
        ?.Fetch_Budget_Value_in_RFQ_Form_from_Item_Master?.feature_value === "N"
    ) {
      value = this.LATEST(field, item);
    }
    return value;
  };

  MIN = (field, item) => {
    let value = 0;
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = 0;
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) {}

      if (expression_val < value) {
        value = expression_val;
      }
    });

    return value;
  };

  MIN_DATE = (field, item) => {
    let value = 0;

    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = 0;
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
        if (!value || moment(value).isAfter(moment(expression_val))) {
          value = expression_val;
        }
      } catch (e) {}

      if (expression_val < value) {
        value = expression_val;
      }
    });

    return value;
  };

  LATEST_Remove_Circumflex = (field, item) => {
    let value = this.LATEST(field, item);
    return value ? value.replace(/\^\^\^/g, "\n") : "";
  };

  PRLongText = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    return (
      this.LATEST_Remove_Circumflex(field, item) ||
      pritems[pritems.length - 1]["material_short_text"] ||
      ""
    );
  };

  DeliverySchedule = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    let pr_delivery_schedule = "";
    pritems.forEach(function (item, index) {
      let quantity = item.quantity;
      let delivery_date = item.delivery_date;
      let date_arr = delivery_date.split("-");
      date_arr = date_arr.reverse();
      //delivery_date = delivery_date.replace(/-/g, "\/");
      delivery_date = date_arr.join("-");
      let line_item = item.pr_line_item;
      let pr_number = item.pr.number;
      pr_delivery_schedule +=
        delivery_date +
        "," +
        quantity +
        "," +
        pr_number +
        "(" +
        line_item +
        ");";
    });
    pr_delivery_schedule = pr_delivery_schedule.slice(0, -1);
    return pr_delivery_schedule;
  };

  PRNoLineItem = (field, item) => {
    let str = "";
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      str += prlineitem.pr.number + "(" + prlineitem.pr_line_item + "), ";
    });
    return str.slice(0, -2);
  };

  ReqDeliveryDate = (field, item) => {};

  LATEST = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    
    if (new URLSearchParams(this.props.location.search)?.get('multiplant_rfq') == 1 && 
        this.props.location?.pathname.indexOf("/prcombination") > -1 && 
        prconfig[field].response_key == 'plant') {
      const plants = Object.values(this.props.prlineitems).flat().map(item => item.plant);
      const uniquePlants = [...new Set(plants)];
      return uniquePlants;
    } else {

      let pr_line_item_object = pritems[pritems.length - 1];
      let expression = "pr_line_item_object" + "." + prconfig[field].response_key;
      let result = "";
      try {
        result = eval(expression);
      } catch (e) {}
  
      return result;
    }
  };

  MERGE = (field,item) => {
    let pritems = this.props.prlineitems[item.id];
    
    if (new URLSearchParams(this.props.location.search)?.get('multiplant_rfq') == 1 && 
        this.props.location?.pathname.indexOf("/prcombination") > -1 && 
        prconfig[field].response_key == 'plant') {
      const plants = Object.values(this.props.prlineitems).flat().map(item => item.plant);
      const uniquePlants = [...new Set(plants)];
      return uniquePlants;
    } else {

      let pr_line_item_object = pritems[pritems.length - 1];
      let expression = "pr_line_item_object" + "." + prconfig[field].response_key;
      let result = "";
      try {
        result = eval(expression);
      } catch (e) {}
  
      return result;
    }
  }

  LATEST_PO_DATE = (field, item) => {
    let value = "";
    value = this.LATEST(field, item);
    if (value) {
      let pattern = /\b(\d{4})(\d{2})(\d{2})\b/;
      let result = value.match(pattern);
      if (result) {
        result.shift();
        value = result.join("-");
      }
    }
    return value;
  };

  QTY_Check = (qty) => {
    let $moq_permission =
      this.props.accountModulePermission[
        "Allow more Quantity in PR Qty Distribution"
      ]?.module_activated;
    let $service_rfq_permission =
      this.props.accountModulePermission["Service PR"]?.module_activated;
    let readOnly = true;
    if ($moq_permission === "Y" || $service_rfq_permission === "Y") {
      readOnly = false;
    }
    return readOnly;
  };

  COMPANY_CODE_Check = (company_code) => {
    return company_code ? true : false;
  };

  initializeState = async () => {
    let initialSlab = {};
    let finalState = {};
    let index = 0;
    if (this.props.jsonTemplate?.ITEMIZE?.length) {
    //   for (let index = 0; index < 1; index++) {
        let obj = {};
        this.state.usedAttributesId.push(
          this.props.jsonTemplate?.ITEMIZE[0][1]?.Child?.AttributeId
        );
        this.setState({
          usedAttributesId: this.state.usedAttributesId,
        });

        for (
          let fieldIndex = 0;
          fieldIndex < this.props.jsonTemplate?.ITEMIZE[0].length;
          fieldIndex++
        ) {
          const row = this.props.jsonTemplate?.ITEMIZE[0][fieldIndex];
          if (row.VisibilityFlag === "Y") {
            // if (row.Identifier === "Product" || row.Identifier === "ProductForeign") {
            //   obj[row.FieldId] = {
            //     HTMLAttributeName: row.HTMLAttributeName,
            //     value: this.props.selectedItems[index].old_id,
            //     Validation: "",
            //     FieldLabel: row.FieldLabel,
            //     error: false,
            //     Identifier: row.Identifier,
            //     ErrorMessage: "",
            //   };
            //   continue;
            // }
            // if (row.Identifier === "ProductCode") {
            //   obj[row.FieldId] = {
            //     HTMLAttributeName: row.HTMLAttributeName,
            //     value: this.props.selectedItems[index].code,
            //     Validation: "",
            //     FieldLabel: row.FieldLabel,
            //     error: false,
            //     Identifier: row.Identifier,
            //     ErrorMessage: "",
            //   };
            //   continue;
            // }

            let value = "";
            let editableJsonField = 0;
            // if (row.Identifier === "MasterSpecification") {
            //   value = this.props.selectedItems[index].specifications;
            // }
            // else if (row.Identifier === "MasterHSNCode") {
            //   value = this.props.selectedItems[index].hsn_code;
            // }
            let readOnly = false;
            if (
            //   Object.keys(this.props.prlineitems).length &&
              prconfig &&
              prconfig[row.Identifier] !== undefined
            ) {
              value = 1;
              editableJsonField = 1;
                // eval(this[prconfig[row.Identifier].function_name])(
                //   row.Identifier,
                //   this.props.selectedItems[index]
                // ) || "";
              if (
                prconfig[row.Identifier]?.non_editable_function &&
                prconfig[row.Identifier]?.non_editable_function !== "0"
              ) {
                
                if (prconfig[row.Identifier]?.non_editable_function == "1") {
                  readOnly = true;
                } else {
                  readOnly = eval(
                    this[prconfig[row.Identifier]?.non_editable_function]
                  )(value);
                }
              }
            }
            if(row.ElementType === 'T' && value == '') value = row.DefaultValue;
            obj[row.FieldId] = {
              HTMLAttributeName: row.HTMLAttributeName,
              value: value,
              Validation: row.Validation,
              FieldLabel: row.FieldLabel,
              error: false,
              ErrorMessage: row.ErrorMessage,
              Identifier: row.Identifier,
              ElementType: row.ElementType,
              readOnly: readOnly,
              displayTxt: "Derived From PR",
              editableJsonField : editableJsonField
            };
            if (
              row.Identifier === "EstimatedCost" ||
              row.Identifier === "EstimatedCostForeign" ||
              row.Identifier === "Budgetedrate" ||
              row.Identifier === "BudgetedrateForeign"
            ) {
            //   obj[row.FieldId][
            //     `upd_budget_master_${1}`
            //   ] = false;

            //   if (
            //     this.props.featurePermission
            //       ?.Fetch_Budget_Value_in_RFQ_Form_from_Item_Master
            //       ?.feature_value === "Y" &&
            //     this.props.selectedItems[index]?.budgeted_rate
            //   ) {
            //     if (
            //       parseFloat(this.props.selectedItems[index]?.budgeted_rate)
            //     ) {
            //       obj[row.FieldId]["value"] =
            //         this.props.selectedItems[index].budgeted_rate;
            //       obj["rate"] = this.props.selectedItems[index].budgeted_rate;
            //     } else {
            //       obj["rate"] = "";
            //     }
            //   } else {
                obj["rate"] = value;
              }
            }
            if (row.ElementType === "S") {
              if (row.DropdownEdit === "Y") {
                obj[row.FieldId]["options"] = [];
                //getting options data from redux if it is available else calling api
                let optionsData = this.getOptionsFromRedux(row, "itemize");
                
                if (optionsData) {
                  obj[row.FieldId]["options"] = optionsData;
                  this.setState({
                    commonOptions: {
                      ...this.state.commonOptions,
                      [row.Identifier]: optionsData,
                    },
                  });
                } else {
                  obj[row.FieldId]["options"] = await this.getItemizeOptions(
                    row,
                    index
                  );
                }
              } else {
                obj[row.FieldId]["options"] = row.DefaultValue;
              }
            }
            // if (
            //   row.Identifier === "BasicHistoricalPrice" ||
            //   row.Identifier === "LandedHistoricalPrice" ||
            //   row.Identifier === "DateHP"
            // ) {
            //   obj[row.FieldId]["DynamicDefaultValue"] = "loading";
            //   this.getDynamicDefaultValues(row, index, row.DefaultValue);
            // }
        //   }
          if (row.Identifier === "EstimatedValue") {
            obj[row.FieldId] = {
              HTMLAttributeName: row.HTMLAttributeName,
              value: "",
              Validation: row.Validation,
              FieldLabel: row.FieldLabel,
              error: false,
              ErrorMessage: row.ErrorMessage,
              Identifier: row.Identifier,
            };
          }
          // if ( row.Identifier === "ProductQuantity") obj.qty =  obj[row.FieldId].value
          if (row.Identifier === "ProductQuantity" && obj[row.FieldId]){
            obj.qty = obj[row.FieldId].value;
          }
            
        }
        obj.specification = "";
        if (!obj.hasOwnProperty("qty")) {
          obj.qty = "";
        }
        if (!obj.hasOwnProperty("slab")) {
          obj["slab"] = [
            { id: 1, start: "1", end: "", error: false },
            { id: 2, start: "More Than", end: "", error: false },
          ];
        }

        finalState[1] = obj;
        if (this.props.rfqSettingValues.includeSlab && this.props.location.pathname.indexOf("/prcombination") == -1) {
          initialSlab[1] = { isOpen: true };
        } else {
          initialSlab[1] = { isOpen: false };
        }
    //   }
      this.setState(
        {
          mainTemplate: finalState,
          slab: initialSlab,
        },
        () => {
           if (
            this.props.featurePermission?.prev_tran_value?.feature_value ===
              "Y" &&
            this.props.prplants.length === 0 && Object.keys(this.props.ImportExcelRfq).length == 0
          ) {
            // this.fetchPrevious();
          }
        }
      );
      
    }
  };

  editInitializeState = async () => {
    let initialSlab = {};
    let finalState = {};
    let index = 0;
    if (this.props.jsonTemplate?.ITEMIZE?.length) {
    //   for (let index = 0; index < 1; index++) {
        let obj = {};
        this.state.usedAttributesId.push(
          this.props.jsonTemplate?.ITEMIZE[0][1]?.Child?.AttributeId
        );
        this.setState({
          usedAttributesId: this.state.usedAttributesId,
        });

        for (
          let fieldIndex = 0;
          fieldIndex < this.props.jsonTemplate?.ITEMIZE[0].length;
          fieldIndex++
        ) {
          const row = this.props.jsonTemplate?.ITEMIZE[0][fieldIndex];
          if (row.VisibilityFlag === "Y") {

            let value = "";
            let editableJsonField = 0;
            let readOnly = false;

            if (
              prconfig &&
              prconfig[row.Identifier] !== undefined
            ) {
              value = 1;
              editableJsonField = 1;
              if (
                prconfig[row.Identifier]?.non_editable_function &&
                prconfig[row.Identifier]?.non_editable_function !== "0"
              ) {
                
                if (prconfig[row.Identifier]?.non_editable_function == "1") {
                  readOnly = true;
                } else {
                  readOnly = eval(
                    this[prconfig[row.Identifier]?.non_editable_function]
                  )(value);
                }
              }
            }
            if(row.ElementType === 'T' && value == '') value = row.DefaultValue;

            
            let itemizeData = this.props.taskData?.action_document_data?.template?.data?.ITEMIZE;

            if (itemizeData.hasOwnProperty(row.Identifier)) {
              value = itemizeData[row.Identifier];
            }
            
            obj[row.FieldId] = {
              HTMLAttributeName: row.HTMLAttributeName,
              value: value,
              Validation: row.Validation,
              FieldLabel: row.FieldLabel,
              error: false,
              ErrorMessage: row.ErrorMessage,
              Identifier: row.Identifier,
              ElementType: row.ElementType,
              readOnly: readOnly,
              displayTxt: "Derived From PR",
              editableJsonField : editableJsonField
            };
            if (
              row.Identifier === "EstimatedCost" ||
              row.Identifier === "EstimatedCostForeign" ||
              row.Identifier === "Budgetedrate" ||
              row.Identifier === "BudgetedrateForeign"
            ) {
                obj["rate"] = value;
              }
            }
            if (row.ElementType === "S") {
              if (row.DropdownEdit === "Y") {
                obj[row.FieldId]["options"] = [];
                //getting options data from redux if it is available else calling api
                let optionsData = this.getOptionsFromRedux(row, "itemize");
                
                if (optionsData) {
                  obj[row.FieldId]["options"] = optionsData;
                  this.setState({
                    commonOptions: {
                      ...this.state.commonOptions,
                      [row.Identifier]: optionsData,
                    },
                  });
                } else {
                  obj[row.FieldId]["options"] = await this.getItemizeOptions(
                    row,
                    index
                  );
                }
              } else {
                obj[row.FieldId]["options"] = row.DefaultValue;
              }
            }
          if (row.Identifier === "EstimatedValue") {
            obj[row.FieldId] = {
              HTMLAttributeName: row.HTMLAttributeName,
              value: "",
              Validation: row.Validation,
              FieldLabel: row.FieldLabel,
              error: false,
              ErrorMessage: row.ErrorMessage,
              Identifier: row.Identifier,
            };
          }
          
          if (row.Identifier === "ProductQuantity" && obj[row.FieldId]){
            obj.qty = obj[row.FieldId].value;
          }
            
        }
        obj.specification = "";
        if (!obj.hasOwnProperty("qty")) {
          obj.qty = "";
        }
        if (!obj.hasOwnProperty("slab")) {
          obj["slab"] = [
            { id: 1, start: "1", end: "", error: false },
            { id: 2, start: "More Than", end: "", error: false },
          ];
        }

        finalState[1] = obj;
        if (this.props.rfqSettingValues.includeSlab && this.props.location.pathname.indexOf("/prcombination") == -1) {
          initialSlab[1] = { isOpen: true };
        } else {
          initialSlab[1] = { isOpen: false };
        }
    //   }
      this.setState(
        {
          mainTemplate: finalState,
          slab: initialSlab,
        }
      );
    }
  };

  initializeLocalRecipientsState = () => {
    let row = {};
    this.props.jsonTemplate?.LOCAL?.forEach((cell) => {
      row[cell.FieldId] = {
        HTMLAttributeName: cell.HTMLAttributeName,
        value: "",
        Validation: cell.Validation,
        FieldLabel: cell.FieldLabel,
        error: false,
        ErrorMessage: cell.ErrorMessage,
        Identifier: cell.Identifier,
        ElementType: cell.ElementType,
      };
      if (cell.ElementType === "S") {
        if (cell.DropdownEdit === "Y") {
          row[cell.FieldId]["options"] = "loading";
          //getting options data from redux if it is available else calling api
          let optionsData = this.getOptionsFromRedux(cell, "local");
          if (optionsData) {
            row[cell.FieldId]["options"] = optionsData;
          } else {
            this.getOptionsFromApi(cell, "local");
          }
        } else {
          row[cell.FieldId]["options"] = cell.DefaultValue;
        }
      } else {
        row[cell.FieldId]["value"] = cell.DefaultValue || "";
      }
    });
    
    this.setState({
      localTemplate: row,
    });
  };

  editInitializeLocalRecipientsState = () => {
    let row = {};
    this.props.jsonTemplate?.LOCAL?.forEach((cell) => {
      
      row[cell.FieldId] = {
        HTMLAttributeName: cell.HTMLAttributeName,
        value: "",
        Validation: cell.Validation,
        FieldLabel: cell.FieldLabel,
        error: false,
        ErrorMessage: cell.ErrorMessage,
        Identifier: cell.Identifier,
        ElementType: cell.ElementType,
      };
      if (cell.ElementType === "S") {
        if (cell.DropdownEdit === "Y") {
          row[cell.FieldId]["options"] = "loading";
          //getting options data from redux if it is available else calling api
          let optionsData = this.getOptionsFromRedux(cell, "local");
          if (optionsData) {
            row[cell.FieldId]["options"] = optionsData;
          } else {
            this.getOptionsFromApi(cell, "local");
          }
        } else {
          row[cell.FieldId]["options"] = cell.DefaultValue;
        }
      } 

      let localData = this.props.taskData?.action_document_data?.template?.data?.LOCAL;
      if (localData.hasOwnProperty(cell.Identifier)) {
        row[cell.FieldId]["value"] = localData[cell.Identifier];
      }

    });

    this.setState({
      localTemplate: row,
    });
  };

  getItemizeOptions = (cell, index) => {
    
    let templateId = this.props.jsonTemplate?.PARAM[0]?.template_id;
    // if (index !== 0) {
    //   return this.state.commonOptions[cell.Identifier];
    // }
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = getDropdownOptions(
      cell.Identifier,
      this.props.jsonTemplate?.PARAM[0]?.template_id
    );
    this.toggleLoaderArray(
      index ? `${cell.Identifier}_${index}` : cell.Identifier
    );
    return axios
      .get(url)
      .then((res) => {
        this.toggleLoaderArray(
          index ? `${cell.Identifier}_${index}` : cell.Identifier
        );
        if (res.data.dropdown_mapping_status !== false) {
          this.setState({
            commonOptions: {
              ...this.state.commonOptions,
              [cell.Identifier]: res.data.data,
            },
          });
          //setting itemize data into redux
          this.setOptionsInRedux(
            templateId,
            cell.Identifier,
            res.data.data,
            "itemize"
          );
          return res.data.data;
        } else {
          this.setState({
            commonOptions: {
              ...this.state.commonOptions,
              [cell.Identifier]: cell.DefaultValue,
            },
          });
          this.setOptionsInRedux(
            templateId,
            cell.Identifier,
            cell.DefaultValue,
            "itemize"
          );
          return cell.DefaultValue;
        }
      })
      .catch((err) => {
        console.log(err, "options error");
        this.toggleLoaderArray(
          index ? `${cell.Identifier}_${index}` : cell.Identifier
        );
        return [];
      });
  };
  getOptionsFromApi = (cell, template) => {
    let templateId = this.props.jsonTemplate?.PARAM[0]?.template_id;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = getDropdownOptions(
      cell.Identifier,
      this.props.jsonTemplate?.PARAM[0]?.template_id
    );
    
    this.toggleLoaderArray(cell.Identifier);
    axios
      .get(url)
      .then((res) => {
        if (template === "local") {
          if (res.data.dropdown_mapping_status !== false) {
            this.setState((prevState) => ({
              ...prevState,
              localTemplate: {
                ...prevState.localTemplate,
                [cell.FieldId]: {
                  ...prevState.localTemplate[cell.FieldId],
                  options: res.data?.data,
                },
              },
            }));
            //setting local data into redux
            this.setOptionsInRedux(
              templateId,
              cell.Identifier,
              res.data.data,
              template
            );
          } else {
            this.setState((prevState) => ({
              ...prevState,
              localTemplate: {
                ...prevState.localTemplate,
                [cell.FieldId]: {
                  ...prevState.localTemplate[cell.FieldId],
                  options: cell.DefaultValue,
                },
              },
            }));
          }
        } else if (template === "intl") {
          if (res.data.dropdown_mapping_status !== false) {
            this.setState((prevState) => ({
              ...prevState,
              IntlTemplate: {
                ...prevState.IntlTemplate,
                [cell.FieldId]: {
                  ...prevState.IntlTemplate[cell.FieldId],
                  options: res.data?.data,
                },
              },
            }));
            //setting intl data into redux
            this.setOptionsInRedux(
              templateId,
              cell.Identifier,
              res.data.data,
              template
            );
          } else {
            this.setState((prevState) => ({
              ...prevState,
              IntlTemplate: {
                ...prevState.IntlTemplate,
                [cell.FieldId]: {
                  ...prevState.IntlTemplate[cell.FieldId],
                  options: cell.DefaultValue,
                },
              },
            }));
          }
        } else if (template === "rfq") {
          //let value = ""
          // if (res.data.dropdown_mapping_status === false) {
          //   if(cell.Identifier === "Destination" ) {
          //     if(res.data?.data.length === 0) {
          //       this.props.openSnackbar("error",`Destination ${this.props.selectedPrCatagory.plant.name} doesnt exist`)
          //     }

          //   }
          // }

          if (res.data.dropdown_mapping_status !== false) {
            let value = this.state.RFQDynamic[cell.FieldId]["value"];
            if (value) {
              res.data?.data.forEach((option, id) => {
                if (option.dropdown_element_code === value) {
                  value = `${option.dropdown_element_code}-${option.dropdown_element_name}`;
                  if (this.props.companyInfo?.default_dropdown_type === "10" || 
                    this.state.RFQDynamic[cell.FieldId]["Identifier"] === "Destination" || this.state.RFQDynamic[cell.FieldId]["Identifier"] === "DestinationForeign"
                  ) {
                    value = `${option.dropdown_element_name}-${option.dropdown_element_code}`;
                  }
                }
              });
            }
            // if(cell.Identifier === "Destination" ) {
            //   let count = 0;

            //   console.log("Destination|| === ",this.props.selectedPrCatagory.plant.code)
            //   if(this.props.prplants.length > 0) {

            //     res.data?.data.forEach((option, id) => {

            //         if(option.dropdown_element_code ===  this.props.prplants[0]) {
            //           count++;
            //           console.log("foundd")
            //           value= `${option.dropdown_element_name}-${option.dropdown_element_code}`
            //         }
            //     });
            //     console.log("Doesnt Exist",value)
            //     if(count === 0) {

            //       this.props.openSnackbar("error",`Destination ${this.props.selectedPrCatagory.plant.name} doesnt exist`)
            //     }
            //   }
            // }

            this.setState((prevState) => ({
              ...prevState,
              RFQDynamic: {
                ...prevState.RFQDynamic,
                [cell.FieldId]: {
                  ...prevState.RFQDynamic[cell.FieldId],
                  options: res.data?.data,
                  value,
                },
              },
            }));
            //setting rfq data into redux
            this.setOptionsInRedux(
              templateId,
              cell.Identifier,
              res.data.data,
              template
            );
          } else {
            this.setState((prevState) => ({
              ...prevState,
              RFQDynamic: {
                ...prevState.RFQDynamic,
                [cell.FieldId]: {
                  ...prevState.RFQDynamic[cell.FieldId],
                  options: cell.DefaultValue,
                },
              },
            }));
          }
        }
        this.toggleLoaderArray(cell.Identifier);
      })
      .catch((err) => {
        console.log(err, "options error");
        if (template === "local") {
          this.setState((prevState) => ({
            ...prevState,
            localTemplate: {
              ...prevState.localTemplate,
              [cell.FieldId]: {
                ...prevState.localTemplate[cell.FieldId],
                options: [],
              },
            },
          }));
        } else if (template === "intl") {
          this.setState((prevState) => ({
            ...prevState,
            IntlTemplate: {
              ...prevState.IntlTemplate,
              [cell.FieldId]: {
                ...prevState.IntlTemplate[cell.FieldId],
                options: [],
              },
            },
          }));
        } else if (template === "rfq") {
          this.setState((prevState) => ({
            ...prevState,
            RFQDynamic: {
              ...prevState.RFQDynamic,
              [cell.FieldId]: {
                ...prevState.RFQDynamic[cell.FieldId],
                options: [],
              },
            },
          }));
        }
        this.toggleLoaderArray(cell.Identifier);
      });
  };
  //setting options data into redux
  setOptionsInRedux = (templateId, name, data, template) => {
    let tempObject = {
      data,
      template,
    };
    let updateTemplateValues = this.props.templateValues;
    if (Object.keys(updateTemplateValues).length !== 0) {
      if (Object.keys(updateTemplateValues).some((val) => val == templateId)) {
        if (
          !Object.keys(updateTemplateValues[templateId]).some(
            (item) => item == name
          )
        ) {
          let newTemplateValues = {
            ...updateTemplateValues[templateId],
            [name]: tempObject,
          };
          this.props.setTemplateValues({ [templateId]: newTemplateValues });
        }
      } else {
        this.props.setTemplateValues({ [templateId]: { [name]: tempObject } });
      }
    } else {
      this.props.setTemplateValues({ [templateId]: { [name]: tempObject } });
    }
  };
  //getting options data from redux
  getOptionsFromRedux = (cell, template) => {
    let templateId = this.props.jsonTemplate?.PARAM[0]?.template_id;
    let updateTemplateValues = this.props.templateValues;
    if (Object.keys(updateTemplateValues).length !== 0) {
      if (updateTemplateValues[templateId]) {
        let cellData = updateTemplateValues[templateId][cell.Identifier];
        if (cellData && cellData.template == template) return cellData.data;
      }
    }
  };
  getDynamicDefaultValues = (cell, index, DefaultValue) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = getIdentifiersDynamicValues(
      cell.Identifier,
      1,
      this.props.selectedCategory.id
    );
    this.toggleLoaderArray(
      `${cell.Identifier}_getDynamicDefaultValues_${index}`
    );
    axios
      .get(url)
      .then((res) => {
        if (res.data) {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplate: {
              ...prevState.mainTemplate,
              [1]: {
                ...prevState.mainTemplate[1],
                [cell.FieldId]: {
                  ...prevState.mainTemplate[
                    1
                  ][cell.FieldId],
                  DynamicDefaultValue:
                    res.data.path[0][0][
                      `${
                        cell.Identifier === "BasicHistoricalPrice"
                          ? "last_basic_rate"
                          : cell.Identifier === "LandedHistoricalPrice"
                          ? "landed_cost"
                          : cell.Identifier === "DateHP" && "update_timestamp"
                      }`
                    ],
                },
              },
            },
          }));
        } else {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplate: {
              ...prevState.mainTemplate,
              [1]: {
                ...prevState.mainTemplate[1],
                [cell.FieldId]: {
                  ...prevState.mainTemplate[
                    1
                  ][cell.FieldId],
                  DynamicDefaultValue: DefaultValue,
                },
              },
            },
          }));
        }

        this.toggleLoaderArray(
          `${cell.Identifier}_getDynamicDefaultValues_${index}`
        );
      })
      .catch((err) => {
        console.log(err, "getDynamicDefaultValues");
        if (this.props.selectedItems) {
          if (this.props.selectedItems[index]) {
            this.setState((prevState) => ({
              ...prevState,
              mainTemplate: {
                ...prevState.mainTemplate,
                [1]: {
                  ...prevState.mainTemplate[
                    1
                  ],
                  [cell.FieldId]: {
                    ...prevState.mainTemplate[
                      1
                    ][cell.FieldId],
                    options: [],
                  },
                },
              },
            }));
          }
        }

        this.toggleLoaderArray(
          `${cell.Identifier}_getDynamicDefaultValues_${index}`
        );
      });
  };
  initializeIntlRecipientsState = () => {
    let row = {};
    this.props.jsonTemplate?.FOREIGN?.forEach((cell) => {
      row[cell.FieldId] = {
        HTMLAttributeName: cell.HTMLAttributeName,
        value: "",
        Validation: cell.Validation,
        FieldLabel: cell.FieldLabel,
        error: false,
        ErrorMessage: cell.ErrorMessage,
        Identifier: cell.Identifier,
        ElementType: cell.ElementType,
      };
      if (cell.ElementType === "S") {
        if (cell.DropdownEdit === "Y") {
          row[cell.FieldId]["options"] = "loading";
          //getting options data from redux if it is available else calling api
          let optionsData = this.getOptionsFromRedux(cell, "intl");
          if (optionsData) {
            // console.log("from redux")
            row[cell.FieldId]["options"] = optionsData;
          } else {
            // console.log("from api")
            this.getOptionsFromApi(cell, "intl");
          }
        } else {
          row[cell.FieldId]["options"] = cell.DefaultValue;
        }
      }
    });
    
    this.setState({
      IntlTemplate: row,
    });
  };

  editInitializeIntlRecipientsState = () => {
    let row = {};
    this.props.jsonTemplate?.FOREIGN?.forEach((cell) => {
      let value = "";
      let intlData = this.props.taskData?.action_document_data?.template?.data?.FOREIGN;
      if (intlData.hasOwnProperty(cell.Identifier)) {
        value = intlData[cell.Identifier];
      }

      row[cell.FieldId] = {
        HTMLAttributeName: cell.HTMLAttributeName,
        value: value,
        Validation: cell.Validation,
        FieldLabel: cell.FieldLabel,
        error: false,
        ErrorMessage: cell.ErrorMessage,
        Identifier: cell.Identifier,
        ElementType: cell.ElementType,
      };
      if (cell.ElementType === "S") {
        if (cell.DropdownEdit === "Y") {
          row[cell.FieldId]["options"] = "loading";
          //getting options data from redux if it is available else calling api
          let optionsData = this.getOptionsFromRedux(cell, "intl");
          if (optionsData) {
            // console.log("from redux")
            row[cell.FieldId]["options"] = optionsData;
          } else {
            // console.log("from api")
            this.getOptionsFromApi(cell, "intl");
          }
        } else {
          row[cell.FieldId]["options"] = cell.DefaultValue;
        }
      }
    });

    this.setState({
      IntlTemplate: row,
    });
  };

  checkIsLinkLocal = (cell) => {
    
    //first check if the vendor master check is present or not
    if (
      this.props.taskData?.action_document_data?.inquiry_settings?.vendor_master_payment_terms &&
      this.props.taskData?.action_document_data?.inquiry_settings?.vendor_master_inco_terms
    ) {
      // check from both
      let str = this.props.companyInfo?.inco_term_identifier;
      let str1 = this.props.companyInfo?.payment_term_identifier;
      
      if (str) {
        let arr = str.split(";");
        if (arr.includes(cell.Identifier)) {
          return "inco";
        }
      }
      if (str1) {
        let arr1 = str1.split(";");
        if (arr1.includes(cell.Identifier)) {
          return "payment";
        }
      }
      return false;
    } else if (this.props.taskData?.action_document_data?.inquiry_settings?.vendor_master_payment_terms) {
      // paymentterm array
      if (!this.props.companyInfo?.payment_term_identifier) return false;
      let str = this.props.companyInfo?.payment_term_identifier;
      let arr = str.split(";");
      // let arr = ["PaymentType", "PaymentTypeForeign"];
      if (arr.includes(cell.Identifier)) {
        return "payment";
      }
      return false;
    } else if (this.props.taskData?.action_document_data?.inquiry_settings?.vendor_master_inco_terms) {
      // incoterm array
      if (!this.props.companyInfo?.inco_term_identifier) return false;
      let str = this.props.companyInfo?.inco_term_identifier;
      let arr = str.split(";");
      // let arr = ["DeliveryBasis", "DeliveryRemarks"];
      if (arr.includes(cell.Identifier)) {
        return "inco";
      }
      return false;
    }
    return false;
  };

  getFieldLocal = (cell) => {
    let error =
      this.state.localTemplate[cell.FieldId] &&
      this.state.localTemplate[cell.FieldId]["error"];
    let ErrorMessage =
      this.state.localTemplate[cell.FieldId] &&
      this.state.localTemplate[cell.FieldId]["ErrorMessage"];
    let name =
      this.state.localTemplate[cell.FieldId] &&
      this.state.localTemplate[cell.FieldId]["HTMLAttributeName"];
    let value =
      this.state.localTemplate[cell.FieldId] &&
      this.state.localTemplate[cell.FieldId]["value"];
    let isLink = this.checkIsLinkLocal(cell);
    if (isLink) { 
      let index1 = this.state.errorArray.indexOf(name);
      if (index1 !== -1) {
        this.state.errorArray.splice(index1, 1);
        this.setState({ errorArray: this.state.errorArray });
      }
      return (
        <React.Fragment>
          <Button
            className="local_template_textfield"
            fullWidth
            variant="outlined"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              border: "1x solid",
              borderColor: (theme) => theme.palette.bluegrey[500],
              padding: "16.5px 14px",
              color: "text.primary",
              fontWeight: 400,
              fontSize:
                "calc(14px - (14 - 16) * ((100vw - 300px) / (1920 - 300)))",
              lineHeight: 1,
              textTransform: "capitalize",
            }}
            onClick={() => {
              this.handleLocalVendorTermsOpen(isLink, cell.FieldId);
            }}
            endIcon={
              <OpenInNew
                sx={{ color: (theme) => theme.palette.primary.main }}
              />
            }
          >
            {cell.FieldLabel}
          </Button>
        </React.Fragment>
      );
    } else if (cell.ElementType === "T") {
      if (value) {
        return (
          <TextField
            key={this.state.prevRFQLoading}
            fullWidth
            className="local_template_textfield"
            variant="outlined"
            label={cell.FieldLabel}
            multiline
            maxRows={3}
            type="text"
            error={error}
            helperText={error && ErrorMessage}
            name={name}
            value={value}
          />
        );
      } else {
        return <Typography>{cell.FieldLabel}</Typography>;
      }
    } else if (cell.ElementType === "TB") {
      return (
        <TextField
          key={this.state.prevRFQLoading}
          className="local_template_textfield"
          fullWidth
          variant="outlined"
          label={cell.FieldLabel}
          type="text"
          error={error}
          helperText={error && ErrorMessage}
          name={name}
          onBlur={(e) => {
            // this.handleLocalChange(e, cell.FieldId);
            this.validateInputLocal(cell.FieldId);
          }}
          onChange={(e)=>{
            this.handleLocalChange(e, cell.FieldId);
            // this.validateInputLocal(cell.FieldId);
          }}
          value={value}
          // InputLabelProps={{ shrink: value?.length > 0 || undefined }}
        />
      );
    } else if (cell.ElementType === "TA") {
      return (
        <TextField
          key={this.state.prevRFQLoading}
          className="local_template_textarea"
          fullWidth
          multiline
          variant="outlined"
          maxRows={3}
          label={cell.FieldLabel}
          error={error}
          helperText={error && ErrorMessage}
          name={name}
          onBlur={(e) => {
            // this.handleLocalChange(e, cell.FieldId);
            this.validateInputLocal(cell.FieldId);
          }}
          onChange={(e)=>{
            this.handleLocalChange(e, cell.FieldId);
            // this.validateInputLocal(cell.FieldId);
          }}
          value={value}
        />
      );
    } else if (cell.ElementType === "M") {
      return (
        <Checkbox
          color="primary"
          checked={!!value}
          name={name}
          onChange={(e) => {
            this.handleLocalChange(e, cell.FieldId);
          }}
        />
      );
    } else if (cell.ElementType === "DT") {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            format="dd/MM/yyyy"
            slotProps={{
              textField: {
                size: "fullWidth",
                error: error,
                helperText: error && "Invalid Date",
              },
            }}
            label={cell.FieldLabel}
            name={name}
            value={value ? new Date(value) : null}
            onChange={(newVal) => {
              this.handleDateChange(
                moment(newVal),
                cell.FieldId,
                "localTemplate"
              );
            }}
          />
        </LocalizationProvider>
      );
    } else if (cell.ElementType === "F") {
      return (
        <FormControl error={error} sx={{ width: "100%" }}>
          <Box
            sx={{
              border: "solid 1px",
              borderColor: (theme) => theme.palette.bluegrey[500],
              borderRadius: "4px",
              minWidth: "100%",
              height: "56px",
              display: "flex",
              alignItems: "center",
              padding: "0 10px",
              position: "relative",
            }}
          >
            <InputLabel
              sx={{
                color: "text.secondary",
                backgroundColor: (theme) => theme.palette.grey[0],
                position: "absolute",
                left: 0,
                top: 0,
                paddingLeft: "5px",
                paddingRight: "5px",
                transformOrigin: "top left",
                webkitTransform: " translate(14px, -9px) scale(0.75)",
                transform: "translate(14px, -9px) scale(0.75)",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "calc(133% - 24px)",
                zIndex: 1,
              }}
            >
              {cell.FieldLabel}
            </InputLabel>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                this.setState({
                  currentFieldId: cell.FieldId,
                  dynamicFileUploadOpen: true,
                  currentTemplate: "localTemplate",
                });
              }}
            >
              <UploadIcon sx={{ marginRight: "10px" }} />
              Attachment
            </Button>
            <FormHelperText sx={{ ml: "auto" }}>
              Number Of Files {value?.length}
            </FormHelperText>
          </Box>
          <Typography variant="caption">
            {this.state.localTemplate[cell.HTMLAttributeName]}
          </Typography>
          {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
        </FormControl>
      );
    } else if (cell.ElementType === "S") {
      return (
        <FormControl fullWidth variant="outlined" error={error}>
          <InputLabel>{cell.FieldLabel}</InputLabel>
          <Tooltip title={value} disableInteractive placement="top-start">
            <Select
              // title={value}
              className="template_dropdown"
              label={cell.FieldLabel}
              name={name}
              value={value || ""}
              onChange={(e) => {
                this.handleLocalChange(e, cell.FieldId);
              }}
              onBlur={(e) => {
                this.validateInputLocal(cell.FieldId);
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 250,
                    width: 250,
                  },
                },
              }}
            >
              <MenuItem value={""}>Select</MenuItem>
              {this.state.localTemplate[cell.FieldId] &&
                this.state.localTemplate[cell.FieldId]["options"] &&
                (this.state.localTemplate[cell.FieldId]["options"].length >
                0 ? (
                  this.state.localTemplate[cell.FieldId]["options"] ===
                  "loading" ? (
                    <MenuItem value="">Loading...</MenuItem>
                  ) : (
                    this.state.localTemplate[cell.FieldId]["options"].map(
                      (option, id) => {
                        return (
                          <MenuItem
                            key={id}
                            value={
                              option.dropdown_element_name
                                ? this.props.companyInfo
                                    ?.default_dropdown_type === "10" || cell.Identifier === "Destination" || cell.Identifier === "DestinationForeign"
                                  ? `${option.dropdown_element_name}-${option.dropdown_element_code}`
                                  : `${option.dropdown_element_code}-${option.dropdown_element_name}`
                                : option.code
                            }
                          >
                            <Typography style={{ whiteSpace: "pre-line" }}>
                              {option.dropdown_element_name
                                ? option.dropdown_element_code ===
                                  option.dropdown_element_name
                                  ? option.dropdown_element_name
                                  : `${option.dropdown_element_code}-${option.dropdown_element_name}`
                                : option.name}
                            </Typography>
                          </MenuItem>
                        );
                      }
                    )
                  )
                ) : (
                  <MenuItem value="">Not found</MenuItem>
                ))}
            </Select>
          </Tooltip>
          {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
        </FormControl>
      );
    } else {
      return cell.FieldLabel;
    }
  };

  getFieldRFQ = (cell) => {
    let error =
      this.state.RFQDynamic[cell.FieldId] &&
      this.state.RFQDynamic[cell.FieldId]["error"];
    let ErrorMessage =
      this.state.RFQDynamic[cell.FieldId] &&
      this.state.RFQDynamic[cell.FieldId]["ErrorMessage"];
    let name =
      this.state.RFQDynamic[cell.FieldId] &&
      this.state.RFQDynamic[cell.FieldId]["HTMLAttributeName"];
    let value =
      this.state.RFQDynamic[cell.FieldId] &&
      this.state.RFQDynamic[cell.FieldId]["value"];
    let readonly =
      this.state.RFQDynamic[cell.FieldId] &&
      this.state.RFQDynamic[cell.FieldId]["readOnly"];
    let Instruction =
      this.state.RFQDynamic[cell.FieldId] &&
      this.state.RFQDynamic[cell.FieldId]["InstructionText"];
    if (cell.ElementType === "T") {
      if (value) {
        return (
          <React.Fragment>
            <TextField
              key={this.state.prevRFQLoading}
              fullWidth
              className="local_template_textfield"
              variant="outlined"
              label={cell.FieldLabel}
              multiline
              maxRows={3}
              type="text"
              error={error}
              helperText={error && ErrorMessage}
              name={name}
              value={value || ''}
              inputProps={{ readOnly: readonly }}
            />
            {Instruction !== "" && (
              <Typography
                style={{
                  color: "#8091a7",
                  marginLeft: "14px",
                  marginTop: "3px",
                  fontSize: "0.75rem",
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                }}
                id="Caption-T"
              >{`(${Instruction})`}</Typography>
            )}
          </React.Fragment>
        );
      } else {
        return <Typography>{cell.FieldLabel}</Typography>;
      }
    } else if (cell.ElementType === "TB") {
      return (
        <React.Fragment>
          <TextField
            key={value}
            fullWidth
            className="local_template_textfield"
            variant="outlined"
            label={cell.FieldLabel}
            type="text"
            error={error}
            helperText={error && ErrorMessage}
            name={name}
            // onBlur={(e) => {
            //   this.handleRFQDynamicChange(e, cell.FieldId);
            //   this.validateInputRFQ(cell.FieldId);
            // }}
            onBlur={(e) => {
              
              this.validateInputRFQ(cell.FieldId);
            }}
            onChange={(e)=>{
              this.handleRFQDynamicChange(e, cell.FieldId);
            }}
            value={value}
          />
          {Instruction !== "" && (
            <Typography
              style={{
                color: "#8091a7",
                marginLeft: "14px",
                marginTop: "3px",
                fontSize: "0.75rem",
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
              }}
              id="Caption-TB"
            >{`(${Instruction})`}</Typography>
          )}
        </React.Fragment>
      );
    } else if (cell.ElementType === "TA") {
      return (
        <React.Fragment>
          <TextField
            key={this.state.prevRFQLoading}
            className="local_template_textarea"
            fullWidth
            multiline
            variant="outlined"
            maxRows={3}
            label={cell.FieldLabel}
            error={error}
            helperText={error && ErrorMessage}
            name={name}
            value={value || ''}
            onChange={(e) => {
              this.handleRFQDynamicChange(e, cell.FieldId);
            }}
            onBlur={() => {
              this.validateInputRFQ(cell.FieldId);
            }}
          />
          {Instruction !== "" && (
            <Typography
              style={{
                color: "#8091a7",
                marginLeft: "14px",
                marginTop: "3px",
                fontSize: "0.75rem",
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
              }}
              id="Caption-TA"
            >{`(${Instruction})`}</Typography>
          )}
        </React.Fragment>
      );
    } else if (cell.ElementType === "M") {
      return (
        <Checkbox
          color="primary"
          name={name}
          checked={!!value}
          onChange={(e) => {
            this.handleRFQDynamicChange(e, cell.FieldId);
          }}
        />
      );
    } else if (cell.ElementType === "DT") {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            sx={{ width: "100%" }}
            format="dd/MM/yyyy"
            slotProps={{
              textField: {
                size: "fullWidth",
                error: error,
                placeholder: cell.FieldLabel,
                label: cell.FieldLabel,
                helperText:
                  error && (ErrorMessage ? ErrorMessage : "Invalid Date"),
              },
            }}
            ampm="false"
            // label={cell.FieldLabel}
            name={name}
            // value={value || null}
            value={value ? new Date(value) : null}
            onChange={(newVal) => {
              this.handleDateChange(moment(newVal), cell.FieldId, "RFQDynamic");
            }}
          />
        </LocalizationProvider>
      );
    } else if (cell.ElementType === "F") {
      return (
        <React.Fragment>
          <Box
            sx={{
              border: "solid 1px",
              borderColor: (theme) => theme.palette.bluegrey[500],
              borderRadius: "4px",
              minWidth: "100%",
              height: "56px",
              display: "flex",
              alignItems: "center",
              padding: "0 10px",
              position: "relative",
            }}
          >
            <InputLabel
              sx={{
                color: "text.secondary",
                backgroundColor: (theme) => theme.palette.grey[0],
                position: "absolute",
                left: 0,
                top: 0,
                paddingLeft: "5px",
                paddingRight: "5px",
                transformOrigin: "top left",
                webkitTransform: " translate(14px, -9px) scale(0.75)",
                transform: "translate(14px, -9px) scale(0.75)",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "calc(133% - 24px)",
                zIndex: 1,
              }}
            >
              {cell.FieldLabel}
            </InputLabel>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                this.setState({
                  currentFieldId: cell.FieldId,
                  dynamicFileUploadOpen: true,
                  currentTemplate: "RFQDynamic",
                });
              }}
              sx={{}}
            >
              <UploadIcon sx={{ marginRight: "10px" }} />
              Attachment
            </Button>
            <FormHelperText sx={{ marginLeft: "auto" }}>
              Number Of Files {value?.length}
            </FormHelperText>
          </Box>
        </React.Fragment>
      );
    } else if (cell.ElementType === "S") {
      //let disable=false
      // if(cell.Identifier === "Destination") {
      //   if(this.props.prplants.length > 0) {
      //     disable = true;
      //   }
      // }
      // if (new URLSearchParams(location.search)?.get('multiplant_rfq') == 1) {
        // return (
          // <Autocomplete
          //   multiple
          //   limitTags={2}
          //   id="multiple-limit-tags"
          //   options={top100Films}
          //   getOptionLabel={(option) => option.title}
          //   defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
          //   renderInput={(params) => (
          //     <TextField {...params} label="limitTags" placeholder="Favorites" />
          //   )}
          //   sx={{ width: '500px' }}
          // />
        // );
      // } else {
        // if (new URLSearchParams(this.props.location.search)?.get('multiplant_rfq') == 1 && this.props.location?.pathname.indexOf("/prcombination") > -1 && cell.Identifier == "Destination") {
        //   let optionsSelct = this.state.RFQDynamic[cell.FieldId] &&
        //   this.state.RFQDynamic[cell.FieldId]["options"] &&
        //   Array.isArray(
        //     this.state.RFQDynamic[cell.FieldId]["options"]
        //   ) &&
        //   this.state.RFQDynamic[cell.FieldId]["options"]?.length > 0
        //     ? this.state.RFQDynamic[cell.FieldId]["options"].map(
        //         (option) =>
        //           option.dropdown_element_name
        //             ? `${option.dropdown_element_name}-${option.dropdown_element_code}`
        //             : option.name
        //       )
        //     : [];
        //     let Destinations = []
        //     value?.map((dest)=> {
        //       if (this.props?.pr_dropdown_values.plant?.[dest]) {
        //         Destinations.push(`${this.props?.pr_dropdown_values.plant?.[dest]?.Dropdown_Element_Name}-${dest}`)
        //       }
              
        //     })
        //       return (
        //         <Tooltip
        //         title={Destinations && Destinations.join(',')}
        //         disableInteractive
        //         placement="top-start"
        //       >
        //         <Autocomplete
        //           multiple
        //           // disabled={true}
        //           limitTags={2}
        //           id="multiple-limit-tags"
        //           getOptionLabel={(option) => option}
        //           disableCloseOnSelect
        //           open={false} // Set open to false to keep the dropdown closed
        //           onOpen={() => {}} 
        //           // getOptionLabel={(option) => option.title}
        //           options={optionsSelct}
        //           // defaultValue={[optionsSelct?.[0],optionsSelct?.[1]]}
        //           value={Destinations}
        //           renderInput={(params) => (
        //             <TextField {...params} label={cell.Identifier} />
        //           )}
        //           // sx={{ width: '500px' }}
        //         />
        //         </Tooltip>

        //       );
        // }
        return (
          <FormControl fullWidth variant="outlined" error={error}>
            {cell.Identifier == "Destination" &&
            this.props.rfqSettingValues.allowMultiPlant ? (
              <Tooltip
                title={value && value?.join(",")}
                disableInteractive
                placement="top-start"
              >
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="checkboxes-tags-demo"
                  // options={this.state.RFQDynamic[cell.FieldId]["options"]?.length > 0 ?this.state.RFQDynamic[cell.FieldId]["options"].map((option) => option.dropdown_element_name
                  options={
                    this.state.RFQDynamic[cell.FieldId] &&
                    this.state.RFQDynamic[cell.FieldId]["options"] &&
                    Array.isArray(
                      this.state.RFQDynamic[cell.FieldId]["options"]
                    ) &&
                    this.state.RFQDynamic[cell.FieldId]["options"]?.length > 0
                      ? this.state.RFQDynamic[cell.FieldId]["options"].map(
                          (option) =>
                            option.dropdown_element_name
                              ? `${option.dropdown_element_name}-${option.dropdown_element_code}`
                              : option.name
                        )
                      : []
                  }
                  disableCloseOnSelect
                  onChange={(e, val) => {
                    this.handleRFQDynamicChange(e, cell.FieldId, val);
                  }}
                  value={value || []}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={error}
                      placeholder="Search Location"
                      label={cell.FieldLabel}
                    />
                  )}
                />
              </Tooltip>
            ) : (
              <React.Fragment>
                <InputLabel>{cell.FieldLabel}</InputLabel>
                <Tooltip title={value} disableInteractive placement="top-start">
                  <Select
                    // title={value}
                    className="template_dropdown"
                    label={cell.FieldLabel}
                    readOnly={readonly}
                    name={name}
                    value={value || ""}
                    onChange={(e) => {
                      this.handleRFQDynamicChange(e, cell.FieldId);
                    }}
                    onBlur={() => {
                      this.validateInputRFQ(cell.FieldId);
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 250,
                          width: 250,
                        },
                      },
                    }}
                  >
                    <MenuItem value={""}>Select</MenuItem>
                    {this.state.RFQDynamic[cell.FieldId] &&
                      (this.state.RFQDynamic[cell.FieldId]["options"]?.length >
                      0 ? (
                        this.state.RFQDynamic[cell.FieldId]["options"] ===
                        "loading" ? (
                          <MenuItem value="">Loading...</MenuItem>
                        ) : (
                          this.state.RFQDynamic[cell.FieldId]["options"].map(
                            (option, id) => {
                              return (
                                <MenuItem
                                  key={id}
                                  value={
                                    option.dropdown_element_name
                                      ? this.props.companyInfo
                                          ?.default_dropdown_type === "10" ||
                                        cell.Identifier == "Destination" ||
                                        cell.Identifier == "DestinationForeign"
                                        ? `${option.dropdown_element_name}-${option.dropdown_element_code}`
                                        : `${option.dropdown_element_code}-${option.dropdown_element_name}`
                                      : option.code
                                  }
                                >
                                  <Typography
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {/* {option.dropdown_element_name || option.name} */}
                                    {option.dropdown_element_name
                                      ? option.dropdown_element_code ===
                                        option.dropdown_element_name
                                        ? option.dropdown_element_name
                                        : `${option.dropdown_element_code}-${option.dropdown_element_name}`
                                      : option.name}
                                  </Typography>
                                </MenuItem>
                              );
                            }
                          )
                        )
                      ) : (
                        <MenuItem value="">Not Found</MenuItem>
                      ))}
                  </Select>
                </Tooltip>
              </React.Fragment>
            )}
            {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
          </FormControl>
        );
      // }
    } else {
      return cell.FieldLabel;
    }
    
  };
  finddependacy = (FieldId, validation) => {
    let field_ids = validation.match(/f(\d)*/g);
    let is_dependacy = 0;
    for (const field_id of field_ids) {
      if ("f" + FieldId != field_id) {
        is_dependacy = 1;
        break;
      }
    }
    return is_dependacy;
  };
  validateInputRFQ = (FieldId, submitting) => {
    if (submitting) {
      if (
        this.state.RFQDynamic[FieldId]["error"] &&
        !this.finddependacy(
          FieldId,
          this.state.RFQDynamic[FieldId]["Validation"]
        )
      )
        return;
    }
    if (this.state.RFQDynamic[FieldId]?.["Validation"]) {
      let temp = this.state.RFQDynamic[FieldId]["Validation"].replace(
        /f(\d)*/g,
        (match) => {
          if (this.state.RFQDynamic[match.replace("f", "")]) {
            return `this.state.RFQDynamic[${match.replace("f", "")}]["value"]`;
          } else if (this.state.localTemplate[match.replace("f", "")]) {
            return `this.state.localTemplate[${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else if (this.state.IntlTemplate[match.replace("f", "")]) {
            return `this.state.IntlTemplate[${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else {
            return null;
          }
        }
      );
      if (temp !== null) {
        if (this.state.RFQDynamic[FieldId]["ElementType"] == "DT") {
          this.state.RFQDynamic[FieldId]["value"] = moment(
            this.state.RFQDynamic[FieldId]["value"]
          ).format("YYYY-MM-DD");
        }
        if (eval(temp)) {
          this.setState((prevState) => ({
            ...prevState,
            RFQDynamic: {
              ...prevState.RFQDynamic,
              [FieldId]: {
                ...prevState.RFQDynamic[FieldId],
                error: false,
              },
            },
          }));

          let index1 = this.state.errorArray.indexOf(
            this.state.RFQDynamic[FieldId]["HTMLAttributeName"]
          );
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }
        } else {
          this.setState((prevState) => ({
            ...prevState,
            RFQDynamic: {
              ...prevState.RFQDynamic,
              [FieldId]: {
                ...prevState.RFQDynamic[FieldId],
                error: true,
              },
            },
          }));

          if (
            !this.state.errorArray.includes(
              this.state.RFQDynamic[FieldId]["HTMLAttributeName"]
            )
          ) {
            this.state.errorArray.push(
              this.state.RFQDynamic[FieldId]["HTMLAttributeName"]
            );
          }
        }
        this.setState({
          errorArray: this.state.errorArray,
        });
      } else {
        console.log("dependent field not found in template");
      }
    }
  };

  getFieldINTL = (cell) => {
    let error =
      this.state.IntlTemplate[cell.FieldId] &&
      this.state.IntlTemplate[cell.FieldId]["error"];
    let ErrorMessage =
      this.state.IntlTemplate[cell.FieldId] &&
      this.state.IntlTemplate[cell.FieldId]["ErrorMessage"];
    let name =
      this.state.IntlTemplate[cell.FieldId] &&
      this.state.IntlTemplate[cell.FieldId]["HTMLAttributeName"];
    let value =
      this.state.IntlTemplate[cell.FieldId] &&
      this.state.IntlTemplate[cell.FieldId]["value"];

    let isLink = this.checkIsLinkLocal(cell);
    if (isLink) {
      let index1 = this.state.errorArray.indexOf(name);
      if (index1 !== -1) {
        this.state.errorArray.splice(index1, 1);
        this.setState({ errorArray: this.state.errorArray });
      }
      return (
        <React.Fragment>
          <Button
            className="local_template_textfield"
            fullWidth
            variant="outlined"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              border: "1x solid",
              borderColor: (theme) => theme.palette.bluegrey[500],
              padding: "16.5px 14px",
              color: "text.primary",
              fontWeight: 400,
              fontSize:
                "calc(14px - (14 - 16) * ((100vw - 300px) / (1920 - 300)))",
              lineHeight: 1,
              textTransform: "capitalize",
            }}
            onClick={() => {
              this.handleIntlVendorTermsOpen(isLink, cell.FieldId);
            }}
            endIcon={
              <OpenInNew
                sx={{ color: (theme) => theme.palette.primary.main }}
              />
            }
          >
            {cell.FieldLabel}
          </Button>
        </React.Fragment>
      );
    } else if (cell.ElementType === "T") {
      return <Typography>{cell.FieldLabel}</Typography>;
    } else if (cell.ElementType === "TB") {
      return (
        <TextField
          key={this.state.prevRFQLoading}
          className="local_template_textfield"
          fullWidth
          variant="outlined"
          label={cell.FieldLabel}
          type="text"
          error={error}
          helperText={error && ErrorMessage}
          name={name}
          // onBlur={(e) => {
          //   this.handleIntlChange(e, cell.FieldId);
          //   this.validateInputINTL(cell.FieldId);
          // }}
          // InputLabelProps={{ shrink: value?.length > 0 || undefined }}

          onBlur={(e) => {
            // this.handleLocalChange(e, cell.FieldId);
            this.validateInputINTL(cell.FieldId);
          }}
          onChange={(e)=>{
            this.handleIntlChange(e, cell.FieldId);
            // this.validateInputLocal(cell.FieldId);
          }}
          value={value}
        />
      );
    } else if (cell.ElementType === "TA") {
      return (
        <TextField
          key={this.state.prevRFQLoading}
          className="local_template_textarea international_textarea"
          fullWidth
          multiline
          variant="outlined"
          maxRows={3}
          label={cell.FieldLabel}
          name={name}
          error={error}
          helperText={error && ErrorMessage}
          // onBlur={(e) => {
          //   this.handleIntlChange(e, cell.FieldId);
          //   this.validateInputINTL(cell.FieldId);
          // }}
          // InputLabelProps={{ shrink: value?.length > 0 || undefined }}

          onBlur={(e) => {
            
            this.validateInputINTL(cell.FieldId);
          }}
          onChange={(e)=>{
            this.handleIntlChange(e, cell.FieldId);
          }}
          value={value}
        />
      );
    } else if (cell.ElementType === "M") {
      return (
        <Checkbox
          defaultChecked
          color="primary"
          checked={!!value}
          name={name}
          onChange={(e) => {
            this.handleIntlChange(e, cell.FieldId);
          }}
        />
      );
    } else if (cell.ElementType === "DT") {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            format="dd/MM/yyyy"
            slotProps={{
              textField: {
                size: "fullWidth",
                error: error,
                helperText: error && (ErrorMessage || "Invalid Date"),
              },
            }}
            ampm={false}
            label={cell.FieldLabel}
            name={name}
            value={value ? new Date(value) : null}
            onChange={(newVal) => {
              this.handleDateChange(
                moment(newVal),
                cell.FieldId,
                "IntlTemplate"
              );
            }}
          />
        </LocalizationProvider>
      );
    } else if (cell.ElementType === "F") {
      return (
        <FormControl error={error} sx={{ width: "100%" }}>
          <Box
            sx={{
              border: "solid 1px",
              borderColor: (theme) => theme.palette.bluegrey[500],
              borderRadius: "4px",
              minWidth: "100%",
              height: "56px",
              display: "flex",
              alignItems: "center",
              padding: "0 10px",
              position: "relative",
            }}
          >
            <InputLabel
              sx={{
                color: "text.secondary",
                backgroundColor: (theme) => theme.palette.grey[0],
                position: "absolute",
                left: 0,
                top: 0,
                paddingLeft: "5px",
                paddingRight: "5px",
                transformOrigin: "top left",
                webkitTransform: " translate(14px, -9px) scale(0.75)",
                transform: "translate(14px, -9px) scale(0.75)",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "calc(133% - 24px)",
                zIndex: 1,
              }}
            >
              {cell.FieldLabel}
            </InputLabel>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                this.setState({
                  currentFieldId: cell.FieldId,
                  dynamicFileUploadOpen: true,
                  currentTemplate: "IntlTemplate",
                });
              }}
            >
              <UploadIcon sx={{ marginRight: "10px" }} />
              Attachment
            </Button>
            <FormHelperText sx={{ marginLeft: "auto" }}>
              Number Of Files {value?.length}
            </FormHelperText>
          </Box>
          {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
        </FormControl>
      );
    } else if (cell.ElementType === "S") {
      return (
        <FormControl fullWidth variant="outlined" error={error}>
          <InputLabel>{cell.FieldLabel}</InputLabel>
          <Tooltip title={value} disableInteractive placement="top-start">
            <Select
              fullWidth
              // title={value}
              className="template_dropdown"
              label={cell.FieldLabel}
              name={name}
              value={value || ""}
              onChange={(e) => {
                this.handleIntlChange(e, cell.FieldId);
              }}
              onBlur={(e) => {
                this.validateInputINTL(cell.FieldId);
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 250,
                    width: 250,
                  },
                },
              }}
            >
              <MenuItem value={""}>Select</MenuItem>
              {this.state.IntlTemplate[cell.FieldId] &&
                this.state.IntlTemplate[cell.FieldId]["options"] &&
                (this.state.IntlTemplate[cell.FieldId]["options"].length > 0 ? (
                  this.state.IntlTemplate[cell.FieldId]["options"] ===
                  "loading" ? (
                    <MenuItem value="">Loading...</MenuItem>
                  ) : (
                    this.state.IntlTemplate[cell.FieldId]["options"].map(
                      (option, id) => {
                        return (
                          <MenuItem
                            key={id}
                            value={
                              option.dropdown_element_name
                                ? this.props.companyInfo
                                    ?.default_dropdown_type === "10" || cell.Identifier === "Destination" || cell.Identifier === "DestinationForeign"
                                  ? `${option.dropdown_element_name}-${option.dropdown_element_code}`
                                  : `${option.dropdown_element_code}-${option.dropdown_element_name}`
                                : option.code
                            }
                          >
                            <Typography style={{ whiteSpace: "pre-line" }}>
                              {option.dropdown_element_name
                                ? option.dropdown_element_code ===
                                  option.dropdown_element_name
                                  ? option.dropdown_element_name
                                  : `${option.dropdown_element_code}-${option.dropdown_element_name}`
                                : option.name}
                            </Typography>
                          </MenuItem>
                        );
                      }
                    )
                  )
                ) : (
                  <MenuItem value="">Not found</MenuItem>
                ))}
            </Select>
          </Tooltip>
          {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
        </FormControl>
      );
    } else {
      return cell.FieldLabel;
    }
  };

  validateInputINTL = (FieldId, submitting) => {
    if (submitting) {
      if (this.state.IntlTemplate[FieldId]["error"] &&  !this.finddependacy(
                FieldId,
                this.state.IntlTemplate[FieldId][
                  "Validation"
          ])){
          if (
          !this.state.errorArray.includes(
            this.state.IntlTemplate[FieldId]["HTMLAttributeName"]
          )
        ) {
          this.state.errorArray.push(
            this.state.IntlTemplate[FieldId]["HTMLAttributeName"]
          );
        }
        return;
      }
    }
    if (this.state.IntlTemplate[FieldId]["Validation"]) {
      let temp = this.state.IntlTemplate[FieldId]["Validation"].replace(
        /f(\d)+/g,
        (match) => {
          if (this.state.IntlTemplate[match.replace("f", "")]) {
            return `this.state.IntlTemplate[${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else if (this.state.localTemplate[match.replace("f", "")]) {
            return `this.state.localTemplate[${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else {
            return null;
          }
        }
      );
      console.log("validations",temp, this.state.IntlTemplate[FieldId]["FieldLabel"], eval(temp));
      
      if (temp !== null) {
        if (eval(temp)) {
          this.setState((prevState) => ({
            ...prevState,
            IntlTemplate: {
              ...prevState.IntlTemplate,
              [FieldId]: {
                ...prevState.IntlTemplate[FieldId],
                error: false,
              },
            },
          }));
          let index1 = this.state.errorArray.indexOf(
            this.state.IntlTemplate[FieldId]["HTMLAttributeName"]
          );
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }
        } else {
          this.setState((prevState) => ({
            ...prevState,
            IntlTemplate: {
              ...prevState.IntlTemplate,
              [FieldId]: {
                ...prevState.IntlTemplate[FieldId],
                error: true,
              },
            },
          }));
          if (
            !this.state.errorArray.includes(
              this.state.IntlTemplate[FieldId]["HTMLAttributeName"]
            )
          ) {
            this.state.errorArray.push(
              this.state.IntlTemplate[FieldId]["HTMLAttributeName"]
            );
          }
        }
        this.setState({
          errorArray: this.state.errorArray,
        });
      } else {
        console.log("dependent field not found in template");
      }
    }
  };

  validateInputLocal = (FieldId, submitting) => {
    if (submitting) {
      if (this.state.localTemplate[FieldId]["error"] &&  !this.finddependacy(
            FieldId,
            this.state.localTemplate[FieldId][
            "Validation" ])) {
        if (
          !this.state.errorArray.includes(
            this.state.localTemplate[FieldId]["HTMLAttributeName"]
          )
        ) {
          this.state.errorArray.push(
            this.state.localTemplate[FieldId]["HTMLAttributeName"]
          );
        }
        return;
      }
    }
    if (this.state.localTemplate[FieldId]["Validation"]) {
      let temp = this.state.localTemplate[FieldId]["Validation"].replace(
        /f(\d)+/g,
        (match) => {
          if (this.state.localTemplate[match.replace("f", "")]) {
            return `this.state.localTemplate[${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else if (this.state.IntlTemplate[match.replace("f", "")]) {
            return `this.state.IntlTemplate[${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else {
            return null;
          }
        }
      );
      if (temp !== null) {
        if (eval(temp)) {
          this.setState((prevState) => ({
            ...prevState,
            localTemplate: {
              ...prevState.localTemplate,
              [FieldId]: {
                ...prevState.localTemplate[FieldId],
                error: false,
              },
            },
          }));
          let index1 = this.state.errorArray.indexOf(
            this.state.localTemplate[FieldId]["HTMLAttributeName"]
          );
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }
        } else {
          this.setState((prevState) => ({
            ...prevState,
            localTemplate: {
              ...prevState.localTemplate,
              [FieldId]: {
                ...prevState.localTemplate[FieldId],
                error: true,
              },
            },
          }));
          if (
            !this.state.errorArray.includes(
              this.state.localTemplate[FieldId]["HTMLAttributeName"]
            )
          ) {
            this.state.errorArray.push(
              this.state.localTemplate[FieldId]["HTMLAttributeName"]
            );
          }
        }
        this.setState({
          errorArray: this.state.errorArray,
        });
      } else {
        console.log("dependent field not found in template");
      }
    }
  };
  initializeRFQDynamicState = () => {
    let row = {};
    let cellsToUpdate = [];
    this.props.jsonTemplate?.COMMON?.forEach((cell) => {
      row[cell.FieldId] = {
        HTMLAttributeName: cell.HTMLAttributeName,
        value: "",
        Validation: cell.Validation,
        FieldLabel: cell.FieldLabel,
        Identifier: cell.Identifier,
        error: false,
        ErrorMessage: cell.ErrorMessage,
        ElementType: cell.ElementType,
        InstructionText: cell.InstructionText,
      };
      if (cell.ElementType === "S") {
        if (cell.DropdownEdit === "Y") {
          row[cell.FieldId]["options"] = "loading";
          cellsToUpdate.push(cell);
          let optionsData = this.getOptionsFromRedux(cell, "rfq");
          //getting options data from redux if it is not available then calling api
          if (!optionsData) {
            this.getOptionsFromApi(cell, "rfq");
          }
          // this.getOptionsFromApi(cell, "rfq");
        } else {
          row[cell.FieldId]["options"] = cell.DefaultValue;
        }
      } else {
        row[cell.FieldId]["value"] = cell.DefaultValue || "";
      }
      if (cell.ElementType === "T") {
        row[cell.FieldId]["value"] = cell.DefaultValue;
      }

      let readOnly = false;
      // let value = row[cell.FieldId]["value"];

      if (
        Object.keys(this.props.prlineitems).length &&
        prconfig &&
        prconfig[cell.Identifier] !== undefined
      ) {
        row[cell.FieldId]["value"] =
          eval(this[prconfig[cell.Identifier]?.function_name])(
            cell.Identifier,
            this.props.selectedItems[0]
          ) || "";
        if (cell.ElementType === "S" && cell.DropdownEdit !== "Y") {
          Object.keys(row[cell.FieldId]["options"]).map((key) => {
            const plant_arr =
              row[cell.FieldId]["options"][key]["code"].split("-");
            if (plant_arr[plant_arr.length - 1] == row[cell.FieldId]["value"]) {
              row[cell.FieldId]["value"] =
                row[cell.FieldId]["options"][key]["code"];
            }
          });
        }
        if (
          prconfig[cell.Identifier]?.non_editable_function &&
          prconfig[cell.Identifier]?.non_editable_function !== "0"
        ) {
          if (prconfig[cell.Identifier]?.non_editable_function == "1") {
            readOnly = true;
          } else {
            readOnly = eval(
              this[prconfig[cell.Identifier]?.non_editable_function]
            )(row[cell.FieldId]["value"]);
          }
        }
      }
      row[cell.FieldId]["readOnly"] = readOnly;
      row[cell.FieldId]["DefaultValue"] = cell['DefaultValue'];
    });
    
    this.setState(
      {
        RFQDynamic: row,
      },
      () => {
        //getting options data from redux if it is available setting rfq data
        cellsToUpdate.map((cell) => {
          let optionsData = this.getOptionsFromRedux(cell, "rfq");
          if (cell.ElementType === "S") {
            if (cell.DropdownEdit === "Y") {
              if (optionsData) {
                let value = this.state.RFQDynamic[cell.FieldId]["value"];
                if (value) {
                  if (optionsData) {
                    optionsData.forEach((option, id) => {
                      if (option.dropdown_element_code === value) {
                        value = `${option.dropdown_element_code}-${option.dropdown_element_name}`;
                        if (
                          this.props.companyInfo?.default_dropdown_type === "10" || cell.Identifier === "Destination" || cell.Identifier === "DestinationForeign"
                        ) {
                          value = `${option.dropdown_element_name}-${option.dropdown_element_code}`;
                        }
                      }
                    });
                    this.setState((prevState) => ({
                      ...prevState,
                      RFQDynamic: {
                        ...prevState.RFQDynamic,
                        [cell.FieldId]: {
                          ...prevState.RFQDynamic[cell.FieldId],
                          options: optionsData,
                          value,
                        },
                      },
                    }));
                  }
                } else {
                  this.setState((prevState) => ({
                    ...prevState,
                    RFQDynamic: {
                      ...prevState.RFQDynamic,
                      [cell.FieldId]: {
                        ...prevState.RFQDynamic[cell.FieldId],
                        options: optionsData,
                      },
                    },
                  }));
                }
              }
            }
          }
        });
      }
    );
  };

  editInitializeRFQDynamicState = () => {
    let row = {};
    let cellsToUpdate = [];
    this.props.jsonTemplate?.COMMON?.forEach((cell) => {
      
      row[cell.FieldId] = {
        HTMLAttributeName: cell.HTMLAttributeName,
        value: "",
        Validation: cell.Validation,
        FieldLabel: cell.FieldLabel,
        Identifier: cell.Identifier,
        error: false,
        ErrorMessage: cell.ErrorMessage,
        ElementType: cell.ElementType,
        InstructionText: cell.InstructionText,
      };
      if (cell.ElementType === "S") {
        if (cell.DropdownEdit === "Y") {
          row[cell.FieldId]["options"] = "loading";
          cellsToUpdate.push(cell);
          let optionsData = this.getOptionsFromRedux(cell, "rfq");
          //getting options data from redux if it is not available then calling api
          if (!optionsData) {
            this.getOptionsFromApi(cell, "rfq");
          }
          // this.getOptionsFromApi(cell, "rfq");
        } else {
          row[cell.FieldId]["options"] = cell.DefaultValue;
        }
      } 
      // else {
      //   row[cell.FieldId]["value"] = cell.DefaultValue || "";
      // }
      // if (cell.ElementType === "T") {
      //   row[cell.FieldId]["value"] = cell.DefaultValue;
      // }

      let readOnly = false;
      // let value = row[cell.FieldId]["value"];

      // if (
      //   Object.keys(this.props.prlineitems).length &&
      //   prconfig &&
      //   prconfig[cell.Identifier] !== undefined
      // ) {
      //   row[cell.FieldId]["value"] =
      //     eval(this[prconfig[cell.Identifier]?.function_name])(
      //       cell.Identifier,
      //       this.props.selectedItems[0]
      //     ) || "";
      //   if (cell.ElementType === "S" && cell.DropdownEdit !== "Y") {
      //     Object.keys(row[cell.FieldId]["options"]).map((key) => {
      //       const plant_arr =
      //         row[cell.FieldId]["options"][key]["code"].split("-");
      //       if (plant_arr[plant_arr.length - 1] == row[cell.FieldId]["value"]) {
      //         row[cell.FieldId]["value"] =
      //           row[cell.FieldId]["options"][key]["code"];
      //       }
      //     });
      //   }
      //   if (
      //     prconfig[cell.Identifier]?.non_editable_function &&
      //     prconfig[cell.Identifier]?.non_editable_function !== "0"
      //   ) {
      //     if (prconfig[cell.Identifier]?.non_editable_function == "1") {
      //       readOnly = true;
      //     } else {
      //       readOnly = eval(
      //         this[prconfig[cell.Identifier]?.non_editable_function]
      //       )(row[cell.FieldId]["value"]);
      //     }
      //   }
      // }
      row[cell.FieldId]["readOnly"] = readOnly;
      row[cell.FieldId]["DefaultValue"] = cell['DefaultValue'];

      let commonData = this.props.taskData?.action_document_data?.template?.data?.COMMON;
      if (commonData.hasOwnProperty(cell.Identifier)) {
        row[cell.FieldId]["value"] = commonData[cell.Identifier];
      }
    });
    
    this.setState(
      {
        RFQDynamic: row,
      },
      () => {
        //getting options data from redux if it is available setting rfq data
        cellsToUpdate.map((cell) => {
          let optionsData = this.getOptionsFromRedux(cell, "rfq");
          if (cell.ElementType === "S") {
            if (cell.DropdownEdit === "Y") {
              if (optionsData) {
                let value = this.state.RFQDynamic[cell.FieldId]["value"];
                if (value) {
                  if (optionsData) {
                    optionsData.forEach((option, id) => {
                      if (option.dropdown_element_code === value) {
                        value = `${option.dropdown_element_code}-${option.dropdown_element_name}`;
                        if (
                          this.props.companyInfo?.default_dropdown_type === "10" || cell.Identifier === "Destination" || cell.Identifier === "DestinationForeign"
                        ) {
                          value = `${option.dropdown_element_name}-${option.dropdown_element_code}`;
                        }
                      }
                    });
                    this.setState((prevState) => ({
                      ...prevState,
                      RFQDynamic: {
                        ...prevState.RFQDynamic,
                        [cell.FieldId]: {
                          ...prevState.RFQDynamic[cell.FieldId],
                          options: optionsData,
                          value,
                        },
                      },
                    }));
                  }
                } else {
                  this.setState((prevState) => ({
                    ...prevState,
                    RFQDynamic: {
                      ...prevState.RFQDynamic,
                      [cell.FieldId]: {
                        ...prevState.RFQDynamic[cell.FieldId],
                        options: optionsData,
                      },
                    },
                  }));
                }
              }
            }
          }
        });
      }
    );
  };

  validateInput = (FieldId, index, submitting) => {
    if (submitting) {
      if (
        this.state.mainTemplate[1][FieldId] &&
        this.state.mainTemplate[1][FieldId][
          "error"
        ] &&
        this.state.mainTemplate[1][FieldId][
          "Validation"
        ] &&
        !this.finddependacy(
          FieldId,
          this.state.mainTemplate[1][FieldId][
            "Validation"
          ]
        )
      ) {
        if (
          !this.state.errorArray.includes(
            this.state.mainTemplate[1][
              FieldId
            ]["HTMLAttributeName"]
          )
        ) {
          this.state.errorArray.push(
            this.state.mainTemplate[1][
              FieldId
            ]["HTMLAttributeName"]
          );
          this.setState({
            errorArray: this.state.errorArray,
          });
        }
        return;
      }
    }
    if (
      this.state.mainTemplate[1][FieldId] &&
      this.state.mainTemplate[1][FieldId][
        "Validation"
      ]
    ) {
      let temp = this.state.mainTemplate[1][
        FieldId
      ]["Validation"].replace(/f(\d)+/g, (match) => {
        if (
          this.state.mainTemplate[1][
            match.replace("f", "")
          ]
        ) {
          return `this.state.mainTemplate[${
            1
          }][${match.replace("f", "")}]["value"]`;
        } else if (this.state.localTemplate[match.replace("f", "")]) {
          return `this.state.localTemplate[${match.replace("f", "")}]["value"]`;
        } else if (this.state.IntlTemplate[match.replace("f", "")]) {
          return `this.state.IntlTemplate[${match.replace("f", "")}]["value"]`;
        } else {
          return null;
        }
      });
      console.log('temp',temp);
      if (temp !== null) {
        if (eval(temp)) {
          // this.state.mainTemplate[1][FieldId][
          //   "error"
          // ] = false;

          this.setState((prevState) => ({
            ...prevState,
            mainTemplate: {
              ...prevState.mainTemplate,
              [1]: {
                ...prevState.mainTemplate[1],
                [FieldId]: {
                  ...prevState.mainTemplate[
                    1
                  ][FieldId],
                  error: false,
                },
              },
            },
          }));

          let index1 = this.state.errorArray.indexOf(
            this.state.mainTemplate[1][
              FieldId
            ]["HTMLAttributeName"]
          );
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }
        } else {
          // this.state.mainTemplate[1][FieldId][
          //   "error"
          // ] = true;

          this.setState((prevState) => ({
            ...prevState,
            mainTemplate: {
              ...prevState.mainTemplate,
              [1]: {
                ...prevState.mainTemplate[1],
                [FieldId]: {
                  ...prevState.mainTemplate[
                    1
                  ][FieldId],
                  error: true,
                },
              },
            },
          }));

          if (
            !this.state.errorArray.includes(
              this.state.mainTemplate[1][
                FieldId
              ]["HTMLAttributeName"]
            )
          ) {
            this.state.errorArray.push(
              this.state.mainTemplate[1][
                FieldId
              ]["HTMLAttributeName"]
            );
          }
        }
        this.setState({
          // mainTemplate: this.state.mainTemplate,
          errorArray: this.state.errorArray,
        });
      } else {
        console.log("dependent field not found in template");
      }
    }
  };

  validateInputRFQStatic = (FieldId) => {
    if (FieldId === "RFQValidity") {
      if (this.state.RFQStatic.RFQValidity.value === "") {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            RFQValidity: {
              ...prevState.RFQStatic.RFQValidity,
              error: true,
            },
          },
        }));
        if (
          !this.state.errorArray.includes(
            this.state.RFQStatic.RFQValidity["HTMLAttributeName"]
          )
        ) {
          this.state.errorArray.push(
            this.state.RFQStatic.RFQValidity["HTMLAttributeName"]
          );
        }
      } else {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            RFQValidity: {
              ...prevState.RFQStatic.RFQValidity,
              error: false,
            },
          },
        }));
        let index1 = this.state.errorArray.indexOf(
          this.state.RFQStatic.RFQValidity["HTMLAttributeName"]
        );
        if (index1 !== -1) {
          this.state.errorArray.splice(index1, 1);
        }
      }
    }
    if (FieldId === "TBValidity") {
      if (this.state.RFQStatic.TBValidity.value === "") {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            TBValidity: {
              ...prevState.RFQStatic.TBValidity,
              error: true,
            },
          },
        }));
        if (
          !this.state.errorArray.includes(
            this.state.RFQStatic.TBValidity["HTMLAttributeName"]
          )
        ) {
          this.state.errorArray.push(
            this.state.RFQStatic.TBValidity["HTMLAttributeName"]
          );
        }
      } else {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            TBValidity: {
              ...prevState.RFQStatic.TBValidity,
              error: false,
            },
          },
        }));
        let index1 = this.state.errorArray.indexOf(
          this.state.RFQStatic.TBValidity["HTMLAttributeName"]
        );
        if (index1 !== -1) {
          this.state.errorArray.splice(index1, 1);
        }
      }
    }
    this.setState({
      errorArray: this.state.errorArray,
    });
  };

  handleLocalChange = (e, FieldId) => {
    const { value, type, checked } = e.target;

    const { localTemplate } = this.state;
    localTemplate[FieldId]["value"] = type !== "checkbox" ? value : checked;
    // e.target.blur();
    this.setState({
      localTemplate,
    });
  };

  setTermValue = (value, vendorType, FieldId) => {
    const { localTemplate, IntlTemplate } = this.state;
    if (vendorType === "local") {
      localTemplate[FieldId]["value"] = value;
      this.setState({
        localTemplate,
      });
    } else {
      IntlTemplate[FieldId]["value"] = value;
      this.setState({
        IntlTemplate,
      });
    }
  };

  setTermError = () =>
    this.state.vendorTerms === "inco"
      ? this.setState({ incoTermError: true })
      : this.setState({ paymentTermError: true });

  setAttachmentsValue = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      [this.state.currentTemplate]: {
        ...prevState[this.state.currentTemplate],
        [this.state.currentFieldId]: {
          ...prevState[this.state.currentTemplate][this.state.currentFieldId],
          value: this.state[this.state.currentTemplate][
            this.state.currentFieldId
          ]["value"]
            ? [
                ...this.state[this.state.currentTemplate][
                  this.state.currentFieldId
                ]["value"],
                ...value,
              ]
            : value,
        },
      },
    }));
  };

  handleIntlChange = (e, FieldId) => {
    const { value, type, checked } = e.target;
    const { IntlTemplate } = this.state;
    IntlTemplate[FieldId]["value"] = type !== "checkbox" ? value : checked;
    // e.target.blur();
    
    this.setState({
      IntlTemplate,
    });
  };
  //added val for autocomplete multiplant
  handleRFQDynamicChange = (e, FieldId, val) => {
    const { RFQDynamic } = this.state;
    if (val) {
      RFQDynamic[FieldId]["value"] = val;
    } else {
      const { value, type, checked } = e.target;
      RFQDynamic[FieldId]["value"] = type !== "checkbox" ? value : checked;
    }
    if( RFQDynamic[FieldId]['Identifier'] === "LDDApplicable" ){
      for (const common_field_id in RFQDynamic) {
        if (RFQDynamic[common_field_id]['Identifier'] === "LateDeliveryC") {
          RFQDynamic[common_field_id]["value"] = RFQDynamic[FieldId]["value"] == "YES-Y" ? RFQDynamic[common_field_id]['DefaultValue'] : "";
          //RFQDynamic[common_field_id]["value"] = RFQDynamic[FieldId]["value"] == "NO-N" ? "" : RFQDynamic[common_field_id]['DefaultValue'];
          break;
        }
      }
    }
    // e.target.blur();
    this.setState(
      {
        RFQDynamic,
      },
      () => {
        this.validateInputRFQ(FieldId);
      }
    );
  };

  handleRFQStaticChange = (e) => {
    const { name, value, type, checked } = e.target;

    const { RFQStatic } = this.state;
    RFQStatic[name]["value"] = type !== "checkbox" ? value : checked;
    // e.target.blur();
    this.setState({
      RFQStatic,
    });
    if (name === "RFQCCUsersmails") {
      let flag = false;
      if (value?.trim() === "") {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            RFQUpdateCCUsersCheck: {
              ...prevState.RFQStatic.RFQUpdateCCUsersCheck,
              value: false,
            },
          },
        }));
      } else {
        value
          ?.trim()
          .split(",")
          .forEach((email) => {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
              flag = true;
            }
          });
      }

      this.setState((prevState) => ({
        ...prevState,
        RFQStatic: {
          ...prevState.RFQStatic,
          RFQCCUsersmails: {
            ...prevState.RFQStatic.RFQCCUsersmails,
            error: flag,
          },
        },
      }));

      if (flag) {
        if (!this.state.errorArray.includes("RFQCCUsersmails")) {
          this.state.errorArray.push("RFQCCUsersmails");
        }
      } else {
        let index1 = this.state.errorArray.indexOf("RFQCCUsersmails");
        if (index1 !== -1) {
          this.state.errorArray.splice(index1, 1);
        }
      }
      this.setState({
        errorArray: this.state.errorArray,
      });
    }
  };
  
  handleLocalVendorRecipintsOpen = () => {
    this.setState({
      vendorRecipintType: "local",
      vendorRecipintOpen: true,
    });
  };

  handleIntlVendorRecipintsOpen = () => {
    this.setState({
      vendorRecipintType: "foreign",
      vendorRecipintOpen: true,
    });
  };

  handleVendorRecipintsClose = () => {
    this.setState({
      vendorRecipintOpen: false,
    });
  };

  handleLocalVendorTermsOpen = (term, currentFieldID) => {
    this.setState({
      vendorType: "local",
      vendorTerms: term,
      vendorTermsOpen: true,
      currentFieldID,
    });
  };

  handleIntlVendorTermsOpen = (term, currentFieldID) => {
    this.setState({
      vendorType: "foreign",
      vendorTerms: term,
      vendorTermsOpen: true,
      currentFieldID,
    });
  };

  handleVendorTermsClose = () => {
    this.setState({
      vendorTermsOpen: false,
      currentFieldID: "",
    });
  };

  allItemsHasVendors = () => {
    let result = true;
    this.props.selectedItems.forEach((item) => {
      if (!item.vendor_count?.total_count) {
        result = false;
      }
    });
    return result;
  };

  
  handleRFQSubmit = (event) => {
    if (this.props.location?.pathname.indexOf("/prcombination") > -1) {
      event.preventDefault();
    }
    this.setState({
      rfqSubmitLoading: true,
    });
    
    const [firstKey] = Object.keys(this.state.mainTemplate); 

    for (const filedId in this.state.mainTemplate[firstKey]) {
      const identifier = this.state.mainTemplate[firstKey][filedId]['Identifier'];
      
      if( 
        !(identifier == "Product" ||
        identifier == "ProductCode" ||
        identifier == "ProductForeign" || 
        identifier == "HSNCode" ||
        identifier == "HSNCodeFor") 
      ){
        this.validateInput(filedId, 0, true);
      }
    }
    

    // if category RFQ then validate based on the vendors selections only
    let localIncoFields = [];
    let localPaymentFields = [];

    let intlIncoFields = [];
    let intlPaymentFields = [];

    if (this.props.master_type === "category") {
      // if (this.handleVendorType("local")) {
        for (const key in this.state.localTemplate) {
          // if it is link then skip
          let result = this.checkIsLinkLocal(this.state.localTemplate[key]);
          if (!result) {
            this.validateInputLocal(key, true);
          } else if (result === "inco") {
            localIncoFields.push(key);
          } else if (result === "payment") {
            localPaymentFields.push(key);
          }
        }
      // }

      // if (this.handleVendorType()) {
        for (const key in this.state.IntlTemplate) {
          let result = this.checkIsLinkLocal(this.state.IntlTemplate[key]);
          if (!result) {
            this.validateInputINTL(key, true);
          } else if (result === "inco") {
            intlIncoFields.push(key);
          } else if (result === "payment") {
            intlPaymentFields.push(key);
          }
        }
      // }
    } else {
      // if (this.vendorTypeExist("local_count")) {
        for (const key in this.state.localTemplate) {
          let result = this.checkIsLinkLocal(this.state.localTemplate[key]);
          // if it is link then skip
          if (!result) {
            this.validateInputLocal(key, true);
          } else if (result === "inco") {
            localIncoFields.push(key);
          } else if (result === "payment") {
            localPaymentFields.push(key);
          }
        }
      // }
      // if (this.vendorTypeExist("internation_count")) {
        for (const key in this.state.IntlTemplate) {
          let result = this.checkIsLinkLocal(this.state.IntlTemplate[key]);
          if (!result) {
            this.validateInputINTL(key, true);
          } else if (result === "inco") {
            intlIncoFields.push(key);
          } else if (result === "payment") {
            intlPaymentFields.push(key);
          }
        }
      // }
    }

    for (const key in this.state.RFQDynamic) {
      this.validateInputRFQ(key, true);
    }

    if(!this.props.taskData?.action_document_data?.inquiry_settings?.validity_days){
      this.setState({
        rfqSubmitLoading: false,
        intervalDayError : true
      });
      this.props.openSnackbar(
        "error",
        "There are errors in your form. Please resolve in order to continue"
      );
      return false;
    }

    if(this.props.featurePermission?.max_rfq_validity_days?.feature_value == "Y" && 
      parseInt(this.props.taskData?.action_document_data?.inquiry_settings?.validity_days) > parseInt(this.props.featurePermission?.max_rfq_validity_days?.feature_permission_value)){

        this.setState({
          rfqSubmitLoading: false,
        });

        this.props.openSnackbar(
          "error",
          "RFQ Validity Days should be less than or equal to Maximum RFQ Validity Days."
        );
        return false;
    }
    
    if (this.state.errorArray.length ) {
      console.log('array',this.state.errorArray)
      this.setState({
        rfqSubmitLoading: false,
      });
      this.props.openSnackbar(
        "error",
        "There are errors in your form. Please resolve in order to continue"
      );
      return false;
    }

    const { mainTemplate, RFQDynamic, localTemplate, IntlTemplate } = this.state;
    
    this.props.handleTaskDataChange(
      "action_document_data",
      {
        ...this.props.taskData.action_document_data,
        template: {
          ...this.props.taskData.action_document_data.template,
          id:this.props.jsonTemplate?.PARAM[0]?.template_id,
          batch_id:this.props.jsonTemplate?.PARAM[0]?.batch_id,
          data:{
            ...this.props.taskData.action_document_data.template.data,
            ITEMIZE : {
              ...this.props.taskData.action_document_data.template.data.ITEMIZE,
              ...Object.fromEntries(
                Object.values(mainTemplate[firstKey]).map(({ Identifier, value }) => [
                  Identifier,
                  value ?? "",
                ])
              ),
            },
            COMMON :{
              ...this.props.taskData.action_document_data.template.data.COMMON,
              ...Object.fromEntries(
              Object.values(RFQDynamic)
                .map(({ Identifier, value }) => [
                  Identifier, 
                  value ?? ""
                ])
              ),
            },
            LOCAL:{
              ...this.props.taskData.action_document_data.template.data.LOCAL,
              ...Object.fromEntries(
              Object.values(localTemplate)
                .map(({ Identifier, value }) => [
                  Identifier, 
                  value ?? ""
                ])
              ),
            },
            FOREIGN:{
              ...this.props.taskData.action_document_data.template.data.FOREIGN,
              ...Object.fromEntries(
              Object.values(IntlTemplate)
                .map(({ Identifier, value }) => [
                  Identifier, 
                  value ?? ""
                ])
              ),
            }
          }
        },
      }
    );

    this.setState({
      confirmDialogOpen: true,
      confirmAlertTitle: "Submission",
      confirmAlertMsg: "Are you sure want to submit this RFQ?",
    });
  };
  
  rfqsubmitAPI = async () => {
    const { editTask, taskData, userInfo, setJsonTemplateloading, openSnackbar, setIsCreateRFQActive, setActiveStep, sessionId } = this.props;
    setJsonTemplateloading(true);
  
    const isEdit = Boolean(editTask);
    const url = isEdit ? autoRFQTaskById(taskData?.id) : postAutoRFQTask;
    const method = isEdit ? "patch" : "post";
    const requestData = isEdit ? { ...taskData, updated_by: userInfo.user_id } : taskData;
  
    try {
      const { data } = await axios({
        method,
        url,
        data: requestData,
        headers: { 
          "Content-Type"  : "application/json",
          "x-public-key"  : sessionId,
          "x-private-key" : "task_service_key"
        }
      });
  
      openSnackbar("success", isEdit ? "Updated Successfully" : "Submitted Successfully");
      setIsCreateRFQActive(false);
      setActiveStep(0);
      return data;
    } catch (error) {
      console.error("Error while processing RFQ:", error.response?.data || error.message);
  
      if (error.response?.status === 409) {
        openSnackbar("error", error.response.data.message);
      } else if (error.response?.status === 422) {
        openSnackbar("error", error.response.data.message);
      } 
      else {
        openSnackbar("error", error.message);
      }
      return null;
    } finally {
      setJsonTemplateloading(false);
    }
  };
  

  findFormulaValues = (element, itemize) => {
    let hiddenLabelValue = 0;
    let itemFormula = element["value"].Formula;
    for (const key in itemize) {
      let object = itemize[key];
      for (const key1 in object) {
        if (itemFormula.includes(key1)) {
          hiddenLabelValue = hiddenLabelValue + Number(object[key1].value);
        }
      }
    }
    return hiddenLabelValue;
  };

  handleUplaod = (index, res) => {
    const { mainTemplate } = this.state;
    mainTemplate[1]["specification"] = res;
    this.setState({
      mainTemplate,
    });
  };

  handleRFQSubmitDialogOpen = (msg) => {
    this.setState({
      RFQSubmitDialogOpen: true,
      rfqSubmitMsg: msg,
    });
  };

  RFQSubmitDialogOnClose = () => {
    if (this.props.location?.pathname.indexOf("/prcombination") === -1) {
      this.props.resetSettingDrawer();
      this.props.resetReminderSettings();
      this.props.resetVendorLocationFlag();
      this.setState({
        RFQSubmitDialogOpen: false,
      });
      this.props.handleTBAction(
        "vendxRating",
        null, // itemId
        null, // oldItemId
        0,
        0
      );
    } else {
      this.setState({
        RFQSubmitDialogOpen: false,
      });
      this.props.setPRselectedChange();
    }
  };

  handleVendorType = (type) => {
    return type == "local"
      ? this.props.selectedVendors?.some(
          (e) =>
          (e.vendor_location?.toUpperCase() === "L" )
          //||
          //   e.country?.toUpperCase() ===
          //   this.props.accountlevelInfo?.country?.toUpperCase())
        )
       :this.props.selectedVendors?.some(
          (e) =>
          (e.vendor_location?.toUpperCase() === "I") 
          // ||  e.country?.toUpperCase() !==
            //this.props.accountlevelInfo?.country?.toUpperCase()
        );
  };

  vendorTypeExist = (type) => {
    // internation_count
    // local_count
    let result = false;
    this.props.selectedItems.forEach((item) => {
      if (item?.vendor_count?.[type]) {
        result = true;
      }
    });
    return result;
  };

  getDestinationValue = () => {
    for (const key in this.state.RFQDynamic) {
      const element = this.state.RFQDynamic[key];
      if (
        element.Identifier === "Destination" ||
        element.Identifier === "DestinationForeign"
      ) {
        return element.value;
      }
    }
  };

  handleUpdateInMasterClick = (item_id, FieldId) => {
    this.setState({
      mainTemplate: {
        ...this.state.mainTemplate,
        [item_id]: {
          ...this.state.mainTemplate[item_id],
          [FieldId]: {
            ...this.state.mainTemplate[item_id][FieldId],
            [`upd_budget_master_${item_id}`]:
              !this.state.mainTemplate[item_id][FieldId][
                `upd_budget_master_${item_id}`
              ],
          },
        },
      },
    });
  };

  updateDeliveryScheduleFieldValue = (value, item_id, FieldId) => {
    this.setState((prevState) => ({
      ...prevState,
      mainTemplate: {
        ...prevState.mainTemplate,
        [item_id]: {
          ...prevState.mainTemplate[item_id],
          [FieldId]: {
            ...prevState.mainTemplate[item_id][FieldId],
            value,
          },
        },
      },
    }));
  };

  render() {
    
    
    return (
      <React.Fragment>
        <Backdrop
          style={{ color: "#fff", zIndex: "10000000000" }}
          open={
            this.state.loaderArray.length > 0 ||
            this.state.prevRFQLoading ||
            this.props.jsonTemplateloading || 
            this.state.excelRFQLoading
          }
        >
          <CircularProgress style={{ color: "#fff" }} />
        </Backdrop>
        <React.Fragment>
          <form
            id="novalidatedform"
            noValidate
            autoComplete="off"
            onSubmit={(event) => {
              if (
                this.props.location?.pathname.indexOf("/prcombination") > -1
              ) {
                event.preventDefault();
              }
            }}>

              <ItemizeSectionTable 
                randomId={this.state.randomId}
                batch_id={this.props.jsonTemplate?.PARAM && this.props.jsonTemplate?.PARAM[0]?.batch_id}
                data={this.props.jsonTemplate?.ITEMIZE||[]}
                mainTemplate={this.state.mainTemplate}
                selectedItems={this.props.selectedItems}
                base_currency={this.props.accountlevelInfo?.base_currency}
                handleUplaod={this.handleUplaod}
                applyToAll={this.state.applyToAll}
                handleApplyToAllClick={this.handleApplyToAllClick}
                handleDateChange={this.handleDateChange}
                handleUpdateInMasterClick={this.handleUpdateInMasterClick}
                handleChange={this.handleChange}
                default_dropdown_type={this.props.companyInfo?.default_dropdown_type}
                destination={this.getDestinationValue()}
                updateDeliveryScheduleFieldValue={this.updateDeliveryScheduleFieldValue}
                itemSpecificFiles = {this.props.itemSpecificFiles}
                LATEST={this.LATEST}
                handleSlab={this.handleSlab}
                slab={this.state.slab}
                rfqSettingValues={this.props.rfqSettingValues}
                // slabRows={this.state.slabRows}
                slabAddRow={this.slabAddRow}
                slabRemoveRow={this.slabRemoveRow}
                handleRowInput={this.handleRowInput}
                handleApplyToAllSlab={this.handleApplyToAllSlab}
                handleKey={this.handleKey}
                slabKey = {this.state.slabKey}
                applyToAllSlab={this.state.applyToAllSlab}
                firstActiveSlab={this.props.selectedItems?.find((element) => {
                  if(this.state?.slab[element?.id] && this.state?.slab[element?.id].isOpen){
                   return element;
                 }
                })}
                displayOptions={this.props.displayOptions}
              />

            {/* {((this.props.master_type === "category" &&
              this.handleVendorType("local")) ||
              (this.props.master_type !== "category" &&
                this.vendorTypeExist("local_count"))) && ( */}
              <div className="template_local_recipients">
                <AutoRFQCardTemplate
                  title="LOCAL RECIPIENTS"
                  count={
                    this.props.selectedVendors?.filter(
                      (vendor) =>
                      vendor.vendor_location?.toUpperCase() === "L" //|| 
                        //vendor.country === this.props.accountlevelInfo?.country
                    ).length
                  }
                  handleOpen={this.handleLocalVendorRecipintsOpen}
                  state={this.state.localTemplate}
                  data={this.props.jsonTemplate?.LOCAL}
                  getField={this.getFieldLocal}
                  handleChange={this.handleLocalChange}
                  taskData={this.props.taskData}
                  handleTaskDataChange={this.props.handleTaskDataChange}
                  setErrorArray={(newErrors) => this.setState({ errorArray: newErrors })}
                  
                />
              </div>
            {/* )} */}

            {/* {((this.props.master_type === "category" &&
              this.handleVendorType()) ||
              (this.props.master_type !== "category" &&
                this.vendorTypeExist("internation_count"))) && ( */}
              <div className="template_international_recipients">
                <AutoRFQCardTemplate
                  title="INTERNATIONAL RECIPIENTS"
                  count={
                    this.props.selectedVendors?.filter(
                      (vendor) =>
                        vendor.vendor_location?.toUpperCase() === "I" //||
                        //vendor.country !== this.props.accountlevelInfo?.country
                    ).length
                  }
                  // handleOpen={this.handleIntlVendorRecipintsOpen}
                  state={this.state.IntlTemplate}
                  data={this.props.jsonTemplate?.FOREIGN}
                  getField={this.getFieldINTL}
                  handleChange={this.handleIntlChange}
                  taskData={this.props.taskData}
                  handleTaskDataChange={this.props.handleTaskDataChange}
                  
                />
              </div>
            {/* )} */}
            <div className="template_rfqstatic">
                <AutoRFQCardTemplate
                  title="Common fields"
                  // count={
                  //   this.props.selectedVendors?.filter(
                  //     (vendor) =>
                  //       vendor.vendor_location?.toUpperCase() === "I" //||
                  //       //vendor.country !== this.props.accountlevelInfo?.country
                  //   ).length
                  // }
                  validateInputRFQStatic={this.validateInputRFQStatic}
                  // handleOpen={this.handleIntlVendorRecipintsOpen}
                  state={this.state.RFQDynamic}
                  data={this.props.jsonTemplate?.COMMON}
                  getField={this.getFieldRFQ}
                  handleChange={this.handleRFQStaticChange}
                  handleTaskDataChange={this.props.handleTaskDataChange}
                  taskData={this.props.taskData}
                  intervalDayError={this.state.intervalDayError}
                  // handleRFQStaticChange={this.handleRFQStaticChange}
                  // handleDateChange={this.handleDateChange}
                  rfqExpiryIntervalDays={this.props.rfqExpiryIntervalDays}
                  setRfqExpiryIntervalDays={this.props.setRfqExpiryIntervalDays}

                  featurePermission={this.props.featurePermission}
                  // intervalDay={this.state.shipment_mark}
                  
                  staticFieldValidate = {(value) => {
                    if (!/^\d*$/.test(value)) {
                      return; // Stop execution if value is not a number
                    }
                                        this.setState({ intervalDayError: !value });
                    this.props.handleTaskDataChange(
                      "action_document_data.inquiry_settings.validity_days", value
                    );
                    this.props.setRfqExpiryIntervalDays(value);
                  }}
                  commonfields={true}
                />
              </div>
            {/* <div className="template_rfqstatic">
              <RFQStatic
                getField={this.getFieldRFQ}
                data={this.props.jsonTemplate?.COMMON}
                destination={this.getDestinationValue()}
                userId={this.props.userInfo.division_id}
                items={this.props.selectedItems}
                // RFQStatic={this.state.RFQStatic}
                // isLockVisible={
                //   this.props.accountModulePermission?.SealedRFQ
                //     ?.module_activated === "Y"
                // }
                // isContractRfqVisible={
                //   this.props.accountModulePermission["Contract Rfq"]
                //     ?.module_activated === "Y"
                // }
                // includeTB={this.props.includeTB}
                // isLockDisable={
                //   this.props.featurePermission?.sealed_rfq?.feature_value ===
                //     "Y" || this.props.defaultValues.sealedGroupRfq === true
                // }
                // isCCMailVisible={
                //   this.props.accountModulePermission["CC User Email"]
                //     ?.module_activated === "Y"
                // }
                // isPriorityDisable={
                //   this.props.defaultValues.priorityRfq === true
                // }
                // isRFQValidityDisable={
                //   window.location.pathname.indexOf("prcombination") !== -1
                // }
                // validateInputRFQStatic={this.validateInputRFQStatic}
                // setLock={() => {
                //   this.setState((prevState) => ({
                //     ...prevState,
                //     RFQStatic: {
                //       ...prevState.RFQStatic,
                //       lock: !this.state.RFQStatic.lock,
                //     },
                //   }));
                // }}
                // openSetting={(val) => {
                //   this.setState({
                //     reminderDrawerOpen: val,
                //   });
                // }}
                // setStar={() => {
                //   this.setState((prevState) => ({
                //     ...prevState,
                //     RFQStatic: {
                //       ...prevState.RFQStatic,
                //       star: !this.state.RFQStatic.star,
                //     },
                //   }));
                // }}
                handleRFQStaticChange={this.handleRFQStaticChange}
                handleDateChange={this.handleDateChange}
                handleRFQvalidtyTimeChange={(newVal) => {
                  this.setState((prevState) => {
                    return {
                      ...prevState,
                      RFQStatic: {
                        ...prevState.RFQStatic,
                        RFQValidityTime: {
                          ...prevState.RFQStatic.RFQValidityTime,
                          value: newVal,
                          error: false,
                        },
                      },
                    };
                  });
                }}
                // handleTBvalidtyTimeChange={(newVal) => {
                //   this.setState((prevState) => {
                //     return {
                //       ...prevState,
                //       RFQStatic: {
                //         ...prevState.RFQStatic,
                //         TBValidityTime: {
                //           ...prevState.RFQStatic.TBValidityTime,
                //           value: newVal,
                //           error: false,
                //         },
                //       },
                //     };
                //   });
                // }}
                // categorySpecificationFiles={this.props.categorySpecFiles}
                // categorySpecification={() => {
                //   this.setState({
                //     specificationsOpen: true,
                //   });
                // }}
                termsAndConditions={() => {
                  this.setState({
                    tandcDialogOpen: true,
                  });
                }}
                termsAndConditionsTotalFiles={
                  this.state.termsAndConditionsData?.t_and_c_files?.length
                }
              />
            </div> */}

            {/* <div style={{ margin: "2rem 0" }}>
              <Button
                color="secondary"
                startIcon={<NotificationAddIcon />}
                variant="contained"
                onClick={() => {
                  this.setState({
                    reminderDrawerOpen: true,
                  });
                }}
              >
                ADD REMINDERS
              </Button> */}
              {/* <Button
                color="secondary"
                startIcon={<NotificationAddIcon />}
                variant="contained"
                onClick={() => {
                  this.setState({
                    reminderDrawerOpen: true,
                  });
                }}
              >
                ADD REMINDERS
              </Button> */}

              {/* {this.state.startDate && (
                <Chip
                  variant="outlined"
                  icon={<AlarmIcon />}
                  label={
                    <Box>
                      <Typography
                        variant="caption"
                        display="block"
                        lineHeight={"normal"}
                      >
                        Quotation reminder
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        fontSize={"12px"}
                        display="block"
                        lineHeight={"normal"}
                      >
                        {moment(this.state.startDate).format("DD MMM YYYY")}
                      </Typography>
                    </Box>
                  }
                  onDelete={() => {
                    let newVal = this.props.remiderDrawer;
                    newVal.startDate = null;
                    newVal.intervalDays = "";
                    newVal.sendSMS = false;
                    this.props.setReminderSettings(newVal);
                    this.setState({
                      startDate: "",
                    });
                  }}
                  sx={{
                    marginLeft: 2,
                    backgroundColor: (theme) => theme.palette.grey[300],
                    border: 0,
                    color: (theme) => theme.palette.grey[800],
                    padding: "5px 5px 5px 8px",
                    borderRadius: "4px",
                    height: "auto",
                    fontSize: "0.875rem",
                    fontWeight: 500,
                    "& .MuiChip-deleteIcon": {
                      color: (theme) => theme.palette.grey[800],
                      fontSize: "16px",
                    },
                  }}
                />
              )} */}

              {/* {this.props?.includeTB && this.state.startDateTB && (
                <Chip
                  variant="outlined"
                  icon={<AlarmIcon />}
                  label={
                    <Box>
                      <Typography
                        variant="caption"
                        display="block"
                        lineHeight={"normal"}
                      >
                        Technical bid reminder
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        fontSize={"12px"}
                        display="block"
                        lineHeight={"normal"}
                      >
                        {moment(
                          moment().add(
                            this.props.remiderDrawer?.remiderDaysTB || 1,
                            "days"
                          )
                        ).format("DD MMM YYYY")}
                      </Typography>
                    </Box>
                  }
                  onDelete={() => {
                    let updatedValues = this.props.remiderDrawer;
                    updatedValues.includeReminderInterval = false;
                    updatedValues.enableHourlyReminderTB = false;
                    updatedValues.sendSMSWithHourlyReminderTB = false;
                    updatedValues.remiderDaysTB = 1;
                    updatedValues.hourlyReminderValueTB = 1;
                    this.props.setReminderSettings(updatedValues);
                    this.setState({
                      startDateTB: false,
                    });
                  }}
                  sx={{
                    marginLeft: 2,
                    backgroundColor: (theme) => theme.palette.grey[300],
                    border: 0,
                    color: (theme) => theme.palette.grey[800],
                    padding: "5px 5px 5px 8px",
                    borderRadius: "4px",
                    height: "auto",
                    fontSize: "0.875rem",
                    fontWeight: 500,
                    "& .MuiChip-deleteIcon": {
                      color: (theme) => theme.palette.grey[800],
                      fontSize: "16px",
                    },
                  }}
                />
              )} */}
            {/* </div> */}

            {/* <div style={{ margin: "2rem 0" }}>
              <Button
                variant="contained"
                color="primary"
                className="Upload_file_btn"
                onClick={(event) => this.handleRFQSubmit(event)}
                disabled={this.state.submitDisable}
              >
                Submit RFQ
              </Button>
            </div> */}
          </form>
        </React.Fragment>
        {/* Modals */}
        {
          // this.props.master_type === 'item'
          // ? <VendorRecipientItemize
          //     open={this.state.vendorRecipintOpen}
          //     type={this.state.vendorRecipintType}
          //     handleClose={this.handleVendorRecipintsClose}
          //   />
          // :
          <VendorRecipient
            open={this.state.vendorRecipintOpen}
            type={this.state.vendorRecipintType}
            handleClose={this.handleVendorRecipintsClose}
            master_type={this.props.master_type}
          />
        }

        <PrRFQSettings
          // includeTB={this.props.includeTB}
          opentab={"reminder"}
          rfqValidity={this.state.RFQStatic.RFQValidity.value}
          open={this.state.reminderDrawerOpen}
          handleClose={() => {
            this.setState({
              reminderDrawerOpen: false,
            });
          }}
        />
        {/* <ReminderTemporaryDrawer
          includeTB={this.props.includeTB}
          rfqValidity={this.state.RFQStatic.RFQValidity.value}
          open={this.state.reminderDrawerOpen}
          onClose={() => {
            this.setState({
              reminderDrawerOpen: false,
            });
          }}
        /> */}
        {this.props.master_type === "item" ? (
          <TermDetailsItemize
            open={this.state.vendorTermsOpen}
            vendorType={this.state.vendorType}
            vendorTerms={this.state.vendorTerms}
            handleClose={this.handleVendorTermsClose}
          />
        ) : (
          <Details
            open={this.state.vendorTermsOpen}
            vendorType={this.state.vendorType}
            vendorTerms={this.state.vendorTerms}
            handleClose={this.handleVendorTermsClose}
            setTermValue={(value, vendorType) =>
              this.setTermValue(value, vendorType, this.state.currentFieldID)
            }
            setTermError={this.setTermError}
          />
        )}

        {this.state.RFQSubmitDialogOpen && (
          <RFQSubmit
            open={this.state.RFQSubmitDialogOpen}
            selectedPrCategory={this.props.selectedPrCatagory}
            rfq_no={this.state.rfqSubmitMsg}
            handleClose={this.RFQSubmitDialogOnClose}
            location = {this.props.location}
            plantNames={this.state.DestinationCodes}
          />
        )}
        {this.state.specificationsOpen && (
          <UploadFileDialog
            product_id={this.props.selectedCategory?.id}
            randomId={this.state.randomId}
            master_type="category"
            handleUplaod={this.handleUplaod}
            open={this.state.specificationsOpen}
            Alreadyexistingfiles={this.props.categorySpecFiles}
            updateCategorySpecificationFiles={(newFiles) => {
              this.setState({
                categorySpecificationFiles: newFiles,
              });
            }}
            handleClose={() => {
              this.setState({
                specificationsOpen: false,
              });
            }}
          />
        )}

        {this.state.dynamicFileUploadOpen && (
          <UploadDynamic
            sessionID={this.props.sessionId}
            currentFieldId={this.state.currentFieldId}
            uploadedFiles={
              this.state[this.state.currentTemplate][this.state.currentFieldId] && this.state[this.state.currentTemplate][this.state.currentFieldId]["value"]
            }
            handleUplaodedFileDelete={(index) => {
              let temp = this.state[this.state.currentTemplate];
              temp[this.state.currentFieldId]["value"].splice(index, 1);
              this.setState({
                [this.state.currentTemplate]: temp,
              });
            }}
            setAttachmentsValue={(value) => {
              this.setAttachmentsValue(value);
            }}
            randomId={this.state.randomId}
            open={this.state.dynamicFileUploadOpen}
            handleClose={() => {
              this.setState({
                dynamicFileUploadOpen: false,
              });
            }}
          />
        )}

        {this.state.tandcDialogOpen && (
          <TermsAndConditions
            category_id={this.props.selectedCategory?.id}
            open={this.state.tandcDialogOpen}
            termsAndConditionsData={this.state.termsAndConditionsData}
            handleClose={() => {
              this.setState({
                tandcDialogOpen: false,
              });
            }}
          />
        )}

        <ConfirmAlert
          open={this.state.confirmDialogOpen}
          handleClose={() => {
            this.setState({
              confirmDialogOpen: false,
            });
          }}
          handleSubmit={() => {
            this.setState({
              confirmDialogOpen: false,
            });
            this.rfqsubmitAPI();
          }}
          msg={this.state.confirmAlertMsg}
          title={this.state.confirmAlertTitle}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  // sessionId: state.sessionReducer.sessionId,
  companyInfo: state.companyInfoReducer.companyInfo,
  rfqSettingValues: state.drawerValuesReducer.rfqSettingValues,
  // reminderDrawer: state.drawerValuesReducer.reminderDrawer,
  selectedVendors: state.vendorReducer.selectedVendors,
  accountModulePermission: state.permissionsReducer.accountModulePermission,
  accountlevelInfo: state.permissionsReducer.accountlevelInfo,
  featurePermission: state.permissionsReducer.featurePermission,
  remiderDrawer: state.drawerValuesReducer.remiderDrawer,
  checkTechnicalBidItems: state.itemReducer.checkTechnicalBidItems,
  checkTechinicalBidCategory: state.categoryReducer.checkTechinicalBidCategory,
  categorySpecFiles: state.categoryReducer.fileSpecifications,
  itemSpecificFiles: state.itemReducer.itemSpecifications,
  prlineitems: state.prrfqreducer.setPrLineItemsData,
  prplants: state.prrfqreducer.plantcodes,
  selectedPrCatagory: state.prrfqreducer.selectedPrCategory,
  rfqSubmittedPrs: state.prrfqreducer.rfqSubmittedPrs,
  prCombinations: state.prrfqreducer.prCombinations,
  pr_dropdown_values: state.prrfqreducer.pr_dropdown_values,
  defaultValues: state.prrfqreducer.defaultValues,
  userInfo: state.userInfoReducer.userInfo,
  isAllVendorSelected: state.vendorReducer.isAllVendorSelected,
  disableIndex: state.prrfqreducer.disableIndex,
  templateValues: state.prrfqreducer.templateValues,
  termsAndConditions: state.prrfqreducer.termsAndConditions,
  fileSpecificationFromApi: state.prrfqreducer.fileSpecificationFromApi,
  selectedItems: state.itemReducer.selectedItems,
  displayOptions: state.prrfqreducer.displayOptions,
  ImportExcelRfq: state.ImportExcelReducer.excelResponse,
  getVendorLocationFlag: state.itemReducer.vendorLocationFlag
});

// export default connect(mapStateToProps, {
//   openSnackbar,
//   setReminderSettings,
//   resetReminderSettings,
//   resetCAT,
//   setFileSpecification,
//   setItemSpecifications,
//   setPrlinktoitemsforspecification,
//   setPrRfqs,
//   setPRselectedChange,
//   setDefaultValues,
//   setTemplateValues,
//   setTermsAndConditions,
//   setFileSpecificationFromApi,
//   setRFQSettings,
// })(AccountTemplate);

const mapDispatchToProps = {
  openSnackbar,
  setReminderSettings,
  resetReminderSettings,
  resetCAT,
  setFileSpecification,
  setItemSpecifications,
  setPrlinktoitemsforspecification,
  setPrRfqs,
  setPRselectedChange,
  setDefaultValues,
  setTemplateValues,
  setTermsAndConditions,
  setFileSpecificationFromApi,
  setRFQSettings,
  resetVendorLocationFlag,
};
const ConnectedAccountTemplate = connect(
  mapStateToProps,
  mapDispatchToProps
)(AutoRFQTemplate);

const StyledAccountTemplate = withStyles(styles)(ConnectedAccountTemplate);

export default StyledAccountTemplate;
