import React, { Component } from "react";
import {
  Grid,
  Checkbox,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Drawer,
} from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { connect } from "react-redux";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { setSelectedItem, setVendorLocationFlag } from "../../../redux/actions/itemActions";
import { getItemizedVendors } from "../../../redux/actions/vendorActions";
import { IconButton } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { LinkVendorIcon, LinkVendorError } from '../../SvgIcon/SvgIcon';
import { Box } from "@mui/system";
import _ from 'lodash'
import CartItem from "../../CartItem/CartItem";
import SidebarVendors from '../Vendors/SidebarVendors'
import RefreshIcon from "@mui/icons-material/Refresh";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import Crop32Icon from '@mui/icons-material/Crop32';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import CloseIcon from '@mui/icons-material/Close';
import WebAssetOffIcon from '@mui/icons-material/WebAssetOffOutlined';
import WebAssetIcon from '@mui/icons-material/WebAssetOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import ItemizeVendorSidebar from "../Vendors/ItemizeVendorSidebar";

class ItemizeItems extends Component {
  state = {
    mainItem: null,
    anchorEl: null,
    showVendors: false,
    currentItem: null,
    showLinkedVendors: false,
    vendorClassifications: this.props.getVendorLocationFlag,
    selectedItems: this.props.selectedItems,
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleClassificationChange = (vendorId, newClassification) => {
    this.setState((prevState) => {
      const updatedVendorClassifications = { ...prevState.vendorClassifications };
      updatedVendorClassifications[vendorId] = newClassification;

      return {
        vendorClassifications: updatedVendorClassifications,
      };
    }, () => {
      this.props.setVendorLocationFlag(this.state.vendorClassifications);
    });
  };

  onUpdateItems = (updatedItems) => {
    let updatedSelectedItems = [];
    updatedItems.forEach((item) => {
      updatedSelectedItems.push(item);
    });
    this.setState({ selectedItems: updatedItems });
    this.props.setSelectedItem(updatedSelectedItems);
  };
  render() {
    return <>
      <div className="card_top_bg">
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                indeterminate={
                  this.props.checkedItems.length > 0 &&
                  this.props.checkedItems.length <
                  this.props.selectedItems.length
                }
                checked={
                  this.props.selectedItems.length > 0 &&
                  this.props.checkedItems.length ===
                  this.props.selectedItems.length
                }
                onChange={this.props.handleItemsSelectAllClick}
              />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ShoppingBagOutlinedIcon color="primary" />
                <Typography sx={{
                  marginTop: 0.5,
                  fontWeight: 500,
                  textTransform: 'uppercase',
                  fontSize: 'calc(13px + (14 - 12) * ((100vw - 300px) / (1920 - 300)))',
                }}>  {this.props.selectedItems?.length} Items</Typography>
              </Box>

              <Box sx={{ ml: 'auto' }}>
                {this.props.checkedItems.length > 0 && (
                  <Tooltip title="Remove Item">
                    <IconButton color="error"
                      onClick={() => this.props.removeItemFromCart()}
                      size="small">
                      <RemoveCircleIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="View link Vendors with Item">
                  <IconButton sx={{ color: 'grey.600' }}
                    onClick={() => this.setState({ showLinkedVendors: true })}
                  >
                    <PeopleOutlineOutlinedIcon />
                  </IconButton>
                </Tooltip>
                {(this.props.userInfo?.is_super_user === "Y" ||
                  this.props.masterPermission.linking === "Y") && (
                    <Tooltip title="Link Unlink Vendors with Item">
                      <IconButton sx={{ color: 'grey.600' }}
                        onClick={() => this.props.handleItemVendorLinkOpen()}
                        size="small">
                        <LinkVendorIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                {(this.props.userInfo?.is_super_user === "Y" ||
                  this.props.masterPermission?.item_master === "Y" ||
                  this.props.masterPermission?.linking === "Y")
                  && (
                    <>
                      <Tooltip title="More">
                        <IconButton onClick={this.handleClick} size="small">
                          <MoreVert />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="long-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            this.props.updateCartItemsDetails()
                            this.handleClose();
                          }}
                        >
                          <RefreshIcon />
                          Reload
                        </MenuItem>
                        {(this.props.userInfo?.is_super_user === "Y" ||
                          this.props.masterPermission.vendor_master === "Y") && (
                            <MenuItem
                              onClick={() => {
                                if (this.props.companyInfo?.common_vendor_login === "Y") {
                                  this.props.handleCompanyToAccountOpen();
                                } else {
                                  this.props.handleAddVendorDialogOpen();
                                }

                                this.handleClose();
                              }}
                            >
                              <AddCircleOutlinedIcon />
                              Add Vendor
                            </MenuItem>
                          )}
                        {(this.props.userInfo?.is_super_user === "Y" ||
                          this.props.masterPermission.linking === "Y") && (
                            <MenuItem
                              onClick={() => {
                                this.props.handlelinkVendorToCategoryOpen();
                                this.handleClose();
                              }}
                            >
                              <InsertLinkIcon />
                              Link Unlink Vendor(s)
                            </MenuItem>
                          )}
                        {((this.props.userInfo?.is_super_user === "Y" ||
                          this.props.masterPermission?.item_master === "Y") && this.props.prplants.length === 0) && (

                            <MenuItem
                              onClick={() => {
                                this.handleClose();
                                this.props.openAddItem();
                              }}
                            >
                              <AddCircleOutlinedIcon />
                              Add New Item
                            </MenuItem>
                          )}
                        {(this.props.userInfo?.is_super_user === "Y" ||
                          this.props.masterPermission?.linking === "Y") && (
                            <MenuItem
                              onClick={() => {
                                this.handleClose();
                                this.props.handleOpenLinkUnlinkItem();
                              }}
                            >
                              <InsertLinkIcon />
                              Link Unlink Item(s)
                            </MenuItem>
                          )}
                        {this.props.itemizeTB && (
                          <>
                            <MenuItem
                              onClick={() => {
                                this.props.updateTechnicalBidItems("disable");
                                this.handleClose();
                              }}
                            >
                              <WebAssetOffIcon />
                              Exclude TB Template
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                this.props.updateTechnicalBidItems("enable");
                                this.handleClose();
                              }}
                            >
                              <WebAssetIcon />
                              Include TB Template
                            </MenuItem>
                          </>
                        )}
                      </Menu>
                    </>
                  )}

              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        style={{ height: "400px", overflowY: "auto", userSelect: "none" }}
      >
        <Grid item xs={12} >
          <List disablePadding
            sx={{
              width: '100%',
              backgroundColor: 'grey.0',
            }}
          >
            {this.props.selectedItems.map((item, index) => {
              // const isItemSelected = this.isItemSelected(item);
              const checked = this.props.isItemSelected(item);
              return (
                <CartItem
                  key={item.id}
                  tbStage={this.props.tbStageStatus[item.id]}
                  addItemTB={() => this.props.addItemTB(item)}
                  removeItemTB={() => this.props.removeItemTB(item)}
                  isUpdated={this.props.tbItemStatus[item.id]}
                  setIsUpdated={(newVal) => this.props.setIsUpdated(item.id, newVal)}
                  defaultTBValuePermission={this.props.defaultTBValuePermission}
                  isIncludedTB={this.props.TBItems.some(cv => cv.id === item.id)}
                  checked={checked}
                  item={item}
                  onClick={() => this.props.handleItemsClick(item)}
                  includeTB={this.props.includeTB}
                  itemizeTB={this.props.itemizeTB}
                  handleAction={this.props.handleAction}
                  itemizeRFQ
                  viewVendors={() => {
                    this.setState({
                      currentItem: item,
                      showVendors: true,
                    });
                  }}
                  disabledtechnicalbid={this.props.disabledtechnicalbid}
                  TBTemplateValueIntegration={this.props.TBTemplateValueIntegration}
                  pr_has_tb={this.props.pr_has_tb}
                  selectedCategory={this.props.selectedCategory}
                  sessionId={this.props.sessionId}
                />
                // <ListItem
                //   disableGutters
                //   key={index}
                //   sx={{
                //     padding: 0,
                //     borderBottom: "1px solid",
                //     borderColor: "bluegrey.500",
                //     overflow: "hidden",
                //   }}
                // >
                //   <ListItemButton
                //     className={`listing_item`}
                //     sx={{
                //       display: "flex",
                //       alignItems: "center",
                //       padding: "8px 16px",
                //       overflow: "hidden",
                //     }}
                //   >
                //     <ListItemIcon sx={{ minWidth: 30 }}>
                //       <Checkbox
                //         edge="start"
                //         checked={checked}
                //         onChange={() => this.props.handleItemsClick(item)}
                //       />
                //     </ListItemIcon>
                //     <ListItemIcon sx={{ minWidth: 30 }}>
                //       {parseInt(item.vendor_count.total_count) > 0
                //         ? <Tooltip title='View Vendors'> 
                //             <IconButton sx={{color: 'grey.600', mr:1}} size="small" onClick={() => {
                //               this.setState({
                //                 currentItem: item,
                //                 showVendors:true
                //               })
                //             }}>
                //               <GroupIcon /> 
                //             </IconButton>
                //           </Tooltip>
                //         : <IconButton sx={{color: 'error.main', mr:1}} size="small">
                //             <LinkVendorError /> 
                //           </IconButton>                           
                //       }
                //     </ListItemIcon>
                //     <ListItemText
                //       primary={
                //         <React.Fragment>
                //           <Box sx={{display:'flex', justifyContent:'space-between'}}>
                //             <Tooltip title={_.unescape(item.name)} placement="bottom-start" disableInteractive>
                //             <Typography noWrap sx={{ fontWeight: 500, width: parseInt(item.vendor_count.total_count) === 0 ? "calc(100% - 170px)" : "100%" }}>
                //                 {_.unescape(item.name)}
                //               </Typography>
                //             </Tooltip>
                //             {parseInt(item.vendor_count.total_count) === 0 && 
                //               <Typography noWrap sx={{ color:'error.main', fontWeight: 500 }}>
                //                 vendors not available
                //               </Typography>
                //             }
                //           </Box>
                //         </React.Fragment>
                //       }
                //     />
                //   </ListItemButton>
                // </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>

      <Drawer
        anchor="right"
        open={this.state.showVendors}
        onClose={() => this.setState({ showVendors: false })}
        sx={{
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: '50%', boxSizing: 'border-box', height: '100vh', },
        }}
      >
        <Box sx={{ width: '100%', height: "100vh" }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            pr: 1,
          }}>
            <Tooltip title={this.state.currentItem?.name}>
              <Typography
                sx={{
                  color: 'grey.900',
                  fontSize: 'calc(16px - (16 - 18) * ((100vw - 300px) / (1920 - 300)))',
                  fontWeight: 500,
                  m: '8px 8px 8px 16px',
                  width: "calc(100% - 50px)"
                }}
                noWrap
                variant='h6'>
                {this.state.currentItem?.name}
              </Typography>
            </Tooltip>

            <IconButton
              size="small"
              sx={{ color: (theme) => theme.palette.bluegrey[500], ml: 'auto', }}
              onClick={() => this.setState({ showVendors: false })}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <SidebarVendors
            key={this.props.ItemizeVendorsKey}
            item={this.state.currentItem}
            selectedCategory={this.props.selectedCategory}
            sessionId={this.props.sessionId}
            handleAddVendorDialogOpen={
              this.props.handleAddVendorDialogOpen
            }
          />
        </Box>
      </Drawer>

      <Drawer
        anchor="right"
        open={this.state.showLinkedVendors}
        onClose={() => this.setState({ showLinkedVendors: false })}
        sx={{
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: '70%', boxSizing: 'border-box', height: '100vh', },
        }}
      >
        <Box sx={{ width: '100%', height: "100vh" }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            pr: 1,
          }}>
            <Tooltip title="Vendors">
              <Typography
                sx={{
                  color: 'grey.900',
                  fontSize: 'calc(16px - (16 - 18) * ((100vw - 300px) / (1920 - 300)))',
                  fontWeight: 500,
                  m: '8px 8px 8px 16px',
                  width: "calc(100% - 50px)"
                }}
                noWrap
                variant='h6'>
                Vendors
              </Typography>
            </Tooltip>

            <IconButton
              size="small"
              sx={{ color: (theme) => theme.palette.bluegrey[500], ml: 'auto', }}
              onClick={() => this.setState({ showLinkedVendors: false })}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <ItemizeVendorSidebar
            selectedCategory={this.props.selectedCategory}
            selectedItems={this.props.selectedItems}
            sessionId={this.props.sessionId}
            handleAddVendorDialogOpen={
              this.props.handleAddVendorDialogOpen
            }
            vendorClassifications={this.state.vendorClassifications}
            handleClassificationChange={this.handleClassificationChange}
            onUpdatevendorClassifications={(newState) => this.setState({ vendorClassifications: newState })}
            onUpdateItems={this.onUpdateItems}
          />
        </Box>
      </Drawer>
    </>;
  }
}
const mapStateToProps = (state) => ({
  sessionId: state.sessionReducer.sessionId,
  selectedItems: state.itemReducer.selectedItems,
  masterPermission: state.permissionsReducer.masterPermission,
  selectedCategory: state.categoryReducer.selectedCategory,
  userInfo: state.userInfoReducer.userInfo,
  companyInfo: state.companyInfoReducer.companyInfo,
  prplants: state.prrfqreducer.plantcodes,
  getVendorLocationFlag: state.itemReducer.vendorLocationFlag
});

export default connect(mapStateToProps, {
  setSelectedItem,
  getItemizedVendors,
  setVendorLocationFlag,
})(ItemizeItems);
