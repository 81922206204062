import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  Typography,
  Stack,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridOverlay,
} from "@mui/x-data-grid-pro";
import CloseIcon from "@mui/icons-material/Close";
import { RFQVendorStyle } from "./style";
import {
  account_all_users,
  get_Inquiries_data,
  getInquiryLogsData,
} from "../../../config/apiUrl";
import CommonDataGridPro from "../commonDataGridPro/commonDataGridPro";
import axios from "axios";
import { useSelector } from "react-redux";
import { EmptyMsgIcon } from "../../SvgIcon/SvgIcon";
import moment from "moment/moment";

function InquiryLogs({
  sessionID,
  templateId,
  selectedId,
  inquiryNumber,
  // setSelectedItem,
  openDialogueBox,
  setOpenDialogueBox
}) {
  const [inquiryLogDetails, setInquiryLogDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const companyPermissions = useSelector(
    (state) => state.companyInfoReducer.companyInfo
  );

  const configAxios = {
    headers: { Authorization: `Bearer ${sessionID}` },
  };

  const handleCloseDialogue = () => {
    setOpenDialogueBox(false);
  };

  const sanitizeHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  function CustomGridToolbar() {
    return (
      <Fragment>
        <GridToolbarContainer className="datagrid-toolbar">
          <Grid
            container
            spacing={0}
            sx={{
              width: "100%",
              pt: 1,
              "&.MuiGrid2-root": {
                paddingTop: { xs: "0", sm: "0", md: "0", alignItems: "center" },
              },
            }}
          >
            <Grid xs={6} sm={6} md={4} xl={4}>
              <Box>
                <GridToolbarQuickFilter
                  className="DataGrid-SearchBar"
                  sx={{ p: 0 }}
                />
              </Box>
            </Grid>
            <Grid xs={6} sm={6} md={8} xl={8} sx={{ textAlign: "right" }}>
              <Box className="toolbar-right">
                <Box className="dg-filter">
                  <GridToolbarColumnsButton />
                  <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{
                      fileName: "Vendor Details Report",
                    }}
                  />
                  <GridToolbarFilterButton />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </GridToolbarContainer>
        {/* <GridToolbarContainer
          className="datagrid-toolbar"
          sx={{ display: { sm: "flex", md: "none" }, right: 0 }}>
          <Grid
            container
            spacing={0}
            sx={{ width: "100%", alignItems: "center" }}>
            <Grid xs={2} md={6}>
              <Box>&nbsp;</Box>
            </Grid>
            <Grid xs={10} md={6} sx={{ textAlign: { xs: "right", sm: "right" } }}>
              <Box className="dg-filter">
                <GridToolbarColumnsButton />
                <GridToolbarExport 
                  printOptions={{ disableToolbarButton: true }}
                  csvOptions={{
                    fileName: "Vendor Details Report",
                  }}
                />
                <GridToolbarFilterButton />
              </Box>
            </Grid>
          </Grid>
        </GridToolbarContainer> 
      <Divider />
      </Box>
      */}
      </Fragment>
    );
  }

  const rfqVariantFilterData = [
    // { value: "slab", label: "Slab" },
    { value: "sealed" },
    { value: "prioritized" },
    { value: "contract" },
    { value: "multiple_solution" },
    { value: "multi_plant" },
    { value: "r2a" },
    // Permisison?.accountModulePermission?.["Technical Bid"]?.module_activated == "Y" ? { value: "technical_bid", label: filterLabels.technical_bid } : ''
  ]

  const columns = [
    {
      field: "action_name",
      headerName: "ACTION NAME",
      width: 300,
      valueOptions: rfqVariantFilterData,
      // renderCell: vendorNameCell,
    },
    {
      field: "user_name",
      headerName: "ACTION TAKEN BY",
      width: 300,
      // renderCell: quoteStatusCell,
      // valueGetter: (params) => params?.row?.quotation?.status,
    },
    {
      field: "justification",
      headerName: "JUSTIFICATION LINK/UNLINK USERS",
      width: 300,
      renderCell: (params) => {
        const sanitizedHTML = sanitizeHTML(params?.value || "");
        return (
          <div
            dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
            style={{ whiteSpace: "normal", wordWrap: "break-word" }}
          />
        );
      },
    },
    {
      field: "value",
      headerName: "CHANGED VALUE",
      width: 300,
      renderCell: (params) => {
        const sanitizedHTML = sanitizeHTML(params?.value || "");
        return (
          <div
            dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
            style={{ whiteSpace: "normal", wordWrap: "break-word" }}
          />
        );
      },
      // valueGetter: (params) => (params?.row?.negotiation ? "Yes" : "No"),
    },
    {
      field: "action_taken_date",
      headerName: "ACTION TAKEN DATE",
      width: 300,
      valueFormatter: (params) => {
        const { value } = params;
        return (
          moment(value, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss") +
          " IST"
        );
      }
    },
  ];

  const CustomNoRowsOverlay = () => (
    <GridOverlay>
      <EmptyMsgIcon sx={{ maxWidth: "225px", width: "100%", height: "auto", mb: 1 }} />
    </GridOverlay>
  );


  const fetchAccountAllUsers = async () => {
    try {
      const response = await axios.get(account_all_users, configAxios);
      return response.data; // Array of user data
    } catch (error) {
      console.error("Error fetching account_all_users:", error);
      return [];
    }
  };

  const fetchInquiryLogsData = async () => {
    setLoading(true);
    let url = getInquiryLogsData(selectedId);
    try {
      let response = await axios.get(url, configAxios);
      const inquiryLogData = response.data;

      // Fetch user details from account_all_users
      const allUsers = await fetchAccountAllUsers();

      // Map UserNames to extendValidityThreadData
      const enrichedData = inquiryLogData.map((item) => {

        const user = allUsers?.users.find((allUsers) => allUsers.UserID === item.action_by);
        return {
          ...item,
          user_name: user ? user.UserName : "Unknown User", // Default to "Unknown User" if not found
        };
      });
      setInquiryLogDetails(enrichedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchInquiryLogsData();
  }, []);

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        sx={{
          margin: { xs: "0", sm: "0", md: "20px" }
        }}
        open={openDialogueBox}
        onClose={handleCloseDialogue}
        className="RFQ-VendorDialog"
      >
        <DialogTitle
        component="div"
          sx={{
            position: "sticky",
            top: 0,
            padding: "11px 15px !important"
          }}
        >
          <Stack
            className="VendorItem-Title"
            sx={{
              flex: 1,
              "@media screen and (max-width:991px)": {
                width: "calc(100% - 25px)"
              }
            }}
          >            
            <Typography
              variant="subtitle1"
              fontWeight={500}
              component="h6"
              color={"secondery.900"}
              lineHeight={"normal"}
              noWrap
            >
              Inquiry Logs
            </Typography>
            <Tooltip title={inquiryNumber}>
              <Typography variant="caption" display="block">{inquiryNumber}</Typography>
            </Tooltip>
          </Stack>
          <IconButton
            edge="end"
            color="var(--bluegrey-500)"
            size="small"
            onClick={() => {
              handleCloseDialogue();
              // setSelectedItem(null);
            }}
            aria-label="close"
          >
            <CloseIcon sx={{ color: "text.primary" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }} dividers>
          <Box className="DataGFullScreen-wrap">
            <CommonDataGridPro
              loading={loading}
              rows={inquiryLogDetails}
              columns={columns}
              getRowId={(row) => `${row?.id}-${row?.action_taken_date}`}
              headerHeight={40}
              columnHeaderHeight={40}
              getRowHeight={() => "25px"}
              pagination={true}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              Toolbar={CustomGridToolbar}
              NoRowsOverlay={CustomNoRowsOverlay}
              disableRowSelectionOnClick
              className="fullScreenGrid"
              sx={{
                "&.MuiDataGrid-root": {
                  border: 0,
                },
                "& .MuiDataGrid-virtualScrollerContent--overflowed .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                {
                  borderBottomColor: "bluegray.500",
                },
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment >
  );
}

export default InquiryLogs;
