import React, { Component } from "react";
import {
  Checkbox,
  Typography,
  Tooltip,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Switch,
  Badge
} from "@mui/material";
import { Box } from "@mui/system";
import "../../pages/style.css";
import _, { debounce } from "lodash";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { ToggleButton } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ResizableBox } from "react-resizable";
import "./resize.style.css";
import ItemizeField from "./ItemizeField";
import More from "../More/More";
import Upload from "../Dialogs/Upload/Upload";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AttachFileIcon from '@mui/icons-material/AttachFile';
export default class ItemizeSectionTable extends Component {
  constructor() {
    super();
    this.state = {
      name: "React",
      SlabTableToggle: false,
      rows: [],
    };
    // this.hideComponent = this.hideComponent.bind(this);
  }

  // slabAddRow = () => {
  //   this.setState((prevState, props) => {
  //     const row = {};
  //     return { rows: [...prevState.rows, row] };
  //   });
  // };

  // slabRemoveRow = () => {
  //   this.setState((prevState, props) => {
  //     return { rows: prevState.rows.slice(1) };
  //   });
  // };

  // hideComponent(name) {
  //   switch (name) {
  //     case "SlabTableToggle":
  //       this.setState({ SlabTableToggle: !this.state.SlabTableToggle });
  //       break;
  //     default:
  //   }
  // }

  state = {
    isScrollActive: false,
    currentItemIndex: null,
    anchorEl: null,
    qty: "",
    rate: "",
    estimatedValueOpen: false,
    specificationsOpen: false,
    updateOnce: false
  };
  getTableBody = () => {
    let itemsToRender;
    const {SlabTableToggle} = this.state;
        return (
          <TableBody>
          <TableRow key={1}>
            
            {this.props.data[0]?.map((cell) => {
              if (cell.VisibilityFlag === "Y") {
                if (
                  !(
                    cell.Identifier === "Product" ||
                    cell.Identifier === "ProductCode" ||
                    cell.Identifier === "ProductForeign"
                  )
                ) {
                  return (
                    <TableCell key={cell.FieldId} sx={{ minWidth: "120px" }}>
                      <ItemizeField
                        batch_id={this.props.batch_id}
                        LATEST={this.props.LATEST}
                        // upd_budget_master={
                        //   this.props.mainTemplate[item.id] &&
                        //   this.props.mainTemplate[item.id][cell?.FieldId] &&
                        //   this.props.mainTemplate[item.id][cell?.FieldId][
                        //     `upd_budget_master_${item.id}`
                        //   ]
                        // }
                        qty={
                          this.props.mainTemplate[1] &&
                          this.props.mainTemplate[1]["qty"]
                        }
                        rate={
                          this.props.mainTemplate[1] &&
                          this.props.mainTemplate[1]["rate"]
                        }
                        readOnly = {this.props.mainTemplate[1]?.[cell?.FieldId]?.readOnly}
                        cell={cell}
                        serviceRFQ = {this.props.rfqSettingValues.serviceRFQ}
                        // item={item}
                        index={1}
                        FieldState={
                          this.props.mainTemplate[1] &&
                          this.props.mainTemplate[1][cell?.FieldId]
                        }
                        base_currency={this.props.base_currency}
                        handleUpdateInMasterClick={
                          this.props.handleUpdateInMasterClick
                        }
                        updateDeliveryScheduleFieldValue={
                          this.props.updateDeliveryScheduleFieldValue
                        }
                        handleChange={(evt, FieldId, Identifier) =>
                          this.props.handleChange(
                            evt,
                            1,
                            FieldId,
                            Identifier
                          )
                        }
                        handleDateChange={(newVal, FieldId, Validation) =>
                          this.props.handleDateChange(
                            newVal,
                            FieldId,
                            "mainTemplate",
                            1,
                            Validation
                          )
                        }
                        default_dropdown_type={this.props.default_dropdown_type}
                        destination={this.props.destination}
                      />
                    </TableCell>
                  );
                }
              }
            })}
          </TableRow>
          </TableBody>
        );
    //   });
    // } 
    // else itemsToRender = "loading...";
    return <TableBody>{itemsToRender}</TableBody>;
  };

  render() {
    return (
      <Box 
        sx={{
          "& .MuiTableContainer-root": {
            maxHeight: "400px",
            border: "solid 1px",
            borderColor: "var(--bluegrey-500)",
            borderBottomRightRadius: "4px",
            borderBottomLeftRadius: "4px",
          },
          "& .MuiTable-root": {
            "& .MuiTableHead-root": {
              "height":"40px",
              "& .MuiTableCell-root": {
                lineHeight: "normal",
                background: "var(--grey-50)",
                whiteSpace:"nowrap",
                "&:nth-child(1), &:nth-child(2)": {
                  zIndex: "4",
                },
              },
            },
            "& .MuiTableCell-root": {
              background: "var(--grey-0)",
              padding:"6px 16px",              
            },
            "& .MuiTableBody-root":{
              "& .MuiTableCell-root": {
                minWidth: "200px",
                maxWidth: "200px",
              },
              ".MuiTableRow-root:last-child .MuiTableCell-root":{
                border: "none"
              }
            }
          },
        }}
      >
        <TableContainer
          className="items_template_table"
          onScroll={debounce((event) => {
            if (event.target.scrollLeft === 0 && this.state.isScrollActive) {
              this.setState({
                isScrollActive: false,
              });
            } else if (
              event.target.scrollLeft !== 0 &&
              !this.state.isScrollActive
            ) {
              this.setState({
                isScrollActive: true,
              });
            }
          }, 200)}
          sx={{
            // maxHeight: "400px",
            // overflowY: "auto",
            // border: "solid 1px",
            // borderColor: (theme) => theme.palette.bluegrey[500],
            // borderBottomRightRadius: "4px",
            // borderBottomLeftRadius: "4px",
            // ".items_template_table .MuiTableHead-root": {
            //   backgroundColor: (theme) => theme.palette.bluegrey[500],
            // },
            // "& .MuiTableHead-root ": {},
            // "& .MuiTableBody-root": {
            //   backgroundColor: (theme) => theme.palette.grey[0],
            // },
            // "& tr": {
            //   whiteSpace: "nowrap",
            // },
            // "& .MuiTableHead-root tr th": {
            //   padding: "15px 8px",
            // },
            // "& .MuiTableBody-root tr td": {
            //   padding: "5px 8px",
            // },
            // ".MuiTableBody-root tr td:first-of-type, .MuiTableHead-root tr th:first-of-type ":
            //   {
            //     position: "sticky",
            //     left: "0",
            //     minWidth: "380px",
            //   },
            // "& .MuiTableHead-root tr th:first-of-type": {
            //   zIndex: "3",
            // },
            // "& .MuiTableBody-root tr td:first-of-type": {
            //   backgroundColor: (theme) => theme.palette.grey[0],
            //   zIndex: "1",
            // },
            // '& .css-17hdxs-MuiTypography-root':{
            //   fontSize:"14px"
            // }
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {/* <TableCell
                  sx={{
                    WebkitBoxShadow:
                      this.state.isScrollActive && "11px 0 19px -2px #bebebe54",
                    boxShadow:
                      this.state.isScrollActive && "11px 0 19px -2px #bebebe54",
                  }}
                >
                  <Box
                    className=""
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox sx={{ visibility: "hidden" }} />
                    <Tooltip
                      title={
                        this.props.data[0]?.find(
                          (obj) => obj.Identifier === "Product" || obj.Identifier === "ProductForeign"
                        )?.FieldLabel
                      }
                    >
                      <Box
                        sx={{
                          minWidth: "350px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography fontWeight={500}>
                          {
                            this.props.data[0]?.find(
                              (obj) => obj.Identifier === "Product" || obj.Identifier === "ProductForeign"
                            )?.FieldLabel
                          }
                        </Typography>

                        <ResizableBox
                          axis="x"
                          height={20}
                          width={20}
                          onResizeStop={(e, data) => {
                            this.setState({
                              itemCellWidth: data.size.width,
                            });
                          }}
                          style={{
                            textAlign: "left",
                            width: "100%",
                          }}
                          minConstraints={[20, 20]}
                        ></ResizableBox>
                      </Box>
                    </Tooltip>
                  </Box>
                </TableCell> */}

                {/* <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Tooltip
                      title={
                        this.props.data[0]?.find(
                          (obj) => obj.Identifier === "ProductCode"
                        )?.FieldLabel
                      }
                    >
                      <Typography fontWeight={500}>
                        {
                          this.props.data[0]?.find(
                            (obj) => obj.Identifier === "ProductCode"
                          )?.FieldLabel
                        }
                      </Typography>
                    </Tooltip>
                    <ResizableBox
                      axis="x"
                      height={20}
                      width={20}
                      style={{
                        textAlign: "left",
                        width: "100%",
                      }}
                      minConstraints={[20, 20]}
                    ></ResizableBox>
                  </Box>
                </TableCell> */}

                {this.props.data[0]?.map((cell, index) => {
                  if (cell.VisibilityFlag === "Y") {
                    if (
                      !(
                        cell.Identifier === "Product" ||
                        cell.Identifier === "ProductForeign" ||
                        cell.Identifier === "ProductCode"
                      )
                    ) {
                      return (
                        <TableCell key={cell.FieldId}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                          <Tooltip title={cell.FieldLabel}>
                            {/* <p>{cell.FieldLabel}</p> */}
                            <Typography variant="caption" component="p" fontWeight={500}>
                                {cell.FieldLabel}
                              </Typography>
                          </Tooltip>
                          <ResizableBox
                            axis="x"
                            height={20}
                            width={20}
                            style={{
                              textAlign: "left",
                              width: "100%",
                            }}
                            minConstraints={[20, 20]}
                          ></ResizableBox>
                        </div>
                        </TableCell>
                      );
                    }
                  } else {
                    return "";
                  }
                })}
              </TableRow>
            </TableHead>
            {this.getTableBody()}
          </Table>
        </TableContainer>
        {/* {this.state.specificationsOpen && (
          <Upload
            currentItemIndex={this.state.currentItemIndex}
            product_id={
              this.props.selectedItems[this.state.currentItemIndex]?.id
            }
            randomId={this.props.randomId}
            master_type={"item"}
            handleUplaod={this.props.handleUplaod}
            open={this.state.specificationsOpen}
            handleClose={() => {
              this.setState({
                specificationsOpen: false,
              });  
            }}
            Alreadyexistingfiles = {this.props.itemSpecificFiles[this.props.selectedItems[this.state.currentItemIndex]?.id] || []}
          />
        )} */}
      </Box>
    );
  }
}
