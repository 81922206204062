import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Divider,
  Typography,  
  CardHeader,
  Button,
  TextField,
  Box,
  InputAdornment,
  Tooltip,
  Checkbox
} from "@mui/material";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const AutoRFQCardTemplate = ({ sendToNew, title, data, getField, handleOpen, shipment_mark, count, commonfields, taskData, handleTaskDataChange, intervalDayError, staticFieldValidate, featurePermission,rfqExpiryIntervalDays,setRfqExpiryIntervalDays}) => {

  const [isChecked, setIsChecked] = React.useState(false);

  React.useEffect(()=>{
    handleTaskDataChange("action_document_data.inquiry_settings.cc_user_enable_flag", (isChecked) ? "Y" : "N");
  },[isChecked]);

  
  
  return (
    <Card 
      variant="outlined" 
      // className="template-card local_recepients_card"
    >
      <CardHeader title={title} 
        titleTypographyProps={{
          variant: "subtitle2",
          component: "h5",
          color: "grey.900",
          textTransform: "uppercase",
        }}
        sx={{
          minHeight: '50px',
          '& .MuiCardHeader-action':{
            marginRight:0,
            marginTop:0,
          }
        }}  
      />      
        <Divider sx={{ marginBottom: "0", }} />
      <CardContent>            
        <Grid container spacing={2} className="local_recepients_card_content">

          {commonfields && (
            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}> 
              <TextField
                // className="local_template_textfield"
                fullWidth
                // key={shipment_mark}
                value={rfqExpiryIntervalDays}
                onChange={(event) => staticFieldValidate(event.target.value)}
                required
                variant="outlined"
                label="RFQ Validity Expiry Interval (in Days)"
                type="text"
                name="rfq-validity-expiry-interval"
                error={intervalDayError}
                helperText={
                  intervalDayError
                    ? "Enter value for field: RFQ Validity Expiry Interval (in Days)"
                    : featurePermission?.max_rfq_validity_days?.feature_value === "Y"
                    ? `Maximum RFQ validity ${featurePermission?.max_rfq_validity_days?.feature_permission_value} days`
                    : ""
                }
            />
           </Grid>
          )}
          {data?.map((cell, i) => {
            const field = getField(cell);
            if (field && cell.VisibilityFlag === "Y") {
              return (
              <Grid item xl={3} lg={4} md={6} sm={6} xs={12} key={i}>
                  {field}
                </Grid>
              );
            }
          })}

          {commonfields && (
            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}> 
            

            <TextField
              fullWidth
              // className="local_template_textfield"
              multiline
              variant="outlined"
              maxRows={3}
              label="CC User's mails"
              value={taskData?.action_document_data?.inquiry_settings?.cc_user_email_id || ""}
              onChange={(e)=>{
                const value = e.target.value.trim();
                handleTaskDataChange("action_document_data.inquiry_settings.cc_user_email_id", value);
                if (!value) {
                  setIsChecked(false);
                }
              }}
              helperText="(Use comma (,) to enter multiple email IDs)"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Update CC Users in Masters">
                      <Checkbox
                        color="primary"
                        checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)}
                        disabled={!taskData?.action_document_data?.inquiry_settings?.cc_user_email_id?.trim()}
                      />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />



            {/* <TextField
              fullWidth
              value={taskData?.action_document_data?.inquiry_settings?.cc_user_email_id}
              onChange={(e) => handleTaskDataChange("action_document_data.inquiry_settings.cc_user_email_id",e.target.value)}
              variant="outlined"
              label="CC Users Mails"
              type="text"
              name="cc-users-mails"
            />

            <Typography 
              fontWeight={500}
              fontSize="10px"
              // color='grey.500'
              marginRight={1}
            >
              use comma (,) to enter multiple email ids 
            </Typography> */}
           </Grid>
          )}
          
          {title === "INTERNATIONAL RECIPIENTS" && 
            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}> 
              <TextField
                fullWidth
                value={taskData?.action_document_data?.template?.data?.FOREIGN?.shipment_mark}
                onChange={(e) => handleTaskDataChange("action_document_data.template.data.FOREIGN.shipment_mark",e.target.value)}
                variant="outlined"
                label="Shipment mark"
                type="text"
                name="shipment_mark"
              />

            </Grid>
          }
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AutoRFQCardTemplate;
