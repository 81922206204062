import React, { useState } from "react";
import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Grid,
    IconButton,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import {
    GridOverlay,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarQuickFilter
} from "@mui/x-data-grid-pro";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import CommonDataGridPro from "../../commonDataGridPro/commonDataGridPro";
import { useSelector } from "react-redux";
import VendorChip from "../../../Vendors/VendorChip";

const useStyles = makeStyles(() => ({
    RFQDrawer: {
        "&.MuiPaper-root": {
            boxShadow: "none",
            borderRadius: "0"
        },
        "& .DataGFullScreen-drawer": {
            height: "calc(100vh - 62px)",
            width: "100%"
        }
    }
}));

const TbGridDrawer = ({
    InquiryData,
    QuotedVendorTB,
    setInquiryData,
    setQuotedVendorTB,
    setAnchorElTBquoted
}) => {
    const classes = useStyles();
    const companyPermissions = useSelector(
        (state) => state.companyInfoReducer.companyInfo
      );
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25,
        page: 0
    });

    const CustomNoRowsOverlay = () => (
        <GridOverlay>
            <Typography>No data available</Typography>
        </GridOverlay>
    );

    const handleDrawerClose = () => {
        setAnchorElTBquoted(false);
        setInquiryData({});
        setQuotedVendorTB({});
    };

    const GroupedIcon = (params) => {
        // const isTB = params.row.inquiry.is_tb;
        return (
          <Stack spacing={1} direction="row">
            <VendorChip vendor_data={params.row} module_permission={companyPermissions} />
          </Stack>
        );
      };

    function CustomGridToolbar() {
        return (
            <React.Fragment>
                <GridToolbarContainer className="datagrid-toolbar">
                    <Grid
                        container
                        spacing={0}
                        sx={{
                            width: "100%",
                            "&.MuiGrid2-root": {
                                paddingTop: { xs: "0", sm: "0", md: "0", alignItems: "center" }
                            }
                        }}
                    >
                        <Grid xs={12} sm={12} md={3} xl={4}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <GridToolbarQuickFilter
                                    className="DataGrid-SearchBar"
                                    sx={{ pb: 0 }}
                                />
                            </Box>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={12}
                            md={9}
                            xl={8}
                            sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
                        >
                            <Box className="toolbar-right">
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    &nbsp;
                                </Box>
                                <Box className="dg-filter">
                                    <GridToolbarColumnsButton />
                                    <GridToolbarExport
                                        printOptions={{ disableToolbarButton: true }}
                                        csvOptions={{
                                            fileName: "QCS Details Report"
                                        }}
                                    />
                                    <GridToolbarFilterButton />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </GridToolbarContainer>
                <GridToolbarContainer
                    className="datagrid-toolbar"
                    sx={{ display: { sm: "flex", md: "none" } }}
                >
                    <Grid
                        container
                        spacing={0}
                        sx={{ width: "100%", alignItems: "center" }}
                    >
                        <Grid xs={2} md={6}>
                            <Box>&nbsp;</Box>
                        </Grid>
                        <Grid
                            xs={10}
                            md={6}
                            sx={{ textAlign: { xs: "right", sm: "right" } }}
                        >
                            <Box className="dg-filter">
                                <GridToolbarColumnsButton />
                                <GridToolbarExport
                                    printOptions={{ disableToolbarButton: true }}
                                    csvOptions={{
                                        fileName: "QCS Details Report"
                                    }}
                                />
                                <GridToolbarFilterButton />
                            </Box>
                        </Grid>
                    </Grid>
                </GridToolbarContainer>
            </React.Fragment>
        );
    }

    const columns = [
        {
            field: "vendor_Name",
            headerName: "VENDOR NAME",
            width: 250,
            filterable: true,
            valueGetter: (params) => `${params.row.name || ""} ${params.row.code || ""}`, 
            renderCell: (params) => {
                const { value, row } = params;
                const myNameFirstLetter = row?.name?.toUpperCase().slice(0, 1) || "";
                const vendorName = row?.name || "";
                const vendorCode = row?.code || "";

                return (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ margin: 1 }}>
                            <Avatar sx={{ color: "white", bgcolor: "var(--grey-500)" }}>
                                {myNameFirstLetter}
                            </Avatar>
                        </Box>
                        <Tooltip title={value}>
                            <Box sx={{ width: "100%" }}>
                                <Typography variant="body1">{vendorName}</Typography>
                                <Typography variant="body2">
                                    {vendorCode}
                                </Typography>
                            </Box>
                        </Tooltip>
                    </Box>
                );
            },
        },
        {
            field: "country",
            headerName: "COUNTRY",
            width: 150,
            filterable: true,
        },
        {
            field: "is_quoted",
            headerName: "LAST STATUS",
            width: 150,
            filterable: true,
            valueGetter: (params) => (params.row.is_quoted ? "Quoted" : "Non-Quoted"),
            renderCell: (params) => {
                return (
                    <Box>
                        {params.row.is_quoted ? (
                            <Chip
                                label="Quoted"
                                variant="soft"
                                color="success"
                                size="small"
                            />
                        ) : (
                            <Chip
                                label="Non-Quoted"
                                variant="soft"
                                color="default"
                                size="small"
                            />
                        )}
                    </Box>
                );
            }
        },
        {
            field: "status",
            headerName: "STATUS",
            width: 150,
            filterable: true,
            renderCell: GroupedIcon,
            valueGetter: (params) => {
              const {type, is_gst, gstin_no, pan_no, is_approved, pan_status, msme_status, gstin_status, industry_type, industry_type_value } = params.row;
              const { gst_verification, msme_verification, pan_verification } = companyPermissions;
      
              // Determine GST status based on permissions
              let gstStatus = '';
              if (gst_verification === 'Y' && gstin_no && gstin_status) {
                gstStatus = "GSTIN " + gstin_status;
              } else {
                gstStatus = is_gst === true ? 'GSTIN Exist' : 'GSTIN Not Exists';
              }
      
              let msmeStatus = '';
              if (msme_verification === 'Y' && industry_type === 'MSME' && industry_type_value && msme_status) {
                msmeStatus = "MSME " + msme_status;
              } else if (msme_verification !== 'Y' && industry_type === 'MSME' && industry_type_value) {
                msmeStatus = "MSME Exists";
              } else {
                msmeStatus = "MSME Not Exists";
              }
      
              let panStatus = '';
              if (pan_verification === 'Y' && pan_no && pan_status) {
                panStatus = "PAN " + pan_status;
              } else if (pan_verification !== 'Y' && pan_no) {
                panStatus = "PAN Exists";
              } else {
                panStatus = "PAN Not Exists";
              }
      
              const approvalStatus = is_approved === true ? 'Approved' : 'Non-Approved';
              const vendorLocationStatus = type === "Local" ? 'L' : 'I';
              // Conditionally push each status to the array if it exists
              const statuses = [];
              if (approvalStatus) statuses.push(approvalStatus);
              if (vendorLocationStatus) statuses.push(vendorLocationStatus);
              if (gstStatus) statuses.push(gstStatus);
              if (msmeStatus) statuses.push(msmeStatus);
              if (panStatus) statuses.push(panStatus);
      
              return  `${statuses.join(', ')}`;
            }
        },
    ];

    const generateRowId = (row) => {
        const id = row?.id;
        return id;
    };

    return (
        <Card className={classes.RFQDrawer}>
            <CardHeader
                action={
                    <Tooltip title="Close">
                        <IconButton
                            onClick={handleDrawerClose}
                            aria-label="close"
                            size="small"
                            sx={{ color: "var(--grey-500)" }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                }
                title={
                    <>
                        <Typography
                            variant="subtitle1"
                            fontWeight={700}
                            color={"secondary.main"}
                            noWrap
                        >
                            TB Vendor(s)
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            fontWeight={400}
                            color={"secondary.main"}
                            noWrap
                        >
                            {InquiryData?.number}
                        </Typography>
                    </>
                }
                subheader={
                    <Typography
                        variant="subtitle2"
                        fontWeight={500}
                        color={"text.primary"}
                        component="div"
                        sx={{ marginTop: "-5px" }}
                        noWrap
                    >
                        {/* {linkUnlinkData[0]?.inquiryNo} */}
                    </Typography>
                }
                sx={{
                    borderBottom: "1px solid var(--bluegrey-500)",
                    pt: 1,
                    pb: 1,
                    "& .MuiCardHeader-action": {
                        alignSelf: "center"
                    }
                }}
            />

            <CardContent sx={{ p: "0 !important" }}>
                <Box className="DataGFullScreen-drawer">
                    <CommonDataGridPro
                        // loading={loading}
                        rows={QuotedVendorTB}
                        getRowId={generateRowId}
                        columns={columns}
                        columnHeaderHeight={40}
                        pagination={true}
                        paginationModel={paginationModel}
                        NoRowsOverlay={CustomNoRowsOverlay}
                        onPaginationModelChange={setPaginationModel}
                        Toolbar={CustomGridToolbar}
                        className="fullScreenGrid"
                        sx={{
                            "&.MuiDataGrid-root": {
                                border: 0
                            },
                            "& .MuiDataGrid-virtualScrollerContent--overflowed .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                            {
                                borderBottomColor: "var(--bluegrey-500)"
                            }
                        }}
                    />
                </Box>
            </CardContent>
        </Card>
    );
};

export default TbGridDrawer;