import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Alert,
  Avatar,
  Backdrop,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { QuotationFormStyle } from "./style";
import { QuotationThread } from "../../components/SvgIcon/SvgIcon";
import { RquoteLimitMsgIcon } from "../../components/SvgIcon/SvgIcon";
import DataTransferIcon from "../../assets/images/data-transfer-icon.png";
import ViewDetailsDrawer from "../../components/QuotationForm/ViewDetailsDrawer/ViewDetailsDrawer";
import QuotationThreadDrawer from "../../components/QuotationForm/QuotationThreadDrawer/QuotationThreadDrawer";
import QuotationTemplate from "../../components/QuotationForm/QuotationTemplate/QuotationTemplate";
import QuotationTemplateView from "../../components/QuotationForm/QuotationTemplate/QuotationTemplateView";
import PersonIcon from "@mui/icons-material/PersonOutlined";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import ConstructionIcon from "@mui/icons-material/ConstructionOutlined";
import NavigationIcon from "@mui/icons-material/RoomOutlined";
import ExpandLess from "@mui/icons-material/ArrowDropDown";
import ExpandMore from "@mui/icons-material/ArrowDropUp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AccountCircleIcon from "@mui/icons-material/AccountCircleOutlined";
import WorkOutline from "@mui/icons-material/WorkOutline";
import Public from "@mui/icons-material/Public";
import ErrorIcon from "@mui/icons-material/ErrorOutlineOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import HelpActionButtons from "../../components/QuotationForm/HelpButton";

import axios from "axios";
import {
  account_all_users,
  devTemplateURi,
  feature_permission,
  getAllowMultipleSolutionOptionLabel,
  getBuyers,
  getDestinations,
  getDropDownCurrencyList,
  getTermsAndConditionsDetails,
  getTransactionDetails,
  getTransactionDetailsView,
  quotationValidation,
  sendMailUri,
  templateURi,
  vendx_connect,
} from "../../config/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo, setVendorInfo } from "../../redux/actions/setUserInfo";
import MailIcon from "@mui/icons-material/Mail";
import dayjs from "dayjs";
import { CustomAlert, CustomSnackbar } from "../../components/Snackbar/style";
import GoogleTranslate from "../../components/Utils/GoogleTranslate";
import SendMail from "../../components/RfqForm/SendMail/SendMail";
const PageToolbar = styled("div")(({ theme }) => ({
  background: "#ffffff",
  position: "sticky",
  // top: "50px",
  top: "0",
  width: "100%",
  minHeight: "54px",
  padding: "10px 20px 0 20px",
  zIndex: "99",
}));

const PopoverDetailList = styled(List)({
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 8,
    marginTop: 0,
  },
  "& .MuiTypography-root": {
    fontSize: "12px",
    color: "var(--secondary-600)",
  },
  "& .MuiListItemText-primary": {
    color: "var(--grey-500)",
  },
  "& .MuiListItem-root": {
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: "start",
  },
  "& .MuiListItemText-root": {
    marginTop: "0",
  },
  "& .MuiListItemIcon-root svg": {
    color: "var(--secondary-600)",
  },
});

const getConfigAxios = (sessionId) => ({
  headers: { Authorization: `Bearer ${sessionId}` },
});

function QuotationDetails(props) {
  const queryParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const [gst, setGst] = useState("10");
  const [referenceNo, setReferenceNo] = useState("");
  const [rfqDate, setRfqDate] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [quotationDataLoading, setQuotationDataLoading] = useState(true);
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryCode, setCategoryCode] = useState("");
  const [vendorType, setVendorType] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [inquiryId, setInquiryId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [mainValidationError, setMainValidationError] = useState("");
  const [itemBoxId, setItemBoxId] = useState("");
  const [itemSpecific, setItemSpecific] = useState("");
  const [newSessionID, setNewSessionID] = useState("");
  const [shipmentMark, setShipmentMark] = useState("");
  const [multipleSolutionFlag, setMultipleSolutionFlag] = useState(false);
  const [featurePermissionDetails, setFeaturePermissionDetails] = useState("");
  const [transactionApiResponse, setTransactionApiResponse] = useState("");
  const [tabValue, setTabValue] = useState("one"); //Tabs Script
  const [totalCostOpen, setTotalCostOpen] = useState(false);
  const [buyerInfo, setBuyerInfo] = useState([]);
  const [inquiryItemsId, setInquiryItemsId] = useState([]);
  const [validityDate, setValidityDate] = useState(null);
  const [currencyDetails, setCurrencyDetails] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [quotationFormFlag, setQuotationFormFlag] = useState(true);
  const [showSlabRangeTable, setShowSlabRangeTable] = useState(false); // Start Slab Range Show Hide Script
  const [quotationJsonTemplate, setQuotationJsonTemplate] = useState([]);
  const [quotationJsonData, setQuotationJsonData] = useState({});
  const [quotedCurrency, setQuotedCurrency] = useState("");
  const [items, setItems] = useState({});
  const [destinationDetails, setDestinationDetails] = useState({});
  const [fileSpecificationData, setFileSpecificationData] = useState({});
  const [filteredSelectedNotData, setFilteredSelectedNotData] = useState({});
  const [termsAndConditionsDetails, setTermsAndConditionsDetails] = useState(
    {}
  );
  const [lastTransactionData, setLastTransactionData] = useState(null);
  const [lastNegotiationData, setLastNegotiationData] = useState(null);
  const [lastTransactionInThread, setLastTransactionInThread] = useState({});
  const [lastTransactionInThreadCommon, setLastTransactionInThreadCommon] =
    useState({});
  const [sessionId, setSessionId] = useState(null);
  const [configAxios, setConfigAxios] = useState(null);
  const [lastTransactionTypeInThread, setLastTransactionTypeInThread] =
    useState("");
  const [lastTransactionGuidInThread, setLastTransactionGuidInThread] =
    useState("");
  const [state, setState] = useState({ clickfunction: null });
  const [quotationJsonTemplateLoading, setQuotationJsonTemplateLoading] =
    useState(false);
  const [viewDetails, setViewDetails] = useState({
    // Start ViewDetails Drawer script
    right: false,
  });

  const [quotationThread, setQuotationThread] = useState({
    // Start Quotation Thread Drawer script
    right: false,
  });

  const [viewQuote, setviewQuote] = useState(false);
  const [CurrentTransaction, setCurrentTransaction] = useState({});
  const [inquiryTransaction, setInquiryTransaction] = useState({});
  const [IsLastTxn, setIsLastTxn] = useState(false);
  const [multiplePlantsFlag, setMultiplePlantsFlag] = useState(false);
  const [slabFlag, setSlabFlag] = useState(false);
  const [AccountUsers, setAccountUsers] = useState({});
  const [PrevTransaction, setPrevTransaction] = useState({});
  const [industryDetails, setIndustryDetails] = useState([]);
  const [PermissionInquiry, setPermissionInquiry] = useState({})
  const setClick = (click) => {
    setState({
      clickfunction: click,
    });
  };
  const userInfo = useSelector((state) => state.userInfoReducer.userInfo);
  const accountModulePermission = useSelector((state) => state.permissionsReducer.accountModulePermission);
  const featurePermission = useSelector((state) => state.permissionsReducer.featurePermission);
  const companyInfo = useSelector(
    (state) => state.companyInfoReducer.companyInfo
  );
  const [headerLabels, setHeaderLabels] = useState([]);
  const [FetchedGuids, setFetchedGuids] = useState([]);
  const [copied, setCopied] = useState(false);
  const [fetchedTxn, setFetchedTxn] = useState({});
  const [transactionItems, setTransactionItems] = useState({});
  const [transactionItemSequence, setTransactionItemSequence] = useState([]);
  const [isLastOnlineOfflineRequotes, setIsLastOnlineOfflineRequotes] = useState(false);
  const [onlineOfflineRequotesExceeded, setOnlineOfflineRequotesExceeded] = useState(false);
  const [ServiceFileName, setServiceFileName] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const [lastTxnInquiryState, setLastTxnInquiryState] = useState(false)
  const [AlternativeNames, setAlternativeNames] = useState({})
  const [openSendMailDrawer, setOpenSendMailDrawer] = useState(false);

  useEffect(() => {
    // Function to extract headers from the DOM
    const extractHeaders = () => {
      // Select all table header elements with the class name 'Itemize_headers'
      const tableHeaderElements = document.querySelectorAll(
        ".Itemize_headers th"
      );

      // Initialize an array to store header labels
      const labels = [];
      // Iterate over each table header element and extract the text content
      tableHeaderElements.forEach((header) => {
        labels.push(header.textContent);
      });
      labels.push("Specifications");
      // Set the header labels state
      setHeaderLabels(labels);
    };
    const queryParams = new URLSearchParams(window.location.search);
    if (!queryParams.get("TLGUID")) {
      // submit form
      extractHeaders();
    }
  }, [quotationJsonTemplate]);

  useEffect(() => {
    const fetchSessionId = async () => {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const sessionId = queryParams.get("q");
        // Simulating asynchronous operation, replace with actual async fetch if needed
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setSessionId(sessionId);
        setNewSessionID(sessionId);
      } catch (error) {
        console.error("Error fetching sessionId:", error);
      }
    };

    fetchSessionId();
  }, []);

  useEffect(() => {
    if (sessionId) {
      const queryParams = new URLSearchParams(window.location.search);

      setConfigAxios(getConfigAxios(sessionId));
      if (queryParams.get("supplier")) {
        dispatch(setVendorInfo(sessionId));
      } else {
        dispatch(setUserInfo(sessionId));
      }
      fetchQCSDetails();
    }
  }, [sessionId]);

  useEffect(() => {
    if (FetchedGuids.length > 0) {
      FetchedGuids.map((guid) => {
        fetchTransactionByGuid(guid);
      });
    }
  }, [FetchedGuids]);

  const fetchTransactionByGuid = async (guid) => {
    const url = getTransactionDetailsView(inquiryId, vendorId, guid);
    const response = await axios.get(url, configAxios);
    const transaction_thread = [...response.data.vendors[0].transaction_thread];
    const indexOfTemplate = transaction_thread
      .reverse()
      .findIndex((obj) => obj.hasOwnProperty("template"));
    let id;
    if (transaction_thread[indexOfTemplate].type === "Inquiry") {
      id = 1;
    } else if (transaction_thread[indexOfTemplate].type === "Quotation") {
      id = 3;
    } else if (transaction_thread[indexOfTemplate].type === "Requote") {
      id = 5;
    } else {
      id = 4;
    }
    setFetchedTxn((prevData) => ({
      ...prevData,
      [id]: transaction_thread[indexOfTemplate].template,
    }));
  };
  const fetchQCSDetails = async () => {
    try {
      const userResponse = await axios.get(account_all_users, configAxios);
      if (userResponse.status === 200) {
        const accountUserData = userResponse.data.users;
        const userIdMap = {};
        accountUserData.forEach((user) => {
          userIdMap[user.UserID] = user.UserName;
        });

        setAccountUsers(userIdMap);
      } else {
        throw new Error("Network response for user data was not ok");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const gstHandleChange = (event) => {
    setGst(event.target.value);
  };

  const handleChangeTabPosition = (event, newValue) => {
    setTabValue(newValue);
  };

  //End Tabs Script
  const ViewDetailsOpen = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setViewDetails({ ...viewDetails, [anchor]: open });
  };
  // End ViewDetails Drawer script

  // End Total Landed Cost For RFQ Drawer script
  const openCommunication = () => {
    const form = document.createElement("form");
    form.target = "_blank";
    form.action = vendx_connect;
    form.method = "post";

    const hiddenInput = document.createElement("input");
    hiddenInput.type = "hidden";
    hiddenInput.name = "inquiry_id";
    hiddenInput.value = inquiryId;
    const queryParams = new URLSearchParams(window.location.search);
    const sessionID = queryParams.get("q");

    const sessionid = document.createElement("input");
    sessionid.type = "hidden";
    sessionid.name = "sessionID";
    sessionid.value = sessionID;

    const hiddenInputAction = document.createElement("input");
    hiddenInputAction.type = "hidden";
    hiddenInputAction.name = "action";
    hiddenInputAction.value = "display_communication_chain";
    form.appendChild(hiddenInput);
    form.appendChild(sessionid);

    form.appendChild(hiddenInputAction);
    document.body.appendChild(form);
    form.submit();
  };
  const sendMail = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const sessionID = queryParams.get("q");
    let data = {
      CallingFile: ["tran_details"],
      CompanyDetailId: [""],
      MultiOrder: [""],
      item_ids_data: ["[]"],
      view: ["trans_thread"],
      qcs_plus: ["1"],
      inquiry_guid: [""],
      CalloffAmendment: [""],
      item_id_seq: [""],
      OfflineTransaction: [""],
      perform: [""],
      template_type: ["item"],
      MasqueradeVendorID: [""],
      vendx_action: ["compose"],
      OverRideThread: [""],
      IDEmail: [""],
      OrderAmendment: [""],
      Delivery: [""],
      ReverseAuction: ["", ""],
      destination_count: ["1"],
      TranType: ["Quotation"],
      Calloff: [""],
      newtranstype: ["Mail"],
      CompanyDetailType: [""],
      from_vendx_admin: ["N"],

      productid: [categoryId],
      recepientid: [vendorId],
      vendor_id: [vendorId],
      NextMailRecepient: [vendorId],
      userid: [userInfo.user_id],
      buyerid: [buyerInfo.id],
      buyer_id: [buyerInfo.id],

      prevtranguid: [CurrentTransaction.guid],
      TransactionNumber: [CurrentTransaction.no],
      TemplateMasterID: [quotationJsonData.template.id],
      TranStatus: [CurrentTransaction.type],
      TranTypeGuid: [CurrentTransaction.TranTypeGUID],
      q: [sessionID],
      sessionID: [sessionID],
    };

    const form = document.createElement("form");
    form.action = sendMailUri;
    form.method = "post";

    for (const key in data) {
      const value = data[key][0]; // Extracting the first element of the array
      const hiddenInput = document.createElement("input");
      hiddenInput.type = "hidden";
      hiddenInput.name = key;
      hiddenInput.value = value;
      form.appendChild(hiddenInput);
    }
    document.body.appendChild(form);
    form.submit();
  };
  const QuotationThreadOpen = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setQuotationThread({ ...quotationThread, [anchor]: open });
  };

  const totalClickOpen = () => {
    setTotalCostOpen(true);
  };

  const handleCloseTotalCost = () => {
    setTotalCostOpen(false);
  };

  const clickSlabTable = () => {
    setShowSlabRangeTable(!showSlabRangeTable);
  };
  // End Slab Range Show Hide Script

  // scrolling down script
  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 155; // Offset to adjust the scrolling position
    const top = sectionElement.offsetTop - offset;
    window.scrollTo({ behavior: "smooth", top });
  };
  const getUniqueGuids = (data, type) => {
    const uniqueTypes = new Set();
    const result = [];

    data.forEach((item) => {
      if (!item.template && !uniqueTypes.has(item.type) && item.type !== type) {
        uniqueTypes.add(item.type);
        result.push(item.guid);
      }
    });

    return result;
  };

  const openSnackbar = (severity, message) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };


  useEffect(() => {
    const fetchQuotationJSON = async () => {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const inq_id = queryParams.get("inq_id");
        setInquiryId(inq_id);
        const vendor_id = queryParams.get("vendor_id");
        setVendorId(vendor_id);
        let URL = getTransactionDetails(inq_id, vendor_id);
        if (queryParams.get("TLGUID")) {
          setviewQuote(true);
          URL = getTransactionDetailsView(
            inq_id,
            vendor_id,
            queryParams.get("TLGUID")
          );
          setIsLoading(true);
          setQuotationDataLoading(true);
          const response = await axios.get(URL, configAxios);
          setRfqDate(response.data.rfq_date);
          setTransactionItems(response.data.items);
          setTransactionItemSequence(response.data.item_sequence);
          setQuotationJsonData(response.data);
          setReferenceNo(response.data.ref_no);
          setCategoryId(response.data.categories[0].id);
          setCategoryName(response.data.categories[0].name);
          setCategoryCode(response.data.categories[0].code);
          setFileSpecificationData(response.data.categories[0].files);
          setCurrencyDetails(response.data.currency);
          setPermissionInquiry(response.data.permission)
          setItems(response.data.items);
          setVendorType(response.data.vendors[0].type);
          const transaction_thread = [
            ...response.data.vendors[0].transaction_thread,
          ];
          const indexOfTemplate = transaction_thread
            .reverse()
            .findIndex((obj) => obj.hasOwnProperty("template"));
          // const indexOfTemplate = transaction_thread.length - 1;
          if (indexOfTemplate === 0) {
            setIsLastTxn(true);
          }
          setLastTransactionInThread(
            transaction_thread[indexOfTemplate].template.itemize
          );
          setLastTransactionTypeInThread(
            transaction_thread[indexOfTemplate].type
          );
          setShipmentMark(transaction_thread[indexOfTemplate].shipment_mark);
          setCurrentTransaction(transaction_thread[indexOfTemplate]);
          setInquiryTransaction(
            transaction_thread[transaction_thread.length - 1]
          );
          setLastTransactionData(transaction_thread[indexOfTemplate]?.template);
          setPrevTransaction(transaction_thread[indexOfTemplate + 1]);
          setIsLoading(false);
          setQuotationDataLoading(false);
          setItemSpecific(response.data.item_specific);
          setMultiplePlantsFlag(response.data.filter_data.multi_plant);
          setMultipleSolutionFlag(
            response.data.filter_data.multiple_solution
          );
          setSlabFlag(response.data.filter_data.slab);
          setLastTransactionInThreadCommon(
            transaction_thread[indexOfTemplate].template.common
          );
          setQuotedCurrency(response.data.vendors[0].quoted_currency);
          setInquiryItemsId(response.data.vendors[0].inquiry_item_ids);
        } else {
          setIsLoading(true);
          setQuotationDataLoading(true);
          const response = await axios.get(URL, configAxios);
          const transaction_thread = [
            ...response.data.vendors[0].transaction_thread,
          ];

          const indexOfTemplate = transaction_thread
          .reverse()
          .findIndex((obj) => obj.hasOwnProperty("template"));
          // const indexOfTemplate = transaction_thread.length - 1;

          // const indexOfTemplates = transaction_thread
          //   .map((item, index) => ({ ...item, index })) // Attach index to items
          //   .reverse()                                  // Reverse the array
          //   .find(item => item.type === "Negotiation")?.index - 1;

          const indexOfTemplates = transaction_thread
            .slice()
            //  .reverse() // Reverse the array to find the last matching element first
            .findIndex((obj) => obj.hasOwnProperty("template") && obj.type !== "Negotiation");

            const negotiationIndex = transaction_thread
            .slice()
            //  .reverse() // Reverse the array to find the last matching element first
            .findIndex((obj) => obj.hasOwnProperty("template") && obj.type === "Negotiation");

          // response.data.vendors[0].transaction_thread.map()
          if (transaction_thread[indexOfTemplate].type != "Inquiry") {

            const guids = getUniqueGuids(
              response.data.vendors[0].transaction_thread,
              transaction_thread[indexOfTemplate].type
            );
            setFetchedGuids(guids);
          } else {
            setLastTxnInquiryState(true)
          }
          // console.log("guids", guids);
          //validationURL
          const validationURL = quotationValidation(
            inq_id,
            vendor_id,
            transaction_thread[indexOfTemplate].guid,
            transaction_thread.length > 1 ? "Requote" : "Quotation"
          );
          const res = await axios.get(validationURL, configAxios);
          setMainValidationError(res?.data?.error);
          if (res.status === 200) {
            setServiceFileName(transaction_thread[indexOfTemplates]?.service_filename)
            setTransactionApiResponse(response.status);
            setRfqDate(response.data.rfq_date);
            setTransactionItems(response.data.items);
            setTransactionItemSequence(response.data.item_sequence);
            setQuotationJsonData(response.data);
            setReferenceNo(response.data.ref_no);
            setMultipleSolutionFlag(
              response.data.filter_data.multiple_solution
            );
            setValidityDate(response.data.expiry_at);
            setItemSpecific(response.data.item_specific);
            setCategoryId(response.data.categories[0].id);
            setCategoryName(response.data.categories[0].name);
            setCategoryCode(response.data.categories[0].code);
            setMultiplePlantsFlag(response.data.filter_data.multi_plant);
            setSlabFlag(response.data.filter_data.slab);
            setFileSpecificationData(response.data.categories[0].files);
            setCurrencyDetails(response.data.currency);
            setItems(response.data.items);
            setVendorType(response.data.vendors[0].type);
            setInquiryItemsId(response.data.vendors[0].inquiry_item_ids);
            setIndustryDetails(response.data.vendors[0].industry_details);
            setPermissionInquiry(response.data.permission)
            if (indexOfTemplate === 0) {
              setIsLastTxn(true);
            }
            let selectedIndex;
            if (transaction_thread[indexOfTemplate].type === "Negotiation") {
              selectedIndex = indexOfTemplates;
            } else {
              selectedIndex = indexOfTemplate;
            }
            if (negotiationIndex !== null && transaction_thread[negotiationIndex]?.template?.itemize) {
              setLastNegotiationData(transaction_thread[negotiationIndex].template.itemize);
            } else {
                // Handle the case where negotiationIndex is null
              setLastNegotiationData(null);
            }

            if (response.data.filter_data.multiple_solution && !response.data.filter_data.slab){
              setLastTransactionInThread(
                transaction_thread[selectedIndex].template.itemize
              );
            } else {
              setLastTransactionInThread(
                transaction_thread[indexOfTemplate].template.itemize
              );
            }
           
            setLastTransactionInThreadCommon(
              transaction_thread[indexOfTemplate].template.common
            );
            setLastTransactionTypeInThread(
              transaction_thread[indexOfTemplate].type
            );
            setLastTransactionGuidInThread(
              transaction_thread[indexOfTemplate].guid
            );
            setIsLoading(false);
            setQuotationDataLoading(false);
            setQuotedCurrency(response.data.vendors[0].quoted_currency);
          }
        }
      } catch (err) {
        console.log("Fetch Error:", err);
        setIsLoading(false);
        setQuotationDataLoading(false);
        if (err?.response?.data[0]?.code === 1018) {
          setOnlineOfflineRequotesExceeded(true)
        } else {
          setMainValidationError(
            err?.response?.data[0]?.error ||
            err?.response?.data?.error ||
            err?.response?.data.message
          );
        }
        openSnackbar(
          "error",
          err?.message ? err?.message : "Something went wrong!"
        );
        // Handle error
        // setQuotationJsonTemplate("");
      }
    };

    fetchQuotationJSON();
  }, []);

  useEffect(() => {
    const fetchJSONTemplate = async () => {
      if (
        !quotationJsonData ||
        !quotationJsonData.template ||
        !quotationJsonData.template.batch_id ||
        !quotationJsonData.template.id
      ) {
        // Data not ready yet, do nothing
        return;
      }

      // Get Transaction Type
      const transaction_type = quotationJsonData?.vendors[0]?.transaction_thread
        .slice()
        .reverse() // Reverse the array to find the last matching element first
        .find((item) => item?.template && item.type !== "Negotiation")?.type;

      // Set transaction_id based on the type
      let transaction_id;
      if (viewQuote && transaction_type == "Quotation") {
        transaction_id = 3;
      } else if (transaction_type === "Inquiry") {
        transaction_id = 3;
      } else if (
        transaction_type === "Quotation" ||
        transaction_type === "Requote" ||
        transaction_type === "Negotiation"
      ) {
        transaction_id = 5;
      } else {
        // Handle unknown type
        console.error("Unknown type:", transaction_type);
        return;
      }

      // axios.defaults.headers.common["Authorization"] = `Bearer ${sessionID}`;
      const batchID = quotationJsonData.template.batch_id;
      const templateID = quotationJsonData.template.id;
      let templateURL;
      if (process.env.NODE_ENV === "production") {
        templateURL = templateURi(
          userInfo?.division_id,
          companyInfo?.display_name,
          templateID,
          batchID,
          transaction_id + (vendorType === "Local" ? "_L" : "_I")
        );
      } else {
        templateURL = devTemplateURi(
          userInfo?.division_id,
          companyInfo?.display_name,
          templateID,
          batchID,
          transaction_id + (vendorType === "Local" ? "_L" : "_I")
        );
      }
      try {
        setQuotationJsonTemplateLoading(true);
        const response = await axios.get(templateURL, configAxios);
        if (
          response?.data.LOCAL.length == 0 &&
          response?.data.FOREIGN.length == 0
        ) {
          setMainValidationError(
            "Sorry ! This is an invalid transaction. The RFQ you are attempting to quote is for a different country than the one you are registered for."
          );
        }
        setQuotationJsonTemplate(response.data);
        setItemBoxId(response.data.ITEMIZE[0][0].FieldId);
        setQuotationJsonTemplateLoading(false);
      } catch (err) {
        setQuotationJsonTemplateLoading(false);
      }
    };
    if (
      vendorType &&
      Object.keys(userInfo).length > 0 &&
      Object.keys(companyInfo).length > 0
    ) {
      fetchJSONTemplate();
    }
  }, [quotationJsonData, userInfo, companyInfo]); // Adding quotationJsonData to dependency array

  useEffect(() => {
    const fetchBuyerData = async () => {
      try {
        if (quotationJsonData && quotationJsonData.created_by) {
          let url;
          if (queryParams.get("supplier")) {
            url = getBuyers(quotationJsonData?.created_by);
          } else {
            url = getBuyers(quotationJsonData?._internal?.current_user_id);
          }
          const response = await axios.get(url, configAxios);
          setBuyerInfo(response.data);
        }
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    fetchBuyerData();
  }, [quotationJsonData]);

  useEffect(() => {
    const getCurrencyOptions = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const sessionID = queryParams.get("q");
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${sessionID}`;
        const URL = getDropDownCurrencyList;
        const response = await axios.get(URL, configAxios);
        setCurrencyOptions(response.data);
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    const queryParams = new URLSearchParams(window.location.search);
    if (!queryParams.get("TLGUID")) {
      getCurrencyOptions();
    }
  }, []);

  useEffect(() => {
    axios
    .get(getAllowMultipleSolutionOptionLabel,configAxios)
    .then((response) => {
      setAlternativeNames(response.data.options)
      // this.setState({
      //   label: response.data.options?.alternate_options,
      // });
    })
    .catch((err) => console.log(err));
  }, []);

  // Inside your QuotationDetails component

  useEffect(() => {
    if (
      quotationJsonData &&
      quotationJsonData.vendors &&
      quotationJsonData.vendors.length > 0
    ) {
      const transactionThread = quotationJsonData.vendors[0].transaction_thread;

      if (transactionThread && transactionThread.length > 0) {
        const lastNonNegotiationTransaction = transactionThread
          .slice()
          .reverse()
          .find((transaction) => transaction.type !== "Negotiation");

        if (
          lastNonNegotiationTransaction &&
          lastNonNegotiationTransaction.template?.itemize
        ) {
          setLastTransactionData(lastNonNegotiationTransaction.template);
          setCurrentTransaction(lastNonNegotiationTransaction);
          setTransactionType(lastNonNegotiationTransaction.type);
        }
      }
    }
  }, [quotationJsonData]);

  useEffect(() => {
    const fetchDestinationDetails = async () => {
      try {
        const url = getDestinations;
        const response = await axios.get(url, configAxios);
        setDestinationDetails(response.data);
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    fetchDestinationDetails();
  }, []);

  useEffect(() => {
    const fetchTermsAndConditionsDetails = async () => {
      try {
        const url = getTermsAndConditionsDetails(categoryId);
        const response = await axios.get(url, configAxios);
        setTermsAndConditionsDetails(response.data.data);
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    if (categoryId) {
      fetchTermsAndConditionsDetails();
    }
  }, [categoryId]);

  const filterData = () => {
    const filtered = Object.fromEntries(
      Object.entries(lastTransactionData?.itemize).filter(
        ([key, value]) => value.selected === "N"
      )
    );
    setFilteredSelectedNotData(filtered);
  };

  useEffect(() => {
    if (lastTransactionData && lastTransactionData.itemize) {
      filterData();
    }
  }, [lastTransactionData]);

  useEffect(() => {
    if (viewQuote == false && featurePermission?.max_online_offline_requotes?.feature_value === "Y") {
      if (
        quotationJsonData &&
        quotationJsonData.vendors &&
        quotationJsonData.vendors.length > 0
      ) {
        let transactionThread = quotationJsonData.vendors[0].transaction_thread;
        let requoteCount = transactionThread.filter(item => (item.type === "Requote" && item.r2a_flag === "N")).length
        if (quotationJsonData.vendors[0].transaction_thread.length > 1 && featurePermission.max_online_offline_requotes.feature_permission_value - 1 === requoteCount) {
          setIsLastOnlineOfflineRequotes(true);
        }
        if (featurePermission.max_online_offline_requotes.feature_permission_value <= requoteCount) {
          setOnlineOfflineRequotesExceeded(true);
        }
      }
    }
  }, [featurePermission, viewQuote, quotationJsonData])
  useEffect(() => {
    const getFeaturePermission = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const sessionID = queryParams.get("q");
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${sessionID}`;
        const URL = feature_permission;
        const response = await axios.get(URL, configAxios);
        setFeaturePermissionDetails(
          response.data.data.offline_quote_requote_date_and_attachment
            .feature_value
        );
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    if (transactionApiResponse === 200) {
      // getFeaturePermission();
    }
  }, [quotationJsonData]);

  //Start Quotation Detail popover js
  const [popperAnchorEl, setPopperAnchorEl] = React.useState(null);
  const [buyerPopperAnchorEl, setBuyerPopperAnchorEl] = React.useState(null);

  const popperClick = (event) => {
    setPopperAnchorEl(popperAnchorEl ? null : event.currentTarget);
  };
  const buyerPopperClick = (event) => {
    setBuyerPopperAnchorEl(buyerPopperAnchorEl ? null : event.currentTarget);
  };

  const popperOpen = Boolean(popperAnchorEl);
  const buyerPopperOpen = Boolean(buyerPopperAnchorEl);
  const popperId = popperOpen ? "true" : undefined;
  const buyerPopperId = buyerPopperOpen ? "true" : undefined;

  const [arrowRef, setArrowRef] = useState(null);
  const [buyerArrowRef, setBuyerArrowRef] = useState(null);

  const [openEmailList, setOpenEmailList] = React.useState(false);
  const [openPhoneList, setOpenPhoneList] = React.useState(false);
  const EmailListClick = () => {
    setOpenEmailList(!openEmailList);
  };
  const PhoneListClick = (type) => {
    // setOpenPhoneList((prev) => ({ ...prev, [type]: !openPhoneList }));
    setOpenPhoneList((prev) => ({
      ...prev,
      [type]: !prev[type], // Toggle the value for the given type
    }));
  };
  //End Quotation Detail popover js
  function stringAvatar(name = "") {
    const value = name.split(" ");
    return name ? `${value[0][0]}${value[1] ? value?.[1][0] : ""}` : "";
  }

  const handleCopy = (content) => {
    // const email = quotationJsonData.vendors?.[0].contact_person_details?.email;
    if (content) {
      const textarea = document.createElement("textarea");
      textarea.value = content;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000); // Reset copied state after 3 seconds
    }
  };

  // Start Mobile view details section collapsible
  const [expandedDetails, setExpandedDetails] = React.useState(false);

  const isDesktopDetails = useMediaQuery("(min-width: 600px)");

  const detailsExpandClick = () => {
    setExpandedDetails((prevOpen) => !prevOpen);
  };

  const handleRemoveIframe = () => {
    window.parent.location.reload()
  };

  const CloseSendMailDrawer = () => {
    setOpenSendMailDrawer(false);
  };

  const handleSendMailDrawer = (event) => {
    event.stopPropagation();
    setOpenSendMailDrawer(true);
  }

  // End Mobile view details section collapsible
  return onlineOfflineRequotesExceeded ? (

    <Box
      className="requote-limit-msg"
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      height={"100vh"}
      maxWidth={"100%"}
      textAlign={"center"}
    >
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gap={1} width={"330px"} margin={"0 auto"}>
        <RquoteLimitMsgIcon sx={{ width: "167px", height: "75px" }} />
        <Typography
          variant="subtitle1"
          color={"text.primary"}
          fontWeight={400}
          lineHeight={"normal"}
          fontSize={"18px"}
        >
          Maximum number of <Box component="span" fontWeight='500'>Requote</Box> limit has been exceeded.
        </Typography>
      </Box>
    </Box>
  ) : (!mainValidationError ? (
    <Fragment>
      {QuotationFormStyle}
      <Backdrop
        style={{ color: "#fff", zIndex: "10000000000" }}
        open={isLoading || quotationJsonTemplateLoading || quotationDataLoading}
      >
        <CircularProgress style={{ color: "#fff" }} />
      </Backdrop>
      <main
        sx={{
          width: "100%",
          maxWidth: "100%",
          padding: 0,
        }}
      >
        <PageToolbar className="datagrid-PageToolbar head-datagrid-toolbar">
          <Stack direction="row" spacing="2" alignItems="center">
            <Box
              sx={{
                display: "flex",
                alignItems: { xs: "center", md: "start" },
              }}
            >
              {queryParams.get("supplier") && <Tooltip title="Back">
                <IconButton
                  aria-label="arrow"
                  size="small"
                  sx={{
                    mr: 1,
                    p: 0,
                    color: "var(--grey-900)",
                  }}
                  onClick={handleRemoveIframe} // Add onClick handler here

                >
                  <KeyboardArrowLeftIcon fontSize="medium" />
                </IconButton>
              </Tooltip>}
              <Box
                sx={{
                  marginTop: {
                    xs: "0",
                    md: "-4px",
                  },
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  {viewQuote ? (
                    <Typography
                      variant="subtitle2"
                      color="secondary.main"
                      noWrap
                    >
                      {" "}
                      {CurrentTransaction.type &&
                        `${CurrentTransaction?.type} Details`}
                    </Typography>
                  ) : (
                    quotationJsonData &&
                    quotationJsonData.vendors &&
                    quotationJsonData.vendors[0] &&
                    quotationJsonData.vendors[0].transaction_thread && (
                      <Typography variant="subtitle2" color="secondary.main">
                        Submit&nbsp;
                        {quotationJsonData.vendors[0].transaction_thread
                          .length > 1
                          ? "Requote"
                          : "Quotation"}
                      </Typography> //check condition
                    )
                  )}
                  <Divider
                    orientation="vertical"
                    sx={{ height: "12px", display: { xs: "none", md: "flex" } }}
                  />
                  <Typography
                    variant="body2"
                    color="secondary.main"
                    sx={{ display: { xs: "none", md: "flex" } }}
                  >
                    {quotationJsonData?.ref_no}
                  </Typography>
                  <Divider
                    orientation="vertical"
                    sx={{ height: "12px", display: { xs: "none", md: "flex" } }}
                  />
                  <Box
                    sx={{
                      display: { xs: "none", md: "flex" },
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    {quotationJsonData &&
                      quotationJsonData?.vendors &&
                      quotationJsonData?.vendors[0] &&
                      quotationJsonData?.vendors[0]?.transaction_thread && (
                        <>
                          {quotationJsonData?.vendors[0]?.transaction_thread.map(
                            (item, index) => {
                              if (
                                item?.template &&
                                index ===
                                quotationJsonData?.vendors[0]
                                  ?.transaction_thread.length -
                                1
                              ) {
                                return (
                                  <React.Fragment key={index}>
                                    <Typography
                                      variant="body2"
                                      color="grey.500"
                                      component="label"
                                    >
                                      {item?.no && "Last Quote No :"}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      {item?.no}
                                    </Typography>
                                  </React.Fragment>
                                );
                              } else {
                                return null; // Return null for items that don't satisfy the condition
                              }
                            }
                          )}
                        </>
                      )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <Typography
                    variant="body2"
                    color="grey.500"
                    component="label"
                    noWrap
                  >
                    Category Name :{" "}
                  </Typography>
                  <Tooltip title={categoryName}>
                    <Typography
                      variant="body2"
                      color="text.primary"
                      sx={{ maxWidth: "220px" }}
                      noWrap
                    >
                      {categoryName}
                    </Typography>
                  </Tooltip>

                  <Divider orientation="vertical" sx={{ height: "12px" }} />

                  <Typography
                    variant="body2"
                    color="grey.500"
                    component="label"
                    noWrap
                  >
                    Category Code :{" "}
                  </Typography>
                  <Tooltip title={categoryCode}>
                    <Typography
                      variant="body2"
                      color="text.primary"
                      sx={{ maxWidth: "120px" }}
                      noWrap
                    >
                      {categoryCode}
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
            </Box>

            <Box sx={{ ml: "auto !important" }}>
              <List
                sx={{
                  display: "flex",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                  gap: "4px",
                  justifyContent: "right",
                  p: 0,
                  "& .MuiListItem-root": {
                    p: 0,
                  },
                  flexWrap: { xs: "wrap", sm: "inherit" },
                  "&.MuiList-root .MuiListItem-root:not(:first-of-type)": {
                    flex: "0 0 36px",
                  },
                }}
              >
                <ListItem
                  sx={{
                    justifyContent: "right",
                  }}
                >
                  <Box className="GoogleTranslate-Box">
                    <GoogleTranslate />
                  </Box>
                </ListItem>
                {accountModulePermission?.['VendX Connect']?.module_activated == 'Y' && (
                  <ListItem>
                    <Tooltip title="Vendx Connect">
                      <IconButton aria-label="" size="small">
                        <img
                          src={DataTransferIcon}
                          width="24"
                          onClick={openCommunication}
                        />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                )}
                {userInfo.type === "buyer" && (
                  <ListItem>
                    <Tooltip title="Send Mail">
                      <IconButton aria-label="" size="small">
                        <MailIcon
                          onClick={(e) => handleSendMailDrawer(e)}
                          sx={{ color: "secondary.600" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                )}
                <ListItem>
                  <Tooltip title="Transaction Thread">
                    <IconButton aria-label="QuotationThread" size="small">
                      <QuotationThread
                        onClick={QuotationThreadOpen("right", true)}
                      />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              </List>
            </Box>
          </Stack>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={tabValue}
              onChange={handleChangeTabPosition}
              aria-label=""
              sx={{
                "&.MuiTabs-root .MuiTabs-scroller": {
                  overflowX: {
                    xs: "auto !important",
                  },
                },
                "&": {
                  minHeight: "34px",
                  ".MuiButtonBase-root": {
                    minHeight: "34px",
                    padding: {
                      sm: "12px 16px",
                      xs: "12px 12px",
                    },
                  },
                },
              }}
            >
              <Tab
                value="one"
                label="Quotation Details"
                onClick={() => scrollToSection("QuotationDetails")}
              />
              <Tab
                value="two"
                label="Template"
                onClick={() => scrollToSection("Template")}
              />
              <Tab
                value="three"
                label="Additional Info"
                onClick={() => scrollToSection("AdditionalInfo")}
              />
            </Tabs>
          </Box>
        </PageToolbar>
        <Box sx={{ p: 2, pb: 0 }}>
          <Alert
            severity="warning"
            className="LanguageSelect-Alert"
            sx={{
              mb: 2,
              "&.LanguageSelect-Alert": {
                display: "none",
              },
            }}
            icon={<ErrorIcon fontSize="inherit" />}
          >
            We are not responsible for correctness of translation as we are
            using third party services.
          </Alert>

          {isLastOnlineOfflineRequotes && (<Alert
            severity="warning"
            className="LanguageSelect-Alert"
            sx={{
              mb: 2,
              "&.LanguageSelect-Alert": {
              },
            }}
            icon={<ErrorIcon fontSize="inherit" />}
          >
            "This is your last submission for the re-quotation. Please review all details carefully and complete the form accurately."
          </Alert>)}

          {/* Start Quotation Details Section HTML */}
          {Object.keys(quotationJsonData).length > 0 &&
            Object.keys(buyerInfo).length > 0 && (
              <Card variant="outlined" sx={{ mb: 2 }}>
                <CardHeader
                  title={
                    <Typography
                      variant="body2"
                      color={"grey.900"}
                      fontWeight={500}
                      textTransform="uppercase"
                      component="h5"
                    >
                      Details
                    </Typography>
                  }
                  action={
                    <IconButton
                      expand={expandedDetails}
                      onClick={detailsExpandClick}
                      aria-label="Details"
                      size="small"
                      sx={{
                        display: { xs: "inline-flex", sm: "none" },
                        p: 0,
                      }}
                    >
                      {expandedDetails ? <ExpandMore /> : <ExpandLess />}
                    </IconButton>
                  }
                  titleTypographyProps={{
                    variant: "subtitle2",
                    component: "h5",
                    color: "grey.900",
                    textTransform: "uppercase",
                  }}
                  sx={{
                    minHeight: "50px",
                    "& .MuiCardHeader-action": {
                      marginRight: 0,
                      marginTop: 0,
                    },
                  }}
                />
                <Collapse
                  in={isDesktopDetails ? true : expandedDetails}
                  timeout="auto"
                  unmountOnExit
                >
                  <Divider sx={{ marginBottom: "0" }} />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xl={2} lg={3} md={3} sm={6} xs={12}>
                        <Typography
                          component="label"
                          variant="body2"
                          color={"text.primary"}
                          fontWeight={500}
                        >
                          Vendor Details
                        </Typography>
                        <Card
                          elevation={0}
                          sx={{ lineHeight: 0, marginTop: "6px" }}
                        >
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  width: 32,
                                  height: 32,
                                  fontSize: "12px",
                                  bgcolor: "primary.500",
                                }}
                                aria-label=""
                              >
                                {stringAvatar(
                                  quotationJsonData.vendors?.[0]?.name
                                )}
                              </Avatar>
                            }
                            title={
                              <Box display="flex" alignItems="center" gap="4px">
                                <Tooltip
                                  title={quotationJsonData.vendors?.[0]?.name}
                                >
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                    fontWeight={500}
                                    mb={0}
                                    color={"text.primary"}
                                    sx={{ fontSize: "14px !important" }}
                                    noWrap
                                  >
                                    {quotationJsonData.vendors?.[0]?.name}
                                  </Typography>
                                </Tooltip>
                                <IconButton
                                  aria-label="Arrow Down"
                                  size="small"
                                  sx={{ p: 0 }}
                                  onClick={popperClick}
                                >
                                  {popperOpen ? <ExpandMore /> : <ExpandLess />}
                                </IconButton>
                                <Popper
                                  id={popperId}
                                  open={popperOpen}
                                  anchorEl={popperAnchorEl}
                                  placement="bottom-start"
                                  disablePortal={true}
                                  modifiers={[
                                    {
                                      name: "flip",
                                      enabled: true,
                                      options: {
                                        altBoundary: true,
                                        rootBoundary: "document",
                                        padding: 8,
                                      },
                                    },
                                    {
                                      name: "preventOverflow",
                                      enabled: true,
                                      options: {
                                        altAxis: false,
                                        altBoundary: false,
                                        tether: false,
                                        rootBoundary: "document",
                                        padding: 8,
                                      },
                                    },
                                    {
                                      name: "arrow",
                                      enabled: true,
                                      options: {
                                        element: buyerArrowRef,
                                      },
                                    },
                                  ]}
                                  sx={{
                                    zIndex: "99",
                                  }}
                                >
                                  <Box
                                    component="span"
                                    className="popper-arrow"
                                    ref={setBuyerArrowRef}
                                  />
                                  <Paper
                                    elevation={3}
                                    sx={{
                                      "&": {
                                        width: "290px",
                                        height: "400px",
                                        position: "relative",
                                        zIndex: "1",
                                        overflowY: "auto",
                                      },
                                    }}
                                  >
                                    <Typography
                                      sx={{ padding: "8px 16px" }}
                                      variant="body2"
                                      component="h6"
                                      fontWeight={500}
                                      color="Secondary.900"
                                    >
                                      Contact Person
                                    </Typography>
                                    <Divider />
                                    <PopoverDetailList>
                                      <ListItem>
                                        <ListItemIcon>
                                          <PersonIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Contact Person"
                                          secondary={
                                            quotationJsonData.vendors?.[0]
                                              .contact_person_details?.name
                                              ? quotationJsonData.vendors?.[0]
                                                .contact_person_details?.name
                                              : "N/A"
                                          }
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemIcon>
                                          <EmailIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Mail I’D"
                                          secondary={
                                            <>
                                              <Box
                                                display="flex"
                                                gap="8px"
                                                className="EmailId-Info"
                                              >
                                                <Tooltip
                                                  title={
                                                    quotationJsonData
                                                      .vendors?.[0]
                                                      .contact_person_details
                                                      ?.email &&
                                                    quotationJsonData
                                                      .vendors?.[0]
                                                      .contact_person_details
                                                      ?.email
                                                  }
                                                >
                                                  {quotationJsonData
                                                    .vendors?.[0]
                                                    .contact_person_details
                                                    ?.email ? (
                                                    <Link
                                                      href={`mailto:${quotationJsonData
                                                        .vendors?.[0]
                                                        .contact_person_details
                                                        ?.email &&
                                                        quotationJsonData
                                                          .vendors?.[0]
                                                          .contact_person_details
                                                          ?.email
                                                        }`}
                                                      display="block"
                                                      underline="none"
                                                      noWrap
                                                    >
                                                      {quotationJsonData
                                                        .vendors?.[0]
                                                        .contact_person_details
                                                        ?.email
                                                        ? quotationJsonData
                                                          .vendors?.[0]
                                                          .contact_person_details
                                                          ?.email
                                                        : "N/A"}
                                                    </Link>
                                                  ) : (
                                                    <Link
                                                      display="block"
                                                      underline="none"
                                                      noWrap
                                                    >
                                                      N/A
                                                    </Link>
                                                  )}
                                                </Tooltip>
                                                <Tooltip title="Copy">
                                                  <IconButton
                                                    aria-label="Arrow Down"
                                                    color="primary"
                                                    size="small"
                                                    className="CopyIcon"
                                                    sx={{ p: 0 }}
                                                    onClick={() => {
                                                      handleCopy(
                                                        quotationJsonData
                                                          .vendors?.[0]
                                                          .contact_person_details
                                                          ?.email || "N/A"
                                                      );
                                                    }}
                                                  >
                                                    <ContentCopyIcon
                                                      sx={{ fontSize: 18 }}
                                                    />
                                                  </IconButton>
                                                </Tooltip>
                                                {/* {copied && <span style={{ color: "green" }}>Copied!</span>} */}
                                              </Box>
                                            </>
                                          }
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemIcon>
                                          <LocalPhoneIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Phone No"
                                          secondary={
                                            quotationJsonData.vendors?.[0]
                                              .contact_person_details?.phone
                                              ? quotationJsonData.vendors?.[0]
                                                .contact_person_details?.phone
                                              : "N/A"
                                          }
                                        />
                                      </ListItem>
                                    </PopoverDetailList>
                                    <Divider />
                                    <Typography
                                      sx={{ padding: "8px 16px" }}
                                      variant="body2"
                                      component="h6"
                                      fontWeight={500}
                                      color="Secondary.900"
                                    >
                                      Vendor Info
                                    </Typography>
                                    <Divider />
                                    <PopoverDetailList>
                                      <ListItem alignItems="flex-start">
                                        <ListItemIcon>
                                          <EmailIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Mail I’D"
                                          secondary={
                                            <>
                                              <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                gap="8px"
                                                className="EmailId-Info"
                                              >
                                                <Box
                                                  display="flex"
                                                  justifyContent="space-between"
                                                  gap="8px"
                                                  sx={{
                                                    width: "calc(100% - 25px)",
                                                  }}
                                                >
                                                  <Tooltip
                                                    title={
                                                      quotationJsonData
                                                        .vendors?.[0].email
                                                        ? quotationJsonData.vendors?.[0].email.split(
                                                          ","
                                                        )[0]
                                                        : "N/A"
                                                    }
                                                  >
                                                    {quotationJsonData
                                                      .vendors?.[0].email !=
                                                      null ? (
                                                      <Link
                                                        href={`mailto:${quotationJsonData.vendors?.[0].email.split(
                                                          ","
                                                        )[0]
                                                          }`}
                                                        display="block"
                                                        underline="none"
                                                        noWrap
                                                      >
                                                        {
                                                          quotationJsonData.vendors?.[0].email.split(
                                                            ","
                                                          )[0]
                                                        }
                                                      </Link>
                                                    ) : (
                                                      <Link
                                                        display="block"
                                                        underline="none"
                                                        noWrap
                                                      >
                                                        N/A
                                                      </Link>
                                                    )}
                                                  </Tooltip>
                                                  <Tooltip title="Copy">
                                                    <IconButton
                                                      aria-label="Arrow Down"
                                                      color="primary"
                                                      size="small"
                                                      className="CopyIcon"
                                                      sx={{ p: 0 }}
                                                      onClick={() => {
                                                        handleCopy(
                                                          quotationJsonData.vendors?.[0].email.split(
                                                            ","
                                                          )[0] || "N/A"
                                                        );
                                                      }}
                                                    >
                                                      <ContentCopyIcon
                                                        sx={{ fontSize: 18 }}
                                                      />
                                                    </IconButton>
                                                  </Tooltip>
                                                  {/* {copied && <span style={{ color: "green" }}>Copied!</span>} */}
                                                </Box>
                                                {quotationJsonData.vendors?.[0].email.split(
                                                  ","
                                                ).length > 1 && (
                                                    <IconButton
                                                      aria-label="Arrow Down"
                                                      size="small"
                                                      sx={{ p: 0 }}
                                                      onClick={EmailListClick}
                                                    >
                                                      {openEmailList ? (
                                                        <ExpandMore fontSize="small" />
                                                      ) : (
                                                        <ExpandLess fontSize="small" />
                                                      )}
                                                    </IconButton>
                                                  )}
                                              </Box>
                                              {quotationJsonData.vendors?.[0].email.split(
                                                ","
                                              ).length > 1 && (
                                                  <Collapse
                                                    in={openEmailList}
                                                    timeout="auto"
                                                    unmountOnExit
                                                  >
                                                    <List disablePadding>
                                                      {quotationJsonData.vendors?.[0].email
                                                        .split(",")
                                                        .slice(1)
                                                        .map((email) => (
                                                          <ListItem
                                                            disableGutters
                                                          >
                                                            <ListItemText
                                                              primary={
                                                                <Box
                                                                  display="flex"
                                                                  gap="8px"
                                                                  className="EmailId-Info"
                                                                >
                                                                  <Tooltip title={email}>
                                                                    <Link
                                                                      href={`mailto:${email}`}
                                                                      display="block"
                                                                      underline="none"
                                                                      noWrap
                                                                    >
                                                                      {email}
                                                                    </Link>
                                                                  </Tooltip>
                                                                  <Tooltip title="Copy">
                                                                    <IconButton
                                                                      aria-label="Arrow Down"
                                                                      color="primary"
                                                                      size="small"
                                                                      className="CopyIcon"
                                                                      sx={{
                                                                        p: 0,
                                                                      }}
                                                                      onClick={() => {
                                                                        handleCopy(
                                                                          email ||
                                                                          "N/A"
                                                                        );
                                                                      }}
                                                                    >
                                                                      <ContentCopyIcon
                                                                        sx={{
                                                                          fontSize: 18,
                                                                        }}
                                                                      />
                                                                    </IconButton>
                                                                  </Tooltip>
                                                                </Box>
                                                              }
                                                            />
                                                          </ListItem>
                                                        ))}
                                                    </List>
                                                  </Collapse>
                                                )}
                                            </>
                                          }
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemIcon>
                                          <LocalPhoneIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Phone No"
                                          secondary={
                                            <>
                                              <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                gap="8px"
                                                className="EmailId-Info"
                                              >
                                                <Box
                                                  display="flex"
                                                  justifyContent="space-between"
                                                  gap="8px"
                                                  sx={{
                                                    width: "calc(100% - 25px)",
                                                  }}
                                                >
                                                  <Tooltip
                                                    title={
                                                      quotationJsonData?.vendors[0]?.phone?.split(
                                                        ","
                                                      )[0] || "N/A"
                                                    }
                                                  >
                                                    {quotationJsonData
                                                      .vendors[0]?.phone
                                                      ? quotationJsonData?.vendors[0]?.phone?.split(
                                                        ","
                                                      )[0]
                                                      : " N/A"}
                                                  </Tooltip>
                                                </Box>
                                                {quotationJsonData?.vendors?.[0]?.phone?.split(
                                                  ","
                                                ).length > 1 && (
                                                    <IconButton
                                                      aria-label="Arrow Down"
                                                      size="small"
                                                      sx={{ p: 0 }}
                                                      onClick={() => {
                                                        PhoneListClick("Vendor");
                                                      }}
                                                    >
                                                      {openPhoneList.Vendor ? (
                                                        <ExpandMore fontSize="small" />
                                                      ) : (
                                                        <ExpandLess fontSize="small" />
                                                      )}
                                                    </IconButton>
                                                  )}
                                              </Box>
                                              {quotationJsonData?.vendors?.[0]?.phone?.split(
                                                ","
                                              ).length > 1 && (
                                                  <Collapse
                                                    in={openPhoneList.Vendor}
                                                    timeout="auto"
                                                    unmountOnExit
                                                  >
                                                    <List disablePadding>
                                                      {quotationJsonData.vendors?.[0]?.phone
                                                        .split(",")
                                                        .slice(1)
                                                        .map((email) => (
                                                          <ListItem
                                                            disableGutters
                                                          >
                                                            <ListItemText
                                                              primary={
                                                                <Box
                                                                  display="flex"
                                                                  gap="8px"
                                                                  className="EmailId-Info"
                                                                >
                                                                  <Tooltip
                                                                    title={email}
                                                                  >
                                                                    {email}
                                                                  </Tooltip>
                                                                </Box>
                                                              }
                                                            />
                                                          </ListItem>
                                                        ))}
                                                    </List>
                                                  </Collapse>
                                                )}
                                            </>
                                          }
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemIcon>
                                          <ConstructionIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Industry Type"
                                          secondary={
                                            quotationJsonData.vendors?.[0]
                                              .industry_details?.type
                                              ? quotationJsonData.vendors?.[0]
                                                .industry_details?.type
                                              : "N/A"
                                          }
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemIcon>
                                          <PersonIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Sender"
                                          secondary={
                                            quotationJsonData.vendors?.[0]
                                              ?.sender || "N/A"
                                          }
                                        />
                                      </ListItem>
                                      <ListItem alignItems="flex-start">
                                        <ListItemIcon>
                                          <NavigationIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Address"
                                          secondary={
                                            quotationJsonData.vendors?.[0]
                                              .address || "N/A"
                                          }
                                        />
                                      </ListItem>
                                      <ListItem alignItems="flex-start">
                                        <ListItemIcon>
                                          <Public fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Country"
                                          secondary={
                                            quotationJsonData.vendors?.[0]
                                              .country || "N/A"
                                          }
                                        />
                                      </ListItem>
                                    </PopoverDetailList>
                                  </Paper>
                                </Popper>
                              </Box>
                            }
                            subheader={
                              <Typography
                                gutterBottom
                                variant="caption"
                                color={"grey.500"}
                                sx={{ fontSize: "12px !important" }}
                              >
                                {quotationJsonData.vendors?.[0]?.code}
                              </Typography>
                            }
                            sx={{
                              p: 0,
                              "& .MuiCardHeader-content": {
                                maxWidth: "calc(100% - 60px)",
                                flex: "0 0 auto",
                              },
                              "& .MuiCardHeader-action": {
                                m: 0,
                              },
                              "& .MuiCardHeader-avatar": {
                                mr: 1,
                              },
                            }}
                          />
                        </Card>
                      </Grid>
                      <Grid item xl={2} lg={3} md={3} sm={6} xs={12}>
                        <Typography
                          component="label"
                          variant="body2"
                          color={"text.primary"}
                          fontWeight={500}
                        >
                          Buyer Details
                        </Typography>
                        <Card
                          elevation={0}
                          sx={{ lineHeight: 0, marginTop: "6px" }}
                        >
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  width: 32,
                                  height: 32,
                                  fontSize: "12px",
                                  bgcolor: "primary.500",
                                }}
                                aria-label=""
                              >
                                {stringAvatar(buyerInfo?.name)}
                              </Avatar>
                            }
                            title={
                              <Box display="flex" alignItems="center" gap="4px">
                                <Tooltip title={buyerInfo?.name}>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                    fontWeight={500}
                                    mb={0}
                                    color={"text.primary"}
                                    sx={{ fontSize: "14px !important" }}
                                    noWrap
                                  >
                                    {buyerInfo?.name}
                                  </Typography>
                                </Tooltip>
                                <IconButton
                                  aria-label="Arrow Down"
                                  size="small"
                                  sx={{ p: 0 }}
                                  onClick={buyerPopperClick}
                                >
                                  {buyerPopperOpen ? (
                                    <ExpandMore />
                                  ) : (
                                    <ExpandLess />
                                  )}
                                </IconButton>
                                <Popper
                                  id={buyerPopperId}
                                  open={buyerPopperOpen}
                                  anchorEl={buyerPopperAnchorEl}
                                  placement="bottom-start"
                                  disablePortal={true}
                                  modifiers={[
                                    {
                                      name: "flip",
                                      enabled: true,
                                      options: {
                                        altBoundary: true,
                                        rootBoundary: "document",
                                        padding: 8,
                                      },
                                    },
                                    {
                                      name: "preventOverflow",
                                      enabled: true,
                                      options: {
                                        altAxis: false,
                                        altBoundary: false,
                                        tether: false,
                                        rootBoundary: "document",
                                        padding: 8,
                                      },
                                    },
                                    {
                                      name: "arrow",
                                      enabled: true,
                                      options: {
                                        element: arrowRef,
                                      },
                                    },
                                  ]}
                                  sx={{
                                    zIndex: "99",
                                  }}
                                >
                                  <Box
                                    component="span"
                                    className="popper-arrow"
                                    ref={setArrowRef}
                                  />
                                  <Paper
                                    elevation={3}
                                    sx={{
                                      "&": {
                                        width: "290px",
                                        height: "400px",
                                        position: "relative",
                                        zIndex: "1",
                                        overflowY: "auto",
                                      },
                                    }}
                                  >
                                    <Typography
                                      sx={{ padding: "8px 16px" }}
                                      variant="body2"
                                      component="h6"
                                      fontWeight={500}
                                      color="Secondary.900"
                                    >
                                      Buyer Info
                                    </Typography>
                                    <Divider />
                                    <PopoverDetailList>
                                      <ListItem alignItems="flex-start">
                                        <ListItemIcon>
                                          <EmailIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Mail I’D"
                                          secondary={
                                            <>
                                              <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                gap="8px"
                                                className="EmailId-Info"
                                              >
                                                <Box
                                                  display="flex"
                                                  justifyContent="space-between"
                                                  gap="8px"
                                                  sx={{
                                                    width: "calc(100% - 25px)",
                                                  }}
                                                >
                                                  <Tooltip
                                                    title={
                                                      buyerInfo?.email.split(
                                                        ","
                                                      )[0] || "N/A"
                                                    }
                                                  >
                                                    {buyerInfo?.email ? (
                                                      <Link
                                                        href={`mailto:${buyerInfo?.email.split(
                                                          ","
                                                        )[0]
                                                          }`}
                                                        display="block"
                                                        underline="none"
                                                        noWrap
                                                      >
                                                        {
                                                          buyerInfo?.email.split(
                                                            ","
                                                          )[0]
                                                        }
                                                      </Link>
                                                    ) : (
                                                      <Link
                                                        display="block"
                                                        underline="none"
                                                        noWrap
                                                      >
                                                        N/A
                                                      </Link>
                                                    )}
                                                  </Tooltip>
                                                  <Tooltip title="Copy">
                                                    <IconButton
                                                      aria-label="Arrow Down"
                                                      color="primary"
                                                      size="small"
                                                      className="CopyIcon"
                                                      sx={{ p: 0 }}
                                                      onClick={() => {
                                                        handleCopy(
                                                          buyerInfo?.email.split(
                                                            ","
                                                          )[0] || "N/A"
                                                        );
                                                      }}
                                                    >
                                                      <ContentCopyIcon
                                                        sx={{ fontSize: 18 }}
                                                      />
                                                    </IconButton>
                                                  </Tooltip>
                                                </Box>
                                                {buyerInfo?.email.split(",")
                                                  .length > 1 && (
                                                    <IconButton
                                                      aria-label="Arrow Down"
                                                      size="small"
                                                      sx={{ p: 0 }}
                                                      onClick={EmailListClick}
                                                    >
                                                      {openEmailList ? (
                                                        <ExpandMore fontSize="small" />
                                                      ) : (
                                                        <ExpandLess fontSize="small" />
                                                      )}
                                                    </IconButton>
                                                  )}
                                              </Box>
                                              {buyerInfo?.email.split(",")
                                                .length > 1 && (
                                                  <Collapse
                                                    in={openEmailList}
                                                    timeout="auto"
                                                    unmountOnExit
                                                  >
                                                    <List disablePadding>
                                                      {buyerInfo?.email
                                                        .split(",")
                                                        .slice(1)
                                                        .map((email) => (
                                                          <ListItem
                                                            disableGutters
                                                          >
                                                            <ListItemText
                                                              primary={
                                                                <Box
                                                                  display="flex"
                                                                  gap="8px"
                                                                  className="EmailId-Info"
                                                                >
                                                                  <Tooltip
                                                                    title={email}
                                                                  >
                                                                    <Link
                                                                      href={`mailto:${email}`}
                                                                      display="block"
                                                                      underline="none"
                                                                      noWrap
                                                                    >
                                                                      {email}
                                                                    </Link>
                                                                  </Tooltip>
                                                                  <Tooltip title="Copy">
                                                                    <IconButton
                                                                      aria-label="Arrow Down"
                                                                      color="primary"
                                                                      size="small"
                                                                      className="CopyIcon"
                                                                      sx={{
                                                                        p: 0,
                                                                      }}
                                                                      onClick={() => {
                                                                        handleCopy(
                                                                          email
                                                                        );
                                                                      }}
                                                                    >
                                                                      <ContentCopyIcon
                                                                        sx={{
                                                                          fontSize: 18,
                                                                        }}
                                                                      />
                                                                    </IconButton>
                                                                  </Tooltip>
                                                                </Box>
                                                              }
                                                            />
                                                          </ListItem>
                                                        ))}
                                                    </List>
                                                  </Collapse>
                                                )}
                                            </>
                                          }
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemIcon>
                                          <LocalPhoneIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Phone No"
                                          secondary={
                                            <>
                                              <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                gap="8px"
                                                className="EmailId-Info"
                                              >
                                                <Box
                                                  display="flex"
                                                  justifyContent="space-between"
                                                  gap="8px"
                                                  sx={{
                                                    width: "calc(100% - 25px)",
                                                  }}
                                                >
                                                  <Tooltip
                                                    title={
                                                      buyerInfo?.phone.split(
                                                        ","
                                                      )[0] || "N/A"
                                                    }
                                                  >
                                                    {buyerInfo?.phone
                                                      ? buyerInfo?.phone.split(
                                                        ","
                                                      )[0]
                                                      : " N/A"}
                                                  </Tooltip>
                                                </Box>
                                                {buyerInfo?.phone.split(",")
                                                  .length > 1 && (
                                                    <IconButton
                                                      aria-label="Arrow Down"
                                                      size="small"
                                                      sx={{ p: 0 }}
                                                      onClick={() => {
                                                        PhoneListClick("Buyer");
                                                      }}
                                                    >
                                                      {openPhoneList.Buyer ? (
                                                        <ExpandMore fontSize="small" />
                                                      ) : (
                                                        <ExpandLess fontSize="small" />
                                                      )}
                                                    </IconButton>
                                                  )}
                                              </Box>
                                              {buyerInfo?.phone.split(",")
                                                .length > 1 && (
                                                  <Collapse
                                                    in={openPhoneList.Buyer}
                                                    timeout="auto"
                                                    unmountOnExit
                                                  >
                                                    <List disablePadding>
                                                      {buyerInfo?.phone
                                                        .split(",")
                                                        .slice(1)
                                                        .map((email) => (
                                                          <ListItem
                                                            disableGutters
                                                          >
                                                            <ListItemText
                                                              secondary={
                                                                <Box
                                                                  display="flex"
                                                                  gap="8px"
                                                                  className="EmailId-Info"
                                                                >
                                                                  <Tooltip
                                                                    title={email}
                                                                  >
                                                                    {email}
                                                                  </Tooltip>
                                                                </Box>
                                                              }
                                                            />
                                                          </ListItem>
                                                        ))}
                                                    </List>
                                                  </Collapse>
                                                )}
                                            </>
                                          }
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemIcon>
                                          <BusinessIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Company Name"
                                          secondary={
                                            buyerInfo?.company_name
                                              ? buyerInfo?.company_name
                                              : "N/A"
                                          }
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemIcon>
                                          <AccountCircleIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Account Name"
                                          secondary={
                                            buyerInfo?.acc_name
                                              ? buyerInfo?.acc_name
                                              : "N/A"
                                          }
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemIcon>
                                          <AccountCircleIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Account Code"
                                          secondary={
                                            buyerInfo?.acc_code
                                              ? buyerInfo?.acc_code
                                              : "N/A"
                                          }
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemIcon>
                                          <WorkOutline fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Designation"
                                          secondary={
                                            buyerInfo?.designation
                                              ? buyerInfo?.designation
                                              : "N/A"
                                          }
                                        />
                                      </ListItem>
                                      <ListItem alignItems="flex-start">
                                        <ListItemIcon>
                                          <NavigationIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Address"
                                          secondary={
                                            buyerInfo?.address
                                              ? buyerInfo?.address
                                              : "N/A"
                                          }
                                        />
                                      </ListItem>
                                      <ListItem alignItems="flex-start">
                                        <ListItemIcon>
                                          <Public fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Country"
                                          secondary={
                                            buyerInfo?.country
                                              ? buyerInfo?.country
                                              : "N/A"
                                          }
                                        />
                                      </ListItem>
                                    </PopoverDetailList>
                                  </Paper>
                                </Popper>
                              </Box>
                            }
                            subheader={
                              <Typography
                                gutterBottom
                                variant="caption"
                                color={"grey.500"}
                                sx={{ fontSize: "12px !important" }}
                              >
                                {buyerInfo?.phone || "N/A"}
                              </Typography>
                            }
                            sx={{
                              p: 0,
                              "& .MuiCardHeader-content": {
                                maxWidth: "calc(100% - 60px)",
                                flex: "0 0 auto",
                              },
                              "& .MuiCardHeader-action": {
                                m: 0,
                              },
                              "& .MuiCardHeader-avatar": {
                                mr: 1,
                              },
                            }}
                          />
                        </Card>
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Typography
                          component="label"
                          variant="body2"
                          color={"text.primary"}
                          fontWeight={500}
                        >
                          RFQ Details
                        </Typography>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={2}
                        >
                          <Box>
                            <Typography
                              component="label"
                              variant="body2"
                              color={"grey.500"}
                            >
                              RFQ Start Date
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.primary"
                              fontWeight={500}
                            >
                              {dayjs(
                                quotationJsonData?.created_at,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format(
                                `DD/MM/YYYY HH:mm:ss [${quotationJsonData?.created_at
                                  ?.split(" ")
                                  .pop()}]`
                              )}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              component="label"
                              variant="body2"
                              color={"grey.500"}
                            >
                              RFQ End Date
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.primary"
                              fontWeight={500}
                            >
                              {dayjs(
                                quotationJsonData?.expiry_at,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format(
                                `DD/MM/YYYY HH:mm:ss [${quotationJsonData?.expiry_at
                                  ?.split(" ")
                                  .pop()}]`
                              )}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              component="label"
                              variant="body2"
                              color={"grey.500"}
                            >
                              RFQ No
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.primary"
                              fontWeight={500}
                            >
                              {quotationJsonData?.ref_no}
                            </Typography>
                          </Box>
                        </Stack>
                      </Grid>

                      {/* Start Header Quotation Details Mobile Responsive UI Display */}
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        sx={{ display: { xs: "block", md: "none" } }}
                      >
                        <Typography
                          component="label"
                          variant="body2"
                          color={"grey.500"}
                        >
                          RFQ No
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.primary"
                          fontWeight={500}
                        >
                          {quotationJsonData?.ref_no}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        sx={{ display: { xs: "block", md: "none" } }}
                      >
                        {quotationJsonData &&
                          quotationJsonData?.vendors &&
                          quotationJsonData?.vendors[0] &&
                          quotationJsonData?.vendors[0]?.transaction_thread && (
                            <>
                              {quotationJsonData?.vendors[0]?.transaction_thread.map(
                                (item, index) => {
                                  if (
                                    item?.template &&
                                    index ===
                                    quotationJsonData?.vendors[0]
                                      ?.transaction_thread.length -
                                    1
                                  ) {
                                    return (
                                      <React.Fragment key={index}>
                                        <Typography
                                          component="label"
                                          variant="body2"
                                          color={"grey.500"}
                                        >
                                          {item?.no && "Last Quote No:"}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          color="text.primary"
                                          fontWeight={500}
                                        >
                                          {item?.no}
                                        </Typography>
                                      </React.Fragment>
                                    );
                                  } else {
                                    return null; // Return null for items that don't satisfy the condition
                                  }
                                }
                              )}
                            </>
                          )}
                      </Grid>
                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={3}
                        sm={6}
                        xs={12}
                        sx={{ display: { xs: "block", md: "none" } }}
                      >
                        <Typography
                          component="label"
                          variant="body2"
                          color={"grey.500"}
                        >
                          Category Name
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.primary"
                          fontWeight={500}
                        >
                          {categoryName}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={3}
                        sm={6}
                        xs={12}
                        sx={{ display: { xs: "block", md: "none" } }}
                      >
                        <Typography
                          component="label"
                          variant="body2"
                          color={"grey.500"}
                        >
                          Category Code{" "}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.primary"
                          fontWeight={500}
                        >
                          {categoryCode}
                        </Typography>
                      </Grid>
                      {/* Start Header Quotation Details Mobile Responsive UI Display */}
                    </Grid>
                  </CardContent>
                </Collapse>
              </Card>
            )}
          {/* End Quotation Details Section HTML */}

          {Object.keys(quotationJsonData).length > 0 &&
            Object.keys(quotationJsonTemplate).length > 0 &&
            (viewQuote == true ? (
              <QuotationTemplateView
                setClick={setClick}
                resetSettingDrawer={props.resetSettingDrawer}
                quotationJsonTemplate={quotationJsonTemplate}
                lastTransactionData={lastTransactionData}
                selectedQuotedItems={quotationJsonData}
                inquiryTransaction={inquiryTransaction}
                quotationJsonTemplateLoading={quotationJsonTemplateLoading}
                props={props}
                quotationFormFlag={quotationFormFlag}
                location={props.location}
                match={props.location}
                destinationDetails={destinationDetails}
                currencyDetails={currencyDetails}
                currencyOptions={currencyOptions}
                lastTransactionInThread={lastTransactionInThread}
                lastTransactionTypeInThread={lastTransactionTypeInThread}
                fileSpecificationData={fileSpecificationData}
                CurrentTransaction={CurrentTransaction}
                filteredSelectedNotData={filteredSelectedNotData}
                termsAndConditionsDetails={termsAndConditionsDetails}
                vendorType={vendorType}
                categoryId={categoryId}
                IsLastTxn={IsLastTxn}
                PrevTransaction={PrevTransaction}
                items={items}
                vendorId={vendorId}
                inquiryItemsId={inquiryItemsId}
                itemSpecific={itemSpecific}
                multiplePlantsFlag={multiplePlantsFlag}
                slabFlag={slabFlag}
                multipleSolutionFlag={multipleSolutionFlag}
                lastTransactionInThreadCommon={lastTransactionInThreadCommon}
                transactionItemSequence={transactionItemSequence}
                quotedCurrency={quotedCurrency}
                PermissionInquiry={PermissionInquiry}
                inquiryId={inquiryId}
                isServiceRfq = {quotationJsonData.template?.type === "service" ? true : false}
                isMultipleCurrency = {quotationJsonData.template?.service_multiple_currency ? true : false}
                AlternativeNames={AlternativeNames}
              />
            ) : (

              (Object.keys(fetchedTxn).length > 0 || lastTxnInquiryState) && <QuotationTemplate
                setClick={setClick}
                resetSettingDrawer={props.resetSettingDrawer}
                quotationJsonTemplate={quotationJsonTemplate}
                lastTransactionData={lastTransactionData}
                lastNegotiationData={lastNegotiationData}
                headerLabels={headerLabels}
                transactionItemSequence={transactionItemSequence}
                selectedQuotedItems={quotationJsonData}
                lastTransactionGuidInThread={lastTransactionGuidInThread}
                quotationJsonTemplateLoading={quotationJsonTemplateLoading}
                props={props}
                quotationFormFlag={quotationFormFlag}
                location={props.location}
                match={props.location}
                destinationDetails={destinationDetails}
                currencyDetails={currencyDetails}
                currencyOptions={currencyOptions}
                lastTransactionInThread={lastTransactionInThread}
                lastTransactionTypeInThread={lastTransactionTypeInThread}
                fileSpecificationData={fileSpecificationData}
                filteredSelectedNotData={filteredSelectedNotData}
                vendorType={vendorType}
                categoryId={categoryId}
                categoryName={categoryName}
                termsAndConditionsDetails={termsAndConditionsDetails}
                quotationDataLoading={quotationDataLoading}
                items={items}
                transactionType={transactionType}
                inquiryId={inquiryId}
                vendorId={vendorId}
                featurePermissionDetails={featurePermissionDetails}
                validityDate={validityDate}
                itemBoxId={itemBoxId}
                industryDetails={industryDetails}
                newSessionID={newSessionID}
                inquiryItemsId={inquiryItemsId}
                itemSpecific={itemSpecific}
                CurrentTransaction={CurrentTransaction}
                multipleSolutionFlag={multipleSolutionFlag}
                slabFlag={slabFlag}
                shipmentMark={shipmentMark}
                fetchedTxn={fetchedTxn}
                quotedCurrency={quotedCurrency}
                multiplePlantsFlag={multiplePlantsFlag}
                lastTransactionInThreadCommon={lastTransactionInThreadCommon}
                PermissionInquiry={PermissionInquiry}
                isServiceRfq = {quotationJsonData.template?.type === "service" ? true : false}
                isMultipleCurrency = {quotationJsonData.template?.service_multiple_currency ? true : false}
                activity_template_id = {quotationJsonData.template?.service_template_id}
                TxnType={quotationJsonData.vendors[0].transaction_thread
                  .length > 1
                  ? "Requote"
                  : "Quotation"}
                ServiceFileName={ServiceFileName}
                AlternativeNames={AlternativeNames}
              />
            ))}
        </Box>
        {quotationJsonData &&
          quotationJsonData.vendors &&
          quotationJsonData.vendors[0] ? (
          <ViewDetailsDrawer
            vendorDetails={quotationJsonData.vendors[0]}
            isViewDetailsDrawerOpened={viewDetails}
            isViewDetailsDrawerClosed={() => setViewDetails(false)}
            buyerInfo={buyerInfo}
          />
        ) : (
          ""
        )}

        {quotationJsonData &&
          quotationJsonData.vendors &&
          quotationJsonData.vendors[0] ? (
          <QuotationThreadDrawer
            isQuotationDrawerOpened={quotationThread}
            isQuotationDrawerClosed={() => setQuotationThread(false)}
            transactionThread={quotationJsonData.vendors[0].transaction_thread}
            vendorDetails={quotationJsonData.vendors[0]}
            buyerDetails={buyerInfo}
            rfqDate={rfqDate}
            referenceNo={referenceNo}
            AccountUsers={AccountUsers}
            CurrentTransaction={CurrentTransaction}
            newSessionID={newSessionID}
            filterData={quotationJsonData?.filter_data}
          />
        ) : (
          ""
        )}

        <HelpActionButtons />
      </main>
      <CustomSnackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }} // Positioning Snackbar at top-right
      >
        {
          <CustomAlert
            onClose={handleCloseSnackbar}
            severity="error"
            variant="filled"
          >
            {snackbar.message}
          </CustomAlert>
        }
      </CustomSnackbar>
      {
          userInfo &&
          userInfo?.type === "buyer" && (
            <SendMail
              CloseSendMailDrawer={CloseSendMailDrawer}
              openSendMailDrawer={openSendMailDrawer}
              rfqJsonData={quotationJsonData}
              buyerInfo={buyerInfo}
              userInfo={userInfo}
              newSessionID={newSessionID}
              transactionThread={quotationJsonData.vendors?.[0]?.transaction_thread}
              sendMailQuotationFlag={true}
              inquiryId={inquiryId}
            />
          )
        }
    </Fragment>
  ) : (
    <Card
      sx={{
        backgroundColor: "#ffebee",
        borderColor: "#d32f2f",
        borderWidth: 1,
        borderStyle: "solid",
      }}
    >
      <CardContent>
        <Typography sx={{ color: "#d32f2f" }}>{mainValidationError}</Typography>
      </CardContent>
    </Card>
  ));
}

export default QuotationDetails;
