import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import {
  CancelOutlined,
  PersonAddAltOutlined,
  StarBorderOutlined,
  GroupAddOutlined,
} from "@mui/icons-material";
import {
  Alert,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ListItemText,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  AddDatePicker,
  ExtendValidityThread
} from "../../components/SvgIcon/SvgIcon";
import { CaseStudyIcon } from "../../components/SvgIcon/SvgIcon";
import { R2AReportsIcon } from "../../components/SvgIcon/SvgIcon";
import JustificationDialog from "../../common/RFQList/justificationModal";
import LinkUnlinkUser from "../../components/RFQList/linkUnlinkUser/linkUnlinkUser";
import axios from "axios";
import {
  get_Inquiries_data,
  multiple_solution,
  prviewTB,
  pr_status_integration,
  getBuyers,
  getInquiryData,
  linkUnlinkItem,
  get_Inquiries_Item_data,
  templateURi,
  devTemplateURi,
} from "../../config/apiUrl";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { setRfqListInfo } from "../../redux/actions/rfqListAction";
import InnerHTML from "dangerously-set-html-content";
import $ from "jquery";
import jQuery from "jquery";
// import DataTable from "datatables.net";
import * as Constants from "../../constant/RFQList/constant";
import { URL_MAPPING } from "../../config/host-mapping";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import LayersIcon from '@mui/icons-material/Layers';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import R2aReportComponent from "../../components/R2aReport/R2aReportComponent";
import QuotationReminder from "../../components/RfqForm/QuotationReminderDrawer/QuotationReminder";
import { openSnackbar } from "../../redux/actions/snackbarAction";
import SendRfqToNewVendorCategory from "../../components/RFQList/SendRfqToVendor/SendRfqToNewVendorCategory";
import AddNewVendor from "../../components/RfqForm/AddNewVendor/AddNewVendor";
import InquiryLogs from "../../components/RFQList/inquiryLogs/inquiryLogs";
import ExtendValidityThreads from "../../components/RFQList/extendValidityThread/extendValidityThread";
import SendRfqCategoryVendors from "../../components/RFQList/sendRfqCategoryVendors/sendRfqCategoryVendors";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LinkIcon from '@mui/icons-material/Link';
import { setSelectedVendors } from "../../redux/actions/vendorActions";
import SlabRangeDialog from "../../components/RFQList/SlabRange/SlabRange";
import LinkSendRfqCategoryVendor from "../../components/RFQList/linkSendRfqCategoryVendor/linkSendRfqCategoryVendor";
import LinkSendRfqItemVendor from "../../components/RFQList/linkSendRfqItemVendor/linkSendRfqItemVendor";
import ValidityError from "../../assets/RFQList/images/validity-error.svg";
import MultiSolutionDialog from "../../components/RFQList/multipleSolution/viewMultipleSolution";

const currentHost = window.location.host;
const parentHostURI =
  URL_MAPPING[currentHost]["parent_host"] ||
  URL_MAPPING["default"]["parent_host"];

export function loadScripts(sources) {
  sources.forEach((src) => {
    var script = document.createElement("script");
    script.src = src;
    script.async = false; //<-- the important part
    document.body.appendChild(script); //<-- make sure to append to body instead of head
  });
}
export function loadStyle(sources) {
  sources.forEach((src) => {
    var linkElement = document.createElement("link");
    linkElement.setAttribute("rel", "stylesheet");
    linkElement.setAttribute("type", "text/css");
    linkElement.setAttribute("href", src);
    document.head.appendChild(linkElement);
  });
}

export function removeJSFile(filename, filetype) {
  var targetelement =
    filetype == "js" ? "script" : filetype == "css" ? "link" : "none"; //determine element type to create nodelist from
  var targetattr =
    filetype == "js" ? "src" : filetype == "css" ? "href" : "none"; //determine corresponding attribute to test for
  var allsuspects = document.getElementsByTagName(targetelement);
  for (var i = allsuspects.length; i >= 0; i--) {
    //search backwards within nodelist for matching elements to remove
    if (
      allsuspects[i] &&
      allsuspects[i].getAttribute(targetattr) != null &&
      allsuspects[i].getAttribute(targetattr).indexOf(filename) != -1
    )
      allsuspects[i].parentNode.removeChild(allsuspects[i]); //remove element by calling parentNode.removeChild()
  }
}

const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: "100%", // Set the desired width
    height: "20%", // Set the desired height
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  typography1: {
    display: "inline",
    marginRight: theme.spacing(1),
  },
  RFQMenuStyle: {
    "& .css-jwi4z7-MuiButtonBase-root-MuiMenuItem-root": {
      minHeight: "auto",
      // marginRight: "57px",
    },
  },
}));

export default function RFQMenuItems({
  sessionID,
  menulabels,
  openMenu,
  handleClose,
  anchorEl,
  categoryId,
  r2aId,
  userData,
  linkDataFlag,
  isPriortized,
  linkUnlinkData,
  InquiryData,
  InquiryId,
  itemSpecificVendorFlag,
  selectedId,
  configAxios,
  fetchData,
  reloadGridData,
  startDate,
  endDate
}) {
  const drawerWidth = 400;
  const classes = useStyles();
  const dispatch = useDispatch();
  // selectedVendors: state.vendorReducer.selectedVendors || [],
  const masterPermission = useSelector((state) => state.permissionsReducer.masterPermission);
  const { selectedCategory } = useSelector((reducer) => reducer.categoryReducer);
  const selectedPrCategory = useSelector((state) => state.prrfqreducer.selectedPrCategory);
  const userInfo = useSelector((state) => state.userInfoReducer.userInfo);
  const Permission = useSelector((state) => state.permissionsReducer)
  const companyInfo = useSelector((state) => state.companyInfoReducer.companyInfo);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openInquiryLogs, setOpenInquiryLogs] = React.useState(false);
  const [uniqueVendor, setUniqueVendor] = React.useState([]);  // for unique vendor carrying Local and International vendor type if both then ""
  const [itemsList, setItemsList] = React.useState("");
  const [ItemsListData, setItemsListData] = React.useState([])
  const [itemIdsCSV, setItemIdsCSV] = React.useState([]);
  const [openExtendValidityThread, setOpenExtendValidityThread] = React.useState(false);
  const [R2AReports, setR2AReports] = React.useState({ __html: "" });
  const [Tbpreview, setTbpreview] = React.useState({
    __html: "",
  });
  const [multipleSolution, setMultipleSolution] = React.useState({
    __html: "",
  });
  const [openHtml, setOpenHtml] = React.useState(false);
  const [openHtmlGrpNotCreated, setOpenHtmlGrpNotCreated] = React.useState(false);
  const [prioritySnackbar, setPrioritySnackbar] = React.useState(false);
  const [categoryVendorsKey, setCategoryVendorsKey] = React.useState(0);
  const [itemVendorsKey, setItemVendorsKey] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [severity, setseverity] = React.useState('')
  const [SnackMessage, setSnackMessage] = React.useState('')
  const [loader, setLoader] = React.useState(true);
  const [anchorEl1, setAnchorEl1] = React.useState(false);
  const [anchorEl2, setAnchorEl2] = React.useState(false);
  const [anchorEl3, setAnchorEl3] = React.useState(false);
  const [buyerInfo, setBuyerInfo] = React.useState([]);
  const [checkedItems, setCheckedItems] = React.useState([]);
  const [openReminderDrawer, setOpenReminderDrawer] = React.useState(false);
  const [rfqJsonData, setRfqJsonData] = React.useState({});
  const [linkVendorToCategoryOpen, setLinkVendorToCategoryOpen] = React.useState(false);
  const [linkVendorToItemOpen, setLinkVendorToItemOpen] = React.useState(false);
  const [newlyVendorIdToItem, setNewlyVendorIdToItem] = React.useState(false);
  const [addVendorDialogOpen, setAddVendorDialogOpen] = React.useState(false);
  const [currentVendorID, setCurrentVendorID] = React.useState(null);
  const [currentVendorName, setCurrentVendorName] = React.useState("");
  const [sendRfqNewVendorsOpen, setSendRfqNewVendorsOpen] = React.useState(false);
  const [sendRfqNewVendorsItemOpen, setSendRfqNewVendorsItemOpen] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [vendorsDetails, setVendorsDetails] = React.useState([]);
  const [vendorsDetailsAPIRes, setVendorsDetailsAPIRes] = React.useState([]);
  const [inquiryJsonTemplates, setInquiryJsonTemplates] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [jsonLoading, setJsonLoading] = React.useState(true);
  const [countVendor, setCountVendor] = React.useState(0);  // Start Slab Range Dialog function
  const [cancelCounter, setCancelCounter] = React.useState(0);
  const [openSlabRange, setopenSlabRange] = React.useState(false);
  const [openMultipleSolution, setOpenMultipleSolution] = React.useState(false);
  const [updateNewVendorFlag, setUpdateNewVendorFlag] = React.useState(false);
  const [anchorElTB, setAnchorElTB] =
    React.useState(false);
  const [openDialogueBox, setOpenDialogueBox] = React.useState(false);
  const [openPriorityModal, setOpenPriorityModal] = React.useState(false);
  const [cancelFlag, setCancelFlag] = React.useState(false);
  const [multipleSolutionAnchor, setMultipleSolutionAnchor] =
    React.useState(false);
  const [openJustificationModal, setOpenJustificationModal] =
    React.useState(false);

  const configAxiosPost = {
    headers: { Authorization: `Bearer ${sessionID}` },
    "Content-Type": "application/json",
  };

  const configAxiosGet = {
    headers: { Authorization: `Bearer ${sessionID}` },
  };

  const handleSendRfqNewVendorsOpen = () => setSendRfqNewVendorsOpen(true);

  const handleSendRfqNewVendorsClose = () => {

    setSelectedVendors([]);
    setSendRfqNewVendorsOpen(false);
    setCancelFlag(true)
    setTabIndex(0)
    // reloadGridData()
    window.location.reload()
    // dispatch(setRfqListInfo(sessionID, [
    //   {
    //     "start": startDate,
    //     "end": endDate
    //   }
    // ]))
    // setNewlyVendorIdToItem(false);
  }

  const handleSendRfqNewVendorsItemOpen = () => setSendRfqNewVendorsItemOpen(true);
  const handleSendRfqNewVendorsItemClose = () => {
    setSelectedVendors([]);
    setSendRfqNewVendorsItemOpen(false);
    setCancelFlag(true)
    setNewlyVendorIdToItem(false);
    setCancelCounter(prev => prev + 1); // Increment to trigger child
    setTabIndex(0)
    // reloadGridData()
    window.location.reload()
    // dispatch(setRfqListInfo(sessionID, [
    //   {
    //     "start": startDate,
    //     "end": endDate
    //   }
    // ]))
  }

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleOpenSlabRange = () => {
    setopenSlabRange(true);
  };

  const handleOpenMultipleSolution = (event) => {
    setOpenMultipleSolution(true);
  }

  const closeSlabRange = () => {
    setopenSlabRange(false);
  };

  const closeMultipleSolution = () => {
    setOpenMultipleSolution(false);
  };

  React.useEffect(() => {
    window.$ = $;
    window.jQuery = jQuery;
    window.jq = jQuery;
  }, []);

  const inquiry_id = linkUnlinkData?.inquiryNo;

  const handlePriorityModalOpen = () => {
    setOpenPriorityModal(true);
  };

  const handleJustificationModalOpen = () => {
    setOpenJustificationModal(true);
  };

  const handleReminderDrawer = (event) => {
    event.stopPropagation();
    setOpenReminderDrawer(true);
    fetchQuotationJSON();
    handleClose();
  }

  const CloseReminderDrawer = () => {
    setOpenReminderDrawer(false);
  };

  const handleCategoryVendorsReload = () => {
    setCategoryVendorsKey((prevKey) => prevKey + 1);
    setSelectedVendors([]);
  };

  const handleItemizeVendorsReload = () => {
    setItemVendorsKey((prevKey) => prevKey + 1);
    setSelectedVendors([]);
  };

  const handleClosePrioritySnackbar = () => {
    setPrioritySnackbar(false);
  };

  const handlePriorityModalClose = () => {
    setOpenPriorityModal(false);
  };
  const handleCloseSnackbar = () => {
    setSnackbar(false);
  };

  const handleR2AReportsClose = () => {
    // dispatch(setRfqListInfo(sessionID, [
    //   {
    //     "start": startDate,
    //     "end": endDate
    //   }
    // ]))
    // reloadGridData()
    window.location.reload()
    setAnchorEl2(false);
    setR2AReports({ __html: "" });
  };

  const handleExtendValidityThreadClose = () => {
    setOpenExtendValidityThread(false);
  };

  const handleTbClose = () => {
    window.location.reload()
    // reloadGridData()
    // dispatch(setRfqListInfo(sessionID, [
    //   {
    //     "start": startDate,
    //     "end": endDate
    //   }
    // ]))
    setAnchorElTB(false);
    setTbpreview({ __html: "" });
  };

  const handleInquiryLogsClose = () => {
    setOpenInquiryLogs(false);
  };

  const handleJustificationModalClose = () => {
    setOpenJustificationModal(false);
  };

  const OpenMultipleSolutionDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };


  const updateVendorList = () => {
    setUpdateNewVendorFlag(true); // Updates state, triggering re-renders
  };

  const handleJustificationModalSubmit = (justificationText) => {
    const url =
      get_Inquiries_data + `${linkUnlinkData.inquiryNo}/close_inquiry`;

    axios
      .patch(url, { justification: justificationText }, configAxiosGet)
      .then((response) => {
        if (response.status === 200) {
          window.location.reload()
          // reloadGridData();
          handleJustificationModalClose();
          setSnackbar(true);
          setseverity("success")
          setSnackMessage("Close RFQ Requested Successfully")
          // dispatch(setRfqListInfo(sessionID, [
          //   {
          //     "start": startDate,
          //     "end": endDate
          //   }
          // ]))
          if (
            Permission.accountModulePermission['PR Status Integration']?.module_activated === "Y"
          ) {
            axios
              .get(
                pr_status_integration(
                  userInfo?.division_id,
                  userInfo?.company_id,
                  InquiryData.id,
                  // pr_ids_array.join()
                )
              )
              .then((response) => {
                console.log('Successfully rfq closed');
              })
              .catch((err) => {
                //alert("error");
              });
          }
        } else {
          console.error(
            `PATCH request failed with status code ${response.status}.`
          );

        }
      })
      .catch((error) => {
        console.error(`Error: ${error.message}`);
        if (error.response.data.code == 500) {
          setSnackbar(true);
          setseverity('error')
          setSnackMessage(error?.response?.data?.message ? error.response.data.message : "Somwthing went wrong.")
          // dispatch({
          //   type: OPEN_SNACKBAR,
          //   payload: {
          //     type: "error",
          //     msg: error.response.data.message,
          //   },
          // });
        }
      });
    handleJustificationModalClose();
  };

  const handlePriorityModalSubmit = (justification) => {
    const url =
      get_Inquiries_data + `${linkUnlinkData.inquiryNo}/priority_non_priority`;

    axios
      .patch(
        url,
        { is_priority: !isPriortized, justification: justification },
        configAxiosGet
      )
      .then((response) => {
        if (response.status === 200) {
          setPrioritySnackbar(true);
          // reloadGridData()
          window.location.reload()
          // dispatch(setRfqListInfo(sessionID, [
          //   {
          //     "start": startDate,
          //     "end": endDate
          //   }
          // ]))
        } else {
          console.error(
            `PATCH request failed with status code ${response.status}.`
          );
        }
      })
      .catch((error) => {
        console.error(`Error: ${error.message}`);
      });
    handlePriorityModalClose();
  };

  React.useEffect(() => {
    const fetchItem = async () => {
      var url = get_Inquiries_Item_data(selectedId);
      await axios
        .get(url, configAxios)
        .then((res) => {
          setItemsList(res.data.inquiry_items);
          setItemsListData(res.data.inquiry_items);

          const itemIdsArray = res.data.inquiry_items.map((item) => item.id);
          setItemIdsCSV(itemIdsArray);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    if (selectedId) {
      fetchItem();
    }
  }, [selectedId]);

  const handleOpenDialogue = (params) => {
    setOpenDialogueBox(true);
  };

  const handleCloseDialogue = () => {
    setOpenDialogueBox(false);
  };

  function loadExtendValidityJs() {
    loadScripts([
      parentHostURI + "/js/masters/jquery_grid/jquery-1.7.2.min.js",
      parentHostURI + "/js/jquery-1.6.2.js",
      parentHostURI + "/js/ui.js",
      parentHostURI + "/jquery_grid/javascript/jquery.loader-0.3.js",
      parentHostURI + "/js/check_validation.js",
      parentHostURI + "/js/extranet_compose.js",
      parentHostURI + "/plugins/anytimec/anytimec.js",
      parentHostURI + "/js/datepicker.js",
      parentHostURI + "/js/Widget.js",
      parentHostURI + "/js/vendx_common.js",
    ]);
  }

  const handleSendRfqToNewVendor = () => {
    if (itemSpecificVendorFlag) {
      handleSendRfqNewVendorsItemOpen();
    } else {
      // if (companyInfo?.common_vendor_login === "Y") {}
      handleSendRfqNewVendorsOpen();
    };
  };

  const handlelinkVendorToCategoryOpen = () => {
    setLinkVendorToCategoryOpen(true);
  }

  const handlelinkVendorToItemOpen = (id) => {
    setLinkVendorToItemOpen(true);
  }

  const handleNewlyVendorIdChange = (id) => {
    setNewlyVendorIdToItem(id);
  };

  const handleInquiryLogs = () => {
    setOpenInquiryLogs(true);
  };

  const [openR2ADialog, setOpenR2ADialog] = React.useState(false);

  const handleOpenR2A = () => {
    setOpenR2ADialog(true);
  };

  const handleCloseOpenR2A = () => {
    setOpenR2ADialog(false);
  };

  const handleExtendValidityThread = () => {
    setOpenExtendValidityThread(true);
  }
  const handleTBPreview = async () => {
    const formData = new FormData();
    formData.append("from_react", "1");
    formData.append("inquiry_id", inquiry_id);
    formData.append("tran_type_guid", InquiryData?.vendor?.guid);
    formData.append("tb_master_type", "category");
    formData.append("product_category_name", InquiryData?.inquiry_categories?.name);
    formData.append("product_id", InquiryData?.inquiry_categories?.id);
    formData.append("master_action", 'technical_bid');
    formData.append("oper", 'vendor_preview');
    formData.append("vendor_flag", '1');

    formData.append("pr_flag", '1');
    // formData.append("rfq_mapping_type", '10');//need to discuss

    loadStyle([
      parentHostURI + "/plugins/font-awesome-4.7.0/css/font-awesome.min.css",
      parentHostURI + "/css/ajax-dynamic-list.css",
      parentHostURI + "/pr/bootstrap/css/bootstrap-toggle.min.css",
      parentHostURI + "/css/bluestar_blue.css",
      parentHostURI + "/css/masters/admin-jquery-ui.min.css",
      parentHostURI + "/css/masters/jquery-ui-1.8.20.custom.css",
      parentHostURI + "/css/masters/new_masters.css",
      parentHostURI + "/css/masters/technical_bid_template.css",
      parentHostURI + "/css/masters/colorbox_custom/theme3/colorbox.css",
      parentHostURI + "/css/ui-kits/number-align.css",
      parentHostURI + "/css/ui-kits/react-custom.css",
    ]);
    loadScripts([
      parentHostURI + "/js/masters/jquery_grid/jquery-1.7.2.min.js",
      parentHostURI + "/js/masters/masters_auto_minimized.js"
    ]);
    setAnchorElTB(true);
    setLoader(true);
    try {
      const response = await axios.post(prviewTB, formData);
      if (response) {
        setTbpreview(response.data);
        setLoader(false);
      } else {
        removeJSFile(parentHostURI + "/css/bluestar_blue.css", "css");
        // Handle empty data case
      }
    } catch (error) {
      removeJSFile(parentHostURI + "/css/bluestar_blue.css", "css");
      console.error("Error fetching data:", error);
    }
    // console.log('clicled');
  }

  // End Slab Range Dialog function

  React.useEffect(() => {
    const fetchBuyerData = async () => {
      try {
        if (userData && userData.created_by) {
          let url;
          if (userInfo.type === "vendor") {
            url = getBuyers(userData?.created_by);
          } else {
            url = getBuyers(userData?._internal?.current_user_id);
          }
          const response = await axios.get(url, configAxiosGet);
          setBuyerInfo(response.data);
        }
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    fetchBuyerData();
  }, [userData]);

  const fetchVendorsDetails = async () => {
    let url =
      get_Inquiries_data +
      selectedId +
      "/vendors?embed[]=inquiry&embed[]=action";
    try {
      let response = await axios.get(url, configAxios);
      if (response?.data && response?.data?.vendors) {
        // Remove duplicates based on 'id' or 'guid'
        const uniqueVendors = response.data.vendors.reduce((acc, vendor) => {
          if (!acc.some((v) => v.id === vendor.id)) {
            acc.push(vendor);
          }
          return acc;
        }, []);

        // Add 'idx' field
        const indexedVendors = uniqueVendors.map((vendor, idx) => ({
          ...vendor,
          idx,
        }));
        setVendorsDetailsAPIRes(indexedVendors);
        setVendorsDetails(indexedVendors);
        setCountVendor(indexedVendors.length);
        setLoading(false);
      } else {
        // Handle empty data case
        setLoading(false);
        setVendorsDetails([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  React.useEffect(() => {
    if (selectedId) {
      fetchVendorsDetails();
    }
  }, [selectedId]);

  const fetchQuotationJSON = async () => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const inq_id = queryParams.get("inq_id") || InquiryId;
      const vendor_id = queryParams.get("vendor_id");

      let URL = getInquiryData(inq_id);

      // if (queryParams.get("TLGUID")) {
      //   URL = getTransactionDetailsView(inq_id, vendor_id, queryParams.get("TLGUID"));
      // }

      const response = await axios.get(URL, configAxiosGet);

      // Set rfqJsonData directly from the response
      setRfqJsonData(response.data);
    } catch (err) {
      openSnackbar(
        "error",
        err?.message ? err?.message : "Something went wrong!"
      );
    }
  };

  {/*Send RFQ to new Vendor Fucntions */ }

  const handleAddVendorDialogOpen = (vendorID, vendorName) => {
    setAddVendorDialogOpen(true);
    setCurrentVendorID(vendorID);
    setCurrentVendorName(vendorName);
  };

  const handleAddVendorDialogClose = () => {
    setAddVendorDialogOpen(false);
  }

  const updateCartItemsDetails = () => {
    if (itemsList?.length) {
      // setLoading(true);

      let params = {
        pagination: false,
        id: itemsList.map((item) => item.id),
        prlineitems_ids: [],
      };

      if (
        Permission.accountModulePermission[
          "Plant Specific Categroy Item Vendor Linking"
        ].module_activated === "Y"
      ) {
        if (
          selectedCategory?.id === selectedPrCategory?.category?.id
        ) {
          itemsList.forEach((item) => {
            if (item.hasOwnProperty("prlineitems_ids")) {
              params.prlineitems_ids = params.prlineitems_ids.concat(
                item.prlineitems_ids
              );
            }
          });
          params.plant_code = selectedPrCategory.plant.code;
        }
      }

      axios
        .get(linkUnlinkItem(selectedCategory?.new_category_id), {
          params,
        })
        .then((response) => {
          response.data.data.map((items) => {
            itemsList.forEach((item) => {
              if (
                item.id === items.id &&
                item.hasOwnProperty("prlineitems_ids")
              ) {
                items["prlineitems_ids"] = item.prlineitems_ids;
              }
            });
          });
          // setItemsList(response.data.data);
          setLoading(false);
          setCheckedItems([]);
        })
        .catch((err) => {
          // openSnackbar("error", err.response.data.message);
          console.log(err);
          setLoading(false);
        });
    }
  };

  const fetchJSONTemplateInquiry = React.useCallback(async () => {
    setInquiryJsonTemplates([]);
    if (!InquiryData || !InquiryData.batch_id || !InquiryData.template_id) {
      return;
    }

    let transaction_id = 1;
    if (transaction_id === null) return;

    const batchID = InquiryData.batch_id;
    const templateID = InquiryData.template_id;

    // let templateURL =
    //   // process.env.NODE_ENV === "production"
    //   //   ? 
    //   templateURi(
    //     userInfo?.division_id,
    //     companyInfo?.display_name,
    //     templateID,
    //     batchID,
    //     transaction_id
    //     // + (vendorType === "Local" ? "_L" : "_I")
    //   )
    // : devTemplateURi(
    //   userInfo?.division_id,
    //   companyInfo?.display_name,
    //   templateID,
    //   batchID,
    //   transaction_id,
    //   // + (vendorType === "Local" ? "_L" : "_I")
    // );

    const template_url = URL_MAPPING[currentHost]["template_url"] || URL_MAPPING["default"]["template_url"];
    let templateURL = `${template_url}/template/${companyInfo?.display_name}/${userInfo?.division_id}/template_${templateID}_${batchID}_${transaction_id}.json?version=` + Date.now();

    try {
      setJsonLoading(true);
      const response = await axios.get(templateURL);
      setInquiryJsonTemplates(response.data);
    } catch (err) {
      console.error("Error fetching template:", err);
    } finally {
      setJsonLoading(false);
    }
  }, [InquiryData, userInfo, companyInfo]);

  React.useEffect(() => {
    setUniqueVendor("");
    if (!Array.isArray(vendorsDetails) || vendorsDetails.length === 0) return;

    // Extract unique vendor types
    const uniqueVendorTypes = new Set(vendorsDetails.map(vendor => vendor.type));

    // Only proceed if all vendors share the same type
    if (uniqueVendorTypes.size === 1) {
      const vendorType = vendorsDetails[0].type; // "Local" or "International"
      // Call the function passing the vendor type
      fetchJSONTemplateInquiry();
      setUniqueVendor(vendorType);
    }
  }, [InquiryData, vendorsDetails, fetchJSONTemplateInquiry]);

  return (
    <React.Fragment>
      {openHtml == true ? loadExtendValidityJs() : ""}

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        fullWidth
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          "&.MuiPopover-root .MuiPaper-root": {
            minWidth: "315px",
            "& .MuiMenuItem-root .MuiListItemIcon-root": {
              minWidth: "40px",
            },
          },
        }}
        className={classes.RFQMenuStyle}
        slotProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              mr: 1,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {InquiryData?.closed_at === null && (userInfo?.user_id == InquiryData?.created_by || userInfo?.is_super_user == "Y" || Permission?.masterPermission?.extend_rfq == 'Y') && <MenuItem onClick={handleReminderDrawer}>
          <ListItemIcon>
            <AddDatePicker />
          </ListItemIcon>
          {/* <ListItemText>Extend Validity to RFQ</ListItemText> */}
          <ListItemText>{menulabels.extend_validity_to_rfq}</ListItemText>
        </MenuItem>}
        {InquiryData?.closed_at === null && <MenuItem onClick={handleSendRfqToNewVendor}>
          <ListItemIcon>
            <PersonAddAltOutlined fontSize="small" />
          </ListItemIcon>
          {/* <ListItemText>Send RFQ To New Vendor</ListItemText> */}
          <ListItemText>{menulabels.send_rfq_to_new_vendor}</ListItemText>
        </MenuItem>}
        {(Permission?.accountModulePermission?.['Multiple Solutions']?.module_activated === 'Y' &&
          InquiryData?.filter_data?.multiple_solution &&
          InquiryData?.vendor_count?.quoted > 0 &&
          (InquiryData?.filter_data?.sealed === false || InquiryData?.closed_at !== null || InquiryData?.status === 'Expired')) && (
            <MenuItem
              onClick={() =>
                InquiryData?.filter_data?.multiple_solution && InquiryData?.filter_data?.slab
                  ? handleOpenSlabRange()
                  : handleOpenMultipleSolution()
              }
            >
              <ListItemIcon>
                {InquiryData?.filter_data?.multiple_solution && InquiryData?.filter_data?.slab ? (
                  <LayersIcon fontSize="small" />
                ) : (
                  <LayersOutlinedIcon fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText>{menulabels.view_multiple_solution}</ListItemText>
            </MenuItem>
          )}
        {(userInfo?.user_id == InquiryData?.created_by || userInfo?.is_super_user == "Y") && <MenuItem onClick={handlePriorityModalOpen}>
          <ListItemIcon>
            <StarBorderOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {/* {isPriortized === true ? "Priority" : "Not Priority"} */}
            {isPriortized === true
              ? menulabels.not_priority
              : menulabels.priority}
          </ListItemText>
        </MenuItem>}
        <MenuItem onClick={(e) => handleInquiryLogs(e)}>
          <ListItemIcon>
            <CaseStudyIcon />
          </ListItemIcon>
          {/* <ListItemText>Inquiry Logs</ListItemText> */}
          <ListItemText>{menulabels.inquiry_logs}</ListItemText>
        </MenuItem>
        {(Permission?.accountModulePermission?.['Transaction Access']?.module_activated == 'Y') && <MenuItem onClick={(e) => handleOpenDialogue(e)}>
          <ListItemIcon>
            <GroupAddOutlined fontSize="small" />
          </ListItemIcon>
          {/* <ListItemText>Link/Unlink User</ListItemText> */}
          <ListItemText>{menulabels.link_unlink_user}</ListItemText>
        </MenuItem>}
        {/*<MenuItem onClick={handleClickPTB}>
          <ListItemIcon>
            <RemoveRedEyeOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Preview Technical Bid</ListItemText>
      </MenuItem>*/}
        {(InquiryData?.filter_data?.r2a === true && Permission?.accountModulePermission?.['RFQ To Auction']?.module_activated == 'Y') && <MenuItem onClick={handleOpenR2A}>
          <ListItemIcon>
            <R2AReportsIcon />
          </ListItemIcon>
          {/* <ListItemText>R2A Reports</ListItemText> */}
          <ListItemText>{menulabels.r2a_reports}</ListItemText>
        </MenuItem>}
        {InquiryData?.closed_at === null && (userInfo?.user_id == InquiryData?.created_by || userInfo?.is_super_user == "Y" || Permission?.masterPermission?.closing_rfq == 'Y') && <MenuItem onClick={handleJustificationModalOpen}>
          <ListItemIcon>
            <CancelOutlined fontSize="small" />
          </ListItemIcon>
          {/* <ListItemText>Close RFQ</ListItemText> */}
          <ListItemText>{menulabels.close_rfq}</ListItemText>
        </MenuItem>}

        <MenuItem onClick={handleExtendValidityThread}>
          <ListItemIcon>
            <ExtendValidityThread fontSize="small" />
          </ListItemIcon>
          {/* <ListItemText>Extend Validity Thread</ListItemText> */}
          <ListItemText>{menulabels.extend_validity_thread}</ListItemText>
        </MenuItem>

        {Permission?.accountModulePermission?.["Technical Bid"]?.module_activated == "Y" &&
          InquiryData?.valid_date_tb !== null &&
          <MenuItem onClick={handleTBPreview} >
            <ListItemIcon>
              <VisibilityOutlinedIcon fontSize="small" />
            </ListItemIcon>
            {/* <ListItemText>Technical Bid Preview</ListItemText> */}
            <ListItemText>{menulabels.technical_bid_preview}</ListItemText>
          </MenuItem>}
      </Menu>
      <JustificationDialog
        open={openJustificationModal}
        onClose={handleJustificationModalClose}
        title={linkUnlinkData?.inquiryName}
        subtitle="Justification (RFQ Closed)"
        floatedRfqUser={InquiryData?.created_by}
        Flag='Closed'
        onSubmit={handleJustificationModalSubmit}
        sessionID={sessionID}
      />
      <JustificationDialog
        open={openPriorityModal}
        onClose={handlePriorityModalClose}
        title={linkUnlinkData?.inquiryName}
        subtitle={
          isPriortized ? "Priority to Not Priority" : "Not Priority to Priority"
        }
        floatedRfqUser={InquiryData?.created_by}
        Flag='Priority'
        onSubmit={handlePriorityModalSubmit}
        sessionID={sessionID}
      />
      <LinkUnlinkUser
        sessionID={sessionID}
        openDialogueBox={openDialogueBox}
        handleCloseDialogue={handleCloseDialogue}
        userData={userData}
        linkUnlinkData={linkUnlinkData}
        linkDataFlag={linkDataFlag}
        rowSelectionModel={[linkUnlinkData?.inquiryNo]}
        SelctedRFQ={[linkUnlinkData?.inquiryNo]}
      />

      {openSlabRange &&
        <SlabRangeDialog
          openSlabRange={openSlabRange}
          closeSlabRange={closeSlabRange}
          inquiryNumber={InquiryData?.number}
          inquiryId={selectedId}
          configAxiosGet={configAxiosGet}
          itemsList={itemsList}
          companyInfo={companyInfo}
          userInfo={userInfo}
          inquiryData={InquiryData}
        />
      }

      {openMultipleSolution &&
        <MultiSolutionDialog
          openMultipleSolution={openMultipleSolution}
          closeMultipleSolution={closeMultipleSolution}
          inquiryNumber={InquiryData?.number}
          inquiryId={selectedId}
          configAxiosGet={configAxiosGet}
          itemsList={itemsList}
          companyInfo={companyInfo}
          userInfo={userInfo}
          inquiryData={InquiryData}
        />
      }

      {/* <Dialog open={openR2ADialog} onClose={handleCloseOpenR2A} fullScreen sx={{ m: 4 }}> */}
      <R2aReportComponent
        sessionID={sessionID}
        openR2ADialog={openR2ADialog}
        handleCloseOpenR2A={handleCloseOpenR2A}
        selctedRFQInquiryId={inquiry_id}
        auctionR2AId={r2aId}
      />
      <Dialog
        open={anchorEl2}
        onClose={handleR2AReportsClose}
        fullScreen
        sx={{ m: 4 }}
      >
        <DialogTitle sx={{ m: 0, padding: "16px !important" }}>
          {menulabels.r2a_reports}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleR2AReportsClose}
          sx={{ position: "absolute", right: 8, top: 13 }}
          size="small"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {loader === true ? (
            <CircularProgress
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: R2AReports }} className="RFQ-PerlData"></div>
          )}
        </DialogContent>
      </Dialog>
      <Dialog
        open={anchorElTB}
        onClose={handleTbClose}
        fullScreen
        sx={{ m: 4 }}
      >
        <DialogTitle sx={{ m: 0, padding: "16px !important" }}>
          Technical Bid Preview
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleTbClose}
          sx={{ position: "absolute", right: 8, top: 13 }}
          size="small"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {loader === true ? (
            <CircularProgress
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            <InnerHTML html={Tbpreview} />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={multipleSolutionAnchor}
        fullScreen
      >
        <DialogTitle sx={{ m: 0, padding: "16px !important" }}>
          {menulabels.view_multiple_solution}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeMultipleSolution}
          sx={{ position: "absolute", right: 8, top: 13 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {loader === true ? (
            <CircularProgress
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            // <div dangerouslySetInnerHTML={multipleSolution}></div>
            <InnerHTML html={multipleSolution} />
          )}
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackbar}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          variant="filled"
        >
          {SnackMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        open={prioritySnackbar}
        onClose={handleClosePrioritySnackbar}
      >
        <Alert
          onClose={handleClosePrioritySnackbar}
          severity="success"
          variant="filled"
        >
          Priority Status Changed Successfully
        </Alert>
      </Snackbar>
      {/* Quotation Reminder */}
      {
        userInfo &&
        userInfo?.type === "buyer" && rfqJsonData && (
          <QuotationReminder
            CloseReminderDrawer={CloseReminderDrawer}
            openReminderDrawer={openReminderDrawer}
            rfqJsonData={rfqJsonData}
            buyerInfo={buyerInfo}
            userInfo={userInfo}
            newSessionID={sessionID}
            inquiryId={InquiryId}
            onApiSuccess={fetchData}
            fetchData={fetchData}
            accountModulePermission={Permission?.accountModulePermission}
            RFQMenuItemsFlag={true}
            reloadGrid={() => {
              // reloadGridData()
              window.location.reload()
              // dispatch(setRfqListInfo(sessionID, [
              //   {
              //     "start": startDate,
              //     "end": endDate
              //   }
              // ]))
            }}
          />
        )
      }
      {/* Send RFQ to new vendor for category*/}
      <Dialog
        key={sendRfqNewVendorsOpen ? "open" : "closed"}
        open={sendRfqNewVendorsOpen}
        fullScreen
        sx={{
          "& .MuiDialogTitle-root": {
            padding: "12px 16px"
          },
          margin: { xs: "0", sm: "0", md: "20px" },
          zIndex: "1199"
        }}
      >
        <DialogTitle component="div">
          <Box
            className="VendorItem-Title"
          >
            <Typography
              variant="subtitle1"
              fontWeight={500}
              component="h6"
              color={"secondery.900"}
              lineHeight={"normal"}
              noWrap
            >
              Send RFQ to New Vendor
            </Typography>
            <Tooltip title={InquiryData?.number}>
              <Typography variant="caption" display="block">{InquiryData?.number}</Typography>
            </Tooltip>
          </Box>
          <Box className="dialog-title-action" display={"flex"} gap={{ xs: 0, sm: "8px" }}>
            {InquiryData?.status !== "Expired" && (
              <>
                <Tooltip title="Add New Vendor">
                  <IconButton
                    onClick={() => {
                      handleAddVendorDialogOpen();
                      handleClose();
                      // this.setState({
                      //   rows: [],
                      // });
                    }}
                    size="small"
                  >
                    <AddCircleIcon sx={{ color: "text.primary" }} />
                  </IconButton>
                </Tooltip>
                {(userInfo?.is_super_user === "Y" ||
                  masterPermission.linking === "Y") && (
                    <Tooltip title="Link Unlink Vendor(s)">
                      <IconButton
                        color="text.primary"
                        onClick={() => {
                          handlelinkVendorToCategoryOpen();
                        }}
                        size="small"
                      >
                        <LinkIcon sx={{ color: "text.primary" }} />
                      </IconButton>
                    </Tooltip>
                  )}
              </>
            )}
            <IconButton
              color="text.primary"
              onClick={() => {
                handleSendRfqNewVendorsClose();
                // this.setState({
                //   rows: [],
                // });
              }}
              size="small"
            >
              <CloseIcon sx={{ color: "text.primary" }} />
            </IconButton>
          </Box>
        </DialogTitle>
        {InquiryData?.status !== "Expired" ? (
          <DialogContent dividers sx={{ p: 0 }}>
            <Box
              sx={{
                backgroundColor: "var(--grey-0)",
                borderBottom: "1px solid var(--bluegrey-500)",
                ".MuiTabs-root": {
                  minHeight: "36px",
                },
                ".MuiTab-root": {
                  minHeight: "36px",
                  padding: "8px 16px",
                  textTransform: "capitalize"
                }
              }}
            >
              <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab label="Linked Vendors" />
                {companyInfo?.common_vendor_login === "Y" && <Tab label="Import Vendors" />}
              </Tabs>
            </Box>
            <Box>
              {/* <Divider /> */}
              {tabIndex === 0 && (
                <Box>
                  <SendRfqCategoryVendors
                    key={categoryVendorsKey}
                    handleCategoryVendorsReload={handleCategoryVendorsReload}
                    handlelinkVendorToCategoryOpen={handlelinkVendorToCategoryOpen}
                    handleAddVendorDialogOpen={handleAddVendorDialogOpen}
                    handleClose={handleSendRfqNewVendorsClose}
                    vendorsDetails={vendorsDetails}
                    selectedId={selectedId}
                    inquiryData={InquiryData}
                    selectedCategory={selectedCategory}
                    isCancelAction={cancelFlag}
                    setCancelFlag={setCancelFlag}
                    cancelCounter={cancelCounter}
                    countVendor={countVendor}
                    itemIdsCSV={itemIdsCSV}
                    itemSpecificFlag={false}
                    uniqueVendor={uniqueVendor}
                    inquiryJsonTemplates={inquiryJsonTemplates}
                    sessionID={sessionID}
                    fetchVendorsDetails={fetchVendorsDetails}
                    fetchData={fetchData}
                    handleVendorsReload={
                      !itemSpecificVendorFlag
                        ? handleCategoryVendorsReload
                        : handleItemizeVendorsReload
                    }
                  />
                </Box>
              )}
              {companyInfo?.common_vendor_login === "Y" && tabIndex === 1 && (
                <Box>
                  <SendRfqToNewVendorCategory
                    handleClose={handleSendRfqNewVendorsClose}
                    categoryId={categoryId}
                    handleAddVendorDialogOpen={handleAddVendorDialogOpen}
                    handlelinkVendorToCategoryOpen={handlelinkVendorToCategoryOpen}
                    inquiryNumber={InquiryData?.number}
                    handleVendorsReload={
                      !itemSpecificVendorFlag
                        ? handleCategoryVendorsReload
                        : handleItemizeVendorsReload
                    }
                  />
                </Box>
              )}
            </Box>
          </DialogContent>) :
          (
            <DialogContent dividers sx={{
              p: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
              textAlign: "center"
            }}>
              <Stack spacing={1} alignItems="center">
                <img src={ValidityError} alt="RFQ validity has expired" />
                <Typography color={"error"} sx={{ fontWeight: "bold" }}>RFQ validity has expired, Please extend RFQ validity.</Typography>
              </Stack>
            </DialogContent>
          )}
        {/* <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            borderTop: '1px solid #ddd',
          }}
        > */}
        {/* Left Side: Send SMS Switch */}
        {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ ml: 1 }}>Send SMS</Typography>
            <Switch
              // checked={this.state.sendSms}
              // onChange={this.handleSendSmsChange}
              color="primary"
              name="sendSms"
              inputProps={{
                'aria-label': 'primary checkbox',
              }}
            />
          </Box> */}

        {/* Right Side: Cancel and Submit Buttons */}
        {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                this.props.handleClose();
                // this.setState({
                //   rows: [],
                // });
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              // onClick={this.handleSendRfqNewVendorsCategory}
              sx={{ ml: 2 }}
            >
              Send
            </Button>
          </Box> */}
        {/* </Box> */}
      </Dialog>

      {/* Send RFQ to new vendor for Items*/}
      <Dialog
        open={sendRfqNewVendorsItemOpen}
        // onClose={handleSendRfqNewVendorsItemClose}
        fullScreen
        sx={{
          "& .MuiDialogTitle-root": {
            padding: "12px 16px"
          },
          margin: { xs: "0", sm: "0", md: "20px" },
          zIndex: "1199"
        }}
      >
        <DialogTitle component="div">
          <Box
            className="VendorItem-Title"
          >

            <Typography
              variant="subtitle1"
              fontWeight={500}
              component="h6"
              color={"secondery.900"}
              lineHeight={"normal"}
              noWrap
            >
              Send RFQ to New Vendor
            </Typography>
            <Tooltip title={InquiryData?.number}>
              <Typography variant="caption" display="block">{InquiryData?.number}</Typography>
            </Tooltip>
          </Box>
          <Box className="dialog-title-action" display={"flex"} gap={{ xs: 0, sm: "8px" }}>
            {InquiryData?.status !== "Expired" && (
              <>
                <Tooltip title="Add New Vendor">
                  <IconButton
                    color="text.primary"
                    onClick={() => {
                      handleAddVendorDialogOpen();
                      handleClose();
                      // this.setState({
                      //   rows: [],
                      // });
                    }}
                    size="small"
                  >
                    <AddCircleIcon sx={{ color: "text.primary" }} />
                  </IconButton>
                </Tooltip>
                {(userInfo?.is_super_user === "Y" ||
                  masterPermission.linking === "Y") && (
                    <Tooltip title="Link Unlink Vendor(s)">
                      <IconButton
                        color="text.primary"
                        onClick={() => {
                          handlelinkVendorToItemOpen();
                        }}
                        size="small"
                      >
                        <LinkIcon sx={{ color: "text.primary" }} />
                      </IconButton>
                    </Tooltip>
                  )}</>)}
            <IconButton
              color="text.primary"
              onClick={() => {
                handleSendRfqNewVendorsItemClose();
                // this.setState({
                //   rows: [],
                // });
              }}
              size="small"
            >
              <CloseIcon sx={{ color: "text.primary" }} />
            </IconButton>
          </Box>
        </DialogTitle>
        {(InquiryData?.status !== "Expired" || InquiryData?.status !== "Closed") ? (
          <DialogContent dividers sx={{ p: 0 }}>
            <Box
              sx={{
                backgroundColor: "var(--grey-0)",
                borderBottom: "1px solid var(--bluegrey-500)",
                ".MuiTabs-root": {
                  minHeight: "36px",
                },
                ".MuiTab-root": {
                  minHeight: "36px",
                  padding: "8px 16px",
                  textTransform: "capitalize"
                }
              }}
            >
              <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab label="Linked Vendors" />
                {companyInfo?.common_vendor_login === "Y" && <Tab label="Import Vendors" />}
              </Tabs>
            </Box>
            <Box>
              {/* <Divider /> */}
              {tabIndex === 0 && (
                <Box>
                  <SendRfqCategoryVendors
                    key={itemVendorsKey}
                    handleCategoryVendorsReload={handleCategoryVendorsReload}
                    handlelinkVendorToCategoryOpen={handlelinkVendorToItemOpen}
                    handleAddVendorDialogOpen={handleAddVendorDialogOpen}
                    handleClose={() => {
                      handleSendRfqNewVendorsItemClose();
                    }}
                    countVendor={countVendor}
                    vendorsDetails={vendorsDetails}
                    inquiryData={InquiryData}
                    itemSpecificVendorFlag={itemSpecificVendorFlag ? "item" : "category"}
                    linkVendorToItemOpen={linkVendorToItemOpen}
                    newlyVendorIdToItem={newlyVendorIdToItem}
                    setNewlyVendorIdToItem={setNewlyVendorIdToItem}
                    handleNewlyVendorIdChange={handleNewlyVendorIdChange}
                    handlelinkVendorToItemOpen={handlelinkVendorToItemOpen}
                    isCancelAction={cancelFlag}
                    setCancelFlag={setCancelFlag}
                    cancelCounter={cancelCounter}
                    fetchData={fetchData}
                    updateNewVendorFlag={updateNewVendorFlag}
                    selectedCategory={selectedCategory}
                    itemIdsCSV={itemIdsCSV}
                    itemSpecificFlag={true}
                    uniqueVendor={uniqueVendor}
                    inquiryJsonTemplates={inquiryJsonTemplates}
                    sessionID={sessionID}
                    fetchVendorsDetails={fetchVendorsDetails}
                    handleVendorsReload={
                      !itemSpecificVendorFlag
                        ? handleCategoryVendorsReload
                        : handleItemizeVendorsReload
                    }
                  />
                </Box>
              )}
              {companyInfo?.common_vendor_login === "Y" && tabIndex === 1 && (
                <Box>
                  <SendRfqToNewVendorCategory
                    handleClose={() => {
                      handleSendRfqNewVendorsItemClose();
                    }}
                    categoryId={categoryId}
                    handleAddVendorDialogOpen={handleAddVendorDialogOpen}
                    handlelinkVendorToCategoryOpen={handlelinkVendorToCategoryOpen}
                    inquiryNumber={InquiryData?.number}
                    handleVendorsReload={
                      !itemSpecificVendorFlag
                        ? handleCategoryVendorsReload
                        : handleItemizeVendorsReload
                    }
                  />
                </Box>
              )}
            </Box>
          </DialogContent>) :
          (
            <DialogContent dividers sx={{
              p: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
              textAlign: "center"
            }}>
              <Stack spacing={1} alignItems="center">
                <img src={ValidityError} alt="RFQ validity has expired" />
                <Typography color={"error"} sx={{ fontWeight: "bold" }}>{InquiryData?.status === "Expired" ? "RFQ validity has expired, Please extend RFQ validity." : "Action can not be taken, RFQ is closed."}</Typography>
              </Stack>
            </DialogContent>
          )}
      </Dialog>

      {addVendorDialogOpen && (
        <AddNewVendor
          open={addVendorDialogOpen}
          currentVendorID={currentVendorID}
          currentVendorName={currentVendorName}
          handleClose={handleAddVendorDialogClose}
          selectedCategory={selectedCategory}
          categoryId={categoryId}
          updateVendorList={updateVendorList}
          handleVendorsReload={
            !itemSpecificVendorFlag
              ? handleCategoryVendorsReload
              : handleItemizeVendorsReload
          }
        // isVendorSelected={!!this.props.selectedVendors?.length}
        />
      )}

      {/* Inquiry Logs */}
      {openInquiryLogs && (
        <InquiryLogs
          openDialogueBox={openInquiryLogs}
          setOpenDialogueBox={setOpenInquiryLogs}
          handleClose={handleInquiryLogsClose}
          sessionID={sessionID}
          selectedId={InquiryId}
          inquiryNumber={InquiryData?.number}
        // selectedItem={selectedItem}
        // setSelectedItem={setSelectedItem}
        />
      )}

      {/* Extend Validity Thread */}
      {openExtendValidityThread && (
        <ExtendValidityThreads
          openDialogueBox={openExtendValidityThread}
          setOpenDialogueBox={setOpenExtendValidityThread}
          handleClose={handleExtendValidityThreadClose}
          sessionID={sessionID}
          selectedId={InquiryId}
          inquiryNumber={InquiryData?.number}
        // selectedItem={selectedItem}
        // setSelectedItem={setSelectedItem}
        />
      )}
      {/* Link unlink vendor to category */}
      {linkVendorToCategoryOpen && (
        <LinkSendRfqCategoryVendor
          open={linkVendorToCategoryOpen}
          sessionId={sessionID}
          categoryId={categoryId}
          selectedCategory={selectedCategory}
          countVendor={countVendor}
          handleCategoryVendorsReload={handleCategoryVendorsReload}
          updateCartItemsDetails={updateCartItemsDetails}
          master_type={itemSpecificVendorFlag ? "item" : "category"}
          handleClose={() => {
            setLinkVendorToCategoryOpen(false);
            (!itemSpecificVendorFlag ? handleCategoryVendorsReload() : handleItemizeVendorsReload());
          }}
        // isVendorSelected={!!selectedVendors?.length}
        />
      )}
      {/* Link unlink vendor to category */}

      {linkVendorToItemOpen && (
        <LinkSendRfqItemVendor
          open={linkVendorToItemOpen}
          handleClose={() => {
            setSelectedVendors([]);
            setNewlyVendorIdToItem(false);
            setLinkVendorToItemOpen(false);
            (!itemSpecificVendorFlag ? handleCategoryVendorsReload() : handleItemizeVendorsReload());
            // reloadGridData()
            // window.location.reload()
            // dispatch(setRfqListInfo(sessionID, [
            //   {
            //     "start": startDate,
            //     "end": endDate
            //   }
            // ]))
          }}
          updateCartItemsDetails={updateCartItemsDetails}
          newlyVendorIdToItem={newlyVendorIdToItem}
          setNewlyVendorIdToItem={setNewlyVendorIdToItem}
          itemsList={itemsList}
          ItemsListData={ItemsListData}
          // handleItemizeVendorsReload={this.handleItemizeVendorsReload}
          cartCheckedItems={checkedItems}
          selectedCategory={selectedCategory}
          inquiryData={InquiryData}
        />
      )}
    </React.Fragment>
  );
}
