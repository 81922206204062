import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

export const DialogSlabRange = styled(Dialog)(({ theme }) => ({
  ".SlabRange-Box": {
    ".MuiTableContainer-root": {
      height: "calc(100vh - 188px)",
    },
    ".MuiTable-root": {
      // tableLayout: "fixed",
      borderCollapse: "separate",
    },
    ".ItemUser-Box": {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      width: "calc(100vw - 70px)",
      padding: "4px 12px",
      position: "sticky",
      left: "0",
      zIndex: "10",
    },
    ".ItemList-TabsHeader": {
      display: "flex",
      position: "sticky",
      top: "0",
      zIndex: "11",
      background: "var(--bg-white)",
      borderTop: "1px solid var(--bluegrey-500)",
      borderBottom: "1px solid var(--bluegrey-500)",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    ".MS-Tital": {
      width: "100%",
      padding: "8px 16px",
      alignItems: "center",
    },
    ".ItemTabs-Box": {
      maxWidth: "100%",
    },
    ".ItemList-Table": {
      borderBottom: "1px solid var(--bluegrey-500)",
      ".MuiTableRow-head .MuiTableCell-root": {
        whiteSpace: "nowrap",
      },
    },
    ".SlabRang-Body table": {
      tableLayout: "fixed",
    },
    ".SlabRang-Body table tr th, .SlabRang-Body table tr td": {
      width: "285px",
      minWidth: "285px",
      padding: "5px 12px",
      backgroundColor: "var(--bg-white)",
      fontSize: "14px",
      color: "text.primary",
      textTransform: "capitalize",
    },
    ".SlabRang-Body table tr th": {
      backgroundColor: "var(--grey-100)",
      "&:first-of-type": {
        zIndex: "10 !important",
      },
    },
    ".SlabRang-Body .MuiCollapse-entered table tr th": {
      top: "50px",
    },
    ".SlabRang-Body table tr th:first-of-type, .SlabRang-Body table tr td:first-of-type":
      {
        position: "sticky",
        left: "0",
        zIndex: "3",
        [theme.breakpoints.down("md")]: {
          width: "200px",
          minWidth: "200px",
        },
        [theme.breakpoints.down("sm")]: {
          width: "150px",
          minWidth: "150px",
        },
      },
    ".MuiTableRow-root > td.HiddentCell": {
      borderBottom: "none",
      padding: "0",
    },
    ".MuiTable-root .MuiTableRow-root .MuiTableCell-root:not(:last-of-type)": {
      borderRight: " 1px solid var(--bluegrey-500)",
    },
    ".SlabRang-Head tr th": {
      width: "100%",
    },
    ".SlabRang-Body table tr th:last-of-type, .SlabRang-Body table tr td:last-of-type":
      {
        width: "100%",
        padding: "0",
        borderRight: "1px solid var(--bluegrey-500)",
      },
    ".SlabRang-Head tr th": {
      borderBottom: "2px solid var(--bluegrey-500)",
      background: "var(--grey-200)",
      textTransform: "capitalize",
      padding: "0",
      zIndex: "11",
      borderRight: "1px solid var(--bluegrey-500)",
    },
    "table tr td > a": {
      color: "var(--primary-500)",
    },
  },
}));
