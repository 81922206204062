import axios from "axios";
import React, { Component } from "react";
import { getSymbol } from "./CurrencySymbol";
import {
  Select,
  FormControl,
  MenuItem,
  Button,
  Checkbox,
  Typography,
  TextField,
  Tooltip,
  InputAdornment,
  Link,
  FormControlLabel,
  Badge,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  getArtworkDescription,
  getMaterialSpecificationFile,
  getmatspecghclfile,
} from "../../config/apiUrl";
import "../../pages/style.css";
import { openSnackbar } from "../../redux/actions/snackbarAction";
import EstimatedValue from "../Dialogs/EstimatedValue";
import DeliveryScheduleDetails from "../Dialogs/DeliveryScheduleDetails";
import moment from "moment";
import { ErrorOutline, Update } from "@mui/icons-material";
import { OutlineTextfieldLabel } from "../TextField";
import { connect } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
//import prconfig from "../../config/prconfig"
class ItemizeField extends Component {
  state = {
    deliveryScheduleOpen: false,
    estimatedValueOpen: false,
    value: "",
    count: 0,
  };
  componentDidMount() {
    
    this.setState({
      value: this.props.FieldState?.value,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.FieldState?.value !== prevProps.FieldState?.value) {
      this.setState({
        value: this.props.FieldState?.value,
      });
    }
  }
  setValue = (evt) => {
    this.setState({
      value: evt.target.value,
    });
  };
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.value !== nextState.value) {
      return true;
    }
    if (this.state.estimatedValueOpen !== nextState.estimatedValueOpen) {
      return true;
    }
    if (this.state.deliveryScheduleOpen !== nextState.deliveryScheduleOpen) {
      return true;
    }
    if (this.props.FieldState?.value !== nextProps.FieldState?.value) {
      return true;
    }
    if (this.props.FieldState?.error !== nextProps.FieldState?.error) {
      return true;
    }
    if (this.props.FieldState?.options !== nextProps.FieldState?.options) {
      return true;
    }
    if (this.props.upd_budget_master !== nextProps.upd_budget_master) {
      return true;
    }
    if (this.props.qty !== nextProps.qty) {
      return true;
    }
    if (this.props.rate !== nextProps.rate) {
      return true;
    }
    return false;
  }
  // handleMaterialSpecificationClick = () => {
  //   if (this.props.destination) {
  //     axios
  //       .get(
  //         getMaterialSpecificationFile(
  //           this.props.item.id,
  //           this.props.destination
  //         )
  //       )
  //       .then((response) => {
  //         if (response.data.path) {
  //           let a = document.createElement("a");
  //           a.href = response.data.path;
  //           a.download = "MaterialSpecificationFile";
  //           a.target = "_blank";
  //           a.rel = "noopener noreferrer";
  //           a.click();
  //         }
  //       });
  //   } else {
  //     this.props.openSnackbar("error", "please select Destination first!");
  //   }
  // };

  // handlematspecghclfileClick = () => {
  //   let pr_dest_code = "";
  //   if (this.props.prlineitems[this.props.item.id]?.length > 0) {
  //     pr_dest_code = this.props.LATEST("Destination", this.props.item);
  //   }
  //   axios
  //     .get(getmatspecghclfile(1, this.props.item.code, "Inquiry", pr_dest_code)) // static 1
  //     .then((response) => {
  //       if (response.data.path) {
  //         let a = document.createElement("a");
  //         a.href = response.data.path;
  //         a.download = "Matspecghclfile";
  //         a.target = "_blank";
  //         a.rel = "noopener noreferrer";
  //         a.click();
  //       }
  //     });
  // };

  ArtworkDescription = () => {
    let batch_no = this.props.batch_id;
    if (this.props.prlineitems[this.props.item.id]?.length > 0) {
      batch_no = this.props.LATEST("PRBatchNo", this.props.item);
    }
    axios
      .get(getArtworkDescription(this.props.item.code, batch_no))
      .then((response) => {
        if (response.data.path) {
          let a = document.createElement("a");
          a.href = response.data.path;
          a.download = "ArtworkDescription";
          a.target = "_blank";
          a.rel = "noopener noreferrer";
          a.click();
        }
      });
  };

  render() {
    let { item, cell, qty, rate, base_currency, readOnly } = this.props;
    if(cell.Identifier=='ProductQuantity' && this.props.serviceRFQ){
     //readOnly = false;
      //if(this.props.serviceRFQ){
        readOnly = true;
      //}
    }
    let unit;
    let currency;
    if (cell.UnitsFlag == 1) {
      unit = item?.unit;
    }
    if (cell.UnitsFlag == 31) {
      unit = "Pack";
    }
    if (cell.CurrencyFormula === "BuyerLocalCurrency") {
      currency = base_currency; //`/${item?.unit}`;
      if (unit) unit = `/${unit}`;
    }

    let matserCheckbox =
      cell.Identifier === "EstimatedCost" ||
      cell.Identifier === "EstimatedCostForeign" ||
      cell.Identifier === "Budgetedrate" ||
      cell.Identifier === "BudgetedrateForeign";
    let error, ErrorMessage, name, value, DynamicDefaultValue;
    if (this.props.FieldState) {
      error = this.props.FieldState?.error;
      ErrorMessage = this.props.FieldState?.ErrorMessage;
      name = this.props.FieldState?.HTMLAttributeName;
      value = this.props.FieldState?.value;
      DynamicDefaultValue = this.props.FieldState?.DynamicDefaultValue;
    }
    if (this.props?.FieldState?.editableJsonField) {
      return ( 
        <Tooltip title={this.props?.FieldState?.displayTxt} placement="bottom-start">
          <Typography
            style={{
              // maxWidth: "240px",
              // minWidth: "180px",
              // minHeight: "36px",
              // height: "36px",
              // whiteSpace: "nowrap",
              // overflow: "hidden",
              // textOverflow: "ellipsis",
              // padding: "8.5px 8.5px",
            }}
            variant="body2"
            noWrap
            >
            {this.props?.FieldState?.displayTxt}
          </Typography>
        </Tooltip>
      )
    }
    else if (cell.Identifier === "EstimatedValue") {
      return (
        <React.Fragment>
          <Button
            color="primary"
            aria-controls="fade-menu"
            aria-haspopup="true"
            onClick={() => {
              this.setState({
                estimatedValueOpen: true,
              });
            }}
            disabled
            variant="contained"
            size="small"
          >
            Details
          </Button>

          <EstimatedValue
            open={this.state.estimatedValueOpen}
            qty={qty}
            rate={rate}
            unit={item?.unit}
            currency={base_currency}
            handleClose={() => {
              this.setState({
                estimatedValueOpen: false,
              });
            }}
          />
        </React.Fragment>
      );
    } else if (
      cell.Identifier.includes("DeliverySchedule") &&
      cell.ElementType === "TA") 
    {
      let value = "";
      if(this.props.FieldState){
      value = this.props.FieldState["value"];
        if(value){
          value = value.split(";").length;
        }else{
          value = '';
        }
      }
      return (
        <React.Fragment>
          {value ? (
            <Badge color="primary" badgeContent={value}>
              <Button
                startIcon={<CalendarTodayIcon />}
                onClick={() => {
                  this.setState({
                    deliveryScheduleOpen: true,
                  });
                }}
                variant="text">
                Add Schedule
                {error ? (
                <Tooltip title={ErrorMessage}>
                <ErrorOutline sx={{ color: "error.main", ml: 1, }} />
                </Tooltip>
                ) : null}
              </Button>
            </Badge>
            ) :   (
          <Button
            startIcon={<CalendarTodayIcon />}
            onClick={() => {
              this.setState({
                deliveryScheduleOpen: true,
              });
            }}
            variant="text">
              Add Schedule 
            {error ? (
              <Tooltip title={ErrorMessage}>
            <ErrorOutline sx={{ color: "error.main", ml: 1, }} />
            </Tooltip>
            ) : null}
          </Button>
            )}
          {this.state.deliveryScheduleOpen && (
            <DeliveryScheduleDetails
              open={this.state.deliveryScheduleOpen}
              unit={item.unit}
              handleClose={() => {
                this.setState({
                  deliveryScheduleOpen: false,
                });
              }}
              value={this.props.FieldState && this.props.FieldState["value"]}
              updateFieldValue={(newValue) => {
                this.props.updateDeliveryScheduleFieldValue(
                  newValue,
                  item?.id,
                  cell.FieldId
                );
              }}
              readonly={readOnly}
            />
          )}
        </React.Fragment>
      );
    } else if (cell.Identifier === "ArtworkDescription") {
      return (
        <Button
          component="Text"
          color="primary"
          onClick={() => this.ArtworkDescription()}>
          {cell.FieldLabel}
        </Button>
      );
    } else if (
      cell.Identifier === "BasicHistoricalPrice" ||
      cell.Identifier === "LandedHistoricalPrice" ||
      cell.Identifier === "DateHP"
    ) {
      return <Typography>{DynamicDefaultValue}</Typography>;
    } 
     else if (cell.Identifier === "ItemMasterSPEC") {
      return (
        <React.Fragment>
          {/* <Tooltip
          title={item?.specifications}
          placement="bottom-start"
          disableInteractive
        >
          <Typography noWrap sx={{ maxWidth: '240px' }} >
            {item?.specifications}
          </Typography>
        </Tooltip> */}
          <textarea
            style={{
              maxWidth: "300px",
              minWidth: "240px",
              // resize: "vertical",
              minHeight: "36px",
              height: "36px",
              padding: "8.5px 8.5px",
              font: "inherit",
              letterSpacing: "inherit",
              color: "currentColor",
              border: error ? "1px solid #ff5547" : "1px solid #a6c1d3",
            }}
            id={name}
            name={name}
            readOnly={readOnly}
            value={item?.specifications}
            onChange={this.setValue}
            onBlur={(e) => {
              this.props.handleChange(e, cell.FieldId);
            }}
            disabled={true}
            className="template_textarea"
          />
        </React.Fragment>
      );
    } else if (cell.Identifier === "MasterHSNCode") {
      return (
        <React.Fragment>
          <Typography>{item?.hsn_code}</Typography>
        </React.Fragment>
      );
    } else if (cell.ElementType === "T") {
      value = cell.defaultValue || this.state.value;

      return (
        <React.Fragment>
          <Tooltip
            title={
              value && unit
                ? `${value} ${unit}`
                : value?.length > 28
                ? `${value}`
                : ""
            }
            disableInteractive
            placement="bottom-start">
            <Typography
              style={{
                maxWidth: "240px",
                minWidth: "240px",
                minHeight: "36px",
                height: "36px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                padding: "8.5px 8.5px",
              }}>
              {currency && getSymbol(this.props.base_currency)}
              {value} {unit ? unit : ""}
            </Typography>
          </Tooltip>
          {/* {matserCheckbox && (
            <React.Fragment>
              <Tooltip
                title="Update in master"
                disableInteractive
                placement="bottom-start"
              >
                <Button
                  style={{ marginLeft: "1rem" }}
                  variant={
                    this.props.upd_budget_master
                      ? "icon-contained"
                      : "icon-outline"
                  }
                  color="primary"
                  onClick={() => {
                    this.props.handleUpdateInMasterClick(item.id, cell.FieldId);
                  }}
                >
                  <Update />
                </Button>
              </Tooltip>
            </React.Fragment>
          )} */}
        </React.Fragment>
      );
    } else if (cell.ElementType === "TB") {
      return (
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <TextField
            id={name}
            fullWidth
            error={error}
            variant="outlined"
            size="small"
            placeholder={cell.FieldLabel}
            type="text"
            name={name}
            value={this.state.value}
            onChange={readOnly ? "" : this.setValue}
            readOnly={readOnly}
            onBlur={(e) => {
              this.props.handleChange(e, cell.FieldId, cell.Identifier);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  title={ErrorMessage}
                  sx={{
                    "&.MuiInputAdornment-root": {
                      fontSize: "12px",
                      fontWeight: 500,
                      color: "text.primary",
                    },
                  }}>
                  {error && <ErrorOutline style={{ color: "red" }} />}
                  {unit ? unit : ""}
                </InputAdornment>
              ),
              startAdornment: currency && (
                <InputAdornment position="start">
                  {getSymbol(this.props.base_currency)}
                </InputAdornment>
              ),
            }}
          />

          {matserCheckbox && (
            <React.Fragment>
              <Tooltip
                title="Update in master"
                disableInteractive
                placement="bottom-start">
                <Button
                  style={{ marginLeft: "1rem" }}
                  variant={
                    this.props.upd_budget_master
                      ? "icon-contained"
                      : "icon-outline"
                  }
                  color="primary"
                  onClick={() => {
                    this.props.handleUpdateInMasterClick(item.id, cell.FieldId);
                  }}>
                  <Update />
                </Button>
              </Tooltip>
            </React.Fragment>
          )}
        </div>
      );
    } else if (cell.ElementType === "TA") {
      return (
        <React.Fragment>
          <textarea
            style={{
              width: "100%",
              resize: "vertical",
              minHeight: "36px",
              height: "36px",
              padding: "8.5px 14px",
              font: "inherit",
              letterSpacing: "inherit",
              color: "currentColor",
              border: error ? "1px solid #ff5547" : "1px solid #a6c1d3",
            }}
            id={name}
            name={name}
            readOnly={readOnly}
            value={this.state.value}
            onChange={readOnly ? "" : this.setValue}
            onBlur={(e) => {
              this.props.handleChange(e, cell.FieldId);
            }}
            className="template_textarea"
          />
        </React.Fragment>
      );
    } else if (cell.ElementType === "M") {
      //let value = this.state.value;
      //console.log("TS ==",cell.Identifier);
      return (
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={!!value}
              name={name}
              onChange={(e) => {
                this.props.handleChange(e, cell.FieldId);
              }}
            />
          }
          label={cell.FieldLabel}
        />
      );
    } else if (cell.ElementType === "DT") {
      // let value = this.state.value;
      if (typeof value === "string") {
        let arr = value.split("/");
        if (arr.length > 1) {
          value = `${arr[2]}-${arr[1]}-${arr[0]}`;
        }
      }
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            minDate={new Date()}
            format="dd/MM/yyyy"
            slotProps={{
              textField: {
                className: "template_Date_picker template_textbox",
                size: "fullWidth",
                title: error
                  ? moment(value).isValid()
                    ? cell.ErrorMessage
                      ? cell.ErrorMessage
                      : "Delivery date should be greater than or equal to current date"
                    : "invalid date"
                  : "",
                placeholder: cell.FieldLabel,
                label: cell.FieldLabel,
                error: error,
                readOnly: true,
              },
            }}
            name={name}
            value={new Date(value) || null}
            onChange={(newVal) => {
              this.props.handleDateChange(
                newVal ? moment(newVal) : newVal,
                cell.FieldId,
                cell.Validation
              );
            }}
          />
        </LocalizationProvider>
      );
    } else if (cell.ElementType === "F") {
      return (
        <OutlineTextfieldLabel
          type="file"
          name={name}
          value={value}
          error={error}
          helperText={error && ErrorMessage}
          onChange={(e) => {
            this.props.handleChange(e, cell.FieldId);
          }}
        />
      );
    } else if (cell.ElementType === "S") {
      return (
        <FormControl fullWidth error={error} size="small">
          <Select
            fullWidth
            className="template_dropdown"
            // style={{ height: "40px" }}
            displayEmpty
            name={name}
            value={value || ""}
            onChange={(e) => {
              this.props.handleChange(e, cell.FieldId);
            }}
            endAdornment={
              error && (
                <InputAdornment
                  style={{ marginRight: "10px" }}
                  position="end"
                  title={ErrorMessage}>
                  <ErrorOutline style={{ color: "red" }} />
                </InputAdornment>
              )
            }>
            <MenuItem value="">
              <em>select</em>
            </MenuItem>
            {this.props.FieldState &&
              this.props.FieldState["options"]?.length > 0 &&
              (this.props.FieldState["options"] === "loading" ? (
                <MenuItem value="">Loading...</MenuItem>
              ) : (
                Array.isArray(this.props.FieldState["options"]) &&
                this.props.FieldState["options"]?.map((option, id) => {
                  return (
                    <MenuItem
                      key={id}
                      value={
                        option.dropdown_element_name
                          ? this.props.default_dropdown_type === "10" || cell.Identifier === "Destination" || cell.Identifier === "DestinationForeign"
                            ? `${option.dropdown_element_name}-${option.dropdown_element_code}`
                            : `${option.dropdown_element_code}-${option.dropdown_element_name}`
                          : option.code
                      }>
                      {/* {option.dropdown_element_name || option.name} */}
                      {/* {
                        option.dropdown_element_name
                          ? this.props.default_dropdown_type === "10"
                            ? `${option.dropdown_element_name}-${option.dropdown_element_code}`
                            : `${option.dropdown_element_code}-${option.dropdown_element_name}`
                          : option.code
                      } */}
                      {option.dropdown_element_name
                        ? option.dropdown_element_code ===
                          option.dropdown_element_name
                          ? option.dropdown_element_name
                          : `${option.dropdown_element_code}-${option.dropdown_element_name}`
                        : option.name}
                    </MenuItem>
                  );
                })
              ))}
          </Select>
        </FormControl>
      );
    } else if (cell.ElementType === "L") {
      return (
        <Link
          component="button"
          variant="body2"
          target="_blank"
          rel="noopener noreferrer">
          {cell.FieldLabel}
        </Link>
      );
    } else {
      return cell.FieldLabel;
    }
  }
}
const mapStateToProps = (state) => ({
  prlineitems: state.prrfqreducer.setPrLineItemsData,
});
export default connect(mapStateToProps, {
  openSnackbar,
})(ItemizeField);
