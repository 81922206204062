import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setSelectedItem,
  setSearchedItem,
  resetSearchedItems,
  setItemSpecifications,
} from "../../redux/actions/itemActions";
import { getVendors } from "../../redux/actions/vendorActions";
import { DataGridPro, GridFooter, GridFooterContainer, GridOverlay } from "@mui/x-data-grid-pro";
import { Grid, Typography, Button, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { AddCircleOutline, LinkOutlined } from "@mui/icons-material";
import { addTocart } from "../Utils/addToCart";
import { openSnackbar } from "../../redux/actions/snackbarAction";
import _ from "lodash";

const columns = [
  {
    field: "name",
    headerName: "Item Name",
    width: 230,
    align: "left",
    textTransform: "uppercase",
    renderCell: (params) => {
      return (
        <React.Fragment>
          <Typography variant="body2" fontWeight={500}>
            {_.unescape(params.row.name)}
          </Typography>
        </React.Fragment>
      );
    },
  },
  {
    field: "code",
    headerName: "Item Code",
    headerAlign: "left",
    align: "left",
    width: 200,
    textTransform: "uppercase",
    renderCell: (params) => {
      return (
        <React.Fragment>
          <Typography variant="body2" fontWeight={400}>
            {_.unescape(params.row.code)}
          </Typography>
        </React.Fragment>
      );
    },
  },
  {
    field: "unit",
    headerName: "Item Unit",
    headerAlign: "left",
    align: "left",
    flex: 1,
    textTransform: "uppercase",
    renderCell: (params) => {
      return (
        <React.Fragment>
          <Typography variant="body2" fontWeight={400}>
            {_.unescape(params.row.unit)}
          </Typography>
        </React.Fragment>
      )
    },
  },
];


class ItemSearchResult extends Component {
  state = {
    selectionModel: [],
    rows: [],
    page: 1,
    lastPage: 1,
    isFilterActive: false,
    filteredRows: [],
    filterDataCurrentPage: 1,
    filterDataLastPage: 1,
    itemValue: "",
    codeValue: "",
    keyword: "",
  };
  /*
  static getDerivedStateFromProps(props, state) {
    if (!!!props.searchedItemsError) {
      if (state.isFilterActive) {
        if (props.selectedItems.length) {
          const newRows = [];
          props.searchedItems.forEach((avail) => {
            if (
              !props.selectedItems.some((element) => {
                return element.id === avail.id;
              })
            ) {
              //rows will contain unique items only
              newRows.push(avail);
            }
          });
          let finalRows = newRows;
          let jsonObject = finalRows.map(JSON.stringify);
          let uniqueSet = new Set(jsonObject);
          let uniqueRows = Array.from(uniqueSet).map(JSON.parse);

          return {
            rows: uniqueRows,
          };
        } else {
          return {
            rows: props.searchedItems,
          };
        }
      } else {
        if (props.selectedItems.length) {
          const newRows = [];
          props.searchedItems.forEach((avail) => {
            if (
              !props.selectedItems.some((element) => {
                return element.id === avail.id;
              })
            ) {
              //rows will contain unique items only
              newRows.push(avail);
            }
          });
          let finalRows;

          if (state.keyword === props.searchVal) {
            // append
            finalRows = [...state.rows, ...newRows];
          } else {
            // do not append
            finalRows = newRows;
          }
          let jsonObject = finalRows.map(JSON.stringify);
          let uniqueSet = new Set(jsonObject);
          let uniqueRows = Array.from(uniqueSet).map(JSON.parse);
          return {
            rows: uniqueRows,
            page: props.searchedItemsMeta.current_page,
            lastPage: props.searchedItemsMeta.last_page,
            keyword: props.searchVal,
          };
        } else {
          let finalRows;

          if (state.keyword === props.searchVal) {
            // append
            finalRows = [...state.rows, ...props.searchedItems];
          } else {
            // do not append
            finalRows = props.searchedItems;
          }

          let jsonObject = finalRows.map(JSON.stringify);
          let uniqueSet = new Set(jsonObject);
          let uniqueRows = Array.from(uniqueSet).map(JSON.parse);
          return {
            rows: uniqueRows,
            page: props.searchedItemsMeta.current_page,
            lastPage: props.searchedItemsMeta.last_page,
            keyword: props.searchVal,
          };
        }
      }
    } else {
      return {
        selectionModel: [],
        rows: [],
        page: 1,
        lastPage: 1,
        isFilterActive: false,
        filteredRows: [],
        filterDataCurrentPage: 1,
        filterDataLastPage: 1,
        itemValue: "",
        codeValue: "",
        keyword: "",
      };
    }
  }
  */
  // componentWillUnmount() {
  //  this.props.resetSearchedItems();
  // }
  // handleScrollEnd = () => {
  //   if (this.state.page !== this.state.lastPage) {
  //     this.props.setSearchedItem(
  //       this.props.selectedCategory,
  //       this.props.searchVal,
  //       this.state.page + 1,
  //       this.props.sessionId
  //     );
  //     this.setState({
  //       page: this.state.page + 1,
  //     });
  //   }
  // };



  render() {
    return (
      <React.Fragment>
        <div className="search_list_main_datagrid">
          <DataGridPro
            className="custom_xgrid"
            density="compact"                       
            sx={{              
              background: (theme) => theme.palette.grey[0],
              border: 0,
              "& .Mui-disabled": {
                visibility: "hidden",
              },              
            }}
            rows={this.props.rows}
            loading={this.props.loading}
            columns={columns}
            onRowSelectionModelChange={(newSelectionModel) => {
              this.setState({ selectionModel: newSelectionModel });
            }}
            rowSelectionModel={this.state.selectionModel}
            hideFooterPagination
            checkboxSelection
            disableColumnSelector
            disableColumnReorder
            disableColumnPinning
            onRowsScrollEnd={this.props.handleScrollend}
            slots={{ 
              //footer: CustomFooter,
              footer: () => {
                return (
                  <GridFooterContainer >
                    <Box
                      sx={{
                        padding: "3px 8px",
                        backgroundColor: `grey.100`,
                        overflow: "hidden",
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <Grid container>
                        <Grid item xl={7} xs={7} sx={{display: 'flex', }}>
                          <Button
                            variant="text"
                            color="inherit"
                            onClick={() => {
                              // this.props.handleClose();
                              addTocart(this, this.props.rows);
                            }}
                            //startIcon={}
                          >
                            + Add to cart
                          </Button>
                        </Grid>
                        <Grid item xl={5} xs={5} sx={{ textAlign: "right" }}>
                          {/* <ButtonGroup aria-label="outlined group"> */}
                          {(this.props.userInfo?.is_super_user === "Y" ||
                            this.props.masterPermission?.item_master ===
                              "Y") && (
                            <Tooltip title="Add New Item">
                              <IconButton
                                sx={{ color: `grey.600` }}
                                onClick={() => {
                                  this.props.handleClose();
                                  this.props.openAddItemDialog();
                                }}>
                                <AddCircleOutline />
                              </IconButton>
                            </Tooltip>
                          )}
                          {(this.props.userInfo?.is_super_user === "Y" ||
                            this.props.masterPermission?.linking === "Y") && (
                            <Tooltip title="Link Items To Category">
                              <IconButton
                                sx={{ color: `grey.600` }}
                                onClick={() =>
                                  this.props.handleOpenLinkUnlinkItem(false)
                                }>
                                <LinkOutlined />
                              </IconButton>
                            </Tooltip>
                          )}
                          {/* </ButtonGroup> */}
                        </Grid>
                      </Grid>
                    </Box>
                  </GridFooterContainer>
                );
              },
              noRowsOverlay: () => {
                return (
                  <GridOverlay>
                    <Typography>
                      {this.state.isFilterActive
                        ? this.props.filteredItemsError
                          ? this.props.filteredItemsError
                          : "No item(s) found"
                        : this.props.error
                        ? this.props.error
                        : "No item(s) found"}
                    </Typography>
                  </GridOverlay>
                );
              },
            }}
            // isRowSelectable={(params) => {
            //   if (this.props.master_type !== "category"){
            //     return params.row.vendor_count.total_count > 0;
            //   }
            //   return true;
            // }}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  selectedItems: state.itemReducer.selectedItems,
  searchedItems: state.itemReducer.searchedItems,
  searchedItemsLoading: state.itemReducer.searchedItemsLoading,
  searchedItemsError: state.itemReducer.searchedItemsError,
  searchedItemsMeta: state.itemReducer.searchedItemsMeta,
  masterPermission: state.permissionsReducer.masterPermission,
  userInfo: state.userInfoReducer.userInfo,
  sessionId: state.sessionReducer.sessionId,
});

export default connect(mapStateToProps, {
  setSelectedItem,
  getVendors,
  setSearchedItem,
  resetSearchedItems,
  openSnackbar,
  setItemSpecifications,
})(ItemSearchResult);
