import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
// import "../../../pages/style.css";
import _, { debounce } from "lodash";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Badge, Modal, ToggleButton, Tooltip } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ResizableBox } from "react-resizable";
// import "./resize.style.css";
// import More from "../More/More";
import Upload from "../../Dialogs/Upload/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import QuotationFormField from "../QuotationFormField";
import { styled } from "@mui/material/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  List,
  ListItem,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import RupeeIcon from "@mui/icons-material/CurrencyRupee";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Close as CloseIcon } from "@mui/icons-material";
import LandedCostRFQDrawer from "../LandedCostRFQDrawer/LandedCostRFQDrawer";
import QuotationFormFieldView from "../QuotationFormFieldView";
import FileSpecUpload from "../../Dialogs/Upload/FileSpecUpload";
import axios from "axios";
import { postFileSpecifications } from "../../../config/apiUrl";
import UploadDynamicView from "../../Dialogs/Upload/UploadDynamicView";
import { getSymbol } from "../../AccountTemplate/CurrencySymbol";
import LandedCostSlabDrawer from "../LandedCostSlabDrawer/LandedCostSlabDrawer";

// const PageToolbar = styled("div")(({ theme }) => ({

// export default class QuotationFormSectionTable extends Component {
export default function QuotationTemplateSectionTableView(props) {
  const [name, setName] = useState("React");
  const [rows, setRows] = useState([]);
  const [disabledProps, setDisabledProps] = useState({});
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [ModalOpenTotalCost, setModalOpenTotalCost] = useState(false);
  const [ModalTitle, setModalTitle] = useState("");
  const [isCheckedSelected, setIsCheckedSelected] = useState(false);
  const [landedCostRFQ, setLandedCostRFQ] = useState({
    // Start Total Landed Cost For RFQ Drawer script
    right: false,
  });
  const [landedCostSlab, setLandedCostSlab] = useState({
    // Start Total Landed Cost For RFQ Drawer script
    right: false,
  });
  const [updatedFileValue, setUpdatedFileValue] = useState([]);
  const [fileSpecificationsOpen, setFileSpecificationsOpen] = useState(false);
  const [selectedQuotedItems, setSelectedQuotedItems] = useState(
    props.selectedQuotedItems || {}
  );
  const [popHash, setPopHash] = useState({});
  const [state, setState] = useState({
    isScrollActive: false,
    currentItemIndex: null,
    anchorEl: null,
    qty: "",
    rate: "",
    estimatedValueOpen: false,
    specificationsOpen: false,
  });
  const [Loading, setLoading] = useState(true);
  const [iconState, setIconState] = useState({});
  const [RearrangedArray, setRearrangedArray] = useState([]);
  const [Toggle, setToggle] = useState({});
  const [ToggleMS, setToggleMS] = useState({});
  const [specification, setSpecification] = useState([]);
  const [SpecificationText, setSpecificationText] = useState("");
  const [modalData, setModalData] = useState([]);
  const [unit, setUnit] = useState("");
  const [destinationCount, setDestinationCount] = useState(0);
  const [FilteredItems, setFilteredItems] = useState({});
  const [FilteredMainTemplate, setFilteredMainTemplate] = useState({});
  const [FilteredSlabMainTemplate, setFilteredSlabMainTemplate] = useState({});
  const [AllItemsTemplate, setAllItemsTemplate] = useState([]);
  const [TotalCostFIeld, setTotalCostFIeld] = useState({});
  const [PerCost, setPerCost] = useState({});
  const [RateField, setRateFIeld] = useState({});

  // handleChange = (e) => setState({ SlabTableToggle: e.target.checked });

  // const handleDetailsClick = (rowData) => {
  //   setSelectedRowData(rowData);
  //   setModalOpen(true);
  // };
  const handleDetailsClick = (key, index, identifier, fieldlbl, unitFlag) => {
    // Check if mainTemplateQuotation and keys are available
    if (props.mainTemplateQuotation && key !== undefined) {
      const rowData = props.mainMSTemplateQuotation[key][index];
      const unit = props.items[key]?.unit || "";

      // Extract FieldLabels and FieldIds from TotalCost template formula
      const totalCostCell = props.data[0].find(
        (cell) => cell.Identifier === identifier
      );
      const fieldIds = totalCostCell.DefaultValue.Formula.match(
        /\(f\d+\)/g
      ).map((id) => id.slice(2, -1));
      const fieldLabels = fieldIds.map((id) => {
        const correspondingCell = props.data[0].find(
          (cell) => cell.FieldId === id
        );
        // const correspondingCell = AllItemsTemplate.find(
        //   (cell) => cell.FieldId === id
        // );
        return correspondingCell ? correspondingCell.FieldLabel : "";
      });

      const basicPriceRate = props.data[0].find(
        (cell) => cell.Identifier === "Rate"
      );
      let totalCostValue = 0;
      // Evaluate DefaultFormula for each FieldLabel to get values
      const values = fieldLabels.map((label) => {
        const fieldId = props?.data[0].find(
          (cell) => cell.FieldLabel === label
        )?.FieldId;
        let AttributeName = fieldId.replace("f", "");
        // console.log('fieldId', label, fieldId.replace("f", ""), props.CurrentTransaction.template.itemize[key].solution[0].data[0], rowData);

        return {
          label,
          value:
            props.CurrentTransaction.template.itemize[key].solution[index]
              .data[0][AttributeName],
          unit,
        };
      });

      // values.push({ label: "Total Cost", value: totalCostValue });
      // Open modal and pass values and labels
      setModalOpenTotalCost(true);
      setModalTitle(fieldlbl);
      setSelectedRowData(rowData);
      setModalData(values);
      setUnit(unitFlag == 3 ? "Pack" : unit);
    } else {
      console.error("mainTemplateQuotation or key is null or undefined.");
    }
  };

  const handleDetailsClickForSlab = (
    key,
    solutionRowIndex,
    identifier,
    fieldlbl,
    unitFlag
  ) => {
    // Check if mainTemplateQuotation and keys are available
    if (props.slabMainTemplateQuotation && key !== undefined) {
      const rowData = props.slabMainTemplateQuotation[key][solutionRowIndex];
      const unit = props.items[key]?.unit || "";

      // Extract FieldLabels and FieldIds from TotalCost template formula
      const totalCostCell = props.data[0].find(
        (cell) => cell.Identifier === identifier
      );
      const fieldIds = totalCostCell.DefaultValue.Formula.match(
        /\(f\d+\)/g
      ).map((id) => id.slice(2, -1));
      const fieldLabels = fieldIds.map((id) => {
        const correspondingCell = props.data[0].find(
          (cell) => cell.FieldId === id
        );
        // const correspondingCell = AllItemsTemplate.find(
        //   (cell) => cell.FieldId === id
        // );
        return correspondingCell ? correspondingCell.FieldLabel : "";
      });

      const basicPriceRate = props.data[0].find(
        (cell) => cell.Identifier === "Rate"
      );
      let totalCostValue = 0;
      // Evaluate DefaultFormula for each FieldLabel to get values
      const values = fieldLabels.map((label) => {
        const fieldId = props?.data[0].find(
          (cell) => cell.FieldLabel === label
        )?.FieldId;
        let AttributeName = fieldId.replace("f", "");
        // console.log('fieldId', label, fieldId.replace("f", ""), props.CurrentTransaction.template.itemize[key].solution[0].data[0], rowData);

        return {
          label,
          value:
            props.CurrentTransaction.template.itemize[key].solution[0].data[
              solutionRowIndex
            ][AttributeName],
          unit,
        };
      });

      // values.push({ label: "Total Cost", value: totalCostValue });
      // Open modal and pass values and labels
      setModalOpenTotalCost(true);
      setModalTitle(fieldlbl);
      setSelectedRowData(rowData);
      setModalData(values);
      setUnit(unitFlag == 3 ? "Pack" : unit);
    } else {
      console.error("mainTemplateQuotation or key is null or undefined.");
    }
  };

  const handleDetailsClickWithoutSlab = (
    key,
    solutionIndex,
    identifier,
    fieldlbl,
    unitFlag
  ) => {
    console.log(
      "handleDetailsClickWithoutSlab",
      key,
      solutionIndex,
      identifier,
      fieldlbl,
      unitFlag
    );
    // Check if mainTemplateQuotation and keys are available
    if (props.slabMainTemplateQuotation && key !== undefined) {
      const rowData = props.slabMainTemplateQuotation[key][solutionIndex];
      const unit = props.items[key]?.unit || "";

      // Extract FieldLabels and FieldIds from TotalCost template formula
      const totalCostCell = props.data[0].find(
        (cell) => cell.Identifier === identifier
      );
      const fieldIds = totalCostCell.DefaultValue.Formula.match(
        /\(f\d+\)/g
      ).map((id) => id.slice(2, -1));
      const fieldLabels = fieldIds.map((id) => {
        const correspondingCell = props.data[0].find(
          (cell) => cell.FieldId === id
        );
        // const correspondingCell = AllItemsTemplate.find(
        //   (cell) => cell.FieldId === id
        // );
        return correspondingCell ? correspondingCell.FieldLabel : "";
      });

      const basicPriceRate = props.data[0].find(
        (cell) => cell.Identifier === "Rate"
      );
      let totalCostValue = 0;
      // Evaluate DefaultFormula for each FieldLabel to get values
      const values = fieldLabels.map((label) => {
        const fieldId = props?.data[0].find(
          (cell) => cell.FieldLabel === label
        )?.FieldId;
        let AttributeName = fieldId.replace("f", "");
        // console.log('fieldId', label, fieldId.replace("f", ""), props.CurrentTransaction.template.itemize[key].solution[0].data[0], rowData);

        return {
          label,
          value:
            props.CurrentTransaction.template.itemize[key].solution[0].data[
              solutionIndex
            ][AttributeName],
          unit,
        };
      });

      // values.push({ label: "Total Cost", value: totalCostValue });
      // Open modal and pass values and labels
      setModalOpenTotalCost(true);
      setModalTitle(fieldlbl);
      setSelectedRowData(rowData);
      setModalData(values);
      setUnit(unitFlag == 3 ? "Pack" : unit);
    } else {
      console.error("mainTemplateQuotation or key is null or undefined.");
    }
  };

  const test_function = (
    formula,
    placeholders,
    basicPriceRate,
    fieldId,
    key,
    value
  ) => {
    placeholders.forEach((placeholder) => {
      const matchId = placeholder.slice(2, -1);
      let matchValue = props.mainTemplateQuotation[key][matchId]?.value;
      if (matchValue === undefined) {
        let data1 = props.data[0].find((cell) => cell.FieldId === matchId);
        if (data1) {
          matchValue = "(" + data1.DefaultValue.Formula + ")";
          let placeholders11 = "";
          if (data1.DefaultValue.Formula && data1.DefaultValue.Formula !== "") {
            placeholders11 = data1.DefaultValue.Formula.match(/\(f\d+\)/g);
          } else if (data1.DefaultFormula && data1.DefaultFormula !== "") {
            placeholders11 = data1.DefaultFormula.match(/\(f\d+\)/g);
          }
          if (placeholders11 != "") {
            let data_hash = test_function(
              data1.DefaultValue.Formula,
              placeholders11,
              basicPriceRate,
              matchId,
              key,
              0
            );
            if (data_hash.eval) {
              matchValue = eval(data_hash.formula);
            } else {
              matchValue = data_hash.value;
            }
          } else {
            matchValue = data1.DefaultValue || 0;
          }
        } else {
          matchValue = props.mainTemplateQuotation[matchId]?.value || 0;
          if (props.mainTemplateQuotation[matchId]?.Identifier === "GSTType") {
            matchValue = "'" + matchValue + "'";
          }

          if (props.mainTemplateQuotation[matchId]?.Identifier === "GSTValue") {
            matchValue = "'" + (matchValue - 0.0) + "'";
          }
          // data1 = props.additionalInfoTemplate[0].find(
          //   (cell) => cell.FieldId === matchId
          // );
        }
      }
      if (matchValue === "") {
        matchValue = 0;
      }
      if (matchValue !== undefined) {
        // If value is defined, update the result
        if (
          props.mainTemplateQuotation[key][matchId]?.Identifier === "GSTType"
        ) {
          matchValue = "'" + matchValue + "'";
        }
        if (
          props.mainTemplateQuotation[key][matchId]?.Identifier === "GSTValue"
        ) {
          matchValue = "'" + (matchValue - 0.0) + "'";
        }
        // "Identifier": "GSTValue",
        if (basicPriceRate.FieldId === fieldId) {
          value = matchValue || 0;
          return { formula: formula, value: value, eval: 0 };
        } else {
          formula = formula.replace(placeholder, matchValue);
        }
      }
    });
    return { formula: formula, value: value, eval: 1 };
  };
  const handleUplaodSpecificationOpen = (
    index,
    specifications,
    specificationText
  ) => {
    setUpdatedFileValue(specifications);
    setFileSpecificationsOpen(!fileSpecificationsOpen);
    setPopHash({ index: index, type: "specification" });
    getExistingFiles(specifications);
    setSpecificationText(specificationText);
  };
  const handleUploadClose = () => {
    setFileSpecificationsOpen(false);
    setSpecification([]);
    setLoading(true);
    setSpecificationText("");
  };
  const getExistingFiles = (file_id) => {
    const queryString = file_id
      .map((file) => `file_ids[]=${file.id}`)
      .join("&");

    const url = `${postFileSpecifications}?${queryString}`;
    axios
      .get(url)
      .then((res) => {
        setSpecification(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, " downloadFile error");
        this.props.openSnackbar(
          "error",
          err.response.data?.message
            ? err.response.data?.message
            : "Something went wrong!"
        );
        setLoading(false);
      });
  };

  function rearrangeArray(array) {
    // Step 1: Build a mapping of FieldId to their respective objects
    let fieldMap = {};
    array.forEach((item) => {
      fieldMap[item.FieldId] = item;
    });

    // Step 2: Initialize an empty array to store the rearranged items
    let rearranged = [];

    // Step 3: Helper function to recursively resolve dependencies
    const resolveDependency = (identifier, visited) => {
      if (visited.has(identifier)) {
        throw new Error(
          "Circular dependency detected for FieldId: " + identifier
        );
      }

      visited.add(identifier);

      // Get the item for the current identifier
      const item = fieldMap[identifier];

      // Resolve dependencies recursively
      const matches = item.DefaultValue?.Formula?.match(/\(f\d+\)/g);
      if (matches) {
        matches.forEach((dependency) => {
          const depIdentifier = dependency.substring(2, dependency.length - 1);
          if (fieldMap.hasOwnProperty(depIdentifier)) {
            resolveDependency(depIdentifier, visited);
          }
        });
      }

      // Add the item to the rearranged array if it's not already added
      if (!rearranged.some((i) => i.FieldId === item.FieldId)) {
        rearranged.push(item);
      }

      visited.delete(identifier);
    };

    // Step 4: Iterate over the array to start resolving dependencies
    array.forEach((item) => {
      if (!rearranged.some((i) => i.FieldId === item.FieldId)) {
        resolveDependency(item.FieldId, new Set());
      }
    });

    // Return the rearranged array
    return rearranged;
  }

  const handleAddClick = (event, key) => {
    setIconState((prevState) => ({
      ...prevState,
      [key]: true,
    }));
    setToggle((prevState) => ({
      ...prevState,
      [key]: true,
    }));
    // handleSwitchBoxChange(event, key, true);
  };

  const handleRemoveClick = (event, key) => {
    setIconState((prevState) => ({
      ...prevState,
      [key]: false,
    }));
    setToggle((prevState) => ({
      ...prevState,
      [key]: false,
    }));
    // handleSwitchBoxChange(event, key, false);
  };

  const handleAddClickForMS = (event, key) => {
    setIconState((prevState) => ({
      ...prevState,
      [key]: true,
    }));
    setToggleMS((prevState) => ({
      ...prevState,
      [key]: true,
    }));
    // handleSwitchBoxChange(event, key, true);
  };

  const handleRemoveClickForMS = (event, key) => {
    setIconState((prevState) => ({
      ...prevState,
      [key]: false,
    }));
    setToggleMS((prevState) => ({
      ...prevState,
      [key]: false,
    }));
    // handleSwitchBoxChange(event, key, false);
  };

  useEffect(() => {
    let allItems = [];
    let templateData = props.template;
    let elementTypes = [];

    for (let section in templateData) {
      if (section !== "PARAM") {
        if (section === "ITEMIZE") {
          allItems = allItems.concat(templateData[section][0]);
        } else {
          allItems = allItems.concat(templateData[section]);
        }
      }
    }
    // props?.setItemsTemplate(allItems);
    setAllItemsTemplate(allItems);
    allItems.forEach((items) => {
      if (items.ElementType === "D") {
        elementTypes.push(items);
      }
    });
    let rearrangedArray = rearrangeArray(elementTypes);
    setRearrangedArray(rearrangedArray);
    // props.setRearrangedTemplate(rearrangedArray);
    // console.log("resultinEffect", result);
  }, [props.template]);

  const updateDisabledProps = () => {
    const disabledProps = {};

    for (const key in props.mainTemplateQuotation) {
      const isNegotiation = props.lastTransactionTypeInThread === "Negotiation";
      const lastSelected = props.lastTransactionInThread[key]?.selected === "Y";
      const mainSelected = props.mainTemplateQuotation[key]?.selected === "Y";
      const filteredSelectedNotDataSelected =
        props.filteredSelectedNotData[key]?.selected;

      if (isNegotiation) {
        if (isNegotiation && lastSelected) {
          disabledProps[key] = !lastSelected;
        } else {
          if (!mainSelected && filteredSelectedNotDataSelected == "N") {
            // Allow toggling between 'Y' and 'N' when mainSelected is 'N' and
            // filteredSelectedNotDataSelected is 'N'
            disabledProps[key] = true;
          } else if (mainSelected && filteredSelectedNotDataSelected != "N") {
            disabledProps[key] = true;
          } else if (!mainSelected) {
            // quotation = N , RFQ = Y
            // Enable item if mainSelected matches filteredSelectedNotDataSelected
            disabledProps[key] = false;
          } else if (mainSelected && filteredSelectedNotDataSelected == "N") {
            // quotation = N , RFQ = Y
            // Enable item if mainSelected matches filteredSelectedNotDataSelected
            disabledProps[key] = false;
          } else {
            disabledProps[key] = false;
          }
        }
      } else {
        if (mainSelected && filteredSelectedNotDataSelected == "N") {
          disabledProps[key] = false;
        } else if (mainSelected && filteredSelectedNotDataSelected != "N") {
          disabledProps[key] = false;
        } else if (!mainSelected) {
          disabledProps[key] = true;
        } else if (mainSelected && filteredSelectedNotDataSelected == "N") {
          disabledProps[key] = true;
        } else {
          disabledProps[key] = true;
        }
      }
    }
    setDisabledProps(disabledProps);
  };

  useEffect(() => {
    updateDisabledProps();
  }, [
    props.lastTransactionTypeInThread,
    props.lastTransactionInThread,
    props.mainTemplateQuotation,
  ]);

  useEffect(() => {
    if (props.destination) {
      const destinationsArray = props.destination.split("###");
      setDestinationCount(destinationsArray?.length);
    }
  }, [props.destination]);
  useEffect(() => {
    const filteredItems = {};
    const filterMainTemplate = {};
    Object.keys(props.selectedQuotedItems).forEach((key) => {
      if (props.CurrentTransaction.template.itemize[key]?.selected === "Y") {
        filteredItems[key] = props.selectedQuotedItems[key];
      }
    });
    Object.keys(filteredItems).forEach((key) => {
      if (props.mainTemplateQuotation[key]) {
        filterMainTemplate[key] = props.mainTemplateQuotation[key];
      }
    });
    setFilteredItems(filteredItems);
    setFilteredMainTemplate(filterMainTemplate);
  }, [props.mainTemplateQuotation]);

  useEffect(() => {
    const filterSlabMainTemplate = {};

    Object.keys(props.slabMainTemplateQuotation).forEach((key) => {
      if (props.slabMainTemplateQuotation[key]?.selected === "Y") {
        filterSlabMainTemplate[key] = props.slabMainTemplateQuotation[key];
      }
    });
    setFilteredSlabMainTemplate(filterSlabMainTemplate);
  }, [props.slabMainTemplateQuotation]);
  const getTableBody = () => {
    const filteredItems = {};
    Object.keys(props.selectedQuotedItems).forEach((key) => {
      if (props.CurrentTransaction.template.itemize[key]?.selected === "Y") {
        filteredItems[key] = props.selectedQuotedItems[key];
      }
    });
    let itemsToRender;
    const { SlabTableToggle } = state;
    if (props.selectedQuotedItems || props.selectedQuotedItems[0]) {
      const { selectedQuotedItems, mainTemplateQuotation } = props;
      const filteredItemsMap = new Map(
        Object.entries(filteredItems).map((item) => [item[0], item[1]])
      );
      const reorderedTransactionItemSequence = props?.transactionItemSequence
        .filter((key) => filteredItemsMap.has(key)) // Filter keys that are present in map
        .map((key) => [key, filteredItemsMap.get(key)]);
      // itemsToRender = Object.entries(filteredItems)
      //   .sort(([, a], [, b]) => a.name.localeCompare(b.name))
      itemsToRender = reorderedTransactionItemSequence.map(
        ([key, value], index) => {
          const solutions =
            props.CurrentTransaction.template.itemize[key].solution;
          return (
            <>
              <TableRow key={key}>
                <TableCell
                  sx={{
                    WebkitBoxShadow:
                      state.isScrollActive && "11px 0 19px -2px #bebebe54",
                    boxShadow:
                      state.isScrollActive && "11px 0 19px -2px #bebebe54",
                  }}
                >
                  <Box display="flex" alignItems="center" gap="16px">
                    {solutions?.length !== 1 ? (
                      <div key={key}>
                        {!props.slabFlag &&
                          props.multipleSolutionFlag &&
                          solutions?.length > 1 &&
                          (!iconState[key] ? (
                            <Tooltip
                              title={`Click here to view ${
                                props?.AlternativeOptions || "Multiple Solution"
                              }`}
                              placement="bottom-start"
                              disableInteractive
                            >
                              <Badge
                                color="primary"
                                badgeContent={solutions?.length}
                              >
                                <AddIcon
                                  onClick={(event) =>
                                    handleAddClickForMS(event, key)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </Badge>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={`Click here to close ${
                                props?.AlternativeOptions || "Multiple Solution"
                              }`}
                              placement="bottom-start"
                              disableInteractive
                            >
                              <Badge
                                color="primary"
                                badgeContent={solutions?.length}
                              >
                                <RemoveIcon
                                  onClick={(event) =>
                                    handleRemoveClickForMS(event, key)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </Badge>
                            </Tooltip>
                          ))}
                      </div>
                    ) : (
                      !props.slabFlag &&
                      props.multipleSolutionFlag && (
                        <div style={{ visibility: "hidden" }}>
                          <AddIcon />
                        </div>
                      )
                    )}
                    {props.CurrentTransaction.template.itemize[key].solution[0]
                      .name && ( // This is for including slab ranges in quotation form
                      <Tooltip
                        title={`Include ${props?.displayOptions["label"]}`}
                        placement="bottom-start"
                        disableInteractive
                      >
                        <Switch
                          //  onClick={() => hideComponent("SlabTableToggle")}
                          // checked={SlabTableToggle}
                          // onChange={handleChange}
                          checked={
                            props.CurrentTransaction.template.itemize[key]
                              .solution.length > 1
                              ? true
                              : false
                          }
                          disabled={true}
                          onChange={(evt) => {
                            props.handleSlab(evt, value);
                          }}
                        />
                      </Tooltip>
                    )}

                    <Tooltip // This is for Attachments specific files
                      title="Specification"
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Badge
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        badgeContent={`${value?.files?.length}`}
                        color="primary"
                      >
                        <AttachFileIcon
                          onClick={() =>
                            value?.files?.length > 0 &&
                            handleUplaodSpecificationOpen(
                              key,
                              value.files,
                              value.specification_text
                            )
                          }
                          sx={{ cursor: "pointer" }}
                        />
                      </Badge>
                    </Tooltip>
                    <Tooltip //Item name header starts from here
                      title={_.unescape(value.name)}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Badge
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        badgeContent={
                          props.mainTemplateQuotation[key]
                            ?.added_after_rfq_created
                            ? "New"
                            : null
                        }
                        color="primary"
                        sx={{
                          "& .MuiBadge-badge": {
                            top: "-5px",
                            height: "16px",
                            fontSize: "10px",
                          },
                        }}
                      >
                        <Typography
                          noWrap
                          variant="body2"
                          color="text.primary"
                          fontWeight={500}
                          sx={{
                            width: {
                              lg: `${
                                state.itemCellWidth > 300
                                  ? state.itemCellWidth
                                  : 300
                              }px`,
                              md: `${
                                state.itemCellWidth > 200
                                  ? state.itemCellWidth
                                  : 200
                              }px`,
                              sm: `${
                                state.itemCellWidth > 200
                                  ? state.itemCellWidth
                                  : 200
                              }px`,
                              xs: `${
                                state.itemCellWidth > 125
                                  ? state.itemCellWidth
                                  : 125
                              }px`,
                            },
                            // width: `${
                            //   state.itemCellWidth > 350
                            //     ? state.itemCellWidth
                            //     : 350
                            // }px`,
                            marginLeft: "10px",
                          }}
                        >
                          {_.unescape(value.name)}
                        </Typography>
                      </Badge>
                      {/* {props.mainTemplateQuotation[key]
                        ?.added_after_rfq_created && (
                          <Chip label="New" size="small" color="primary" sx={{ marginLeft: "10px", height: "16px" }} />
                        )} */}
                    </Tooltip>
                  </Box>
                </TableCell>
                {props.data[0]?.find(
                  (obj) =>
                    obj.Identifier === "ProductCode" &&
                    obj.VisibilityFlag === "Y"
                ) && (
                  <TableCell
                  // sx={{
                  //   WebkitBoxShadow:
                  //     state.isScrollActive && "11px 0 19px -2px #bebebe54",
                  //   boxShadow:
                  //     state.isScrollActive && "11px 0 19px -2px #bebebe54",
                  // }}
                  >
                    <Tooltip //Item code header starts from here
                      title={_.unescape(value.code)}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Typography
                        noWrap
                        variant="body2"
                        color="text.primary"
                        fontWeight={500}
                        // sx={{
                        //   width: {
                        //     lg: `${state.itemCellWidth > 300
                        //         ? state.itemCellWidth
                        //         : 250
                        //       }px`,
                        //     md: `${state.itemCellWidth > 200
                        //         ? state.itemCellWidth
                        //         : 200
                        //       }px`,
                        //     sm: `${state.itemCellWidth > 200
                        //         ? state.itemCellWidth
                        //         : 200
                        //       }px`,
                        //     xs: `${state.itemCellWidth > 125
                        //         ? state.itemCellWidth
                        //         : 125
                        //       }px`,
                        //   },
                        // }}
                      >
                        {_.unescape(value.code)}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                )}

                {props.CurrentTransaction.template.itemize[key].solution[0]
                  .name && (
                  <TableCell>
                    <Tooltip //Item code header starts from here
                      title={_.unescape(
                        props.CurrentTransaction.template.itemize[key]
                          .solution[0].name
                      )}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Typography noWrap variant="body2">
                        {_.unescape(
                          props.CurrentTransaction.template.itemize[key]
                            .solution[0].name
                        )}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                )}

                {props?.data[index]?.map((cell) => {
                  const DefaultFormula = cell.DefaultFormula;
                  // Regular expression to extract field IDs
                  const regex = /f(\d+)/g;
                  let match;
                  const fieldIDs = [];

                  // Extract field IDs from DefaultFormula
                  while ((match = regex.exec(DefaultFormula)) !== null) {
                    fieldIDs.push(match[1]);
                  }

                  // Assuming you always have two field IDs in the DefaultFormula
                  const [field1, field2] = fieldIDs;

                  // Initialize FieldState
                  const FieldState = {};

                  // If field IDs are found, set data in FieldState
                  if (field1 && field2) {
                  }
                  if (
                    cell.VisibilityFlag === "Y" ||
                    (cell.DefaultValue &&
                      cell.DefaultValue.Button === "Y" &&
                      cell.ElementType === "D")
                  ) {
                    if (
                      props.multiplePlantsFlag &&
                      cell.Identifier === "GSTType"
                    ) {
                      return null;
                    }
                    if (
                      !(
                        cell.Identifier === "Product" ||
                        cell.Identifier === "ProductCode" ||
                        cell.Identifier === "ProductForeign"
                      )
                    ) {
                      return (
                        <TableCell
                          key={cell.FieldId}
                          sx={{ minWidth: "120px" }}
                        >
                          <QuotationFormFieldView
                            inquiryId={props.inquiryId}
                            batch_id={props.batch_id}
                            LATEST={props.LATEST}
                            diffrentiatedFields={
                              props?.diffrentiatedFields?.[key]?.[0]
                            }
                            cell={cell}
                            item={value}
                            index={index}
                            FieldState={
                              props.mainMSTemplateQuotation[key]?.[0][
                                cell?.FieldId
                              ]
                            }
                            base_currency={props?.base_currency}
                            selectedCurrency={
                              props.CurrentTransaction.template.itemize[key]
                                .solution[0].currency
                            }
                            CurrentTransaction={
                              props.CurrentTransaction.template.itemize[key]
                            }
                            handleChange={(
                              evt,
                              FieldId,
                              Identifier,
                              FieldLabel,
                              ElementType
                            ) =>
                              props.handleChange(
                                evt,
                                index,
                                FieldId,
                                Identifier,
                                FieldLabel,
                                ElementType
                              )
                            }
                            handleUploadFiles={(
                              FieldId,
                              Identifier,
                              itemId,
                              index
                            ) => {
                              props.uploadItemFiles(
                                FieldId,
                                Identifier,
                                itemId,
                                index,
                                props.mainMSTemplateQuotation[key]?.[0][
                                  cell?.FieldId
                                ]
                              );
                            }}
                            SlabTableToggle={SlabTableToggle}
                            // disabled={
                            //   mainTemplateQuotation[key]?.selected !== "Y" // Disable if checkbox is not checked
                            // }
                            disabled={
                              Object.keys(disabledProps).length > 0 &&
                              disabledProps[key]
                            }
                            keys={key.length > 0 && key}
                            solutionIndex={0}
                            handleDetailsClick={handleDetailsClick}
                            destination={props.destination}
                            multiplePlantsFlag={props.multiplePlantsFlag}
                            getMasterCurrencyByFieldId={(id) => props.getMasterCurrencyByFieldId(id)}
                          />
                        </TableCell>
                      );
                    }
                  }
                })}
              </TableRow>
              {props.slab[key] && props.slab[key].isOpen && (
                <TableRow>
                  <TableCell style={{ padding: 0 }} colSpan={100}>
                    <TableContainer
                      className="quotation_template_table slab_range_table"
                      sx={{
                        maxHeight: "400px",
                        overflowY: "auto",
                        borderTop: "solid 1px",
                        borderColor: (theme) => theme.palette.bluegrey[500],
                        borderBottomRightRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                      onScroll={debounce((event) => {
                        if (
                          event.target.scrollLeft === 0 &&
                          state.isScrollActive
                        ) {
                          setState({
                            isScrollActive: false,
                          });
                        } else if (
                          event.target.scrollLeft !== 0 &&
                          !state.isScrollActive
                        ) {
                          setState({
                            isScrollActive: true,
                          });
                        }
                      }, 200)}
                    >
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                WebkitBoxShadow:
                                  state.isScrollActive &&
                                  "11px 0 19px -2px #bebebe54",
                                boxShadow:
                                  state.isScrollActive &&
                                  "11px 0 19px -2px #bebebe54",
                              }}
                            >
                              <Box
                                className=""
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Tooltip
                                  title={
                                    props.data[0]?.find(
                                      (obj) =>
                                        obj.Identifier === "Product" ||
                                        obj.Identifier === "ProductForeign"
                                    )?.FieldLabel
                                  }
                                >
                                  <Box
                                    sx={{
                                      minWidth: "350px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography fontWeight={500}>
                                      {
                                        props.data[0]?.find(
                                          (obj) =>
                                            obj.Identifier === "Product" ||
                                            obj.Identifier === "ProductForeign"
                                        )?.FieldLabel
                                      }
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Tooltip
                                  title={
                                    props.data[0]?.find(
                                      (obj) => obj.Identifier === "ProductCode"
                                    )?.FieldLabel
                                  }
                                >
                                  <Typography fontWeight={500}>
                                    {
                                      props.data[0]?.find(
                                        (obj) =>
                                          obj.Identifier === "ProductCode"
                                      )?.FieldLabel
                                    }
                                  </Typography>
                                </Tooltip>
                              </Box>
                            </TableCell>

                            {props.data[0]?.map((cell, index) => {
                              if (
                                cell.VisibilityFlag === "Y" ||
                                (cell.DefaultValue &&
                                  cell.DefaultValue.Button === "Y" &&
                                  cell.ElementType === "D")
                              ) {
                                if (
                                  !(
                                    cell.Identifier === "Product" ||
                                    cell.Identifier === "ProductForeign" ||
                                    cell.Identifier === "ProductCode"
                                  )
                                ) {
                                  return (
                                    <TableCell key={cell.FieldId}>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Tooltip title={cell.FieldLabel}>
                                          {/* <p>{cell.FieldLabel}</p> */}
                                          <Typography fontWeight={500}>
                                            {cell.FieldLabel}
                                          </Typography>
                                        </Tooltip>
                                      </div>
                                    </TableCell>
                                  );
                                }
                              } else {
                                return "";
                              }
                            })}
                          </TableRow>
                        </TableHead>
                        {/* {getSecondTableBody()} */}
                      </Table>
                    </TableContainer>
                  </TableCell>
                </TableRow>
              )}
              {props.CurrentTransaction.template.itemize[key].solution.length >
                1 &&
                ToggleMS[key] &&
                getHeaders(
                  props.CurrentTransaction.template.itemize[key].solution,
                  key,
                  value
                )}
            </>
          );
        }
      );
    } else itemsToRender = "loading...";
    return (
      <TableBody className="MultiSolution-Body">{itemsToRender}</TableBody>
    );
  };

  const getHeaders = (solutions, key, value) => {
    return (
      <TableRow>
        <TableCell
          sx={{
            ".quotation_template_table .MuiTableBody-root &.MuiTableCell-root":
              {
                backgroundColor: "var(--grey-50)",
                padding: scrolledLeft
                  ? "0"
                  : {
                      lg: "0 0 0 486px",
                      md: "0 0 0 386px",
                      sm: "0 0 0 386px",
                      xs: "0 0 0 386px",
                    },
              },
          }}
          colSpan={100}
        >
          <Table
            className="MultiSolution-Table SlabDetailsForm"
            sx={{
              "&.MultiSolution-Table tr td:first-of-type, &.MultiSolution-Table tr th:first-of-type":
                {
                  borderLeft: scrolledLeft
                    ? "0"
                    : "1px solid var(--bluegrey-500)",
                  width: {
                    lg: "486px",
                    md: "386px",
                    sm: "386px",
                    xs: "386px",
                  },
                  position: {
                    md: "sticky",
                    sm: "static",
                    xs: "static",
                  },
                  left: "0",
                  boxShadow: {
                    md: scrolledLeft ? "inset -1px 0px 0 #a6c1d3" : "none",
                    sm: "none",
                  },
                },
              "&.MultiSolution-Table .MuiTableBody-root tr:last-of-type td": {
                borderBottom: "none",
              },
            }}
          >
            <TableHead>
              <TableRow>
                {props.multipleSolutionFlag && !props.slabFlag && (
                  <TableCell key={"M2"} align="left">
                    <Tooltip
                      title={props?.AlternativeOptions || `MULTIPLE SOLUTION`}
                      placement="bottom-start"
                    >
                      <Typography
                        variant="body2"
                        color={"grey.600"}
                        fontWeight={500}
                      >
                        {props?.AlternativeOptions || `MULTIPLE SOLUTION`}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                )}
                {scrolledLeft && (
                  <>
                    <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                    <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                  </>
                )}
                {props.data[0]?.map((cell, index) => {
                  if (
                    props.multiplePlantsFlag === true &&
                    cell.Identifier === "GSTType"
                  ) {
                    return null;
                  }
                  if (
                    props.vendorType === "Local" ||
                    props.vendorType === "International" ||
                    props.vendorType === "Local Importer"
                      ? cell.VisibilityFlag === "Y" ||
                        (cell.DefaultValue &&
                          cell.DefaultValue.Button === "Y" &&
                          cell.ElementType === "D")
                      : cell.VisibilityFlag === "Y"
                  ) {
                    if (
                      !(
                        cell.Identifier === "Product" ||
                        cell.Identifier === "ProductForeign" ||
                        cell.Identifier === "ProductCode"
                      )
                    ) {
                      // return (
                      return (
                        <TableCell key={cell.FieldId}>
                          {/* <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            > */}

                          <Tooltip title={cell.FieldLabel}>
                            {/* <p>{cell.FieldLabel}</p> */}
                            <Typography
                              variant="body2"
                              color={"grey.600"}
                              fontWeight={500}
                            >
                              {cell.FieldLabel}
                            </Typography>
                          </Tooltip>
                          {/* </div> */}
                        </TableCell>
                      );
                      // );
                    }
                  } else {
                    return "";
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {solutions.map((solution, index) => {
                // if (index != 0) {
                return (
                  <React.Fragment key={index + 1}>
                    <TableRow>
                      <TableCell>
                        <Tooltip //Item code header starts from here
                          title={_.unescape(solution.name)}
                          placement="bottom-start"
                          disableInteractive
                        >
                          <Typography
                            variant="body2"
                            color={"grey.600"}
                            noWrap
                            sx={{
                              width: {
                                lg: scrolledLeft ? "466px" : "379px",
                                md: scrolledLeft ? "360px" : "379px",
                                sm: scrolledLeft ? "360px" : "379px",
                                xs: scrolledLeft ? "360px" : "379px",
                              },
                            }}
                          >
                            {_.unescape(solution.name)}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      {scrolledLeft && (
                        <>
                          <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                          <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                        </>
                      )}
                      {props?.data[index]?.map((cell) => {
                        const DefaultFormula = cell.DefaultFormula;
                        const regex = /f(\d+)/g;
                        let match;
                        const fieldIDs = [];

                        while ((match = regex.exec(DefaultFormula)) !== null) {
                          fieldIDs.push(match[1]);
                        }

                        const [field1, field2] = fieldIDs;
                        const FieldState = {};

                        if (field1 && field2) {
                        }
                        if (
                          cell.VisibilityFlag === "Y" ||
                          (cell.DefaultValue &&
                            cell.DefaultValue.Button === "Y" &&
                            cell.ElementType === "D")
                        ) {
                          if (
                            props.multiplePlantsFlag &&
                            cell.Identifier === "GSTType"
                          ) {
                            return null;
                          }
                          if (
                            !(
                              cell.Identifier === "Product" ||
                              cell.Identifier === "ProductCode" ||
                              cell.Identifier === "ProductForeign"
                            )
                          ) {
                            return (
                              <TableCell key={cell.FieldId}>
                                <QuotationFormFieldView
                                  batch_id={props.batch_id}
                                  LATEST={props.LATEST}
                                  // diffrentiatedFields={

                                  //   props?.diffrentiatedFields?.[key]?.[index]
                                  // }
                                  cell={cell}
                                  item={value}
                                  index={index}
                                  FieldState={
                                    props.mainMSTemplateQuotation[key]?.[
                                      index
                                    ]?.[cell?.FieldId]
                                  }
                                  base_currency={props?.base_currency}
                                  selectedCurrency={
                                    props.CurrentTransaction.template.itemize[
                                      key
                                    ].solution[0].currency
                                  }
                                  CurrentTransaction={
                                    props.CurrentTransaction.template.itemize[
                                      key
                                    ]
                                  }
                                  handleChange={(
                                    evt,
                                    FieldId,
                                    Identifier,
                                    FieldLabel,
                                    ElementType
                                  ) =>
                                    props.handleChange(
                                      evt,
                                      index,
                                      FieldId,
                                      Identifier,
                                      FieldLabel,
                                      ElementType
                                    )
                                  }
                                  SlabTableToggle={""}
                                  disabled={
                                    Object.keys(disabledProps).length > 0 &&
                                    disabledProps[key]
                                  }
                                  keys={key.length > 0 && key}
                                  solutionIndex={index}
                                  handleDetailsClick={handleDetailsClick}
                                  destination={props.destination}
                                  multiplePlantsFlag={
                                    props.multiplePlantsFlag
                                  }
                                  getMasterCurrencyByFieldId={(id) => props.getMasterCurrencyByFieldId(id)}
                                />
                              </TableCell>
                            );
                          }
                        }
                      })}
                    </TableRow>
                  </React.Fragment>
                );
                // }
              })}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    );
  };

  const getTableSlabBody = () => {
    let filteredItems = {};
    Object.keys(props.selectedQuotedItems).forEach((key) => {
      if (props.CurrentTransaction.template.itemize[key]?.selected === "Y") {
        filteredItems[key] = props.selectedQuotedItems[key];
        // if (props.itemSpecific) {
        //   filteredItems = Object.entries(props?.selectedQuotedItems)
        //     .filter(([key, value]) =>
        //       props?.inquiryItemsId?.includes(parseInt(key))
        //     )
        //     .reduce((obj, [key, value]) => {
        //       obj[key] = value;
        //       return obj;
        //     }, {});
        // } else {
        //   filteredItems[key] = props.selectedQuotedItems[key];
        // }
      }
    });
    let itemsToRender;
    const { SlabTableToggle } = state;
    if (props.selectedQuotedItems || props.selectedQuotedItems[0]) {
      const { selectedQuotedItems, slabMainTemplateQuotation } = props;
      const filteredItemsMap = new Map(
        Object.entries(filteredItems).map((item) => [item[0], item[1]])
      );
      const reorderedTransactionItemSequence = props?.transactionItemSequence
        .filter((key) => filteredItemsMap.has(key)) // Filter keys that are present in map
        .map((key) => [key, filteredItemsMap.get(key)]);
      // itemsToRender = Object.entries(filteredItems)
      //   .sort(([, a], [, b]) => a.name.localeCompare(b.name))
      itemsToRender = reorderedTransactionItemSequence.map(
        ([key, value], index) => {
          const quantity = props.selectedQuotedItems[key]?.quantity;

          // Get the solutions array from lastTransactionData
          const solutions = slabMainTemplateQuotation[key] || [];

          // Initialize an array to store the indices of filtered solutions
          let filteredSolutionIndices = [];

          // Filter selected slabs first
          const selectedSlabs = solutions.filter(
            (solution) => solution.select_slab === "Y"
          );

          // First, try to find an exact match
          let filteredSolutions = solutions.filter((solution, index) => {
            if (solution.select_slab === "Y") {
              const range = solution?._range;

              // Exact match logic for "More Than"
              if (range && range.includes("More Than")) {
                const min = parseInt(range.split(" ")[2], 10);
                if (quantity === min) {
                  filteredSolutionIndices.push(index); // Store the index
                  return true; // Exact match for "More Than"
                }
              }
              // Exact match logic for range
              else if (range) {
                const [min, max] = range.split("-").map(Number);
                if (quantity >= min && quantity <= max) {
                  filteredSolutionIndices.push(index); // Store the index
                  return true; // Exact match for range
                }
              }
            }
            return false; // Default return false if no conditions are met
          });

          // If no exact match is found, check for the next nearest slab
          if (filteredSolutions.length === 0) {
            filteredSolutions = solutions.filter((solution, index) => {
              if (solution.select_slab === "Y") {
                const range = solution?._range;

                // Logic for the next nearest "More Than"
                if (range && range.includes("More Than")) {
                  const min = parseInt(range.split(" ")[2], 10);
                  if (quantity < min) {
                    filteredSolutionIndices.push(index); // Store the index
                    return true; // Ensure the quantity is less than min for "More Than"
                  }
                }
                // Logic for the next nearest range
                else if (range) {
                  const [min] = range.split("-").map(Number);
                  if (quantity < min) {
                    filteredSolutionIndices.push(index); // Store the index
                    return true; // Ensure the quantity is less than min for the selected ranges
                  }
                }
              }
              return false; // Default return false if no conditions are met
            });

            // Sort by ascending order to get the next available slab
            filteredSolutions = filteredSolutions.sort((a, b) => {
              const rangeA = a._range.includes("More Than")
                ? parseInt(a._range.split(" ")[2], 10)
                : parseInt(a._range.split("-")[0], 10);
              const rangeB = b._range.includes("More Than")
                ? parseInt(b._range.split(" ")[2], 10)
                : parseInt(b._range.split("-")[0], 10);
              return rangeA - rangeB; // Sort by ascending order
            });

            // Update the indices accordingly after sorting
            filteredSolutionIndices = filteredSolutionIndices.sort((a, b) => {
              const rangeA = solutions[a]._range.includes("More Than")
                ? parseInt(solutions[a]._range.split(" ")[2], 10)
                : parseInt(solutions[a]._range.split("-")[0], 10);
              const rangeB = solutions[b]._range.includes("More Than")
                ? parseInt(solutions[b]._range.split(" ")[2], 10)
                : parseInt(solutions[b]._range.split("-")[0], 10);
              return rangeA - rangeB;
            });

            // Select the first (smallest) valid slab that is greater than the quantity
            if (filteredSolutions.length > 0) {
              filteredSolutions = filteredSolutions.slice(0, 1);
              filteredSolutionIndices = [filteredSolutionIndices[0]];
            }
          }

          // If still no solutions found, ensure to return the solution with select_slab "Y"
          if (filteredSolutions.length === 0) {
            // Try to find the largest available range
            filteredSolutions = solutions
              .filter((solution, index) => {
                if (solution.select_slab === "Y") {
                  filteredSolutionIndices.push(index); // Store the index
                  return true;
                }
                return false;
              })
              .sort((a, b) => {
                const rangeA = a._range.includes("More Than")
                  ? parseInt(a._range.split(" ")[2], 10)
                  : parseInt(a._range.split("-")[0], 10);
                const rangeB = b._range.includes("More Than")
                  ? parseInt(b._range.split(" ")[2], 10)
                  : parseInt(b._range.split("-")[0], 10);
                return rangeA - rangeB; // Sort by ascending order
              });

            // Select the last (largest) range
            filteredSolutions = filteredSolutions.slice(-1);
            filteredSolutionIndices = [filteredSolutionIndices.slice(-1)[0]];
          }

          let filteredChildSlabLength = solutions.filter(
            (solution) => solution.select_slab === "Y"
          );

          return (
            // console.log("checkboxState[key]?.parentChecked",key,checkboxState,checkboxState[key]?.parentChecked),
            <>
              <TableRow key={key}>
                <TableCell
                  sx={{
                    WebkitBoxShadow:
                      state.isScrollActive && "11px 0 19px -2px #bebebe54",
                    boxShadow:
                      state.isScrollActive && "11px 0 19px -2px #bebebe54",
                  }}
                >
                  <Box display="flex" alignItems="center" gap="16px">
                    <div key={key}>
                      {props.slabFlag &&
                        props.multipleSolutionFlag &&
                        solutions[0]?._range !== undefined &&
                        solutions?.length > 1 &&
                        (!iconState[key] ? (
                          <>
                            <Tooltip
                              title={"Click here to View Slab"}
                              placement="bottom-start"
                              disableInteractive
                            >
                              <Badge
                                color="primary"
                                badgeContent={filteredChildSlabLength?.length}
                              >
                                <AddIcon
                                  onClick={(event) =>
                                    handleAddClick(event, key)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </Badge>
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <Tooltip
                              title={"Click here to close Slab"}
                              placement="bottom-start"
                              disableInteractive
                            >
                              <Badge
                                color="primary"
                                badgeContent={filteredChildSlabLength?.length}
                              >
                                <RemoveIcon
                                  onClick={(event) =>
                                    handleRemoveClick(event, key)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </Badge>
                            </Tooltip>
                          </>
                        ))}
                    </div>
                    <Tooltip // This is for Attachments specific files
                      title="Specification"
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Badge
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        // badgeContent={value?.files?.length}
                        badgeContent={`${value?.files?.length}`}
                        color="primary"
                      >
                        <AttachFileIcon
                          onClick={() =>
                            value?.files?.length > 0 &&
                            handleUplaodSpecificationOpen(
                              key,
                              value.files,
                              value.specification_text
                            )
                          }
                          sx={{ cursor: "pointer" }}
                        />
                      </Badge>
                    </Tooltip>
                    <Tooltip //Item name header starts from here
                      title={_.unescape(value.name)}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Badge
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        badgeContent={
                          props.mainTemplateQuotation[key]
                            ?.added_after_rfq_created
                            ? "New"
                            : null
                        }
                        color="primary"
                        sx={{
                          "& .MuiBadge-badge": {
                            top: "-5px",
                            height: "16px",
                            fontSize: "10px",
                          },
                        }}
                      >
                        <Typography
                          noWrap
                          variant="body2"
                          color="text.primary"
                          fontWeight={500}
                          sx={{
                            width: {
                              lg: `${
                                state.itemCellWidth > 300
                                  ? state.itemCellWidth
                                  : 300
                              }px`,
                              md: `${
                                state.itemCellWidth > 200
                                  ? state.itemCellWidth
                                  : 200
                              }px`,
                              sm: `${
                                state.itemCellWidth > 200
                                  ? state.itemCellWidth
                                  : 200
                              }px`,
                              xs: `${
                                state.itemCellWidth > 125
                                  ? state.itemCellWidth
                                  : 125
                              }px`,
                            },
                            // width: `${
                            //   state.itemCellWidth > 350
                            //     ? state.itemCellWidth
                            //     : 350
                            // }px`,
                            marginLeft: "10px",
                          }}
                        >
                          {_.unescape(value.name)}
                        </Typography>
                      </Badge>

                      <Typography
                        sx={{ color: "error.main", marginLeft: "10px" }}
                      >
                        {props.mainTemplateQuotation[key]
                          ?.is_deleted_from_master &&
                          "This item is deleted from item master."}
                      </Typography>
                      <Typography sx={{ color: "red", marginLeft: "10px" }}>
                        {props.mainTemplateQuotation[key]
                          ?.is_unlink_from_category &&
                          "This item is unlinked from category."}
                      </Typography>

                      {/* {props.mainTemplateQuotation[key]
                        ?.added_after_rfq_created && (
                          <Chip label="New" size="small" color="primary" sx={{ marginLeft: "10px", height: "16px" }} />
                        )} */}
                    </Tooltip>
                  </Box>
                </TableCell>
                {props.data[0]?.find(
                  (obj) =>
                    obj.Identifier === "ProductCode" &&
                    obj.VisibilityFlag === "Y"
                ) && (
                  <TableCell
                  // sx={{
                  //   WebkitBoxShadow:
                  //     state.isScrollActive && "11px 0 19px -4px #bebebe54",
                  //   boxShadow:
                  //     state.isScrollActive && "11px 0 19px -4px #bebebe54",
                  // }}
                  >
                    <Tooltip //Item code header starts from here
                      title={_.unescape(value.code)}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Typography
                        noWrap
                        variant="body2"
                        color="text.primary"
                        fontWeight={500}
                        // sx={{
                        //   width: {
                        //     lg: `${state.itemCellWidth > 300
                        //       ? state.itemCellWidth
                        //       : 250
                        //       }px`,
                        //     md: `${state.itemCellWidth > 200
                        //       ? state.itemCellWidth
                        //       : 200
                        //       }px`,
                        //     sm: `${state.itemCellWidth > 200
                        //       ? state.itemCellWidth
                        //       : 200
                        //       }px`,
                        //     xs: `${state.itemCellWidth > 125
                        //       ? state.itemCellWidth
                        //       : 125
                        //       }px`,
                        //   },
                        // }}
                      >
                        {_.unescape(value.code)}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                )}
                {props.multipleSolutionFlag && !props.slabFlag && (
                  <TableCell>
                    <Tooltip //Item code header starts from here
                      title={props.lastTransactionInThread[key]?.name}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="text"
                        disabled={
                          props.lastTransactionTypeInThread !== "Inquiry"
                        }
                        value={props.solutionNameMainTemplate[key]} // Use editable value from state or default value
                        // onChange={(e)=>handleInputChange(key,e)} // Pass key to identify which row is being updated
                      />
                    </Tooltip>
                  </TableCell>
                )}
                {props.slabFlag && props.multipleSolutionFlag && (
                  <TableCell>
                    <Tooltip //Item code header starts from here
                      title={solutions[key]?._range}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Typography>
                        {filteredSolutions[0]?._range
                          ? filteredSolutions[0]?._range
                          : "N/A"}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                )}
                {props?.data[index]?.map((cell) => {
                  const DefaultFormula = cell.DefaultFormula;

                  // Regular expression to extract field IDs
                  const regex = /f(\d+)/g;
                  let match;
                  const fieldIDs = [];

                  // Extract field IDs from DefaultFormula
                  while ((match = regex.exec(DefaultFormula)) !== null) {
                    fieldIDs.push(match[1]);
                  }

                  // Assuming you always have two field IDs in the DefaultFormula
                  const [field1, field2] = fieldIDs;

                  // Initialize FieldState
                  const FieldState = {};

                  // If field IDs are found, set data in FieldState
                  if (field1 && field2) {
                    // Assuming num1 and num2 are the values you want to set
                    //  const num1 = /* Your value for field1 */;
                    //  const num2 = /* Your value for field2 */;
                    // Setting data in FieldState for both field1 and field2
                    //  const num1 = FieldState[field1];
                    //  const num2 = FieldState[field2];
                  }
                  // All I/P fields comes from there in the template section table
                  if (
                    cell.VisibilityFlag === "Y" ||
                    (cell.DefaultValue &&
                      cell.DefaultValue.Button === "Y" &&
                      cell.ElementType === "D")
                  ) {
                    if (
                      props.multiplePlantsFlag &&
                      cell.Identifier === "GSTType"
                    ) {
                      return null;
                    }
                    if (
                      !(
                        cell.Identifier === "Product" ||
                        cell.Identifier === "ProductCode" ||
                        cell.Identifier === "ProductForeign"
                      )
                    ) {
                      return (
                        <TableCell
                          key={cell.FieldId}
                          // sx={{ minWidth: "120px" }}
                          sx={{ minWidth: "180px", maxWidth: "180px" }}
                        >
                          <QuotationFormFieldView
                            batch_id={props.batch_id}
                            LATEST={props.LATEST}
                            vendorType={props?.vendorType}
                            // upd_budget_master={
                            //   props.mainTemplateQuotation[item.id] &&
                            //   props.mainTemplateQuotation[item.id][cell?.FieldId] &&
                            //   props.mainTemplateQuotation[item.id][cell?.FieldId][
                            //     `upd_budget_master_${item.id}`
                            //   ]
                            // }
                            // qty={
                            //   props.mainTemplateQuotation[item.id] &&
                            //   props.mainTemplateQuotation[item.id]["qty"]
                            // }
                            // rate={
                            //   props.mainTemplateQuotation[item.id] &&
                            //   props.mainTemplateQuotation[item.id]["rate"]
                            // }
                            // readOnly={
                            //   props.mainTemplateQuotation[item.id]?.[cell?.FieldId]
                            //     ?.readOnly
                            // }
                            cell={cell}
                            item={value}
                            index={index}
                            // FieldState={
                            //   props.selectedQuotedItems.vendors[0]
                            //     .transaction_thread[0].template.itemize[key]
                            //     .solution[0] &&
                            //   (props.selectedQuotedItems.vendors[0]
                            //     .transaction_thread[0].template.itemize[key]
                            //     .solution[0].data[0][field1] ||
                            //     props.selectedQuotedItems.vendors[0]
                            //       .transaction_thread[0].template.itemize[key]
                            //       .solution[0].data[0][field2])
                            // }
                            //Important: there I'll be sending the mainTemplateQuotation doing this just for the static data
                            // props.selectedQuotedItems
                            //   .transaction_thread[0].template.itemize[
                            //   item.id
                            // ][cell.FieldId].value}
                            // FieldState={
                            //   mainTemplateQuotation[key] &&
                            //   mainTemplateQuotation[key][cell?.FieldId]
                            // }
                            // FieldState={
                            //   // solutions?.[ &&
                            //   solutions?.[0]?.[cell?.FieldId]
                            // }
                            // FieldState={filteredSolutions[0][cell?.FieldId]}
                            FieldState={
                              solutions[0]?._range &&
                              solutions[0]?._range != "N/A" &&
                              solutions[0]?._range != undefined
                                ? filteredSolutions?.[0]?.[cell?.FieldId]
                                : solutions?.[0]?.[cell?.FieldId]
                            }
                            base_currency={props?.base_currency}
                            selectedCurrency={
                              props.lastTransactionTypeInThread === "Inquiry" &&
                              (props.vendorType === "International" ||
                                props.vendorType === "Local Importer")
                                ? props?.selectedCurrency
                                : props?.selectedCurrency ||
                                  props.quotedCurrency
                              // props.lastTransactionTypeInThread === "Inquiry" &&
                              // (props.vendorType === "International" ||
                              //   props.vendorType === "Local Importer")
                              //   ? props.selectedCurrency
                              //   : Object.values(
                              //       props.lastTransactionInThread
                              //     )[0].solution[0]?.currency
                            }
                            // selectedCurrency={
                            //   props.CurrentTransaction.template.itemize[
                            //     key
                            //   ].solution[0].currency
                            // }
                            CurrentTransaction={
                              props.CurrentTransaction.template.itemize[key]
                            }
                            updateDeliveryScheduleFieldValueSlab={
                              props.updateDeliveryScheduleFieldValueSlab
                            }
                            slabFlag={props.slabFlag}
                            multipleSolutionFlag={props.multipleSolutionFlag}
                            handleChange={(
                              evt,
                              FieldId,
                              Identifier,
                              FieldLabel,
                              // ElementType,
                              key,
                              index
                            ) =>
                              props.handleWithOutSlabChange(
                                evt,
                                FieldId,
                                Identifier,
                                FieldLabel,
                                // ElementType,
                                key,
                                index
                              )
                            }
                            handleDateChange={(newVal, FieldId, Validation) =>
                              props.handleDateWithOutSlabChange(
                                newVal,
                                FieldId,
                                "slabMainTemplateQuotation",
                                index,
                                Validation,
                                key
                              )
                            }
                            default_dropdown_type={props.default_dropdown_type}
                            destination={props.destination}
                            SlabTableToggle={SlabTableToggle}
                            // disabled={
                            //   Object.keys(disabledProps).length > 0 &&
                            //   disabledProps[key]
                            // }
                            disabled={
                              solutions[0]?._range !== undefined ? true : false
                            }
                            // readOnlyForSwitch={switchDisabledProps[key]}
                            readOnlyForSwitch={disabledProps[key]}
                            keys={key.length > 0 && key}
                            solutionIndex={0}
                            solutionRowIndex={
                              solutions[0]?._range != "N/A"
                                ? filteredSolutionIndices.length > 0
                                  ? filteredSolutionIndices[0]
                                  : 0
                                : 0
                            }
                            handleDetailsClick={handleDetailsClick}
                            handleDetailsClickForSlab={
                              handleDetailsClickForSlab
                            }
                            handleDetailsClickWithoutSlab={
                              handleDetailsClickWithoutSlab
                            }
                            mainTemplateQuotation={props.mainTemplateQuotation}
                            multiplePlantsFlag={props.multiplePlantsFlag}
                            selectedQuotedItems={props.selectedQuotedItems}
                            slabMainTemplateQuotation={
                              props.slabMainTemplateQuotation
                            }
                            diffrentiatedFields={
                              props?.diffrentiatedSlabFields?.[key]?.[
                                solutions[0]?._range != "N/A"
                                  ? filteredSolutionIndices.length > 0
                                    ? filteredSolutionIndices[0]
                                    : 0
                                  : 0
                              ]
                            }
                            getMasterCurrencyByFieldId={(id) => props.getMasterCurrencyByFieldId(id)}
                          />
                        </TableCell>
                      );
                    }
                  }
                })}
              </TableRow>
              {/* For Slab */}
              {props.slabFlag &&
                props.multipleSolutionFlag &&
                solutions[0]?._range !== undefined &&
                Toggle[key] &&
                props.slabMainTemplateQuotation[key] &&
                getHeadersForSlab(
                  props.slabMainTemplateQuotation[key],
                  key,
                  value
                )}
            </>
          );
        }
      );
    } else {
      itemsToRender = "loading...";
    }

    return <TableBody className="SlabRange-Body">{itemsToRender}</TableBody>;
  };

  const getHeadersForSlab = (solutions, key, value) => {
    // Filter solutions to include only those with select_slab === "Y"
    // let filteredSolutions = solutions.filter(
    //   (solution) => solution.select_slab === "Y"
    // );

    // Step 1: Filter the solutions
    let filteredSolutions = solutions.filter(
      (solution) => solution.select_slab === "Y"
    );

    // Step 2: Get the index of each filtered solution in the original array
    let filteredIndexes = filteredSolutions.map((filteredSolution) =>
      solutions.findIndex((solution) => solution === filteredSolution)
    );

    console.log("filtered index", key, filteredIndexes);

    return (
      <TableRow>
        <TableCell
          sx={{
            ".quotation_template_table .MuiTableBody-root &.MuiTableCell-root":
              {
                backgroundColor: "var(--grey-50)",
                padding: scrolledLeft
                  ? "0"
                  : {
                      lg: "0 0 0 410px",
                      md: "0 0 0 380px",
                      sm: "0 0 0 380px",
                      xs: "0 0 0 380px",
                    },
              },
          }}
          colSpan={100}
        >
          <Table
            className="MultiSolution-Table SlabDetailsForm"
            sx={{
              "&.MultiSolution-Table tr td:first-of-type, &.MultiSolution-Table tr th:first-of-type":
                {
                  borderLeft: scrolledLeft
                    ? "0"
                    : "1px solid var(--bluegrey-500)",
                  width: {
                    lg: "410px",
                    md: "380px",
                    sm: "380px",
                    xs: "380px",
                  },
                  position: {
                    md: "sticky",
                    sm: "static",
                    xs: "static",
                  },
                  left: "0",
                  boxShadow: {
                    md: scrolledLeft ? "inset -1px 0px 0 #a6c1d3" : "none",
                    sm: "none",
                  },
                },
              "&.MultiSolution-Table .MuiTableBody-root tr:last-of-type td": {
                borderBottom: "none",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell key={"M2"} align="left">
                  <Tooltip title="Slab Range" placement="bottom-start">
                    <Typography
                      variant="body2"
                      color={"grey.600"}
                      fontWeight={500}
                      // textAlign={{ sm: "left", md: "right" }}
                    >
                      Slab Range
                    </Typography>
                  </Tooltip>
                </TableCell>
                {scrolledLeft && (
                  <>
                    <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                    <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                  </>
                )}

                {props.data[0]?.map((cell, index) => {
                  if (
                    props.multiplePlantsFlag === true &&
                    cell.Identifier === "GSTType"
                  ) {
                    return null;
                  }
                  if (
                    props.vendorType === "Local" ||
                    props.vendorType === "International" ||
                    props.vendorType === "Local Importer"
                      ? cell.VisibilityFlag === "Y" ||
                        (cell.DefaultValue &&
                          cell.DefaultValue.Button === "Y" &&
                          cell.ElementType === "D")
                      : cell.VisibilityFlag === "Y"
                  ) {
                    if (
                      !(
                        cell.Identifier === "Product" ||
                        cell.Identifier === "ProductForeign" ||
                        cell.Identifier === "ProductCode"
                      )
                    ) {
                      return (
                        <TableCell key={cell.FieldId}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip
                              title={
                                cell?.InstructionText === ""
                                  ? cell?.FieldLabel
                                  : cell?.InstructionText
                              }
                            >
                              <Typography fontWeight={500}>
                                {cell.FieldLabel}
                              </Typography>
                            </Tooltip>
                            {/* <ResizableBox
                              axis="x"
                              height={20}
                              width={20}
                              style={{
                                textAlign: "left",
                                width: "100%",
                              }}
                              minConstraints={[20, 20]}
                            ></ResizableBox> */}
                          </div>
                        </TableCell>
                      );
                    }
                  } else {
                    return "";
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSolutions?.map((solution, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Tooltip
                        title={solution._range}
                        placement="bottom-start"
                        disableInteractive
                      >
                        <Typography
                          fontWeight={500}
                          noWrap
                          sx={{
                            width: {
                              lg: scrolledLeft ? "390px" : "339px",
                              md: scrolledLeft ? "360px" : "339px",
                              sm: scrolledLeft ? "360px" : "339px",
                              xs: scrolledLeft ? "360px" : "339px",
                            },
                          }}
                        >
                          {solution._range}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  {scrolledLeft && (
                    <>
                      <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                      <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                    </>
                  )}
                  {props?.data[index]?.map((cell) => {
                    const DefaultFormula = cell.DefaultFormula;
                    const regex = /f(\d+)/g;
                    let match;
                    const fieldIDs = [];

                    while ((match = regex.exec(DefaultFormula)) !== null) {
                      fieldIDs.push(match[1]);
                    }

                    const [field1, field2] = fieldIDs;
                    const FieldState = {};

                    if (field1 && field2) {
                    }

                    if (
                      cell.VisibilityFlag === "Y" ||
                      (cell.DefaultValue &&
                        cell.DefaultValue.Button === "Y" &&
                        cell.ElementType === "D")
                    ) {
                      if (
                        props.multiplePlantsFlag &&
                        cell.Identifier === "GSTType"
                      ) {
                        return null;
                      }
                      if (
                        !(
                          cell.Identifier === "Product" ||
                          cell.Identifier === "ProductCode" ||
                          cell.Identifier === "ProductForeign"
                        )
                      ) {
                        return (
                          <TableCell
                            key={cell.FieldId}
                            // sx={{ minWidth: "120px" }}
                          >
                            <QuotationFormFieldView
                              batch_id={props.batch_id}
                              LATEST={props.LATEST}
                              cell={cell}
                              index={index}
                              vendorType={props?.vendorType}
                              FieldState={solution?.[cell?.FieldId]}
                              base_currency={props?.base_currency}
                              selectedCurrency={
                                props?.selectedCurrency || props.quotedCurrency
                              }
                              CurrentTransaction={
                                props.CurrentTransaction.template.itemize[key]
                              }
                              item={value}
                              destination={props.destination}
                              updateDeliveryScheduleFieldValueSlab={
                                props.updateDeliveryScheduleFieldValueSlab
                              }
                              default_dropdown_type={
                                props.default_dropdown_type
                              }
                              rowIndex={index}
                              keys={key.length > 0 && key}
                              handleDetailsClick={handleDetailsClick}
                              handleDetailsClickForSlab={
                                handleDetailsClickForSlab
                              }
                              handleDetailsClickWithoutSlab={
                                handleDetailsClickWithoutSlab
                              }
                              multiplePlantsFlag={props.multiplePlantsFlag}
                              multipleSolutionFlag={props.multipleSolutionFlag}
                              slabFlag={props.slabFlag}
                              solutionIndex={0}
                              solutionRowIndex={filteredIndexes[index]}
                              selectedQuotedItems={props.selectedQuotedItems}
                              slabMainTemplateQuotation={props.slabMainTemplateQuotation}
                              getMasterCurrencyByFieldId={(id) => props.getMasterCurrencyByFieldId(id)}
                            // diffrentiatedFields={
                            //   props?.diffrentiatedSlabFields?.[key]?.[index]
                            // }
                            // switchState={props.switchState[key]}
                            />
                          </TableCell>
                        );
                      }
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    );
  };

  const LandedCostRFQOpen = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (AllItemsTemplate.length > 0) {
      let totalCost = AllItemsTemplate.find((items) => {
        return (
          items.DefaultValue?.Button == "Y" || items.Identifier === "TotalCost"
        );
      });

      let rateCell = AllItemsTemplate.find((items) => {
        return (
          (items.Identifier === "EstimatedCost" ||
            items.Identifier === "EstimatedCostForeign" ||
            items.Identifier == "Budgetedrate" ||
            items.Identifier === "Rate" ||
            items.Identifier === "CIFRate" ||
            items.Identifier == "rate") &&
          items.ElementType === "D"
        );
      });
      let PerCost = AllItemsTemplate.find((items) => {
        return items.Identifier === "PER";
      });
      setRateFIeld(rateCell);
      setPerCost(PerCost);

      setTotalCostFIeld(totalCost);
    }
    setLandedCostRFQ({ ...landedCostRFQ, [anchor]: open });
  };
  const LandedCostSlabOpen = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (AllItemsTemplate.length > 0) {
      let totalCost = AllItemsTemplate.find((items) => {
        return (
          items.DefaultValue?.Button == "Y" || items.Identifier === "TotalCost"
        );
      });

      let rateCell = AllItemsTemplate.find((items) => {
        return (
          (items.Identifier === "EstimatedCost" ||
            items.Identifier === "EstimatedCostForeign" ||
            items.Identifier == "Budgetedrate" ||
            items.Identifier === "Rate" ||
            items.Identifier === "CIFRate" ||
            items.Identifier == "rate") &&
          items.ElementType === "D"
        );
      });
      let PerCost = AllItemsTemplate.find((items) => {
        return items.Identifier === "PER";
      });
      setRateFIeld(rateCell);
      setPerCost(PerCost);

      setTotalCostFIeld(totalCost);
    }
    setLandedCostSlab({ ...landedCostSlab, [anchor]: open });
  };

  // Start table duplicate synchronize scrollbar script
  const containerRef = useRef(null);
  const duplicateScrollbarRef = useRef(null);
  const [isVisibleScrollbar, setIsVisibleScrollbar] = useState(false);

  const handleScrollSecond = (scroll) => {
    containerRef.current.scrollLeft = scroll.target.scrollLeft;
  };

  useEffect(() => {
    const container = containerRef.current;
    const duplicateScrollbar = duplicateScrollbarRef.current;
    const boxElement = document.getElementById("CardAdditional");

    const handleScroll = () => {
      duplicateScrollbar.scrollLeft = container.scrollLeft;

      const scrollSection = document.getElementById("ScrollAreaSection");
      if (!scrollSection) return;

      const sectionTop = scrollSection.getBoundingClientRect().top;
      const sectionBottom = scrollSection.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;

      // Check if cardAdditional is within the viewport
      const boxTop = boxElement.getBoundingClientRect().top;
      const boxBottom = boxElement.getBoundingClientRect().bottom;

      // If cardAdditional is in the viewport, set isVisible to false
      if (boxTop < windowHeight && boxBottom > 0) {
        setIsVisibleScrollbar(false);
        return;
      }

      // Otherwise, check if the ScrollAreaSection is in the viewport
      if (sectionTop < windowHeight && sectionBottom > 100) {
        setIsVisibleScrollbar(true);
      } else {
        setIsVisibleScrollbar(false);
      }
    };

    container.addEventListener("scroll", handleScroll);
    window.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // End table duplicate synchronize scrollbar script

  // Start left scrollbar tablecell show hide & first child sticky script
  const [scrolledLeft, setScrolledLeft] = useState(false);

  // Function to handle scroll event
  const tableHandleScroll = () => {
    if (containerRef.current) {
      // Check if the scroll position is greater than 0
      setScrolledLeft(containerRef.current.scrollLeft > 400);
    }
  };

  useEffect(() => {
    const tableScroll = containerRef.current;
    if (tableScroll) {
      tableScroll.addEventListener("scroll", tableHandleScroll);
      return () => {
        tableScroll.removeEventListener("scroll", tableHandleScroll);
      };
    }
  }, []);
  // End left scrollbar tablecell show hide & first child sticky script

  return (
    <>
      <Card variant="outlined" id="Template">
        <CardHeader
          title={
            <Typography variant="subtitle2" component="h5" color={"grey.900"}>
              TEMPLATE
            </Typography>
          }
          action={
            props.multiplePlantsFlag && (
              <>
                <Badge
                  badgeContent={destinationCount}
                  color="primary"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{ mr: 2 }}
                >
                  <Button
                    variant="text"
                    size="small"
                    startIcon={<LocationOnOutlinedIcon />}
                    onClick={() =>
                      props.setIsDestinationDetailsDialogOpen(true)
                    }
                    sx={{ backgroundColor: "var(--primary-50)" }}
                  >
                    Destination
                  </Button>
                </Badge>
              </>
            )
          }
        />
        <CardContent sx={{ p: 0 }} id="ScrollAreaSection">
          <TableContainer
            className="quotation_template_table view_quotation_t_table"
            ref={containerRef}
            sx={{
              // maxHeight: "400px",
              // overflowY: "auto",
              overflow: "auto",
              borderTop: "solid 1px",
              borderColor: (theme) => theme.palette.bluegrey[500],
              borderBottomRightRadius: "4px",
              borderBottomLeftRadius: "4px",
              "&.quotation_template_table tr th:last-child p[aria-label='Total Cost']":
                {
                  width: "100%",
                  textAlign: "right",
                },
            }}
            onScroll={debounce((event) => {
              if (event.target.scrollLeft === 0 && state.isScrollActive) {
                setState({
                  isScrollActive: false,
                });
              } else if (
                event.target.scrollLeft !== 0 &&
                !state.isScrollActive
              ) {
                setState({
                  isScrollActive: true,
                });
              }
            }, 200)}
            // sx={{
            //   maxHeight: "400px",
            //   overflowY: "auto",
            //   borderTop: "solid 1px",
            //   borderColor: (theme) => theme.palette.bluegrey[500],
            //   borderBottomRightRadius: "4px",
            //   borderBottomLeftRadius: "4px",
            //   ".items_template_table .MuiTableHead-root": {
            //     backgroundColor: (theme) => theme.palette.bluegrey[500],
            //   },
            //   "& .MuiTableHead-root ": {},
            //   "& .MuiTableBody-root": {
            //     backgroundColor: (theme) => theme.palette.grey[0],
            //   },
            //   "& tr": {
            //     whiteSpace: "nowrap",
            //   },
            //   "& .MuiTableHead-root tr th": {
            //     padding: "15px 8px",
            //   },
            //   "& .MuiTableBody-root tr td": {
            //     padding: "5px 8px",
            //   },
            //   ".MuiTableBody-root tr td:first-of-type, .MuiTableHead-root tr th:first-of-type ":
            //     {
            //       // position: "sticky",
            //       left: "0",
            //       minWidth: "380px",
            //     },
            //   "& .MuiTableHead-root tr th:first-of-type": {
            //     zIndex: "3",
            //   },
            //   "& .MuiTableBody-root tr td:first-of-type": {
            //     backgroundColor: (theme) => theme.palette.grey[0],
            //     zIndex: "1",
            //   },
            //   "& .css-17hdxs-MuiTypography-root": {
            //     fontSize: "14px",
            //   },
            // }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      WebkitBoxShadow:
                        state.isScrollActive && "11px 0 19px -2px #bebebe54",
                      boxShadow:
                        state.isScrollActive && "11px 0 19px -2px #bebebe54",
                    }}
                  >
                    <Box
                      className=""
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip
                        title={
                          props.data[0]?.find(
                            (obj) =>
                              obj.Identifier === "Product" ||
                              obj.Identifier === "ProductForeign"
                          )?.FieldLabel
                        }
                      >
                        <Box
                          sx={{
                            minWidth: "350px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography fontWeight={500}>
                            {
                              props.data[0]?.find(
                                (obj) =>
                                  obj.Identifier === "Product" ||
                                  obj.Identifier === "ProductForeign"
                              )?.FieldLabel
                            }
                          </Typography>

                          {/* <ResizableBox
                            axis="x"
                            height={20}
                            width={20}
                            onResizeStop={(e, data) => {
                              setState({
                                itemCellWidth: data.size.width,
                              });
                            }}
                            style={{
                              textAlign: "left",
                              width: "100%",
                            }}
                            minConstraints={[20, 20]}
                          ></ResizableBox> */}
                        </Box>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  {props.data[0]?.find(
                    (obj) =>
                      obj.Identifier === "ProductCode" &&
                      obj.VisibilityFlag === "Y"
                  ) && (
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Tooltip
                          title={
                            props.data[0]?.find(
                              (obj) => obj.Identifier === "ProductCode"
                            )?.FieldLabel
                          }
                        >
                          <Typography fontWeight={500}>
                            {
                              props.data[0]?.find(
                                (obj) => obj.Identifier === "ProductCode"
                              )?.FieldLabel
                            }
                          </Typography>
                        </Tooltip>
                        {/* <ResizableBox
                        axis="x"
                        height={20}
                        width={20}
                        style={{
                          textAlign: "left",
                          width: "100%",
                        }}
                        minConstraints={[20, 20]}
                      ></ResizableBox> */}
                      </Box>
                    </TableCell>
                  )}
                  {props.multipleSolutionFlag && !props.slabFlag && (
                    <TableCell key={"M1"}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip title={""}>
                          {/* <p>{cell.FieldLabel}</p> */}
                          <Typography fontWeight={500}>
                            {props?.AlternativeOptions || `MULTIPLE SOLUTION`}
                          </Typography>
                        </Tooltip>
                      </div>
                    </TableCell>
                  )}
                  {props.slabFlag && props.multipleSolutionFlag && (
                    <TableCell key={"M2"} align="left">
                      <Tooltip title={""}>
                        {/* <p>{cell.FieldLabel}</p> */}
                        <Typography fontWeight={500}>SLAB RANGE</Typography>
                      </Tooltip>
                    </TableCell>
                  )}
                  {props.data[0]?.map((cell, index) => {
                    if (
                      props.multiplePlantsFlag === true &&
                      cell.Identifier === "GSTType"
                    ) {
                      return null;
                    }
                    if (
                      cell.VisibilityFlag === "Y" ||
                      (cell.DefaultValue &&
                        cell.DefaultValue.Button === "Y" &&
                        cell.ElementType === "D")
                    ) {
                      if (
                        !(
                          cell.Identifier === "Product" ||
                          cell.Identifier === "ProductForeign" ||
                          cell.Identifier === "ProductCode"
                        )
                      ) {
                        return (
                          <TableCell key={cell.FieldId}>
                            <Tooltip title={cell.FieldLabel}>
                              {/* <p>{cell.FieldLabel}</p> */}
                              <Typography fontWeight={500}>
                                {cell.FieldLabel}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                        );
                      }
                    } else {
                      return "";
                    }
                  })}

                  {/* <TableCell>Total value</TableCell> */}
                </TableRow>
              </TableHead>
              {props.slabFlag && props.multipleSolutionFlag
                ? getTableSlabBody()
                : getTableBody()}
            </Table>
          </TableContainer>
          {/* Start Quotation form Duplicate Scroll bar code */}
          <div
            ref={duplicateScrollbarRef}
            onScroll={handleScrollSecond}
            style={{
              display: isVisibleScrollbar ? "block" : "none",
              overflowX: "scroll",
              overflowY: "hidden",
              width: "calc(100% - 34px)",
              height: "18px",
              position: "fixed",
              bottom: "0",
              zIndex: "13",
            }}
          >
            <div
              style={{
                width: containerRef.current?.scrollWidth || 0,
                height: "18px",
              }}
            ></div>
          </div>
          {/* End Quotation form Duplicate Scroll bar code */}
        </CardContent>
        <CardActions
          sx={{
            justifyContent: "end",
            alignItems: "center",
            padding: "8px 16px",
          }}
          disableSpacing
        >
          {!props.isMultipleCurrency && (
            <Button
              variant="text"
              endIcon={<InfoIcon fontSize="small" color="primary" />}
              onClick={
                props.multipleSolutionFlag && props.slabFlag
                  ? LandedCostSlabOpen("right", true)
                  : LandedCostRFQOpen("right", true)
              }
              sx={{
                textDecoration: "underline",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                },
              }}
            >
              Total Value
            </Button>
          )}
        </CardActions>
      </Card>

      {state.specificationsOpen && (
        <Upload
          currentItemIndex={state.currentItemIndex}
          product_id={props.selectedQuotedItems[state.currentItemIndex]?.id}
          randomId={props.randomId}
          master_type={"item"}
          handleUplaod={props.handleUplaod}
          open={state.specificationsOpen}
          handleClose={() => {
            setState({
              specificationsOpen: false,
            });
          }}
          Alreadyexistingfiles={
            props.itemSpecificFiles[
              props.selectedQuotedItems[state.currentItemIndex]?.id
            ] || []
          }
        />
      )}
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "2px 15px",
          }}
        >
          <Typography variant="body1">Estimated Value Details</Typography>
          <IconButton
            edge="end"
            sx={{ color: (theme) => theme.palette.bluegrey[500] }}
            onClick={() => setModalOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Table>
            <TableBody>
              {Object.keys(selectedRowData).map((key) => {
                const field = selectedRowData[key];
                if (field?.FieldLabel === "Basic Price *") {
                  return (
                    <TableRow key={key}>
                      <TableCell align="left">Basic price of a bag:</TableCell>
                      <TableCell align="right">{field.value}</TableCell>
                    </TableRow>
                  );
                }
                return null;
              })}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
      {fileSpecificationsOpen && (
        <UploadDynamicView
          Loading={Loading}
          title="Specifications"
          SpecificationText={SpecificationText}
          uploadedFiles={specification?.data}
          open={fileSpecificationsOpen}
          handleClose={handleUploadClose}
        />
      )}
      <LandedCostRFQDrawer
        isLandedCostRFQOpened={landedCostRFQ}
        data={FilteredItems}
        isLandedCostRFQClosed={() => setLandedCostRFQ(false)}
        mainTemplateQuotation={FilteredMainTemplate}
        multiSolmainTemplateQuotation={props.mainMSTemplateQuotation || {}}
        solutionNames={props.solutionNames || {}}
        msFlag={props.multipleSolutionFlag}
        selectedCurrency={props.quotedCurrency}
        currencyDetails={props?.currencyDetails}
        inquiryItemsId={props?.inquiryItemsId}
        itemSpecific={props?.itemSpecific}
        transactionItemSequence={props?.transactionItemSequence}
        vendorType={props?.vendorType}
        TotalCostFIeld={TotalCostFIeld}
        currentTxn={props.CurrentTransaction}
        from="View"
        PerCost={PerCost}
        RateField={RateField}
      />
      <LandedCostSlabDrawer
        isLandedCostSlabOpened={landedCostSlab}
        data={FilteredItems}
        isLandedCostSlabClosed={() => setLandedCostSlab(false)}
        slabMainTemplateQuotation={FilteredSlabMainTemplate}
        selectedCurrency={props.quotedCurrency}
        currencyDetails={props?.currencyDetails}
        transactionItemSequence={props?.transactionItemSequence}
        vendorType={props?.vendorType}
        TotalCostFIeld={TotalCostFIeld}
        currentTxn={props.CurrentTransaction}
        from="View"
        PerCost={PerCost}
        RateField={RateField}
        selectedQuotedItems={props.selectedQuotedItems}
      />
      <Dialog
        open={ModalOpenTotalCost}
        onClose={() => setModalOpenTotalCost(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          component="div"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "2px 16px",
          }}
        >
          <Typography variant="subtitle1" fontWeight={500} component="h6">
            {ModalTitle} Details
          </Typography>
          <IconButton
            edge="end"
            sx={{ color: (theme) => theme.palette.bluegrey[500] }}
            onClick={() => setModalOpenTotalCost(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Table size="small">
            <TableBody>
              {modalData?.map(({ label, value }) => (
                <TableRow key={label}>
                  <TableCell>{label}</TableCell>
                  <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                    {getSymbol(props.currencyDetails)} &nbsp;
                    {isNaN(parseFloat(value).toFixed(3))
                      ? "0.000"
                      : parseFloat(value).toLocaleString("en-IN", {
                          minimumFractionDigits: 3,
                          maximumFractionDigits: 3,
                        })}
                    {" / "} {unit}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
}
