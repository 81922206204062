import axios from "axios";
import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Chip,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  Popover,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Alert,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { MoreVert, Add, Remove, GroupAddOutlined } from "@mui/icons-material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import Grid from "@mui/material/Unstable_Grid2";
import {
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridOverlay,
  getGridSingleSelectOperators,
} from "@mui/x-data-grid-pro";
import RFQMenuItems from "../../utils/RFQList/rfqMenuItems";
import { Tune } from "@mui/icons-material";
import QcsGridDrawer from "../../components/RFQList/drawer/qcsGridDrawer/qcsGridDrawer";
import PrGridDrawer from "../../components/RFQList/drawer/prGridDrawer/prGridDrawer";
import moment from "moment";
import { CustomIconButton } from "../../utils/RFQList/utils";
import AccountDateRangePicker from "../../utils/RFQList/accountDatePicker";
import RFQSearchModal from "../../components/RFQList/modal/rfqSearchModal/rfqSearchModal";
import JustificationPopOver from "../../common/RFQList/justificationPopOver";
import JustificationPopOverLastAction from "../../common/RFQList/justificationPopOver";

import {
  HOSTMAP,
  all_templates,
  getAccountModulePermission,
  getMasterPermission,
  getUserInfo,
  get_Inquiries,
  q_plus_screen,
  users_Data,
  validity_date_update,
  vendx_connect,
} from "../../config/apiUrl";
import { RFQDataGStyle } from "./Style";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import ItemWiseVendor from "../../components/RFQList/itemWiseVendor/itemWiseVendor";
import LinkUnlinkUser from "../../components/RFQList/linkUnlinkUser/linkUnlinkUser";
import { ExcelIcon, HexagonIcon } from "../../components/SvgIcon/SvgIcon";
import CommonDataGridPro from "../../components/RFQList/commonDataGridPro/commonDataGridPro";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { setRfqListInfo } from "../../redux/actions/rfqListAction";
import { setUserInfo } from "../../redux/actions/setUserInfo";
import DaterangepickerCustom from "../../utils/RFQList/accountDatePicker";
import {
  OPEN_SNACKBAR,
  SET_ACCOUNT_MODULE_PERMISSION,
  SET_ACCOUNT_MODULE_PERMISSION_ERROR,
  SET_MASTER_PERMISSION,
  SET_MASTER_PERMISSION_ERROR,
  SET_USER_INFO,
  SET_USER_INFO_ERROR,
} from "../../redux/constants/type";
import { setMasterPermission } from "../../redux/actions/permissionsAction";
import TrasactionDetailsDrawer from "../../components/RFQListVendor/TrasactionDetailsDrawer";
import VendorMenuItems from "../../utils/RFQList/vendorMenuItems";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { dateColumnTypeFilter } from "../../components/Utils/dateFilterDatagridPro";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import locale from "date-fns/locale/en-US";
import Hotjar from "@hotjar/browser";

export const customBooleanFilterOperator = {
  label: "Is",
  value: "is",
};

const PageToolbar = styled("div")(({ theme }) => ({
  background: "#ffffff",
  position: "sticky",
  top: 0,
  width: "100%",
  minHeight: "54px",
  padding: "6px 16px",
  zIndex: "99",
}));

export function CustomExpandIcon() {
  return <Add className="icon" />;
}

export function CustomCollapseIcon() {
  return <Remove className="icon" />;
}

function RFQListVendor(props) {
  // console.log("RfqDataGrid Props", props);
  // const drawerWidth = 850;
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = React.useState(null);
  const [InquiryId, setInquiryId] = React.useState(null);
  const [categoryId, setCategoryId] = React.useState(null);
  const [r2aId, setR2aId] = React.useState(null);
  const [selecetedRows, setSelectedRows] = React.useState(null);
  const [filterDataChip, setfilterDataChip] = React.useState(null);
  // const [userData, setUserData] = React.useState([]);
  const [templateNames, setTemplateNames] = React.useState([]);
  const [selectedRowIds, setSelectedRowIds] = React.useState([]);
  const [templateId, setTemplateId] = React.useState([]);
  const [itemSpecificVendor, setItemSpecificVendor] = React.useState([]);
  const [TransactionDetailsRow, setTransactionDetailsRow] = React.useState({});
  const [openPrDrawer, setOpenPrDrawer] = React.useState(false);
  const [openValidityDateModal, setOpenValidityDateModal] =
    React.useState(false);
  const [RFQNoForValidityDateModal, setRFQNoForValidityDateModal] =
    React.useState("false");
  const [statusClosed, setStatusClosed] = React.useState(false);
  const [openQcsDrawer, setOpenQcsDrawer] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [openDialogueBox, setOpenDialogueBox] = React.useState(false);
  const [linkDataFlag, setLinkDataFlag] = React.useState(false);
  const [openLinkUnlinkBox, setOpenLinkUnlinkBox] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [AnchorElLastAction, setAnchorElLastAction] = React.useState(null);
  const [justificationMessage, setJustificationMessage] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [isPriortized, setIsPriortized] = React.useState(false);
  const [anchorElTest, setAnchorElTest] = React.useState(null);
  const [hoveredItemId, setHoveredItemId] = React.useState(null);
  const [filteredListData, setFilteredListData] = React.useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState(null);
  const [InquiryData, setInquiryData] = React.useState({});
  const [selecetedRowValidDate, setSelecetedRowValidDate] =
    React.useState(null);
  const [dateValues, setDateValues] = React.useState(null);
  const [filteredUserData, setFilteredUserData] = React.useState([]); // Initialize filteredUserData state
  const [RFQListData, setRFQListData] = React.useState([]);

  const [filteredRows, setFilteredRows] = React.useState([]);
  const today = dayjs();
  const [value, setValue] = React.useState([null, null]);
  const [selectedValueOptions, setSelectedValueOptions] = React.useState([]);
  const [JustificationText, setJustificationText] = React.useState("");
  const [IframeVisible, setIframeVisible] = React.useState(false);
  const [Guid, setGuid] = React.useState("");
  const [templateID, settemplateID] = React.useState();
  const [filteredData, setFilteredData] = React.useState([]);
  const [TranType, setTranType] = React.useState("");
  // const handleValueOptionClick = (valueOption) => {
  //   setSelectedValueOptions([...selectedValueOptions, valueOption]);
  // };
  const userInfo = useSelector((state) => state.userInfoReducer.userInfo);

  const Permisison = useSelector((state) => state.permissionsReducer);
  const [JustificationContent, setJustificationContent] = React.useState("");
  const [JustificationTitle, setJustificationTitle] = React.useState("");
  const [flagFromChild, setFlagFromCHild] = React.useState(false);
  const [Refresh, setRefresh] = React.useState(false);
  const [IframeUrl, setIframeUrl] = React.useState();
  const iframeRef = React.useRef(null);

  const initialLinkUnlinkData = {
    inquiryName: null,
    inquiryNo: null,
    linkedUserId: null,
    createdBy: null,
  };

  // const sessionID = "1667372754321900.108495002283544";

  const menulabels = {
    technical_bid_preview: "Technical Bid Preview",
    technical_bid: "Technical Bid",
  };
  const multipleSolution = localStorage.getItem("multipleSolution");
  const filterLabels = {
    multiple_solution: multipleSolution,
    r2a: "R2A",
    sealed: "Sealed",
    prioritized: "Prioritized",
    contract: "Contract",
    multi_plant: "Multi Plant",
    technical_bid: "Technical Bid",
  };

  const sessionID = props.session_id;
  // const menulabels = props.inquiry_menu_labels;

  // const filterLabels = props.filter_labels;
  const quotationListInfo = useSelector((state) => state.quotationInfoReducer.quotationListInfo);
  const configAxios = {
    headers: { Authorization: `Bearer ${sessionID}` },
  };
  const submitQuote = (data) => {
    let url = `${window.location.origin}/purchase-product/quotation-form?q=${sessionID}&supplier=1&inq_id=${data.id}&vendor_id=${data.vendor.id}`;
    setIframeUrl(url);
    setIframeVisible(true);
  };
  const openRfqNoLinkQplusScreen = (inqId, type = "") => {
    if (type == "QUOTATION_REF") {
      // settemplateID(inqId?.template_id)
      setGuid(inqId?.vendor?.quotation?.latest_TLGUID); //need to set latest guid
      // setTranType(inqId?.vendor?.trantype)
    } else {
      // setIframeVisible(true);
      setGuid(inqId?.vendor?.guid);
    }
    setIframeUrl();
    setIframeVisible(true);
    settemplateID(inqId?.template_id);
    setTranType(inqId?.vendor?.trantype);
    // console.log('inqId', inqId);
    // const form = document.createElement("form");
    // form.target = "_blank";
    // form.action = q_plus_screen;
    // form.method = "post";

    // const hiddenInput = document.createElement("input");
    // hiddenInput.type = "hidden";
    // hiddenInput.name = "inq_id";
    // hiddenInput.value = inqId;

    // form.appendChild(hiddenInput);
    // document.body.appendChild(form);
    // form.submit();
  };

  const openMenu = Boolean(anchorEl);
  const openMenu2 = Boolean(anchorEl2);
  const openTest = Boolean(anchorElTest);
  const openLastAction = Boolean(AnchorElLastAction);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  const siteId = 3807671;
  const hotjarVersion = 6;
  React.useEffect(() => {
    // Initialize Hotjar when the component mounts
    Hotjar.init(siteId, hotjarVersion);
  }, []);

  const handleChange = async (event) => {
    if (event[0] == null && event[1] == null) {
      setValue([null, null]);
    }
    if (event[0] !== null && event[1] !== null) {
      const newStartDate = dayjs(event[0]);
      const newEndDate = dayjs(event[1]);

      if (newStartDate.year().toString().length > 3 && newEndDate.year().toString().length > 3) {
        if (newStartDate.isValid() && newEndDate.isValid()) {
          if (!newStartDate.isSame(value[0]) && !newEndDate.isSame(value[1])) {
            setValue(event);
          }
          if (!newEndDate.isSame(value[1])) {
            setValue([event[0], event[1]]);
          }
        } else {
          console.error("Invalid date input");
        }
      }
    }
  };

  function CustomGridToolbar() {
    return (
      <React.Fragment>
        <GridToolbarContainer className="datagrid-toolbar">
          <Grid
            container
            spacing={0}
            sx={{
              width: "100%",
              pt: 1,
              "&.MuiGrid2-root": {
                paddingTop: { xs: "0", sm: "0", md: "0", alignItems: "center" },
              },
            }}
          >
            {/* <Grid xs={9} sm={8} md={5} xl={4}> */}
            <Grid xs={12} sm={12} md={5} xl={4}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box className="QsearchBox">
                  <GridToolbarQuickFilter
                    className="DataGrid-SearchBar"
                    placeholder="Search..."
                    sx={{ pb: 0 }}
                  />
                  <IconButton
                    variant=""
                    color="default"
                    size="small"
                    onClick={() => OpenSearchFilterModal()}
                  >
                    <Tune />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
            <Grid
              xs={3}
              sm={4}
              sx={{ display: { xs: "none", sm: "none", md: "none" } }}
            >
              {/* <SelectGridView /> */}
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={7}
              xl={8}
              sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
            >
              <Box className="toolbar-right">
                <Box>
                  <DaterangepickerCustom
                    value={value}
                    setDateRangeValue={(value) => {
                      handleChange(value);
                    }}
                  />
                </Box>
                <Box className="dg-filter">
                  <GridToolbarColumnsButton />
                  <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                  />
                  <GridToolbarFilterButton />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </GridToolbarContainer>

        <GridToolbarContainer
          className="datagrid-toolbar"
          sx={{ display: { sm: "flex", md: "none" } }}
        >
          <Grid
            container
            spacing={0}
            sx={{ width: "100%", alignItems: "center" }}
          >
            <Grid xs={6} md={6}>
              <Box>
                <DaterangepickerCustom
                  value={value}
                  setDateRangeValue={(value) => {
                    handleChange(value);
                  }}
                />
              </Box>
            </Grid>
            <Grid
              xs={6}
              md={6}
              sx={{ textAlign: { xs: "right", sm: "right" } }}
            >
              <Box className="dg-filter">
                <GridToolbarColumnsButton />
                <GridToolbarExport
                  printOptions={{ disableToolbarButton: true }}
                />
                <GridToolbarFilterButton />
              </Box>
            </Grid>
          </Grid>
        </GridToolbarContainer>
      </React.Fragment>
    );
  }

  const handleCloseDialogue = () => {
    setOpenDialogueBox(false);
  };

  const rfqStatusCell = (params) => {
    const { value } = params;
    const isClosedStatus = value === "Closed";
    setStatusClosed(isClosedStatus);
    return (
      <Box sx={{ textTransform: "uppercase" }}>
        {isClosedStatus && (
          <Chip
            size="small"
            label="Closed"
            sx={{ cursor: "pointer" }}
            variant="soft"
            color="default"
            aria-haspopup="true"
            onClick={(e) =>
              transactionStatusModal(e, params.row.id, params.row.justification)
            }
          />
        )}
        {!isClosedStatus && (
          <Chip
            size="small"
            label={
              value === "Ongoing"
                ? "Ongoing"
                : value === "Expired"
                  ? "Expired"
                  : "Unknown"
            }
            variant="soft"
            color={
              value === "Ongoing"
                ? "success"
                : value === "Expired"
                  ? "error"
                  : "default"
            }
          />
        )}
      </Box>
    );
  };

  const rfqStartDateCell = (params) => {
    const { value } = params;
    const formatDate = moment(value, "YYYY-MM-DD HH:mm:ss").format(
      "DD/MM/YYYY HH:mm:ss a"
    );
    const splittedArray = formatDate?.split(" ");
    return (
      <Box>
        <Typography variant="body2" component="div">
          {splittedArray[0]}
        </Typography>
        <Typography variant="caption" component="div">
          {splittedArray[1] + " " + "IST"}
        </Typography>
      </Box>
    );
  };
  const valueGetterForDate = (params) => {
    const { value } = params;
    // Assuming the date format is "YYYY-MM-DD HH:MM:SS IST"
    const dateParts = value.split(" ");
    if (dateParts.length === 3) {
      const [datePart, timePart, timeZone] = dateParts;
      const iso8601DateTime = `${datePart}T${timePart}`;
      const parsedDate = new Date(iso8601DateTime);

      // Check if the parsed date is a valid date object
      if (!isNaN(parsedDate)) {
        return parsedDate;
      }
    }
    return null; // Return null for invalid date strings
  };
  const rfqEndDateCell = (params) => {
    const { value } = params;
    const formatDate = moment(value, "YYYY-MM-DD HH:mm:ss").format(
      "DD/MM/YYYY HH:mm:ss"
    );
    const splittedArray = formatDate?.split(" ");
    return (
      <Box>
        <Typography variant="body2" component="div">
          {splittedArray[0]}
        </Typography>
        <Typography variant="caption" component="div">
          {splittedArray[1] + " " + "IST"}
        </Typography>
      </Box>
    );
  };
  const receiveFlagFromChild = (flag) => {
    setFlagFromCHild(flag);
  };
  const TrasactionDetailsVendorCell = (params) => {
    const { id, row } = params;
    const handleOpenDialogue = () => {
      setOpenDialogueBox(true);
      setSelectedId(id);
      setTransactionDetailsRow(row);
    };
    return (
      <CustomIconButton onClick={(e) => handleOpenDialogue(e)}>
        <AddBoxOutlinedIcon
          sx={{
            fontSize: "24px",
            color: "var(--grey-600)",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        />
      </CustomIconButton>
    );
  };

  const handlePopoverClose = () => {
    setAnchorEl2(null);
  };

  const transactionStatusModal = (event, id, justification) => {
    setAnchorEl2(event.currentTarget);
    setJustificationText(justification);

    event.stopPropagation();
  };

  const LastActionModel = (event, id, justification, status) => {
    setAnchorElLastAction(event.currentTarget);
    setJustificationTitle(status);
    setJustificationContent(justification);

    event.stopPropagation();
  };
  const LastActionModelClose = () => {
    setAnchorElLastAction(null);
    setJustificationTitle("");
    setJustificationContent("");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverOpenTest = (event, itemId) => {
    setAnchorElTest(event.currentTarget);
    setHoveredItemId(itemId);
  };

  const handlePopoverCloseTest = () => {
    setAnchorElTest(null);
    setHoveredItemId([]);
  };

  const tripleDotStatusCell = (params) => {
    const handleClick = (event, id) => {
      setAnchorEl(event.currentTarget);
      setInquiryId(id);
      setInquiryData(params?.row);
      event.stopPropagation();
    };
    return (
      <Box>
        <CustomIconButton onClick={(e) => handleClick(e, params.row.id)}>
          <MoreVert
            sx={{
              color: "var(--thm-gray)",
              cursor: "pointer",
              rowSelectionModel,
            }}
          />
        </CustomIconButton>
      </Box>
    );
  };

  const tagsFilterOperators = getGridSingleSelectOperators()
    .filter((operator) => operator.value === "isAnyOf")
    .map((operator) => {
      const newOperator = { ...operator };
      const newGetApplyFilterFn = (filterItem, column) => {
        return (params) => {
          if (filterItem?.value) {
            let isOk = true;
            filterItem?.value.forEach((fv) => {
              if (isOk == true && params.value[fv] == false) {
                isOk = false;
              }
            });
            return isOk;
          }
        };
      };
      newOperator.getApplyFilterFn = newGetApplyFilterFn;
      return newOperator;
    });

  const rfqVariantFilterData = [
    // { value: "slab", label: "Slab" },
    { value: "prioritized", label: filterLabels.prioritized },
    { value: "contract", label: filterLabels.contract },
    { value: "multiple_solution", label: filterLabels.multiple_solution },
    { value: "multi_plant", label: filterLabels.multi_plant },
    { value: "r2a", label: filterLabels.r2a },
    // Permisison?.accountModulePermission?.["Technical Bid"]?.module_activated == "Y" ? { value: "technical_bid", label: filterLabels.technical_bid } : ''
  ];
  if (
    Permisison?.accountModulePermission?.["Technical Bid"]?.module_activated ==
    "Y"
  ) {
    rfqVariantFilterData.push({
      value: "technical_bid",
      label: filterLabels.technical_bid,
    });
  }
  const columns = [
    {
      field: "actions",
      headerName: "",
      width: 40,
      align: "center",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableExport: true,
      renderCell: tripleDotStatusCell,
    },
    // {
    //   field: "addBox",
    //   headerName: "",
    //   sortable: false,
    //   filterable: false,
    //   disableColumnMenu: true,
    //   width: 50,
    //   align: "center",
    //   disableExport: true,
    //   renderCell: TrasactionDetailsVendorCell,
    // },

    {
      field: "filter_data",
      headerName: "RFQ VARIANT",
      sortable: false,
      filterable: true,
      disableColumnMenu: false,
      type: "singleSelect",
      valueOptions: rfqVariantFilterData,
      filterOperators: tagsFilterOperators,
      width: 90,
      disableExport: true,
      align: "center",
      renderCell: (params) => {
        const { value, id } = params;
        return (
          <Box>
            {value.multi_plant ||
              value.prioritized ||
              value.contract ||
              value.multiple_solution ||
              value.r2a ||
              (value.technical_bid &&
                rfqVariantFilterData.some(
                  (option) => option.value == "technical_bid"
                )) ? (
              <>
                <IconButton
                  onMouseEnter={(e) => handlePopoverOpenTest(e, id)}
                  onMouseLeave={handlePopoverCloseTest}
                >
                  <HexagonIcon
                    aria-owns={openTest ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                    // onClick={handlePopoverOpenTest}
                    onMouseEnter={(e) => handlePopoverOpenTest(e, id)}
                    onMouseLeave={handlePopoverCloseTest}
                    sx={{ color: "var(--grey-600)" }}
                  />
                </IconButton>
              </>
            ) : (
              <IconButton disabled>
                <HexagonIcon sx={{ color: "var(--grey-400)" }} />
              </IconButton>
            )}

            {(value.multi_plant ||
              value.prioritized ||
              value.contract ||
              value.multiple_solution ||
              value.r2a ||
              value.technical_bid) && (
                <Popover
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: "none",
                    "&.MuiPopover-root .MuiPaper-root": {
                      maxWidth: "300px",
                      padding: "5px",
                      "& .MuiChip-root": {
                        margin: "5px",
                      },
                      cursor: "pointer",
                    },
                  }}
                  open={hoveredItemId === id}
                  anchorEl={anchorElTest}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  onClose={handlePopoverCloseTest}
                  disableRestoreFocus
                >
                  {params?.row?.filter_data?.prioritized && (
                    <Chip label={filterLabels.prioritized} size="small" />
                  )}
                  {params?.row?.filter_data?.multi_plant && (
                    <Chip label={filterLabels.multi_plant} size="small" />
                  )}
                  {params?.row?.filter_data?.contract && (
                    <Chip label={filterLabels.contract} size="small" />
                  )}
                  {params?.row?.filter_data?.multiple_solution && (
                    <Chip label={filterLabels.multiple_solution} size="small" />
                  )}
                  {params?.row?.filter_data?.r2a && (
                    <Chip label={filterLabels.r2a} size="small" />
                  )}
                  {Permisison?.accountModulePermission?.["Technical Bid"]
                    ?.module_activated == "Y" &&
                    params?.row?.valid_date_tb !== null &&
                    params?.row?.filter_data?.technical_bid && (
                      <Chip label={filterLabels.technical_bid} size="small" />
                    )}
                </Popover>
              )}
          </Box>
        );
      },
    },
    {
      field: "number",
      headerName: "RFQ NO.",
      width: 275,
      renderCell: (params) => {
        return (
          <Stack
            direction="row"
            justifyContent="between"
            alignItems="center"
            sx={{
              width: "100%",
              "& .MuiButtonBase-root": {
                visibility: { md: "hidden", sm: "visible" },
                marginLeft: "auto",
              },
              "&:hover .MuiButtonBase-root": {
                visibility: "visible",
              },
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                width: "calc(100% - 30px)",
                alignItems: "center",
                display: "flex",
                gap: "4px",
              }}
            >
              <Tooltip title={params.value} placement="bottom">
                <Typography
                  variant="subtitle2"
                  component="h4"
                  fontWeight={700}
                  noWrap
                  sx={{
                    color: "text.primary",
                    textDecoration: "underline",
                  }}
                  onClick={() => openRfqNoLinkQplusScreen(params.row)}
                >
                  {params.value}
                  {params.row.itemize_flag === "Y" ? (
                    <Chip
                      label="IV"
                      variant="soft"
                      size="xsmall"
                      sx={{
                        marginLeft: "8px",
                        color: "black",
                        border: "solid 1px black",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Typography>
              </Tooltip>
            </Box>
            <Tooltip title="View Details" placement="bottom">
              <IconButton
                aria-label="View Details"
                size="small"
                onClick={() => openRfqNoLinkQplusScreen(params.row)}
              >
                <LaunchRoundedIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
      valueGetter: (params) => params.row.number,
    },

    {
      field: "group_id",
      headerName: "GROUP RFQ NO.",
      width: 110,
      align: "right",
      renderCell: (params) => {
        return (
          <Box>
            {!params?.value || params?.value?.length === 0 ? (
              <Typography variant="body2" fontWeight={500}>
                -
              </Typography>
            ) : (
              <Typography variant="body2" fontWeight={500}>
                {params.value}
              </Typography>
            )}
          </Box>
        );
      },
    },

    {
      field: "status",
      headerName: "TRANSACTION STATUS",
      width: 160,
      type: "singleSelect",
      valueOptions: ["Ongoing", "Closed", "Expired"],
      renderCell: rfqStatusCell,
    },

    {
      field: "created_at",
      ...dateColumnTypeFilter,
      headerName: "RFQ DATE",
      width: 140,
      renderCell: rfqStartDateCell,
      valueGetter: valueGetterForDate,
      valueFormatter: (params) => {
        const { value } = params;
        return (
          moment(value, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss") +
          " IST"
        );
      },
    },
    {
      field: "expiry_at",
      ...dateColumnTypeFilter,
      headerName: "VALIDITY DATE",
      width: 140,
      // editable: statusClosed ? false : true,
      filterable: true,
      renderCell: rfqEndDateCell,
      // valueFormatter: (params) => {
      //   const { value } = params;
      //   return moment(value, "YYYY-MM-DD HH:mm:ss").format(
      //     "DD/MM/YYYY HH:mm:ss"
      //   );
      // },
      valueGetter: valueGetterForDate,
      valueFormatter: (params) => {
        const { value } = params;
        return (
          moment(value, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss") +
          " IST"
        );
      },
    },

    {
      field: "Last_action",
      headerName: "LAST ACTION",
      width: 140,
      // filterable: false,
      // disableExport: true,
      type: "singleSelect",
      valueOptions: [
        "Pending",
        "Off regret Nego",
        "Nego. Regretted",
        "Negotiations",
        "Off Regretted",
        "Regretted",
        "Off. Requoted",
        "Requoted",
        "Off. Quoted",
        "Quoted",
      ],
      valueGetter: (params) => params?.row?.vendor?.quotation?.status,
      renderCell: (params) => {
        let colorVal;

        switch (params?.row?.vendor?.quotation?.status) {
          case "Pending":
            colorVal = "warning";
            break;
          case "Off. Requoted":
          case "Requoted":
          case "Off. Quoted":
          case "Quoted":
          case "Negotiations":
            colorVal = "success";
            break;
          case "Off regret Nego":
          case "Nego. Regretted":
          case "Off Regretted":
          case "Regretted":
            colorVal = "error";
            break;
          default:
            break;
        }

        return (
          <Box>
            {params?.row?.vendor?.quotation?.justification ? (
              <Chip
                label={params?.row?.vendor?.quotation?.status}
                variant="soft"
                size="small"
                color={colorVal}
                onClick={(e) =>
                  LastActionModel(
                    e,
                    params.row.id,
                    params?.row?.vendor?.quotation?.justification,
                    params?.row?.vendor?.quotation?.status
                  )
                }
              />
            ) : (
              <Chip
                label={params?.row?.vendor?.quotation?.status}
                variant="soft"
                size="small"
                color={colorVal}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "last_action_at",
      ...dateColumnTypeFilter,
      headerName: "LAST ACTION DATE",
      width: 140,

      valueGetter: (params) => {
        if (params?.row?.vendor?.quotation?.action_at != null) {
          const dateParts =
            params?.row?.vendor?.quotation?.action_at.split(" ");
          if (dateParts.length === 3) {
            const [datePart, timePart, timeZone] = dateParts;
            const iso8601DateTime = `${datePart}T${timePart}`;
            const parsedDate = new Date(iso8601DateTime);

            // Check if the parsed date is a valid date object
            if (!isNaN(parsedDate)) {
              return parsedDate;
            }
          }
        }
        return null; // Return null for invalid date strings
      },
      valueFormatter: (params) => {
        return params?.value != null
          ? moment(params?.value, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY HH:mm:ss"
          ) + " IST"
          : "-";
      },
      renderCell: (params) => {
        if (params?.value == null) {
          return (
            <Typography variant="body2" component="div">
              {"-"}
            </Typography>
          );
        } else {
          const formatDate = moment(
            params?.value,
            "YYYY-MM-DD HH:mm:ss"
          ).format("DD/MM/YYYY HH:mm:ss");
          const splittedArray = formatDate?.split(" ");
          return (
            <Box sx={{ cursor: "pointer" }}>
              <Typography variant="body2" component="div">
                {splittedArray[0]}
              </Typography>
              <Typography variant="caption" component="div">
                {splittedArray[1] + " " + "IST"}
              </Typography>
            </Box>
          );
        }
      },
    },
    {
      field: "vendor_validity_date",
      ...dateColumnTypeFilter,
      headerName: "QUOTATION VALIDITY DATE",
      width: 140,
      valueFormatter: (params) => {
        return params?.row?.vendor?.quotation?.validity_date != null
          ? moment(
            params?.row?.vendor?.quotation?.validity_date,
            "YYYY-MM-DD HH:mm:ss"
          ).format("DD/MM/YYYY HH:mm:ss")
          : "-";
      },
      valueGetter: (params) => {
        if (params?.row?.vendor?.quotation?.validity_date != null) {
          const dateParts =
            params?.row?.vendor?.quotation?.validity_date.split(" ");
          if (dateParts.length === 3) {
            const [datePart, timePart, timeZone] = dateParts;
            const iso8601DateTime = `${datePart}T${timePart}`;
            const parsedDate = new Date(iso8601DateTime);

            if (!isNaN(parsedDate)) {
              return parsedDate;
            }
          }
        }
        return null; // Return null for invalid date strings
      },
      valueFormatter: (params) => {
        return params?.value != null
          ? moment(params?.value, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY HH:mm:ss"
          ) + " IST"
          : "-";
      },
      renderCell: (params) => {
        if (params?.value == null) {
          return (
            <Typography variant="body2" component="div">
              {"-"}
            </Typography>
          );
        } else {
          const formatDate = moment(
            params?.value,
            "YYYY-MM-DD HH:mm:ss"
          ).format("DD/MM/YYYY HH:mm:ss");
          const splittedArray = formatDate?.split(" ");
          return (
            <Box sx={{ cursor: "pointer" }}>
              <Typography variant="body2" component="div">
                {splittedArray[0]}
              </Typography>
              <Typography variant="caption" component="div">
                {splittedArray[1] + " " + "IST"}
              </Typography>
            </Box>
          );
        }
      },
    },
    {
      field: "vendor_quotation_ref_no",
      headerName: "QUOTATION REF NO.",
      width: 160,
      valueGetter: (params) =>
        params?.row?.vendor?.quotation?.quotation_ref
          ? params?.row?.vendor?.quotation?.quotation_ref
          : "Submit Quote",
      renderCell: (params) => {
        return (
          <>
            {params?.value !== "Submit Quote" ? (
              <Tooltip title={params.value}>
                <Typography
                  variant="subtitle2"
                  component="h4"
                  fontWeight={700}
                  noWrap
                  sx={{
                    color: "text.primary",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => openRfqNoLinkQplusScreen(params.row, "QUOTATION_REF")}
                >
                  {params?.value}
                </Typography>
              </Tooltip>
            ) : (
              <Chip
                label="Submit Quote"
                variant="soft"
                size="small"
                color="primary"
                onClick={(e) => submitQuote(params.row)}
              />
            )}
          </>
        );
      },
    }
    
  ];

  const OpenSearchFilterModal = () => {
    setShowModal(true);
  };

  const CloseSearchFilterModal = () => {
    setShowModal(false);
  };

  // Callback function to update filtered list data
  const handleFilterChange = async (filteredData) => {
    await setFilteredListData(filteredData);
  };

  const handleRefreshGrid = (refreshFlag) => {
    setRefresh(refreshFlag);
  };

  React.useEffect(() => {
    if (value && value[0] && value[0].$d) {
      if (Array.isArray(RFQListData)) {
        const filteredRows = RFQListData.filter((row) => {
          const date = moment(row.created_at, "YYYY-MM-DD");
          if (value[1]) {
            return date._d >= value[0].$d && date._d <= value[1].$d;
          } else {
            return date._d >= value[0].$d;
          }
        });
        setFilteredRows(filteredRows);
      }
    } else {
      // If selectedDateRange is not set or invalid, use the original data
      setFilteredRows(RFQListData);
    }
    // if (RFQListData.length > 0) {
    //  setLoading(false)
    // }
  }, [value, RFQListData]);

  // React.useEffect(() => {
  //   async function fetchUsersData() {
  //     try {
  //       const response = await axios.get(users_Data, configAxios);
  //       if (response.status !== 200) {
  //         throw new Error("Network response was not ok");
  //       }
  //       setUserData(response.data.users);
  //     } catch (error) {
  //       console.error("Error fetching User Data:", error);
  //     }
  //   }
  //   fetchUsersData();
  // }, []);

  const fetchVendorsInquires = () => {
    let url = `${get_Inquiries}?vendor_ids[]=${userInfo?.UserID}&embed[]=vendor`;
    if (Object.keys(userInfo).length > 0) {
      axios
        .get(url, configAxios)
        .then((res) => {
          setRFQListData(res?.data?.inquires);
          // dispatch(setuserdetails(values));
          setLoading(false);
        })
        .catch((err) => {
          console.log("err", err.response.data.code);
          setLoading(false);
        });
    }
  };

  React.useEffect(() => {
    fetchVendorsInquires();
  }, [userInfo]);

  React.useEffect(() => {
    if (Refresh) {
      fetchVendorsInquires();
    }
  }, [Refresh]);
  React.useEffect(() => {
    if (quotationListInfo?.frommail) {
      const queryParams = new URLSearchParams(quotationListInfo);
      let url = `${window.location.origin}/purchase-product/quotation-form?${queryParams}`;
      setIframeUrl(url);
      setIframeVisible(true);
    }
  }, [quotationListInfo]);
  React.useEffect(() => {
    // Function to handle messages from the iframe
    const handleMessage = (event) => {
      // Ensure the message is from a trusted origin
      // if (event.origin === HOSTMAP) {
      // Check the message data and perform the action
      if (event.data.message === 'changeIframeSrc' || event.data.message === "update url") {
        setIframeUrl(event.data.url);
      }
      // }
    };

    // Add event listener for messages
    window.addEventListener('message', handleMessage);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  // added for zoho help icon from shital :: START
  // React.useEffect(() => {
  //   const script = document.createElement('script');
  //   script.type = 'text/javascript';
  //   script.nonce = '{place_your_nonce_value_here}';
  //   script.src = 'https://desk.zoho.in/portal/api/web/inapp/29496000022363045?orgId=60005599474';
  //   script.defer = true;

  //   document.head.appendChild(script);

  //   return () => {
  //     document.head.removeChild(script);
  //   };
  // }, []);
  // added for zoho help icon from shital :: END

  const CustomNoRowsOverlay = () => (
    <GridOverlay>
      <Typography>No data available</Typography>
    </GridOverlay>
  );

  const getCellClassName = (params) => {
    if (params.field === "rfq_status" && params.row.rfq_status === "30") {
      return "hovered-cell";
    }
    return "";
  };

  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("xs");
  const handleCloseModal = () => {
    setIframeVisible(false);
    setLoading(true);
    fetchVendorsInquires();
  };
  return (
    <React.Fragment>
      {RFQDataGStyle}
      {!IframeVisible ? (
        <>
          <main
            sx={{
              width: "100%",
              maxWidth: "100%",
              padding: 0,
            }}
          >
            <PageToolbar className="datagrid-PageToolbar head-datagrid-toolbar">
              <Stack spacing={2} direction="row" alignItems={"center"}>
                <Typography className="app-pagetitle">
                  Transaction Register
                </Typography>
              </Stack>
            </PageToolbar>
            <RFQSearchModal
              sessionID={sessionID}
              showModal={showModal}
              setShowModal={setShowModal}
              CloseSearchFilterModal={CloseSearchFilterModal}
              OpenSearchFilterModal={OpenSearchFilterModal}
              onFilterChange={handleFilterChange}
              sendFlagToParent={receiveFlagFromChild}
              From="Supplier"
            />
            <Backdrop
              style={{ color: "var(--thm-white)", zIndex: "10000000000" }}
              open={flagFromChild}
            >
              <CircularProgress style={{ color: "var(--thm-white)" }} />
            </Backdrop>
            <Box className="DataGFullScreen-wrap">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={locale}
              >
                <CommonDataGridPro
                  loading={loading}
                  rows={
                    filteredListData?.length > 0
                      ? filteredListData
                      : filteredRows
                  }
                  columns={columns}
                  headerHeight={40}
                  columnHeaderHeight={40}
                  checkboxSelection
                  Toolbar={CustomGridToolbar}
                  NoRowsOverlay={CustomNoRowsOverlay}
                  // onRowSelectionModelChange={handleRowSelectionChange}
                  // rowSelectionModel={rowSelectionModel}
                  disableRowSelectionOnClick
                  pagination={true}
                  pageSizeOptions={[25, 50, 100]}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  // getRowHeight={() => "auto"}
                  getRowId={(row) => row.id}
                  className="fullScreenGrid"
                  sx={{
                    "&.MuiDataGrid-root": {
                      border: 0,
                    },
                    "& .MuiDataGrid-virtualScrollerContent--overflowed .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                    {
                      borderBottomColor: "var(--bluegrey-500)",
                    },
                  }}
                  getCellClassName={getCellClassName}
                />
              </LocalizationProvider>
            </Box>
          </main>
          {/* {IframeVisible && (
        <iframe
          title="Your Iframe Title"
          src={`${HOSTMAP}/cgi-bin/vendx/index.cgi?vendx_action=tran_details&TLGUID=${Guid}&TType=Inquiry&TemplateID=${templateID}&call_from=qcs_plus&q=${sessionID}`}
          width="100%"
          height="400px"
          frameBorder="1"
          allowFullScreen
        ></iframe>
      )} */}
          <VendorMenuItems
            openMenu={openMenu}
            handleClose={handleClose}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            menulabels={menulabels}
            From="Supplier"
            sessionID={sessionID}
            selectedId={InquiryId}
            InquiryData={InquiryData}
            handleRefreshGrid={handleRefreshGrid}
          />
          <TrasactionDetailsDrawer
            sessionID={sessionID}
            openDialogueBox={openDialogueBox}
            handleCloseDialogue={handleCloseDialogue}
            TransactionDetailsRow={TransactionDetailsRow}
            templateNames={templateNames}
          />
          {/* <JustificationPopOver
            open={openMenu2}
            anchorEl={anchorEl2}
            onClose={handlePopoverClose}
            justificationTitle="Justification For RFQ Closed"
            justificationDetails={JustificationText ? JustificationText : "-"}
          /> */}
          <JustificationPopOverLastAction
            open={openLastAction}
            anchorEl={AnchorElLastAction}
            onClose={LastActionModelClose}
            justificationTitle={JustificationTitle}
            justificationDetails={JustificationContent}
          />
        </>
      ) : (
        // Code when the condition is false
        <React.Fragment>
          <iframe
            ref={iframeRef}
            title="Your Iframe Title"
            src={
              IframeUrl ||
              `${HOSTMAP}/cgi-bin/vendx/QuotationForm.cgi?vendx_action=tran_details&TLGUID=${Guid}&TType=${TranType}&TemplateID=${templateID}&call_from=qcs_plus&q=${sessionID}`
            }
            width="100%"
            height="800px"
            frameBorder="0"
            className="QuotationForm-Iframe"
            style={{
              height: "calc(100vh - 82px)"
            }}
            allowFullScreen
          ></iframe>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
export default RFQListVendor;
