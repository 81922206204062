import React, { useEffect, useState } from "react";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
    TextField,
    InputAdornment,
    Snackbar,
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FindInPageIcon from "@mui/icons-material/FindInPageOutlined";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import {
    getTechnicalBidTemplate,
    getTechnicalBidTemplateItemize,
    linkUnlinkTechnicalBidTemplate,
    linkUnlinkTechnicalBidTemplateItemize,
    prviewTB,
} from "../../config/apiUrl";
import { URL_MAPPING } from "../../config/host-mapping";
import InnerHTML from "dangerously-set-html-content";
import { useDispatch } from "react-redux";
import { setRfqListInfo } from "../../redux/actions/rfqListAction";
import { LinkUnlinkItemDrawer } from "./LinkUnlinkForItemStyle";

const currentHost = window.location.host;
const parentHostURI =
    URL_MAPPING[currentHost]["parent_host"] ||
    URL_MAPPING["default"]["parent_host"];

export function loadScripts(sources) {
    sources.forEach((src) => {
        var script = document.createElement("script");
        script.src = src;
        script.async = false; //<-- the important part
        document.body.appendChild(script); //<-- make sure to append to body instead of head
    });
}
export function loadStyle(sources) {
    sources.forEach((src) => {
        var linkElement = document.createElement("link");
        linkElement.setAttribute("rel", "stylesheet");
        linkElement.setAttribute("type", "text/css");
        linkElement.setAttribute("href", src);
        document.head.appendChild(linkElement);
    });
}
export function removeJSFile(filename, filetype) {
    var targetelement =
        filetype == "js" ? "script" : filetype == "css" ? "link" : "none"; //determine element type to create nodelist from
    var targetattr =
        filetype == "js" ? "src" : filetype == "css" ? "href" : "none"; //determine corresponding attribute to test for
    var allsuspects = document.getElementsByTagName(targetelement);
    for (var i = allsuspects.length; i >= 0; i--) {
        //search backwards within nodelist for matching elements to remove
        if (
            allsuspects[i] &&
            allsuspects[i].getAttribute(targetattr) != null &&
            allsuspects[i].getAttribute(targetattr).indexOf(filename) != -1
        )
            allsuspects[i].parentNode.removeChild(allsuspects[i]); //remove element by calling parentNode.removeChild()
    }
}

export default function LinkUnlinkTemplateItemDrawer(props) {
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState("");
    const [templates, setTemplates] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [anchorElTB, setAnchorElTB] = useState(false);
    const [loader, setLoader] = React.useState(true);
    const [Tbpreview, setTbpreview] = React.useState({
        __html: "",
    });

    const handleToggleLink = async (index) => {
        const template = templates[index];
        const newLinkedStatus = !template.is_linked;
        const messages = []; // Array to collect messages

        try {
            // If linking new template, unlink existing first
            if (newLinkedStatus) {
                const linkedTemplate = templates.find((t) => t.is_linked);
                if (linkedTemplate) {
                    await linkUnlinkTemplate(props.itemId, linkedTemplate.id, "unlink");
                    setTemplates((prevTemplates) =>
                        prevTemplates.map((t) =>
                            t.id === linkedTemplate.id ? { ...t, is_linked: false } : t
                        )
                    );
                    messages.push(`${linkedTemplate.name} Template Unlinked!`);
                }
            }

            // Link/unlink current template
            const action = newLinkedStatus ? "link" : "unlink";
            await linkUnlinkTemplate(props.itemId, template.id, action);

            // Update state
            setTemplates((prevTemplates) =>
                prevTemplates.map((t, i) =>
                    i === index ? { ...t, is_linked: newLinkedStatus } : t
                )
            );

            // Add main action message
            messages.push(
                newLinkedStatus
                    ? `${template.name} Template Linked!`
                    : `${template.name} Template Unlinked!`
            );

            // Set final message
            setSnackbarMessage(messages.join(" and "));
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        } catch (error) {
            console.error("Error updating template:", error);
            setSnackbarMessage("An error occurred. Please try again.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const linkUnlinkTemplate = async (item_id, link_id, action) => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: props.sessionId,
                },
            };

            const url = linkUnlinkTechnicalBidTemplateItemize(
                item_id,
                link_id,
                action
            );
            const res = await axios.patch(url, config);
            return res.data;
        } catch (error) {
            console.error("Error posting data:", error);
            throw error;
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleTBPreview = async (templateId) => {
        const formData = new FormData();
        formData.append("from_react", "1");
        // formData.append("inquiry_id", props.inquiry_id);
        // formData.append("tran_type_guid", props.InquiryData?.vendor?.guid);
        formData.append("master_action", 'technical_bid');
        formData.append("oper", 'preview');
        formData.append("tb_id", templateId); // Include template ID
        formData.append("tb_master_type", "category");
        formData.append("item_id", props.selectedCategory.id);
        formData.append("product_id", templateId);
        formData.append("pr_flag", '');
        formData.append("pr_count", '');
        formData.append("sessionID", props.sessionId); // Include template ID
        // formData.append("product_category_name", props.selectedCategory.name);
        // formData.append("vendor_flag", '1');

        // formData.append("rfq_mapping_type", '10');//need to discuss
        loadStyle([
            parentHostURI + "/plugins/font-awesome-4.7.0/css/font-awesome.min.css",
            parentHostURI + "/css/ajax-dynamic-list.css",
            parentHostURI + "/pr/bootstrap/css/bootstrap-toggle.min.css",
            parentHostURI + "/css/bluestar_blue.css",
            parentHostURI + "/css/masters/admin-jquery-ui.min.css",
            parentHostURI + "/css/masters/jquery-ui-1.8.20.custom.css",
            parentHostURI + "/css/masters/new_masters.css",
            parentHostURI + "/css/masters/technical_bid_template.css",
            parentHostURI + "/css/masters/colorbox_custom/theme3/colorbox.css",
            parentHostURI + "/css/ui-kits/number-align.css",
            parentHostURI + "/css/ui-kits/react-custom.css",
        ]);
        loadScripts([
            parentHostURI + "/js/masters/jquery_grid/jquery-1.7.2.min.js",
            parentHostURI + "/js/masters/masters_auto_minimized.js",
        ]);
        setAnchorElTB(true);
        setLoader(true);
        try {
            const response = await axios.post(prviewTB, formData);
            if (response) {
                setTbpreview(response.data);
                setLoader(false);
            } else {
                removeJSFile(parentHostURI + "/css/bluestar_blue.css", "css");
                // Handle empty data case
            }
        } catch (error) {
            removeJSFile(parentHostURI + "/css/bluestar_blue.css", "css");
            console.error("Error fetching data:", error);
        }
        // console.log('clicled');
    };

    const handleTbClose = () => {
        // window.location.reload()
        dispatch(setRfqListInfo(props.sessionId));
        setAnchorElTB(false);
        setTbpreview({ __html: "" });
    };

    // Filter templates based on search input
    const filteredTemplates = templates.filter((template) =>
        template.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const getLinkUnlinkTemplate = async () => {
        try {
            const response = await axios.get(
                getTechnicalBidTemplateItemize(props?.itemId)
            );
            setTemplates(response.data.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getLinkUnlinkTemplate();
    }, []);

    // Find the currently linked template (if any)
    const currentlyLinkedTemplate = templates.find(
        (template) => template.is_linked
    );

    return (
        <LinkUnlinkItemDrawer
            anchor="right"
            open={props.openLinkUnlinkItemDrawer}
            onClose={props.CloseLinkUnlinkItemDrawer}
            className="SidebarDrawer"
        >
            <Card>
                <CardHeader
                    action={
                        <Tooltip title="Close">
                            <IconButton
                                onClick={props.CloseLinkUnlinkItemDrawer}
                                aria-label="close"
                                size="small"
                                sx={{ color: "var(--grey-500)" }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    title={
                        <Typography
                            variant="subtitle1"
                            fontWeight={500}
                            component="h6"
                            color={"secondary.main"}
                        >
                            Link/Unlink Technical Bid Template to Item
                        </Typography>
                    }
                />
                <Divider />
                <CardContent>
                    <Box className="TemplateSearchBox">
                        <TextField
                            fullWidth
                            size="small"
                            variant="filled"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="action" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    {/* Display currently linked template, if any */}
                    {currentlyLinkedTemplate && (
                        <Box
                            mb={2}
                            sx={{
                                backgroundColor: "#e3f2fd",
                                padding: "8px",
                                borderRadius: "4px",
                            }}
                        >
                            <Typography variant="body2" color="primary">
                                Currently linked template: {currentlyLinkedTemplate.name}
                            </Typography>
                        </Box>
                    )}
                    <List className="TemplateName-List">
                        {/* Render Filtered Templates */}
                        {filteredTemplates.length > 0 ? (
                            filteredTemplates.map((template, index) => (
                                <React.Fragment key={index}>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Tooltip title="Preview">
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleTBPreview(template.id)} // Pass template ID
                                                >
                                                    <FindInPageIcon color="action" />
                                                </IconButton>
                                            </Tooltip>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Tooltip title={template.name} placement="bottom-start">
                                                    <Typography
                                                        variant="body2"
                                                        color={"text.primary"}
                                                        noWrap
                                                    >
                                                        {template.name}
                                                    </Typography>
                                                </Tooltip>
                                            }
                                        />
                                        <IconButton onClick={() => handleToggleLink(index)}>
                                            {template.is_linked ? (
                                                <Tooltip title="Unlink">
                                                    <LinkIcon color="primary" />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Link">
                                                    <LinkOffIcon color="action" />
                                                </Tooltip>
                                            )}
                                        </IconButton>
                                    </ListItem>
                                    <Divider component="li" />
                                </React.Fragment>
                            ))
                        ) : (
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            textAlign="center"
                                            p={2}
                                        >
                                            No Templates Found.
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        )}
                    </List>
                </CardContent>
            </Card>
            {/* Preview Technical Bid Template */}
            <Dialog
                open={anchorElTB}
                onClose={handleTbClose}
                fullScreen
                sx={{ m: 4 }}
            >
                <DialogTitle sx={{ m: 0, padding: "16px !important" }}>
                    Technical Bid Preview
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleTbClose}
                    sx={{ position: "absolute", right: 8, top: 13 }}
                    size="small"
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {loader === true ? (
                        <CircularProgress
                            sx={{
                                position: "absolute",
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        />
                    ) : (
                        <InnerHTML html={Tbpreview} />
                    )}
                </DialogContent>
            </Dialog>

            {/* Snackbar Notification */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                    variant="filled"
                    sx={{ color: "white" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </LinkUnlinkItemDrawer>
    );
}
