import {
    Grid,
    Checkbox,
    Button,
    LinearProgress,
    List,
    ListItem,
    Box,
    Badge,
    Typography,
    ListItemIcon,
    ListItemText,
    ListItemButton,
    IconButton,
    CircularProgress,
  } from "@mui/material";
  import React, { Component } from "react";
  import { connect } from "react-redux";
  import noSearchimg from "../../../assets/images/no-search.svg";
  import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
  import { getVendorGMPFlag } from "../../Vendors/VendorChip";
  
  class VendorList extends Component {
    getVendorLocation = (country) => {
      if (this.props.accountlevelInfo?.country === country) {
        return "L";
      } else return "I";
    };
  
    render() {
      const {
        data,
        isItemSelected,
        handleItemClick,
        lastPage,
        currentPage,
        loading,
        loadMore,
        type,
        searchValue,
        showSelected
      } = this.props;
  
      if (showSelected) {
        return (
          <>
          <Box className="LinkUnlinkCard">
            <Box className="link-items-list selected-list">
            <List sx={{ width: "100%" }}  >
              {data?.length > 0 && 
                data.map((vendor, id) => {
                return (
                  <>
                    <ListItem
                      key={id}
                      secondaryAction={
                        <Box
                          sx={{
                            textAlign: "end",
                          }}
                        >
                          {getVendorGMPFlag(this.props.companyInfo.gst_verification, vendor.gstin_no, vendor.gstin_status, 'G', "GSTIN")}
                          {vendor.industry_type === "MSME" ? 
                            getVendorGMPFlag(this.props.companyInfo.msme_verification, vendor.industry_type_value, vendor.msme_status, 'M', "MSME") : 
                            getVendorGMPFlag(this.props.companyInfo.msme_verification, "", vendor.msme_status, 'M', "MSME")
                          }
                          {getVendorGMPFlag(this.props.companyInfo.pan_verification, vendor.pan_no, vendor.pan_status, 'P', "PAN")}
                          
                          {/* <Badge
                            badgeContent={this.getVendorLocation(vendor.country)}
                            variant="standard"
                          ></Badge> */}
                          <Typography
                            sx={{
                              fontSize: 14,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            {vendor.country} {vendor.state}
                          </Typography>
                        </Box>
                      }
                      disablePadding
                    >
                    
                        <ListItemIcon>
                          <IconButton className="selected-remove-btn" onClick={(event) => handleItemClick(event, vendor, type)}>
                            <RemoveCircleIcon />
                          </IconButton>
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            width: "90%",
                          }}
                          primary={vendor.vendor_name}
                          secondary={vendor.vendor_code}
                        />
                      
                    </ListItem>
                  </>
                );
              })}
            </List>
            </Box>
          </Box>          
          </>
        );
      }
      return (
        <>
          {loading && <LinearProgress />}
  
          {data?.length > 0 ? (
            <List sx={{ width: "100%" }}>
              {data.map((vendor, id) => {
                const ItemSelected = isItemSelected(vendor, type);
                return (
                  <>
                    <ListItem
                      sx={{ display: "block" }}
                      key={id}
                      secondaryAction={
                        <Box
                          sx={{
                            textAlign: "end",
                          }}
                        >
                          {getVendorGMPFlag(this.props.companyInfo.gst_verification, vendor.gstin_no, vendor.gstin_status, 'G', "GSTIN")}
                          {vendor.industry_type === "MSME" ? 
                            getVendorGMPFlag(this.props.companyInfo.msme_verification, vendor.industry_type_value, vendor.msme_status, 'M', "MSME") : 
                            getVendorGMPFlag(this.props.companyInfo.msme_verification, "", vendor.msme_status, 'M', "MSME")
                          }
                          {getVendorGMPFlag(this.props.companyInfo.pan_verification, vendor.pan_no, vendor.pan_status, 'P', "PAN")}
                          {/* <Badge
                            badgeContent={this.getVendorLocation(vendor.country)}
                            variant="standard"
                          ></Badge> */}
                          <Typography
                            sx={{
                              fontSize: 14,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            {vendor.country} {vendor.state}
                          </Typography>
                        </Box>
                      }
                      disablePadding
                    >
                      <ListItemButton
                        role={undefined}
                        dense
                        onClick={(event) => handleItemClick(event, vendor, type)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={ItemSelected}
                            // onChange={(event) => handleItemClick(event, vendor, type)}
                            // tabIndex={-1}
                            // disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            width: "90%",
                          }}
                          primary={vendor.vendor_name}
                          secondary={vendor.vendor_code}
                        />
                      </ListItemButton>
                    </ListItem>
                  </>
                );
              })}
            </List>
          ) : (
            !loading && (
              <>
                <Box className="no-search">
                  <img src={noSearchimg} alt="Search" />
                  <Typography component="p">
                    {searchValue ? (
                      <>
                        {" "}
                        There are no vendors like <b>{searchValue}</b>
                      </>
                    ) : (
                      "There are no vendors"
                    )}
                  </Typography>
                </Box>
              </>
            )
          )}
          {lastPage !== currentPage && (
            <Grid
              container
              alignItems="center"
              spacing={2}
              className="categorydialog_card_content card_content_border"
            >
              <Grid item xs={12}>
              <Box sx={{textAlign: 'center'}}>
                  <Button 
                  variant="outlined"
                  color="primary" 
                  size="small"
                  sx={{
                    m: '16px auto',                 
                  }}
                  onClick={loadMore}
                  endIcon={loading && <CircularProgress size={15} />}
                  >Load More</Button>              
                </Box>             
              </Grid>
            </Grid>
          )}
        </>
      );
    }
  }
  const mapStateToProps = (state) => ({
    accountlevelInfo: state.permissionsReducer.accountlevelInfo,
    companyInfo: state.companyInfoReducer.companyInfo,
  });
  export default connect(mapStateToProps)(VendorList);
  