import React, { useEffect, useState } from "react";
import {
  Box,
  FormControlLabel,
  Switch,
  FormGroup,
  Divider,
  Autocomplete,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slider,
  Stack,
  TextField,
  Typography,
  CircularProgress
} from "@mui/material";

import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import { makeStyles } from "@mui/styles";
import NotificationsIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles({
  RFQRulesBox: {
    display: "flex",
    gap: "16px",
    alignItems: "flex-start",
    "@media (max-width:900px)": {
      flexDirection: "column",
    },
    "& .RFQSetting-box": {
      padding: "24px 18px",
      width: "100%",
      borderRadius: "5px",
      border: "1px solid var(--bluegrey-500)",
      backgroundColor: "var(--grey-0)",
    },
    "& .RFQSetting-Title": {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      marginBottom: "16px",
    },
    "& .MuiFormControlLabel-label": {
      fontWeight: "500"
    },
  },
});
export default function SetRFQRules(props) {
  const userInfo = useSelector((state) => state.userInfoReducer.userInfo);
  const Permisison = useSelector((state) => state.permissionsReducer)


  
  const [rfqType, setRfqType] = useState(props?.taskData?.action_document_data?.inquiry_settings?.type);
  const [isDisabled, setIsDisabled] = useState(false);

  const [enableHourlyReminder, setEnableHourlyReminder] = useState(props?.taskData?.action_document_data?.inquiry_settings?.reminders?.hourly?.reminder);
  const [selectedProjectValue, setSelectedProjectValue] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    
      const accountPerm = Permisison?.accountModulePermission || {};
      const featurePerm = Permisison?.featurePermission || {};
      const isEdit = props?.editTask;

      const notAllowedCategory = accountPerm["Not Allowed for Category Specific RFQ"]?.module_activated === "Y";
      const itemizeVendorSpecific = accountPerm["Itemize Vendor Specific RFQ"]?.module_activated === "Y";
      const isCategorySpecificAllowed = featurePerm.category_specific_rfq?.feature_value === "Y";

      if (notAllowedCategory) {
        setRfqType("item");
        setIsDisabled(true);
        return;
      }

      if (itemizeVendorSpecific && !isEdit) {
        setRfqType(isCategorySpecificAllowed ? "category" : "item");
        setIsDisabled(false);
        return;
      }

      if (!isEdit) {
        setRfqType("category");
        setIsDisabled(true);
      }

  }, [Permisison,props?.editTask]);

  useEffect(() => {
    props.handleTaskDataChange(
      "action_document_data.inquiry_settings.type", rfqType
    );
  }, [rfqType]);

  useEffect(() => {
    if (!enableHourlyReminder) {

      props.handleTaskDataChange(
        "action_document_data",
        {
          ...props.taskData.action_document_data,
          inquiry_settings: {
            ...props.taskData.action_document_data?.inquiry_settings,
            reminders: {
              ...props.taskData.action_document_data.inquiry_settings?.reminders,
              hourly: {
                ...props.taskData.action_document_data.inquiry_settings?.reminders?.hourly,
                sms: false,
                reminder_hours: "",
                reminder: false,
              },
            },
          },
        }
      );

    } else {

      props.handleTaskDataChange(
        "action_document_data",
        {
          ...props.taskData.action_document_data,
          inquiry_settings: {
            ...props.taskData.action_document_data.inquiry_settings,
            reminders: {
              ...props.taskData.action_document_data.inquiry_settings.reminders,
              hourly: {
                reminder: enableHourlyReminder,
                reminder_hours: props?.taskData?.action_document_data?.inquiry_settings?.reminders?.hourly?.reminder_hours,
                sms: props?.taskData?.action_document_data?.inquiry_settings?.reminders?.hourly?.sms
              }
            },
          },
        }
      )
    }

  }, [enableHourlyReminder]);

  return (
    <Box className={classes.RFQRulesBox}>
      <Box className="RFQSetting-box">
        <Box className="RFQSetting-Title">
          <SettingsIcon fontSize="small" />
          <Typography variant="subtitle1" color={"grey.900"} fontWeight={500}>
            RFQ Setting
          </Typography>
        </Box>

        <Box
          width={"100%"}
          sx={{
            ".MuiFormControlLabel-root": {
              justifyContent: "space-between",
              ml: 0, mr: 0,
              width: "100%"
            },
          }}
        > 
            {Permisison?.accountModulePermission?.["Project"]?.module_activated ===
              "Y" && (
              <Box component="form" sx={{ "& > :not(style)": { mb: 3 } }}>
                <Autocomplete
                  options={props.projectList}
                  value={selectedProjectValue}
                  defaultValue={selectedProjectValue}
                  onChange={(event, value) => {
                    const selectedValues = value.map(option => option.id);
                    props.handleTaskDataChange(
                      "action_document_data.inquiry_settings.project_ids", 
                      selectedValues
                    );
                    setSelectedProjectValue(value);
                  }}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  multiple
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Projects"
                      name="projectList"
                    />
                  )}
                />
              </Box>
          )}
          <Typography variant="subtitle1" color={"grey.500"} fontWeight={500} mb={1}>
            Select RFQ Specific
          </Typography>
          <FormGroup sx={{ "& > :not(style)": { marginBottom: "6px" } }}>
            <FormControlLabel
              control={<Switch size="small" color="primary" />}
              label="Category Specific"
              labelPlacement="start"
              checked={props?.taskData?.action_document_data?.inquiry_settings?.type === "category"}
              disabled={isDisabled}
              onChange={() => setRfqType("category")}

            />
            <FormControlLabel
              control={<Switch size="small" color="primary" />}
              label="Item Specific"
              labelPlacement="start"
              checked={props?.taskData?.action_document_data?.inquiry_settings?.type === "item"}
              disabled={isDisabled}
              onChange={() => setRfqType("item")}

            />
          </FormGroup>
          <Divider
            sx={{ my: 2, borderColor: "var(--bluegrey-500)", opacity: "0.5" }}
          />
          {Permisison?.featurePermission?.display_vendors_rank
            ?.feature_value === "Y" && (
              <>
                <Typography variant="subtitle1" color={"grey.500"} fontWeight={500} mb={1}>
                  Display Rank to Vendor on
                </Typography>
                <FormGroup sx={{ "& > :not(style)": { marginBottom: "6px" } }}>
                  <FormControlLabel
                    control={<Switch size="small" color="primary" />}
                    label="Basic Rate"
                    labelPlacement="start"
                    checked={props?.taskData?.action_document_data?.inquiry_settings?.display_rank_to_vendor?.basic_rate}
                    onChange={(event) => {
                      props.handleTaskDataChange(
                        "action_document_data.inquiry_settings.display_rank_to_vendor.basic_rate", event.target.checked
                      );
                    }}
                  />
                  <FormControlLabel
                    control={<Switch size="small" color="primary" />}
                    label="Landed Cost"
                    labelPlacement="start"
                    checked={props?.taskData?.action_document_data?.inquiry_settings?.display_rank_to_vendor?.landed_cost}
                    onChange={(event) =>
                      props.handleTaskDataChange(
                        "action_document_data.inquiry_settings.display_rank_to_vendor.landed_cost", event.target.checked
                      )

                    }
                  />
                </FormGroup>
              </>
            )}
          <Divider
            sx={{ my: 2, borderColor: "var(--bluegrey-500)", opacity: "0.5" }}
          />
          <FormGroup sx={{ "& > :not(style)": { marginBottom: "10px" } }}>
            {Permisison?.accountModulePermission?.SealedRFQ?.module_activated === "Y" && (
              <FormControlLabel
                control={
                  <Switch size="small" color="primary"
                    checked={
                      (Permisison?.featurePermission?.sealed_rfq?.feature_value === "Y") ? true :
                        props?.taskData?.action_document_data?.inquiry_settings?.sealed
                    }
                    disabled={Permisison?.featurePermission?.sealed_rfq?.feature_value === "Y"} // Disable when "Y"
                    onChange={(event) =>
                      props.handleTaskDataChange(
                        "action_document_data.inquiry_settings.sealed",
                        Permisison?.featurePermission?.sealed_rfq?.feature_value === "Y"
                          ? true
                          : event.target.checked
                      )
                    }
                  />
                }
                label="Sealed RFQ"
                labelPlacement="start"
              />
            )}
            <FormControlLabel
              control={<Switch size="small" color="primary" />}
              label="Priority RFQ"
              labelPlacement="start"
              checked={props?.taskData?.action_document_data?.inquiry_settings?.priority}
              onChange={(event) =>
                props.handleTaskDataChange(
                  "action_document_data.inquiry_settings.priority",
                  Permisison?.featurePermission?.sealed_rfq?.feature_value === "Y"
                    ? true
                    : event.target.checked
                )

              }
            />
            {Permisison?.accountModulePermission?.["Fetch Payment Term"]
              ?.module_activated === "Y" && (
                <FormControlLabel
                  control={
                    <Switch
                      size="small" color="primary"
                      disabled={
                        Permisison?.masterPermission?.update_payment_terms === "N"
                      }
                      checked={props.vendorMasterPaymentTerm}
                      onChange={(event) => {
                        props.handleTaskDataChange(
                          "action_document_data.inquiry_settings.vendor_master_payment_terms", event.target.checked
                        );

                        props.setVendorMasterPaymentTerm(event.target.checked);
                      }}

                    />
                  }
                  label="Vendor Master Payment Term"
                  labelPlacement="start"
                />
              )}

            {Permisison?.accountModulePermission?.["Fetch Inco Term"]
              ?.module_activated === "Y" && (
                <FormControlLabel
                  control={
                    <Switch
                      size="small" color="primary"
                      disabled={
                        Permisison?.masterPermission?.update_inco_terms ===
                        "N"
                      }
                      checked={props.vendorMasterIncoTerm}
                      name="vendorMasterIncoTerm"
                      onChange={(event) => {
                        props.handleTaskDataChange(
                          "action_document_data.inquiry_settings.vendor_master_inco_terms",event.target.checked
                        );
                        props.setVendorMasterIncoTerm(event.target.checked);
                      }
                      }
                    />
                  }
                  label="Vendor Master Inco Term"
                  labelPlacement="start"
                />
              )}
          </FormGroup>
        </Box>
      </Box>

      <Box className="RFQSetting-box">
        <Box className="RFQSetting-Title">
          <NotificationsIcon fontSize="small" />
          <Typography variant="subtitle1" color={"grey.900"} fontWeight={500}>
            Quotation Reminder
          </Typography>
        </Box>
        <Box
          width={"100%"}
          sx={{
            ".MuiFormControlLabel-root": {
              justifyContent: "space-between",
              ml: 0, mr: 0,
              width: "100%"
            },
            "& > :not(style)": { mb: 1.5 }
          }}
        >
          <TextField sx={{ "& > :not(style)": { mb: 1 }}}
            fullWidth
            label="Start Reminder After (Days)"
            variant="outlined"
            // defaultValue={}
            size="small"
            onKeyPress={(event) => {
              const keyValue = event.key;
              if (keyValue === "-" || keyValue === "+" || keyValue === "e" || keyValue ===".") {
                event.preventDefault();
              }
            }}
            value={props?.taskData?.action_document_data?.inquiry_settings?.reminders?.after_inquiry_created_days}
            onChange={(event) => {
              props.handleTaskDataChange(
                "action_document_data.inquiry_settings.reminders.after_inquiry_created_days", event.target.value
              );
              if(!event.target.value){
                props.handleTaskDataChange(
                  "action_document_data.inquiry_settings.reminders.interval_days", ""
                );
                props?.setQuotationReminderDays("");
              }

              if(event.target.value && 
                  Permisison?.featurePermission?.default_Quotation_reminder_interval_days?.feature_value === "Y"
                    && Permisison?.featurePermission?.default_Quotation_reminder_interval_days?.feature_permission_value){
                    
                      props.handleTaskDataChange(
                      "action_document_data.inquiry_settings.reminders.interval_days", Permisison?.featurePermission?.default_Quotation_reminder_interval_days?.feature_permission_value
                    );
                    props?.setQuotationReminderDays(Permisison?.featurePermission?.default_Quotation_reminder_interval_days?.feature_permission_value);
              }

            }}
            type="number"
            inputProps={{ min: 0 }}
          />

          <TextField
            fullWidth
            label="Intervals(in days) at which new reminder has to be set"
            variant="outlined"
            // defaultValue={}
            size="small"
            onKeyPress={(event) => {
              const keyValue = event.key;
              if (keyValue === "-" || keyValue === "+" || keyValue === "e" || keyValue ===".") {
                event.preventDefault();
              }
            }}
            value={props?.QuotationReminderDays}
            // value={props?.taskData?.action_document_data?.inquiry_settings?.reminders?.interval_days}
            onChange={(event) => {
              props.handleTaskDataChange(
                "action_document_data.inquiry_settings.reminders.interval_days", event.target.value
              );
              props?.setQuotationReminderDays(event.target.value);
            }}
            type="number"
            inputProps={{ min: 0 }}
            disabled={!props?.taskData?.action_document_data?.inquiry_settings?.reminders?.after_inquiry_created_days}
          />

          <FormControlLabel
            control={
              <Switch
                size="small"
                color="primary"
                sendSmsReminder
                onChange={(event) => {
                  props.handleTaskDataChange(
                    "action_document_data.inquiry_settings.reminders.send_sms", event.target.checked
                  );

                }}
                checked={props?.taskData?.action_document_data?.inquiry_settings?.reminders?.send_sms}
              />
            }
            label="Send SMS Reminders"
            labelPlacement="start"
          />

          {Permisison?.featurePermission["hourly_quotation_reminders"][
            "feature_value"
          ] === "Y" && (
              <>

                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      color="primary"
                      onChange={(event) => {
                        setEnableHourlyReminder(event.target.checked);
                      }}
                      checked={enableHourlyReminder}
                    />
                  }
                  label="Enable Hourly Reminders"
                  labelPlacement="start"
                />
                <Box component="form">
                  <FormControl fullWidth size="small">
                    <InputLabel>Hourly Reminders</InputLabel>
                    <Select
                      fullWidth
                      disabled={!enableHourlyReminder}
                      label="Hourly Reminder"
                      name={"hourlyReminderValue"}
                      value={props?.taskData?.action_document_data?.inquiry_settings?.reminders?.hourly?.reminder_hours}
                      onChange={(event) => {
                        props.handleTaskDataChange(
                          "action_document_data.inquiry_settings.reminders.hourly.reminder_hours", event.target.value
                        );
                      }}>
                      <MenuItem value="">
                        <em>select</em>
                      </MenuItem>
                      {[...Array(11).keys()].map((cv) => (
                        <MenuItem key={cv} value={cv + 1}>
                          {cv + 1} Hour
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <FormControlLabel
                  control={
                    <Switch
                      size="small" color="primary"
                      onChange={(event) => {
                        props.handleTaskDataChange(
                          "action_document_data.inquiry_settings.reminders.hourly.sms", event.target.checked
                        );
                      }}
                      checked={props?.taskData?.action_document_data?.inquiry_settings?.reminders?.hourly?.sms}
                    />
                  }
                  label="Send SMS Hourly Reminders"
                  labelPlacement="start"
                  disabled={!enableHourlyReminder}
                />
              </>
            )}
        </Box>
      </Box>
    </Box>
  );
}
