import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

export const DialogMultiSolution = styled(Dialog)(({ theme }) => ({
  ".MultiSolution-Box": {
    ".ItemUser-Box": {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      width: "100%",
      padding: "4px 12px",
      borderBottom: "2px solid var(--bluegrey-500)",
      background: "var(--grey-100)",
      position: "sticky",
      top: "65px",
      zIndex: "10",
    },
    ".ItemList-TabsHeader": {
      display: "flex",
      position: "sticky",
      top: "0",
      zIndex: "11",
      background: "var(--bg-white)",
      borderBottom: "1px solid var(--bluegrey-500)",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    ".MS-Tital": {
      width: "275px",
      padding: "10px",
      alignItems: "center",
      display: "flex",
      position: "sticky",
      [theme.breakpoints.down("md")]: {
        width: "175px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        borderBottom: "1px solid var(--bluegrey-500)",
      },
    },
    ".ItemTabs-Box": {
      maxWidth: "calc(100% - 275px)",
      [theme.breakpoints.down("md")]: {
        maxWidth: "calc(100% - 175px)",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    ".ItemList-Table": {
      // borderBottom: "1px solid var(--bluegrey-500)",
      ".MuiTableRow-head .MuiTableCell-root": {
        background:"var(--grey-50)",
        whiteSpace: "nowrap",
        fontSize:"12px"
      },
      ".MuiTableCell-root":{
        maxWidth: "350px",
        minWidth:"175px"
      }
    },
  },
}));
