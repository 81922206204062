import React, { useState, useEffect } from "react";
import { Box, Button, Stack } from "@mui/material";
import AutoRFQDialog from "../../components/AutoRFQDialog/AutoRFQDialog";
// import AutoRFQLogsDialog from "../../components/AutoRFQLogsDialog/AutoRFQLogsDialog";
import AutoRFQLogsDialog from "../../components/AutoRFQLogsDialog/AutoRFQLogs";
import { setAccountUsers } from "../../redux/actions/setAccountUsers";
import { useDispatch, useSelector } from "react-redux";
import {
  account_all_users,
  pr_dropdown_mapping,
  dropdown_detail,
  getUsersByPermissionsURL
} from "../../config/apiUrl";
import { setDropDownData } from "../../redux/actions/dropdownAction";
import { setUserInfo } from "../../redux/actions/setUserInfo";
import axios from "axios";

function AutoRFQ(props) {
  //Start Auto RFQ Modal Dialog
  const [autoRFQ, setAutoRFQ] = useState(true);
  const userInfo = useSelector((state) => state.userInfoReducer.userInfo);
  const [activeStep, setActiveStep] = useState(0);
  const [isCreateRFQActive, setIsCreateRFQActive] = useState(false);
  const [editTask, setEditTask] = useState(false);
  const [ autoRfqPermissionUsers , setAutoRfqPermissionUsers ] = useState([]);
  const Permisison = useSelector((state) => state.permissionsReducer)
  
  const AutoRFQOpen = (event) => {
    event.stopPropagation();
    setAutoRFQ(true);
  };

  useEffect(()=>{console.log('autoRfqPermissionUser',autoRfqPermissionUsers)},[autoRfqPermissionUsers])
  const [taskData, setTaskData] = useState({})
  
  const initializeTaskData = () => {
    setTaskData({
      name: "",
      client: userInfo?.company_name, 
      client_account_id: userInfo?.division_id, 
      rule_document: "pr",
      rules: [],
      action_document: "inquiry",
      action: "POST",
      action_document_data: {
        inquiry_settings: {
          type: "",
          display_rank_to_vendor: {
            basic_rate: false,
            landed_cost: false,
          },
          priority: false,
          sealed: false,
          vendor_master_payment_terms: Permisison?.featurePermission?.change_payment_terms?.feature_value === "Y",
          vendor_master_inco_terms:  Permisison?.featurePermission?.change_payment_terms?.feature_value === "Y",
          validity_days: Permisison?.featurePermission?.default_RFQ_validity_days?.feature_value === "Y"
                            ? Permisison?.featurePermission?.default_RFQ_validity_days?.feature_permission_value : "",
          validity_time: "",
          termsAndCondition: [],
          reminders: {
            quotation_reminder: false,
            after_inquiry_created_days: null,
            interval_days: null,
            send_sms: false,
            update_all_inquiries: false,
            description: null,
            hourly: {
              reminder: false,
              reminder_hours: 1,
              sms: false,
            },
          },
        },
        template: {
          id: 0,
          batch_id: 0,
          data: {
            ITEMIZE : {},
            FOREIGN : {},
            LOCAL   : {},
            COMMON  : {},
          }
        },
      },
      status: "Active",
      description: "Description for task",
      action_by: userInfo?.user_id, 
      execute_as: userInfo?.user_id,
      updated_by: "",
    });
  }

  const AutoRFQClose = (createRFQ) => {
    setEditTask(false);
    if (createRFQ) {
      // for close Create and edit section when click on close btn
      setActiveStep(0);
      setIsCreateRFQActive(false);
      initializeTaskData();
    } else {
      setAutoRFQ(false); // for iframe close
      window.parent.postMessage(
        { type: "AUTO_RFQ_CLOSE_ACTION", message: "Closing from React" },
        "*"
      );
    }
  };

  //Start Auto RFQ Modal Dialog
  const [autoRFQLogs, setAutoRFQLogs] = useState(false);
  const dispatch = useDispatch();

  const AutoRFQLogsOpen = (event) => {
    event.stopPropagation();
    setAutoRFQLogs(true);
  };
  const AutoRFQLogsClose = () => {
    setAutoRFQLogs(false);
  };

  const queryParams = new URLSearchParams(props?.location?.search);
  let sessionId = queryParams.get("q");

  useEffect(() => {
    if (sessionId) {
      fetchAllUsersDetails();
      dispatch(setUserInfo(sessionId));
    }
  }, []);

  useEffect(() => {
    if (userInfo.length !== 0) {
      fetchDropDownMappingData();
      initializeTaskData()
      getUsersByPermissions()
          }
  }, [userInfo]);
  
  useEffect(() => {
    if(Permisison.featurePermission.length !== 0 ){
      initializeTaskData()
    }
  }, [Permisison]);

  const fetchAllUsersDetails = async () => {
    try {
      const userResponse = await axios.get(account_all_users, {
        headers: { Authorization: `Bearer ${sessionId}` },
      });
      if (userResponse.status === 200) {
        const accountUserData = userResponse.data.users;
        const userIdMap = {};
        accountUserData.forEach((user) => {
          userIdMap[user.UserID] = user.UserName;
        });

        dispatch(setAccountUsers(userIdMap));
      } else {
        throw new Error("Network response for user data was not ok");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDropDownMappingData = async () => {
    const mapping_url = pr_dropdown_mapping(
      userInfo?.division_id,
      userInfo?.company_id
    );

    try {
      let mappingResponse = await fetch(mapping_url);

      const mappingData = await mappingResponse.json();

      const { plant, pgr, porg } = mappingData;

      Object.keys(mappingData).forEach(function (key) {
        if (key === "plant" || key === "pgr" || key === "porg") {
          fetchDropDownDetails(mappingData[key], key);
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDropDownDetails = async (id, type, action) => {
    let dropdown_detail_url = dropdown_detail(
      userInfo?.division_id,
      userInfo?.company_id,
      id
    );

    try {
      const response = await fetch(dropdown_detail_url);

      if (!response.ok) {
        throw new Error(`Failed to fetch ${type} data`);
      }

      const data = await response.json();

      dispatch(setDropDownData({ [type]: data }));
    } catch (error) {
      console.error(`Error fetching ${type} data:`, error);
    }
  };


  const getUsersByPermissions = async () => {
    try {

        const queryParams = new URLSearchParams({ permissions: 'auto_rfq' }).toString();

        let response = await axios.get(`${getUsersByPermissionsURL}?${queryParams}`);
        
        let autoRfqUsers = userInfo.is_super_user === "Y"
            ? [...new Set([...response.data.auto_rfq, userInfo.user_id])] // add id and Avoid duplicates
            : response.data.auto_rfq;

        setAutoRfqPermissionUsers(autoRfqUsers); 
        return;
    } catch (error) {
        console.error("Error fetching users:", error.response?.data || error.message);
    }
  };




  //End Auto RFQ Modal Dialog
  return (
    <>
      {/* <Stack p={2} spacing={2} direction="row">
        <Button variant="contained" onClick={AutoRFQOpen}>
          Auto RFQ
        </Button>

        <Button variant="contained" onClick={AutoRFQLogsOpen}>
          Auto RFQ Logs
        </Button>
      </Stack> */}
      <AutoRFQDialog
        AutoRFQOpen={autoRFQ}
        AutoRFQClose={AutoRFQClose}
        sessionId={sessionId}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        isCreateRFQActive={isCreateRFQActive}
        setIsCreateRFQActive={setIsCreateRFQActive}
        taskData={taskData}
        setTaskData={setTaskData}
        initializeTaskData={ () => initializeTaskData()}
        editTask={editTask}
        setEditTask={setEditTask}
        autoRfqPermissionUsers={autoRfqPermissionUsers}
      />
    </>
  );
}

export default AutoRFQ;
