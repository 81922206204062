import {
  Box,
  Button,
  Divider,
  Link,
  Switch,
  Tooltip,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  DataGridPro,
} from "@mui/x-data-grid-pro";
import EditNoteIcon from "@mui/icons-material/EditNote";
import RFQNoDataImg from "../../assets/images/RFQ-NoData.svg";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { openSnackbarfunc } from "../../redux/actions/snackbarAction";
import { openSnackbarfunc } from "../../redux/actions/snackbarAction";
import CommonDataGridPro from "../RFQList/commonDataGridPro/commonDataGridPro";
import CreateAutoRFQ from "./CreateAutoRFQ";
import {
  getAllAutoRFQTask,
  autoRFQTaskById,
  getCategories,
} from "../../config/apiUrl";
import moment from "moment";

function CustomGridToolbar() {
  return (
    <>
      {/* Start Desktop View Code */}
      <GridToolbarContainer className="datagrid-toolbar">
        <Grid
          container
          spacing={0}
          sx={{
            width: "100%",
            // pt: 1,
            "&.MuiGrid2-root": {
              paddingTop: { xs: "0", sm: "0", md: "0", alignItems: "center" },
            },
          }}
        >
          <Grid xs={12} sm={4} md={4} xl={4}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <GridToolbarQuickFilter
                className="DataGrid-SearchBar"
                sx={{ pb: 0 }}
              />
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={8}
            md={8}
            xl={8}
            sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}
          >
            <Box
              className="toolbar-right"
              sx={{
                "&.toolbar-right": {
                  marginLeft: "auto",
                },
              }}
            >
              <Box className="dg-filter">
                <GridToolbarColumnsButton />
                <GridToolbarExport
                  printOptions={{ disableToolbarButton: true }}
                />
                <GridToolbarFilterButton />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </GridToolbarContainer>
      {/* Start Desktop View Code */}

      {/* Start Mobile View Code */}
      <GridToolbarContainer
        className="datagrid-toolbar"
        sx={{ display: { xs: "flex", sm: "none" } }}
      >
        <Grid
          container
          spacing={0}
          sx={{ width: "100%", alignItems: "center" }}
        >
          <Grid
            xs={12}
            sm={12}
            md={12}
            xl={12}
            // sx={{ textAlign: { xs: "right", sm: "right" } }}
          >
            <Box className="dg-filter">
              <GridToolbarColumnsButton />
              <GridToolbarExport />
              <GridToolbarFilterButton />
            </Box>
          </Grid>
        </Grid>
      </GridToolbarContainer>
      {/* End Mobile View Code */}
    </>
  );
}

function ViewListGrid(props) {
  const [taskRows, setTaskRows] = useState([]);
  const [Category, setCategory] = useState({});
  // const [loading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const accountUsers = useSelector(
    (state) => state.accountUsersReducer.accountUsers
  );
  const dropdownData = useSelector(
    (state) => state.dropdownReducer.dropdownData
  );
  const userInfo = useSelector((state) => state.userInfoReducer.userInfo);
  
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  useEffect(() => {
    if (
      Object.keys(userInfo).length > 0 &&
      Object.keys(dropdownData?.pgr).length > 0
    ) {
      fetchTasks();
      fetchCategory();
    }
  }, [props.sessionId, dropdownData]);

  const fetchTasks = async () => {
    try {
      props.setIsLoading(true);

      axios.defaults.headers.common["x-public-key"] = props.sessionId;
      axios.defaults.headers.common["x-private-key"] = "task_service_key";
  
      const url = getAllAutoRFQTask(`${userInfo?.company_name}?client_account_id=${userInfo?.division_id}`);
      const response = await axios.get(url);

      setTaskRows(response.data);
    } catch (err) {
      console.error(err);
      let errorMessage = "";
      if (err.response?.status === 404) {
        errorMessage = "Tasks not found.";
      } else if (err.response?.status === 500) {
        errorMessage = "Server error. Please try again later.";
      } else {
        errorMessage = "Something went wrong. Please try again.";
      }

      dispatch(openSnackbarfunc("error", errorMessage));
    } finally {
      props.setIsLoading(false);
    }
  };

  const SwitchCell = ({ row }) => {
    // const [checked, setChecked] = useState(row.status === "Active");
    const [switchLoading, setSwitchLoading] = useState(false);

    return (
      <Switch
        size="small"
        checked={row.status === "Active"}
        disabled={switchLoading}
        onChange={(event) =>
          handleSwitchChange({
            event,
            switchLoading,
            setSwitchLoading,
            row,
          })
        }
      />
    );
  };

  const handleSwitchChange = async ({
    event,
    switchLoading,
    setSwitchLoading,
    row,
  }) => {
    if (switchLoading) return;

    try {
      props.setIsLoading(true);
      setSwitchLoading(true);

      const newStatus = event.target.checked ? "Active": "Inactive";

      const url = autoRFQTaskById(row.id);
      const { data } = await axios.patch(url, { status: newStatus });

      fetchTasks();
      dispatch(openSnackbarfunc("success", `Status ${newStatus}`));
      // setChecked(newStatus === "Active");
    } catch (error) {
      if (error.response?.status === 409) {
        dispatch(openSnackbarfunc("error", error.response.data.message));
      } else if (error.response?.status === 422) {
        dispatch(openSnackbarfunc("error", error.response.data.message));
      } else {
        dispatch(openSnackbarfunc("error", `${error.response?.data || error}`));
        console.error("Error updating status:", error.response?.data || error);
      }
    } finally {
      setSwitchLoading(false);
      props.setIsLoading(false);

    }
  };

  const getFormattedValues = (rules, attributeId) => {
    if (!rules || !Array.isArray(rules))
      return { displayText: "N/A", tooltipText: "N/A" };

    const ruleValues = rules
      .filter((rule) => rule.attribute_id === attributeId)
      .flatMap((rule) => rule.value);

    if (ruleValues.length === 0)
      return { displayText: "N/A", tooltipText: "N/A" };

    let displayNameArray = [];

    const attributeMap = {
      purchase_groups: "pgr",
      purchase_organizations: "porg",
    };

    const key = attributeMap[attributeId];

    if (key) {
      displayNameArray = ruleValues.map(
        (element) => dropdownData?.[key]?.[element]?.Dropdown_Element_Name
      );
    } else if (attributeId === "categories") {
      displayNameArray = ruleValues.map((element) => Category[element]);
    }

    const displayValue = displayNameArray[0];
    const extraCount = displayNameArray.length - 1;

    if (extraCount > 0) {
      return {
        displayText: (
          <PopoverList
            displayValue={displayValue}
            extraCount={extraCount}
            displayNameArray={displayNameArray}
          />
        ),
        exportValue : displayNameArray.join(", "), 
        
      };
    }
  
    return {
      displayText: displayValue,
      tooltipText: displayNameArray.join(", "),
      exportValue : displayNameArray.join(", "),
    };

    // return {
    //   displayText:
    //     extraCount > 0 ? `${displayValue} +${extraCount}` : displayValue,
    //   tooltipText: displayNameArray.join(", "),
    // };
  };

  const fetchCategory = async () => {
    try {
      axios.defaults.headers["Authorization"] = `Bearer ${props.sessionId}`;

      const url = `${getCategories}?pagination=false`;
      const response = await axios.get(url);
      const categoryMap = {};
      response?.data?.data.forEach((category) => {
        categoryMap[category.id] = category.name;
      });
      setCategory(categoryMap);
    } catch (err) {
      console.error(err);
      dispatch(
        openSnackbarfunc("error", "An error occurred while fetching tasks")
      );
    }
  };

  const PopoverList = ({ displayValue, extraCount, displayNameArray }) => {
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
  
    return (
      <Box display={"flex"} alignItems={"center"} gap={"8px"} height={40}>
        <Typography
          variant="body2"
          component="a"
          color={"primary.500"}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={handleClick}
        >
          {displayValue} +{extraCount}
        </Typography>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                overflow: "visible",
                border: "1px solid var(--bluegrey-500)",
                mt: 1.5,
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: -1,
                  right: 10,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                  borderTop: "1px solid var(--bluegrey-500)",
                  borderLeft: "1px solid var(--bluegrey-500)",
                },
              },
            },
          }}
        >
          <List dense>
            {displayNameArray.map((name, index) => (
              <ListItem key={index}>
                <ListItemText primary={name} />
              </ListItem>
            ))}
          </List>
        </Popover>
      </Box>
    );
  };

  const columns = [
    {
      field: "switch",
      headerName: "",
      width: 75,
      align: "center",
      filterable: false,
      renderCell: (params) => <SwitchCell row={params.row} />,
    },
    {
      field: "name",
      headerName: "NAME",
      width: 150,
      renderCell: (params) => (
        <Tooltip title={params?.row?.name || "N/A"} placement="bottom-start">
          <Link
            component="button"
            variant="body2"
            onClick={() => props.CreateAlertClick(params?.row.id)}
            noWrap
          >
            {params?.row?.name || "N/A"}
          </Link>
        </Tooltip>
      ),
    },
    {
      field: "created_at",
      headerName: "Creation Time",
      width: 160,
      valueFormatter: (params) => {
        return moment(params.value, "YYYY-MM-DD HH:mm:ss").format(
          "DD/MM/YYYY hh:mm:ss"
        );
      },
    },
    {
      field: "updated_at",
      headerName: "Updated action time",
      width: 160,
      valueFormatter: (params) => {
        return moment(params.value, "YYYY-MM-DD HH:mm:ss").format(
          "DD/MM/YYYY hh:mm:ss"
        );
      },
    },
    {
      field: "execute_as",
      headerName: "USER NAME",
      width: 120,
      valueGetter: (params) => accountUsers[params.row?.execute_as] || "N/A",
      renderCell: (params) => (
        <Tooltip title={params.value || "N/A"} placement="bottom-start">
          <Typography variant="body" noWrap>
            {params.value || "N/A"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "PurchaseGroup",
      headerName: "Purchase Group",
      width: 215,
      valueGetter: (params) => params.row._tooltipPurchaseGroup || "N/A",
      renderCell: (params) => {
        const { displayText, tooltipText, exportValue } = getFormattedValues(
          params.row.rules,
          "purchase_groups"
        );
        params.row._tooltipPurchaseGroup = exportValue;
        return (
        <Tooltip title={tooltipText} arrow>
          <Typography variant="body2" noWrap>
            {displayText}
          </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "PurchaseOrganization",
      headerName: "Purchase Organization",
      width: 210,
      valueGetter: (params) => params.row._tooltipPurchaseOrganization || "N/A",
      renderCell: (params) => {
        const { displayText, tooltipText, exportValue } = getFormattedValues(
          params.row.rules,
          "purchase_organizations"
        );
        params.row._tooltipPurchaseOrganization = exportValue;
        return (
          <Tooltip title={tooltipText} arrow>
            <Typography variant="body2" noWrap>
              {displayText}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "Category",
      headerName: "Category",
      width: 230,
      valueGetter: (params) => params.row._tooltipCategory || "N/A",
      renderCell: (params) => {
        const { displayText, tooltipText, exportValue } = getFormattedValues(
          params.row.rules,
          "categories"
        );
        params.row._tooltipCategory = exportValue;
        return (
          <Tooltip title={tooltipText} arrow>
            <Typography variant="body2" noWrap>
              {displayText}
            </Typography>
          </Tooltip>
        );
      },
    },
    // {
    //   field: "MaterialGroup",
    //   headerName: "Material Group",
    //   width: 270,
    //   renderCell: (params) => (
    //     <Typography variant="body">{params.value || "N/A"}</Typography>
    //   ),
    // },
  ];

  return (
    <>
      <Box
        className="Dialog-AppTitle"
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={2}
        p={1}
        pl={2}
        pr={2}
      >
        <Typography variant="body1" fontWeight={500} color={"grey.900"}>
          View List
        </Typography>
        <Button
          variant="contained"
          startIcon={<EditNoteIcon />}
          size="small"
          onClick={props.CreateAlertClick}
        >
          Create auto rfq
        </Button>
      </Box>
      <Divider />
      <div
        className="AutoRFQGrid"
        style={{
          height: "calc(100vh - 106px)",
        }}
      >
        <CommonDataGridPro
          loading={props.loading}
          rows={taskRows}
          columns={columns}
          className="fullScreenGrid"
          slots={{
            toolbar: CustomGridToolbar,
          }}
          headerFilters
          pagination={true}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          Toolbar={CustomGridToolbar}
          columnHeaderHeight={40}
          headerFilterHeight={40}
          rowHeight={36}
          sx={{
            border: "none",
          }}
        />

        {/* <DataGridPro
          rows={rows}
          columns={columns}
          className="fullScreenGrid"
          slots={{
            toolbar: CustomGridToolbar,
          }}
          headerFilters
          pagination
          columnHeaderHeight={40}
          headerFilterHeight={40}
          rowHeight={36}
          sx={{
            border: "none",
          }} */}
        {/* />   */}
      </div>
    </>
  );
}

export default ViewListGrid;
