import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  InputAdornment,
  List,
  ListItem,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Alert,
  Snackbar,
  styled,
  CircularProgress,
  Backdrop,
  Popover,
  ListItemText,
  ListSubheader,
  ListItemIcon,
} from "@mui/material";
import axios from "axios";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LinkVendorToItemStyle } from "./Style";
import {
  get_Inquiries_Item_data,
  get_Inquiries_Vendor_data,
  linkVendorsToItem,
  sendMailUri,
  itemizeInquiryURL,
  fetchLinkDetails,
} from "../../config/apiUrl";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InfoDetailIcon from "@mui/icons-material/Info";
import VendorChip from "../Vendors/VendorChip";
import { useSelector } from "react-redux";

export function LinkCheckbox(props) {
  return (
    <Checkbox
      defaultChecked
      icon={<LinkOffIcon sx={{ color: "secondary.500" }} />}
      checkedIcon={<LinkIcon color="primary" />}
      indeterminateIcon={<LinkOffIcon sx={{ color: "secondary.500" }} />}
      {...props}
    />
  );
}

export function checkboxComponent(props, vendor_id, type) {
  return (
    <LinkCheckbox
      {...props}
      vendorId={vendor_id}
      rowId={props.id}
      checkboxType={type}
    />
  );
}
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid var(--bluegrey-500)`,
  borderRadius: "4px",
  marginBottom: "16px",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0, 2, 1, 2),
  borderTop: "1px solid var(--bluegrey-500)",
  [theme.breakpoints.down("sm")]: {
    height: "auto",
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  ".MuiAccordionSummary-content": {
    width: "calc(100% - 24px)",
    margin: "8px 0",
  },
}));

export default function LinkVendorItemDialog({
  inquiryID,
  destination_code,
  sessionId,
  openLinkVendorItem,
  handleCloseDialogue,
  plantSpecific,
  accountPlant,
}) {
  const configAxios = {
    headers: { Authorization: `Bearer ${sessionId}` },
  };
  const [expandedAccordion, setExpandedAccordion] = useState("panel1");
  const [itemsList, setItemsList] = useState([]);
  const [itemsHash, setItemsHash] = useState({});
  const [selectedRowId, setSelectedRowId] = useState({});
  const [vendorList, setVendorList] = useState([]);
  const [allVendorList, setAllVendorList] = useState([]);
  const [linkDetailsFetched, setLinkDetailsFetched] = useState(false);
  const [allItemErrArr, setAllItemErrArr] = useState([]);
  const [itemErrArr, setItemErrArr] = useState([]);

  const [severity, setseverity] = useState("");
  const [SnackMessage, setSnackMessage] = useState("");
  const [snackbar, setSnackbar] = useState(false);
  const [isListOpen, setIsListOpen] = useState(false);
  const [isPR, setIsPR] = useState(false);
  const [selectionModel, setSelectionModel] = useState({});
  const [linkItemVendorDetails, setLinkItemVendorDetails] = useState({});
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedOldCategoryId, setSelectedOldCategoryId] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [vendorIDArr, setVendorIDArr] = useState([]);
  const [applyToAll, setApplyToAll] = useState(false);
  const [itemSearchInput, setItemSearchInput] = useState("");
  const [loadingOverlay, setLoadingOverlay] = useState(false);

  const companyPermissions = useSelector(
    (state) => state.companyInfoReducer.companyInfo
  );
  var mail_payload = {};
  var itemize_inquiry_payload = [];

  // Start popover script
  const [dtnAnchorEl, setDtnAnchorEl] = React.useState(null);
  const [destinationOpen, setDestinationOpen] = useState(null);

  const dtnHandleClick = (event, id) => {
    setDtnAnchorEl(event.currentTarget);
    setDestinationOpen(id);
  };

  const dtnHandleClose = () => {
    setDtnAnchorEl(null);
    setDestinationOpen(null);
  };
  // End popover script

  const AccordionClick = (panel) => (event, newExpanded) => {
    setExpandedAccordion(newExpanded ? panel : false);
  };
  const createMailPayload = () => {
    mail_payload = {
      SelectedVendorIds: "",
      prevtranguid: "",
      master_type: "category",
      ProductName: selectedCategoryName,
      TranType: "Inquiry",
      TranTypeGuid: "s1:" + inquiryID,
      TranStatus: "Inquiry",
      ReverseAuction: "n",
      SendSMSFlag: "Y",
      product_id: selectedOldCategoryId,
      vendx_action: "resend_automail",
      fromQPlus: true,
      sessionID: sessionId,
    };
    let selectedVendorIds = "";
    let vendor_id_arr = [...vendorIDArr];
    vendor_id_arr = vendor_id_arr.filter(
      (item, index) => vendor_id_arr.indexOf(item) === index
    );

    if (applyToAll) {
      selectedVendorIds = "All";
    } else {
      allVendorList.map((vendor_data) => {
        if (
          selectionModel[vendor_data.id] &&
          selectionModel[vendor_data.id].length > 0
        ) {
          if (mail_payload.prevtranguid === "") {
            selectedVendorIds = vendor_data.id + "###" + vendor_data.guid;

            mail_payload.prevtranguid = vendor_data.guid;
          } else {
            selectedVendorIds +=
              "," + vendor_data.id + "###" + vendor_data.guid;
          }
        }
      });
    }
    mail_payload.SelectedVendorIds = selectedVendorIds;
  };
  const createItemizeInqPayload = (linkItemVendorDetailsHash) => {
    Object.keys(linkItemVendorDetailsHash).map((item_id) => {
      linkItemVendorDetailsHash[item_id].forEach((vendor_id) => {
        let hash = {
          category_id: selectedCategoryId,
          vendor_status: "A",
        };

        hash["vendor_id"] = vendor_id;
        hash["item_id"] = item_id;
        itemize_inquiry_payload.push(hash);
      });
    });
  };
  function handleApplyToAll() {
    let linkItemVendorDetailsHash = { ...linkItemVendorDetails };
    let SelectionModelHash = { ...selectionModel };
    if (!SelectionModelHash[allVendorList[0].id]) {
      SelectionModelHash[allVendorList[0].id] = [];
    }
    let firstVendorData = [...SelectionModelHash[allVendorList[0].id]];

    Object.keys(linkItemVendorDetailsHash).map((item_id) => {
      if (!linkItemVendorDetailsHash[item_id].includes(allVendorList[0].id)) {
        delete linkItemVendorDetailsHash[item_id];
      } else {
        allVendorList.map((vendor_data) => {
          if (
            !linkItemVendorDetailsHash[item_id].includes(vendor_data.id) &&
            vendor_data.cat_ven_link_data &&
            vendor_data.vendor_status !== "suspended"
          ) {
            linkItemVendorDetailsHash[item_id].push(vendor_data.id);
          }
        });
      }
    });
    allVendorList.map((vendor_data) => {
      SelectionModelHash[vendor_data.id] = [];
      SelectionModelHash[vendor_data.id].push(...firstVendorData);
      SelectionModelHash[vendor_data.id] = SelectionModelHash[vendor_data.id].filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    });
    setApplyToAll(true);
    setSelectionModel(SelectionModelHash)
    setLinkItemVendorDetails(linkItemVendorDetailsHash)
  }
  function handleSubmit() {
    let linkItemVendorDetailsHash = { ...linkItemVendorDetails };
    let data = {
      linking_stage: 10,
      link_as: "approved",
    };
    if (Object.keys(linkItemVendorDetailsHash)?.length > 0) {
      if (plantSpecific === "Y" && isPR) {
        data["item_vendor_plant"] = {};
        itemsList.forEach((itemData) => {
          if (linkItemVendorDetailsHash[itemData.id]) {
            itemData.Destination.forEach((plant) => {
              if (!data["item_vendor_plant"][plant.plant_code]) {
                data["item_vendor_plant"][plant.plant_code] = {};
              }
              data["item_vendor_plant"][plant.plant_code][itemData.id] =
                linkItemVendorDetailsHash[itemData.id];
            });
          }
        });
      } else {
        data["item_vendor_plant"] = {};
        data["item_vendor_plant"]["_"] = linkItemVendorDetailsHash;
      }
    } else {
      setSnackbar(true);
      setseverity("error");
      if (applyToAll) {
        setSnackMessage(
          "No Item and Vendor Found for Linking, Select for Vendor " +
          allVendorList[0].name
        );
      } else {
        setSnackMessage("No Item and Vendor Found for Linking");
      }
      return false;
    }
    setLoadingOverlay(true);
    axios
      .post(linkVendorsToItem(selectedCategoryId), data)
      .then((response) => {
        if (response.status === 204) {
          setLoadingOverlay(false);
          setSnackbar(true);
          setseverity("error");
          setSnackMessage(
            "Category " + selectedCategoryName + " deleted"
          );
        } else {

          setSnackbar(true);
          setseverity("success");
          setSnackMessage(
            response.data.message ? response.data.message : "Linked successully"
          );
          let mail_url = sendMailUri + "?vendx_action=resend_automail";
          createMailPayload();
          createItemizeInqPayload(linkItemVendorDetailsHash);
          axios
            .patch(itemizeInquiryURL(inquiryID), itemize_inquiry_payload)
            .then((response) => {
              let formData = new FormData();
              Object.keys(mail_payload).forEach((key) => {
                formData.append(key, mail_payload[key]);
              });
              const config = {
                headers: {
                  Authorization: `Bearer ${sessionId}`,
                  "content-type": "multipart/form-data",
                },
              };
              axios
                .post(mail_url, formData, config)
                .then((res) => {
                  setLoadingOverlay(false);
                  setSnackbar(true);
                  setseverity("success");
                  setSnackMessage("Mail Sent Successfully");
                  setTimeout(function () {
                    handleCloseDialogue(true);
                  }, 3000);
                })
                .catch((err) => {
                  console.log("err", err);
                  setLoadingOverlay(false);
                  setSnackbar(true);
                  setseverity("error");
                  setSnackMessage("Failed to send Mail");
                });
            })
            .catch((er) => {
              console.log("err", er);
              setLoadingOverlay(false);
              setSnackbar(true);
              setseverity("error");
              setSnackMessage("Failed to link Item and Inquiry");
            });
        }
      })
      .catch((er) => {
        console.log("err", er);
        setLoadingOverlay(false);
        setSnackbar(true);
        setseverity("error");
        setSnackMessage("Linking Failed");
      });
    return true;
  }

  var plants_code_arr = [];
  const handleCloseSnackbar = () => {
    setSnackbar(false);
  };

  const fetchItem = async () => {
    var url = get_Inquiries_Item_data(inquiryID);
    var item_rows = [];
    setLoadingOverlay(true);
    await axios
      .get(url, configAxios)
      .then((res) => {
        let finalData;
        let item_hash = {};
        finalData = res.data.inquiry_items;
        finalData.map((item_data) => {
          setSelectedCategoryId(item_data.inquiry_category.new_id);
          setSelectedCategoryName(item_data.inquiry_category.name);
          setSelectedOldCategoryId(item_data.inquiry_category.id);
          let item_id = item_data.new_item_id;
          if (Array.isArray(plants_code_arr)) {
            plants_code_arr = {};
          }
          plants_code_arr[item_data.id] = [];
          if (destination_code !== "" && !Array.isArray(destination_code)) {
            destination_code = JSON.parse(destination_code);
          }
          plants_code_arr[item_data.id] = destination_code;
          if (item_data.prlis.length > 0) {
            setIsPR(true);
            plants_code_arr[item_data.id] = [];
            item_data.prlis.map((pr_details) => {
              if (
                !plants_code_arr[item_data.id].includes(pr_details.plant_code)
              ) {
                plants_code_arr[item_data.id].push(pr_details.plant_code);
              }
            });
          }
          let plant_arr = {};
          if (plantSpecific === "Y") {
            Object.keys(plants_code_arr).forEach((itemID) => {
              if (
                Array.isArray(plants_code_arr[itemID]) &&
                plants_code_arr[itemID].length > 0
              ) {
                plants_code_arr[itemID].forEach((plant_code) => {
                  if (accountPlant.hasOwnProperty(plant_code)) {
                    let hash = {
                      plant_name:
                        accountPlant[plant_code].Dropdown_Element_Name,
                      plant_code: plant_code,
                    };
                    if (!plant_arr[itemID]) {
                      plant_arr[itemID] = [];
                    }
                    plant_arr[itemID].push(hash);
                  }
                });
              }
            });
          }
          item_hash = {
            id: item_data.new_item_id,
            old_id: item_data.id,
            item_name: item_data.name,
            item_code: item_data.code,
            rfq_quantity: item_data.quantity,
            unit: item_data.unit,
            item_stage: item_data.item_stage,
            Destination: plant_arr[item_data.id],
          };

          item_rows.push(item_hash);
          let data_hash = itemsHash;
          data_hash[item_id] = item_hash;
          setItemsHash(data_hash);
        });
        item_rows.sort((a, b) => a.id - b.id);
        setItemsList(item_rows);
        setLoadingOverlay(false);
      })
      .catch((err) => {
        setLoadingOverlay(false);
        console.log(err);
      });
  };
  const fetchVendors = () => {
    var url = get_Inquiries_Vendor_data(inquiryID);
    var selectedRowId = { ...selectedRowId };
    var vendor_rows = [];
    setLoadingOverlay(true);
    axios
      .get(url, configAxios)
      .then((res) => {
        let finalData;
        let vendor_hash = {};
        finalData = res.data.vendors;
        finalData.map((vendor_data) => {
          vendor_data.inquiry_item_ids = vendor_data.inquiry_item_ids.filter(
            (item, index) =>
              vendor_data.inquiry_item_ids.indexOf(item) === index
          );
          vendor_hash = {
            id: vendor_data.id,
            name: vendor_data.name,
            code: vendor_data.code,
            is_gst: vendor_data.is_gst,
            country: vendor_data.country,
            item_count: vendor_data.inquiry_item_ids.length,
            is_approved: vendor_data.is_approved,
            State: vendor_data.State,
            contact_person: vendor_data.contact_person,
            contact_number: vendor_data.contact_number,
            inquiry_item_ids: vendor_data.inquiry_item_ids,
            inquiry_plant_codes: {},
            guid: vendor_data.guid,
            vendor_status: vendor_data.vendor_status,
            cat_ven_link_data: vendor_data.cat_ven_link_data,
            gstin_no: vendor_data.gstin_no,
            gstin_status: vendor_data.gstin_status,
            pan_status: vendor_data.pan_status,
            pan_no: vendor_data.pan_no,
            msme_status: vendor_data.msme_status,
            industry_type: vendor_data.industry_type,
            industry_type_value: vendor_data.industry_type_value,
          };
          if (selectedRowId[vendor_data.id] === undefined) {
            vendor_rows.push(vendor_hash);
          }
          selectedRowId[vendor_data.id] = [];
        });
        vendor_rows.sort((a, b) => a.id - b.id);
        setVendorList(vendor_rows);
        setAllVendorList(vendor_rows);
        setSelectedRowId(selectedRowId);
        setLoadingOverlay(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingOverlay(false);
      });
  };
  var itemColums = {};

  const handleSelectionItem = (vendor_id, item_id) => {
    let linkItemVendorDetailsHash = { ...linkItemVendorDetails };
    if (!linkItemVendorDetailsHash[item_id]) {
      linkItemVendorDetailsHash[item_id] = [];
    }
    if (linkItemVendorDetailsHash[item_id]?.includes(vendor_id)) {
      linkItemVendorDetailsHash[item_id].splice(
        linkItemVendorDetailsHash[item_id].indexOf(vendor_id),
        1
      );
      if (linkItemVendorDetailsHash[item_id].length === 0) {
        delete linkItemVendorDetailsHash[item_id];
      }
    } else {
      linkItemVendorDetailsHash[item_id].push(vendor_id);
    }
    setLinkItemVendorDetails(linkItemVendorDetailsHash);
    let SelectionModelHash = { ...selectionModel };
    if (!SelectionModelHash[vendor_id]) {
      SelectionModelHash[vendor_id] = [];
    }
    if (SelectionModelHash[vendor_id]?.includes(item_id)) {
      SelectionModelHash[vendor_id].splice(
        SelectionModelHash[vendor_id].indexOf(item_id),
        1
      );
    } else {
      SelectionModelHash[vendor_id].push(item_id);
    }
    setSelectionModel(SelectionModelHash);
    setApplyToAll(false);
  };

  const handleSelectAllItem = (vendor_id, inquiry_item_ids) => {
    let select_arr = itemsList
      .map((row) => {
        let itemError = allItemErrArr.includes(parseInt(row.id)) ? true : false;
        if (!inquiry_item_ids?.includes(row.old_id) && !itemError) {
          return row.id;
        }
      })
      .filter(function (element) {
        return element !== undefined;
      });
    let SelectionModelHash = { ...selectionModel };
    let linkItemVendorDetailsHash = { ...linkItemVendorDetails };
    if (
      selectionModel[vendor_id]?.length +
      inquiry_item_ids.length +
      allItemErrArr.length ===
      itemsList.length
    ) {
      SelectionModelHash[vendor_id].map((item_id) => {
        delete linkItemVendorDetailsHash[item_id];
      });
      SelectionModelHash[vendor_id] = [];
      setSelectionModel(SelectionModelHash);
    } else {
      SelectionModelHash[vendor_id] = select_arr;
      setSelectionModel(SelectionModelHash);
    }
    SelectionModelHash[vendor_id].map((item_id) => {
      if (!linkItemVendorDetailsHash[item_id]) {
        linkItemVendorDetailsHash[item_id] = [];
      }
      if (!linkItemVendorDetailsHash[item_id]?.includes(vendor_id)) {
        linkItemVendorDetailsHash[item_id].push(vendor_id);
      }
    });
    setLinkItemVendorDetails(linkItemVendorDetailsHash);
    setApplyToAll(false);
  };
  const muitiPlantUIList = (plant) => {
    if (plant?.length > 1) {
      const listItems = plant.map((plantDetailHash, index) => (
        <React.Fragment>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="body2" color="text.primary">
                  {plantDetailHash.plant_name}
                </Typography>
              }
              secondary={
                <Typography variant="caption" display="block" color="grey.500">
                  {plantDetailHash.plant_code}
                </Typography>
              }
            />
          </ListItem>
          {index !== plant.length - 1 && (
            <Divider component="li" sx={{ borderColor: "secondary.50" }} />
          )}
        </React.Fragment>
      ));
      return (
        <>
          <List
            subheader={
              <ListSubheader>
                <ListItemIcon sx={{ minWidth: "24px" }}>
                  <LocationOnIcon
                    fontSize="small"
                    sx={{ color: "secondary.700" }}
                  />
                </ListItemIcon>
                View Destination
              </ListSubheader>
            }
            disablePadding
          >
            {listItems}
          </List>
        </>
      );
    }
    return <></>;
  };
  const getColumns = (vendor_data) => {
    const createRenderCell = () => (params) => {
      const isItemDeleted = allItemErrArr.includes(parseInt(params.id));
      const isVendorSuspended = vendor_data.vendor_status === "suspended";
      const isCatVenLinkDataAbsent = !vendor_data.cat_ven_link_data;
      if (!selectionModel[vendor_data.id]) {
        selectionModel[vendor_data.id] = [];
      }
      return (
        <>
          {vendor_data.inquiry_item_ids?.includes(params.row.old_id) ? (
            isItemDeleted ? (
              <>
                <Tooltip title="Item is deleted or unlinked from the category.">
                  <InfoDetailIcon color="error" />
                </Tooltip>
              </>
            ) : (
              <>
                <Checkbox
                  defaultChecked
                  disabled
                  id={vendor_data.id + "_" + params.id}
                />
              </>
            )
          ) : (
            isItemDeleted ? (
              <>
                <Tooltip title="Item is deleted or unlinked from the category.">
                  <InfoDetailIcon color="error" />
                </Tooltip>
              </>
            ) : (
              <Checkbox
                onClick={() =>
                  handleSelectionItem(vendor_data.id, params.id, params.row)
                }
                id={vendor_data.id + "_" + params.id}
                disabled={isVendorSuspended || isCatVenLinkDataAbsent}
                checked={selectionModel[vendor_data.id].includes(params.id)}
              />
            )
          )}
        </>

      );
    }
    let item_row_cell = [
      {
        field: "selectAll",
        headerName: "Select All",
        width: 60,
        renderHeader: () => {
          return (
            <>
              <Checkbox
                checked={
                  (selectionModel[vendor_data.id] === undefined
                    ? 0
                    : selectionModel[vendor_data.id]?.length) +
                  (vendor_data.inquiry_item_ids === undefined
                    ? 0
                    : vendor_data.inquiry_item_ids.length) +
                  (itemErrArr[vendor_data.id] === undefined ? 0 :
                    itemErrArr[vendor_data.id].length) ===
                  itemsList.length
                }
                disabled={
                  ((!vendor_data.cat_ven_link_data ||
                    vendor_data.vendor_status === "suspended" ||
                    allItemErrArr.length === itemsList.length))
                }
                onChange={() =>
                  handleSelectAllItem(
                    vendor_data.id,
                    vendor_data.inquiry_item_ids
                  )
                }
              />
            </>
          );
        },
        disableReorder: true,
        disableExport: true,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        resizable: false,
        headerAlign: "center",
        align: "center",
        renderCell: createRenderCell(),
      },
      {
        field: "linked_item",
        headerName: "",
        width: 30,
        disableReorder: true,
        disableExport: true,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        resizable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          if (vendor_data.inquiry_item_ids?.includes(params.row.old_id)) {
            return (
              <>
                <Tooltip title="Linked Item">
                  <LinkIcon sx={{ color: "text.primary" }} />
                </Tooltip>
              </>
            );
          } else {
            return <Box></Box>;
          }
        },
      },
      {
        field: "item_name",
        headerName: "Item Name",
        width: 240,
        renderCell: (params) => {
          let field = params.field;
          return (
            <Tooltip title={params.row[field]}>
              <Typography variant="body2" fontWeight={500} noWrap>
                {params.row[field]}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "item_code",
        headerName: "Item Code",
        width: 125,
        renderCell: (params) => {
          let field = params.field;
          return (
            <Typography variant="body2" noWrap>
              {params.row[field]}
            </Typography>
          );
        },
      },
      {
        field: "rfq_quantity",
        headerName: "RFQ Quantity",
        width: 125,
        headerAlign: "right",
        align: "right",
        renderCell: (params) => {
          let field = params.field;
          return (
            <Typography variant="body2" noWrap>
              {params.row[field]}
            </Typography>
          );
        },
      },
      {
        field: "unit",
        headerName: "Unit",
        width: 125,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          let field = params.field;
          return (
            <Typography variant="body2" noWrap>
              {params.row[field]}
            </Typography>
          );
        },
      },
      {
        field: "Destination",
        headerName: "Destination",
        width: 150,
        // headerAlign: "center",
        // align: "center",
        renderCell: (params) => {
          return (
            <>
              <Box
                sx={{ cursor: "pointer" }}
                display="flex"
                width="100%"
                onClick={(e) =>
                  dtnHandleClick(e, params.id + "_" + vendor_data.id)
                }
              >
                <Tooltip title={params.formattedValue[0]?.plant_name}>
                  <Typography variant="body2" noWrap>
                    {params.formattedValue[0]?.plant_name}
                  </Typography>
                </Tooltip>
                {params.formattedValue?.length > 1 ? (
                  <Typography
                    variant="body2"
                    component="span"
                    color="primary"
                    sx={{ ml: 1 }}
                  >
                    +{params.formattedValue?.length - 1}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
              {params.formattedValue?.length > 1 && (
                <Popover
                  open={destinationOpen === params.id + "_" + vendor_data.id}
                  anchorEl={dtnAnchorEl}
                  onClose={dtnHandleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  className="DestList-Popover"
                >
                  {muitiPlantUIList(params.formattedValue)}
                </Popover>
              )}
            </>
          );
        },
      },
    ];
    if (plantSpecific === "N" || !isPR) {
      item_row_cell = item_row_cell.filter(
        (obj) => obj.field !== "Destination"
      );
    }
    return item_row_cell;
  }

  const handleSearchChange = (event) => {
    setExpandedAccordion(false);
    setItemSearchInput(event.target.value);
    // setLoadingOverlay(true);
    let timeout = 490;
    if (expandedAccordion) {
      timeout = 50;
    }
    setTimeout(() => {
      if (event.target.value !== "") {
        let searchVendorList = [];
        allVendorList.map((vendor_data) => {
          if (
            vendor_data.code.toUpperCase().includes(event.target.value.toUpperCase()) ||
            vendor_data.name.toUpperCase().includes(event.target.value.toUpperCase())
          ) {
            searchVendorList.push(vendor_data);
          }
        });
        setVendorList(searchVendorList);
      } else {
        setVendorList(allVendorList);
      }
      // setLoadingOverlay(false);
    }, timeout);
  };

  const handleRowClick = (id, vendor_id) => {
    let selected_arr = { ...selectedRowId };
    if (
      selected_arr[vendor_id] !== undefined &&
      selected_arr[vendor_id]?.includes(id)
    ) {
      selected_arr[vendor_id].splice(selected_arr[vendor_id].indexOf(id), 1);
    } else {
      if (selected_arr[vendor_id] === undefined) {
        selected_arr[vendor_id] = [];
      }
      selected_arr[vendor_id].push(id);
    }
    setSelectedRowId(selected_arr);
    if (selected_arr[vendor_id].length > 0) {
      setIsListOpen(true);
    } else {
      setIsListOpen(false);
    }
  };
  const fetchLinkDetailsAPI = () => {
    const url = fetchLinkDetails(selectedCategoryId);
    let vendor_arr = [...allVendorList];
    let item_arr = [...itemsList];
    let data = {
      vendor_ids: [],
      item_ids: [],
    };
    vendor_arr.map((vendorHash) => {
      data.vendor_ids.push(vendorHash.id);
    });
    let item_old_new_id_hash = {};
    item_arr.map((itemHash) => {
      data.item_ids.push(itemHash.old_id);
      item_old_new_id_hash[itemHash.old_id] = itemHash.id;
    });
    if (plantSpecific === "Y" && isPR) {
      if (destination_code !== "" && !Array.isArray(destination_code)) {
        destination_code = JSON.parse(destination_code);
      }
      data["plant_codes"] = destination_code;
    }
    setLoadingOverlay(true);
    if (vendor_arr.length > 0 && item_arr.length > 0) {
      axios
        .post(url, data, configAxios)
        .then((res) => {
          let category_item_vendor_mapping =
            res.data.category_item_vendor_mapping;
          let itemArr = [];
          let itemNotExist = [...allItemErrArr];
          let vendorExist = [];
          let errItemArr = {};
          category_item_vendor_mapping.map((cat_itm_vend_data) => {
            if (!itemArr.includes(cat_itm_vend_data.item_id)) {
              itemArr.push(cat_itm_vend_data.item_id);
            }
            if (!vendorExist.includes(cat_itm_vend_data.vendor_id)) {
              vendorExist.push(cat_itm_vend_data.vendor_id);
            }
          });
          itemsList.forEach((itemData) => {
            if (
              !itemArr.includes(itemData.old_id) ||
              itemData.item_stage === 20
            ) {
              itemNotExist.push(itemData.id);
              allVendorList.forEach((vendorData) => {
                if (!vendorData.inquiry_item_ids.includes(itemData.id)) {
                  if (!errItemArr[vendorData.id]) {
                    errItemArr[vendorData.id] = [];
                  }
                  errItemArr[vendorData.id].push(itemData.id);
                }
              });
            }
          });
          setAllItemErrArr(itemNotExist);
          setItemErrArr(errItemArr);
          setLoadingOverlay(false);
          setLinkDetailsFetched(true);
        })
        .catch((err) => {
          console.log("err", err);
          setLoadingOverlay(false);
          setLinkDetailsFetched(true);
        });
    }
  };
  useEffect(() => {
    fetchItem();
    fetchVendors();
  }, []);
  useEffect(() => {
    if (!linkDetailsFetched) {
      fetchLinkDetailsAPI();
    }
  }, [linkDetailsFetched, itemsList, allVendorList]);
  return (
    <React.Fragment>
      {LinkVendorToItemStyle}
      <Dialog
        fullScreen
        sx={{
          margin: { xs: "0", sm: "0", md: "0" },
        }}
        open={openLinkVendorItem}
        onClose={() => {
          handleCloseDialogue(false);
        }}
        className="RFQ-VendorDialog"
      >
        <DialogTitle
          component="div"
          sx={{
            position: "sticky",
            top: 0,
            padding: "11px 15px !important",
          }}
        >
          <Stack
            className="VendorItem-Title"
            direction={{ sm: "column", md: "row" }}
            spacing={{ xs: 0, sm: 0, md: 1 }}
            alignItems={{ sm: "start", md: "center" }}
            sx={{
              flex: 1,
              width: "calc(100% - 25px)",
            }}
          >
            <Tooltip title="Link Vendor To Item">
              <Typography
                sx={{ color: "black" }}
                variant="body1"
                fontWeight={500}
                component="h2"
                noWrap
              >
                Link Vendor To Item
              </Typography>
            </Tooltip>
          </Stack>
          <IconButton
            edge="end"
            color="var(--bluegrey-500)"
            size="small"
            onClick={() => {
              handleCloseDialogue(false);
            }}
            aria-label="close"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }} dividers>
          <React.Fragment>
            <Box
              display="flex"
              alignItems={{ xs: "start", sm: "center", md: "center" }}
              flexDirection={{ xs: "column", sm: "row", md: "row" }}
              position='sticky'
              sx={{
                background: "var(--grey-0)",
                top: "0",
                zIndex: "11",
                borderBottom: "1px solid var(--bluegrey-500)"
              }}
            >
              <Box
                sx={{
                  minWidth: "320px",
                  "@media screen and (max-width:600px)": {
                    minWidth: "100%",
                    borderBottom: "1px solid var(--bluegrey-500)",
                  },
                }}
              >
                <TextField
                  type="search"
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={itemSearchInput}
                  onChange={handleSearchChange}
                  placeholder="Search"
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent={{ xs: "space-between", sm: "end", md: "end" }}
                pr={2}
                pl={2}
                width={"100%"}
              >
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      // disabled={allVendorList[0]?.inquiry_item_ids.length === itemsList.length}
                      checked={applyToAll}
                      onClick={(eve) => {
                        if (eve.target.checked) {
                          handleApplyToAll();
                        } else {
                          setApplyToAll(eve.target.checked);
                        }
                      }}
                    />
                  }
                  label={
                    <Typography variant="body2" fontWeight={500} ml={1}>
                      Apply to All Vendors
                    </Typography>
                  }
                  sx={{
                    marginRight: "8px",
                  }}
                />
                <Tooltip
                  title="All items and plants linked with first vendor will be applied to rest of all available vendor."
                  placement="bottom-end"
                >
                  <InfoIcon color="primary" />
                </Tooltip>
              </Box>
            </Box>
            <Box p={2}>
              {vendorList.map((vendor_data) => (
                <Accordion
                  expanded={expandedAccordion === vendor_data.id}
                  onChange={AccordionClick(vendor_data.id)}
                  id={vendor_data.id}
                >
                  <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                    <Stack
                      width="100%"
                      display="flex"
                      direction="row"
                      spacing={4}
                      sx={{ overflowY: "auto" }}
                      className="VendorDtls-Box"
                    >
                      <Box sx={{ flex: "0 0 275px" }}>
                        <Typography
                          variant="caption"
                          component="div"
                          color={"grey.500"}
                        >
                          VENDOR NAME
                        </Typography>
                        <Box display="flex">
                          <Tooltip title={vendor_data.name}>
                            <Typography
                              variant="body2"
                              fontWeight={500}
                              color={"secondary.900"}
                              noWrap
                            >
                              {vendor_data.name}
                            </Typography>
                          </Tooltip>
                          <Stack
                            direction="row"
                            justifyContent="end"
                            spacing={1}
                            ml={2}
                          >
                            <VendorChip vendor_data={vendor_data} module_permission={companyPermissions} />
                          </Stack>
                        </Box>
                      </Box>

                      <Box sx={{ flex: "0 0 100px" }}>
                        <Typography
                          variant="caption"
                          component="div"
                          color={"grey.500"}
                        >
                          VENDOR CODE
                        </Typography>
                        <Tooltip title={vendor_data.code}>
                          <Typography
                            variant="body2"
                            color={"text.primary"}
                            noWrap
                          >
                            {vendor_data.code}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <Box sx={{ flex: "0 0 120px" }}>
                        <Typography
                          variant="caption"
                          component="div"
                          color={"grey.500"}
                        >
                          VENDOR STATUS
                        </Typography>
                        <Chip
                          label={
                            !vendor_data.cat_ven_link_data ||
                              vendor_data.vendor_status === "suspended"
                              ? vendor_data.vendor_status === "suspended"
                                ? "Suspended"
                                : "Unlinked"
                              : "Active"
                          }
                          color={
                            !vendor_data.cat_ven_link_data ||
                              vendor_data.vendor_status === "suspended"
                              ? "error"
                              : "success"
                          }
                          variant="soft"
                          size="xsmall"
                          sx={
                            vendor_data.is_approved
                              ? { opacity: "1" }
                              : { opacity: "0.5" }
                          }
                        />
                      </Box>
                      <Box sx={{ flex: "0 0 100px" }}>
                        <Typography
                          variant="caption"
                          component="div"
                          color={"grey.500"}
                        >
                          STATE
                        </Typography>
                        <Tooltip
                          title={
                            vendor_data.country === "India" &&
                              vendor_data.State !== "" &&
                              vendor_data.State !== null
                              ? vendor_data.State
                              : "NA"
                          }
                        >
                          <Typography
                            variant="body2"
                            color={"text.primary"}
                            noWrap
                          >
                            {vendor_data.country === "India" &&
                              vendor_data.State !== "" &&
                              vendor_data.State !== null
                              ? vendor_data.State
                              : "NA"}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <Box sx={{ flex: "0 0 120px" }}>
                        <Typography
                          variant="caption"
                          component="div"
                          color={"grey.500"}
                        >
                          COUNTRY
                        </Typography>
                        <Tooltip title={vendor_data.country}>
                          <Typography
                            variant="body2"
                            color={"text.primary"}
                            noWrap
                          >
                            {vendor_data.country}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <Box sx={{ flex: "0 0 100px" }}>
                        <Typography
                          variant="caption"
                          component="div"
                          color={"grey.500"}
                        >
                          ITEM COUNT
                        </Typography>
                        <Typography variant="body2" color={"text.primary"}>
                          {vendor_data.item_count}
                        </Typography>
                      </Box>
                      <Box sx={{ flex: "0 0 120px" }}>
                        <Typography
                          variant="caption"
                          component="div"
                          color={"grey.500"}
                        >
                          CONTACT PERSON
                        </Typography>
                        <Tooltip title={vendor_data.contact_person}>
                          <Typography
                            variant="body2"
                            color={"text.primary"}
                            noWrap
                          >
                            {vendor_data.contact_person}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <Box sx={{ flex: "0 0 120px" }}>
                        <Typography
                          variant="caption"
                          component="div"
                          color={"grey.500"}
                        >
                          CONTACT NUMBER
                        </Typography>
                        <Typography variant="body2" color={"text.primary"}>
                          {vendor_data.contact_number}
                        </Typography>
                      </Box>
                    </Stack>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Box className="ItemListGrid">
                      <DataGridPro
                        rows={itemsList}
                        columns={getColumns(vendor_data)}
                        headerHeight={50}
                        columnHeaderHeight={50}
                        rowHeight={50}
                        pagination
                        hideFooter
                        disableColumnPinning
                        disableColumnSelector
                        disableRowSelectionOnClick
                        onCellClick={(params) => {
                          if (params.field !== "selectAll") {
                            handleRowClick(params.id, vendor_data.id);
                          }
                        }}
                        getRowSpacing={() => ({ top: 4, bottom: 4 })}
                        sx={{
                          border: "none",
                          "& .MuiDataGrid-row:last-child .MuiDataGrid-cell": {
                            borderBottom: "1px solid var(--bluegrey-500)",
                          },
                        }}
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </React.Fragment>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              handleCloseDialogue(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
          >
            send RFQ to Vendor
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackbar}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          variant="filled"
        >
          {SnackMessage}
        </Alert>
      </Snackbar>
      <Backdrop
        style={{ color: "#fff", zIndex: "10000000000" }}
        open={loadingOverlay}
      >
        <CircularProgress style={{ color: "#fff" }} />
      </Backdrop>
    </React.Fragment>
  );
}
