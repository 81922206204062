import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Popover,
  styled,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PRLogsStatusDrawer } from "./PRStatusLogsStyle";
import Grid from "@mui/material/Unstable_Grid2";
import {
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import CommonDataGridPro from "../RFQList/commonDataGridPro/commonDataGridPro";
import { getItemsV2, getPrDetails, get_RFQ_List } from "../../config/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbarfunc } from "../../redux/actions/snackbarAction";
import axios from "axios";
import moment from "moment";

const SmallTabs = styled(Tabs)({
  background: "var(--grey-0)",
  borderBottom: "1px solid var(--bluegrey-500)",
  minHeight: "32px",
  padding: "0 16px 0 16px",
  position: "sticky",
  top: "0",
  zIndex: "11",
});

const SmallTab = styled(Tab)({
  minHeight: "32px",
  textTransform: "none",
  fontWeight: "400",
  textTransform: "uppercase",
  color: "var(--secondary-600)",
  whiteSpace: "nowrap",
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ height: "calc(100vh - 104px)" }}>{children}</Box>
      )}
    </div>
  );
}

function CustomGridToolbar() {
  return (
    <>
      {/* Start Desktop View Code */}
      <GridToolbarContainer className="datagrid-toolbar">
        <Grid
          container
          spacing={0}
          sx={{
            width: "100%",
            // pt: 1,
            "&.MuiGrid2-root": {
              paddingTop: { xs: "0", sm: "0", md: "0", alignItems: "center" },
            },
          }}
        >
          <Grid xs={12} sm={4} md={4} xl={4}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <GridToolbarQuickFilter
                className="DataGrid-SearchBar"
                sx={{ pb: 0 }}
              />
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={8}
            md={8}
            xl={8}
            sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}
          >
            <Box
              className="toolbar-right"
              sx={{
                "&.toolbar-right": {
                  marginLeft: "auto",
                },
              }}
            >
              <Box className="dg-filter">
                <GridToolbarColumnsButton />
                <GridToolbarExport
                  printOptions={{ disableToolbarButton: true }}
                />
                <GridToolbarFilterButton />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </GridToolbarContainer>
      {/* Start Desktop View Code */}

      {/* Start Mobile View Code */}
      <GridToolbarContainer
        className="datagrid-toolbar"
        sx={{ display: { xs: "flex", sm: "none" } }}
      >
        <Grid
          container
          spacing={0}
          sx={{ width: "100%", alignItems: "center" }}
        >
          <Grid
            size={{ xs: 12, sm: 12, md: 12, xl: 12 }}
            // sx={{ textAlign: { xs: "right", sm: "right" } }}
          >
            <Box className="dg-filter">
              <GridToolbarColumnsButton />
              <GridToolbarExport />
              <GridToolbarFilterButton />
            </Box>
          </Grid>
        </Grid>
      </GridToolbarContainer>
      {/* End Mobile View Code */}
    </>
  );
}

export default function PreferenceSettingDrawer(props) {
  const [value, setValue] = React.useState(0);
  const [SuccessLogs, setSuccessLogs] = React.useState([]);
  const [FailureLogs, setFailureLogs] = React.useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [Loading, setLoading] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [InquiryMap, setInquiryMap] = React.useState({});
  const [popoverId, setPopoverId] = useState(null); // Track which popover should be open

  const handlePopoverOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setPopoverId(id); // Set the ID of the item triggering the popover
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (
        props.prLogs?.action_logs?.length ||
        props.prLogs?.rule_logs?.length
      ) {
        const failure = [];
        const success = [];

        const filteredRuleLogs = props.prLogs.rule_logs
          .filter((log) => log.status === 0)
          .map((log) => ({
            status: 0,
            message: log.message,
            rule_document_ids: [log.rule_document_id],
            action_document_id: null,
          }));

        let actionsLogs = props.prLogs?.action_logs
          ? props.prLogs?.action_logs
          : [];
        const actionsLogsInquiries = actionsLogs.map((log) => {
          if (log.status == 1 && log.action_document_id != null) {
            return log.action_document_id || [];
          }
        });

        actionsLogs.push(...filteredRuleLogs);

        const uniqueRuleDocumentIds = [
          ...new Set(
            actionsLogs?.flatMap((log) => log.rule_document_ids || [])
          ),
        ];

        const getPrlineItemsData = await getPrLineItemsDetails(
          uniqueRuleDocumentIds
        );
        if (actionsLogsInquiries[0] != undefined) {
          const InquiryData = await getInquiryDetails(
            actionsLogsInquiries.toString(",")
          );
        }
        actionsLogs.forEach((log, index) => {
          if (log.status === 0) {
            log.rule_document_ids.map((prId) => {
              failure.push({
                prlineitem: getPrlineItemsData?.prLineItemMap[prId],
                item: getPrlineItemsData?.items[
                  getPrlineItemsData?.prLineItemMap[prId].item.item_old_id
                ],
                message: log.message,
              });
            });
          } else if (log.status === 1) {
            log.rule_document_ids.map((prId) => {
              success.push({
                prlineitem: getPrlineItemsData?.prLineItemMap[prId],
                item: getPrlineItemsData?.items[
                  getPrlineItemsData?.prLineItemMap[prId].item.item_old_id
                ],
                message: log.message,
                action_document_id: log.action_document_id,
              });
            });
          }
        });

        setFailureLogs(
          failure.map((row, index) => ({ ...row, id: index + 1 }))
        );
        setSuccessLogs(
          success.map((row, index) => ({ ...row, id: index + 1 }))
        );
        setLoading(false);
      }
    };

    fetchData();
  }, [props.prLogs, props.sessionId]);

  const getInquiryDetails = async ($inquiryIds) => {
    try {
      axios.defaults.headers["Authorization"] = `Bearer ${props.sessionId}`;
      // let str = "?id[]=";
      // prIds.forEach((prId, j) => {
      //   if (j === prIds.length - 1) str = str + prId;
      //   else str = str + prId + "&id[]=";
      // });

      let url = `${get_RFQ_List}?inquiry_ids=${$inquiryIds}`;
      const response = await axios.get(url);

      const inquiryMap = {};
      response?.data?.inquires.forEach((inquiry) => {
        // oldItemIds.push(prLineItem.item?.item_old_id);
        inquiryMap[inquiry.id] = inquiry;
      });
      setInquiryMap(inquiryMap);
    } catch (err) {
      console.error(err);
      dispatch(
        openSnackbarfunc("error", "An error occurred while fetching tasks")
      );
    }
  };

  const getPrLineItemsDetails = async (prIds) => {
    try {
      axios.defaults.headers["Authorization"] = `Bearer ${props.sessionId}`;
      // let str = "?id[]=";
      // prIds.forEach((prId, j) => {
      //   if (j === prIds.length - 1) str = str + prId;
      //   else str = str + prId + "&id[]=";
      // });

      let url = `${getPrDetails}`;
      const response = await axios.post(url, { id: prIds });

      const prLineItemMap = {};
      let oldItemIds = [];
      response?.data.forEach((prLineItem) => {
        oldItemIds.push(prLineItem.item?.item_old_id);
        prLineItemMap[prLineItem.id] = prLineItem;
      });
      oldItemIds = [...new Set(oldItemIds)];
      // setPrLineItems(prLineItemMap);
      const items = await fetchItems(oldItemIds);
      return { prLineItemMap, items };
    } catch (err) {
      console.error(err);
      dispatch(
        openSnackbarfunc("error", "An error occurred while fetching tasks")
      );
    }
  };

  const fetchItems = async (oldItemIds) => {
    try {
      axios.defaults.headers["Authorization"] = `Bearer ${props.sessionId}`;
      // let str = "?old_id[]=";
      // oldItemIds.forEach((prId, j) => {
      //   if (j === oldItemIds.length - 1) str = str + prId;
      //   else str = str + prId + "&old_id[]=";
      // });

      let url = `${getItemsV2}?pagination=false`;
      // getPrLineItems() + str + "&embed=customfield_details,attachment";
      const response = await axios.post(url, { old_id: oldItemIds });
      const itemMap = {};
      response?.data?.data.forEach((item) => {
        itemMap[item.old_id] = item;
      });
      return itemMap;
      // setItems(itemMap);
    } catch (err) {
      console.error(err);
      dispatch(
        openSnackbarfunc("error", "An error occurred while fetching tasks")
      );
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const formatErrors = (data) => {
  //   if (typeof data === "object" && data !== null) {
  //     return Object.entries(data)
  //       .map(([itemName, errors]) => {
  //         const errorMessages = Object.entries(errors)
  //           .map(([field, message]) => `${field}=>${message}`) // Get the error message
  //           .join("<br/>"); // Join messages with <br/> to create line breaks
  //         return `For item ${itemName}:<br/>${errorMessages}`; // Add a new line before each item name
  //       })
  //       .join("<br/><br/> ");
  //   } else {
  //     // If not an object (e.g., a simple string), just return it
  //     return data;
  //   }
  // };
  const formatErrors = (data, isExport = false) => {
    if (typeof data === "object" && data !== null) {
      return Object.entries(data)
        .map(([itemName, errors]) => {
          const errorMessages = Object.entries(errors)
            .map(([field, message]) => `${field} => ${message}`)
            .join(isExport ? "\n" : "<br/>"); // Use \n for Excel, <br/> for UI
          return `For item ${itemName}:${isExport ? "\n" : "<br/>"}${errorMessages}`;
        })
        .join(isExport ? "\n\n" : "<br/><br/> ");
    } else {
      return data; // Return as is if it's a simple string
    }
  };
  const columns = [
    {
      field: "id",
      headerName: "SR No",
      width: 55,
    },
    {
      field: "prNo",
      headerName: "PR No",
      width: 75,
      valueGetter: (params) => params.row.prlineitem?.pr?.number,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value} placement="bottom-start">
            <Typography variant="body" noWrap>
              {params.value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "prLiNo",
      headerName: "PR LI No",
      width: 150,
      valueGetter: (params) => params.row.prlineitem?.pr_line_item,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value} placement="bottom-start">
            <Typography variant="body" noWrap>
              {params.value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "prDate",
      headerName: "PR Date",
      width: 175,
      valueGetter: (params) =>
        moment(
          params.row.prlineitem?.created_time,
          "YYYY-MM-DD HH:mm:ss"
        ).format("DD/MM/YYYY hh:mm:ss"),
      renderCell: (params) => {
        return (
          <Tooltip title={params.value} placement="bottom-start">
            <Typography variant="body">{params.value}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "qty",
      headerName: "QTY",
      width: 100,
      valueGetter: (params) =>
        parseFloat(params.row.prlineitem?.quantity).toFixed(3),
      renderCell: (params) => {
        return (
          <Tooltip title={params.value} placement="bottom-start">
            <Typography variant="body">{params.value}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "itemName",
      headerName: "Item Name",
      width: 150,
      valueGetter: (params) => params.row?.item?.name,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value} placement="bottom-start">
            <Typography variant="body" noWrap>
              {params.value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "itemCode",
      headerName: "Item Code",
      width: 150,
      valueGetter: (params) => params.row?.item?.code,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value} placement="bottom-start">
            <Typography variant="body" noWrap>
              {params.value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "rfqNo",
      headerName: "RFQ No",
      width: 210,
      valueGetter: (params) =>
        InquiryMap?.[params.row?.action_document_id]?.number,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value} placement="bottom-start">
            <Typography variant="body">{params.value}</Typography>
          </Tooltip>
        );
      },
    },
  ];

  const failureColumns = [
    {
      field: "id",
      headerName: "SR No",
      width: 55,
    },

    {
      field: "prNo",
      headerName: "PR No",
      width: 75,
      valueGetter: (params) => params.row.prlineitem?.pr?.number,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value} placement="bottom-start">
            <Typography variant="body" noWrap>
              {params.value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "prLiNo",
      headerName: "PR LI No",
      width: 150,
      valueGetter: (params) => params.row.prlineitem?.pr_line_item,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value} placement="bottom-start">
            <Typography variant="body" noWrap>
              {params.value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "prDate",
      headerName: "PR Date",
      width: 175,
      valueGetter: (params) =>
        moment(
          params.row.prlineitem?.created_time,
          "YYYY-MM-DD HH:mm:ss"
        ).format("DD/MM/YYYY hh:mm:ss"),
      renderCell: (params) => {
        return (
          <Tooltip title={params.value} placement="bottom-start">
            <Typography variant="body">{params.value}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "qty",
      headerName: "QTY",
      width: 100,
      valueGetter: (params) =>
        parseFloat(params.row.prlineitem?.quantity).toFixed(3),
      renderCell: (params) => {
        return (
          <Tooltip title={params.value} placement="bottom-start">
            <Typography variant="body">{params.value}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "itemName",
      headerName: "Item Name",
      width: 150,
      valueGetter: (params) => params.row?.item?.name,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value} placement="bottom-start">
            <Typography variant="body" noWrap>
              {params.value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "itemCode",
      headerName: "Item Code",
      width: 150,
      valueGetter: (params) => params.row?.item?.code,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value} placement="bottom-start">
            <Typography variant="body" noWrap>
              {params.value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "errorMessage",
      headerName: "Failure Remarks",
      width: 200,
      align: "center",
      display: "flex",
      valueGetter: (params) =>  formatErrors(params.row.message, true),
      renderCell: (params) => {
        return (
          // <Tooltip
          // title={formatErrors(params.row.message)}
          // placement="bottom-start"
          // >
          // <Tooltip title={params.value} placement="bottom-start">
          <>
            <Typography
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onClick={(event) => handlePopoverOpen(event, params.row.id)}
              // onMouseEnter={(event) => handlePopoverOpen(event, params.row.id)}
              // onMouseLeave={handlePopoverClose}
              variant="body"
              sx={{ cursor: "pointer", color: "error.500" }}
              noWrap
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: formatErrors(params.row.message),
                }}
              />
            </Typography>
            <Popover
              id={`popover-${params.row.id}`}
              sx={{
                // pointerEvents: "none",
                maxHeight: 200,
                minHeight: 200,
                // overflowY: "auto",
              }}
              open={open && popoverId === params.row.id} // Open only for the selected row
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 1 }}>
                {" "}
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatErrors(params.row.message),
                  }}
                />
              </Typography>
            </Popover>
          </>
        );
      },
    },
    // { field: "justification", headerName: "Justification", width: 175 },
  ];
  return (
    <>
      <PRLogsStatusDrawer
        anchor="right"
        open={props.openPRLogsStatusDrawer}
        onClose={props.ClosePRLogsStatusDrawer}
        className="SidebarDrawer"
      >
        <Card>
          <CardHeader
            action={
              <Tooltip title="Close">
                <IconButton
                  onClick={() => {
                    props.ClosePRLogsStatusDrawer();
                    setLoading(true);
                    setSuccessLogs([]);
                    setFailureLogs([]);
                  }}
                  aria-label="close"
                  size="small"
                  sx={{ color: "var(--grey-500)" }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            }
            title={
              <Typography
                variant="subtitle1"
                fontWeight={500}
                component="h6"
                color={"secondary.main"}
              >
                PR Logs Status
              </Typography>
            }
          />
          <Divider />
          <CardContent>
            <SmallTabs value={value} onChange={handleChange}>
              <SmallTab iconPosition="start" label="Success Logs" />
              <SmallTab iconPosition="start" label="Failure Logs" />
            </SmallTabs>

            <TabPanel value={value} index={0}>
              <CommonDataGridPro
                loading={Loading}
                rows={SuccessLogs}
                columns={columns}
                className="fullScreenGrid"
                Toolbar={CustomGridToolbar}
                headerFilters
                pagination={true}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                columnHeaderHeight={40}
                headerFilterHeight={40}
                rowHeight={36}
                hideFooter
                sx={{
                  border: "none",
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CommonDataGridPro
                loading={Loading}
                rows={FailureLogs}
                columns={failureColumns}
                className="fullScreenGrid"
                Toolbar={CustomGridToolbar}
                headerFilters
                pagination={true}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                columnHeaderHeight={40}
                headerFilterHeight={40}
                rowHeight={36}
                hideFooter
                sx={{
                  border: "none",
                }}
              />
            </TabPanel>
          </CardContent>
        </Card>
      </PRLogsStatusDrawer>
    </>
  );
}
