import styled1 from "styled-components";
import {styled} from "@mui/system";
import { Dialog } from "@mui/material";

export const LinkCategoryDialog = styled1(Dialog)`
  .MuiDialog-paperWidthMd {
    min-width: 1200px;
  }
  @media screen and (max-width: 1520px) {
    .MuiDialog-paperWidthMd {
      min-width: 1200px;
    }
  }
  @media only screen and (max-width: 991.5px) {
    .MuiDialog-paperWidthMd {
      min-width: 90%;
    }
    .categorydialog_card {
      margin-top: 20px;
    }
  }

  .MuiDialogTitle-root
  {
    padding: 10px 15px 5px;
  }
  // .MuiGrid-item p {
  //   text-align: left;
  //   font-size: 18px;
  //   line-height: 22px;
  //   letter-spacing: 0.36px;
  //   color: var(--black-textcolor);
  // }
  .MuiDialogContent-root {
    background: #f6f9fc;
    padding-bottom:50px;
  }
  // .MuiDialogContent-root .linkcategorydialog_main p {
  //   text-align: left;
  //   font-size: calc(14px + (14 - 13) * ((100vw - 300px) / (1920 - 300))) !important;
  //   line-height: 20px;
  //   letter-spacing: 0px;
  //   color: var(--text-color);
  //   text-transform: uppercase;
  //   font-weight: 400;    
  // }
  .MuiDialogContent-root p.dialog_small_text{
    font-size: calc(13px + (13 - 12) * ((100vw - 300px) / (1920 - 300))) !important;
    letter-spacing: 0px;
    color: var(--small-text-ceolor);
    font-weight: 400 !important;
    text-transform: capitalize;    
  }
  .linkcategorydialog_main {
    min-height: 400px;
  }
  .categorydialog_card {
    background: var(--thm-white);
    box-shadow: none;
    border-radius: 3px;
    min-height: 200px;
    padding: 10px 0;
    margin-top: 0px;
    box-shadow: 0px 0px 6px #0000000A;
  }
 
  // .categorydialog_card_content p {
  //   text-align: center;
  //   font-size: 14px;
  //   line-height: 12px;
  //   letter-spacing: 0px;
  //   color: var(--text-color);
  //   text-transform: uppercase;
  // }
  .categorydialog_card_content .transfer_icon svg {
    color: var(--thm-light-blue);
  }
  .categorydialog_card_content .transfer_icon h5 {
    margin: 0;
    padding: 0;
  }
  .MuiOutlinedInput-root {
    background:var(--mainbg-color);
    background: #EBEEF2;
    border: none !important;
    border-radius: 0 !important;
  }
  .MuiOutlinedInput-root svg {
    color: var(--text-small-color);
  }
  .card_content_border {
    border-bottom: 1px solid var(--bluegrey-500);    
    padding:7px 16px 7px 5px;
  }
  .card_content_border h6 {
    font-size: calc(14px + (14 - 13) * ((100vw - 300px) / (1920 - 300)));
    line-height: 17px;
    font-weight: 500;
    text-align: left;
    letter-spacing: 0.7px;
    color: var(--text-color);
    margin: 0;
    padding: 0;
    text-transform: capitalize;    
  }
  .card_content_border span {
    text-align: left;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.6px;
    color: var(--small-text-ceolor);
  }
  .card_content_border .transfer_icon p {
    background: #a6c1d380;
    border-radius: 3px;
    color: var(--text-small-color);
    font-weight: 400;
    font-size: 16px;
    width: 26px;
    height: 18px;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(11px + (14 - 13) * ((100vw - 300px) / (1920 - 300))) !important;
  }
  .card_content_border .transfer_icon span {
    text-align: left;
    font-size: calc(12px + (12 - 11) * ((100vw - 300px) / (1920 - 300)));;
    line-height: 14px;
    letter-spacing: 0.6px;
    color: var(--small-text-ceolor);
    margin-left: 10px;
    font-weight: 400;
    white-space: nowrap;
  }
  .card_content_border .transfer_icon span span {
    font-weight: 600;
    color: var(--text-color);
  }
  .card_content_overflow {
    height: 350px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0;
  }
  .categorydialog_card_footer{
    padding: 10px 16px 0 16px;
    text-align: right;
  }
  .categorydialog_card_footer span{
    font-size:14px;
  }
  .linkvendor_right_content {
    display: block;
    float: right;
  }

`;


export const LinkItemDialog = styled(Dialog)(({ theme }) => ({
  '&.MuiDialogTitle-root': {
    padding: '10px 15px 5px',
  },
  '& .MuiDialogContent-root': {
    background: '#f6f9fc',
    paddingBottom: '50px'
  },
  '& .MuiDialog-paperWidthMd': {
    minWidth: '1200px',
  }, 
  '@media only screen and (max-width: 1363px)': {
    '& .MuiDialog-paperWidthMd': {
      minWidth: 'calc(100% - 64px)',
    },    
  },
  '& .MuiCardContent-root': {
    padding:0,
  },
  '& .MuiList-root': {
    padding:0
  },
 '& .LinkUnlinkCard .LinkCardTitle': {
  fontSize: '14px',
  fontWeight: '500',  
  color: theme.palette.text.primary,
  textTransform: 'uppercase',
  marginRight: 'auto'
 },
//  '& .LinkUnlinkCard button':{
//   color: theme.palette.grey[600],
//  }, 
 '& .LinkUnlinkCard .link-items-list': {
  height: '350px',
  minHeight: '200px',
  overflowY: 'auto',
  overflowX: 'hidden',
  position: 'relative'
 },
 '& .LinkUnlinkCard .link-items-list ul li': {
    borderBottom: 'solid 1px',
    borderColor: theme.palette.bluegrey[500],   
 },
 '& .LinkUnlinkCard .link-items-list .MuiListItemButton-root, .LinkUnlinkCard .link-items-list.selected-list li': {
    padding:'7px 16px 7px 8px',
 },
 '& .MuiListItemIcon-root': {
    minWidth: '40px',
 },
 '& .MuiCheckbox-root': {
   marginLeft: 0,
 },
 '& .link-items-list .item-name, & .MuiListItemText-primary': {
   fontSize: '1rem', 
   [theme.breakpoints.down('xl')]: {
    fontSize: '0.875rem',
  },        
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.875rem',
  },        
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem', 
  },
   fontWeight: '500',
   color: theme.palette.text.primary,
   margin: 0,
 },
 '& .link-items-list .item-code, & .MuiListItemText-secondary': {
  fontSize: '0.875rem',
  [theme.breakpoints.down('xl')]: {
    fontSize: '0.75rem', 
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '0.875rem', 
  },   
  fontWeight: '400',
  color: theme.palette.primary.main,
  margin: 0,
},
'& .link-items-list .MuiBadge-badge': {
  color: theme.palette.text.primary,
  background: 'rgba(166,193,211, 0.5)',
  borderRadius: 4,
  padding: '0 10px',
  position: 'static',
  transform: 'inherit',
},
'& .link-items-list .selected-remove-btn': {
  color: theme.palette.error.main,
},
'& .LinkUnlink-footer': {
  padding: '10px 16px',
  textAlign: 'right',
  color: theme.palette.grey[500],
  fontSize:'14px',
},
'& .LinkUnlink-footer span': {
  color: theme.palette.grey[800],
  fontWeight: '500',
},
'& .no-search': {
  minWidth:'100%',
  position: 'absolute', 
  top: '50%', 
  left: '50%', 
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',  
},
'& .no-search p': {
  color: theme.palette.text.primary,
}

}));