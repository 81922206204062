import React, { Component } from "react";
import {
  Grid,
  Checkbox,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  Tooltip,
} from "@mui/material";
import { connect } from "react-redux";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// import { removeItemFromCart } from "../../Utils/removeItemFromCart";
import { setSelectedItem } from "../../../redux/actions/itemActions";
import { IconButton } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import WebAssetOffIcon from '@mui/icons-material/WebAssetOffOutlined';
import WebAssetIcon from '@mui/icons-material/WebAssetOutlined';
// import {
//   handleItemsClick,
//   handleItemsSelectAllClick,
//   isItemSelected,
// } from "../../Utils/customSelectionMethods";
import _ from 'lodash'
import CartItem from "../../CartItem/CartItem";

class CategoryItems extends Component {
  state = {
    clickedFirstTime: true,
    checkedItems: [],
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    return <>
      <div className="card_top_bg">
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12} >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                disabled={this.props.search_disable}
                indeterminate={
                  this.props.checkedItems.length > 0 &&
                  this.props.checkedItems.length <
                  this.props.selectedItems.length
                }
                checked={
                  this.props.selectedItems.length > 0 &&
                  this.props.checkedItems.length ===
                  this.props.selectedItems.length
                }
                // onChange={this.handleItemsSelectAllClick}
                onChange={(evt) => this.props.handleItemsSelectAllClick(evt)}
              />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ShoppingBagOutlinedIcon color="primary" />
                <Typography sx={{
                  marginTop: 0.5,
                  fontWeight: 500,
                  textTransform: 'uppercase',
                  fontSize: 'calc(13px + (14 - 12) * ((100vw - 300px) / (1920 - 300)))',
                }}>  {this.props.selectedItems?.length} Items</Typography>
              </Box>

              <Box sx={{ ml: 'auto' }}>
                {this.props.checkedItems.length > 0 && (
                  <Tooltip title="Remove Item">
                    <IconButton color="error"
                      // onClick={this.removeItemFromCart}
                      disabled={this.props.search_disable}
                      onClick={() => {
                        if (!this.props.search_disable) {
                          this.props.removeItemFromCart();
                        }
                      }
                      }
                      size="small">
                      <RemoveCircleIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {(this.props.userInfo?.is_super_user === "Y" ||
                  this.props.masterPermission?.item_master === "Y" ||
                  this.props.masterPermission?.linking === "Y") && (
                    <>
                      {(this.props.prplants.length === 0 || (this.props.itemizeTB && this.props.includeTB)) &&
                        <Tooltip title="More">
                          <IconButton onClick={this.handleClick} size="small">
                            <MoreVert />
                          </IconButton>
                        </Tooltip>
                      }
                      <Menu
                        id="long-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        {((this.props.userInfo?.is_super_user === "Y" ||
                          this.props.masterPermission?.item_master === "Y") && this.props.prplants.length === 0) && (
                            <MenuItem
                              onClick={() => {
                                this.handleClose();
                                this.props.openAddItem();
                              }}
                            >
                              <AddCircleOutlinedIcon />
                              Add New Item
                            </MenuItem>
                          )}
                        {((this.props.userInfo?.is_super_user === "Y" ||
                          this.props.masterPermission?.linking === "Y") && this.props.prplants.length === 0) && (
                            <MenuItem
                              onClick={() => {
                                this.props.handleOpenLinkUnlinkItem();
                                this.handleClose();
                              }}
                            >
                              <InsertLinkIcon />
                              Link Unlink Item(s)
                            </MenuItem>
                          )}
                        {this.props.itemizeTB && (
                          <>
                            <MenuItem
                              onClick={() => {
                                this.props.updateTechnicalBidItems("disable");
                                this.handleClose();
                              }}
                            >
                              <WebAssetOffIcon />
                              Exclude TB Template
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                this.props.updateTechnicalBidItems("enable");
                                this.handleClose();
                              }}
                            >
                              <WebAssetIcon />
                              Include TB Template
                            </MenuItem>
                          </>
                        )}
                      </Menu>
                    </>
                  )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Grid container style={{ height: "420px", overflowY: "auto" }}>
        <Grid item xs={12}>
          <List disablePadding
            sx={{
              width: '100%',
              backgroundColor: 'grey.0',
            }}
          >
            {this.props.selectedItems.map((item, index) => {
              // const isItemSelected = this.isItemSelected(item);
              const checked = this.props.isItemSelected(item);

              return (
                <CartItem
                  key={item.id}
                  search_disable={this.props.search_disable}
                  tbStage={this.props.tbStageStatus[item.id]}
                  isUpdated={this.props.tbItemStatus[item.id]}
                  setIsUpdated={(newVal) => this.props.setIsUpdated(item.id, newVal)}
                  addItemTB={() => this.props.addItemTB(item)}
                  removeItemTB={() => this.props.removeItemTB(item)}
                  defaultTBValuePermission={this.props.defaultTBValuePermission}
                  isIncludedTB={this.props.TBItems.some(cv => cv.id === item.id)}
                  checked={checked}
                  item={item}
                  onClick={() => this.props.handleItemsClick(item)}
                  includeTB={this.props.includeTB}
                  itemizeTB={this.props.itemizeTB}
                  disabledtechnicalbid={this.props.disabledtechnicalbid}
                  handleAction={this.props.handleAction}
                  TBTemplateValueIntegration={this.props.TBTemplateValueIntegration}
                  pr_has_tb={this.props.pr_has_tb}
                  selectedCategory={this.props.selectedCategory}
                  sessionId={this.props.sessionId}
                />
              )
              // return (
              //     <ListItem key={index} disableGutters sx={{padding:0, borderBottom: '1px solid', borderColor: 'bluegrey.500'}}>
              //       <ListItemButton  sx={{overflow:'hidden'}} onClick={ () => handleItemsClick(this, item)}>
              //         <ListItemIcon  sx={{minWidth:30,}}>
              //           <Checkbox
              //             edge="start"                            
              //             checked={checked}                         
              //           />
              //         </ListItemIcon>
              //         <ListItemText 
              //           primary={
              //             <React.Fragment>
              //               <Tooltip title={_.unescape(item.name)} placement="bottom-start" disableInteractive>
              //               <Typography noWrap sx={{fontWeight: 500,}} >
              //                 {_.unescape(item.name)}
              //               {/* {item.name}  */}
              //               </Typography> 
              //               </Tooltip>
              //             </React.Fragment>
              //           }
              //           // secondary={ 
              //           // <React.Fragment>
              //           //   <Typography color="error" noWrap >
              //           //       {item.name}
              //           //   </Typography> 
              //           // </React.Fragment> }
              //         />
              //       </ListItemButton> 
              //     </ListItem>                
              // );
            })}
          </List>
        </Grid>
      </Grid>
    </>;
  }
}
const mapStateToProps = (state) => ({
  selectedItems: state.itemReducer.selectedItems,
  masterPermission: state.permissionsReducer.masterPermission,
  userInfo: state.userInfoReducer.userInfo,
  prplants: state.prrfqreducer.plantcodes,
  sessionId: state.sessionReducer.sessionId,
  selectedCategory: state.categoryReducer.selectedCategory,
});

export default connect(mapStateToProps, {
  setSelectedItem,
})(CategoryItems);
