import React, { useEffect, useState } from "react";
import { Stepper, Step, StepLabel, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SetPrRules from "./SetPrRules";
import SetRFQRules from "./SetRFQRules";
import SetRFQSchedule from "./SetRFQSchedule";
import SetTemplate from "./SetTemplate";
import axios from "axios";
import { getProjectList } from "../../config/apiUrl";
import { openSnackbarfunc } from "../../redux/actions/snackbarAction";
import { useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles({
  StepperWrap: {
    "& .MuiStepper-root": {
      maxWidth: "1080px",
      margin: "0 auto",
      alignItems: "start",
      textAlign: "center",
      "& .MuiStep-root": {
        "& .MuiStepLabel-root": {
          flexDirection: "column",
        },
        "& .MuiStepLabel-iconContainer": {
          paddingRight: "0",
          paddingBottom: "16px",
        },
      },
      "& .MuiStepConnector-root": {
        top: "12px",
        position: "relative",
        "&.Mui-active, &.Mui-completed": {
          "& .MuiStepConnector-line": {
            borderColor: "var(--primary-500)",
          },
        },
      },
    },
  },
});

function CreateAutoRFQ({ activeStep, steps, editTask, taskData, setTaskData, handleTaskDataChange, errors, setErrors ,submit , sessionId , setIsCreateRFQActive, setActiveStep, autoRfqPermissionUsers}) {
  const classes = useStyles();
  
  // rule state 
  const [selectedPgrValues, setSelectedPgrValues] = useState([]);
  const [selectedPorgValues, setSelectedPorgValues] = useState([]);
  const [selectedPlantValues, setSelectedPlantValues] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [PrValues, setPrValues] = useState({ min: "", max: "" });

  const [jsonTemplate, setJsonTemplate] = useState({});
  const [template, setTemplate] = useState([]);
  const [dropdown_values, setdropdown_values] = useState([]);
  const [projectList, setProjectList] = useState([]);
  
  const Permisison = useSelector((state) => state.permissionsReducer)

  const [vendorMasterPaymentTerm, setVendorMasterPaymentTerm] = useState(
    Permisison?.accountModulePermission?.["Fetch Payment Term"]?.module_activated === "N" ? false :
    editTask ? taskData?.action_document_data?.inquiry_settings?.vendor_master_payment_terms :
      Permisison?.featurePermission?.change_payment_terms?.feature_value === "Y"
  );
  const [vendorMasterIncoTerm, setVendorMasterIncoTerm] = useState(
    Permisison?.accountModulePermission?.["Fetch Inco Term"]?.module_activated === "N" ? false : 
    editTask ? taskData?.action_document_data?.inquiry_settings?.vendor_master_inco_terms :
      Permisison?.featurePermission?.change_payment_terms?.feature_value === "Y"
  );

  // const [QuotationReminderDays, setQuotationReminderDays] = useState(
  //   editTask
  //     ? taskData?.action_document_data?.inquiry_settings?.reminders?.after_inquiry_created_days
  //     // : Permisison?.featurePermission?.default_Quotation_reminder_interval_days?.feature_value === "Y"
  //     //   ? Permisison?.featurePermission?.default_Quotation_reminder_interval_days?.feature_permission_value
  //     //   : taskData?.action_document_data?.inquiry_settings?.reminders?.after_inquiry_created_days
  // )

  const [QuotationReminderDays, setQuotationReminderDays] = useState(
    editTask
      ? taskData?.action_document_data?.inquiry_settings?.reminders?.after_inquiry_created_days
      : ""
  );
  
  const [ rfqExpiryIntervalDays, setRfqExpiryIntervalDays ] = useState(
    editTask
      ? taskData?.action_document_data?.inquiry_settings?.validity_days
      : Permisison?.featurePermission?.default_RFQ_validity_days?.feature_value === "Y"
        ? Permisison?.featurePermission?.default_RFQ_validity_days?.feature_permission_value
        : taskData?.action_document_data?.inquiry_settings?.validity_days
  )

  const dispatch = useDispatch();

  const fetchProjectsOptions = async () => {
    try {

      const res = await axios.get(getProjectList);
      setProjectList(res.data.data);

    } catch (error) {
      dispatch(
        openSnackbarfunc(
          "error", 
          "Failed to fetch Projects"
        )
      );
    } 
  };

  useEffect(()=>{
    fetchProjectsOptions()
  },[])


  function getStepContent(step) {
    switch (step) {
      case 0:
        return <SetPrRules 
                  taskData={taskData} 
                  handleTaskDataChange={handleTaskDataChange} 
                  editTask={editTask} 
                  sessionId={sessionId}
                  errors={errors}
                  setErrors={setErrors}
                  selectedPgrValues={selectedPgrValues}
                  setSelectedPgrValues={setSelectedPgrValues}
                  selectedPorgValues={selectedPorgValues}
                  setSelectedPorgValues={setSelectedPorgValues}
                  selectedPlantValues={selectedPlantValues}
                  setSelectedPlantValues={setSelectedPlantValues}
                  selectedCategories={selectedCategories}
                  setSelectedCategories={setSelectedCategories}
                  PrValues={PrValues}
                  setPrValues={setPrValues}
                  autoRfqPermissionUsers={autoRfqPermissionUsers}
                />;
      case 1:
        return <SetRFQRules 
                  taskData={taskData} 
                  handleTaskDataChange={handleTaskDataChange} 
                  editTask={editTask} 
                  projectList={projectList}
                  QuotationReminderDays={QuotationReminderDays}
                  setQuotationReminderDays={setQuotationReminderDays}
                  vendorMasterPaymentTerm={vendorMasterPaymentTerm}
                  setVendorMasterPaymentTerm={setVendorMasterPaymentTerm}
                  vendorMasterIncoTerm={vendorMasterIncoTerm}
                  setVendorMasterIncoTerm={setVendorMasterIncoTerm}
                />;
      case 2:
        return <SetTemplate 
                  taskData={taskData} 
                  handleTaskDataChange={handleTaskDataChange} 
                  editTask={editTask} 
                  submit={submit}
                  sessionId={sessionId}
                  setIsCreateRFQActive={setIsCreateRFQActive}
                  setActiveStep={setActiveStep}
                  jsonTemplate={jsonTemplate}
                  setJsonTemplate={setJsonTemplate}
                  template={template}
                  setTemplate={setTemplate}
                  dropdown_values={dropdown_values}
                  setdropdown_values={setdropdown_values}
                  rfqExpiryIntervalDays={rfqExpiryIntervalDays}
                  setRfqExpiryIntervalDays={setRfqExpiryIntervalDays}
                />;
      // default:
      //   return <SetRFQSchedule />;
    }
  }

  return (
    <Box p={{ xs: 2, sm: 4 }} className={classes.StepperWrap}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box> */}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box className="StepperBody" pt={{ xs: 2, sm: 4 }}>
            {getStepContent(activeStep)}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}

export default CreateAutoRFQ;
