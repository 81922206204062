import React, { Component } from "react";
import {
  Select,
  FormControl,
  MenuItem,
  Button,
  Checkbox,
  Typography,
  InputLabel,
  TextField,
  FormHelperText,
  Backdrop,
  CircularProgress,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Stack,
  DialogContent,
  Dialog,
  Grid,
  DialogContentText,
  DialogActions,
  InputAdornment,
} from "@mui/material";
import { Box } from "@mui/system";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import {
  getDropdownOptions,
  getIdentifiersDynamicValues,
  getTermsAndConditions,
  negotiationSubmit,
  postFileSpecifications,
  HOSTMAP,
  downloadmaterialDescription,
  templateURi,
  devTemplateURi,
  sendMailDataWriter,
} from "../../../config/apiUrl";
import axios from "axios";
import { styles } from "./style.js";
import UploadIcon from "@mui/icons-material/CloudUploadRounded";
import { OpenInNew } from "@mui/icons-material";
import { openSnackbar } from "../../../redux/actions/snackbarAction";
import _, { isEmpty, values } from "lodash";
import UploadFileDialog from "../../Dialogs/Upload/Upload";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import ConfirmAlert from "../../Dialogs/ConfirmAlert.js";
import TermsAndConditions from "../../Dialogs/Upload/TermsAndConditions";
import {
  setTemplateValues,
  setTermsAndConditions,
} from "../../../redux/actions/prrfqactions";
import NegotiationFormSectionTable from "../NegotiationTemplateSectionTable/NegotiationTemplateSectionTable";
import NegotiationDetailsSection from "../NegotiationDetailsSection/NegotiationDetailsSection";
import dayjs from "dayjs";
import successimage from "../../../assets/vendxAdminPanelAsstes/assetsnew/rfq_submit.svg";
import UploadDynamicQuotation from "../../Dialogs/Upload/uploadDynamicQuotation";
import UploadDynamicView from "../../Dialogs/Upload/UploadDynamicView";
import DestinationDetailsDialog from "../../QuotationForm/DestinationDetailsDialog/DestinationDialog";
import { getSymbol } from "../../AccountTemplate/CurrencySymbol";
import NegotiationCardTemplate from "../../Card/NegotiationCardTemplate";
import DefaultTerms from "../DefaultPaymentIncoTerms/DefaultTerms";

let Date_Time_Not_Null = function (value1) {
  //return value1 && value1.isValid() ? true : false;
  //let date1 = new Date(value1);
  return typeof value1 == "object"
    ? moment(value1).isValid()
    : !isNaN(new Date(value1));
};

let Greater_Than_Date_Time = function (value1, value2) {
  let date1 = new Date(value1);
  let date2 = new Date(value2);

  return date1 > date2;
};

let Greater_Than_Current_Date_Time = function (value1) {
  let date1 = new Date(value1);
  let Today = new Date();

  return date1 > Today;
};
let prconfig = {};

let slab = {
  //has to be dynamic
  58: {
    isOpen: false,
  },
};

class NegotiationTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: "",
      template: "",
      loading: false,
      InitializeFlag: true,
      mainTemplate: {},
      slabMainTemplate: [],
      msmainTemplate: {},
      notFilteredMainTemplate: {},
      multiSolmainTemplate: [],
      errorArray: [],
      errorArrayForMS: [],
      errorArrayForSlab: [],
      solutionNames: [],
      solutionNameErrors: {},
      errorArrayForMSParentName: {},
      additionalInfoTemplate: {},
      IntlTemplate: {},
      disabledRows: {},
      shipment_mark: this.props.shipmentMark,
      MaterialDescription: false,
      specificationsOpen: false,
      qty: "",
      rate: "",
      unit: "",
      loaderArray: [],
      vendorType: "",
      vendorTerms: "",
      vendorTermsOpen: false,
      vendorTermData: {},
      title: "",
      currentFieldID: "",
      dynamicFileUploadOpen: false,
      currentFieldId: null,
      currentTemplate: null,
      categorySpecificationFiles: [],
      prevRFQLoading: false,
      prevFormLoading: false,
      randomId: null,
      usedAttributesId: [],
      confirmDialogOpen: false,
      confirmAlertMsg: "",
      confirmAlertTitle: "",
      tandcDialogOpen: false,
      termsAndConditionsData: {},
      applyToAll: {},
      multiSolApplyToAll: {},
      slabApplyToAll: {},
      slabParentApplyToAll: {},
      commonOptions: {},
      slab: {},
      slabKey: 1,
      applyToAllSlab: false,
      selectedCurrency: "",
      dataFromDetailsSection: {},
      offlineDateError: "",
      offlineAttachment: "",
      offlineFieldError: "",
      industryTypeError: "",
      offlineReferenceError: "",
      industryTypeErrorMessage: "",
      remainTemplate: {},
      SubmitSuccess: false,
      SubmitSuccessMessage: "",
      txnChecked: false,
      SubmitLoading: false,
      switchState: false,
      switchStateToggleCheck: false,
      slabChildStateToggleCheck: false,
      fileSpecificationsOpen: false,
      specification: [],
      fileSpecLoading: true,
      fileAttachmentName: "",
      attachmentLoading: false,
      attachments: {},
      solutionNameMainTemplate: {},
      isDestinationDetailsDialogOpen: false,
      selectedCurrencyValidator: false,
      selectedCurrencyErrorMessage: "",
      rearrangedArray: [],
      allItemsTemplate: [],
      intialMaintemplate: {},
      disabledProps: {},
      excelSourceName: "",
      filteredData: {},
      notFilteredSlabMainTemplate: {},
      fieldIdentifierMapping: {},
      currencyExchangeRates: {},
      isDialogShown: false,
    };
    this.setIsDestinationDetailsDialogOpen =
      this.setIsDestinationDetailsDialogOpen.bind(this);
    this.setRearrangedTemplate = this.setRearrangedTemplate.bind(this);
    this.setItemsTemplate = this.setItemsTemplate.bind(this);
    this.setServiceSourceName = this.setServiceSourceName.bind(this);
    this.currencyExchange = this.currencyExchange.bind(this);
    this.disabledProps = this.disabledProps.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this.props.JsonTemplate.ITEMIZE[0]?.map((cell, index) => {
      if (cell.VisibilityFlag === "Y") {
        if (cell.Identifier === "matspecghclfile") {
          this.setState({
            MaterialDescription: true,
          });
        }
      }
    });
    this.props.setClick(this.fetchPrevious);
    if (this.props.multipleSolutionFlag && !this.props.slabFlag) {
      const { items } = this.props;
      if (items) {
        const solutionNameMainTemplate = {};
        // Initialize solutionNameMainTemplate for all keys in items
        Object.entries(items).forEach(([key, value]) => {
          solutionNameMainTemplate[key] = value.name || "";
        });
        this.setState({ solutionNameMainTemplate });
      } else {
        console.log("items is not available at componentDidMount");
      }
    }

    this.initializeState();
    this.props.multipleSolutionFlag &&
      !this.props.slabFlag &&
      this.initializeSolutionNames();
    // this.initializeRFQDynamicState();
    // if (
    //   // this.props.TxnType == "Requote" &&
    //   this.props.multipleSolutionFlag &&
    //   this.props.slabFlag
    // ) {
    this.fetchPrevTemplate();
    // }
  }

  setSlabMainTemplate = (updatedSlabMainTemplate) => {
    this.setState({
      slabMainTemplate: updatedSlabMainTemplate,
    });
  };

  setDisabledRows = (updatedDisabledRows) => {
    this.setState({ disabledRows: updatedDisabledRows });
  };

  setDataFromDetailSection = (data) => {
    this.setState({ dataFromDetailsSection: data });
  };

  handleCurrencyChange = (event, FieldId) => {
    const { value } = event.target;

    const { additionalInfoTemplate } = this.state;
    if (additionalInfoTemplate.hasOwnProperty(FieldId)) {
      additionalInfoTemplate[FieldId]["value"] = value;
      additionalInfoTemplate[FieldId]["error"] = false;
      this.setState({
        additionalInfoTemplate,
      });
    }
    // e.target.blur();
    this.setState({
      selectedCurrency: event.target.value,
      selectedCurrencyValidator: !event.target.value, // Update validator state based on selection
    });
  };

  toggleSwitch = (key) => {
    this.setState((prevState) => ({
      switchState: {
        ...prevState.switchState,
        [key]: !prevState.switchState[key],
      },
    }));
  };

  initializeSolutionNames() {
    const { lastTransactionInThread } = this.props;
    const solutionNames = {};

    Object.entries(lastTransactionInThread).forEach(([key, item]) => {
      item.solution.forEach((solution, index) => {
        // added by sonali to add default item code :: START
        if (index == 0) {
          const { items } = this.props;
          let solNames = {};
          if (items) {
            // Initialize solNames for all keys in items
            Object.entries(items).forEach(([key, value]) => {
              solNames[key] = value.name || "";
            });
            this.setState({ solNames });
          }

          const solutionKey = `${key}-${index}`;
          if (this.props.lastTransactionTypeInThread === "Inquiry") {
            solutionNames[solutionKey] = solNames[key];
          } else {
            solutionNames[solutionKey] = solution.name || solNames[key];
          }
        } // added by sonali to add default item code :: END
        else {
          const solutionKey = `${key}-${index}`;
          solutionNames[solutionKey] = solution.name || "";
        }
      });
    });

    this.setState({ solutionNames });
  }

  //for apply to all in slab
  handleApplyToAllSlab = (evt, index, itemId) => {
    var found = this.props.selectedItems.find((element) => {
      if (this.state.slab[element.id].isOpen) {
        return element;
      }
    });
    let allSlab = this.state.mainTemplate[found.id]["slab"];
    if (allSlab.some((obj) => obj.error == true || obj.end == "")) {
      this.props.openSnackbar(
        "error",
        `Please provide a valid ${this.props?.displayOptions["label"]} for ${found.code}.`
      );
      return;
    }
    this.setState({
      applyToAllSlab: evt.target.checked,
    });
    this.props.selectedItems.forEach((item) => {
      if (this.state.slab[item.id].isOpen) {
        this.setState((prevState) => ({
          ...prevState,
          mainTemplate: {
            ...prevState.mainTemplate,
            [item.id]: {
              ...prevState.mainTemplate[item.id],
              slab: allSlab.map((obj, i) => {
                return { ...obj, id: Math.random().toString(36).slice(2) };
              }),
            },
          },
        }));
      }
    });
  };

  handleItemizeInputChange = (
    evt,
    index,
    FieldId,
    Identifier,
    ElementType,
    key
  ) => {
    const { value, type, checked } = evt.target;
    const { mainTemplate } = this.state;

    if (mainTemplate[key][FieldId]["value"] !== value) {
      this.setState({
        applyToAll: {
          ...this.state.applyToAll,
          [`${FieldId}_applyToAll`]: false,
          [`${FieldId}_applyToAll`]: false,
          [`${FieldId}_applyToAll`]: false,
        },
      });
    }
    this.setState(
      (prevState) => ({
        ...prevState,
        mainTemplate: {
          ...prevState.mainTemplate,
          [key]: {
            ...prevState.mainTemplate[key],
            [FieldId]: {
              ...prevState.mainTemplate[key][FieldId],
              value:
                Identifier == "GSTValue"
                  ? value
                  : type !== "checkbox"
                  ? //Number.isInteger(parseFloat(evt.target.value))
                    !isNaN(evt.target.value) && evt.target.value != ""
                    ? parseFloat(evt.target.value, 10)
                    : evt.target.value
                  : checked,
              error:
                type !== "checkbox"
                  ? false
                  : prevState.mainTemplate[key][FieldId].error,
            },
          },
        },
      }),
      () => {
        this.validateInput(FieldId, index);
        if (
          Identifier === "Budgetedrate" ||
          Identifier === "rate" ||
          Identifier === "CIFRate" ||
          Identifier === "Rate"
        ) {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplate: {
              ...prevState.mainTemplate,
              [key]: {
                ...prevState.mainTemplate[key],
                rate: value,
              },
            },
          }));
        } else if (Identifier === "ProductQuantity") {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplate: {
              ...prevState.mainTemplate,
              [key]: {
                ...prevState.mainTemplate[key],
                qty: value,
              },
            },
          }));
        }
      }
    );

    window.localStorage.setItem(
      "mainTemplate",
      JSON.stringify(this.state.mainTemplate)
    );
  };

  handleMSItemizeInputChange = (
    evt,
    FieldId,
    Identifier,
    FieldLabel,
    key,
    index
  ) => {
    const { value, type, checked } = evt.target;
    const { multiSolmainTemplate, mainTemplate, multiSolApplyToAll } =
      this.state;

    // Check if the field value is different from the current value
    if (multiSolmainTemplate[key][index][FieldId]?.value !== value) {
      this.setState({
        multiSolApplyToAll: {
          ...multiSolApplyToAll,
          [`${key}_${FieldId}_multiSolApplyToAll`]: false, // Set Apply to All to false for the changed field
        },
      });
    }

    // Check if the key and index are valid
    if (
      multiSolmainTemplate[key] &&
      Array.isArray(multiSolmainTemplate[key]) &&
      multiSolmainTemplate[key][index]
    ) {
      let errpr = false;

      this.setState(
        (prevState) => ({
          ...prevState,
          multiSolmainTemplate: {
            ...prevState.multiSolmainTemplate,
            [key]: prevState.multiSolmainTemplate[key].map((item, idx) => {
              if (idx === index) {
                errpr = type !== "checkbox" ? false : item[FieldId].error;
              }
              return idx === index
                ? {
                    ...item,
                    [FieldId]: {
                      ...item[FieldId],
                      value:
                        Identifier === "GSTValue"
                          ? value
                          : type !== "checkbox"
                          ? Number.isInteger(parseFloat(value))
                            ? parseInt(value, 10)
                            : value
                          : checked,
                      error: type !== "checkbox" ? false : item[FieldId].error,
                    },
                    ...(Identifier === "Budgetedrate" ||
                    Identifier === "rate" ||
                    Identifier === "CIFRate" ||
                    Identifier === "Rate"
                      ? { rate: value }
                      : Identifier === "ProductQuantity"
                      ? { qty: value }
                      : {}),
                  }
                : item;
            }),
          },
          ...(index === 0 &&
            multiSolmainTemplate[key].length > 1 && {
              mainTemplate: {
                ...prevState.mainTemplate,
                [key]: {
                  ...prevState.mainTemplate[key],
                  [FieldId]: {
                    ...prevState.mainTemplate[key][FieldId],
                    value: value,
                  },
                  // Add this line to update rate in mainTemplate
                  ...(Identifier === "Budgetedrate" ||
                  Identifier === "rate" ||
                  Identifier === "CIFRate" ||
                  Identifier === "Rate"
                    ? { rate: value }
                    : {}),
                },
              },
            }),
        }),
        () => {
          this.validateInputForMultiSol(FieldId, key, index, true);
        }
      );

      window.localStorage.setItem(
        "multiSolmainTemplate",
        JSON.stringify(this.state.multiSolmainTemplate)
      );
    }
  };

  handleSlabItemizeInputChange = (
    evt,
    FieldId,
    Identifier,
    FieldLabel,
    key,
    index
  ) => {
    const { value, type, checked } = evt.target;
    const { slabMainTemplate, slabApplyToAll } = this.state;

    // Check if the field value is different from the current value
    if (slabMainTemplate[key][index][FieldId]?.value !== value) {
      this.setState({
        slabApplyToAll: {
          ...slabApplyToAll,
          [`${key}_${FieldId}_slabApplyToAll`]: false, // Set Apply to All to false for the changed field
        },
      });
    }
    // Check if the key and index are valid
    if (
      slabMainTemplate[key] &&
      Array.isArray(slabMainTemplate[key]) &&
      slabMainTemplate[key][index]
    ) {
      this.setState(
        (prevState) => {
          const updatedData = prevState.slabMainTemplate[key].map(
            (item, idx) => {
              if (idx === index) {
                return {
                  ...item,
                  [FieldId]: {
                    ...item[FieldId],
                    value:
                      Identifier === "GSTValue"
                        ? value
                        : type !== "checkbox"
                        ? Number.isInteger(parseFloat(value))
                          ? parseInt(value, 10)
                          : value
                        : checked,
                    error: type !== "checkbox" ? false : item[FieldId].error,
                  },
                  ...(Identifier === "Budgetedrate" ||
                  Identifier === "rate" ||
                  Identifier === "CIFRate" ||
                  Identifier === "Rate"
                    ? { rate: value }
                    : Identifier === "ProductQuantity"
                    ? { qty: value }
                    : {}),
                };
              }
              return item;
            }
          );

          // Preserve the selected key
          updatedData.selected = prevState.slabMainTemplate[key].selected;

          return {
            ...prevState,
            slabMainTemplate: {
              ...prevState.slabMainTemplate,
              [key]: updatedData,
            },
          };
        },
        () => {
          this.validateInputForSlab(FieldId, key, index, true);
          window.localStorage.setItem(
            "slabMainTemplate",
            JSON.stringify(this.state.slabMainTemplate)
          );
        }
      );
    }
  };

  handleWithOutSlabInputChange = (
    evt,
    FieldId,
    Identifier,
    FieldLabel,
    key,
    index
  ) => {
    const { value, type, checked } = evt.target;
    const { slabMainTemplate, slabParentApplyToAll } = this.state;
    // Check if the field value is different from the current value
    if (
      slabMainTemplate[key] &&
      slabMainTemplate[key][0]?._range === undefined &&
      slabMainTemplate[key][0][FieldId]?.value !== value
    ) {
      this.setState({
        slabParentApplyToAll: {
          ...slabParentApplyToAll,
          [`${FieldId}_slabParentApplyToAll`]: false, // Set Apply to All to false for the changed field
        },
      });
    }

    this.setState(
      (prevState) => {
        const slabMainTemplate = {
          ...prevState.slabMainTemplate,
        };

        if (
          slabMainTemplate[key] &&
          slabMainTemplate[key][0]?._range === undefined
        ) {
          slabMainTemplate[key] = slabMainTemplate[key].map((item, idx) => {
            if (idx === 0) {
              return {
                ...item,
                [FieldId]: {
                  ...item[FieldId],
                  value:
                    Identifier === "GSTValue"
                      ? value
                      : type !== "checkbox"
                      ? Number.isInteger(parseFloat(value))
                        ? parseInt(value, 10)
                        : value
                      : checked,
                  error: type !== "checkbox" ? false : item[FieldId].error,
                },
                ...(Identifier === "Budgetedrate" ||
                Identifier === "rate" ||
                Identifier === "CIFRate" ||
                Identifier === "Rate"
                  ? { rate: value }
                  : Identifier === "ProductQuantity"
                  ? { qty: value }
                  : {}),
              };
            }
            return item;
          });

          // Preserve the selected key
          slabMainTemplate[key].selected =
            prevState.slabMainTemplate[key].selected;

          window.localStorage.setItem(
            "slabMainTemplate",
            JSON.stringify(slabMainTemplate)
          );

          return { slabMainTemplate };
        }

        return null;
      },
      () => {
        // Validation after state update
        this.validateInputForSlab(FieldId, key, 0, true);
      }
    );
  };

  extractAndFormat = (inputString) => {
    const pattern = /(\d+)\.\w\.\((f\d+)\)/g;
    const seen = new Set(); // Set to keep track of seen values
    let matches;
    const results = [];

    while ((matches = pattern.exec(inputString)) !== null) {
      const value = `${matches[1]}.${matches[2]}`;
      if (!seen.has(value)) {
        // Check if value is not already in the set
        results.push(value);
        seen.add(value); // Add value to the set
      }
    }

    return results;
  };

  handleFileSpecificationsOpen = (value, name) => {
    this.setState({ fileSpecificationsOpen: true });
    this.setState({ fileAttachmentName: name });
    this.getExistingFiles(value);
  };

  handleUploadClose = () => {
    this.setState({
      fileSpecificationsOpen: false,
      specification: [],
      fileSpecLoading: true,
    });
  };

  getExistingFiles(file_id) {
    const queryString = file_id
      .map((file) => `file_ids[]=${file.id}`)
      .join("&");
    const url = `${postFileSpecifications}?${queryString}`;

    axios
      .get(url)
      .then((res) => {
        this.setState({ specification: res.data });
        this.setState({ fileSpecLoading: false });
      })
      .catch((err) => {
        console.log(err, " downloadFile error");
        this.props.openSnackbar(
          "error",
          err.response.data?.message
            ? err.response.data?.message
            : "Something went wrong!"
        );
        this.setState({ fileSpecLoading: false });
      });
  }

  getExistingFilesForAttachment = (file_id) => {
    this.setState({ attachmentLoading: true });
    const queryString = file_id
      .map((file) => `file_ids[]=${file.id}`)
      .join("&");
    const url = `${postFileSpecifications}?${queryString}`;

    axios
      .get(url)
      .then((res) => {
        this.setState({ attachments: res.data.data });
        this.setState({
          dynamicFileUploadOpen: true,
          attachmentLoading: false,
        });
      })
      .catch((err) => {
        console.log(err, " downloadFile error");
        this.props.openSnackbar(
          "error",
          err.response.data?.message
            ? err.response.data?.message
            : "Something went wrong!"
        );
        this.setState({ attachmentLoading: false });
      });
  };
  isValidJsonString = (str) => {
    try {
      const parsed = JSON.parse(str);

      return typeof parsed === "object" && parsed !== null;
    } catch (e) {
      return false;
    }
  };
  finalFormulaFunction = (
    formula,
    placeholders,
    // basicPriceRate,
    fieldId,
    key,
    value,
    rowdata,
    fromRD = false
  ) => {
    let mainData = this.state.mainTemplate[key];
    placeholders.forEach((placeholder) => {
      const matchId = placeholder.slice(2, -1);
      let matchValue =
        (mainData && mainData[matchId] && mainData[matchId].value) || undefined;
      if (matchValue === undefined) {
        let fieldCell =
          // this.props.JsonTemplate &&
          // this.props.JsonTemplate.ITEMIZE &&
          // this.props.JsonTemplate.ITEMIZE[0] && //Refactor
          // this.props.JsonTemplate.ITEMIZE[0][fieldId] &&
          // this.props.JsonTemplate.ITEMIZE[0][fieldId].find(
          //   (cell) => cell.FieldId === matchId
          // );
          // if (fieldCell) {
          //   matchValue = "(" + fieldCell.DefaultValue.Formula + ")";
          //   let TemperoraryPlaceholders = "";
          //   if (
          //     fieldCell.DefaultValue.Formula &&
          //     fieldCell.DefaultValue.Formula !== ""
          //   ) {
          //     TemperoraryPlaceholders =
          //       fieldCell.DefaultValue.Formula.match(/\(f\d+\)/g);
          //   } else if (
          //     fieldCell.DefaultFormula &&
          //     fieldCell.DefaultFormula !== ""
          //   ) {
          //     TemperoraryPlaceholders =
          //       fieldCell.DefaultFormula.match(/\(f\d+\)/g);
          //   }
          //   if (TemperoraryPlaceholders != "") {
          //     let data_hash = this.finalFormulaFunction(
          //       fieldCell.DefaultValue.Formula,
          //       TemperoraryPlaceholders,
          //       // basicPriceRate,
          //       matchId,
          //       key,
          //       0,
          //       rowdata,
          //       fromRD
          //     );
          //     if (data_hash.eval) {
          //       matchValue = eval(data_hash.formula);
          //     } else {
          //       matchValue = data_hash.value;
          //     }
          //   } else {
          //     matchValue = fieldCell.DefaultValue || 0;
          //   }
          // } else {
          (matchValue =
            (this.state.additionalInfoTemplate &&
              this.state.additionalInfoTemplate[matchId] &&
              this.state.additionalInfoTemplate[matchId].value) ||
            0);
        if (
          this.state.additionalInfoTemplate &&
          this.state.additionalInfoTemplate[matchId] &&
          this.state.additionalInfoTemplate[matchId].Identifier === "GSTType"
        ) {
          matchValue = "'" + matchValue + "'";
        }

        if (
          this.state.additionalInfoTemplate &&
          this.state.additionalInfoTemplate[matchId] &&
          this.state.additionalInfoTemplate[matchId].Identifier === "GSTValue"
        ) {
          matchValue = "'" + (matchValue - 0.0) + "'";
        }
        fieldCell = this.state.additionalInfoTemplate[0]?.find(
          (cell) => cell.FieldId === matchId
        );
        // }
      }
      // if (matchValue === "") {
      //   matchValue = '';
      // }
      if (matchValue !== undefined) {
        // If value is defined, update the result
        if (
          mainData &&
          mainData[matchId] &&
          mainData[matchId].Identifier === "GSTType"
        ) {
          matchValue = "'" + matchValue + "'";
        }
        if (
          mainData &&
          mainData[matchId] &&
          mainData[matchId].Identifier === "GSTValue"
        ) {
          matchValue = "'" + (matchValue - 0.0) + "'";
        }
        // "Identifier": "GSTValue",
        // if (basicPriceRate.FieldId === fieldId) {
        //   value = matchValue || 0;
        //   return { formula: formula, value: value, eval: 0 };
        // } else {
        formula = formula.replace(placeholder, matchValue);
        // }
      }
    });
    return { formula: formula, value: value, eval: 1 };
  };

  fetchPrevious = () => {
    const { JsonTemplate, lastTransactionData } = this.props;
    if (JsonTemplate?.ITEMIZE?.length && lastTransactionData) {
      this.setState({
        prevFormLoading: true,
      });
      let notFilteredMainTemplate = {};
      let notFilteredSlabMainTemplate = {};

      let switchStateManage = {};
      let switchStateToggleManage = {};
      let slabChildStateToggleManage = {};
      let mainTemplate = _.cloneDeep(this.state.mainTemplate);
      let multiSolmainTemplate = _.cloneDeep(this.state.mainTemplate);
      let slabMainTemplate = _.cloneDeep(this.state.mainTemplate);
      let remainTemplate = _.cloneDeep(this.state.remainTemplate);
      let additionalInfoTemplate;
      if (this.props.vendorType == "Local") {
        additionalInfoTemplate = this.initializeLocalRecipientsState();
      } else {
        additionalInfoTemplate = this.initializeIntlRecipientsState();
      }

      /**
       * old code in case issue is there open this and commit
       */
      /*For Main Template */
      for (const key in mainTemplate) {
        const currentItem = mainTemplate[key];

        for (const key2 in currentItem) {
          const currentField = currentItem[key2];
          if (
            currentField?.Identifier === "GSTType" &&
            currentField?.["DefaultFormula"] == "" &&
            this.props.negotiationJsonData.vendors[0]?.gst_type
          ) {
            if (
              !this.props.negotiationJsonData.vendors[0]?.gst_type.includes(
                "###"
              )
            ) {
              let substring =
                this.props.negotiationJsonData.vendors[0]?.gst_type.split(
                  "_"
                )[0];

              const filteredData = currentField.options.find(
                (item) =>
                  item.code.includes(substring) && !item.code.includes("UT")
              );
              currentField.value = filteredData?.code;
            } else if (
              this.props.negotiationJsonData.vendors[0]?.gst_type.includes(
                "###"
              )
            ) {
              let optionArray = [];
              let subArray =
                this.props.negotiationJsonData.vendors[0]?.gst_type.split(
                  "###"
                );
              subArray.map((element) => {
                const filteredData = currentField.options.find((item) => {
                  let substring = element.split("_")[0];
                  return (
                    item.code.includes(substring) && !item.code.includes("UT")
                  );
                });
                optionArray.push(filteredData?.code);
              });
              currentField.value = optionArray.join("###");
            }
          }
          // const defaultFormula = currentField?.["DefaultFormula"];
          if (currentField && currentField.hasOwnProperty("DefaultFormula")) {
            const defaultFormula = currentField["DefaultFormula"];
            if (defaultFormula) {
              const formattedResult = this.extractAndFormat(defaultFormula);

              if (formattedResult && formattedResult.length >= 1) {
                const valuesFound = formattedResult
                  .map((identifier, index) => {
                    const attributeKey = identifier.split(".");
                    const subStringAttrKey = attributeKey[1].substring(1);
                    // Check if the solution array exists and has the identifier
                    const valuesInSolution = lastTransactionData.itemize[
                      key
                    ]?.solution
                      ?.map((obj) => obj?.data[0]?.[subStringAttrKey])
                      ?.filter((value) => value !== undefined);
                    // Fallback to checking in common if valuesInSolution is empty or undefined
                    const fallbackValue =
                      lastTransactionData.common?.[subStringAttrKey];
                    const fetchTxn = this.props.fetchedTxn?.[
                      attributeKey[0]
                    ]?.itemize[key]?.solution
                      ?.map((obj) => obj?.data[0]?.[subStringAttrKey])
                      ?.filter((value) => value !== undefined);

                    const commonInquiryData =
                      this.props.fetchedTxn?.[1].common?.[subStringAttrKey];

                    if (valuesInSolution && valuesInSolution.length > 0) {
                      return valuesInSolution;
                    } else if (
                      valuesInSolution &&
                      valuesInSolution.length == 0 &&
                      fetchTxn &&
                      fetchTxn.length > 0
                    ) {
                      return fetchTxn;
                    } else if (fallbackValue !== undefined) {
                      return [fallbackValue];
                    } else if (
                      fallbackValue === undefined &&
                      commonInquiryData !== undefined
                    ) {
                      return [commonInquiryData];
                    } else {
                      return [];
                    }
                  })
                  .flat();

                let finalValue = null;
                // Evaluate conditional statements in DefaultFormula
                for (let i = 0; i < valuesFound.length; i++) {
                  // if (valuesFound[i] !== null) {
                  finalValue = valuesFound[i];
                  break;
                  // }
                }
                if (finalValue !== null) {
                  const integerPattern = /^\d+$/;
                  currentItem[key2]["value"] =
                    integerPattern.test(finalValue) &&
                    currentItem[key2].Identifier != "GSTValue"
                      ? parseInt(finalValue, 10)
                      : finalValue;
                  // currentItem[key2]["value"] = finalValue;

                  currentItem[key2]["error"] = false;

                  if (currentItem[key2]["Identifier"] === "ProductQuantity") {
                    currentItem["qty"] = finalValue;
                  }

                  if (
                    currentItem[key2]["Identifier"] === "EstimatedCost" ||
                    currentItem[key2]["Identifier"] ===
                      "EstimatedCostForeign" ||
                    currentItem[key2]["Identifier"] === "Budgetedrate" ||
                    currentItem[key2]["Identifier"] === "Rate" ||
                    currentItem[key2]["Identifier"] === "CIFRate" ||
                    currentItem[key2]["Identifier"] === "rate"
                  ) {
                    currentItem["rate"] = finalValue;
                  }

                  const attributeName = currentItem[key2]["HTMLAttributeName"];
                  const index1 = this.state.errorArray.indexOf(attributeName);

                  if (index1 !== -1) {
                    this.setState((prevState) => ({
                      errorArray: prevState.errorArray.filter(
                        (item, index) => index !== index1
                      ),
                    }));
                  }
                }
              }
            }
          } else {
            // console
            //   .log
            //   // "Skipping currentField because DefaultFormula is undefined."
            //   ("currentFieldValue", currentField);
          }
        }
      }

      /*For Multi solution */
      for (const key in multiSolmainTemplate) {
        const currentItem = multiSolmainTemplate[key];

        // Get the solutions array from lastTransactionData
        const solutions = lastTransactionData.itemize[key]?.solution || [];
        let newItemArray = [];

        if (solutions.length > 1) {
          switchStateToggleManage[key] = true;
          switchStateManage[key] = true;
        } else {
          switchStateManage[key] = false;
        }

        solutions.forEach((solution, solutionIndex) => {
          let newItem = {};

          // Initialize newItem with all fields from currentItem
          for (const key2 in currentItem) {
            newItem[key2] = currentItem[key2];
          }

          for (const key2 in currentItem) {
            const currentField = currentItem[key2];

            // Check if the current field is GSTType with an empty DefaultFormula
            if (
              currentField?.Identifier === "GSTType" &&
              currentField?.["DefaultFormula"] === ""
            ) {
              if (this.props.negotiationJsonData.vendors[0]?.gst_type) {
                let substring =
                  this.props.negotiationJsonData.vendors[0]?.gst_type.split(
                    "_"
                  )[0];
                const filteredData = currentField.options.find(
                  (item) =>
                    item.code.includes(substring) && !item.code.includes("UT")
                );
                newItem[key2] = { ...currentField, value: filteredData?.code };
              }
            }
            const defaultFormula = currentField?.["DefaultFormula"];
            if (currentField && currentField.hasOwnProperty("DefaultFormula")) {
              if (defaultFormula) {
                const formattedResult = this.extractAndFormat(defaultFormula);

                if (formattedResult && formattedResult.length >= 1) {
                  const valuesFound = formattedResult
                    .map((identifier) => {
                      const attributeKey = identifier.split(".");
                      const subStringAttrKey = attributeKey[1].substring(1);
                      const valuesInSolution =
                        solution.data[0]?.[subStringAttrKey];

                      const fallbackValue =
                        lastTransactionData.common?.[subStringAttrKey];
                      const fetchTxn = this.props.fetchedTxn?.[
                        attributeKey[0]
                      ]?.itemize[key]?.solution
                        ?.map((obj) => obj?.data[0]?.[subStringAttrKey])
                        ?.filter((value) => value !== undefined);
                      const commonInquiryData =
                        this.props.fetchedTxn?.[1].common?.[subStringAttrKey];

                      if (valuesInSolution !== undefined) {
                        return valuesInSolution;
                      } else if (fetchTxn && fetchTxn.length > 0) {
                        return fetchTxn[0];
                      } else if (fallbackValue !== undefined) {
                        return fallbackValue;
                      } else if (
                        fallbackValue === undefined &&
                        commonInquiryData !== undefined
                      ) {
                        return commonInquiryData;
                      } else {
                        return null;
                      }
                    })
                    .filter((value) => value !== null);

                  let finalValue =
                    valuesFound.length > 0 ? valuesFound[0] : null;

                  if (finalValue !== null) {
                    const integerPattern = /^\d+$/;
                    newItem[key2] = {
                      ...currentField,
                      value:
                        integerPattern.test(finalValue) &&
                        currentField.Identifier !== "GSTValue"
                          ? parseInt(finalValue, 10)
                          : finalValue,
                      error: false,
                    };

                    if (currentField.Identifier === "ProductQuantity") {
                      newItem["qty"] = finalValue;
                    }

                    if (
                      currentField.Identifier === "EstimatedCost" ||
                      currentField.Identifier === "EstimatedCostForeign" ||
                      currentField.Identifier === "Budgetedrate" ||
                      currentField.Identifier === "Rate" ||
                      currentField.Identifier === "CIFRate" ||
                      currentField.Identifier === "rate"
                    ) {
                      newItem["rate"] = finalValue;
                    }

                    const attributeName = currentField["HTMLAttributeName"];
                  }
                }
              }
            }
          }

          newItemArray.push(newItem);
        });
        multiSolmainTemplate[key] = newItemArray;
      }

      for (const key in slabMainTemplate) {
        const currentItem = slabMainTemplate[key];
        const solutions =
          lastTransactionData.itemize[key]?.solution[0].data || [];
        let newItemArray = [];
        solutions.forEach((solution, solutionIndex) => {
          let newItem = {};

          // Initialize newItem with all fields from currentItem
          for (const key2 in currentItem) {
            const currentField = currentItem[key2];

            // Check if the field should be directly assigned or copied as an object
            if (
              key2 === "qty" ||
              key2 === "specification" ||
              key2 === "added_after_rfq_created" ||
              key2 === "is_deleted_from_master" ||
              key2 === "is_unlink_from_category" ||
              key2 === "selected" ||
              key2 === "formdetailRowId" ||
              key2 === "_range" ||
              key2 === "select_slab" ||
              key2 === "rate"
            ) {
              // Direct assignment without wrapping in an object
              newItem[key2] = currentField;
            } else {
              // Copy the object for other fields
              newItem[key2] = { ...currentField };
            }
          }

          // Ensure slabChildStateToggleManage[key] is initialized as an array
          if (!slabChildStateToggleManage[key]) {
            slabChildStateToggleManage[key] = []; // Initialize as an array
          }

          // Make sure the array has a slot for solutionIndex
          if (!slabChildStateToggleManage[key][solutionIndex]) {
            slabChildStateToggleManage[key][solutionIndex] = false; // Initialize the slot
          }

          // Ensure _range and select_slab are copied from the solution
          newItem["_range"] = solution["_range"];
          newItem["select_slab"] = solution["select_slab"];

          // Update the flag for this key and solutionIndex if "select_slab" is "Y"
          if (solution["select_slab"] === "Y") {
            slabChildStateToggleManage[key][solutionIndex] = true; // Mark this key's solution as having "select_slab: Y"
          } else {
            slabChildStateToggleManage[key][solutionIndex] = false; // Mark as false otherwise
          }

          for (const key2 in currentItem) {
            const currentField = currentItem[key2];

            if (currentField && currentField.hasOwnProperty("DefaultFormula")) {
              // Handle GSTType with an empty DefaultFormula
              if (
                currentField.Identifier === "GSTType" &&
                currentField["DefaultFormula"] === ""
              ) {
                const gstType =
                  this.props.negotiationJsonData.vendors[0]?.gst_type;

                if (gstType) {
                  if (!gstType.includes("###")) {
                    // Case where GST type does not include "###"
                    let substring = gstType.split("_")[0];
                    const filteredData = currentField.options.find(
                      (item) =>
                        item.code.includes(substring) &&
                        !item.code.includes("UT")
                    );
                    newItem[key2] = {
                      ...currentField,
                      value: filteredData?.code,
                    };
                  } else {
                    // Case where GST type includes "###"
                    let optionArray = [];
                    let subArray = gstType.split("###");
                    subArray.forEach((element) => {
                      let substring = element.split("_")[0];
                      const filteredData = currentField.options.find(
                        (item) =>
                          item.code.includes(substring) &&
                          !item.code.includes("UT")
                      );
                      optionArray.push(filteredData?.code);
                    });
                    newItem[key2]["value"] = optionArray.join("###");
                  }
                }
              }

              const defaultFormula = currentField["DefaultFormula"];
              if (defaultFormula) {
                const formattedResult = this.extractAndFormat(defaultFormula);

                if (formattedResult && formattedResult.length >= 1) {
                  const valuesFound = formattedResult
                    .map((identifier) => {
                      const attributeKey = identifier.split(".");
                      const subStringAttrKey = attributeKey[1].substring(1);
                      const valuesInSolution = solution[subStringAttrKey];
                      const fallbackValue =
                        lastTransactionData.common?.[subStringAttrKey];
                      let fetchTxn;
                      if (newItem["select_slab"] === "N") {
                        fetchTxn = this.props.fetchedTxn?.[1]?.itemize[
                          key
                        ]?.solution[0].data
                          ?.map(
                            (obj) =>
                              obj[
                                this.state.fieldIdentifierMapping?.[
                                  currentField.Identifier
                                ]
                              ]
                          )
                          ?.filter((value) => value !== undefined);
                      } else {
                        fetchTxn = this.props.fetchedTxn?.[
                          attributeKey[0]
                        ]?.itemize[key]?.solution[0].data
                          ?.map((obj) => obj[subStringAttrKey])
                          ?.filter((value) => value !== undefined);
                      }
                      const commonInquiryData =
                        this.props.fetchedTxn?.[1].common?.[subStringAttrKey];
                      if (valuesInSolution !== undefined) {
                        return valuesInSolution;
                      } else if (fetchTxn && fetchTxn.length > 0) {
                        return fetchTxn[solutionIndex];
                      } else if (fallbackValue !== undefined) {
                        return fallbackValue;
                      } else if (
                        fallbackValue === undefined &&
                        commonInquiryData !== undefined
                      ) {
                        return commonInquiryData;
                      } else {
                        return null;
                      }
                    })
                    .filter((value) => value !== null);

                  let finalValue =
                    valuesFound.length > 0 ? valuesFound[0] : null;

                  if (finalValue !== null) {
                    const integerPattern = /^\d+$/;
                    newItem[key2] = {
                      ...currentField,
                      value:
                        integerPattern.test(finalValue) &&
                        currentField.Identifier !== "GSTValue"
                          ? parseInt(finalValue, 10)
                          : finalValue,
                      error: false,
                    };

                    if (currentField.Identifier === "ProductQuantity") {
                      newItem["qty"] = finalValue;
                    }

                    if (
                      currentField.Identifier === "EstimatedCost" ||
                      currentField.Identifier === "EstimatedCostForeign" ||
                      currentField.Identifier === "Budgetedrate" ||
                      currentField.Identifier === "Rate" ||
                      currentField.Identifier === "CIFRate" ||
                      currentField.Identifier === "rate"
                    ) {
                      newItem["rate"] = finalValue;
                    }
                  }
                }
              }
            }
          }
          newItemArray.push(newItem);
        });
        slabMainTemplate[key] = newItemArray;
        slabMainTemplate[key].selected = currentItem?.selected;
      }

      Object.entries(additionalInfoTemplate).forEach(([key, currentField]) => {
        if (
          currentField.Identifier === "GSTType" &&
          currentField?.["DefaultFormula"] == "" &&
          this.props.negotiationJsonData.vendors[0]?.gst_type
        ) {
          if (
            !this.props.negotiationJsonData.vendors[0]?.gst_type?.includes(
              "###"
            )
          ) {
            let substring =
              this.props.negotiationJsonData.vendors[0]?.gst_type.split("_")[0];
            const filteredData = currentField.options.find(
              (item) =>
                item.code.includes(substring) && !item.code.includes("UT")
            );
            currentField.value = filteredData?.code;
          } else if (
            this.props.negotiationJsonData.vendors[0]?.gst_type.includes("###")
          ) {
            {
              let optionArray = [];
              let subArray =
                this.props.negotiationJsonData.vendors[0]?.gst_type.split(
                  "###"
                );
              subArray.map((element) => {
                const filteredData = currentField.options.find((item) => {
                  let substring = element.split("_")[0];
                  return (
                    item.code.includes(substring) && !item.code.includes("UT")
                  );
                });
                optionArray.push(filteredData?.code);
              });
              currentField.value = optionArray.join("###");
            }
          }
        }
        const attributeNameWithoutF = currentField.HTMLAttributeName
          ? currentField.HTMLAttributeName.replace("f", "")
          : null;
        if (
          attributeNameWithoutF &&
          lastTransactionData.common.hasOwnProperty(attributeNameWithoutF)
        ) {
          // Set the value to the currentField
          currentField.value =
            lastTransactionData.common[attributeNameWithoutF];
          currentField.error = false;

          // Remove field from errorArray if present
          const index1 = this.state.errorArray.indexOf(attributeNameWithoutF);
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }

          // Set value to corresponding HTML element if it exists
          const element = document.getElementsByName(attributeNameWithoutF)[0];
          if (element) {
            element.value = currentField.value;
          }
        } else if (
          currentField.DefaultFormula &&
          currentField.DefaultFormula.trim() != ""
        ) {
          // Extract attribute codes from DefaultFormula
          const attributeCodes =
            currentField.DefaultFormula.match(/f\d+/g) || [];
          const formattedResult = this.extractAndFormat(
            currentField.DefaultFormula
          );
          // Iterate over each attribute code
          formattedResult.forEach((attributeCode) => {
            // Extract attribute key from attributeCode
            const attributeKey = attributeCode.split(".");
            const subStringAttrKey = attributeKey[1].substring(1);
            // Check if the attribute key exists in lastTransactionData.common
            if (lastTransactionData.common.hasOwnProperty(subStringAttrKey)) {
              // Set the value to the currentField
              if (
                this.isValidJsonString(
                  lastTransactionData.common[subStringAttrKey]
                ) &&
                lastTransactionData.common[subStringAttrKey] &&
                currentField.ElementType !== "F"
              ) {
                let element = JSON.parse(
                  lastTransactionData.common[subStringAttrKey]
                )[this.props.negotiationJsonData.vendors[0].id];

                currentField.value =
                  this.props.companyInfo?.default_dropdown_type == 10
                    ? `${element.element_name}-${element.element_code}`
                    : `${element.element_code}-${element.element_name}`;
              } else {
                currentField.value =
                  lastTransactionData.common[subStringAttrKey];
              }
              currentField.error = false;
              // Remove field from errorArray if present
              const index2 = this.state.errorArray.indexOf(
                currentField.HTMLAttributeName
              );
              if (index2 !== -1) {
                this.state.errorArray.splice(index2, 1);
              }

              // Set value to corresponding HTML element if it exists
              const element = document.getElementsByName(
                currentField.HTMLAttributeName
              )[0];
              if (element) {
                element.value = currentField.value;
              }
            } else if (
              this.props.fetchedTxn?.[attributeKey[0]]?.common.hasOwnProperty(
                subStringAttrKey
              )
            ) {
              if (
                this.isValidJsonString(
                  this.props.fetchedTxn?.[attributeKey[0]]?.common[
                    subStringAttrKey
                  ]
                ) &&
                this.props.fetchedTxn?.[attributeKey[0]]?.common[
                  subStringAttrKey
                ] &&
                currentField.ElementType !== "F"
              ) {
                let element = JSON.parse(
                  this.props.fetchedTxn?.[attributeKey[0]]?.common[
                    subStringAttrKey
                  ]
                )[this.props.negotiationJsonData.vendors[0].id];

                currentField.value =
                  this.props.companyInfo?.default_dropdown_type == 10
                    ? `${element.element_name}-${element.element_code}`
                    : `${element.element_code}-${element.element_name}`;
              } else {
                currentField.value =
                  this.props.fetchedTxn?.[attributeKey[0]]?.common[
                    subStringAttrKey
                  ];
                if (
                  currentField.VisibilityFlag === "Y" &&
                  currentField.Identifier === "Currency"
                ) {
                  this.setState({
                    selectedCurrency: currentField.value,
                  });
                }
              }
              currentField.error = false;

              const index2 = this.state.errorArray.indexOf(
                currentField.HTMLAttributeName
              );
              if (index2 !== -1) {
                this.state.errorArray.splice(index2, 1);
              }

              const element = document.getElementsByName(
                currentField.HTMLAttributeName
              )[0];
              if (element) {
                element.value = currentField.value;
              }
            }
          });
        } else if (
          currentField.DefaultFormula == "" &&
          currentField.DefaultValue.length > 0 &&
          currentField.ElementType == "T"
        ) {
          currentField.value = currentField.DefaultValue;
        }
      });
      // Filter out items not present in inquiry_item_ids
      if (this.props.itemSpecific === true) {
        const filteredMainTemplate = Object.fromEntries(
          Object.entries(mainTemplate).filter(([key]) =>
            this.props.inquiryItemsId.includes(parseInt(key))
          )
        );
        const NotfilteredMainTemplate = Object.fromEntries(
          Object.entries(mainTemplate)
            .filter(
              ([key]) => !this.props.inquiryItemsId.includes(parseInt(key))
            )
            .map(([key, value]) => {
              return [key, { ...value, selected: "N" }];
            })
        );
        mainTemplate = filteredMainTemplate;
        notFilteredMainTemplate = NotfilteredMainTemplate;
      }

      if (
        this.props.itemSpecific === true &&
        this.props.multipleSolutionFlag === true &&
        !this.props.slabFlag
      ) {
        const filteredMSmainTemplate = Object.fromEntries(
          Object.entries(multiSolmainTemplate).filter(([key]) =>
            this.props.inquiryItemsId.includes(parseInt(key))
          )
        );
        multiSolmainTemplate = filteredMSmainTemplate;
      }

      if (
        this.props.itemSpecific === true &&
        this.props.multipleSolutionFlag === true &&
        this.props.slabFlag === true
      ) {
        const NotfilteredSlabMainTemplate = Object.fromEntries(
          Object.entries(slabMainTemplate)
            .filter(
              ([key]) => !this.props.inquiryItemsId.includes(parseInt(key))
            )
            .map(([key, value]) => {
              // Update 'selected' and 'select_slab' properties
              value.selected = "N";
              value.map((val) => {
                val.select_slab = "N";
                val.selected = "N";
                return val;
              });

              // Return the updated key-value pair
              return [key, value];
            })
        );
        notFilteredSlabMainTemplate = NotfilteredSlabMainTemplate;
        const filteredSlabmainTemplate = Object.fromEntries(
          Object.entries(slabMainTemplate).filter(([key]) =>
            this.props.inquiryItemsId.includes(parseInt(key))
          )
        );
        slabMainTemplate = filteredSlabmainTemplate;
      }

      this.setState({
        remainTemplate,
        switchState: switchStateManage,
        switchStateToggleCheck: switchStateToggleManage,
        slabChildStateToggleCheck: slabChildStateToggleManage,
        multiSolmainTemplate,
        slabMainTemplate,
        mainTemplate,
        intialMaintemplate: mainTemplate,
        additionalInfoTemplate,
        notFilteredMainTemplate,
        notFilteredSlabMainTemplate,
        prevFormLoading: false, // Assuming loading is finished after updating the state
      });
    }
  };

  fetchMassNegoData = () => {
    const { JsonTemplate, lastTransactionData } = this.props;
    if (JsonTemplate?.ITEMIZE?.length && lastTransactionData) {
      this.setState({
        prevFormLoading: true,
      });
      let notFilteredMainTemplate = {};
      let notFilteredSlabMainTemplate = {};

      let switchStateManage = {};
      let switchStateToggleManage = {};
      let slabChildStateToggleManage = {};
      let mainTemplate = _.cloneDeep(this.state.mainTemplate);
      let multiSolmainTemplate = _.cloneDeep(this.state.mainTemplate);
      let slabMainTemplate = _.cloneDeep(this.state.mainTemplate);
      let remainTemplate = _.cloneDeep(this.state.remainTemplate);
      let additionalInfoTemplate;
      if (this.props.vendorType == "Local") {
        additionalInfoTemplate = this.initializeLocalRecipientsState();
      } else {
        additionalInfoTemplate = this.initializeIntlRecipientsState();
      }

      /**
       * old code in case issue is there open this and commit
       */
      /*For Main Template */
      for (const key in mainTemplate) {
        const currentItem = mainTemplate[key];

        for (const key2 in currentItem) {
          const currentField = currentItem[key2];
          if (
            currentField?.Identifier === "GSTType" &&
            currentField?.["DefaultFormula"] == "" &&
            this.props.negotiationJsonData.vendors[0]?.gst_type
          ) {
            if (
              !this.props.negotiationJsonData.vendors[0]?.gst_type.includes(
                "###"
              )
            ) {
              let substring =
                this.props.negotiationJsonData.vendors[0]?.gst_type.split(
                  "_"
                )[0];

              const filteredData = currentField.options.find(
                (item) =>
                  item.code.includes(substring) && !item.code.includes("UT")
              );
              currentField.value = filteredData?.code;
            } else if (
              this.props.negotiationJsonData.vendors[0]?.gst_type.includes(
                "###"
              )
            ) {
              let optionArray = [];
              let subArray =
                this.props.negotiationJsonData.vendors[0]?.gst_type.split(
                  "###"
                );
              subArray.map((element) => {
                const filteredData = currentField.options.find((item) => {
                  let substring = element.split("_")[0];
                  return (
                    item.code.includes(substring) && !item.code.includes("UT")
                  );
                });
                optionArray.push(filteredData?.code);
              });
              currentField.value = optionArray.join("###");
            }
          }
          // const defaultFormula = currentField?.["DefaultFormula"];
          if (currentField && currentField.hasOwnProperty("DefaultFormula")) {
            const defaultFormula = currentField["DefaultFormula"];
            if (defaultFormula) {
              const formattedResult = this.extractAndFormat(defaultFormula);

              if (formattedResult && formattedResult.length >= 1) {
                const valuesFound = formattedResult
                  .map((identifier, index) => {
                    const attributeKey = identifier.split(".");
                    const subStringAttrKey = attributeKey[1].substring(1);
                    // Check if the solution array exists and has the identifier

                    // Fallback to checking in common if valuesInSolution is empty or undefined
                    const fallbackValue =
                      this.props.fetchedTxn?.[1]?.common?.[subStringAttrKey];
                    const fetchTxn = this.props.fetchedTxn?.[1]?.itemize[
                      key
                    ]?.solution
                      ?.map((obj) => obj?.data[0]?.[subStringAttrKey])
                      ?.filter((value) => value !== undefined);
                    if (fetchTxn && fetchTxn.length > 0) {
                      return fetchTxn;
                    } else if (fallbackValue !== undefined) {
                      return [fallbackValue];
                    } else {
                      return [];
                    }
                  })
                  .flat();

                let finalValue = null;
                // Evaluate conditional statements in DefaultFormula
                for (let i = 0; i < valuesFound.length; i++) {
                  // if (valuesFound[i] !== null) {
                  finalValue = valuesFound[i];
                  break;
                  // }
                }
                if (finalValue !== null) {
                  const integerPattern = /^\d+$/;
                  currentItem[key2]["value"] =
                    integerPattern.test(finalValue) &&
                    currentItem[key2].Identifier != "GSTValue"
                      ? parseInt(finalValue, 10)
                      : finalValue;
                  // currentItem[key2]["value"] = finalValue;

                  currentItem[key2]["error"] = false;

                  if (currentItem[key2]["Identifier"] === "ProductQuantity") {
                    currentItem["qty"] = finalValue;
                  }

                  if (
                    currentItem[key2]["Identifier"] === "EstimatedCost" ||
                    currentItem[key2]["Identifier"] ===
                      "EstimatedCostForeign" ||
                    currentItem[key2]["Identifier"] === "Budgetedrate" ||
                    currentItem[key2]["Identifier"] === "Rate" ||
                    currentItem[key2]["Identifier"] === "CIFRate" ||
                    currentItem[key2]["Identifier"] === "rate"
                  ) {
                    currentItem["rate"] = finalValue;
                  }

                  const attributeName = currentItem[key2]["HTMLAttributeName"];
                  const index1 = this.state.errorArray.indexOf(attributeName);

                  if (index1 !== -1) {
                    this.setState((prevState) => ({
                      errorArray: prevState.errorArray.filter(
                        (item, index) => index !== index1
                      ),
                    }));
                  }
                }
              }
            }
          } else {
            // console
            //   .log
            //   // "Skipping currentField because DefaultFormula is undefined."
            //   ("currentFieldValue", currentField);
          }
        }
      }

      /**
       * only refer rfq values
       */
      // for (const key in mainTemplate) {
      //   const currentItem = mainTemplate[key];

      //   for (const key2 in currentItem) {
      //     const currentField = currentItem[key2];
      //     if (
      //       currentField?.Identifier === "GSTType" &&
      //       currentField?.["DefaultFormula"] == "" &&
      //       this.props.negotiationJsonData.vendors[0]?.gst_type
      //     ) {
      //       if (
      //         !this.props.negotiationJsonData.vendors[0]?.gst_type.includes(
      //           "###"
      //         )
      //       ) {
      //         let substring =
      //           this.props.negotiationJsonData.vendors[0]?.gst_type.split(
      //             "_"
      //           )[0];

      //         const filteredData = currentField.options.find(
      //           (item) =>
      //             item.code.includes(substring) && !item.code.includes("UT")
      //         );
      //         currentField.value = filteredData?.code;
      //       } else if (
      //         this.props.negotiationJsonData.vendors[0]?.gst_type.includes(
      //           "###"
      //         )
      //       ) {
      //         let optionArray = [];
      //         let subArray =
      //           this.props.negotiationJsonData.vendors[0]?.gst_type.split(
      //             "###"
      //           );
      //         subArray.map((element) => {
      //           const filteredData = currentField.options.find((item) => {
      //             let substring = element.split("_")[0];
      //             return (
      //               item.code.includes(substring) && !item.code.includes("UT")
      //             );
      //           });
      //           optionArray.push(filteredData?.code);
      //         });
      //         currentField.value = optionArray.join("###");
      //       }
      //     }
      //     const inquiryFieldId =
      //       this.state.fieldIdentifierMapping[currentField?.Identifier];
      //     if (
      //       this.props.fetchedTxn[1]?.itemize[
      //         key
      //       ]?.solution[0]?.data[0].hasOwnProperty(inquiryFieldId)
      //     ) {
      //       const inquiriesValue =
      //         this.props.fetchedTxn[1]?.itemize[key]?.solution[0]?.data[0][
      //           inquiryFieldId
      //         ];
      //       currentField.value = inquiriesValue === null ? "" : inquiriesValue;
      //     } else if (
      //       (this.props.fetchedTxn[1]?.common).hasOwnProperty(inquiryFieldId)
      //     ) {
      //       const inquiriesCommonValue =
      //         this.props.fetchedTxn[1]?.common[inquiryFieldId];
      //       currentField.value =
      //         inquiriesCommonValue === null ? "" : inquiriesCommonValue;
      //     }
      //   }
      // }

      Object.entries(additionalInfoTemplate).forEach(([key, currentField]) => {
        if (
          currentField.Identifier === "GSTType" &&
          currentField?.["DefaultFormula"] == "" &&
          this.props.negotiationJsonData.vendors[0]?.gst_type
        ) {
          if (
            !this.props.negotiationJsonData.vendors[0]?.gst_type?.includes(
              "###"
            )
          ) {
            let substring =
              this.props.negotiationJsonData.vendors[0]?.gst_type.split("_")[0];
            const filteredData = currentField.options.find(
              (item) =>
                item.code.includes(substring) && !item.code.includes("UT")
            );
            currentField.value = filteredData?.code;
          } else if (
            this.props.negotiationJsonData.vendors[0]?.gst_type.includes("###")
          ) {
            {
              let optionArray = [];
              let subArray =
                this.props.negotiationJsonData.vendors[0]?.gst_type.split(
                  "###"
                );
              subArray.map((element) => {
                const filteredData = currentField.options.find((item) => {
                  let substring = element.split("_")[0];
                  return (
                    item.code.includes(substring) && !item.code.includes("UT")
                  );
                });
                optionArray.push(filteredData?.code);
              });
              currentField.value = optionArray.join("###");
            }
          }
        }
        const attributeNameWithoutF = currentField.HTMLAttributeName
          ? currentField.HTMLAttributeName.replace("f", "")
          : null;
        if (
          currentField.DefaultFormula &&
          currentField.DefaultFormula.trim() != ""
        ) {
          // Extract attribute codes from DefaultFormula
          const attributeCodes =
            currentField.DefaultFormula.match(/f\d+/g) || [];
          const formattedResult = this.extractAndFormat(
            currentField.DefaultFormula
          );
          // Iterate over each attribute code
          formattedResult.forEach((attributeCode) => {
            // Extract attribute key from attributeCode
            const attributeKey = attributeCode.split(".");
            const subStringAttrKey = attributeKey[1].substring(1);
            // Check if the attribute key exists in lastTransactionData.common
            if (
              this.props.fetchedTxn?.[1]?.common.hasOwnProperty(
                subStringAttrKey
              )
            ) {
              if (
                this.isValidJsonString(
                  this.props.fetchedTxn?.[1]?.common[subStringAttrKey]
                ) &&
                this.props.fetchedTxn?.[1]?.common[subStringAttrKey] &&
                currentField.ElementType !== "F"
              ) {
                let MultipleVendors = {};
                this.props.Vendors.map((vendor) => {
                  let element = JSON.parse(
                    this.props.fetchedTxn?.[1]?.common[subStringAttrKey]
                  )[vendor.id];
                  element.VendorName = vendor.name;
                  MultipleVendors[vendor.id] = element;
                });
                currentField.value = MultipleVendors;
              } else {
                currentField.value =
                  this.props.fetchedTxn?.[1]?.common[subStringAttrKey];
                if (
                  currentField.VisibilityFlag === "Y" &&
                  currentField.Identifier === "Currency"
                ) {
                  this.setState({
                    selectedCurrency: currentField.value,
                  });
                }
              }
              currentField.error = false;

              const index2 = this.state.errorArray.indexOf(
                currentField.HTMLAttributeName
              );
              if (index2 !== -1) {
                this.state.errorArray.splice(index2, 1);
              }

              const element = document.getElementsByName(
                currentField.HTMLAttributeName
              )[0];
              if (element) {
                element.value = currentField.value;
              }
            }
          });
        } else if (
          currentField.DefaultFormula == "" &&
          currentField.DefaultValue.length > 0 &&
          currentField.ElementType == "T"
        ) {
          currentField.value = currentField.DefaultValue;
        }
      });
      // Filter out items not present in inquiry_item_ids
      if (this.props.itemSpecific === true) {
        const filteredMainTemplate = Object.fromEntries(
          Object.entries(mainTemplate).filter(([key]) =>
            this.props.inquiryItemsId.includes(parseInt(key))
          )
        );
        const NotfilteredMainTemplate = Object.fromEntries(
          Object.entries(mainTemplate)
            .filter(
              ([key]) => !this.props.inquiryItemsId.includes(parseInt(key))
            )
            .map(([key, value]) => {
              return [key, { ...value, selected: "N" }];
            })
        );
        mainTemplate = filteredMainTemplate;
        notFilteredMainTemplate = NotfilteredMainTemplate;
      }

      if (
        this.props.itemSpecific === true &&
        this.props.multipleSolutionFlag === true &&
        !this.props.slabFlag
      ) {
        const filteredMSmainTemplate = Object.fromEntries(
          Object.entries(multiSolmainTemplate).filter(([key]) =>
            this.props.inquiryItemsId.includes(parseInt(key))
          )
        );
        multiSolmainTemplate = filteredMSmainTemplate;
      }

      if (
        this.props.itemSpecific === true &&
        this.props.multipleSolutionFlag === true &&
        this.props.slabFlag === true
      ) {
        const NotfilteredSlabMainTemplate = Object.fromEntries(
          Object.entries(slabMainTemplate)
            .filter(
              ([key]) => !this.props.inquiryItemsId.includes(parseInt(key))
            )
            .map(([key, value]) => {
              // Update 'selected' and 'select_slab' properties
              value.selected = "N";
              value.map((val) => {
                val.select_slab = "N";
                val.selected = "N";
                return val;
              });

              // Return the updated key-value pair
              return [key, value];
            })
        );
        notFilteredSlabMainTemplate = NotfilteredSlabMainTemplate;
        const filteredSlabmainTemplate = Object.fromEntries(
          Object.entries(slabMainTemplate).filter(([key]) =>
            this.props.inquiryItemsId.includes(parseInt(key))
          )
        );
        slabMainTemplate = filteredSlabmainTemplate;
      }

      this.setState({
        remainTemplate,
        switchState: switchStateManage,
        switchStateToggleCheck: switchStateToggleManage,
        slabChildStateToggleCheck: slabChildStateToggleManage,
        multiSolmainTemplate,
        slabMainTemplate,
        mainTemplate,
        intialMaintemplate: mainTemplate,
        additionalInfoTemplate,
        notFilteredMainTemplate,
        notFilteredSlabMainTemplate,
        prevFormLoading: false, // Assuming loading is finished after updating the state
      });
    }
  };

  handleSave = (savedValues) => {
    const mainTemplate = _.cloneDeep(this.state.mainTemplate);
    const multiSolmainTemplate = _.cloneDeep(this.state.multiSolmainTemplate);
    const slabMainTemplate = _.cloneDeep(this.state.slabMainTemplate);

    let mainFound = 0;
    let multiSolFound = 0;
    let slabFound = 0;

    // Iterate over mainTemplate
    for (const key in mainTemplate) {
      const currentItem = mainTemplate[key];

      for (const key2 in currentItem) {
        if (currentItem[key2].Identifier === "GSTType") {
          mainFound = 1;
          currentItem[key2].value = savedValues;
          currentItem[key2].error = false;
        }
      }
    }

    // Iterate over multiSolmainTemplate
    {
      this.props.multipleSolutionFlag &&
        !this.props.slabFlag &&
        Object.keys(multiSolmainTemplate).forEach((mainIndex) => {
          multiSolmainTemplate[mainIndex].forEach((subItem, subIndex) => {
            for (const key in subItem) {
              if (subItem[key].Identifier === "GSTType") {
                multiSolFound = 1;
                subItem[key].value = savedValues;
              }
            }
          });
        });
    }

    // Update slabMainTemplate
    if (this.props.multipleSolutionFlag && this.props.slabFlag) {
      Object.keys(slabMainTemplate).forEach((mainIndex) => {
        slabMainTemplate[mainIndex].forEach((subItem) => {
          for (const key in subItem) {
            if (subItem[key] && subItem[key].Identifier === "GSTType") {
              slabFound = 1;
              // Preserve the selected key
              const selected = this.state.slabMainTemplate[mainIndex].selected;
              // Update the specific field in the selected row
              slabMainTemplate[mainIndex] = slabMainTemplate[mainIndex].map(
                (item) => {
                  return {
                    ...item,
                    [key]: {
                      ...item[key],
                      value: savedValues, // Update value
                    },
                  };
                }
              );

              // Restore the selected key
              slabMainTemplate[mainIndex].selected = selected;
            }
          }
        });
      });
    }

    if (
      (this.props.multipleSolutionFlag &&
        !this.props.slabFlag &&
        multiSolFound !== 1) ||
      (!this.props.multipleSolutionFlag && mainFound !== 1) ||
      (this.props.multipleSolutionFlag &&
        this.props.slabFlag &&
        slabFound !== 1)
    ) {
      const additionalInfoTemplate = _.cloneDeep(
        this.state.additionalInfoTemplate
      );
      for (const key in additionalInfoTemplate) {
        if (additionalInfoTemplate[key].Identifier === "GSTType") {
          additionalInfoTemplate[key].value = savedValues;
        }
      }
      this.setState({ additionalInfoTemplate });
    } else {
      // Update mainTemplate if mainFound is 1
      if (mainFound === 1) {
        this.setState({ mainTemplate });
      }

      // Update multiSolmainTemplate if multiSolFound is 1
      if (multiSolFound === 1) {
        this.setState({ multiSolmainTemplate });
      }

      // Update slabMainTemplate if slabFound is 1
      if (slabFound === 1) {
        this.setState({ slabMainTemplate });
      }
    }
  };

  handleCheckboxChange = (event, key) => {
    const { checked } = event.target; // Get the checked state of the checkbox
    const { errorArrayForMSParentName } = this.state;
    const updatedErrorArrayForMSParentName = { ...errorArrayForMSParentName };

    // added to solve : removing solution name error when item is unchecked :: START
    const { solutionNameErrors } = this.state;
    Object.keys(solutionNameErrors).forEach((key_sol) => {
      // Extract itemId or key prefix
      if (!checked) {
        if (key_sol.split("-")[0] == key) {
          delete solutionNameErrors[key_sol];
        }
      }
    });
    // added to solve : removing solution name error when item is unchecked :: END

    // Ensure that mainTemplate and lastTransactionTypeInThread exist before accessing their properties
    if (
      this.state.mainTemplate &&
      this.props.lastTransactionInThread &&
      this.state.mainTemplate[key] &&
      this.props.lastTransactionInThread[key]
    ) {
      const isNegotiation =
        this.props.lastTransactionTypeInThread === "Negotiation";
      const lastSelected =
        this.props.lastTransactionInThread[key].selected === "Y";
      const mainSelected = this.state.mainTemplate[key].selected === "Y";
      const is_deleted_from_master =
        this.props.items[key]?.is_deleted_from_master;
      const is_unlink_from_category =
        this.props.items[key]?.is_unlink_from_category;
      // Check if filteredSelectedNotData contains the key and its selected value is 'N'
      const filteredSelectedNotDataContainsKey =
        this.props.filteredSelectedNotData &&
        this.props.filteredSelectedNotData[key] &&
        this.props.filteredSelectedNotData[key].selected;
      if (!checked) {
        delete updatedErrorArrayForMSParentName[key]; // Remove the key from the error array
      }
      // Check if either is_deleted_from_master or is_unlink_from_category is true, if so, disable input fields
      if (is_deleted_from_master || is_unlink_from_category) {
        const updatedData = {
          ...this.state.mainTemplate,
          [key]: {
            ...this.state.mainTemplate[key],
            selected: "N",
            readOnly: true, // Set readOnly to true to disable input fields
          },
        };
        this.setState({ mainTemplate: updatedData });
      } else if (!lastSelected && !this.props.MassNego) {
        const updatedData = {
          ...this.state.mainTemplate,
          [key]: {
            ...this.state.mainTemplate[key],
            selected: "N",
            readOnly: true, // Set readOnly to true to disable input fields
          },
        };
        this.setState({ mainTemplate: updatedData });
      } else {
        if (!event.target.checked) {
          const updatedInitialData = {
            ...this.state.mainTemplate,
            [key]: {
              ...this.state.intialMaintemplate[key],
              selected: "N",
              readOnly: false, // Reset readOnly to false to enable input fields
            },
          };

          this.setState({ mainTemplate: updatedInitialData });
        } else {
          const updatedData = {
            ...this.state.mainTemplate,
            [key]: {
              ...this.state.mainTemplate[key],
              selected: "Y",
              readOnly: false, // Reset readOnly to false to enable input fields
            },
          };

          this.setState({ mainTemplate: updatedData });
        }
      }
    } else {
      if (this.state?.mainTemplate[key]?.added_after_rfq_created == true) {
        const updatedData = {
          ...this.state.mainTemplate,
          [key]: {
            ...this.state.mainTemplate[key],
            selected: event.target.checked ? "Y" : "N",
            readOnly: false, // Reset readOnly to false to enable input fields
          },
        };
        this.setState({ mainTemplate: updatedData });
      }
      console.error(
        `Template with key ${key} does not exist in either mainTemplate or lastTransactionInThread.`
      );
    }
    // Update the state with the modified error array
    this.setState({
      errorArrayForMSParentName: updatedErrorArrayForMSParentName,
    });
  };

  clearMultiSolApplyToAll = (key, FieldId) => {
    this.setState((prevState) => ({
      multiSolApplyToAll: {
        ...prevState.multiSolApplyToAll,
        [`${key}_${FieldId}_multiSolApplyToAll`]: false,
      },
    }));
  };

  // Function to update multiSolmainTemplate
  updateMultiSolmainTemplate = (key) => {
    this.setState((prevState) => {
      const newState = { ...prevState.multiSolmainTemplate };

      if (newState[key] && Array.isArray(newState[key])) {
        // Reset the array to only contain the 0th index with the initial data
        newState[key] = [{ ...this.state.mainTemplate[key] }];
      } else {
        // Initialize the array with the initial data if it doesn't exist
        newState[key] = [{ ...this.state.mainTemplate[key] }];
      }

      return { multiSolmainTemplate: newState };
    });
  };

  handleTermsCheckboxChange = (e) => {
    const { name, value, type, checked } = e.target;
    this.setState({ txnChecked: checked });
    // if (this.props.multipleVendors && this.props?.termsConditionChecked) {
    //   this.props.termsConditionChecked(checked);
    // }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the InitializeFlag has changed
    if (this.state.InitializeFlag !== prevState.InitializeFlag) {
      // If it has changed, conditionally initialize the state
      if (this.state.InitializeFlag) {
        this.initializeState();
        // this.initializeSolutionNames();
      }
    }

    if (this.props.multipleVendors) {
      if (
        this.props.SubmitMultipleNegotiaion !==
        prevProps.SubmitMultipleNegotiaion
      ) {
        if (this.props.SubmitMultipleNegotiaion) {
          this.handleNegotiationSubmit();
        }
      }
      if (this.props.combinedSubmit !== prevProps.combinedSubmit) {
        if (this.props.combinedSubmit) {
          const submitAPI = async () => {
            if (this.props.submitflg === "International") {
              await new Promise((resolve) => setTimeout(resolve, 4000)); // Delay for International
            } else if (this.props.submitflg === "Local") {
              await new Promise((resolve) => setTimeout(resolve, 500)); // Delay for Local
            }
            await this.negotiationSubmitAPI(); // Call the API
          };
          submitAPI();
        }
      }
    }
  }

  toggleLoaderArray = (arg) => {
    let temp = this.state.loaderArray;
    let index = temp.indexOf(arg);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(arg);
    }
    this.setState({
      loaderArray: temp,
    });
  };

  SUM = (field, item) => {
    let sum = 0;
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = 0;
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) {}
      sum += Number(expression_val);
    });
    return sum;
  };

  PRLongText = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    //let replacedStr= pritems[pritems.length-1][prconfig[field].response_key].replace(/\^/,"\n");
    return pritems[pritems?.length - 1][prconfig[field].response_key];
  };

  COMMA = (field, item) => {
    let str = "";
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = "";
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) {}
      str += expression_val + ",";
    });
    return str.slice(0, -1);
  };

  COMMA_WITH_SPACE = (field, item) => {
    let str = "";
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = "";
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) {}
      str += expression_val + ", ";
    });
    return str.slice(0, -2);
  };

  EstimatedCost = (field, item) => {
    let value = item.estimated_cost;
    if (
      this.props.featurePermission
        ?.Fetch_Budget_Value_in_RFQ_Form_from_Item_Master?.feature_value === "N"
    ) {
      value = this.LATEST(field, item);
    }
    return value;
  };

  MIN = (field, item) => {
    let value = 0;
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = 0;
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) {}

      if (expression_val < value) {
        value = expression_val;
      }
    });

    return value;
  };

  MIN_DATE = (field, item) => {
    let value = 0;

    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = 0;
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
        if (!value || moment(value).isAfter(moment(expression_val))) {
          value = expression_val;
        }
      } catch (e) {}

      if (expression_val < value) {
        value = expression_val;
      }
    });

    return value;
  };

  LATEST_Remove_Circumflex = (field, item) => {
    let value = this.LATEST(field, item);
    return value ? value.replace(/\^\^\^/g, "\n") : "";
  };

  PRLongText = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    return (
      this.LATEST_Remove_Circumflex(field, item) ||
      pritems[pritems.length - 1]["material_short_text"] ||
      ""
    );
  };

  PRNoLineItem = (field, item) => {
    let str = "";
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      str += prlineitem.pr.number + "(" + prlineitem.pr_line_item + "), ";
    });
    return str.slice(0, -2);
  };

  ReqDeliveryDate = (field, item) => {};

  LATEST = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    //return pritems[pritems?.length-1][prconfig[field].response_key] || '';
    let pr_line_item_object = pritems[pritems.length - 1];
    let expression = "pr_line_item_object" + "." + prconfig[field].response_key;
    let result = "";
    try {
      result = eval(expression);
    } catch (e) {}

    return result;
  };

  LATEST_PO_DATE = (field, item) => {
    let value = "";
    value = this.LATEST(field, item);
    if (value) {
      let pattern = /\b(\d{4})(\d{2})(\d{2})\b/;
      let result = value.match(pattern);
      if (result) {
        result.shift();
        value = result.join("-");
      }
    }
    return value;
  };

  QTY_Check = (qty) => {
    let $moq_permission =
      this.props.accountModulePermission[
        "Allow more Quantity in PR Qty Distribution"
      ]?.module_activated;
    let $service_rfq_permission =
      this.props.accountModulePermission["Service PR"]?.module_activated;
    let readOnly = true;
    if ($moq_permission === "Y" || $service_rfq_permission === "Y") {
      readOnly = false;
    }
    return readOnly;
  };

  COMPANY_CODE_Check = (company_code) => {
    return company_code ? true : false;
  };

  initializeState = async () => {
    let initialSlab = {};
    let finalState = {};
    if (this.props.JsonTemplate?.ITEMIZE?.length) {
      for (
        let index = 0;
        index < Object.keys(this.props.negotiationJsonData.items).length;
        index++
      ) {
        let obj = {};
        let templateIndexes;
        for (let i = 0; i < this?.props?.JsonTemplate.ITEMIZE.length; i++) {
          if (
            this?.props?.JsonTemplate?.ITEMIZE[i][1]?.Child?.AttributeName ==
            this.props.items[this.props.transactionItemSequence[index]]
              ?.attributeName
          ) {
            templateIndexes = i;
            break;
          }
        }
        this.state.usedAttributesId.push(
          this.props.JsonTemplate?.ITEMIZE[index][1]?.Child?.AttributeId
        );
        this.setState({
          usedAttributesId: this.state.usedAttributesId,
        });
        for (
          let fieldIndex = 0;
          fieldIndex < this.props.JsonTemplate?.ITEMIZE[index].length;
          fieldIndex++
        ) {
          const row =
            this.props.JsonTemplate?.ITEMIZE[templateIndexes][fieldIndex];
          if (row.VisibilityFlag === "Y") {
            if (
              row.Identifier === "Product" ||
              row.Identifier === "ProductForeign"
            ) {
              obj[row.FieldId] = {
                HTMLAttributeName: row.HTMLAttributeName,
                value: Object.keys(this.props.negotiationJsonData.items)[index],
                Validation: "",
                FieldLabel: row.FieldLabel,
                error: false,
                Identifier: row.Identifier,
                ErrorMessage: "",
                DefaultFormula: row.DefaultFormula,
                DefaultValue: row.DefaultValue,
                CurrencyFormula: row.CurrencyFormula,
                UnitsFlag: row.UnitsFlag,
                InstructionText: row.InstructionText,
              };
              continue;
            }
            if (row.Identifier === "ProductCode") {
              obj[row.FieldId] = {
                HTMLAttributeName: row.HTMLAttributeName,
                value: this.props.negotiationJsonData.items[this.props.transactionItemSequence[index]].code,
                Validation: "",
                FieldLabel: row.FieldLabel,
                error: false,
                Identifier: row.Identifier,
                ErrorMessage: "",
                DefaultFormula: row.DefaultFormula,
                DefaultValue: row.DefaultValue,
                CurrencyFormula: row.CurrencyFormula,
                UnitsFlag: row.UnitsFlag,
                InstructionText: row.InstructionText,
              };
              continue;
            }

            let value = "";
            if (row.Identifier === "MasterSpecification") {
              value =
                this.props.negotiationJsonData.items[index]?.specifications;
            } else if (row.Identifier === "MasterHSNCode") {
              value =
                this.props.negotiationJsonData.items[index]?.hsn_code || "";
            }

            let readOnly = false;
            if (
              Object.keys(this.props.prlineitems).length &&
              prconfig &&
              prconfig[row.Identifier] !== undefined
            ) {
              value =
                eval(this[prconfig[row.Identifier].function_name])(
                  row.Identifier,
                  this.props.items.negotiationJsonData[index]
                ) || "";
              if (
                prconfig[row.Identifier]?.non_editable_function &&
                prconfig[row.Identifier]?.non_editable_function !== "0"
              ) {
                if (prconfig[row.Identifier]?.non_editable_function == "1") {
                  readOnly = true;
                } else {
                  readOnly = eval(
                    this[prconfig[row.Identifier]?.non_editable_function]
                  )(value);
                }
              }
            }
            obj[row.FieldId] = {
              HTMLAttributeName: row.HTMLAttributeName,
              value: value,
              Validation: row.Validation,
              FieldLabel: row.FieldLabel,
              error: false,
              ErrorMessage: row.ErrorMessage,
              Identifier: row.Identifier,
              ElementType: row.ElementType,
              readOnly: readOnly,
              DefaultFormula: row.DefaultFormula,
              DefaultValue: row.DefaultValue,
              CurrencyFormula: row.CurrencyFormula,
              UnitsFlag: row.UnitsFlag,
              InstructionText: row.InstructionText,
            };
            if (
              row.Identifier === "EstimatedCost" ||
              row.Identifier === "EstimatedCostForeign" ||
              row.Identifier === "Budgetedrate" ||
              row.Identifier === "BudgetedrateForeign"
            ) {
              // obj[row.FieldId][
              //   `upd_budget_master_${this.props.negotiationJsonData.items[index].id}`
              // ] = false;

              if (
                this.props.featurePermission
                  ?.Fetch_Budget_Value_in_RFQ_Form_from_Item_Master
                  ?.feature_value === "Y" &&
                this.props.negotiationJsonData.items[index]?.budgeted_rate
              ) {
                if (
                  parseFloat(
                    this.props.negotiationJsonData.items[index]?.budgeted_rate
                  )
                ) {
                  obj[row.FieldId]["value"] =
                    this.props.negotiationJsonData.items[index].budgeted_rate;
                  obj["rate"] =
                    this.props.negotiationJsonData.items[index].budgeted_rate;
                } else {
                  obj["rate"] = "";
                }
              } else {
                obj["rate"] = value;
              }
            }

            if (row.ElementType === "S") {
              if (row.DropdownEdit === "Y") {
                obj[row.FieldId]["options"] = [];
                //getting options data from redux if it is available else calling api
                let optionsData = this.getOptionsFromRedux(row, "itemize");
                if (optionsData) {
                  obj[row.FieldId]["options"] = optionsData;
                  this.setState({
                    commonOptions: {
                      ...this.state.commonOptions,
                      [row.Identifier]: optionsData,
                    },
                  });
                } else {
                  obj[row.FieldId]["options"] = await this.getItemizeOptions(
                    row,
                    index
                  );
                }
              } else {
                obj[row.FieldId]["options"] = row.DefaultValue;
              }
            }
            if (
              row.Identifier === "BasicHistoricalPrice" ||
              row.Identifier === "LandedHistoricalPrice" ||
              row.Identifier === "DateHP"
            ) {
              obj[row.FieldId]["DynamicDefaultValue"] = "loading";
              this.getDynamicDefaultValues(row, index, row.DefaultValue);
            }
          }
          if (row.Identifier === "EstimatedValue") {
            obj[row.FieldId] = {
              HTMLAttributeName: row.HTMLAttributeName,
              value: "",
              Validation: row.Validation,
              FieldLabel: row.FieldLabel,
              error: false,
              ErrorMessage: row.ErrorMessage,
              Identifier: row.Identifier,
              DefaultFormula: row.DefaultFormula,
              DefaultValue: row.DefaultValue,
              CurrencyFormula: row.CurrencyFormula,
              UnitsFlag: row.UnitsFlag,
              InstructionText: row.InstructionText,
            };
          }
          if (row.Identifier === "ProductQuantity" && obj[row.FieldId])
            obj.qty = obj[row.FieldId].value;
        }
        obj.specification = "";
        const itemize = this.props.lastTransactionData.itemize;
        const key = this.props.transactionItemSequence[index]; // Get the key at the specified index
        obj.added_after_rfq_created =
          this.props?.items[key]?.added_after_rfq_created;
        obj.is_deleted_from_master =
          this.props?.items[key]?.is_deleted_from_master;
        obj.is_unlink_from_category =
          this.props?.items[key]?.is_unlink_from_category;
        if (this.props.MassNego) {
          obj.selected =
            this.props?.items[key]?.is_deleted_from_master ||
            this.props?.items[key]?.is_unlink_from_category
              ? "N"
              : "Y";
        } else {
          obj.selected =
            this.props?.items[key]?.is_deleted_from_master ||
            this.props?.items[key]?.is_unlink_from_category
              ? "N"
              : itemize[key]?.selected;
        }
        for (let i = 0; i < this?.props?.JsonTemplate.ITEMIZE.length; i++) {
          if (
            this?.props?.JsonTemplate?.ITEMIZE[i][1]?.Child?.AttributeName ==
            this.props.items[key]?.attributeName
          ) {
            obj.formdetailRowId =
              this?.props?.JsonTemplate?.ITEMIZE[i][1]?.Child?.AttributeId;
            break;
          }
        }

        if (!obj.hasOwnProperty("qty")) {
          obj.qty = "";
        }
        if (!obj.hasOwnProperty("slab")) {
          obj["slab"] = [
            { id: 1, start: "1", end: "", error: false },
            { id: 2, start: "More Than", end: "", error: false },
          ];
        }
        // finalState[Object.keys(this.props.negotiationJsonData.items)[index]] = obj;
        finalState[this.props.transactionItemSequence[index]] = obj;
        if (this.props.rfqSettingValues.includeSlab) {
          initialSlab[this.props.transactionItemSequence[index]] = {
            isOpen: true,
          };
        } else {
          initialSlab[this.props.transactionItemSequence[index]] = {
            isOpen: false,
          };
        }
      }
      this.setState(
        {
          mainTemplate: finalState,
          slab: initialSlab,
        },
        () => {
          this.props.MassNego ? this.fetchMassNegoData() : this.fetchPrevious();
        }
      );
    }
  };

  fetchPrevTemplate = async () => {
    let templateURL = "";
    if (process.env.NODE_ENV === "production") {
      templateURL = templateURi(
        this.props.userInfo.division_id,
        this.props.companyInfo.display_name,
        this.props.negotiationJsonData.template.id,
        this.props.negotiationJsonData.template.batch_id,
        1
      );
    } else {
      templateURL = devTemplateURi(
        this.props.userInfo.division_id,
        this.props.companyInfo.display_name,
        this.props.negotiationJsonData.template.id,
        this.props.negotiationJsonData.template.batch_id,
        1
      );
    }
    await axios
      .get(templateURL)
      .then((response) => {
        let FieldIDIdentifierMapping = this.convertToFieldIdIdentifierPairs(
          response.data
        );
        this.setState(
          {
            fieldIdentifierMapping: FieldIDIdentifierMapping,
          },
          () => {
            this.initializeState();
          }
        );
      })
      .catch((err) => {
        console.log(err, "main json err");
      });
  };

  convertToFieldIdIdentifierPairs = (jsonData) => {
    const fieldIdIdentifierPairs = {};

    // Loop through FOREIGN section
    if (jsonData.FOREIGN) {
      jsonData.FOREIGN.forEach((obj) => {
        fieldIdIdentifierPairs[obj.Identifier] = obj.FieldId;
      });
    }

    // Consider only the first array inside ITEMIZE section if present
    if (jsonData.ITEMIZE && jsonData.ITEMIZE.length > 0) {
      jsonData.ITEMIZE[0].forEach((obj) => {
        fieldIdIdentifierPairs[obj.Identifier] = obj.FieldId;
      });
    }

    // Loop through LOCAL section if present
    if (jsonData.LOCAL) {
      jsonData.LOCAL.forEach((obj) => {
        fieldIdIdentifierPairs[obj.Identifier] = obj.FieldId;
      });
    }

    // Loop through COMMON section if present
    if (jsonData.COMMON) {
      jsonData.COMMON.forEach((obj) => {
        fieldIdIdentifierPairs[obj.Identifier] = obj.FieldId;
      });
    }

    return fieldIdIdentifierPairs;
  };

  initializeLocalRecipientsState = () => {
    let row = {};
    const combinedTemplate = this.props.JsonTemplate?.COMMON.concat(
      this.props.JsonTemplate?.LOCAL
    );
    combinedTemplate.forEach((cell) => {
      row[cell.FieldId] = {
        HTMLAttributeName: cell.HTMLAttributeName,
        value: "",
        Validation: cell.Validation,
        FieldLabel: cell.FieldLabel,
        error: false,
        ErrorMessage: cell.ErrorMessage,
        Identifier: cell.Identifier,
        ElementType: cell.ElementType,
        DefaultFormula: cell.DefaultFormula,
        DefaultValue: cell.DefaultValue,
        VisibilityFlag: cell.VisibilityFlag,
      };
      if (cell.ElementType === "S") {
        if (cell.DropdownEdit === "Y") {
          row[cell.FieldId]["options"] = "loading";
          //getting options data from redux if it is available else calling api
          let optionsData = this.getOptionsFromRedux(cell, "additionalInfo");
          if (optionsData) {
            row[cell.FieldId]["options"] = optionsData;
          } else {
            this.getOptionsFromApi(cell, "additionalInfo");
          }
        } else {
          row[cell.FieldId]["options"] = cell.DefaultValue;
        }
      } else {
        row[cell.FieldId]["value"] = "";
      }
    });
    return row;
  };

  initializeIntlRecipientsState = () => {
    let row = {};

    const combinedTemplate = this.props.JsonTemplate?.COMMON.concat(
      this.props.JsonTemplate?.FOREIGN
    );

    combinedTemplate.forEach((cell) => {
      row[cell.FieldId] = {
        HTMLAttributeName: cell.HTMLAttributeName,
        value: "",
        Validation: cell.Validation,
        FieldLabel: cell.FieldLabel,
        error: false,
        ErrorMessage: cell.ErrorMessage,
        Identifier: cell.Identifier,
        ElementType: cell.ElementType,
        DefaultFormula: cell.DefaultFormula,
        DefaultValue: cell.DefaultValue,
        VisibilityFlag: cell.VisibilityFlag,
      };
      if (cell.ElementType === "S") {
        if (cell.DropdownEdit === "Y") {
          row[cell.FieldId]["options"] = "loading";
          //getting options data from redux if it is available else calling api
          let optionsData = this.getOptionsFromRedux(cell, "additionalInfo");
          if (optionsData) {
            row[cell.FieldId]["options"] = optionsData;
          } else {
            this.getOptionsFromApi(cell, "additionalInfo");
          }
        } else {
          row[cell.FieldId]["options"] = cell.DefaultValue || "";
        }
      }
    });
    this.setState({
      additionalInfoTemplate: row,
    });
    return row;
  };

  getItemizeOptions = async (cell, index) => {
    let templateId = this.props.JsonTemplate?.PARAM[0]?.template_id;
    if (index !== 0) {
      return this.state.commonOptions[cell.Identifier];
    }
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.newSessionID}`;
    const url = getDropdownOptions(
      cell.Identifier,
      this.props.JsonTemplate?.PARAM[0]?.template_id
    );
    this.toggleLoaderArray(
      index ? `${cell.Identifier}_${index}` : cell.Identifier
    );
    try {
      // setIsLoading(true);
      const res = await axios.get(url);
      this.toggleLoaderArray(
        index ? `${cell.Identifier}_${index}` : cell.Identifier
      );
      if (res.data.dropdown_mapping_status !== false) {
        this.setState({
          commonOptions: {
            ...this.state.commonOptions,
            [cell.Identifier]: res.data.data,
          },
        });
        //setting itemize data into redux
        this.setOptionsInRedux(
          templateId,
          cell.Identifier,
          res.data.data,
          "itemize"
        );
        return res.data.data;
      } else {
        this.setState({
          commonOptions: {
            ...this.state.commonOptions,
            [cell.Identifier]: cell.DefaultValue,
          },
        });
        this.setOptionsInRedux(
          templateId,
          cell.Identifier,
          cell.DefaultValue,
          "itemize"
        );
        return cell.DefaultValue;
      }
    } catch (err) {
      console.log(err, "options error");
      this.toggleLoaderArray(
        index ? `${cell.Identifier}_${index}` : cell.Identifier
      );
      return [];
    }
  };

  //setting options data into redux

  getOptionsFromApi = async (cell, template) => {
    try {
      let templateId = this.props.JsonTemplate?.PARAM[0]?.template_id;
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.sessionId}`;
      const url = getDropdownOptions(
        cell.Identifier,
        this.props.JsonTemplate?.PARAM[0]?.template_id
      );
      this.toggleLoaderArray(cell.Identifier);
      const res = await axios.get(url);
      if (template === "additionalInfo") {
        if (res.data.dropdown_mapping_status !== false) {
          this.setState((prevState) => ({
            ...prevState,
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [cell.FieldId]: {
                ...prevState.additionalInfoTemplate[cell.FieldId],
                options: res.data?.data,
              },
            },
          }));
          this.setOptionsInRedux(
            templateId,
            cell.Identifier,
            res.data.data,
            template
          );
        } else {
          this.setState((prevState) => ({
            ...prevState,
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [cell.FieldId]: {
                ...prevState.additionalInfoTemplate[cell.FieldId],
                options: cell.DefaultValue,
              },
            },
          }));
        }
      }
      this.toggleLoaderArray(cell.Identifier);
    } catch (error) {
      console.log(error, "options error");
      if (template === "additionalInfo") {
        this.setState((prevState) => ({
          ...prevState,
          additionalInfoTemplate: {
            ...prevState.additionalInfoTemplate,
            [cell.FieldId]: {
              ...prevState.additionalInfoTemplate[cell.FieldId],
              options: [],
            },
          },
        }));
      }
      this.toggleLoaderArray(cell.Identifier);
    }
  };

  handleUpdateTxn = (key, newRow) => {
    this.setState((prevState) => ({
      multiSolmainTemplate: {
        ...prevState.multiSolmainTemplate,
        [key]: newRow,
      },
    }));
  };

  setSolutionNames = (solName) => {
    this.setState({ solutionNames: solName });
  };

  setSolutionNamesErrors = (solName) => {
    this.setState({ solutionNameErrors: solName });
  };

  setOptionsInRedux = (templateId, name, data, template) => {
    let tempObject = {
      data,
      template,
    };
    let updateTemplateValues = this.props.templateValues;
    if (Object.keys(updateTemplateValues).length !== 0) {
      if (Object.keys(updateTemplateValues).some((val) => val == templateId)) {
        if (
          !Object.keys(updateTemplateValues[templateId]).some(
            (item) => item == name
          )
        ) {
          let newTemplateValues = {
            ...updateTemplateValues[templateId],
            [name]: tempObject,
          };
          this.props.setTemplateValues({ [templateId]: newTemplateValues });
        }
      } else {
        this.props.setTemplateValues({ [templateId]: { [name]: tempObject } });
      }
    } else {
      this.props.setTemplateValues({ [templateId]: { [name]: tempObject } });
    }
  };

  //getting options data from redux
  getOptionsFromRedux = (cell, template) => {
    let templateId = this.props.JsonTemplate?.PARAM[0]?.template_id;
    let updateTemplateValues = this.props.templateValues;
    if (Object.keys(updateTemplateValues).length !== 0) {
      if (updateTemplateValues[templateId]) {
        let cellData = updateTemplateValues[templateId][cell.Identifier];
        if (cellData && cellData.template == template) return cellData.data;
      }
    }
  };

  getDynamicDefaultValues = (cell, index, DefaultValue) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = getIdentifiersDynamicValues(
      cell.Identifier,
      this.props.selectedItems[index].id,
      this.props.selectedCategory.id
    );
    this.toggleLoaderArray(
      `${cell.Identifier}_getDynamicDefaultValues_${index}`
    );
    axios
      .get(url)
      .then((res) => {
        if (res.data) {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplate: {
              ...prevState.mainTemplate,
            },
          }));
        } else {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplate: {
              ...prevState.mainTemplate,
              [this.props.selectedItems[index]?.id]: {
                ...prevState.mainTemplate[this.props.selectedItems[index]?.id],
                [cell.FieldId]: {
                  ...prevState.mainTemplate[
                    this.props.selectedItems[index]?.id
                  ][cell.FieldId],
                  DynamicDefaultValue: DefaultValue,
                },
              },
            },
          }));
        }

        this.toggleLoaderArray(
          `${cell.Identifier}_getDynamicDefaultValues_${index}`
        );
      })
      .catch((err) => {
        console.log(err, "getDynamicDefaultValues");
        if (this.props.selectedItems) {
          if (this.props.selectedItems[index]) {
            this.setState((prevState) => ({
              ...prevState,
              mainTemplate: {
                ...prevState.mainTemplate,
                [this.props.selectedItems[index]?.id]: {
                  ...prevState.mainTemplate[
                    this.props.selectedItems[index]?.id
                  ],
                  [cell.FieldId]: {
                    ...prevState.mainTemplate[
                      this.props.selectedItems[index]?.id
                    ][cell.FieldId],
                    options: [],
                  },
                },
              },
            }));
          }
        }

        this.toggleLoaderArray(
          `${cell.Identifier}_getDynamicDefaultValues_${index}`
        );
      });
  };

  checkIsLinkLocal = (cell) => {
    //first check if the vendor master check is present or not
    if (
      this.props.rfqSettingValues?.vendorMasterPaymentTerm &&
      this.props.rfqSettingValues?.vendorMasterIncoTerm
    ) {
      // check from both
      let str = this.props.companyInfo?.inco_term_identifier;
      let str1 = this.props.companyInfo?.payment_term_identifier;
      if (str) {
        let arr = str.split(";");
        if (arr.includes(cell.Identifier)) {
          return "inco";
        }
      }
      if (str1) {
        let arr1 = str1.split(";");
        if (arr1.includes(cell.Identifier)) {
          return "payment";
        }
      }
      return false;
    } else if (this.props.rfqSettingValues?.vendorMasterPaymentTerm) {
      // paymentterm array
      if (!this.props.companyInfo?.payment_term_identifier) return false;
      let str = this.props.companyInfo?.payment_term_identifier;
      let arr = str.split(";");
      // let arr = ["PaymentType", "PaymentTypeForeign"];
      if (arr.includes(cell.Identifier)) {
        return "payment";
      }
      return false;
    } else if (this.props.rfqSettingValues?.vendorMasterIncoTerm) {
      // incoterm array
      if (!this.props.companyInfo?.inco_term_identifier) return false;
      let str = this.props.companyInfo?.inco_term_identifier;
      let arr = str.split(";");
      // let arr = ["DeliveryBasis", "DeliveryRemarks"];
      if (arr.includes(cell.Identifier)) {
        return "inco";
      }
      return false;
    }
    return false;
  };

  download = () => {
    this.setState({
      isLoading: true,
    });
    let destination = this.getDestinationValue();
    const dest = destination?.split("-");
    const code = Object.values(this.props.items).map((item) => item.code);
    if (destination == "") {
      this.setState({
        isLoading: false,
      });

      return false;
    }
    axios
      .post(
        downloadmaterialDescription,
        {
          buyerID: this.props.userInfo.division_id,
          pr_dest_code: dest[dest.length - 1],
          items: code,
        },
        {
          responseType: "blob", // Move responseType option inside the configuration object
        }
      )
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        const href = window.webkitURL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Material Description.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  getFieldLocal = (cell) => {
    let error =
      this.state.additionalInfoTemplate[cell?.FieldId] &&
      this.state.additionalInfoTemplate[cell?.FieldId]["error"];
    let ErrorMessage =
      this.state.additionalInfoTemplate[cell?.FieldId] &&
      this.state.additionalInfoTemplate[cell?.FieldId]["ErrorMessage"];
    let name =
      this.state.additionalInfoTemplate[cell?.FieldId] &&
      this.state.additionalInfoTemplate[cell?.FieldId]["HTMLAttributeName"];
    let value =
      this.state.additionalInfoTemplate[cell?.FieldId] &&
      this.state.additionalInfoTemplate[cell?.FieldId]["value"];
    if (cell?.ElementType === "T" && !Array.isArray(value)) {
      const renderTextField = (value, error, ErrorMessage, name) => (
        <TextField
          key={this.state.prevRFQLoading}
          fullWidth
          value={value || ""}
          className="local_template_textfield"
          variant="outlined"
          label={
            cell.FieldLabel +
            (cell.InstructionText ? ` (${cell.InstructionText})` : "")
          }
          multiline
          maxRows={3}
          type="text"
          error={error}
          helperText={error && ErrorMessage}
          name={name}
        />
      );

      if (
        this.props.multiplePlantsFlag &&
        (cell.Identifier === "Destination" ||
          cell.Identifier === "DestinationForeign")
      ) {
        if (value && !value.includes("###") && !this.props.multiplePlantsFlag) {
          return renderTextField(value, error, ErrorMessage, name);
        }
      } else {
        if (value) {
          return renderTextField(value, error, ErrorMessage, name);
        } else {
          return <Typography>{cell.FieldLabel}</Typography>;
        }
      }

      // Do not render anything if the FieldLabel is "Destination" and value contains "###"
      return null;
    } else if (
      (cell.ElementType === "S") & this.props.multiplePlantsFlag &&
      (cell.Identifier === "Destination" ||
        (cell.Identifier === "DestinationForeign" &&
          value &&
          value.includes("###")))
    ) {
      return null;
    } else if (cell?.ElementType === "TB") {
      return (
        <TextField
          key={this.state.prevRFQLoading}
          className="local_template_textfield"
          fullWidth
          variant="outlined"
          label={
            cell.FieldLabel +
            (cell.InstructionText ? ` (${cell.InstructionText})` : "")
          }
          type="text"
          error={error}
          helperText={error && ErrorMessage}
          name={name}
          value={value ? value : ""}
          onChange={(e) => {
            this.handleLocalChange(e, cell.FieldId);
            this.validateInputAdditionalInfo(cell.FieldId);
          }}
          InputProps={
            (cell.Identifier === "FreightChargesForeign" ||
              cell.Identifier === "FreightChargesLocal" ||
              cell.Identifier === "LoadingCharges" ||
              cell.Identifier === "UpoadingCharges" ||
              (cell.CurrencyFlag === "1" && cell.CurrencyFormula != "") ||
              cell.Identifier === "TwoPointDelivery" ||
              cell.Identifier === "DetentionCharges") && {
              endAdornment: (
                <InputAdornment position="start">
                  {getSymbol(
                    this.props?.lastTransactionTypeInThread === "Inquiry" &&
                      (this.props?.vendorType === "International" ||
                        this.props.vendorType === "Local Importer")
                      ? this.state.selectedCurrency
                      : this.props.quotedCurrency
                  )}
                </InputAdornment>
              ),
            }
          }
        />
      );
    } else if (cell?.ElementType === "TA") {
      return (
        <TextField
          key={this.state.prevRFQLoading}
          className="local_template_textarea"
          fullWidth
          multiline
          variant="outlined"
          maxRows={3}
          label={
            cell.FieldLabel +
            (cell.InstructionText ? ` (${cell.InstructionText})` : "")
          }
          error={error}
          helperText={error && ErrorMessage}
          name={name}
          value={value ? value : ""}
          onChange={(e) => {
            this.handleLocalChange(e, cell.FieldId);
            this.validateInputAdditionalInfo(cell.FieldId);
          }}
        />
      );
    } else if (cell?.ElementType === "M") {
      return (
        <Checkbox
          color="primary"
          checked={!!value}
          name={name}
          onChange={(e) => {
            this.handleLocalChange(e, cell.FieldId);
          }}
        />
      );
    } else if (cell?.ElementType === "DT") {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            format="dd/MM/yyyy"
            sx={{ width: "100%" }}
            slotProps={{
              textField: {
                size: "fullWidth",
                error: error,
                helperText: error && (cell.ErrorMessage || "Invalid Date"),
              },
            }}
            label={
              cell.FieldLabel +
              (cell.InstructionText ? ` (${cell.InstructionText})` : "")
            }
            name={name}
            value={value ? new Date(value) : null}
            onChange={(newVal) => {
              this.handleDateChange(
                moment(newVal),
                cell.FieldId,
                "additionalInfoTemplate"
              );
            }}
          />
        </LocalizationProvider>
      );
    } else if (cell?.ElementType === "F") {
      return (
        <FormControl error={error} sx={{ width: "100%" }}>
          <Box
            sx={{
              border: "solid 1px",
              borderColor: (theme) => theme.palette.bluegrey[500],
              borderRadius: "4px",
              minWidth: "100%",
              height: "56px",
              display: "flex",
              alignItems: "center",
              padding: "0 10px",
              position: "relative",
            }}
          >
            <InputLabel
              sx={{
                color: "text.secondary",
                backgroundColor: (theme) => theme.palette.grey[0],
                position: "absolute",
                left: 0,
                top: 0,
                paddingLeft: "5px",
                paddingRight: "5px",
                transformOrigin: "top left",
                webkitTransform: " translate(14px, -9px) scale(0.75)",
                transform: "translate(14px, -9px) scale(0.75)",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "calc(133% - 24px)",
                zIndex: 1,
              }}
            >
              {cell.FieldLabel +
                (cell.InstructionText ? ` (${cell.InstructionText})` : "")}
            </InputLabel>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                this.setState({
                  currentFieldId: cell.FieldId,
                  currentTemplate: "additionalInfoTemplate",
                });
                if (
                  value !== null &&
                  value !== undefined &&
                  value !== "" &&
                  value.length > 0 &&
                  value[0]?.id
                ) {
                  this.getExistingFilesForAttachment(value);
                } else {
                  this.setState({
                    dynamicFileUploadOpen: true,
                  });
                }
              }}
            >
              <UploadIcon sx={{ marginRight: "10px" }} />
              Attachment
            </Button>
            <FormHelperText sx={{ ml: "auto" }}>
              Number Of Files {value?.length ? value?.length : 0}
            </FormHelperText>
          </Box>
          <Typography variant="caption">
            {this.state.additionalInfoTemplate[cell.HTMLAttributeName]}
          </Typography>
          {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
        </FormControl>
      );
    } else if (
      (this.props.PermissionInquiry?.is_payment_term === "Y" &&
        this.props.companyInfo.payment_term_identifier
          .split(";")
          .includes(cell.Identifier)) ||
      (this.props.PermissionInquiry?.is_inco_term === "Y" &&
        this.props.companyInfo.inco_term_identifier
          .split(";")
          .includes(cell.Identifier))
    ) {
      if (this.props.MassNego && typeof value === "object") {
        return (
          <React.Fragment>
            <Button
              className="local_template_textfield"
              fullWidth
              variant="outlined"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                border: "1x solid",
                borderColor: (theme) => theme.palette.bluegrey[500],
                padding: "16.5px 14px",
                color: "text.primary",
                fontWeight: 400,
                fontSize:
                  "calc(14px - (14 - 16) * ((100vw - 300px) / (1920 - 300)))",
                lineHeight: 1,
                textTransform: "capitalize",
              }}
              onClick={() => {
                this.handleLocalVendorTermsOpen(cell.Identifier, value);
              }}
              endIcon={
                <OpenInNew
                  sx={{ color: (theme) => theme.palette.primary.main }}
                />
              }
            >
              {cell.FieldLabel}
            </Button>
          </React.Fragment>
        );
      } else {
        if (
          this.state.additionalInfoTemplate[cell.FieldId] &&
          this.state.additionalInfoTemplate[cell.FieldId]["options"] &&
          this.state.additionalInfoTemplate[cell.FieldId]["options"] !=
            "loading" &&
          this.state.additionalInfoTemplate[cell.FieldId]["options"].length > 0
        ) {
          return (
            <TextField
              key={this.state.prevRFQLoading}
              fullWidth
              value={value || ""}
              className="textfield"
              variant="outlined"
              label={
                cell.FieldLabel +
                (cell.InstructionText ? ` (${cell.InstructionText})` : "")
              }
              multiline
              maxRows={3}
              type="text"
              error={error}
              helperText={error && ErrorMessage}
              name={name}
            />
          );
        }
      }
    } else if (cell?.ElementType === "S") {
      if (this.props.multiplePlantsFlag && cell.Identifier === "GSTType") {
        // GSTType will not shown in common in case of multiple plants
        return null;
      }

      return (
        <FormControl fullWidth variant="outlined" error={error}>
          <InputLabel>
            {cell.FieldLabel +
              (cell.InstructionText ? ` (${cell.InstructionText})` : "")}
          </InputLabel>
          <Tooltip title={value} disableInteractive placement="top-start">
            <Select
              className="template_dropdown"
              label={
                cell.FieldLabel +
                (cell.InstructionText ? ` (${cell.InstructionText})` : "")
              }
              name={name}
              attr-id={cell.FieldId}
              id="additionalSelect"
              value={value ? value : ""}
              onChange={(e) => {
                this.handleLocalChange(e, cell.FieldId);
              }}
              onBlur={(e) => {
                this.validateInputAdditionalInfo(cell.FieldId);
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 250,
                    width: 250,
                  },
                },
              }}
            >
              <MenuItem value={""}>Select</MenuItem>
              {this.state.additionalInfoTemplate[cell.FieldId] &&
                this.state.additionalInfoTemplate[cell.FieldId]["options"] &&
                (this.state.additionalInfoTemplate[cell.FieldId]["options"]
                  .length > 0 ? (
                  this.state.additionalInfoTemplate[cell.FieldId]["options"] ===
                  "loading" ? (
                    <MenuItem value="">Loading...</MenuItem>
                  ) : (
                    this.state.additionalInfoTemplate[cell.FieldId][
                      "options"
                    ].map((option, id) => {
                      return (
                        <MenuItem
                          key={id}
                          value={
                            option.dropdown_element_name
                              ? this.props.companyInfo
                                  ?.default_dropdown_type === "10" ||
                                cell.Identifier === "Destination" ||
                                cell.Identifier === "DestinationForeign" ||
                                cell.Identifier === "Currency"
                                ? `${option.dropdown_element_name}-${option.dropdown_element_code}`
                                : `${option.dropdown_element_code}-${option.dropdown_element_name}`
                              : option.code
                          }
                        >
                          <Typography style={{ whiteSpace: "pre-line" }}>
                            {option.dropdown_element_name
                              ? option.dropdown_element_code ===
                                option.dropdown_element_name
                                ? option.dropdown_element_name
                                : `${option.dropdown_element_code}-${option.dropdown_element_name}`
                              : option.name}
                          </Typography>
                        </MenuItem>
                      );
                    })
                  )
                ) : (
                  <MenuItem value="">Not found</MenuItem>
                ))}
            </Select>
          </Tooltip>
          {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
        </FormControl>
      );
    } else if (cell?.ElementType === "T" && Array.isArray(value)) {
      return (
        <FormControl error={error} sx={{ width: "100%" }}>
          <Box
            sx={{
              border: "solid 1px",
              borderColor: (theme) => theme.palette.bluegrey[500],
              borderRadius: "4px",
              minWidth: "100%",
              height: "56px",
              display: "flex",
              alignItems: "center",
              padding: "0 10px",
              position: "relative",
            }}
          >
            <InputLabel
              sx={{
                color: "text.secondary",
                backgroundColor: (theme) => theme.palette.grey[0],
                position: "absolute",
                left: 0,
                top: 0,
                paddingLeft: "5px",
                paddingRight: "5px",
                transformOrigin: "top left",
                webkitTransform: " translate(14px, -9px) scale(0.75)",
                transform: "translate(14px, -9px) scale(0.75)",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "calc(133% - 24px)",
                zIndex: 1,
              }}
            >
              {cell.FieldLabel +
                (cell.InstructionText ? ` (${cell.InstructionText})` : "")}
            </InputLabel>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                this.handleFileSpecificationsOpen(value, cell.FieldLabel);
              }}
            >
              <UploadIcon sx={{ marginRight: "10px" }} />
              View Attachment
            </Button>
            <FormHelperText sx={{ ml: "auto" }}>
              Number Of Files {value?.length ? value?.length : 0}
            </FormHelperText>
          </Box>
          <Typography variant="caption">
            {this.state.additionalInfoTemplate[cell.HTMLAttributeName]}
          </Typography>
          {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
        </FormControl>
      );
    } else {
      return cell?.FieldLabel;
    }
  };

  finddependacy = (FieldId, validation) => {
    let field_ids = validation?.match(/f(\d)*/g);
    let is_dependacy = 0;
    for (const field_id of field_ids) {
      if ("f" + FieldId != field_id) {
        is_dependacy = 1;
        break;
      }
    }
    return is_dependacy;
  };

  findMSdependacy = (FieldId, validation) => {
    let field_ids = validation?.match(/f(\d)*/g);
    let is_dependacy = 0;
    if (field_ids) {
      for (const field_id of field_ids) {
        if ("f" + FieldId != field_id) {
          is_dependacy = 1;
          break;
        }
      }
    }
    return is_dependacy;
  };

  validateAdditionalInfo = (FieldId, index, submitting) => {
    const { vendorType } = this.props;
    const { additionalInfoTemplate, errorArray } = this.state;
    const fieldTemplate = additionalInfoTemplate[FieldId];
    const fieldLabel = fieldTemplate?.FieldLabel;

    // if (vendorType === "Local" && fieldLabel === "Currency") {
    if (fieldLabel === "Currency") {
      return null; // Skip further processing for "Currency"
    }

    if (
      submitting &&
      fieldTemplate?.error &&
      !this.finddependacy(FieldId, fieldTemplate.Validation)
    ) {
      return;
    }
    const validation = fieldTemplate?.Validation;
    if (validation) {
      const temp = validation.replace(/f(\d)+/g, (match) => {
        const id = match.replace("f", "");
        const dependentField = additionalInfoTemplate[id];
        return dependentField
          ? dependentField.value === null
            ? '""'
            : `this.state.additionalInfoTemplate[${id}].value`
          : "";
      });

      if (temp !== null) {
        if (fieldTemplate.ElementType === "DT") {
          fieldTemplate.value =
            fieldTemplate.value && fieldTemplate.value != "Invalid Date"
              ? moment(fieldTemplate.value).format("YYYY-MM-DD")
              : "";
        }
        const isValid = eval(temp);
        const updatedFieldTemplate = {
          ...fieldTemplate,
          error: !isValid,
        };

        const updatedErrorArray = isValid
          ? errorArray.filter(
              (attrName) => attrName !== fieldTemplate.HTMLAttributeName
            )
          : [...new Set([...errorArray, fieldTemplate.HTMLAttributeName])];

        this.setState((prevState) => ({
          ...prevState,
          additionalInfoTemplate: {
            ...prevState.additionalInfoTemplate,
            [FieldId]: updatedFieldTemplate,
          },
          errorArray: updatedErrorArray,
        }));
      } else {
        console.log("dependent field not found in template");
      }
    }
  };

  validateInput = (FieldId, index, submitting, item) => {
    const mainTemplateSet = new Set(Object.keys(this.state.mainTemplate));
    let filteredTransactionItemSequence =
      this.props.transactionItemSequence.filter((item) =>
        mainTemplateSet.has(item)
      );
    let itemKey = filteredTransactionItemSequence[index];

    const currentItem = this.state.mainTemplate[itemKey];

    if (currentItem?.selected === "N") {
      Object.keys(currentItem).forEach((field) => {
        if (currentItem[field] && currentItem[field]["HTMLAttributeName"]) {
          const errorIndex = this.state.errorArray.indexOf(
            currentItem[field]["HTMLAttributeName"]
          );
          if (errorIndex !== -1) {
            this.state.errorArray.splice(errorIndex, 1);
          }
        }
      });

      this.setState({
        errorArray: this.state.errorArray,
      });
      // If selected is "N", skip validation
      return;
    }

    if (submitting) {
      if (
        currentItem[FieldId] &&
        currentItem[FieldId]["error"] &&
        currentItem[FieldId]["Validation"] &&
        !this.finddependacy(FieldId, currentItem[FieldId]["Validation"])
      ) {
        if (
          !this.state.errorArray.includes(
            currentItem[FieldId]["HTMLAttributeName"]
          )
        ) {
          this.state.errorArray.push(currentItem[FieldId]["HTMLAttributeName"]);
          this.setState({
            errorArray: this.state.errorArray,
          });
          return;
        }
      }
    }

    if (currentItem[FieldId] && currentItem[FieldId]["Validation"]) {
      let temp = currentItem[FieldId]["Validation"].replace(
        /f(\d)+/g,
        (match) => {
          if (currentItem[match.replace("f", "")]) {
            return `this.state.mainTemplate[${itemKey}][${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else if (
            this.state.additionalInfoTemplate[match.replace("f", "")]
          ) {
            return `this.state.additionalInfoTemplate[${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else if (this.state.IntlTemplate[match.replace("f", "")]) {
            return `this.state.IntlTemplate[${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else if (this.state.EvalResults[itemKey][match.replace("f", "")]) {
            return `this.state.EvalResults[${itemKey}][${match.replace(
              "f",
              ""
            )}]`;
          } else {
            return null;
          }
        }
      );

      if (temp !== null) {
        if (eval(temp)) {
          currentItem[FieldId]["error"] = false;

          this.setState((prevState) => ({
            ...prevState,
            mainTemplate: {
              ...prevState.mainTemplate,
              [itemKey]: {
                ...prevState.mainTemplate[itemKey],
                [FieldId]: {
                  ...prevState.mainTemplate[itemKey][FieldId],
                  error: false,
                },
              },
            },
          }));

          let index1 = this.state.errorArray.indexOf(
            currentItem[FieldId]["HTMLAttributeName"]
          );
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }
        } else {
          currentItem[FieldId]["error"] = true;

          this.setState((prevState) => ({
            ...prevState,
            mainTemplate: {
              ...prevState.mainTemplate,
              [itemKey]: {
                ...prevState.mainTemplate[itemKey],
                [FieldId]: {
                  ...prevState.mainTemplate[itemKey][FieldId],
                  error: true,
                },
              },
            },
          }));

          if (
            !this.state.errorArray.includes(
              currentItem[FieldId]["HTMLAttributeName"]
            )
          ) {
            this.state.errorArray.push(
              currentItem[FieldId]["HTMLAttributeName"]
            );
          }
        }
        this.setState({
          mainTemplate: this.state.mainTemplate,
          errorArray: this.state.errorArray,
        });
      } else {
        console.log("dependent field not found in template");
      }
    }
  };

  validateInputAdditionalInfo = (FieldId, index, submitting) => {
    const additionalInfoTemplate = this.state.additionalInfoTemplate;
    const field = additionalInfoTemplate[FieldId];

    if (!field) {
      console.error(
        `Field with FieldId ${FieldId} not found in additionalInfoTemplate.`
      );
      return;
    }

    if (field["Identifier"] === "Currency") {
      // skip validation for currency
      return;
    }

    // Check if the field is required (if it has an asterisk in the FieldLabel)
    const isRequired = true; //fields validation check on onchange for additional info fields
    //  field["FieldLabel"]
    //   ? field["FieldLabel"].includes("*")
    //   : false;

    // Determine if we should validate this field
    const shouldValidate = isRequired || submitting;
    if (shouldValidate) {
      // Evaluate if field has dependencies that are satisfied
      const dependenciesSatisfied = this.findDependency(
        FieldId,
        field["Validation"]
      );
      if (!dependenciesSatisfied) {
        return;
      }

      // Proceed with validation if required or dependencies are satisfied
      if (field["Validation"]) {
        let temp = field["Validation"].replace(/f(\d)+/g, (match) => {
          const matchField = additionalInfoTemplate[match.replace("f", "")];
          return matchField
            ? `additionalInfoTemplate[${match.replace("f", "")}]["value"]`
            : null;
        });

        if (temp !== null) {
          // Log the actual values used in the validation expression
          const evalString = temp.replace(
            /additionalInfoTemplate\[(\d+)\]\["value"\]/g,
            (match, p1) => {
              return JSON.stringify(additionalInfoTemplate[p1]["value"]);
            }
          );

          let evalResult;
          try {
            evalResult = eval(evalString);
          } catch (error) {
            console.error("Error evaluating expression:", evalString, error);
            evalResult = false; // If evaluation fails, consider it as invalid
          }

          // Prepare updated errorArray
          if (evalResult) {
            // Validation passed, remove error
            field["error"] = false;
            let errorIndex = this.state.errorArray.indexOf(
              field["HTMLAttributeName"]
            );
            if (errorIndex !== -1) {
              this.state.errorArray.splice(errorIndex, 1);
            }
          } else {
            // Validation failed, add error
            field["error"] = true;
          }

          // Update state with new error status and errorArray
          this.setState((prevState) => ({
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [FieldId]: {
                ...prevState.additionalInfoTemplate[FieldId],
                error: field["error"],
              },
            },
          }));
        } else {
          console.log("dependent field not found in template");
        }
      }
    }
  };

  validateInputForMultiSol = (FieldId, mainIndex, subIndex, submitting) => {
    const currentItem = this.state.multiSolmainTemplate[mainIndex][subIndex];
    const currentMainTemplateItemKey = this.state.mainTemplate[mainIndex];
    const errorKey = mainIndex;
    // Skip validation if the FieldId corresponds to GSTType
    if (this.state.switchState[mainIndex]) {
      if (currentMainTemplateItemKey.selected !== "N") {
        if (submitting) {
          if (
            currentItem[FieldId] &&
            currentItem[FieldId].error &&
            currentItem[FieldId].Validation &&
            !this.findMSdependacy(FieldId, currentItem[FieldId].Validation)
          ) {
            if (
              !this.state.errorArrayForMS[errorKey]?.[subIndex]?.includes(
                currentItem[FieldId].HTMLAttributeName
              )
            ) {
              this.setState((prevState) => {
                const newErrorArray = { ...prevState.errorArrayForMS };
                if (!newErrorArray[errorKey]) {
                  newErrorArray[errorKey] = {};
                }
                if (!newErrorArray[errorKey][subIndex]) {
                  newErrorArray[errorKey][subIndex] = [];
                }
                newErrorArray[errorKey][subIndex].push(
                  currentItem[FieldId].HTMLAttributeName
                );
                return { errorArrayForMS: newErrorArray };
              });
              return;
            }
          }
        }
        if (currentItem[FieldId] && currentItem[FieldId]["Validation"]) {
          let temp = currentItem[FieldId]["Validation"].replace(
            /f(\d)+/g,
            (match) => {
              const fieldId = match.replace("f", "");
              if (currentItem[fieldId]) {
                return `this.state.multiSolmainTemplate[${mainIndex}][${subIndex}][${fieldId}]["value"]`;
              } else {
                console.log(`dependent field ${fieldId} not found in template`);
                return null;
              }
            }
          );

          if (temp !== null) {
            if (eval(temp)) {
              currentItem[FieldId]["error"] = false;
              this.setState((prevState) => {
                const newErrorArray = { ...prevState.errorArrayForMS };

                if (newErrorArray[errorKey]?.[subIndex]) {
                  const index = newErrorArray[errorKey][subIndex].indexOf(
                    currentItem[FieldId].HTMLAttributeName
                  );
                  if (index !== -1) {
                    newErrorArray[errorKey][subIndex].splice(index, 1);
                  }
                  // Remove the subIndex if it's empty
                  if (newErrorArray[errorKey][subIndex].length === 0) {
                    delete newErrorArray[errorKey][subIndex];
                  }
                  // Remove the mainIndex if it's empty
                  if (Object.keys(newErrorArray[errorKey]).length === 0) {
                    delete newErrorArray[errorKey];
                  }
                }

                return {
                  multiSolmainTemplate: {
                    ...prevState.multiSolmainTemplate,
                    [mainIndex]: {
                      ...prevState.multiSolmainTemplate[mainIndex],
                      [subIndex]: {
                        ...prevState.multiSolmainTemplate[mainIndex][subIndex],
                        [FieldId]: {
                          ...prevState.multiSolmainTemplate[mainIndex][
                            subIndex
                          ][FieldId],
                          error: false,
                        },
                      },
                    },
                  },
                  errorArrayForMS: newErrorArray,
                };
              });
            } else {
              currentItem[FieldId]["error"] = true;
              this.setState((prevState) => {
                let newErrorArray = { ...prevState.errorArrayForMS };

                if (!newErrorArray[errorKey]) {
                  newErrorArray[errorKey] = {};
                }
                if (!newErrorArray[errorKey][subIndex]) {
                  newErrorArray[errorKey][subIndex] = [];
                }
                if (
                  !newErrorArray[errorKey][subIndex].includes(
                    currentItem[FieldId].HTMLAttributeName
                  )
                ) {
                  newErrorArray[errorKey][subIndex].push(
                    currentItem[FieldId].HTMLAttributeName
                  );
                }

                return {
                  multiSolmainTemplate: {
                    ...prevState.multiSolmainTemplate,
                    [mainIndex]: {
                      ...prevState.multiSolmainTemplate[mainIndex],
                      [subIndex]: {
                        ...prevState.multiSolmainTemplate[mainIndex][subIndex],
                        [FieldId]: {
                          ...prevState.multiSolmainTemplate[mainIndex][
                            subIndex
                          ][FieldId],
                          error: true,
                        },
                      },
                    },
                  },
                  errorArrayForMS: newErrorArray,
                };
              });
            }

            this.setState({
              multiSolmainTemplate: this.state.multiSolmainTemplate,
            });
          } else {
            console.log("dependent field not found in template");
          }
        }
      }
    }
  };

  validateInputForSlab = (FieldId, mainIndex, subIndex, submitting) => {
    const currentItem = this.state.slabMainTemplate[mainIndex][subIndex];
    const currentItemSelected = this.state.slabMainTemplate[mainIndex].selected;
    const errorKey = mainIndex;

    // Check if select_slab is undefined or "Y", continue with normal flow
    if (
      (currentItem.select_slab === undefined ||
        currentItem.select_slab === "Y") &&
      currentItemSelected !== "N"
    ) {
      if (submitting) {
        if (
          currentItem[FieldId] &&
          currentItem[FieldId].error &&
          currentItem[FieldId].Validation &&
          !this.findMSdependacy(FieldId, currentItem[FieldId].Validation)
        ) {
          if (
            !this.state.errorArrayForSlab[errorKey]?.[subIndex]?.includes(
              currentItem[FieldId].HTMLAttributeName
            )
          ) {
            this.setState((prevState) => {
              const newErrorArray = { ...prevState.errorArrayForSlab };
              if (!newErrorArray[errorKey]) {
                newErrorArray[errorKey] = {};
              }
              if (!newErrorArray[errorKey][subIndex]) {
                newErrorArray[errorKey][subIndex] = [];
              }
              newErrorArray[errorKey][subIndex].push(
                currentItem[FieldId].HTMLAttributeName
              );
              return { errorArrayForSlab: newErrorArray };
            });
            return;
          }
        }
      }

      if (currentItem[FieldId] && currentItem[FieldId]["Validation"]) {
        let temp = currentItem[FieldId]["Validation"].replace(
          /f(\d)+/g,
          (match) => {
            const fieldId = match.replace("f", "");
            if (currentItem[fieldId]) {
              return `this.state.slabMainTemplate[${mainIndex}][${subIndex}][${fieldId}]["value"]`;
            } else {
              console.log(`dependent field ${fieldId} not found in template`);
              return null;
            }
          }
        );

        if (temp !== null) {
          if (eval(temp)) {
            currentItem[FieldId]["error"] = false;
            this.setState((prevState) => {
              const newErrorArray = { ...prevState.errorArrayForSlab };
              if (newErrorArray[errorKey]?.[subIndex]) {
                const index = newErrorArray[errorKey][subIndex].indexOf(
                  currentItem[FieldId].HTMLAttributeName
                );
                if (index !== -1) {
                  newErrorArray[errorKey][subIndex].splice(index, 1);
                }
                if (newErrorArray[errorKey][subIndex].length === 0) {
                  delete newErrorArray[errorKey][subIndex];
                }
                if (Object.keys(newErrorArray[errorKey]).length === 0) {
                  delete newErrorArray[errorKey];
                }
              }

              return {
                slabMainTemplate: {
                  ...prevState.slabMainTemplate,
                  [mainIndex]: {
                    ...prevState.slabMainTemplate[mainIndex],
                    [subIndex]: {
                      ...prevState.slabMainTemplate[mainIndex][subIndex],
                      [FieldId]: {
                        ...prevState.slabMainTemplate[mainIndex][subIndex][
                          FieldId
                        ],
                        error: false,
                      },
                    },
                  },
                },
                errorArrayForSlab: newErrorArray,
              };
            });
          } else {
            currentItem[FieldId]["error"] = true;
            this.setState((prevState) => {
              const newErrorArray = { ...prevState.errorArrayForSlab };
              if (!newErrorArray[errorKey]) {
                newErrorArray[errorKey] = {};
              }
              if (!newErrorArray[errorKey][subIndex]) {
                newErrorArray[errorKey][subIndex] = [];
              }
              if (
                !newErrorArray[errorKey][subIndex].includes(
                  currentItem[FieldId].HTMLAttributeName
                )
              ) {
                newErrorArray[errorKey][subIndex].push(
                  currentItem[FieldId].HTMLAttributeName
                );
              }

              return {
                slabMainTemplate: {
                  ...prevState.slabMainTemplate,
                  [mainIndex]: {
                    ...prevState.slabMainTemplate[mainIndex],
                    [subIndex]: {
                      ...prevState.slabMainTemplate[mainIndex][subIndex],
                      [FieldId]: {
                        ...prevState.slabMainTemplate[mainIndex][subIndex][
                          FieldId
                        ],
                        error: true,
                      },
                    },
                  },
                },
                errorArrayForSlab: newErrorArray,
              };
            });
          }

          this.setState({
            slabMainTemplate: this.state.slabMainTemplate,
          });
        } else {
          console.log("dependent field not found in template");
        }
      }
    }
  };

  // Helper function to find dependencies and check if they are satisfied
  findDependency = (FieldId, validation) => {
    let dependenciesSatisfied = true;
    const matches = validation?.match(/f(\d+)/g);
    if (matches) {
      matches.forEach((match) => {
        const dependentFieldId = match.replace("f", "");
        if (this.state.additionalInfoTemplate[dependentFieldId]) {
          let dependentFieldValue =
            this.state.additionalInfoTemplate[dependentFieldId]["value"];
          if (dependentFieldValue == null) {
            dependenciesSatisfied = false;
          }
        }
      });
    }
    return dependenciesSatisfied;
  };

  handleLocalChange = (e, FieldId) => {
    const { value, type, checked } = e.target;

    const { additionalInfoTemplate } = this.state;
    additionalInfoTemplate[FieldId]["value"] =
      type !== "checkbox" ? value : checked;
    // e.target.blur();
    this.setState({
      additionalInfoTemplate,
    });
  };

  handleShipmentChange = (value) => {
    this.setState({
      shipment_mark: value,
    });
  };

  setAttachmentsValue = (
    value,
    currentTemplate = null,
    currentFieldId = null
  ) => {
    this.setState((prevState) => ({
      ...prevState,
      [this.state.currentTemplate || currentTemplate]: {
        ...prevState[this.state.currentTemplate || currentTemplate],
        [this.state.currentFieldId || currentFieldId]: {
          ...prevState[this.state.currentTemplate || currentTemplate][
            this.state.currentFieldId || currentFieldId
          ],
          value: this.state[this.state.currentTemplate || currentTemplate][
            this.state.currentFieldId || currentFieldId
          ]["value"]
            ? [
                ...this.state[this.state.currentTemplate || currentTemplate][
                  this.state.currentFieldId || currentFieldId
                ]["value"],
                ...value,
              ]
            : value,
        },
      },
    }));
  };

  handleDateChange = (value, FieldId, state, index, Validation, key) => {
    if (state === "mainTemplate") {
      if (value && this.state.mainTemplate[key][FieldId]["value"] !== value) {
        this.setState({
          applyToAll: {
            ...this.state.applyToAll,
            [`${FieldId}_applyToAll`]: false,
          },
        });
      }
      this.setState((prevState) => ({
        mainTemplate: {
          ...prevState.mainTemplate,
          [key]: {
            ...prevState.mainTemplate[key],
            [FieldId]: {
              ...prevState.mainTemplate[key][FieldId],
              // value: value || "",
              // value: moment(value).format("DD/MM/YYYY") || "",
              value: moment(value).isValid()
                ? moment(value).format("YYYY-MM-DD") || ""
                : "",
            },
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        [state]: {
          ...prevState[state],
          [FieldId]: {
            ...prevState[state][FieldId],
            value: moment(value).isValid()
              ? moment(value).format("YYYY-MM-DD") || ""
              : "",
            // new Date(value) && new Date(value._d) && value._d != "Invalid Date" ? new Date(value._d) : "",
          },
        },
      }));
    }

    if (
      !(Validation?.trim() === "" && value === null) &&
      !(
        moment(value).isValid() &&
        (FieldId.match(/Validity/i)
          ? moment(value).isSame(moment(), "day") ||
            moment(value).isAfter(moment())
          : true)
      )
    ) {
      if (state === "mainTemplate") {
        this.setState((prevState) => ({
          mainTemplate: {
            ...prevState.mainTemplate,
            [key]: {
              ...prevState.mainTemplate[key],
              [FieldId]: {
                ...prevState.mainTemplate[key][FieldId],
                error: true,
              },
            },
          },
        }));

        if (
          !this.state.errorArray.includes(
            this.state.mainTemplate[key][FieldId]["HTMLAttributeName"]
          )
        ) {
          this.setState((prevState) => ({
            errorArray: [
              ...prevState.errorArray,
              this.state.mainTemplate[key][FieldId]["HTMLAttributeName"],
            ],
          }));
        }
      } else {
        this.setState((prevState) => ({
          [state]: {
            ...prevState[state],
            [FieldId]: {
              ...prevState[state][FieldId],
              error: true,
            },
          },
        }));
      }
    } else {
      if (state === "mainTemplate") {
        this.setState((prevState) => ({
          mainTemplate: {
            ...prevState.mainTemplate,
            [key]: {
              ...prevState.mainTemplate[key],
              [FieldId]: {
                ...prevState.mainTemplate[key][FieldId],
                error: false,
              },
            },
          },
        }));

        const index1 = this.state.errorArray.indexOf(
          this.state.mainTemplate[key][FieldId]["HTMLAttributeName"]
        );
        if (index1 !== -1) {
          this.setState((prevState) => ({
            errorArray: prevState.errorArray.filter((_, i) => i !== index1),
          }));
        }
      } else {
        this.setState((prevState) => ({
          [state]: {
            ...prevState[state],
            [FieldId]: {
              ...prevState[state][FieldId],
              error: false,
            },
          },
        }));
      }
    }
  };

  handleDateMSChange = (value, FieldId, state, index, Validation, key) => {
    const { multiSolmainTemplate, multiSolApplyToAll, mainTemplate } =
      this.state;

    if (state === "multiSolmainTemplate") {
      this.setState({
        multiSolApplyToAll: {
          ...multiSolApplyToAll,
          [`${key}_${FieldId}_multiSolApplyToAll`]: false, // Set Apply to All to false for the changed field
        },
      });

      this.setState((prevState) => {
        const updatedTxnData = {
          ...prevState.multiSolmainTemplate,
        };
        const selected = prevState.multiSolmainTemplate[key].selected; // Preserve the selected key

        updatedTxnData[key] = updatedTxnData[key].map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              [FieldId]: {
                ...item[FieldId],
                value: moment(value).isValid()
                  ? moment(value).format("YYYY-MM-DD") || ""
                  : "",
              },
            };
          }
          return item;
        });

        // Restore the selected key
        updatedTxnData[key].selected = selected;

        return {
          multiSolmainTemplate: updatedTxnData,
          ...(index === 0 &&
            multiSolmainTemplate[key].length > 1 && {
              mainTemplate: {
                ...prevState.mainTemplate,
                [key]: {
                  ...prevState.mainTemplate[key],
                  [FieldId]: {
                    ...prevState.mainTemplate[key][FieldId],
                    value: moment(value).isValid()
                      ? moment(value).format("YYYY-MM-DD") || ""
                      : "",
                  },
                },
              },
            }),
        };
      });
    } else {
      this.setState((prevState) => ({
        [state]: {
          ...prevState[state],
          [FieldId]: {
            ...prevState[state][FieldId],
            value:
              new Date(value) && new Date(value._d) ? new Date(value._d) : "",
          },
        },
      }));
    }

    // Error Handling
    if (
      !(Validation?.trim() === "" && value === null) &&
      !(
        moment(value).isValid() &&
        (FieldId.match(/Validity/i)
          ? moment(value).isSame(moment(), "day") ||
            moment(value).isAfter(moment())
          : true)
      )
    ) {
      if (state === "multiSolmainTemplate") {
        this.setState((prevState) => {
          const updatedTxnData = {
            ...prevState.multiSolmainTemplate,
          };
          const selected = prevState.multiSolmainTemplate[key].selected;

          updatedTxnData[key] = updatedTxnData[key].map((item, idx) => {
            if (idx === index) {
              return {
                ...item,
                [FieldId]: {
                  ...item[FieldId],
                  error: true,
                },
              };
            }
            return item;
          });

          updatedTxnData[key].selected = selected;

          return {
            multiSolmainTemplate: updatedTxnData,
          };
        });

        if (
          !this.state.errorArray.includes(
            this.state.multiSolmainTemplate[key][index][FieldId][
              "HTMLAttributeName"
            ]
          )
        ) {
          this.setState((prevState) => ({
            errorArray: [
              ...prevState.errorArray,
              this.state.multiSolmainTemplate[key][index][FieldId][
                "HTMLAttributeName"
              ],
            ],
          }));
        }
      } else {
        this.setState((prevState) => ({
          [state]: {
            ...prevState[state],
            [FieldId]: {
              ...prevState[state][FieldId],
              error: true,
            },
          },
        }));
      }
    } else {
      if (state === "multiSolmainTemplate") {
        this.setState((prevState) => {
          const updatedTxnData = {
            ...prevState.multiSolmainTemplate,
          };
          const selected = prevState.multiSolmainTemplate[key].selected;

          updatedTxnData[key] = updatedTxnData[key].map((item, idx) => {
            if (idx === index) {
              return {
                ...item,
                [FieldId]: {
                  ...item[FieldId],
                  error: false,
                },
              };
            }
            return item;
          });

          updatedTxnData[key].selected = selected;

          return {
            multiSolmainTemplate: updatedTxnData,
          };
        });

        const index1 = this.state.errorArray.indexOf(
          this.state.multiSolmainTemplate[key][index][FieldId][
            "HTMLAttributeName"
          ]
        );
        if (index1 !== -1) {
          this.setState((prevState) => ({
            errorArray: prevState.errorArray.filter((_, i) => i !== index1),
          }));
        }
      } else {
        this.setState((prevState) => ({
          [state]: {
            ...prevState[state],
            [FieldId]: {
              ...prevState[state][FieldId],
              error: false,
            },
          },
        }));
      }
    }
  };

  handleDateSlabChange = (value, FieldId, state, index, Validation, key) => {
    const { slabMainTemplate, slabApplyToAll } = this.state;

    if (state === "slabMainTemplate") {
      this.setState({
        slabApplyToAll: {
          ...slabApplyToAll,
          [`${key}_${FieldId}_slabApplyToAll`]: false, // Set Apply to All to false for the changed field
        },
      });

      this.setState((prevState) => {
        const updatedTxnData = { ...prevState.slabMainTemplate };

        // Preserve the selected key
        const selected = prevState.slabMainTemplate[key].selected;

        // Update the specific field in the selected row
        updatedTxnData[key] = updatedTxnData[key].map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              [FieldId]: {
                ...item[FieldId],
                value: moment(value).isValid()
                  ? moment(value).format("YYYY-MM-DD") || ""
                  : "",
              },
            };
          }
          return item;
        });

        // Restore the selected key
        updatedTxnData[key].selected = selected;

        return {
          slabMainTemplate: updatedTxnData,
        };
      });
    } else {
      this.setState((prevState) => ({
        [state]: {
          ...prevState[state],
          [FieldId]: {
            ...prevState[state][FieldId],
            value:
              new Date(value) && new Date(value._d) ? new Date(value._d) : "",
          },
        },
      }));
    }

    // Error Handling
    if (
      !(Validation?.trim() === "" && value === null) &&
      !(
        moment(value).isValid() &&
        (FieldId.match(/Validity/i)
          ? moment(value).isSame(moment(), "day") ||
            moment(value).isAfter(moment())
          : true)
      )
    ) {
      if (state === "slabMainTemplate") {
        this.setState((prevState) => {
          const updatedTxnData = { ...prevState.slabMainTemplate };

          // Preserve the selected key
          const selected = prevState.slabMainTemplate[key].selected;

          updatedTxnData[key] = updatedTxnData[key].map((item, idx) => {
            if (idx === index) {
              return {
                ...item,
                [FieldId]: {
                  ...item[FieldId],
                  error: true,
                },
              };
            }
            return item;
          });

          // Restore the selected key
          updatedTxnData[key].selected = selected;

          return {
            slabMainTemplate: updatedTxnData,
          };
        });

        if (
          !this.state.errorArray.includes(
            this.state.slabMainTemplate[key][index][FieldId][
              "HTMLAttributeName"
            ]
          )
        ) {
          this.setState((prevState) => ({
            errorArray: [
              ...prevState.errorArray,
              this.state.slabMainTemplate[key][index][FieldId][
                "HTMLAttributeName"
              ],
            ],
          }));
        }
      } else {
        this.setState((prevState) => ({
          [state]: {
            ...prevState[state],
            [FieldId]: {
              ...prevState[state][FieldId],
              error: true,
            },
          },
        }));
      }
    } else {
      if (state === "slabMainTemplate") {
        this.setState((prevState) => {
          const updatedTxnData = { ...prevState.slabMainTemplate };

          // Preserve the selected key
          const selected = prevState.slabMainTemplate[key].selected;

          updatedTxnData[key] = updatedTxnData[key].map((item, idx) => {
            if (idx === index) {
              return {
                ...item,
                [FieldId]: {
                  ...item[FieldId],
                  error: false,
                },
              };
            }
            return item;
          });

          // Restore the selected key
          updatedTxnData[key].selected = selected;

          return {
            slabMainTemplate: updatedTxnData,
          };
        });

        const index1 = this.state.errorArray.indexOf(
          this.state.slabMainTemplate[key][index][FieldId]["HTMLAttributeName"]
        );
        if (index1 !== -1) {
          this.setState((prevState) => ({
            errorArray: prevState.errorArray.filter((_, i) => i !== index1),
          }));
        }
      } else {
        this.setState((prevState) => ({
          [state]: {
            ...prevState[state],
            [FieldId]: {
              ...prevState[state][FieldId],
              error: false,
            },
          },
        }));
      }
    }
  };

  handleDateWithOutSlabChange = (
    value,
    FieldId,
    state,
    index,
    Validation,
    key
  ) => {
    const { slabMainTemplate } = this.state;

    if (state === "slabMainTemplate") {
      if (
        value &&
        slabMainTemplate[key] &&
        slabMainTemplate[key][0]?._range === undefined
      ) {
        this.setState((prevState) => {
          const updatedTxnData = { ...prevState.slabMainTemplate };

          // Preserve the selected key
          const selected = prevState.slabMainTemplate[key].selected;

          updatedTxnData[key] = updatedTxnData[key].map((item, idx) => {
            if (idx === 0) {
              return {
                ...item,
                [FieldId]: {
                  ...item[FieldId],
                  value: moment(value).isValid()
                    ? moment(value).format("YYYY-MM-DD") || ""
                    : "",
                },
              };
            }
            return item;
          });

          // Restore the selected key
          updatedTxnData[key].selected = selected;

          return {
            slabMainTemplate: updatedTxnData,
          };
        });
      }
    } else {
      this.setState((prevState) => ({
        [state]: {
          ...prevState[state],
          [FieldId]: {
            ...prevState[state][FieldId],
            value:
              new Date(value) && new Date(value._d) ? new Date(value._d) : "",
          },
        },
      }));
    }

    // Error Handling
    if (
      !(Validation?.trim() === "" && value === null) &&
      !(
        moment(value).isValid() &&
        (FieldId.match(/Validity/i)
          ? moment(value).isSame(moment(), "day") ||
            moment(value).isAfter(moment())
          : true)
      )
    ) {
      if (state === "slabMainTemplate") {
        this.setState((prevState) => {
          const updatedTxnData = { ...prevState.slabMainTemplate };

          // Preserve the selected key
          const selected = prevState.slabMainTemplate[key].selected;

          updatedTxnData[key] = updatedTxnData[key].map((item, idx) => {
            if (idx === 0) {
              return {
                ...item,
                [FieldId]: {
                  ...item[FieldId],
                  error: true,
                },
              };
            }
            return item;
          });

          // Restore the selected key
          updatedTxnData[key].selected = selected;

          return {
            slabMainTemplate: updatedTxnData,
          };
        });

        if (
          !this.state.errorArray.includes(
            this.state.slabMainTemplate[key][0][FieldId]["HTMLAttributeName"]
          )
        ) {
          this.setState((prevState) => ({
            errorArray: [
              ...prevState.errorArray,
              this.state.slabMainTemplate[key][0][FieldId]["HTMLAttributeName"],
            ],
          }));
        }
      } else {
        this.setState((prevState) => ({
          [state]: {
            ...prevState[state],
            [FieldId]: {
              ...prevState[state][FieldId],
              error: true,
            },
          },
        }));
      }
    } else {
      if (state === "slabMainTemplate") {
        this.setState((prevState) => {
          const updatedTxnData = { ...prevState.slabMainTemplate };

          // Preserve the selected key
          const selected = prevState.slabMainTemplate[key].selected;

          updatedTxnData[key] = updatedTxnData[key].map((item, idx) => {
            if (idx === 0) {
              return {
                ...item,
                [FieldId]: {
                  ...item[FieldId],
                  error: false,
                },
              };
            }
            return item;
          });

          // Restore the selected key
          updatedTxnData[key].selected = selected;

          return {
            slabMainTemplate: updatedTxnData,
          };
        });

        const index1 = this.state.errorArray.indexOf(
          this.state.slabMainTemplate[key][0][FieldId]["HTMLAttributeName"]
        );
        if (index1 !== -1) {
          this.setState((prevState) => ({
            errorArray: prevState.errorArray.filter((_, i) => i !== index1),
          }));
        }
      } else {
        this.setState((prevState) => ({
          [state]: {
            ...prevState[state],
            [FieldId]: {
              ...prevState[state][FieldId],
              error: false,
            },
          },
        }));
      }
    }
  };

  handleLocalVendorTermsOpen = (identifier, value) => {
    if (this.props.companyInfo.payment_term_identifier) {
      let arr = this.props.companyInfo.payment_term_identifier.split(";");
      if (arr.includes(identifier)) {
        this.setState({
          title: "Payment Term",
        });
      }
    }
    if (this.props.companyInfo.inco_term_identifier) {
      let arr1 = this.props.companyInfo.inco_term_identifier.split(";");
      if (arr1.includes(identifier)) {
        this.setState({
          title: "Inco Term",
        });
      }
    }
    const output = Object.entries(value).map(([id, details]) => ({
      id,
      ...details,
    }));
    this.setState({
      vendorTermsOpen: true,
      vendorTermData: output,
    });
  };
  closeVendorTerms = () => {
    this.setState({
      vendorTermsOpen: false,
      vendorTermData: {},
      title: "",
    });
  };

  handleNegotiationSubmit = (event) => {
    let newErr = [];
    let solutionNameErrors = {};
    let errorArrayForMSParentName = {};
    if (!this.props.multipleVendors) {
      event.preventDefault(); // Prevent default form submission behavior
    }
    if (this.props.multipleSolutionFlag && this.props.slabFlag) {
      this.setState({
        errorArray: [],
        errorArrayForSlab: [],
      });
    }
    if (this.props.multipleSolutionFlag && !this.props.slabFlag) {
      this.setState({
        errorArray: [],
        errorArrayForMS: [],
      });
    }
    if (this.props.multipleSolutionFlag && !this.props.slabFlag) {
      // Reset previous errors
      this.setState({
        // errorArrayForMSParentName: {}, // Clear previous parent solution name errors
      });

      // Validate solution names
      const solutionNames = this.state.solutionNames;
      const uniqueNamesSet = new Set();

      Object.keys(solutionNames).forEach((key) => {
        const solutionName = solutionNames[key];

        if (
          (solutionName === "" || solutionName.trim() === "") &&
          this.state.switchState[key.split("-")[0]]
        ) {
          if (this.state.mainTemplate[key.split("-")[0]]?.selected === "Y") {
            solutionNameErrors[key] = "Solution name cannot be empty";
          }
        } else if (
          solutionName !== "" &&
          uniqueNamesSet.has(solutionName) &&
          this.state.switchState[key.split("-")[0]]
        ) {
          // Solution name is duplicated
          if (this.state.mainTemplate[key.split("-")[0]]?.selected === "Y") {
            solutionNameErrors[key] = "Solution name must be unique";
          }
        } else {
          // Add the name to the set for uniqueness checking
          uniqueNamesSet.add(solutionName);
        }
      });

      // If there are errors in solution names, update state and display error
      if (Object.keys(solutionNameErrors).length > 0) {
        this.setState({
          solutionNameErrors: solutionNameErrors,
        });
        // return; // Stop the form submission if there are errors
      }

      // Validate errorArrayForMSParentName
      Object.keys(this.state.solutionNameMainTemplate).forEach((key) => {
        if (
          this.state.solutionNameMainTemplate[key] === "" &&
          this.state.mainTemplate[key]?.selected === "Y" &&
          this.state.switchState[key]
        ) {
          errorArrayForMSParentName[key] = "Solutions name cannot be empty";
        }
      });

      // If there are errors in errorArrayForMSParentName, update state and display error
      if (Object.keys(errorArrayForMSParentName).length > 0) {
        this.setState({
          errorArrayForMSParentName: errorArrayForMSParentName,
        });
      }
    }

    {
      !this.props.multipleSolutionFlag &&
        !this.props.slabFlag &&
        Object.keys(this.state.mainTemplate).forEach((item, index) => {
          for (const key in this.state.mainTemplate[item]) {
            this.validateInput(key, index, true, item);
          }
        });
    }

    Object.keys(this.state.additionalInfoTemplate).forEach((item, index) => {
      if (this.state.additionalInfoTemplate[item]?.VisibilityFlag === "Y") {
        this.validateInputAdditionalInfo(item, index, true);
      }
    });

    Object.keys(this.state.additionalInfoTemplate).forEach((item, index) => {
      if (this.state.additionalInfoTemplate[item]?.VisibilityFlag === "Y") {
        this.validateAdditionalInfo(item, index, true);
      }
    });

    // Validate multiSolmainTemplate
    {
      this.props.multipleSolutionFlag &&
        !this.props.slabFlag &&
        Object.keys(this.state.multiSolmainTemplate).forEach((mainIndex) => {
          this.state.multiSolmainTemplate[mainIndex].forEach(
            (subItem, subIndex) => {
              for (const key in subItem) {
                if (this.state.mainTemplate[mainIndex]?.selected == "Y") {
                  this.validateInputForMultiSol(key, mainIndex, subIndex, true);
                }
              }
            }
          );
        });
    }

    {
      this.props.multipleSolutionFlag &&
        !this.props.slabFlag &&
        Object.keys(this.state.mainTemplate).forEach((item, index) => {
          for (const key in this.state.mainTemplate[item]) {
            // if(!this.state.switchState[key]){
            this.validateInput(key, index, true, item);
            // }
          }
        });
    }

    // Validate Slab
    {
      this.props.multipleSolutionFlag &&
        this.props.slabFlag &&
        Object.keys(this.state.slabMainTemplate).forEach((mainIndex) => {
          this.state.slabMainTemplate[mainIndex].forEach(
            (subItem, subIndex) => {
              for (const key in subItem) {
                this.validateInputForSlab(key, mainIndex, subIndex, true);
              }
            }
          );
        });
    }

    {
      this.props.lastTransactionTypeInThread === "Inquiry" &&
        this.props.vendorType === "Local" &&
        this.props.multiplePlantsFlag &&
        this.props.multipleSolutionFlag &&
        this.props.slabFlag &&
        Object.keys(this.state.mainTemplate).forEach((item, index) => {
          for (const key in this.state.mainTemplate[item]) {
            // Skip validation for all keys except "GSTType"
            if (this.state.mainTemplate[item][key].Identifier === "GSTType") {
              this.validateInput(key, index, true, item);
            }
          }
        });
    }

    const { dataFromDetailsSection } = this.state;
    let errorMessageDate = "";
    let errorMessageNumber = "";
    let errorMessageReference = "";
    let errorMessageAttachment = "";
    const queryParams = new URLSearchParams(window.location.search);
    if (dataFromDetailsSection) {
      const { negoValidity } = dataFromDetailsSection;
      if (!negoValidity) {
        errorMessageDate = "Please provide Negotiation validity Date.";
      }
    }

    const { selectedCurrency } = this.state;

    let message = "";
    const allSelectedN = Object.values(this.state.mainTemplate).every(
      (item) => item.selected === "N"
    );
    if (allSelectedN) {
      message = "Please select atleast one Product Category.";
    }

    const allSelectedNForSlab = Object.values(
      this.state.slabMainTemplate
    ).every((item) => item.selected === "N");
    if (
      this.props.multipleSolutionFlag &&
      this.props.slabFlag &&
      allSelectedNForSlab
    ) {
      message = "Please select atleast one Product Category.";
    }
    if (
      (this.props.vendorType === "International" ||
        this.props.vendorType === "Local Importer") &&
      // this.props.transactionType === "Inquiry" &&
      !selectedCurrency
    ) {
      this.setState({
        selectedCurrencyValidator: true,
        selectedCurrencyErrorMessage: "Please select currency from dropdown.",
        error: "Please select currency from dropdown.",
      });
    }

    this.setState({
      offlineDateError: errorMessageDate,
      offlineFieldError: errorMessageNumber,
      offlineReferenceError: errorMessageReference,
      error:
        errorMessageDate ||
        // errorMessageNumber ||
        // errorMessageReference
        ((this.props.vendorType === "International" ||
          this.props.vendorType === "Local Importer") &&
        !selectedCurrency
          ? "Please select currency from dropdown."
          : ""),
    });
    setTimeout(() => {
      if (
        message != "" ||
        errorMessageDate ||
        Object.keys(solutionNameErrors).length > 0 ||
        Object.keys(errorArrayForMSParentName).length > 0 ||
        errorMessageNumber ||
        errorMessageReference ||
        errorMessageAttachment ||
        this.state.errorArray.length ||
        (this.props.multipleSolutionFlag &&
          !this.props.slabFlag &&
          Object.keys(this.state.errorArrayForMS).length) ||
        (this.props.multipleSolutionFlag &&
          !this.props.slabFlag &&
          Object.keys(this.state.errorArrayForMSParentName).length) ||
        (this.props.multipleSolutionFlag &&
          this.props.slabFlag &&
          Object.keys(this.state.errorArrayForSlab).length) ||
        Object.keys(this.state.additionalInfoTemplate).some(
          (key) => this.state.additionalInfoTemplate[key].error === true
        ) ||
        newErr.length ||
        ((this.props.vendorType === "International" ||
          this.props.vendorType === "Local Importer") &&
          this.props.transactionType === "Inquiry" &&
          !selectedCurrency)
      ) {
        this.props.openSnackbar(
          "error",
          `There are errors in your form. Please resolve in order to continue.\n${message}`
        );
        if (this.props.multipleVendors) {
          this.props.errorFlag(true);
        }
        return false;
      }

      // Determine the confirmation message based on the status, vendor type, and selected currency
      const status = queryParams.get("supplier") ? "Online" : "Offline";
      let confirmAlertMsg = "Are you sure want to submit this Form?";

      if(this.props.negotiationJsonData.filter_data.sealed_rfq_post_nego){
        confirmAlertMsg = "Once this negotiation is submitted, the RFQ will be sealed. Please ensure you review the form details thoroughly before the final submission.";
      }

      if (
        this.props.vendorType === "International" ||
        this.props.vendorType === "Local Importer"
      ) {
        if (
          (this.props.vendorType === "International" ||
            this.props.vendorType === "Local Importer") &&
          this.state.selectedCurrency
        ) {
          let txnType = "Negotiation";
          confirmAlertMsg = `A ${txnType} will be submitted with ${this.state.selectedCurrency}. Are you sure you want to submit this ${txnType}?`;

          if(this.props.negotiationJsonData.filter_data.sealed_rfq_post_nego){
            confirmAlertMsg = `Once negotiation is submitted, the RFQ will be sealed. Review all details carefully. This negotiation with an international vendor includes ${this.state.selectedCurrency} 
            Are you sure you want to proceed?`;
          }
        }
      }

      if (this.props.multipleVendors) {
        this.setState({
          isDialogShown: true,
        });

        this.props.errorFlag(false);
        this.props.openAlert(true);
        if (this.props?.selectedCurrency) {
          this.props.selectedCurrency(this.state.selectedCurrency);
        }
      } else {
        this.setState({
          confirmDialogOpen: true,
          confirmAlertTitle: "Submission",
          confirmAlertMsg: confirmAlertMsg,
        });
      }
    }, 500);
    if (this.props.multipleVendors) {
      this.props.resetFlag(false);
    }
  };

  handleUplaod = (index, res) => {
    const { mainTemplate } = this.state;
    mainTemplate[this.props.selectedItems[index].id]["specification"] = res;
    this.setState({
      mainTemplate,
    });
  };

  handleApplyToAllClick = (FieldId, textbox = false, Identifier) => {
    // Retrieve keys from mainTemplate state
    const mainTemplateKeys = Object.keys(this.state.mainTemplate);
    // Create a Set from mainTemplate keys
    const mainTemplateSet = new Set(mainTemplateKeys);

    // Filter transaction item sequence based on mainTemplateSet
    const filteredTransactionItemSequence =
      this.props.transactionItemSequence.filter((item) =>
        mainTemplateSet.has(item)
      );

    let firstRowItemId = null;
    let updated = false;

    // Find the first selected item where switchState is false
    filteredTransactionItemSequence.forEach((itemid) => {
      const isSelected = this.state.mainTemplate[itemid].selected === "Y";
      const inquiryItemsIdLen =
        this.props.inquiryItemsId.length > 0
          ? this.props.inquiryItemsId.includes(Number(itemid))
          : true;

      // We need to process only items that are selected and have switchState set to false
      if (
        !updated &&
        isSelected &&
        inquiryItemsIdLen &&
        !this.state.switchState[itemid]
      ) {
        firstRowItemId = itemid;
        updated = true;
      }
    });

    if (!firstRowItemId) {
      console.log("No item found for applyToAll action");
      return;
    }

    // Validate if value is present and no error exists
    if (!this.state.mainTemplate[firstRowItemId][FieldId]?.value) {
      this.props.openSnackbar("error", "Please enter the value first");
      return;
    }

    if (this.state.mainTemplate[firstRowItemId][FieldId]?.error) {
      this.props.openSnackbar("error", "Please resolve error first");
      return;
    }

    // Apply the value to all selected items where switchState is false
    filteredTransactionItemSequence.forEach((itemid) => {
      if (
        this.state.mainTemplate[itemid].selected === "Y" &&
        !this.state.switchState[itemid]
      ) {
        let FieldIndex = this.state.errorArray.indexOf(
          this.state.mainTemplate[itemid][FieldId]?.HTMLAttributeName
        );

        if (FieldIndex !== -1) {
          this.state.errorArray.splice(FieldIndex, 1);
        }

        this.setState((prevState) => ({
          ...prevState,
          mainTemplate: {
            ...prevState.mainTemplate,
            [itemid]: {
              ...prevState.mainTemplate[itemid],
              [FieldId]: {
                ...prevState.mainTemplate[itemid][FieldId],
                error: false,
                value: this.state.mainTemplate[firstRowItemId][FieldId]?.value,
              },
              ...(Identifier === "ProductQuantity" && {
                qty: this.state.mainTemplate[firstRowItemId]["qty"],
              }),
              ...(["Budgetedrate", "CIFRate", "rate", "Rate"].includes(
                Identifier
              ) && {
                rate: this.state.mainTemplate[firstRowItemId]["rate"],
              }),
            },
          },
        }));
      }
    });

    // Toggle applyToAll state
    this.setState((prevState) => ({
      applyToAll: {
        ...prevState.applyToAll,
        [`${FieldId}_applyToAll`]:
          !prevState.applyToAll[`${FieldId}_applyToAll`],
      },
    }));
  };

  handleMSApplyToAllClick = (key, FieldId, textbox = false, Identifier) => {
    // Check if the field value is empty
    if (!this.state.multiSolmainTemplate[key][0][FieldId]["value"]) {
      this.props.openSnackbar(
        "error",
        "Please enter a value before applying to all rows."
      );
      return;
    }

    // Check if there is an error in the first row's field
    if (this.state.multiSolmainTemplate[key][0][FieldId]["error"]) {
      this.props.openSnackbar(
        "error",
        "Please resolve the error in the first row."
      );
      return;
    }

    // Extract the value to be applied from the first row
    const firstRowValue =
      this.state.multiSolmainTemplate[key][0][FieldId]["value"];
    // Apply the value to all rows for the specific key
    this.setState((prevState) => {
      const updatedDatas = { ...prevState.multiSolmainTemplate };

      // Only update the specific key
      updatedDatas[key] = updatedDatas[key].map((item) => ({
        ...item,
        [FieldId]: {
          ...item[FieldId],
          value: firstRowValue,
          error: false,
        },
        ...(Identifier === "ProductQuantity" ? { qty: firstRowValue } : {}),
        ...(Identifier === "Budgetedrate" ||
        Identifier === "CIFRate" ||
        Identifier === "rate" ||
        Identifier === "Rate"
          ? { rate: firstRowValue }
          : {}),
      }));

      return {
        multiSolmainTemplate: updatedDatas,
        multiSolApplyToAll: {
          ...prevState.multiSolApplyToAll,
          [`${key}_${FieldId}_multiSolApplyToAll`]:
            !prevState.multiSolApplyToAll[
              `${key}_${FieldId}_multiSolApplyToAll`
            ],
        },
      };
    });
  };

  handleSlabApplyToAllClick = (key, FieldId, textbox = false, Identifier) => {
    // Find the first row where select_slab is "Y" and get the value from that row
    const firstValidRow = this.state.slabMainTemplate[key]?.find(
      (row) => row.select_slab === "Y"
    );
    const firstRowValue = firstValidRow[FieldId]?.value;

    // Check if the field value is empty
    if (!firstRowValue) {
      this.props.openSnackbar(
        "error",
        "Please enter a value before applying to all rows."
      );
      return;
    }

    // Check if there is an error in the first row's field
    if (firstValidRow[FieldId]?.error) {
      this.props.openSnackbar(
        "error",
        "Please resolve the error in the first row."
      );
      return;
    }

    // Apply the value to all rows for the specific key where select_slab is "Y"
    this.setState((prevState) => {
      const updatedTxnData = { ...prevState.slabMainTemplate };

      // Preserve the selected key
      const selected = prevState.slabMainTemplate[key].selected;

      // Update only the rows where select_slab is "Y"
      updatedTxnData[key] = updatedTxnData[key].map((item) => {
        if (item.select_slab === "Y") {
          return {
            ...item,
            [FieldId]: {
              ...item[FieldId],
              value: firstRowValue,
              error: false,
            },
            ...(Identifier === "ProductQuantity" ? { qty: firstRowValue } : {}),
            ...(Identifier === "Budgetedrate" ||
            Identifier === "CIFRate" ||
            Identifier === "rate" ||
            Identifier === "Rate"
              ? { rate: firstRowValue }
              : {}),
          };
        }
        return item;
      });

      // Restore the selected key
      updatedTxnData[key].selected = selected;
      return {
        slabMainTemplate: updatedTxnData,
        slabApplyToAll: {
          ...prevState.slabApplyToAll,
          [`${key}_${FieldId}_slabApplyToAll`]:
            !prevState.slabApplyToAll[`${key}_${FieldId}_slabApplyToAll`],
        },
      };
    });
  };

  handleSlabParentApplyToAllClick = (
    key,
    FieldId,
    textbox = false,
    Identifier
  ) => {
    // Get the first row for the specified key
    const firstRow = this.state.slabMainTemplate[key]?.[0];
    const firstRowValue = firstRow?.[FieldId]?.value;

    // Check if the field value is empty in the first row
    if (!firstRowValue) {
      this.props.openSnackbar(
        "error",
        "Please enter a value before applying to all rows."
      );
      return;
    }

    // Check if there's an error in the first row's field
    if (firstRow?.[FieldId]?.error) {
      this.props.openSnackbar(
        "error",
        "Please resolve the error in the first row."
      );
      return;
    }

    // Get all keys from slabMainTemplate
    const allKeys = Object.keys(this.state.slabMainTemplate);

    // Filter rows where _range is undefined
    const rowsWithUndefinedRange = (rows) =>
      rows.filter((row) => row._range === undefined);

    this.setState((prevState) => {
      const updatedTxnDatas = { ...prevState.slabMainTemplate };
      const selected = prevState.slabMainTemplate[key].selected;

      allKeys.forEach((currentKey) => {
        const rows = updatedTxnDatas[currentKey];
        const filteredRows = rowsWithUndefinedRange(rows);

        filteredRows.forEach((row, rowIndex) => {
          updatedTxnDatas[currentKey][rowIndex] = {
            ...updatedTxnDatas[currentKey][rowIndex],
            [FieldId]: {
              ...updatedTxnDatas[currentKey][rowIndex][FieldId],
              value: firstRowValue,
              error: false,
            },
            // Update specific fields based on Identifier
            ...(Identifier === "ProductQuantity" ? { qty: firstRowValue } : {}),
            ...(Identifier === "Budgetedrate" ||
            Identifier === "CIFRate" ||
            Identifier === "rate" ||
            Identifier === "Rate"
              ? { rate: firstRowValue }
              : {}),
          };
        });
      });

      updatedTxnDatas[key].selected = selected;

      // Toggle the apply to all flag
      return {
        slabMainTemplate: updatedTxnDatas,
        slabParentApplyToAll: {
          ...prevState.slabParentApplyToAll,
          [`${FieldId}_slabParentApplyToAll`]:
            !prevState.slabParentApplyToAll[`${FieldId}_slabParentApplyToAll`],
        },
      };
    });
  };

  fetchTermsAndCondtions = () => {
    this.toggleLoaderArray("TermsAndCondtions");
    let newTermsAndConditions = this.props.termsAndConditions;
    let categoryId = this.props.selectedCategory?.id;
    if (
      Object.keys(newTermsAndConditions).length !== 0 &&
      newTermsAndConditions[categoryId]
    ) {
      this.setState({
        termsAndConditionsData: newTermsAndConditions[categoryId],
      });
      this.toggleLoaderArray("TermsAndCondtions");
    } else {
      const url = getTermsAndConditions(categoryId);
      axios
        .get(url)
        .then((res) => {
          this.setState({
            termsAndConditionsData: res.data?.data,
          });
          this.props.setTermsAndConditions({ [categoryId]: res.data?.data });
          this.toggleLoaderArray("TermsAndCondtions");
        })
        .catch((err) => {
          console.log(err, "getfilespecs error");
          this.toggleLoaderArray("TermsAndCondtions");
        });
    }
  };

  getDestinationValue = () => {
    for (const key in this.state.additionalInfoTemplate) {
      const element = this.state.additionalInfoTemplate[key];
      if (
        element.Identifier === "Destination" ||
        element.Identifier === "DestinationForeign"
      ) {
        return element.value;
      }
    }
  };

  updateDeliveryScheduleFieldValue = (value, item_id, FieldId, RowIndex) => {
    if (typeof RowIndex != "undefined") {
      this.setState((prevState) => ({
        multiSolmainTemplate: {
          ...prevState.multiSolmainTemplate,
          [item_id]: prevState.multiSolmainTemplate[item_id].map(
            (item, index) =>
              index === RowIndex
                ? {
                    ...item,
                    [FieldId]: {
                      ...item[FieldId],
                      value,
                    },
                  }
                : item
          ),
        },
      }));
      if (RowIndex == 0) {
        this.setState((prevState) => ({
          ...prevState,
          mainTemplate: {
            ...prevState.mainTemplate,
            [item_id]: {
              ...prevState.mainTemplate[item_id],
              [FieldId]: {
                ...prevState.mainTemplate[item_id]?.[FieldId],
                value,
              },
            },
          },
        }));
      }
    } else {
      this.setState((prevState) => ({
        ...prevState,
        mainTemplate: {
          ...prevState.mainTemplate,
          [item_id]: {
            ...prevState.mainTemplate[item_id],
            [FieldId]: {
              ...prevState.mainTemplate[item_id]?.[FieldId],
              value,
            },
          },
        },
      }));
    }
  };

  updateDeliveryScheduleFieldValueSlab = (
    value,
    item_id,
    FieldId,
    RowIndex
  ) => {
    if (typeof RowIndex != "undefined") {
      this.setState((prevState) => {
        const updatedTxnData = prevState.slabMainTemplate[item_id].map(
          (item, idx) => {
            if (idx === RowIndex) {
              return {
                ...item,
                [FieldId]: {
                  ...item[FieldId],
                  value: value,
                  // error: value,
                },
              };
            }
            return item;
          }
        );

        // Preserve the selected key
        updatedTxnData.selected = prevState.slabMainTemplate[item_id].selected;

        return {
          ...prevState,
          slabMainTemplate: {
            ...prevState.slabMainTemplate,
            [item_id]: updatedTxnData,
          },
        };
      });
    } else {
      this.setState((prevState) => {
        const updatedTxnData = prevState.slabMainTemplate[item_id].map(
          (item, idx) => {
            if (idx === 0) {
              return {
                ...item,
                [FieldId]: {
                  ...item[FieldId],
                  value: value,
                  // error: value,
                },
              };
            }
            return item;
          }
        );

        // Preserve the selected key
        updatedTxnData.selected = prevState.slabMainTemplate[item_id].selected;

        return {
          ...prevState,
          slabMainTemplate: {
            ...prevState.slabMainTemplate,
            [item_id]: updatedTxnData,
          },
        };
      });
    }
  };

  getSumOfField = (data, fieldId) => {
    let sum = 0;
    for (let key in data) {
      if (data[key][fieldId]) {
        sum += parseFloat(data[key][fieldId].value);
      }
    }
    return sum;
  };

  arrangedEval = (formula, key, result, identifier) => {
    if (typeof formula != "undefined") {
      let pattern = /f(\d+)/g;
      const matches = [...formula.matchAll(pattern)];
      const matchedIdentifiersFormula = matches.map((match) => match[1]);
      matchedIdentifiersFormula.forEach((formulaIdentifier) => {
        if (result?.hasOwnProperty(formulaIdentifier)) {
          formula = formula.replace(
            `f${formulaIdentifier}`,
            result[formulaIdentifier]
              ? !isNaN(parseFloat(result[formulaIdentifier]))
                ? parseFloat(result[formulaIdentifier])
                : `"${result[formulaIdentifier]}"`
              : 0
          );
        } else if (
          this.state.additionalInfoTemplate.hasOwnProperty(formulaIdentifier)
        ) {
          if (
            typeof this.state.additionalInfoTemplate[formulaIdentifier]
              ?.value === "string" &&
            this.state.additionalInfoTemplate[
              formulaIdentifier
            ]?.value.includes("###")
          ) {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              `"${
                this.state.additionalInfoTemplate[
                  formulaIdentifier
                ]?.value.split("###")[0]
              }"`
            );
          } else {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              this.state.additionalInfoTemplate[formulaIdentifier]?.value
                ? !isNaN(
                    parseFloat(
                      this.state.additionalInfoTemplate[formulaIdentifier]
                        ?.value
                    )
                  )
                  ? parseFloat(
                      this.state.additionalInfoTemplate[formulaIdentifier]
                        ?.value
                    )
                  : `"${this.state.additionalInfoTemplate[formulaIdentifier]?.value}"`
                : 0
            );
          }
        } else {
          if (
            !this.state.mainTemplate[key]?.hasOwnProperty(formulaIdentifier)
          ) {
            let defValue = this.state.allItemsTemplate.find((item) => {
              return item.FieldId == formulaIdentifier;
            });
            if (
              defValue &&
              defValue?.ElementType != "D" &&
              !defValue.DefaultValue.hasOwnProperty("Formula") &&
              !defValue.DefaultValue.includes("(f")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                parseFloat(defValue?.DefaultValue) || 0
              );
            }
          } else {
            if (
              typeof this.state.mainTemplate[key]?.[formulaIdentifier]
                ?.value === "string" &&
              this.state.mainTemplate[key]?.[
                formulaIdentifier
              ]?.value?.includes("###")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                `"${
                  this.state.mainTemplate[key]?.[
                    formulaIdentifier
                  ]?.value.split("###")[0]
                }"`
              );
            } else {
              if (
                identifier == "TotalReqQty" ||
                identifier == "TotalReqQtyFor" ||
                identifier == "TotalQuantity"
              ) {
                const sumOfFieldQty = this.getSumOfField(
                  this.state.mainTemplate,
                  formulaIdentifier
                );
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  sumOfFieldQty
                );
              } else {
                let replaceValue = this.state.mainTemplate[key]?.[
                  formulaIdentifier
                ]?.value
                  ? !isNaN(
                      parseFloat(
                        this.state.mainTemplate[key]?.[formulaIdentifier]?.value
                      )
                    ) &&
                    isFinite(
                      this.state.mainTemplate[key]?.[formulaIdentifier]?.value
                    )
                    ? parseFloat(
                        this.state.mainTemplate[key]?.[formulaIdentifier]?.value
                      )
                    : `"${this.state.mainTemplate[key]?.[formulaIdentifier]?.value}"`
                  : 0;
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  replaceValue
                );
              }
            }
          }
        }
      });
      return formula;
    }
  };

  sumFieldValue = (data, fieldId) =>
    Object.values(data)
      .map((item) => parseFloat(item[fieldId]) || 0)
      .reduce((acc, value) => acc + value, 0);

  findFalledSlabRange = (key, quantity) => {
    const solutions = this.props.fetchedTxn[1].itemize[key].solution[0].data;

    return solutions.find((solution) => {
      if (solution.select_slab === "Y") {
        const range = solution?._range;

        // Handle "More Than" case
        if (range && range.includes("More Than")) {
          const min = parseInt(range.split(" ")[2], 10);
          return quantity > min; // Match for "More Than" ranges
        }

        // Handle regular range case (e.g., "56-100")
        if (range) {
          const [min, max] = range.split("-").map(Number);
          return quantity >= min && quantity <= max; // Match for range
        }
      }
      return false; // No match
    });
  };

  prepareNegotiationPayload = () => {
    let { additionalInfoTemplate, mainTemplate, msmainTemplate } = this.state;
    const { lastTransactionData, JsonTemplate, negotiationJsonData } =
      this.props;
    if (this.props.itemSpecific) {
      mainTemplate = {
        ...mainTemplate,
        ...this.state.notFilteredMainTemplate,
      };
    }
    let finalRemainingTxnData = {};

    // Prepare itemize object
    let item_id_arr =
      this.props.inquiryItemsId.length > 0
        ? this.props.inquiryItemsId
        : Object.keys(mainTemplate);
    // item_id_arr.forEach((item_id) => {
    let EvalResultWithItem = {};
    this.props.transactionItemSequence.forEach((item_id) => {
      let arr = [];
      let obj = {};
      let key = item_id;
      let EvalResults = {};
      this.state.rearrangedArray.forEach((defaultIdentifier) => {
        let formula = defaultIdentifier.DefaultValue.Formula;
        let newFormula = this.arrangedEval(
          formula,
          key,
          EvalResults,
          defaultIdentifier.Identifier
        );
        if (
          newFormula &&
          newFormula.includes("ExchangeRate_SelectedCurrency")
        ) {
          let exchangeRate = this.props.currencyOptions.find(
            (currency) =>
              currency.Currency ==
              (this.state.selectedCurrency || this.props.quotedCurrency)
          );
          if (exchangeRate) {
            newFormula = newFormula.replace(
              /ExchangeRate_SelectedCurrency/g,
              exchangeRate.ExchangeRate
            );
          }
        }

        if (
          newFormula &&
          newFormula.includes("ExchangeRate_BuyerLocalCurrency")
        ) {
          let exchangeRate = this.props.currencyOptions.find(
            (currency) =>
              currency.Currency === (this.props.currencyDetails || "INR")
          );
          if (exchangeRate) {
            newFormula = newFormula.replace(
              /ExchangeRate_BuyerLocalCurrency/g,
              exchangeRate.ExchangeRate
            );
          }
        }

        if (newFormula && newFormula.includes("(f")) {
          const match = newFormula.match(/f\d+/)[0];
          newFormula = newFormula.replace(match, 0);
        }
        // Now set the property
        if (!EvalResultWithItem[item_id]) {
          EvalResultWithItem[item_id] = {};
        }

        EvalResultWithItem[item_id][defaultIdentifier.FieldId] = parseFloat(
          eval(newFormula)
        ).toFixed(3);
        EvalResults[defaultIdentifier.FieldId] = parseFloat(
          eval(newFormula)
        ).toFixed(3);
      });

      Object.entries(this.props.JsonTemplate.ITEMIZE[0]).forEach(
        (value, index) => {
          let field_id = value[1]?.FieldId;
          if (!obj[key]) {
            obj[key] = [];
            // obj[key][0] = {};
          }
          if (this.props.itemBoxId !== field_id) {
            if (
              this.state.switchState[key] &&
              this.props.multipleSolutionFlag &&
              !this.props.slabFlag &&
              msmainTemplate[key]
            ) {
              if (!obj[key][0]) {
                obj[key][0] = {};
              }

              Object.entries(msmainTemplate[key]).forEach(
                ([rowindex, rowdata]) => {
                  let msmaintemp = rowdata["mainData"];
                  if (!obj[key][rowindex + 1]) {
                    obj[key][rowindex + 1] = {};
                  }
                  if (msmaintemp[field_id]) {
                    obj[key][rowindex + 1][field_id] =
                      msmaintemp[field_id]?.value || "";
                    arr.push(field_id);
                    return;
                  }
                }
              );
            } else {
              if (!obj[key][0]) {
                obj[key][0] = {};
              }
              Object.entries(mainTemplate).forEach(([itemId, mainTemp]) => {
                if (mainTemp[field_id] && itemId === key) {
                  obj[key][0][field_id] =
                    mainTemp[field_id]?.value ||
                    // mainTemp[field_id]?.DefaultValue ||
                    "";
                  arr.push(field_id);
                  return;
                }
              });
            }
          }
        }
      );
      obj[key].forEach((rowdata, rowindex) => {
        Object.entries(JsonTemplate.ITEMIZE[0]).forEach(
          ([index, currentField]) => {
            if (
              this.state.mainTemplate &&
              this.state.mainTemplate[key] &&
              this.state.mainTemplate[key].hasOwnProperty(
                currentField.FieldId
              ) &&
              currentField.Identifier !== "Product" &&
              currentField.ElementType !== "M"
            ) {
              if (currentField.ElementType === "D") {
                obj[key][rowindex][currentField.FieldId] = String(
                  EvalResults[currentField.FieldId]
                );
              } else {
                obj[key][rowindex][currentField.FieldId] = String(
                  this.state.mainTemplate[key][currentField.FieldId]?.value
                );
              }
              // }
            } else {
              // else {

              if (arr.indexOf(currentField.FieldId) === -1) {
                let formula =
                  currentField.DefaultFormula ||
                  currentField.DefaultValue.Formula;
                if (this.props.itemBoxId !== currentField.FieldId) {
                  let value = 0;

                  if (formula && formula !== "") {
                    let placeholders = formula.match(/\(f\d+\)/g);
                    if (placeholders) {
                      try {
                        if (
                          currentField.ElementType === "D" &&
                          this.state.mainTemplate[key]?.selected === "Y"
                        ) {
                          if (
                            EvalResults.hasOwnProperty(currentField.FieldId)
                          ) {
                            value = EvalResults[currentField.FieldId];
                          }
                        } else {
                          let defaultFormula = currentField.DefaultFormula;
                          if (defaultFormula) {
                            const formattedResult =
                              this.extractAndFormat(defaultFormula);

                            if (
                              formattedResult &&
                              formattedResult.length >= 1
                            ) {
                              const valuesFound = formattedResult
                                .map((identifier, index) => {
                                  const attributeKey = identifier.split(".");
                                  const subStringAttrKey =
                                    attributeKey[1].substring(1);
                                  // Check if the solution array exists and has the identifier
                                  const valuesInSolution =
                                    lastTransactionData.itemize[key]?.solution
                                      ?.map(
                                        (obj) =>
                                          obj?.data[0]?.[subStringAttrKey]
                                      )
                                      ?.filter((value) => value !== undefined);

                                  // Fallback to checking in common if valuesInSolution is empty or undefined
                                  const fallbackValue =
                                    lastTransactionData.common?.[
                                      subStringAttrKey
                                    ];
                                  const fetchTxn = this.props.fetchedTxn?.[
                                    attributeKey[0]
                                  ]?.itemize[key]?.solution
                                    ?.map(
                                      (obj) => obj?.data[0]?.[subStringAttrKey]
                                    )
                                    ?.filter((value) => value !== undefined);
                                  if (
                                    valuesInSolution &&
                                    valuesInSolution.length > 0
                                  ) {
                                    return valuesInSolution;
                                  } else if (
                                    valuesInSolution &&
                                    valuesInSolution.length == 0 &&
                                    fetchTxn &&
                                    fetchTxn.length > 0
                                  ) {
                                    return fetchTxn;
                                  } else if (fallbackValue !== undefined) {
                                    return [fallbackValue];
                                  } else {
                                    return [];
                                  }
                                })
                                .flat();

                              let finalValue = null;
                              // Evaluate conditional statements in DefaultFormula
                              for (let i = 0; i < valuesFound.length; i++) {
                                if (valuesFound[i] !== null) {
                                  finalValue = valuesFound[i];
                                  break;
                                }
                              }
                              value = finalValue;
                            }
                          }
                        }
                      } catch (error) {
                        console.log("error in eval", error);
                      }
                    }
                  } else {
                    if (typeof currentField.DefaultValue == "object") {
                      value = 0;
                    } else {
                      value = currentField.DefaultValue || 0;
                    }
                  }
                  obj[key][rowindex][currentField.FieldId] = String(value || 0);
                  // }
                }
              }
              // }
            }
          }
        );
        if (!finalRemainingTxnData[key]) {
          finalRemainingTxnData[key] = [];
          finalRemainingTxnData[key][rowindex] = {};
        }
        finalRemainingTxnData[key][rowindex] = obj[key][rowindex];
      });
    });
    const common = {};
    const commonDElemnts = {};
    Object.keys(additionalInfoTemplate).map((fieldId, index) => {
      let fieldValue = additionalInfoTemplate[fieldId]?.value;
      if (additionalInfoTemplate[fieldId].VisibilityFlag === "Y") {
        const isAttachmentField =
          additionalInfoTemplate[fieldId].Identifier === "Attachment" ||
          additionalInfoTemplate[fieldId].Identifier === "AttachmentForeign";

        if (isAttachmentField) {
          if (Array.isArray(fieldValue)) {
            common[fieldId] = fieldValue.length > 0 ? fieldValue : "";
          } else {
            common[fieldId] = "";
          }
        } else {
          common[fieldId] = fieldValue != null ? fieldValue : "";
        }
      } else {
        let commonDelements = {};
        if (additionalInfoTemplate[fieldId]?.ElementType === "D") {
          if (
            additionalInfoTemplate[fieldId].Identifier == "TotalReqQty" ||
            additionalInfoTemplate[fieldId].Identifier == "TotalReqQtyFor" ||
            additionalInfoTemplate[fieldId].Identifier == "TotalQuantity"
          ) {
            let exactIndex = Object.keys(this.state.mainTemplate)[0];
            common[fieldId] = EvalResultWithItem[exactIndex][fieldId];
          } else {
            if (
              additionalInfoTemplate[fieldId].DefaultValue.hasOwnProperty(
                "Formula"
              ) &&
              additionalInfoTemplate[fieldId].DefaultValue.Formula != ""
            ) {
              let pattern = /f(\d+)/g;
              let formula =
                additionalInfoTemplate[fieldId].DefaultValue?.Formula;
              const matches = [...formula.matchAll(pattern)];
              const matchedIdentifiersFormula = matches.map(
                (match) => match[1]
              );
              matchedIdentifiersFormula.forEach((formulaIdentifier) => {
                let FromItemize = this.state.allItemsTemplate.find((field) => {
                  return (
                    field.FieldId === formulaIdentifier &&
                    field.HTMLAttributeName.includes("_")
                  );
                });
                if (FromItemize) {
                  if (FromItemize?.ElementType === "D") {
                    const sum = this.sumFieldValue(
                      EvalResultWithItem,
                      formulaIdentifier
                    );
                    formula = formula.replace(
                      `f${formulaIdentifier}`,
                      sum
                        ? !isNaN(parseFloat(sum))
                          ? parseFloat(sum)
                          : `"${sum}"`
                        : 0
                    );
                  } else {
                    const sumOfFieldQty = this.getSumOfField(
                      mainTemplate,
                      formulaIdentifier
                    );
                    formula = formula.replace(
                      `f${formulaIdentifier}`,
                      sumOfFieldQty
                    );
                  }
                } else if (commonDElemnts.hasOwnProperty(formulaIdentifier)) {
                  formula = formula.replace(
                    `f${formulaIdentifier}`,
                    parseFloat(commonDElemnts[formulaIdentifier])
                  );
                } else {
                  let exactIndex = Object.keys(this.state.mainTemplate)[0];
                  let replacedValue =
                    EvalResultWithItem[exactIndex][formulaIdentifier];
                  if (
                    EvalResultWithItem[exactIndex].hasOwnProperty(
                      formulaIdentifier
                    )
                  ) {
                    formula = formula.replace(
                      `f${formulaIdentifier}`,
                      replacedValue
                        ? !isNaN(parseFloat(replacedValue))
                          ? parseFloat(replacedValue)
                          : `"${replacedValue}"`
                        : 0
                    );
                  } else {
                    formula = formula.replace(
                      `f${formulaIdentifier}`,
                      this.state.additionalInfoTemplate[formulaIdentifier]
                        ?.value
                        ? !isNaN(
                            parseFloat(
                              this.state.additionalInfoTemplate[
                                formulaIdentifier
                              ]?.value
                            )
                          )
                          ? parseFloat(
                              this.state.additionalInfoTemplate[
                                formulaIdentifier
                              ]?.value
                            )
                          : `"${this.state.additionalInfoTemplate[formulaIdentifier]?.value}"`
                        : 0
                    );
                  }
                }
              });
              common[fieldId] = parseFloat(eval(formula)).toFixed(3);
              commonDElemnts[fieldId] = parseFloat(eval(formula)).toFixed(3);
            } else {
              if (
                typeof additionalInfoTemplate[fieldId].DefaultValue == "object"
              ) {
                common[fieldId] = 0;
              } else {
                common[fieldId] =
                  additionalInfoTemplate[fieldId].DefaultValue || 0;
              }
            }
          }
        } else {
          common[fieldId] = fieldValue != null ? fieldValue : "";
        }
      }
    });

    // for itemize field
    const itemize = {};
    this.props.transactionItemSequence.forEach((itemId) => {
      let solution = [];
      for (const rowindex in finalRemainingTxnData[itemId]) {
        let obj = {
          // currency: item.currency,
          data: [finalRemainingTxnData[itemId][rowindex]],
        };
        solution.push(obj);
      }
      if (mainTemplate.hasOwnProperty(itemId)) {
        const item = mainTemplate[itemId];
        const itemData = {
          selected: item.selected,
          formdetailRowId: item.formdetailRowId,
          itemBoxId: this.props.itemBoxId,
          solution: solution,
        };
        itemize[itemId] = itemData;
      }
    });
    // Construct payload
    const queryParams = new URLSearchParams(window.location.search);
    const supplier = parseInt(queryParams.get("supplier"), 10);
    // Use formattedDate wherever needed
    const payload = {
      inquiry_id: this.props.inquiryId,
      validity_date: dayjs(
        this?.state?.dataFromDetailsSection?.negoValidity
      ).format("YYYY-MM-DD HH:mm:ss IST"),
      tran_status: "Negotiation",
      currency: this.state.selectedCurrency || this.props.currencyDetails,
      terms_and_conditions_text: "",
      location_flag: this.props.vendorType === "Local" ? "L" : "I",
      shipment_mark: this.state.shipment_mark || "",
      template: {
        itemize: itemize,
        common: common,
      },

      vendor_id: this.props.vendorId,
    };
    if (this.props.vendorType !== "Local") {
      payload.exchange_rate = this.state.currencyExchangeRates;
    }
    payload.tl_guid = this.props.MassNego
      ? null
      : this.props.lastTransactionGuidInThread;
    if (this.props.isServiceRfq) {
      let dataSeq = this.props.transactionItemSequence.filter((seq) => {
        return Object.keys(this.state.mainTemplate).includes(seq);
      });

      payload.item_id_seq = this.props.itemSpecific
        ? dataSeq
        : this.props.transactionItemSequence;
      payload.item_ids_data = dataSeq;
      payload.filename = this.state.excelSourceName
        ? this.state.excelSourceName
        : this.props.ServiceFileName;
      payload.activity_template_id = this.props.activity_template_id;
      payload.serviceRFQ = this.props.isServiceRfq;
    }

    if (negotiationJsonData.filter_data.slab && this.props.MassNego) {
      Object.entries(payload.template.itemize).forEach(([key, item]) => {
        if (
          this.props.fetchedTxn[1].itemize[
            key
          ].solution[0].data[0].hasOwnProperty("_range")
        ) {
          const quantity = this.props.negotiationJsonData.items[key].quantity;
          const falledSlab = this.findFalledSlabRange(key, quantity);
          item.solution[0].data[0].select_slab = item.selected;
          item.solution[0].data[0]._range = falledSlab._range;
        }
      });
    } else if (
      negotiationJsonData.filter_data.multiple_solution &&
      this.props.MassNego
    ) {
      Object.entries(payload.template.itemize).forEach(([key, item]) => {
        /**
         * need change
         */
        const itemName = this.props.negotiationJsonData.items[key].name;
        item.solution[0].name = _.unescape(itemName);
      });
    }

    return payload;
  };

  arrangedEvalForMS = (formula, key, rowIndex, result, identifier) => {
    if (typeof formula !== "undefined") {
      let pattern = /f(\d+)/g;
      const matches = [...formula.matchAll(pattern)];
      const matchedIdentifiersFormula = matches.map((match) => match[1]);
      matchedIdentifiersFormula.forEach((formulaIdentifier) => {
        if (result[rowIndex]?.hasOwnProperty(formulaIdentifier)) {
          formula = formula.replace(
            `f${formulaIdentifier}`,
            result[rowIndex][formulaIdentifier]
              ? !isNaN(parseFloat(result[rowIndex][formulaIdentifier]))
                ? parseFloat(result[rowIndex][formulaIdentifier])
                : `"${result[rowIndex][formulaIdentifier]}"`
              : 0
          );
        } else if (
          this.state.additionalInfoTemplate.hasOwnProperty(formulaIdentifier)
        ) {
          if (
            typeof this.state.additionalInfoTemplate[formulaIdentifier]
              ?.value === "string" &&
            this.state.additionalInfoTemplate[
              formulaIdentifier
            ]?.value.includes("###")
          ) {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              `"${
                this.state.additionalInfoTemplate[
                  formulaIdentifier
                ]?.value.split("###")[0]
              }"`
            );
          } else {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              this.state.additionalInfoTemplate[formulaIdentifier]?.value
                ? !isNaN(
                    parseFloat(
                      this.state.additionalInfoTemplate[formulaIdentifier]
                        ?.value
                    )
                  )
                  ? parseFloat(
                      this.state.additionalInfoTemplate[formulaIdentifier]
                        ?.value
                    )
                  : `"${this.state.additionalInfoTemplate[formulaIdentifier]?.value}"`
                : 0
            );
          }
        } else {
          if (
            !this.state.multiSolmainTemplate?.[key]?.[rowIndex].hasOwnProperty(
              formulaIdentifier
            )
          ) {
            let defValue = this.state.allItemsTemplate.find((item) => {
              return item.FieldId == formulaIdentifier;
            });
            if (
              defValue &&
              defValue.ElementType != "D" &&
              !defValue.DefaultValue.hasOwnProperty("Formula") &&
              !defValue.DefaultValue.includes("(f")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                parseFloat(defValue.DefaultValue) || 0
              );
            }
          } else {
            if (
              typeof this.state.multiSolmainTemplate[key][rowIndex]?.[
                formulaIdentifier
              ]?.value === "string" &&
              this.state.multiSolmainTemplate[key][rowIndex]?.[
                formulaIdentifier
              ]?.value?.includes("###")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                `"${
                  this.state.multiSolmainTemplate[key][rowIndex]?.[
                    formulaIdentifier
                  ]?.value.split("###")[0]
                }"`
              );
            } else {
              if (
                identifier == "TotalReqQty" ||
                identifier == "TotalReqQtyFor" ||
                identifier == "TotalQuantity"
              ) {
                const sumOfFieldQty = this.getSumOfFieldForSlab(
                  this.state.multiSolmainTemplate,
                  formulaIdentifier
                );
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  sumOfFieldQty
                );
              } else {
                let replaceValue = this.state.multiSolmainTemplate[key][
                  rowIndex
                ]?.[formulaIdentifier]?.value
                  ? !isNaN(
                      parseFloat(
                        this.state.multiSolmainTemplate[key][rowIndex]?.[
                          formulaIdentifier
                        ]?.value
                      )
                    ) &&
                    isFinite(
                      this.state.multiSolmainTemplate[key][rowIndex]?.[
                        formulaIdentifier
                      ]?.value
                    )
                    ? parseFloat(
                        this.state.multiSolmainTemplate[key][rowIndex]?.[
                          formulaIdentifier
                        ]?.value
                      )
                    : `"${this.state.multiSolmainTemplate[key][rowIndex]?.[formulaIdentifier]?.value}"`
                  : 0;
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  replaceValue
                );
              }
            }
          }
        }
      });
      return formula;
    }
  };

  getSumOfFieldForSlab = (data, fieldId) => {
    let sum = 0;
    for (let key in data) {
      for (let rowIndex in data[key]) {
        if (data[key][rowIndex][fieldId]) {
          sum += parseFloat(data[key][rowIndex][fieldId].value);
        }
      }
    }
    return sum;
  };

  arrangedEvalForSlab = (formula, key, rowIndex, result, identifier) => {
    if (typeof formula !== "undefined") {
      let pattern = /f(\d+)/g;
      const matches = [...formula.matchAll(pattern)];
      const matchedIdentifiersFormula = matches.map((match) => match[1]);
      matchedIdentifiersFormula.forEach((formulaIdentifier) => {
        if (result[rowIndex]?.hasOwnProperty(formulaIdentifier)) {
          formula = formula.replace(
            `f${formulaIdentifier}`,
            result[rowIndex][formulaIdentifier]
              ? !isNaN(parseFloat(result[rowIndex][formulaIdentifier]))
                ? parseFloat(result[rowIndex][formulaIdentifier])
                : `"${result[rowIndex][formulaIdentifier]}"`
              : 0
          );
        } else if (
          this.state.additionalInfoTemplate.hasOwnProperty(formulaIdentifier)
        ) {
          if (
            typeof this.state.additionalInfoTemplate[formulaIdentifier]
              ?.value === "string" &&
            this.state.additionalInfoTemplate[
              formulaIdentifier
            ]?.value.includes("###")
          ) {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              `"${
                this.state.additionalInfoTemplate[
                  formulaIdentifier
                ]?.value.split("###")[0]
              }"`
            );
          } else {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              this.state.additionalInfoTemplate[formulaIdentifier]?.value
                ? !isNaN(
                    parseFloat(
                      this.state.additionalInfoTemplate[formulaIdentifier]
                        ?.value
                    )
                  )
                  ? parseFloat(
                      this.state.additionalInfoTemplate[formulaIdentifier]
                        ?.value
                    )
                  : `"${this.state.additionalInfoTemplate[formulaIdentifier]?.value}"`
                : 0
            );
          }
        } else {
          if (
            !this.state.slabMainTemplate?.[key]?.[rowIndex].hasOwnProperty(
              formulaIdentifier
            )
          ) {
            let defValue = this.state.allItemsTemplate.find((item) => {
              return item.FieldId == formulaIdentifier;
            });
            if (
              defValue &&
              defValue.ElementType != "D" &&
              !defValue.DefaultValue.hasOwnProperty("Formula") &&
              !defValue.DefaultValue.includes("(f")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                parseFloat(defValue.DefaultValue) || 0
              );
            }
          } else {
            if (
              typeof this.state.slabMainTemplate[key][rowIndex]?.[
                formulaIdentifier
              ]?.value === "string" &&
              this.state.slabMainTemplate[key][rowIndex]?.[
                formulaIdentifier
              ]?.value?.includes("###")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                `"${
                  this.state.slabMainTemplate[key][rowIndex]?.[
                    formulaIdentifier
                  ]?.value.split("###")[0]
                }"`
              );
            } else {
              if (
                identifier == "TotalReqQty" ||
                identifier == "TotalReqQtyFor" ||
                identifier == "TotalQuantity"
              ) {
                const sumOfFieldQty = this.getSumOfFieldForSlab(
                  this.state.slabMainTemplate,
                  formulaIdentifier
                );
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  sumOfFieldQty
                );
              } else {
                let replaceValue = this.state.slabMainTemplate[key][rowIndex]?.[
                  formulaIdentifier
                ]?.value
                  ? !isNaN(
                      parseFloat(
                        this.state.slabMainTemplate[key][rowIndex]?.[
                          formulaIdentifier
                        ]?.value
                      )
                    ) &&
                    isFinite(
                      this.state.slabMainTemplate[key][rowIndex]?.[
                        formulaIdentifier
                      ]?.value
                    )
                    ? parseFloat(
                        this.state.slabMainTemplate[key][rowIndex]?.[
                          formulaIdentifier
                        ]?.value
                      )
                    : `"${this.state.slabMainTemplate[key][rowIndex]?.[formulaIdentifier]?.value}"`
                  : 0;
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  replaceValue
                );
              }
            }
          }
        }
      });
      return formula;
    }
  };

  prepareNegotiationPayloadForMS = () => {
    let { additionalInfoTemplate, mainTemplate, multiSolmainTemplate } =
      this.state;
    const { lastTransactionData, JsonTemplate } = this.props;
    let newMultiSolDataTemplate = this.state.multiSolmainTemplate;
    let itemize = {};
    let multiSolItemize = {};
    let finalRemainingTxnData = {};
    let finalRemainingDataForMS = {};
    let EvalResultWithItemForMS = {};
    // Clear previous errors
    this.setState({ solutionNameErrors: {} });
    this.setState({ errorArrayForMSParentName: {} });
    if (this.props.itemSpecific) {
      mainTemplate = {
        ...mainTemplate,
        ...this.state.notFilteredMainTemplate,
      };
    }

    // Prepare itemize object
    let item_id_arr =
      this.props.inquiryItemsId.length > 0
        ? this.props.inquiryItemsId
        : Object.keys(mainTemplate);
    this.props.transactionItemSequence.forEach((item_id) => {
      let arr = [];
      let obj = {};
      let key = item_id;
      let EvalResults = {};
      let EvalMultiSolResults = {};
      Object.keys(this.state.switchState).forEach((key) => {
        if (!this.state.switchState[key]) {
          this.state.rearrangedArray.forEach((defaultIdentifier) => {
            let formula = defaultIdentifier.DefaultValue.Formula;
            let newFormula = this.arrangedEval(formula, key, EvalResults);
            if (
              newFormula &&
              newFormula.includes("ExchangeRate_SelectedCurrency")
            ) {
              let exchangeRate = this.props.currencyOptions.find(
                (currency) =>
                  currency.Currency ==
                  (this.state.selectedCurrency || this.props.quotedCurrency)
              );
              if (exchangeRate) {
                newFormula = newFormula.replace(
                  "ExchangeRate_SelectedCurrency",
                  exchangeRate.ExchangeRate
                );
              }
            }

            if (
              newFormula &&
              newFormula.includes("ExchangeRate_BuyerLocalCurrency")
            ) {
              let exchangeRate = this.props.currencyOptions.find(
                (currency) =>
                  currency.Currency === (this.props.currencyDetails || "INR")
              );
              if (exchangeRate) {
                newFormula = newFormula.replace(
                  "ExchangeRate_BuyerLocalCurrency",
                  exchangeRate.ExchangeRate
                );
              }
            }

            if (newFormula && newFormula.includes("(f")) {
              const match = newFormula.match(/f\d+/)[0];
              newFormula = newFormula.replace(match, 0);
            }
            // Now set the property
            if (!EvalResultWithItemForMS[item_id]) {
              EvalResultWithItemForMS[item_id] = {};
            }

            EvalResultWithItemForMS[item_id][defaultIdentifier.FieldId] =
              parseFloat(eval(newFormula));
            EvalResults[defaultIdentifier.FieldId] = parseFloat(
              eval(newFormula)
            );
          });
        } else {
          // Now set the property
          Object.entries(newMultiSolDataTemplate).forEach(([key, obj]) => {
            if (Array.isArray(obj)) {
              EvalMultiSolResults[key] = {};
              EvalResultWithItemForMS[key] = {};
              obj.forEach((rowdata, rowIndex) => {
                if (!EvalMultiSolResults[key][rowIndex]) {
                  EvalMultiSolResults[key][rowIndex] = {};
                }
                if (!EvalResultWithItemForMS[key][rowIndex]) {
                  EvalResultWithItemForMS[key][rowIndex] = {};
                }
                this.state.rearrangedArray.forEach((defaultIdentifier) => {
                  let formula = defaultIdentifier.DefaultValue.Formula;
                  if (formula) {
                    let newFormulaMS = this.arrangedEvalForMS(
                      formula,
                      key,
                      rowIndex,
                      EvalMultiSolResults[key],
                      defaultIdentifier.Identifier
                    );
                    if (
                      newFormulaMS.includes("ExchangeRate_SelectedCurrency")
                    ) {
                      let exchangeRate = this.props.currencyOptions.find(
                        (currency) =>
                          currency.Currency ==
                          (this.state.selectedCurrency ||
                            this.props.quotedCurrency)
                      );
                      if (exchangeRate) {
                        newFormulaMS = newFormulaMS.replace(
                          "ExchangeRate_SelectedCurrency",
                          exchangeRate.ExchangeRate
                        );
                      }
                    }
                    if (
                      newFormulaMS.includes("ExchangeRate_BuyerLocalCurrency")
                    ) {
                      let exchangeRate = this.props.currencyOptions.find(
                        (currency) =>
                          currency.Currency ===
                          (this.props.currencyDetails || "INR")
                      );
                      if (exchangeRate) {
                        newFormulaMS = newFormulaMS.replace(
                          "ExchangeRate_BuyerLocalCurrency",
                          exchangeRate.ExchangeRate
                        );
                      }
                    }
                    if (newFormulaMS && newFormulaMS.includes("(f")) {
                      const match = newFormulaMS.match(/f\d+/)[0];
                      newFormulaMS = newFormulaMS.replace(match, 0);
                    }
                    if (
                      this.props.multipleSolutionFlag &&
                      !this.props.slabFlag &&
                      this.state.switchState[key]
                    ) {
                      const evaluatedValue = parseFloat(eval(newFormulaMS));

                      if (!isNaN(evaluatedValue)) {
                        EvalMultiSolResults[key][rowIndex][
                          defaultIdentifier?.FieldId
                        ] = evaluatedValue;
                      } else {
                        EvalMultiSolResults[key][rowIndex][
                          defaultIdentifier?.FieldId
                        ] = 0; // or any default value you prefer
                      }
                    }

                    if (
                      this.props.multipleSolutionFlag &&
                      !this.props.slabFlag &&
                      this.state.switchState[key]
                    ) {
                      const evaluatedValueItemResult = parseFloat(
                        eval(newFormulaMS)
                      );

                      if (!isNaN(evaluatedValueItemResult)) {
                        EvalResultWithItemForMS[key][rowIndex][
                          defaultIdentifier?.FieldId
                        ] = evaluatedValueItemResult;
                      } else {
                        EvalResultWithItemForMS[key][rowIndex][
                          defaultIdentifier?.FieldId
                        ] = 0; // or any default value you prefer
                      }
                    }
                  }
                });
              });
            }
          });
        }

        if (
          this.props.multipleSolutionFlag &&
          !this.props.slabFlag &&
          !this.state.switchState[key]
        ) {
          // For mainTemplate
          Object.entries(this.props.JsonTemplate.ITEMIZE[0]).forEach(
            (value, index) => {
              let field_id = value[1]?.FieldId;
              if (!obj[key]) {
                obj[key] = [];
                // obj[key][0] = {};
              }
              if (this.props.itemBoxId !== field_id) {
                if (!obj[key][0]) {
                  obj[key][0] = {};
                }
                Object.entries(mainTemplate).forEach(([itemId, mainTemp]) => {
                  if (mainTemp[field_id] && itemId === key) {
                    obj[key][0][field_id] = mainTemp[field_id]?.value || "";
                    arr.push(field_id);
                    return;
                  }
                });
              }
            }
          );
          obj[key].forEach((rowdata, rowindex) => {
            Object.entries(JsonTemplate.ITEMIZE[0]).forEach(
              ([index, currentField]) => {
                if (
                  this.state.mainTemplate &&
                  this.state.mainTemplate[key] &&
                  this.state.mainTemplate[key].hasOwnProperty(
                    currentField.FieldId
                  ) &&
                  currentField.Identifier !== "Product" &&
                  currentField.ElementType !== "M"
                ) {
                  if (currentField.ElementType === "D") {
                    obj[key][rowindex][currentField.FieldId] = String(
                      EvalResults[currentField.FieldId]
                    );
                  } else {
                    obj[key][rowindex][currentField.FieldId] = String(
                      this.state.mainTemplate[key][currentField.FieldId]?.value
                    );
                  }
                } else {
                  if (arr.indexOf(currentField.FieldId) === -1) {
                    let formula =
                      currentField.DefaultFormula ||
                      currentField.DefaultValue.Formula;
                    if (this.props.itemBoxId !== currentField.FieldId) {
                      let value = 0;
                      if (formula && formula !== "") {
                        let placeholders = formula.match(/\(f\d+\)/g);
                        if (placeholders) {
                          try {
                            if (currentField.ElementType === "D") {
                              if (
                                EvalResults.hasOwnProperty(currentField.FieldId)
                              ) {
                                value = EvalResults[currentField.FieldId];
                              }
                            } else {
                              let defaultFormula = currentField.DefaultFormula;
                              if (defaultFormula) {
                                const formattedResult =
                                  this.extractAndFormat(defaultFormula);

                                if (
                                  formattedResult &&
                                  formattedResult.length >= 1
                                ) {
                                  const valuesFound = formattedResult
                                    .map((identifier, index) => {
                                      const attributeKey =
                                        identifier.split(".");
                                      const subStringAttrKey =
                                        attributeKey[1].substring(1);
                                      const valuesInSolution =
                                        lastTransactionData.itemize[
                                          key
                                        ]?.solution
                                          ?.map(
                                            (obj) =>
                                              obj?.data[0]?.[subStringAttrKey]
                                          )
                                          ?.filter(
                                            (value) => value !== undefined
                                          );
                                      const fallbackValue =
                                        lastTransactionData.common?.[
                                          subStringAttrKey
                                        ];
                                      const fetchTxn = this.props.fetchedTxn?.[
                                        attributeKey[0]
                                      ]?.itemize[key]?.solution
                                        ?.map(
                                          (obj) =>
                                            obj?.data[0]?.[subStringAttrKey]
                                        )
                                        ?.filter(
                                          (value) => value !== undefined
                                        );
                                      if (
                                        valuesInSolution &&
                                        valuesInSolution.length > 0
                                      ) {
                                        return valuesInSolution;
                                      } else if (
                                        valuesInSolution &&
                                        valuesInSolution.length == 0 &&
                                        fetchTxn &&
                                        fetchTxn.length > 0
                                      ) {
                                        return fetchTxn;
                                      } else if (fallbackValue !== undefined) {
                                        return [fallbackValue];
                                      } else {
                                        return [];
                                      }
                                    })
                                    .flat();

                                  let finalValue = null;
                                  for (let i = 0; i < valuesFound.length; i++) {
                                    if (valuesFound[i] !== null) {
                                      finalValue = valuesFound[i];
                                      break;
                                    }
                                  }
                                  value = finalValue;
                                }
                              }
                            }
                          } catch (error) {
                            console.log("error in eval", error);
                          }
                        }
                      } else {
                        value = currentField.DefaultValue || 0;
                      }
                      obj[key][rowindex][currentField.FieldId] = String(
                        value || 0
                      );
                    }
                  }
                }
              }
            );
            if (!finalRemainingTxnData[key]) {
              finalRemainingTxnData[key] = [];
              finalRemainingTxnData[key][rowindex] = {};
            }
            finalRemainingTxnData[key][rowindex] = obj[key][rowindex];
          });
        } else {
          // For multiple multiSolmainTemplate
          Object.entries(newMultiSolDataTemplate).forEach(([key, obj]) => {
            let productElement = this.state.allItemsTemplate.find((item) => {
              return (
                item.Identifier === "Product" ||
                item.Identifier === "ProductForeign"
              );
            });
            let productNewElement = productElement.FieldId;
            let copiedObj = JSON.parse(JSON.stringify(obj));
            const updatedData = copiedObj.map((item) => {
              const { [productNewElement]: excluded, ...rest } = item; // Destructure and exclude the productNewElement field
              return rest;
            });
            copiedObj = updatedData;
            copiedObj.forEach((rowdata, rowIndex) => {
              Object.entries(JsonTemplate.ITEMIZE[0]).forEach(
                ([index, currentField]) => {
                  if (
                    newMultiSolDataTemplate &&
                    newMultiSolDataTemplate[key][rowIndex] &&
                    newMultiSolDataTemplate[key][rowIndex].hasOwnProperty(
                      currentField.FieldId
                    ) &&
                    (currentField.Identifier !== "Product" ||
                      currentField.Identifier !== "ProductForeign") &&
                    currentField.ElementType !== "M"
                  ) {
                    if (currentField.ElementType === "D") {
                      copiedObj[rowIndex][currentField.FieldId] =
                        EvalMultiSolResults[key][rowIndex][
                          currentField.FieldId
                        ]; //Change
                    } else {
                      copiedObj[rowIndex][currentField.FieldId] =
                        newMultiSolDataTemplate[key][rowIndex][
                          currentField.FieldId
                        ]["value"];
                    }
                  } else {
                    if (arr.indexOf(currentField.FieldId) === -1) {
                      let formula =
                        currentField.DefaultFormula ||
                        currentField.DefaultValue.Formula;

                      if (this.props.itemBoxId !== currentField.FieldId) {
                        let value = 0;
                        if (formula && formula !== "") {
                          let placeholders = formula.match(/\(f\d+\)/g);
                          if (placeholders) {
                            try {
                              if (currentField.ElementType === "D") {
                                if (
                                  EvalMultiSolResults[key][
                                    rowIndex
                                  ].hasOwnProperty(currentField.FieldId)
                                ) {
                                  value =
                                    EvalMultiSolResults[key][rowIndex][
                                      currentField.FieldId
                                    ];
                                }
                              } else {
                                let defaultFormula =
                                  currentField.DefaultFormula;
                                if (defaultFormula) {
                                  const formattedResult =
                                    this.extractAndFormat(defaultFormula);

                                  if (
                                    formattedResult &&
                                    formattedResult.length >= 1
                                  ) {
                                    const valuesFound = formattedResult
                                      .map((identifier, index) => {
                                        const attributeKey =
                                          identifier.split(".");
                                        const subStringAttrKey =
                                          attributeKey[1].substring(1);
                                        const valuesInSolution =
                                          lastTransactionData.itemize[
                                            key
                                          ]?.solution
                                            ?.map(
                                              (obj) =>
                                                obj?.data[0]?.[subStringAttrKey]
                                            )
                                            ?.filter(
                                              (value) => value !== undefined
                                            );
                                        const fallbackValue =
                                          lastTransactionData.common?.[
                                            subStringAttrKey
                                          ];
                                        const fetchTxn =
                                          this.props.fetchedTxn?.[
                                            attributeKey[0]
                                          ]?.itemize[key]?.solution
                                            ?.map(
                                              (obj) =>
                                                obj?.data[0]?.[subStringAttrKey]
                                            )
                                            ?.filter(
                                              (value) => value !== undefined
                                            );
                                        if (
                                          valuesInSolution &&
                                          valuesInSolution.length > 0
                                        ) {
                                          return valuesInSolution;
                                        } else if (
                                          valuesInSolution &&
                                          valuesInSolution.length == 0 &&
                                          fetchTxn &&
                                          fetchTxn.length > 0
                                        ) {
                                          return fetchTxn;
                                        } else if (
                                          fallbackValue !== undefined
                                        ) {
                                          return [fallbackValue];
                                        } else {
                                          return [];
                                        }
                                      })
                                      .flat();

                                    let finalValue = null;
                                    for (
                                      let i = 0;
                                      i < valuesFound.length;
                                      i++
                                    ) {
                                      if (valuesFound[i] !== null) {
                                        finalValue = valuesFound[i];
                                        break;
                                      }
                                    }
                                    value = finalValue;
                                  }
                                }
                              }
                            } catch (error) {
                              console.log("error in eval", error);
                            }
                          }
                        } else {
                          value = currentField.DefaultValue || 0;
                        }
                        copiedObj[rowIndex][currentField.FieldId] = value || 0;
                      }
                    }
                  }
                }
              );

              if (!finalRemainingDataForMS[key]) {
                finalRemainingDataForMS[key] = [];
                finalRemainingDataForMS[key][rowIndex] = {};
              }
              finalRemainingDataForMS[key][rowIndex] = copiedObj[rowIndex];
            });
          });
        }
        // }

        if (
          this.props.multipleSolutionFlag &&
          !this.props.slabFlag &&
          !this.state.switchState[key]
        ) {
          let solution = [];

          for (const rowindex in finalRemainingTxnData[key]) {
            const keys = `${key}-0`;
            let obj = {
              // name: this.state.solutionNameMainTemplate[key] || "",
              // changed below condition to fix issue id TU4-I148
              name:
                this.state.solutionNames[keys] !== ""
                  ? this.state.solutionNames[keys]
                  : this.state.solutionNameMainTemplate[key],
              data: [finalRemainingTxnData[key][rowindex]],
            };
            solution.push(obj);
          }
          if (mainTemplate.hasOwnProperty(key)) {
            const item = mainTemplate[key];

            const itemData = {
              selected: item.selected,
              formdetailRowId: item.formdetailRowId,
              itemBoxId: this.props.itemBoxId,
              solution: solution,
            };
            itemize[key] = itemData;
          }
        } else {
          // this.props.transactionItemSequence.forEach((itemId) => {
          let solution = [];
          if (finalRemainingDataForMS.hasOwnProperty(key)) {
            const itemTxnData = finalRemainingDataForMS[key];
            for (const rowIndex in itemTxnData) {
              if (itemTxnData.hasOwnProperty(rowIndex)) {
                const keys = `${key}-${rowIndex}`;
                let obj = {
                  name: this.state.solutionNames[keys] || "",
                  data: [itemTxnData[rowIndex]],
                };

                // Filter out numeric keys
                obj.data = obj.data.map((dataObj) => {
                  let filteredObj = {};
                  for (let key in dataObj) {
                    if (/^\d+$/.test(key)) {
                      filteredObj[key] = dataObj[key];
                    }
                  }
                  return filteredObj;
                });
                solution.push(obj);

                if (
                  multiSolmainTemplate.hasOwnProperty(key) &&
                  multiSolmainTemplate[key].hasOwnProperty(rowIndex)
                ) {
                  const itemMS = multiSolmainTemplate[key][rowIndex];
                  const item = this.state.mainTemplate[key];

                  const itemData = {
                    selected: item.selected,
                    formdetailRowId: itemMS.formdetailRowId,
                    itemBoxId: this.props.itemBoxId,
                    solution: solution,
                  };
                  multiSolItemize[key] = itemData;
                }
              }
            }
          } else {
            console.warn(
              `finalRemainingDataForMS does not contain key: ${key}`
            );
          }
          // });
        }
      });
    });

    Object.entries(multiSolItemize).forEach(([key, obj]) => {
      if (this.state.switchState[key]) {
        let isValid = true;
        const solutions = obj.solution; // Access the solution array
        solutions?.forEach((solutionItem) => {
          solutionItem.data?.forEach((rowdata, rowindex) => {
            // Iterate over the data array within each solution item
            if (
              !rowdata["solutionName"] ||
              rowdata["solutionName"].trim() === ""
            ) {
              isValid = false;
              this.setState((prevState) => ({
                solutionNameErrors: {
                  ...prevState.solutionNameErrors,
                  [rowindex]: "Solution name is required",
                },
              }));
            }
          });
        });
      }
    });

    //common new work

    const common = {};
    const commonDElemnts = {};
    Object.keys(additionalInfoTemplate).map((fieldId, index) => {
      let fieldValue = additionalInfoTemplate[fieldId]?.value;
      if (additionalInfoTemplate[fieldId].VisibilityFlag === "Y") {
        const isAttachmentField =
          additionalInfoTemplate[fieldId].Identifier === "Attachment" ||
          additionalInfoTemplate[fieldId].Identifier === "AttachmentForeign";

        if (isAttachmentField) {
          if (Array.isArray(fieldValue)) {
            common[fieldId] = fieldValue.length > 0 ? fieldValue : "";
          } else {
            common[fieldId] = "";
          }
        } else {
          common[fieldId] = fieldValue != null ? fieldValue : "";
        }
      } else {
        let commonDelements = {};
        if (additionalInfoTemplate[fieldId]?.ElementType === "D") {
          if (
            additionalInfoTemplate[fieldId].Identifier == "TotalReqQty" ||
            additionalInfoTemplate[fieldId].Identifier == "TotalReqQtyFor" ||
            additionalInfoTemplate[fieldId].Identifier == "TotalQuantity"
          ) {
            let exactIndex = Object.keys(this.state.mainTemplate)[0];
            common[fieldId] = EvalResultWithItemForMS[exactIndex][fieldId];
          } else {
            if (
              additionalInfoTemplate[fieldId].DefaultValue.hasOwnProperty(
                "Formula"
              ) &&
              additionalInfoTemplate[fieldId].DefaultValue.Formula != ""
            ) {
              let pattern = /f(\d+)/g;
              let formula =
                additionalInfoTemplate[fieldId].DefaultValue?.Formula;
              const matches = [...formula.matchAll(pattern)];
              const matchedIdentifiersFormula = matches.map(
                (match) => match[1]
              );
              matchedIdentifiersFormula.forEach((formulaIdentifier) => {
                let FromItemize = this.state.allItemsTemplate.find((field) => {
                  return (
                    field.FieldId === formulaIdentifier &&
                    field.HTMLAttributeName.includes("_")
                  );
                });
                if (FromItemize) {
                  if (FromItemize?.ElementType === "D") {
                    const sum = this.sumFieldValue(
                      EvalResultWithItemForMS,
                      formulaIdentifier
                    );
                    formula = formula.replace(
                      `f${formulaIdentifier}`,
                      sum
                        ? !isNaN(parseFloat(sum))
                          ? parseFloat(sum)
                          : `"${sum}"`
                        : 0
                    );
                  } else {
                    const sumOfFieldQty = this.getSumOfField(
                      mainTemplate,
                      formulaIdentifier
                    );
                    formula = formula.replace(
                      `f${formulaIdentifier}`,
                      sumOfFieldQty
                    );
                  }
                } else if (commonDElemnts.hasOwnProperty(formulaIdentifier)) {
                  formula = formula.replace(
                    `f${formulaIdentifier}`,
                    parseFloat(commonDElemnts[formulaIdentifier])
                  );
                } else {
                  let exactIndex = Object.keys(this.state.mainTemplate)[0];

                  let replacedValue =
                    EvalResultWithItemForMS[exactIndex][formulaIdentifier];
                  if (
                    EvalResultWithItemForMS[exactIndex]?.hasOwnProperty(
                      formulaIdentifier
                    )
                  ) {
                    formula = formula?.replace(
                      `f${formulaIdentifier}`,
                      replacedValue
                        ? !isNaN(parseFloat(replacedValue))
                          ? parseFloat(replacedValue)
                          : `"${replacedValue}"`
                        : 0
                    );
                  } else {
                    formula = formula.replace(
                      `f${formulaIdentifier}`,
                      this.state.additionalInfoTemplate[formulaIdentifier]
                        ?.value
                        ? !isNaN(
                            parseFloat(
                              this.state.additionalInfoTemplate[
                                formulaIdentifier
                              ]?.value
                            )
                          )
                          ? parseFloat(
                              this.state.additionalInfoTemplate[
                                formulaIdentifier
                              ]?.value
                            )
                          : `"${this.state.additionalInfoTemplate[formulaIdentifier]?.value}"`
                        : 0
                    );
                  }
                }
              });
              common[fieldId] = parseFloat(eval(formula)).toFixed(3);
              commonDElemnts[fieldId] = parseFloat(eval(formula)).toFixed(3);
            } else {
              if (
                typeof additionalInfoTemplate[fieldId].DefaultValue == "object"
              ) {
                common[fieldId] = 0;
              } else {
                common[fieldId] =
                  additionalInfoTemplate[fieldId].DefaultValue || 0;
              }
            }
          }
        } else {
          common[fieldId] = fieldValue != null ? fieldValue : "";
        }
      }
    });

    // Construct payload
    const queryParams = new URLSearchParams(window.location.search);
    const supplier = parseInt(queryParams.get("supplier"), 10);

    const concatenatedItemizeUnique = {
      ...multiSolItemize,
      ...itemize,
    };

    // Use formattedDate wherever needed
    const payload = {
      inquiry_id: this.props.inquiryId,
      validity_date: dayjs(
        this?.state?.dataFromDetailsSection?.negoValidity
      ).format("YYYY-MM-DD HH:mm:ss IST"),

      tran_status: "Negotiation",
      currency: this.state.selectedCurrency || this.props.currencyDetails,
      terms_and_conditions_text: "",
      location_flag: this.props.vendorType === "Local" ? "L" : "I",
      shipment_mark: this.state.shipment_mark || "",
      template: {
        itemize: concatenatedItemizeUnique,
        common: common,
      },
      vendor_id: this.props.vendorId,
    };
    if (this.props.vendorType !== "Local") {
      payload.exchange_rate = this.state.currencyExchangeRates;
    }
    payload.tl_guid = this.props.MassNego
      ? null
      : this.props.lastTransactionGuidInThread;
    return payload;
  };

  prepareNegotiationPayloadForSlab = () => {
    let { additionalInfoTemplate, mainTemplate, remainTemplate } = this.state;
    const { lastTransactionData, JsonTemplate } = this.props;
    let newSlabTxnTemplate = this.state.slabMainTemplate;
    let itemize = {};
    let slabItemize = {};
    let finalRemainingTxnData = {};
    let finalRemainingCommonData = {};
    let finalRemainingDataForSlab = {};

    if (this.props.itemSpecific) {
      newSlabTxnTemplate = {
        ...newSlabTxnTemplate,
        ...this.state.notFilteredSlabMainTemplate,
      };
    }

    // Prepare itemize object
    let item_id_arr =
      this.props.inquiryItemsId.length > 0
        ? this.props.inquiryItemsId
        : Object.keys(mainTemplate);
    // item_id_arr.forEach((item_id) => {
    this.props.transactionItemSequence.forEach((item_id) => {
      let arr = [];
      let obj = {};
      let key = item_id;
      let EvalResults = {};
      let EvalSlabResults = {};
      Object.entries(newSlabTxnTemplate).forEach(([key, obj]) => {
        EvalSlabResults[key] = {};
        obj.forEach((rowdata, rowIndex) => {
          // Initialize EvalSlabResults[rowIndex] if necessary
          if (!EvalSlabResults[key][rowIndex]) {
            EvalSlabResults[key][rowIndex] = {};
          }
          this.state.rearrangedArray.forEach((defaultIdentifier) => {
            let formula = defaultIdentifier.DefaultValue.Formula;
            if (formula) {
              let newFormulaSlab = this.arrangedEvalForSlab(
                formula,
                key,
                rowIndex,
                EvalSlabResults[key],
                defaultIdentifier.Identifier
              );
              // Replace exchange rate placeholders
              if (newFormulaSlab.includes("ExchangeRate_SelectedCurrency")) {
                let exchangeRate = this.props.currencyOptions.find(
                  (currency) =>
                    currency.Currency ==
                    (this.state.selectedCurrency || this.props.quotedCurrency)
                );
                if (exchangeRate) {
                  newFormulaSlab = newFormulaSlab.replace(
                    "ExchangeRate_SelectedCurrency",
                    exchangeRate.ExchangeRate
                  );
                }
              }
              if (newFormulaSlab.includes("ExchangeRate_BuyerLocalCurrency")) {
                let exchangeRate = this.props.currencyOptions.find(
                  (currency) =>
                    currency.Currency === (this.props.currencyDetails || "INR")
                );
                if (exchangeRate) {
                  newFormulaSlab = newFormulaSlab.replace(
                    "ExchangeRate_BuyerLocalCurrency",
                    exchangeRate.ExchangeRate
                  );
                }
              }
              // Replace undefined variables
              if (newFormulaSlab && newFormulaSlab.includes("(f")) {
                const match = newFormulaSlab.match(/f\d+/)[0];
                newFormulaSlab = newFormulaSlab.replace(match, 0);
              }
              // const evaluatedValue = parseFloat(eval(newFormulaSlab)).toFixed(3);
              const evaluatedValue = parseFloat(eval(newFormulaSlab));
              // Check if evaluatedValue is a valid number
              if (!isNaN(evaluatedValue)) {
                EvalSlabResults[key][rowIndex][defaultIdentifier.FieldId] =
                  evaluatedValue;
              } else {
                EvalSlabResults[key][rowIndex][defaultIdentifier.FieldId] = 0; // or any default value you prefer
              }
            }
          });
        });
      });

      const solutionsRange =
        lastTransactionData.itemize[key]?.solution[0].data[0]?._range ===
          undefined || [];

      if (
        this.props.slabFlag &&
        this.props.multipleSolutionFlag &&
        solutionsRange === true
      ) {
        //change this
        // For mainTemplate
        Object.entries(this.props.JsonTemplate.ITEMIZE[0]).forEach(
          (value, index) => {
            let field_id = value[1]?.FieldId;
            if (!obj[key]) {
              obj[key] = [];
            }
            if (this.props.itemBoxId !== field_id) {
              if (!obj[key][0]) {
                obj[key][0] = {};
              }
              Object.entries(mainTemplate).forEach(([itemId, mainTemp]) => {
                if (mainTemp[field_id] && itemId === key) {
                  obj[key][0][field_id] = mainTemp[field_id]?.value || "";
                  arr.push(field_id);
                  return;
                }
              });
            }
          }
        );
        obj[key].forEach((rowdata, rowindex) => {
          Object.entries(JsonTemplate.ITEMIZE[0]).forEach(
            ([index, currentField]) => {
              if (
                this.state.mainTemplate &&
                this.state.mainTemplate[key] &&
                this.state.mainTemplate[key].hasOwnProperty(
                  currentField.FieldId
                ) &&
                currentField.Identifier !== "Product" &&
                currentField.ElementType !== "M"
              ) {
                if (currentField.ElementType === "D") {
                  obj[key][rowindex][currentField.FieldId] = String(
                    EvalResults[currentField.FieldId]
                  );
                } else {
                  obj[key][rowindex][currentField.FieldId] = String(
                    this.state.mainTemplate[key][currentField.FieldId]?.value
                  );
                }
              } else {
                if (arr.indexOf(currentField.FieldId) === -1) {
                  let formula =
                    currentField.DefaultFormula ||
                    currentField.DefaultValue.Formula;
                  if (this.props.itemBoxId !== currentField.FieldId) {
                    let value = 0;
                    if (formula && formula !== "") {
                      let placeholders = formula.match(/\(f\d+\)/g);
                      if (placeholders) {
                        try {
                          if (currentField.ElementType === "D") {
                            if (
                              EvalResults.hasOwnProperty(currentField.FieldId)
                            ) {
                              value = EvalResults[currentField.FieldId];
                            }
                          } else {
                            let defaultFormula = currentField.DefaultFormula;
                            if (defaultFormula) {
                              const formattedResult =
                                this.extractAndFormat(defaultFormula);

                              if (
                                formattedResult &&
                                formattedResult.length >= 1
                              ) {
                                const valuesFound = formattedResult
                                  .map((identifier, index) => {
                                    const attributeKey = identifier.split(".");
                                    const subStringAttrKey =
                                      attributeKey[1].substring(1);
                                    const valuesInSolution =
                                      lastTransactionData.itemize[key]?.solution
                                        ?.map(
                                          (obj) =>
                                            obj?.data[0]?.[subStringAttrKey]
                                        )
                                        ?.filter(
                                          (value) => value !== undefined
                                        );
                                    const fallbackValue =
                                      lastTransactionData.common?.[
                                        subStringAttrKey
                                      ];
                                    const fetchTxn = this.props.fetchedTxn?.[
                                      attributeKey[0]
                                    ]?.itemize[key]?.solution
                                      ?.map(
                                        (obj) =>
                                          obj?.data[0]?.[subStringAttrKey]
                                      )
                                      ?.filter((value) => value !== undefined);
                                    if (
                                      valuesInSolution &&
                                      valuesInSolution.length > 0
                                    ) {
                                      return valuesInSolution;
                                    } else if (
                                      valuesInSolution &&
                                      valuesInSolution.length == 0 &&
                                      fetchTxn &&
                                      fetchTxn.length > 0
                                    ) {
                                      return fetchTxn;
                                    } else if (fallbackValue !== undefined) {
                                      return [fallbackValue];
                                    } else {
                                      return [];
                                    }
                                  })
                                  .flat();

                                let finalValue = null;
                                for (let i = 0; i < valuesFound.length; i++) {
                                  if (valuesFound[i] !== null) {
                                    finalValue = valuesFound[i];
                                    break;
                                  }
                                }
                                value = finalValue;
                              }
                            }
                          }
                        } catch (error) {
                          console.log("error in eval", error);
                        }
                      }
                    } else {
                      value = currentField.DefaultValue || 0;
                    }
                    obj[key][rowindex][currentField.FieldId] = String(
                      value || 0
                    );
                  }
                }
              }
            }
          );
          if (!finalRemainingTxnData[key]) {
            finalRemainingTxnData[key] = [];
            finalRemainingTxnData[key][rowindex] = {};
          }
          finalRemainingTxnData[key][rowindex] = obj[key][rowindex];
        });
      } else {
        // For Slab
        Object.entries(newSlabTxnTemplate).forEach(([key, obj]) => {
          let productElement = this.state.allItemsTemplate.find((item) => {
            return (
              item.Identifier === "Product" ||
              item.Identifier === "ProductForeign"
            );
          });

          let productNewElement = productElement.FieldId;
          let copiedObj = JSON.parse(JSON.stringify(obj));
          const updatedData = copiedObj.map((item) => {
            const { [productNewElement]: excluded, ...rest } = item; // Destructure and exclude the productNewElement field
            return rest;
          });
          copiedObj = updatedData;
          copiedObj.forEach((rowdata, rowIndex) => {
            Object.entries(JsonTemplate.ITEMIZE[0]).forEach(
              ([index, currentField]) => {
                if (
                  newSlabTxnTemplate &&
                  newSlabTxnTemplate[key][rowIndex] &&
                  newSlabTxnTemplate[key][rowIndex].hasOwnProperty(
                    currentField.FieldId
                  ) &&
                  (currentField.Identifier !== "Product" ||
                    currentField.Identifier !== "ProductForeign") &&
                  currentField.ElementType !== "M"
                ) {
                  if (currentField.ElementType === "D") {
                    copiedObj[rowIndex][currentField.FieldId] =
                      EvalSlabResults[key][rowIndex][currentField.FieldId];
                  } else {
                    copiedObj[rowIndex][currentField.FieldId] =
                      newSlabTxnTemplate[key][rowIndex][currentField.FieldId][
                        "value"
                      ];
                  }
                } else {
                  if (arr.indexOf(currentField.FieldId) === -1) {
                    let formula =
                      currentField.DefaultFormula ||
                      currentField.DefaultValue.Formula;

                    if (this.props.itemBoxId !== currentField.FieldId) {
                      let value = 0;
                      if (formula && formula !== "") {
                        let placeholders = formula.match(/\(f\d+\)/g);
                        if (placeholders) {
                          try {
                            if (currentField.ElementType === "D") {
                              if (
                                EvalSlabResults[key][rowIndex].hasOwnProperty(
                                  currentField.FieldId
                                )
                              ) {
                                value =
                                  EvalSlabResults[key][rowIndex][
                                    currentField.FieldId
                                  ];
                              }
                            } else {
                              let defaultFormula = currentField.DefaultFormula;
                              if (defaultFormula) {
                                const formattedResult =
                                  this.extractAndFormat(defaultFormula);
                                if (
                                  formattedResult &&
                                  formattedResult.length >= 1
                                ) {
                                  const valuesFound = formattedResult
                                    .map((identifier, index) => {
                                      const attributeKey =
                                        identifier.split(".");
                                      const subStringAttrKey =
                                        attributeKey[1].substring(1);
                                      const valuesInSolution =
                                        lastTransactionData.itemize[
                                          key
                                        ]?.solution
                                          ?.map(
                                            (obj) =>
                                              obj?.data[0]?.[subStringAttrKey]
                                          )
                                          ?.filter(
                                            (value) => value !== undefined
                                          );
                                      const fallbackValue =
                                        lastTransactionData.common?.[
                                          subStringAttrKey
                                        ];
                                      const fetchTxn = this.props.fetchedTxn?.[
                                        attributeKey[0]
                                      ]?.itemize[key]?.solution
                                        ?.map(
                                          (obj) =>
                                            obj?.data[0]?.[subStringAttrKey]
                                        )
                                        ?.filter(
                                          (value) => value !== undefined
                                        );
                                      if (
                                        valuesInSolution &&
                                        valuesInSolution.length > 0
                                      ) {
                                        return valuesInSolution;
                                      } else if (
                                        valuesInSolution &&
                                        valuesInSolution.length == 0 &&
                                        fetchTxn &&
                                        fetchTxn.length > 0
                                      ) {
                                        return fetchTxn;
                                      } else if (fallbackValue !== undefined) {
                                        return [fallbackValue];
                                      } else {
                                        return [];
                                      }
                                    })
                                    .flat();

                                  let finalValue = null;
                                  for (let i = 0; i < valuesFound.length; i++) {
                                    if (valuesFound[i] !== null) {
                                      finalValue = valuesFound[i];
                                      break;
                                    }
                                  }
                                  value = finalValue;
                                }
                              }
                            }
                          } catch (error) {
                            console.log("error in eval", error);
                          }
                        }
                      } else {
                        value = currentField.DefaultValue || 0;
                      }
                      copiedObj[rowIndex][currentField.FieldId] = value || 0;
                    }
                  }
                }
              }
            );

            if (!finalRemainingDataForSlab[key]) {
              finalRemainingDataForSlab[key] = [];
              finalRemainingDataForSlab[key][rowIndex] = {};
            }
            finalRemainingDataForSlab[key][rowIndex] = copiedObj[rowIndex];
          });
        });
      }
    });

    this.props.transactionItemSequence.forEach((key) => {
      if (finalRemainingDataForSlab.hasOwnProperty(key)) {
        const itemTxnData = finalRemainingDataForSlab[key];

        // Initialize the solution array for the item key
        let solution = [
          {
            data: [],
          },
        ];

        for (const rowIndex in itemTxnData) {
          if (itemTxnData.hasOwnProperty(rowIndex)) {
            // Filter out numeric keys and include select_slab and _range
            let filteredObj = {};
            for (let fieldKey in itemTxnData[rowIndex]) {
              if (
                /^\d+$/.test(fieldKey) ||
                fieldKey === "select_slab" ||
                fieldKey === "_range"
              ) {
                filteredObj[fieldKey] = itemTxnData[rowIndex][fieldKey];
              }
            }

            solution[0].data.push(filteredObj);
            if (
              newSlabTxnTemplate.hasOwnProperty(key) &&
              newSlabTxnTemplate[key].hasOwnProperty(rowIndex)
            ) {
              const item = newSlabTxnTemplate[key][rowIndex];
              const itemSelected = newSlabTxnTemplate[key];
              const concatenatedItemData = item["formdetailRowId"];
              let concatenatedItem = "";
              for (const dataKey in concatenatedItemData) {
                if (concatenatedItemData.hasOwnProperty(dataKey)) {
                  concatenatedItem += concatenatedItemData[dataKey];
                }
              }

              // Concatenate item.selected values
              const concatenatedSelectedData = itemSelected["selected"];
              let concatenatedSelected = "";
              for (const dataKey in concatenatedSelectedData) {
                if (concatenatedSelectedData.hasOwnProperty(dataKey)) {
                  concatenatedSelected += concatenatedSelectedData[dataKey];
                }
              }

              const itemData = {
                selected: concatenatedSelected, // Concatenated selected string
                formdetailRowId: parseInt(concatenatedItem, 10),
                itemBoxId: this.props.itemBoxId,
                solution: solution,
              };

              slabItemize[key] = itemData;
            }
          }
        }
      } else {
        console.warn(`finalRemainingDataForMS does not contain key: ${key}`);
      }
    });

    let item_id_arr_cm = Object.keys(additionalInfoTemplate);
    item_id_arr_cm.forEach((item_id) => {
      let obj = {};
      let key = item_id;
      Object.entries(JsonTemplate.ITEMIZE[0]).forEach(
        ([index, currentField]) => {
          let field_id = currentField.FieldId;
          let value;
          if (remainTemplate[field_id] !== undefined) {
            value = remainTemplate[field_id];
          } else {
            let formula =
              currentField.DefaultFormula || currentField.DefaultValue.Formula;
            if (this.props.itemBoxId !== currentField.FieldId && formula) {
              let placeholders = formula.match(/\(f\d+\)/g);
              if (placeholders) {
                let formulaDataHash = this.finalFormulaFunction(
                  formula,
                  placeholders,
                  currentField.FieldId,
                  key,
                  0
                );
                formula = formulaDataHash.formula;
                try {
                  if (
                    currentField.ElementType === "D" &&
                    formulaDataHash.eval
                  ) {
                    value = eval(formula);
                    value = isNaN(value) ? 0 : value;
                  } else {
                    value = formulaDataHash.value || currentField.DefaultValue;
                  }
                } catch (error) {
                  console.log("error in eval", error);
                }
              }
            }
          }
          obj[field_id] = value !== undefined ? value : "";
        }
      );
      finalRemainingCommonData[key] = obj;
    });

    const common = {};
    for (const fieldId in finalRemainingCommonData) {
      if (
        fieldId === "undefined" ||
        fieldId === undefined ||
        fieldId === null
      ) {
        continue; // Skip this iteration if fieldId is undefined or null
      }

      if (!additionalInfoTemplate.hasOwnProperty(fieldId)) {
        continue; // Skip this iteration if fieldId is not present in additionalInfoTemplate
      }

      const fieldData = additionalInfoTemplate[fieldId];
      const fieldValue = fieldData?.value;

      if (
        fieldId === "undefined" ||
        fieldData === undefined ||
        fieldData === null
      ) {
        continue; // Skip this iteration if fieldData is undefined or null
      }

      const isAttachmentField =
        fieldData.Identifier === "Attachment" ||
        fieldData.Identifier === "AttachmentForeign";

      if (isAttachmentField) {
        if (Array.isArray(fieldValue)) {
          common[fieldId] = fieldValue.length > 0 ? fieldValue : "";
        } else {
          common[fieldId] = "";
        }
      } else {
        common[fieldId] = fieldValue != null ? fieldValue : "";
      }
    }

    // Construct payload
    const queryParams = new URLSearchParams(window.location.search);
    const supplier = parseInt(queryParams.get("supplier"), 10);

    const concatenatedItemizeUnique = {
      ...slabItemize,
      ...itemize,
    };

    // Use formattedDate wherever needed
    const payload = {
      inquiry_id: this.props.inquiryId,
      validity_date: dayjs(
        this?.state?.dataFromDetailsSection?.negoValidity
      ).format("YYYY-MM-DD HH:mm:ss IST"),
      tran_status: "Negotiation",
      currency: this.state.selectedCurrency || this.props.currencyDetails,
      terms_and_conditions_text: "",
      location_flag: this.props.vendorType === "Local" ? "L" : "I",
      shipment_mark: this.state.shipment_mark || "",
      template: {
        itemize: concatenatedItemizeUnique,
        common: common,
      },
      vendor_id: this.props.vendorId,
    };
    if (this.props.vendorType !== "Local") {
      payload.exchange_rate = this.state.currencyExchangeRates;
    }
    payload.tl_guid = this.props.MassNego
      ? null
      : this.props.lastTransactionGuidInThread;

    return payload;
  };

  negotiationSubmitAPI = async () => {
    const formData = this.props.multipleSolutionFlag
      ? this.props.slabFlag
        ? this.prepareNegotiationPayloadForSlab()
        : this.prepareNegotiationPayloadForMS()
      : this.prepareNegotiationPayload();

    try {
      this.setState({
        SubmitLoading: true,
      });
      const response = await axios.post(negotiationSubmit, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if(this.props.negotiationJsonData.filter_data.sealed_rfq_post_nego){
        response.data.message = `RFQ No ${this.props.negotiationJsonData.ref_no} has been sealed and ${response.data.message}`;      
      }
      
      if (this.props.multipleVendors) {
        this.props.sucessMessage(response.data.message);
        this.props.sucessFlg(true);
        this.setState({
          SubmitLoading: false,
        });
      } else {
        this.setState({
          SubmitSuccessMessage: response.data.message,
          SubmitLoading: false,
          SubmitSuccess: true, // Reset success state
        });
      }
    } catch (error) {
      if (this.props.multipleVendors) {
        this.props.errorFlag(true);
      }
      // Handle error
      console.error("Error submitting Negotiation:", error);
      // Turn off loading state
      this.setState({
        SubmitLoading: false,
        SubmitSuccess: false,
      });

      // Extract error messages from the response
      let errorMessages = [];
      if (error?.response?.data[0]?.code === 1018) {
        this.props.openSnackbar(
          "error",
          error?.response?.data[0]?.error
            ? error?.response?.data[0]?.error
            : "Something went wrong!"
        );
      } else {
        if (
          error?.response &&
          error?.response?.data &&
          error?.response?.data?.error
        ) {
          const errors = error?.response?.data?.error;
          errorMessages = Object.keys(errors).map((key) => {
            if (Array.isArray(errors[key])) {
              return errors[key].join(" ");
            } else {
              return errors; // or handle it appropriately if it's not an array
            }
          });
        }
        // Fallback error message
        if (errorMessages.length === 0) {
          errorMessages.push(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Something went wrong!"
          );
        }
        // Show the error messages in MUI Snackbar
        errorMessages.forEach((message) => {
          this.props.openSnackbar("error", message);
        });
      }
    }
    if (this.props.multipleVendors) {
      this.props.resetCombinedSubmitFlag(false);
    }
  };

  handleCloseDestinationDialog() {
    this.setState({ isDestinationDetailsDialogOpen: false });
  }

  handleOpenDestinationDialog() {
    this.setState({ isDestinationDetailsDialogOpen: true });
  }

  setIsDestinationDetailsDialogOpen(isOpen) {
    this.setState({ isDestinationDetailsDialogOpen: isOpen });
  }

  disabledProps(disabledItems) {
    this.setState({ disabledProps: disabledItems });
  }

  setRearrangedTemplate(data) {
    this.setState({ rearrangedArray: data });
  }

  setItemsTemplate(data) {
    this.setState({ allItemsTemplate: data });
  }
  setServiceSourceName(source) {
    this.setState({ excelSourceName: source });
  }
  currencyExchange(rates) {
    this.setState({ currencyExchangeRates: rates });
  }

  render() {
    return (
      <React.Fragment>
        <Backdrop
          style={{ color: "#fff", zIndex: "10000000000" }}
          open={
            this.state.loaderArray.length > 0 ||
            this.state.prevRFQLoading ||
            this.state.SubmitLoading ||
            this.state.attachmentLoading
          }
        >
          <CircularProgress style={{ color: "#fff" }} />
        </Backdrop>
        <React.Fragment>
          <form
            id="novalidatedform"
            noValidate
            autoComplete="off"
            onSubmit={(event) => {
              if (
                this.props.location?.pathname.indexOf("/prcombination") > -1
              ) {
                event.preventDefault();
              }
            }}
          >
            {/* -----------------------Negotiation Details----------------------- */}
            <Box mb={2} id={`NegotiationDetails_${this.props.tabValue}`}>
              <NegotiationDetailsSection
                title="Negotiation Details"
                data={this.props.negotiationJsonData}
                categoryId={this.props.categoryId}
                Alreadyexistingfiles={this.props.categorySpecFiles}
                getField={this.getFieldLocal}
                foreignData={this?.props?.JsonTemplate?.COMMON?.concat(
                  this?.props?.JsonTemplate?.FOREIGN
                )}
                itemizeData={this.state.mainTemplate}
                headerLabels={this.props?.headerLabels}
                commonData={this?.props?.JsonTemplate?.COMMON}
                localData={this?.props?.JsonTemplate?.COMMON?.concat(
                  this?.props?.JsonTemplate?.LOCAL
                )}
                currencyOptions={this.props.currencyOptions}
                currencyDetails={this.props.currencyDetails}
                fileSpecificationData={this.props.fileSpecificationData}
                handleCurrencyChange={this.handleCurrencyChange}
                vendorType={this.props.vendorType}
                lastTransactionTypeInThread={
                  this.props.lastTransactionTypeInThread
                }
                setAttachmentsValue={(value) => {
                  this.setAttachmentsValue(value);
                }}
                dataFromDetailsSection={this.setDataFromDetailSection}
                offlineDateError={this.state.offlineDateError}
                offlineAttachmentError={this.state.offlineAttachment}
                offlineFieldError={this.state.offlineFieldError}
                offlineReferenceError={this.state.offlineReferenceError}
                featurePermissionDetails={this.props.featurePermission}
                industryDetails={this.props.industryDetails}
                selectedCurrencyValidator={this.state.selectedCurrencyValidator}
                industryTypeErrorMessage={this.state.industryTypeErrorMessage}
                selectedCurrencyErrorMessage={
                  this.state.selectedCurrencyErrorMessage
                }
                selectedCurrency={this.state.selectedCurrency}
                additionalInfo={this.state.additionalInfoTemplate}
                inquiryId={this.props.inquiryId}
                CurrentTransaction={this.props.CurrentTransaction}
                quotedCurrency={this.props.quotedCurrency}
                transactionItemSequence={this.props.transactionItemSequence}
                allItemsTemplate={this.state.allItemsTemplate}
                base_currency={this.props.currencyDetails}
                PermissionInquiry={this.props.PermissionInquiry}
                disabledProps={this.state.disabledProps}
                isServiceRfq={this.props.isServiceRfq}
                categoryName={this.props.categoryName}
                serviceSourceName={this.setServiceSourceName}
                itemSpecific={this.props.itemSpecific}
                multipleSolutionFlag={this.props.multipleSolutionFlag}
                slabFlag={this.props.slabFlag}
                solutionNames={this.state.solutionNames}
                MassNego={this.props.MassNego}
                vendorId={this.props.vendorId}
                lastTransactionGuidInThread={
                  this.props.lastTransactionGuidInThread
                }
                currencyExchange={this.currencyExchange}
              />
            </Box>

            {/* -----------------------Template Section----------------------- */}
            <Box mb={2} id={`Template__${this.props.tabValue}`}>
              <NegotiationFormSectionTable
                inquiryId={this.props.inquiryId}
                randomId={this.state.randomId}
                additionalInfoTemplate={this.state.additionalInfoTemplate}
                categoryId={this.props.categoryId}
                batch_id={
                  this.props.JsonTemplate?.PARAM &&
                  this.props.JsonTemplate?.PARAM[0]?.batch_id
                }
                currencyOptions={this.props.currencyOptions}
                msmainTemplate={this.state.msmainTemplate}
                data={this.props.JsonTemplate?.ITEMIZE || []}
                commonData={this?.props?.JsonTemplate?.COMMON || []}
                mainTemplate={this.state.mainTemplate}
                handleOpenDestinationDialog={this.handleOpenDestinationDialog}
                setIsDestinationDetailsDialogOpen={
                  this.setIsDestinationDetailsDialogOpen
                }
                setRearrangedTemplate={this.setRearrangedTemplate}
                setItemsTemplate={this.setItemsTemplate}
                negotiationJsonData={this.props.negotiationJsonData.items}
                base_currency={this.props.currencyDetails}
                handleUplaod={this.handleUplaod}
                applyToAll={this.state.applyToAll}
                multiSolApplyToAll={this.state.multiSolApplyToAll}
                slabApplyToAll={this.state.slabApplyToAll}
                slabParentApplyToAll={this.state.slabParentApplyToAll}
                handleApplyToAllClick={this.handleApplyToAllClick}
                handleMSApplyToAllClick={this.handleMSApplyToAllClick}
                handleSlabApplyToAllClick={this.handleSlabApplyToAllClick}
                handleSlabParentApplyToAllClick={
                  this.handleSlabParentApplyToAllClick
                }
                handleDateChange={this.handleDateChange}
                handleDateSlabChange={this.handleDateSlabChange}
                handleDateMSChange={this.handleDateMSChange}
                handleDateWithOutSlabChange={this.handleDateWithOutSlabChange}
                handleWithOutSlabChange={this.handleWithOutSlabInputChange}
                handleChange={this.handleItemizeInputChange}
                handleMSChange={this.handleMSItemizeInputChange}
                handleSlabChange={this.handleSlabItemizeInputChange}
                solutionNameErrors={this.state.solutionNameErrors}
                errorArrayForMSParentName={this.state.errorArrayForMSParentName}
                solutionNames={this.state.solutionNames}
                handleCheckboxChange={this.handleCheckboxChange}
                default_dropdown_type={
                  this.props.companyInfo?.default_dropdown_type
                }
                destination={this.getDestinationValue()}
                updateDeliveryScheduleFieldValue={
                  this.updateDeliveryScheduleFieldValue
                }
                updateDeliveryScheduleFieldValueSlab={
                  this.updateDeliveryScheduleFieldValueSlab
                }
                itemSpecificFiles={this.props.itemSpecificFiles}
                LATEST={this.LATEST}
                slab={slab}
                handleApplyToAllSlab={this.handleApplyToAllSlab}
                slabKey={this.state.slabKey}
                applyToAllSlab={this.state.applyToAllSlab}
                firstActiveSlab={this.props.selectedItems?.find((element) => {
                  if (
                    this.state?.slab[element?.id] &&
                    this.state?.slab[element?.id].isOpen
                  ) {
                    return element;
                  }
                })}
                displayOptions={this.props.displayOptions}
                selectedCurrency={this.state.selectedCurrency}
                lastTransactionInThread={this.props.lastTransactionInThread}
                lastTransactionData={this.props.lastTransactionData}
                lastNegotiationData={this.props.lastNegotiationData}
                lastTransactionTypeInThread={
                  this.props.lastTransactionTypeInThread
                }
                filteredSelectedNotData={this.props.filteredSelectedNotData}
                vendorType={this.props.vendorType}
                currencyDetails={this.props.currencyDetails}
                items={this.props.items}
                inquiryItemsId={this.props.inquiryItemsId}
                itemSpecific={this.props.itemSpecific}
                multipleSolutionFlag={this.props.multipleSolutionFlag}
                slabFlag={this.props.slabFlag}
                multiplePlantsFlag={this.props.multiplePlantsFlag}
                toggleSwitch={this.toggleSwitch}
                switchState={this.state.switchState}
                errorArray={this.state.errorArray}
                errorArrayForMS={this.state.errorArrayForMS}
                errorArrayForSlab={this.state.errorArrayForSlab}
                quotedCurrency={this.props.quotedCurrency}
                transactionItemSequence={this.props.transactionItemSequence}
                template={this.props.JsonTemplate}
                multiSolmainTemplate={this.state.multiSolmainTemplate}
                CurrentTransaction={this.props.CurrentTransaction}
                handleUpdateTxn={this.handleUpdateTxn}
                setSolutionNames={this.setSolutionNames}
                setSolutionNamesErrors={this.setSolutionNamesErrors}
                solutionNameMainTemplate={this.state.solutionNameMainTemplate}
                disabledProps={this.disabledProps}
                isServiceRfq={this.props.isServiceRfq}
                slabMainTemplate={this.state.slabMainTemplate}
                setSlabMainTemplate={this.setSlabMainTemplate}
                disabledRows={this.state.disabledRows}
                setDisabledRows={this.setDisabledRows}
                openSnackbar={this.props.openSnackbar}
                updateMultiSolmainTemplate={this.updateMultiSolmainTemplate}
                clearMultiSolApplyToAll={this.clearMultiSolApplyToAll}
                switchStateToggleCheck={this.state.switchStateToggleCheck}
                slabChildStateToggleCheck={this.state.slabChildStateToggleCheck}
                handleSave={this.handleSave}
                lastTransactionInThreadCommon={
                  this.props.lastTransactionInThreadCommon
                }
                itemizeData={this?.props?.JsonTemplate?.ITEMIZE?.[0]}
                foreignData={this?.props?.JsonTemplate?.COMMON?.concat(
                  this?.props?.JsonTemplate?.FOREIGN
                )}
                localData={this?.props?.JsonTemplate?.COMMON?.concat(
                  this?.props?.JsonTemplate?.LOCAL
                )}
                isMultipleCurrency={this.props.isMultipleCurrency}
                submitflg={this.props.submitflg}
                tabValue={this.props.tabValue}
                alternativeNames={this.props.AlternativeNames}
              />
            </Box>
            {/*----------------------- Additional Info -----------------------*/}
            <>
              <Box mb={2} id={`AdditionalInfo__${this.props.tabValue}`}>
                <NegotiationCardTemplate
                  title="Additional Info"
                  state={this?.state?.lastTransactionData?.common}
                  foreignData={this?.props?.JsonTemplate?.COMMON?.concat(
                    this?.props?.JsonTemplate?.FOREIGN
                  )}
                  localData={this?.props?.JsonTemplate?.COMMON?.concat(
                    this?.props?.JsonTemplate?.LOCAL
                  )}
                  MaterialDescription={this.state.MaterialDescription}
                  materialDescriptionAll={this.download}
                  getField={this.getFieldLocal}
                  handleChange={this.handleLocalChange}
                  vendorType={this.props.vendorType}
                  categoryId={this.props.categoryId}
                  fileSpecificationData={this.props.fileSpecificationData}
                  CurrentTransaction={this.props.CurrentTransaction}
                  multiplePlantsFlag={this.props.multiplePlantsFlag}
                  category={this.props.negotiationJsonData.categories[0]}
                  shipmentMark={this.state.shipment_mark}
                  handleShipmentChange={this.handleShipmentChange}
                />
              </Box>

              {/* -----------------------Submit Nego Form Button----------------------- */}

              <Box className="Footer-QuotedForm" sx={{ mb: 2 }}>
                <FormGroup
                  sx={{
                    display: "inline-flex",
                    "& .MuiTypography-root": {
                      color: "var(--primary)",
                      textDecoration: "underline",
                      fontWeight: "500",
                    },
                  }}
                >
                  <FormControlLabel
                    sx={{ m: 0 }}
                    control={<Checkbox sx={{ display: "none" }} />}
                    label={
                      <Typography
                        variant="subtitle1"
                        component="a"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            tandcDialogOpen: true,
                          });
                        }}
                      >
                        View Terms and Conditions
                      </Typography>
                    }
                  />
                </FormGroup>
                {!this.props.multipleVendors && (
                  <Stack direction="row" spacing={2} mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      className="Upload_file_btn"
                      onClick={(event) => this.handleNegotiationSubmit(event)}
                    >
                      Submit Negotiation
                    </Button>
                  </Stack>
                )}
              </Box>
            </>
          </form>
        </React.Fragment>

        {/* Modals */}

        {/* Additional Info Specification */}
        {this.state.specificationsOpen && (
          <UploadFileDialog
            product_id={this.props.selectedCategory?.id}
            randomId={this.state.randomId}
            master_type="category"
            handleUplaod={this.handleUplaod}
            open={this.state.specificationsOpen}
            Alreadyexistingfiles={this.props.categorySpecFiles}
            updateCategorySpecificationFiles={(newFiles) => {
              this.setState({
                categorySpecificationFiles: newFiles,
              });
            }}
            handleClose={() => {
              this.setState({
                specificationsOpen: false,
              });
            }}
          />
        )}

        {/* Additional Info Attachment */}
        {(this.state.attachments !== null ||
          this.state.attachments !== undefined) &&
          this.state.dynamicFileUploadOpen && (
            <UploadDynamicQuotation
              sessionID={this.props.sessionId}
              currentFieldId={this.state.currentFieldId}
              uploadedFiles={
                this.state[this.state.currentTemplate] &&
                this.state[this.state.currentTemplate][
                  this.state.currentFieldId
                ] &&
                this.state[this.state.currentTemplate][
                  this.state.currentFieldId
                ]["value"]
              }
              handleUploadedFileDelete={(index) => {
                let temp = this.state[this.state.currentTemplate];
                temp[this.state.currentFieldId]["value"].splice(index, 1);
                this.setState({ [this.state.currentTemplate]: temp });
              }}
              setAttachmentsValue={(value) => {
                this.setAttachmentsValue(value);
              }}
              randomId={this.state.randomId}
              open={this.state.dynamicFileUploadOpen}
              handleClose={() => {
                this.setState({ dynamicFileUploadOpen: false });
              }}
              uploadedFilesAttachment={this.state.attachments} // Pass attachments here
              categoryId={this.props.categoryId}
            />
          )}

        {/* Terms & Conditions Modal */}
        {this.state.tandcDialogOpen && (
          <TermsAndConditions
            category_id={this.props.categoryId}
            open={this.state.tandcDialogOpen}
            termsAndConditionsData={this.props.termsAndConditionsDetails}
            handleClose={() => {
              this.setState({
                tandcDialogOpen: false,
              });
            }}
          />
        )}

        {this.state.fileSpecificationsOpen && (
          <UploadDynamicView
            Loading={this.state.fileSpecLoading}
            title={
              this.state.fileAttachmentName
                ? this.state.fileAttachmentName
                : "View Transaction Specification"
            }
            uploadedFiles={this.state.specification?.data}
            open={this.state.fileSpecificationsOpen}
            handleClose={this.handleUploadClose}
          />
        )}

        <Dialog
          scroll="body"
          maxWidth={"sm"}
          open={this.state.SubmitSuccess}
          aria-labelledby="responsive-dialog-title"
          sx={(theme) => ({
            "&.MuiDialog-root .MuiPaper-root": {
              width: "500px",
              boxSizing: "border-box",
              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },
            },
          })}
        >
          <DialogContent>
            <DialogContentText>
              <div className="linkcategorydialog_main">
                <Grid container>
                  <Grid
                    item
                    lg={12}
                    xl={12}
                    md={12}
                    sm={12}
                    className="text-center"
                  >
                    <img src={successimage} alt="Category Link Icon" />
                    <>
                      <h3 className="success_title">Successfully</h3>
                      <p className="success_subtitle">
                        {this.state.SubmitSuccessMessage}
                      </p>
                    </>
                  </Grid>
                </Grid>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              color="primary"
              // autoFocus
              onClick={(event) => {
                this.setState({
                  SubmitSuccess: false,
                });
                const queryParams = new URLSearchParams(window.location.search);

                if (queryParams.get("supplier")) {
                  window.top.location.reload();
                }
                window.parent.postMessage("Quotation_close_iframe", "*");
                event.preventDefault();
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {/* Nego Form Submit Confirmation */}
        <ConfirmAlert
          open={this.state.confirmDialogOpen}
          handleClose={() => {
            this.setState({
              confirmDialogOpen: false,
            });
          }}
          handleSubmit={() => {
            this.setState({
              confirmDialogOpen: false,
            });
            this.negotiationSubmitAPI();
          }}
          msg={this.state.confirmAlertMsg}
          title={this.state.confirmAlertTitle}
        />

        <DestinationDetailsDialog
          handleClose={this.handleCloseDestinationDialog}
          open={this.state.isDestinationDetailsDialogOpen}
          lastTransactionInThreadCommon={
            this.props.lastTransactionInThreadCommon
          }
          setIsDestinationDetailsDialogOpen={
            this.setIsDestinationDetailsDialogOpen
          }
          itemizeData={this?.props?.JsonTemplate?.ITEMIZE?.[0]}
          foreignData={this?.props?.JsonTemplate?.COMMON?.concat(
            this?.props?.JsonTemplate?.FOREIGN
          )}
          localData={this?.props?.JsonTemplate?.COMMON?.concat(
            this?.props?.JsonTemplate?.LOCAL
          )}
          vendorType={this.props.vendorType}
          handleSave={this.handleSave}
          fetchedTxn={this.props.fetchedTxn}
          additionalInfo={this.state.additionalInfoTemplate}
          mainTemplateInfo={this.state.mainTemplate}
        />

        <DefaultTerms
          openDefaultTerms={this.state.vendorTermsOpen}
          CloseDefaultTerms={this.closeVendorTerms}
          vendorTermData={this.state.vendorTermData}
          title={this.state.title}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  sessionId: state.sessionReducer.sessionId,
  companyInfo: state.companyInfoReducer.companyInfo,
  rfqSettingValues: state.drawerValuesReducer.rfqSettingValues,
  accountModulePermission: state.permissionsReducer.accountModulePermission,
  accountlevelInfo: state.permissionsReducer.accountlevelInfo,
  featurePermission: state.permissionsReducer.featurePermission,
  categorySpecFiles: state.categoryReducer.fileSpecifications,
  itemSpecificFiles: state.itemReducer.itemSpecifications,
  prlineitems: state.prrfqreducer.setPrLineItemsData,
  userInfo: state.userInfoReducer.userInfo,
  templateValues: state.prrfqreducer.templateValues,
  termsAndConditions: state.prrfqreducer.termsAndConditions,
  selectedItems: state.itemReducer.selectedItems,
  displayOptions: state.prrfqreducer.displayOptions,
});

const mapDispatchToProps = {
  openSnackbar,
  setTemplateValues,
  setTermsAndConditions,
};

const ConnectedNegoTemplate = connect(
  mapStateToProps,
  mapDispatchToProps
)(NegotiationTemplate);

const StyledNegotiationTemplate = withStyles(styles)(ConnectedNegoTemplate);

export default StyledNegotiationTemplate;
