import {
  Grid,
  TextField,
  CircularProgress,
  Switch,
  InputAdornment,
  IconButton,
  Backdrop,
  Button,
  Tooltip,
  Autocomplete,
  Dialog,
  createFilterOptions,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  InputLabel,
  Divider,
} from "@mui/material";
import React, { Component, createRef } from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {Box} from "@mui/system";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from '@mui/icons-material/Close';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { connect } from "react-redux";
import { openSnackbar } from "../../../redux/actions/snackbarAction";
import axios from "axios";
import { getIncoTermsOptions, getPaymentTermsOptions, getVendorDetails, postCompanyVendors, getCountries, addVendor, getContactDetails, getDestinations } from "../../../config/apiUrl";
import { HelpOutlined } from "@mui/icons-material";
// import ContactPersonDetails from "./ContactPersonDetails";
import ContactPersonDetails from "../../../components/Dialogs/AddVendor/ContactPersonDetails";
import { State } from "country-state-city";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { FormHelperText } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option) => option.country,
});
class AddNewVendor extends Component {
  constructor(props) {
    super(props);
    this.descriptionElementRef = createRef(null);
  }
  state = {
    contactPersonDetails: [
      {
        destination: {
          value:[],
          error:false
        },
        contactPersonName: {
          value:"",
          error:false
        },
        contactPersonEmail: {
          value:"",
          error:false
        },
        contactNumber: {
          value:"",
          error:false
        },
      },
    ],
    tabValue: 0,
    loading: false,
    contactPersonDetailsloading: false,
    firstName: "",
    error: {},
    lastName: "",
    code: "",
    email: "",
    websiteURL: "",
    country: null,
    contactPerson: "",
    globalLogin: "",
    designation: "",
    EmailContactPerson: "",
    address: "",
    city: "",
    state: null,
    pinCode: "",
    telephone1: "",
    telephone2: "",
    cellPhone: "",
    fax: "",
    loginID: "",
    certification: "",
    bankReference: "",
    companyType: "",
    industryType: "",
    msmeNumber: "",
    companyTurnover: "",
    vendorForProducts: "",
    vendorForCategory: "",
    otherDetails: "",
    remarks: "",
    paymentTermsCode: null,
    paymentTermsCodeLoading:false,
    paymentTermsCodeOptions:[],
    incoTermsCode: null,
    incoTermsCodeLoading:false,
    incoTermsCodeOptions:[],
    hsnCode: "",
    sacCode: "",
    pan: "",
    gstIN: "",
    smsPermission: true,
    chatPermission: false,
    is_approved: "Y",
    countriesOptions: [],
    countriesOptionsLoading: false,
    statesOptions:null,
    restriction: false,
    urlProtocol: "https://",
    inco_term_info:{},
    payment_term_info:{},
    prevCountry:null,
    prevState:null,
    vendorInvoiceClass:"",
    stateUpdated:false,
    destinationOptions:[],
    pan_status: "",
    msme_status: "",
    gstin_status: "",
    initialValues: {
      pan: '',
      msmeNumber: '',
      gstIN: '',
      msme_status: '',
      gstin_status: '',
      pan_status: ''
    }
  };

  componentDidMount() {
    // in order to wait for the element to be actually drawn, using setTimeout

    setTimeout(() => {
      if (this.descriptionElementRef.current !== null) {
        this.descriptionElementRef.current.focus();
      }
    }, 0);

    if (this.props.masterPermission?.vendor_approval !== "Y"){
      this.setState({
        is_approved: 'N'
      })
    }

    if (
      this.props.masterPermission?.add_edit_vendor_contact_person === "Y" &&
      this.props.accountModulePermission["Add/Update contact details"]
        ?.module_activated === "Y"
    ) {
      // fetch destination options for tab2
      this.setState({
        contactPersonDetailsloading: true,
      });
      axios.get(getDestinations).then(response => {
        this.setState({
          destinationOptions: response.data.data
        }, () => {
          // if edit mode then prepopulate tab2 fields
          if (this.props.currentVendorID) {
            axios.get(`${getContactDetails(this.props.currentVendorID)}`).then(res => {
              if (res.data.data?.length){
                let contactPersonDetails= [];
                res.data.data.forEach(row => {
                  let obj = {};
                  obj.destination = {
                    value:this.state.destinationOptions?.filter(option => row.destination_ids?.includes(option.row_id)),
                    error:false
                  }
      
                  obj.contactPersonName = {
                    value:row.contact_name,
                    error:false
                  }
      
                  obj.contactPersonEmail = {
                    value:row.contact_email,
                    error:false
                  }
      
                  obj.contactNumber = {
                    value:row.contact_no,
                    error:false
                  }
                  contactPersonDetails.push(obj)
                })
                this.setState({
                  contactPersonDetails
                })
              } 
              this.setState({
                contactPersonDetailsloading: false,
              });
              }).catch(err => {
                console.log("Error occured while fetching vendor contact details", err)
                this.setState({
                  contactPersonDetailsloading: false,
                });
            })
          }
          this.setState({
            contactPersonDetailsloading: false,
          });
        })
      }).catch(err => {
        console.log("Error occured while fetching destinations options", err)
        this.setState({
          contactPersonDetailsloading: false,
        });
      })
    }

    // if edit mode, populate tab1 fields
    if (this.props.currentVendorID) {
      if (this.props.companyInfo?.restrict_edit_vendor_details === "Y") {
        this.setState({ restriction: true })
      }
      this.setState({
        loading: true,
      });
      let invoiceClassHash = {
        'PO Invoice'    :"10",
        'GRN Invoice'   :"20",
        'PO/GRN Invoice':"30",
      };
      axios
        .get(getVendorDetails(this.props.currentVendorID))
        .then((response) => {
          this.setState({
            loading: false,
            firstName: response.data.data.vendor_name || "",
            lastName: response.data.data.dummy_name || "",
            code: response.data.data.vendor_code || "",
            email: response.data.data.email || "",
            websiteURL: response.data.data.website || "",
            contactPerson: response.data.data.contact_person || "",
            globalLogin: response.data.data.global_login || "",
            designation: response.data.data.contact_designation || "",
            EmailContactPerson: response.data.data.user_email || "",
            address: response.data.data.address || "",
            city: response.data.data.city || "",
            state: response.data.data.state || null,
            pinCode: response.data.data.pincode || "",
            telephone1: response.data.data.tel1 || "",
            telephone2: response.data.data.tel2 || "",
            cellPhone: response.data.data.mobile_no || "",
            fax: response.data.data.fax || "",
            loginID: response.data.data.login_id || "",
            certification: response.data.data.certification || "",
            bankReference: response.data.data.bank_reference || "",
            companyType: response.data.data.company_type || "",
            industryType: response.data.data.industry_type || "",
            msmeNumber:  response.data.data.industry_type_value || "",
            companyTurnover: response.data.data.company_turnover || "",
            vendorForProducts: response.data.data.products || "",
            vendorForCategory: response.data.data.vendor_category || "",
            otherDetails: response.data.data.other_details || "",
            remarks: response.data.data.remarks || "",
            hsnCode: response.data.data.hsn_code || "",
            sacCode: response.data.data.sac_code || "",
            pan: response.data.data.pan_no || "",
            gstIN: response.data.data.gstin_no || "",
            smsPermission: response.data.data.send_sms === "Y" ? true : false,
            chatPermission:
              response.data.data.chat_permission === "Y" ? true : false,
            is_approved:
              response.data.data.is_approved,
            payment_term_info:response.data.data.payment_term_info,
            inco_term_info:response.data.data.inco_term_info,
            prevCountry: response.data.data.country,
            prevState: response.data.data.state,
            pan_status: response.data.data.pan_status || "",
            msme_status: response.data.data.msme_status || "",
            gstin_status: response.data.data.gstin_status || "",
            initialValues: {
              pan: response.data.data.pan_no || "",
              msmeNumber: response.data.data.industry_type_value || "",
              gstIN: response.data.data.gstin_no || "",
              msme_status: response.data.data.msme_status || "",
              pan_status: response.data.data.pan_status || "",
              gstin_status: response.data.data.gstin_status || ""
            },
            vendorInvoiceClass: invoiceClassHash[response.data.data?.invoice_class]
          }, () => {
            this.fetchPaymentTermsOptions();
            this.fetchIncoTermsOptions();
            this.fetchCountriesOptions();
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          if (err.response?.data?.validation_errors) {
            let errorObj = err.response.data.validation_errors;
            let errorMsg = "";
            for (const key in errorObj) {
              errorObj[key].forEach((msg) => {
                errorMsg = `${errorMsg && `${errorMsg}, `}${msg}`;
              });
            }
            this.props.openSnackbar("error", errorMsg);
          } else {
            this.props.openSnackbar("error", "something went wrong!");
          }
          console.log(err);
        });
    } else {
      this.fetchPaymentTermsOptions();
      this.fetchIncoTermsOptions();
      this.fetchCountriesOptions();
    }
  }
  handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if(name === "state"){
      this.setState({
        stateUpdated : true,
      });
    }
    this.setState({
      [name]: type === "checkbox" ? checked : value,
    });


    if (name === "pan" || name === "msmeNumber" || name === "gstIN") {
      this.handleStatusChange(name, value);
    }
  };

  handleStatusChange = (name, value) => {
    
    const initialState = { ...this.state.initialValues };
    const { companyInfo } = this.props;
    const permissions = {
      gstIN: companyInfo?.gst_verification,
      pan: companyInfo?.pan_verification,
      msmeNumber: companyInfo?.msme_verification,
    };
    
    //if (initialState[name] && permissions[name] === 'Y') {
    if (permissions[name] === 'Y') {    
      if (value === '') {
        switch (name) {
          case "pan":
            if (permissions.pan) this.setState({ pan_status: null });
            break;
          case "msmeNumber":
            if (permissions.msmeNumber) this.setState({ msme_status: null });
            break;
          case "gstIN":
            if (permissions.gstIN) this.setState({ gstin_status: null });
            break;
          default:
            break;
        }
      } else if (value !== initialState[name]) {
        // If new value is different from old value, set status to 'Pending'
        switch (name) {
          case "pan":
            if (permissions.pan) this.setState({ pan_status: 'Pending' });
            break;
          case "msmeNumber":
            if (permissions.msmeNumber) this.setState({ msme_status: 'Pending' });
            break;
          case "gstIN":
            if (permissions.gstIN) this.setState({ gstin_status: 'Pending' });
            break;
          default:
            break;
        }
      } else {
        // If new value is the same as the old value, revert status to old status
        switch (name) {
          case "pan":
            if (permissions.pan) this.setState({ pan_status: initialState.pan_status });
            break;
          case "msmeNumber":
            if (permissions.msmeNumber) this.setState({ msme_status: initialState.msme_status });
            break;
          case "gstIN":
            if (permissions.gstIN) this.setState({ gstin_status: initialState.gstin_status });
            break;
          default:
            break;
        }
      }
    }else{
      switch (name) {
        case "pan":
          this.setState({ pan_status: null});
          break;
        case "msmeNumber":
          this.setState({ msme_status: null});
          break;
        case "gstIN":
          this.setState({ gstin_status: null });
          break;
        default:
          break;
      }
    }
  };
  
  handleBlur = (e) => {
    const { name, value } = e.target;
    const { error } = this.state;

    if(name === "globalLogin")
    {
      error[name] = value.includes('@') ? false : true;
    }
    else if(name === "email")
    {
      let result = false;
      let email_regex = /^[A-Z0-9._%-]+@[A-Z0-9.-]+[.][A-Z]{2,63}(?:[,][A-Z0-9._%-]+@[A-Z0-9.-]+[.][A-Z]{2,63})*$/i;

      if (value.trim() === "")
      {
        if(name === "email" && (this.props.currentVendorID || this.props.companyInfo?.common_vendor_login === 'Y'))
        {
          result = true;
        }
      } 
      else {
        value
          .trim()
          .split(",")
          .forEach((email) => {
            if (!email_regex.test(email)) {
              result = true;
            }
          });
      }
      error[name] = result;
    }
    else
    {
      if (!value.length) {
        error[name] = true;
      } else {
        error[name] = false;
      }
    }
    console.log(error);
    this.setState({ error });
  };

  validateOnSubmit = () => {
    this.setState({
      loading: true,
    });
    const { error } = this.state;

    let flag = false;
    let email_regex =
        /^[A-Z0-9._%-]+@[A-Z0-9.-]+[.][A-Z]{2,63}(?:[,][A-Z0-9._%-]+@[A-Z0-9.-]+[.][A-Z]{2,63})*$/i;

    // if (this.state.tabValue === 0){
      if (!(this.props.currentVendorID && this.state.restriction) && this.state.firstName.trim() === "") {
        error["firstName"] = true;
        flag = true;
      }
      
      if (!(this.props.currentVendorID && (this.state.restriction || this.props.companyInfo?.common_vendor_login === "Y")) && this.state.code.trim() === "") {
        flag = true;
        error["code"] = true;
      }

      if(this.props?.companyInfo.common_vendor_login === "Y" && 
        (this.props?.companyInfo.invoice_against_grn_po === "Y" || this.props?.companyInfo.invoice_ocr_verification === "Y") && 
        (this.state.vendorInvoiceClass === '' || this.state.vendorInvoiceClass == '0')){
          flag = true;
          error["vendorInvoiceClass"] = true;
      }
  
      if (!(this.props.currentVendorID && this.state.restriction)){
        let result = false;
        if (this.state.email.trim() === "" && this.props.currentVendorID) {
          result = true;
        } else if (this.state.email.trim() !== "") {
          this.state.email
            .trim()
            .split(",")
            .forEach((email) => {
              if (!email_regex.test(email)) {
                result = true;
              }
            });
        }

        error["email"] = result;
        if (result) {
          flag = true;
        }
      }
  
      if (!(this.props.currentVendorID && this.state.restriction) && !this.state.country) {
        flag = true;
        error["country"] = true;
      }
  
      if (this.state.contactPerson.trim() === "") {
        flag = true;
        error["contactPerson"] = true;
      }
      if (!this.props.currentVendorID && this.props.companyInfo?.common_vendor_login === "Y"){
        let result = false;
        if (this.state.globalLogin.trim() === "") {
          result = true;
        } 
        else if(this.state.globalLogin.search('@') == -1){
          result = false;
          // this.state.globalLogin
          //   .trim()
          //   .split(",")
          //   .forEach((email) => {
          //     if (!email_regex.test(email)) {
          //       result = true;
          //     }
          //   });
        }
        error["globalLogin"] = result;
        if (result) {
          flag = true;
        }
      }
  
      if (this.state.chatPermission && (this.props.currentVendorID || (!this.props.currentVendorID && this.props.companyInfo?.common_vendor_login !== "Y"))){
        if (this.state.loginID.trim() === "") {
          flag = true;
          error["loginID"] = true;
        }
      }
      this.setState({
        error,
      });
    // } else if (this.state.tabValue === 1) {
      const { contactPersonDetails } = this.state;
      contactPersonDetails.forEach((row) => {
        if (
          row["destination"]["value"]?.length ||
          row["contactNumber"]["value"]?.length ||
          row["contactPersonEmail"]["value"]?.length ||
          row["contactPersonName"]["value"]?.length
        ) {
          if (!row.destination.value.length) {
            flag = true;
            row.destination.error = true;
          } else {
            row.destination.error = false;
          }

          if (row.contactPersonName.value?.length < 3) {
            flag = true;
            row.contactPersonName.error = true;
          } else {
            row.contactPersonName.error = false;
          }

          if (!email_regex.test(row.contactPersonEmail.value)) {
            flag = true;
            row.contactPersonEmail.error = true;
          } else {
            row.contactPersonEmail.error = false;
          }

          if (row.contactNumber.value) {
            if (!row.contactNumber.value.match(/^(\+)?\d{10,15}$/)) {
              flag = true;
              row.contactNumber.error = true;
            } else {
              row.contactNumber.error = false;
            }
          } else {
            row.contactNumber.error = false;
          }
        } else {
          row.destination.error = false;
          row.contactPersonName.error = false;
          row.contactPersonEmail.error = false;
          row.contactNumber.error = false;
          this.setState({
            loading: false,
          })
        }
      });

      this.setState({
        contactPersonDetails,
      });
    // }
    
    if (flag) {
      this.setState({
        loading: false,
      });
      this.props.openSnackbar("error", "PLease resolve errors!");
      return;
    }

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    let invoiceClassHash = {
      "10" : 'PO Invoice', 
      "20" : 'GRN Invoice',
      "30" : 'PO/GRN Invoice'
    };
    // if (this.state.tabValue === 0){
      let payload = {
        category_id: this.props.selectedCategory?.new_category_id,
        vendorname: this.state.firstName,
        dummyname: this.state.lastName,
        vendorcode: this.state.code,
        email: this.state.email,
        gl_login: this.state.globalLogin,
        // gl_email: this.state.globalLogin,
        country: this.state.country.country,
        contactperson: this.state.contactPerson,
        mobile: this.state.cellPhone,
        hsn_code: this.state.hsnCode,
        sac_code: this.state.sacCode,
        pannumber: this.state.pan,
        gstcode: this.state.gstIN,
        sendsms: this.state.smsPermission ? "Y" : "N",
        chat_permission: this.state.chatPermission ? "Y" : "N",
        url: this.state.websiteURL,
        designation: this.state.designation,
        address: this.state.address,
        city: this.state.city,
        state: this.state.state?.name ? this.state.state?.name : this.state.state,
        pincode: this.state.pinCode,
        phone1: this.state.telephone1,
        phone2: this.state.telephone2,
        fax: this.state.fax,
        vendorlogid: this.state.loginID,
        certification: this.state.certification,
        bankreference: this.state.bankReference,
        companytype: this.state.companyType,
        industry_type: this.state.industryType,
        turnover: this.state.companyTurnover,
        products: this.state.vendorForProducts,
        category: this.state.vendorForCategory,
        details: this.state.otherDetails,
        remarks: this.state.remarks,
        contactemail:this.state.EmailContactPerson,
        msme:this.state.msmeNumber,
        msme_status: this.state.msme_status,
        gstin_status: this.state.gstin_status,
        pan_status: this.state.pan_status,
      };
      if(this.props?.companyInfo.common_vendor_login === "Y" && this.props?.companyInfo.invoice_against_grn_po === "Y" ){
        payload.invoice_class = invoiceClassHash[this.state.vendorInvoiceClass];
      }
      if(this.state.country && this.state.country?.country !== "India" && !this.state.stateUpdated){
          payload.state = this.state.prevState;
      }
      if (this.props.accountModulePermission["Fetch Payment Term"]?.module_activated === "Y") {
        payload.paymentTermsList = this.state.paymentTermsCode?.row_id;
      }
      if (this.props.accountModulePermission["Fetch Inco Term"]?.module_activated === "Y") {
        payload.incoTermsList = this.state.incoTermsCode?.row_id;
      }
      if (this.props.companyInfo?.common_vendor_login === "Y"){
        payload.vendorlogid = this.state.loginID;
      }
      if (this.props.currentVendorID) {      
        axios
          .put(getVendorDetails(this.props.currentVendorID), payload)
          .then((res) => {
            this.addUpdateContactPersonDeatails(this.props.currentVendorID, true)
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
            if (err.response?.data?.validation_errors) {
              let errorObj = err.response.data.validation_errors;
              let errorMsg = "";
              for (const key in errorObj) {
                errorObj[key].forEach((msg) => {
                  errorMsg = `${errorMsg && `${errorMsg}, `}${msg}`;
                });
              }
              this.props.openSnackbar("error", errorMsg);
            } else {
              this.props.openSnackbar("error", "something went wrong!");
            }
          });
      } else {
        const url = this.props.companyInfo?.common_vendor_login === "Y" ? postCompanyVendors : addVendor
        payload.approved = this.state.is_approved;
        axios
          .post(url, payload)
          .then((res) => {
            this.addUpdateContactPersonDeatails(res.data.data.id, false)
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
            if (err.response?.data?.validation_errors) {
              let errorObj = err.response.data.validation_errors;
              let errorMsg = "";
              for (const key in errorObj) {
                errorObj[key].forEach((msg) => {
                  errorMsg = `${errorMsg && `${errorMsg}, `}${msg}`;
                });
              }
              this.props.openSnackbar("error", errorMsg);
            } else {
              this.props.openSnackbar("error", "something went wrong!");
            }
          });
      }
  };

  handleTab2Change = (e, index, newValue) => {
    const { name, value } = e.target;
    const { contactPersonDetails } = this.state;
    if (newValue !== undefined && newValue?.length === 0){
      contactPersonDetails[index]["contactPersonName"]["error"] = false;
      contactPersonDetails[index]["contactPersonEmail"]["error"] = false;
      contactPersonDetails[index]["contactNumber"]["error"] = false;
    }
    contactPersonDetails[index][name]["value"] = newValue ? newValue : value;
    this.setState({
      contactPersonDetails,
    });
  };
  
  handleTab2Blur = (e, index, newValue) => {
    const { name, value } = e.target;
    const { contactPersonDetails } = this.state;

    let error = false;
    if (name === "contactPersonName"){
      if (value.length < 3){
        error=true;
      } 
    } else if (name === "contactPersonEmail"){
      let email_regex = /^[A-Z0-9._%-]+@[A-Z0-9.-]+[.][A-Z]{2,63}(?:[,][A-Z0-9._%-]+@[A-Z0-9.-]+[.][A-Z]{2,63})*$/i;
      if (!email_regex.test(value)){
        error=true;
      } 
    } else if (name === "contactNumber"){
      if (value){
        if(!(value.match(/^(\+)?\d{10,15}$/))){
          error=true;
        } 
      }
    } else if (name === "destination"){
      if (!value.length) {
        // console.log(name,value);
        error=true;
      }
    }

    // if any of that row has value 
    if (
      contactPersonDetails[index]["destination"]["value"]?.length ||
      contactPersonDetails[index]["contactNumber"]["value"]?.length ||
      contactPersonDetails[index]["contactPersonEmail"]["value"]?.length ||
      contactPersonDetails[index]["contactPersonName"]["value"]?.length
    ){
      contactPersonDetails[index][name]["error"] = error;
    } else {
      // contactPersonDetails[index][name]["error"] = false;
      contactPersonDetails[index]["destination"]["error"] = false;
      contactPersonDetails[index]["contactNumber"]["error"] = false;
      contactPersonDetails[index]["contactPersonEmail"]["error"] = false;
      contactPersonDetails[index]["contactPersonName"]["error"] = false
    }

    this.setState({
      contactPersonDetails,
    });
  };

  fetchPaymentTermsOptions = () => {
    this.setState({
      paymentTermsCodeLoading : true,
    })
    axios.get(getPaymentTermsOptions()).then(res => {
      this.setState({
        paymentTermsCodeLoading : false,
        paymentTermsCodeOptions : res.data.data
      },() => {
        if (this.state.payment_term_info){
          let paymentTermsCode = this.state.paymentTermsCodeOptions?.find(
            (option) =>
              parseFloat(option.row_id) ===
              parseFloat(this.state.payment_term_info.id)
          );
          if (paymentTermsCode) {
            this.setState({
              paymentTermsCode
            })
          } else {
            this.setState({
              paymentTermsCode : null
            })
          }
        } else {
          this.setState({
            paymentTermsCode : null
          })
        }
      })
    }).catch(err => {
      console.log(err, "payment term fetch error")
      this.setState({
        paymentTermsCodeLoading : false,
      })
    })
  }
  fetchIncoTermsOptions = () => {
    this.setState({
      incoTermsCodeLoading : true,
    })
    axios.get(getIncoTermsOptions()).then(res => {
      this.setState({
        incoTermsCodeLoading : false,
        incoTermsCodeOptions : res.data.data
      }, () => {
        if (this.state.inco_term_info){
          let incoTermsCode = this.state.incoTermsCodeOptions?.find(
            (option) => 
              parseFloat(option.row_id) ===
              parseFloat(this.state.inco_term_info.id)
              
          );
          if (incoTermsCode) {
            this.setState({
              incoTermsCode
            })
          } else {
            this.setState({
              incoTermsCode : null
            })
          }
        }
      })
    }).catch(err => {
      console.log(err, "Inco term fetch error")
      this.setState({
        incoTermsCodeLoading : false,
      })
    })
  }

  fetchCountriesOptions = () => {
    this.setState({
      countriesOptionsLoading : true,
    })
    
    axios.get(getCountries).then(res => {
      this.setState({
        countriesOptionsLoading : false,
        countriesOptions : [...res.data.data, { country: "Local Importer" }]
      }, () => {

        if (this.state.prevCountry){
          let country = this.state.countriesOptions?.find(
            (option) => 
              option.country?.toLowerCase() ===
              this.state.prevCountry.toLowerCase()
          );
          if (country) {
            this.setState({
              country
            }, () => {
              if (this.state.prevState){
                let state = State.getStatesOfCountry(this.state.country.union_id)?.find(
                  (option) => 
                    option.name?.toLowerCase() ===
                    this.state.prevState?.toLowerCase()
                );
                if (state) {
                  this.setState({
                    state
                  })
                } else {
                  this.setState({
                    state : null
                  })
                }
              }
            })
          } else {
            this.setState({
              country : null
            })
          }
        }
      })
    }).catch(err => {
      this.setState({
        countriesOptionsLoading : false,
      })
    })
  }

  addUpdateContactPersonDeatails = (id, edit) => {
    if (
      this.props.masterPermission?.add_edit_vendor_contact_person === "Y" &&
      this.props.accountModulePermission["Add/Update contact details"]
        ?.module_activated === "Y"
    ) {
      let payload1 = [];
      this.state.contactPersonDetails.forEach((row) => {
        if (
          row["destination"]["value"]?.length ||
          row["contactNumber"]["value"]?.length ||
          row["contactPersonEmail"]["value"]?.length ||
          row["contactPersonName"]["value"]?.length
        ) {
          payload1.push({
            destination_ids: row.destination.value.map((el) => el.row_id),
            contact_email: row.contactPersonEmail.value,
            contact_name: row.contactPersonName.value,
            contact_no: row.contactNumber.value,
          });
        }
      });

      axios
        .post(getContactDetails(id), {
          contact_details: payload1,
        })
        .then(() => {
          if(edit){
            this.props.openSnackbar("success","Vendor and Contact Person details (if applicable) are edited successfully!", true);
          }
          else{
            this.props.openSnackbar("success","Vendor and Contact Person details (if applicable) are added successfully!", true);
          }
          this.setState({
            loading: false,
          });
          this.props.handleClose();
          this.props.handleVendorsReload();
        })
        .catch((err) => {
          console.log("Error in Adding Contact Details", err);
          if (err.response?.data?.validation_errors) {
            let errorObj = err.response.data.validation_errors;
            let errorMsg = "";
            for (const key in errorObj) {
              errorObj[key].forEach((msg) => {
                errorMsg = `${errorMsg && `${errorMsg}, `}${msg}`;
              });
            }
            this.props.openSnackbar("error", errorMsg);
          } else {
            this.props.openSnackbar("error", "something went wrong!");
          }
          this.setState({
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
      });
      if(edit){
        this.props.openSnackbar("success","Vendor edited successfully!", true);  
      }
      else{
        this.props.openSnackbar("success","Vendor added successfully!", true);
      }

      this.props.handleClose();
      this.props.handleVendorsReload();
    }
  };

  getStatusIcon = (status, type) => {

    const { companyInfo } = this.props;
    const permissions = {
      GSTIN: companyInfo?.gst_verification,
      PAN: companyInfo?.pan_verification,
      MSME: companyInfo?.msme_verification,
    };

    const statusMap = {
        Verified: {
          icon: <CheckCircleOutlineOutlinedIcon style={{ color: 'green' }} />,
          tooltip: "Verified",
        },
        Pending: {
          icon: <ScheduleIcon style={{ color: 'yellow' }} />,
          tooltip: " Pending",
        },
        "Not Found": {
          icon: <CancelOutlinedIcon style={{ color: 'grey' }} />,
          tooltip: "Not Found ",
        },
        Inactive: {
          icon: <BlockOutlinedIcon style={{ color: 'red' }} />,
          tooltip: "Inactive",
        }
    };

    if (statusMap[status] && permissions[type] === 'Y') {

      return (
        <InputAdornment position="end">
          <Tooltip title= {`${type} ${statusMap[status].tooltip}`} >
            {statusMap[status].icon}
          </Tooltip>
        </InputAdornment>
      );
    }
    return null;
  };

  render() {
    const { open, handleClose } = this.props;
    return (
      <div>
        <Backdrop
          style={{ color: "var(--thm-white)", zIndex: "10000000000" }}
          open={this.state.loading || this.state.contactPersonDetailsloading}
        >
          <CircularProgress style={{ color: "var(--thm-white)" }} />
        </Backdrop>
        <Dialog
          scroll="paper"
          maxWidth={"lg"}
          open={open}
          // fullScreen
          // onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          className="vendor_edit_main"
        >
          <DialogTitle
            id="responsive-dialog-title"           
            sx={{
              '&:focus-visible': {
                border: 'none',
                outline: 'none'
              }
            }}
            ref={this.descriptionElementRef}
            tabIndex={-1}
          >
            <Grid container>
              <Grid item sm={12}>
                <Box sx={{display:'flex', justifyContent:'space-between', width:'100%'}}>
                  {this.props.currentVendorID ? "Edit Vendor" + ": " + this.props.currentVendorName : "Add Vendor"}
                  <Box className="dialog-title-action">
                    <IconButton
                      sx={{
                        color: (theme) => theme.palette.bluegrey[500]}}
                        onClick={handleClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={12}>
                <Box>
                  <Tabs
                    value={this.state.tabValue}
                    onChange={(evt, newVal) => {
                      this.setState({
                        tabValue: newVal,
                      });
                    }}
                  >
                    {/* Add/Update contact details */}
                    <Tab label="VENDOR DETAILS" />
                    {this.props.masterPermission
                      ?.add_edit_vendor_contact_person === "Y" && 
                      this.props.accountModulePermission["Add/Update contact details"]?.module_activated === "Y" && (
                        <Tab label="CONTACT PERSON DETAILS" sx={{color:(theme) => theme.palette.error}} />
                    )} 
                  </Tabs>
                </Box>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent className="add_item_dialog_content">
              {this.props.isVendorSelected && <Typography component="p" color="grey.500">
                Note: Vendor selection will be reset on submit.
              </Typography>}
              <form id="novalidatedform" noValidate autoComplete="off">
                <Grid container>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="vendor_edit_tab"
                  >
                    {/* Add/Update contact details */}
                    {/* <Tabs
                      value={this.state.tabValue}
                      onChange={(evt, newVal) => {
                        this.setState({
                          tabValue: newVal,
                        });
                      }}
                    >
                      <Tab label="VENDOR DETAILS" />
                      {this.props.masterPermission
                        ?.add_edit_vendor_contact_person === "Y" && 
                        this.props.accountModulePermission["Add/Update contact details"]?.module_activated === "Y" && (
                          <Tab label="CONTACT PERSON DETAILS" sx={{color:(theme) => theme.palette.error}} />
                      )} 
                    </Tabs> */}
                  </Grid>
                  <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} sx={{mt:'20px'}}>
                      <TabPanel value={this.state.tabValue} index={0}>
                        <Grid container spacing={2} sx={{mx:'auto'}}>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Vendor Name"
                              name="firstName"
                              value={this.state.firstName}
                              disabled={this.props.currentVendorID && this.state.restriction}
                              onChange={this.handleChange}
                              variant="outlined"
                              onBlur={this.handleBlur}
                              required
                              error={this.state.error["firstName"]}
                              helperText={
                                this.state.error["firstName"] ? "required" : ""
                              }
                              fullWidth                            
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Dummy Name"
                              name="lastName"
                              value={this.state.lastName}
                              onChange={this.handleChange}
                              variant="outlined"
                              fullWidth                            
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Vendor Code"
                              name="code"
                              value={this.state.code}
                              disabled={this.props.currentVendorID && (this.state.restriction || this.props.companyInfo?.common_vendor_login === "Y")}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              error={this.state.error["code"]}
                              helperText={
                                this.state.error["code"] ? "required" : ""
                              }
                              variant="outlined"
                              required
                              fullWidth
                            />
                          </Grid>
                          {(this.props?.companyInfo.common_vendor_login === "Y" && this.props?.companyInfo.invoice_against_grn_po === "Y" ) && 
                          <Grid
                            item
                            lg={4}
                            md={6}
                            sm={6}
                            xs={12}
                            className=""
                          >
                            <FormControl fullWidth variant="outlined" error={this.state.error["vendorInvoiceClass"]} required>
                              <InputLabel>Vendor Invoice Group</InputLabel>
                              <Select
                                label='Vendor Invoice Group'
                                name="vendorInvoiceClass"
                                onChange={this.handleChange}
                                value={this.state.vendorInvoiceClass}
                                onBlur={this.handleBlur}
                              >
                                <MenuItem value="0">
                                  Select
                                </MenuItem>
                                <MenuItem value="10">
                                  PO Invoice
                                </MenuItem>

                                <MenuItem value="20">
                                  GRN Invoice
                                </MenuItem>

                                <MenuItem value="30">
                                  PO/GRN Invoice
                                </MenuItem>
                              </Select>
                              {this.state.error["vendorInvoiceClass"] && (
                                <FormHelperText>Required</FormHelperText>
                              )}
                            </FormControl>
                          </Grid>}
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              fullWidth 
                              variant="outlined"
                              maxRows={2}
                              label="Email"
                              type="email"
                              name="email"
                              disabled={this.props.currentVendorID && this.state.restriction}

                              value={this.state.email}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              required={this.props.currentVendorID || this.props.companyInfo?.common_vendor_login === "Y"}
                              error={this.state.error["email"]}
                              helperText={
                                this.state.error["email"] 
                                  ? this.state.email.length
                                    ? "invalid email"
                                    : "required"
                                  : ""
                              }
                                  InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">                                        
                                          <Tooltip placement="left" title="Use coma (,) to enter multiple email ids">                                      
                                            <HelpOutlined sx={{ color: (theme) => theme.palette.grey[600] }} />      
                                          </Tooltip>                                   
                                        </InputAdornment>
                                      ),
                                  }}                           
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Website URL"
                              name="websiteURL"
                              value={this.state.websiteURL}
                              onChange={this.handleChange}
                              // onBlur={this.handleBlur}
                              error={this.state.error["websiteURL"]}
                              helperText={
                                this.state.error["websiteURL"] ? "invalid" : ""
                              }
                              variant="outlined"
                              fullWidth 
                              // InputProps={{
                              //   startAdornment: <InputAdornment position="start">
                              //     <FormControl sx={{ p:0 }}>
                              //       <Select
                              //         sx={{
                              //           fieldset:{
                              //             border:"none"
                              //           },
                              //         }}
                              //         value={this.state.urlProtocol}
                              //         onChange={(e)=> this.setState({ urlProtocol: e.target.value})}
                              //         displayEmpty
                              //         inputProps={{ 'aria-label': 'Without label' }}
                              //       >
                              //         <MenuItem value={"https://"}>https://</MenuItem>
                              //         <MenuItem value={"http://"}>http://</MenuItem>
                              //       </Select>
                              //     </FormControl>
                              //   </InputAdornment>,
                              // }}
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            {/* <TextField
                              className="local_template_textfield"
                              label="country"
                              name="country"
                              value={this.state.country}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              required
                              error={this.state.error["country"]}
                              helperText={
                                this.state.error["country"] ? "required" : ""
                              }
                              variant="outlined"
                              fullWidth 
                            /> */}
                            <Autocomplete
                              options={this.state.countriesOptions}
                              filterOptions={filterOptions}
                              loading={this.state.countriesOptionsLoading}
                              getOptionLabel={(option) => option.country}
                              value={this.state.country}
                              disabled={this.props.currentVendorID && this.state.restriction}
                              name="country"
                              onChange={(event, newVal) => {
                                this.setState((prevState) => ({
                                  ...prevState,
                                  country: newVal,
                                  state: null
                                }));
                              }}
                              onBlur={() => {
                                // console.log(this.state.country, "newVal");
                                if (this.state.country) {
                                  this.setState((prevState) => ({
                                    ...prevState,
                                    error:{
                                      ...prevState.error,
                                      country: false
                                    }
                                  }));
                                } else {
                                  this.setState((prevState) => ({
                                    ...prevState,
                                    error:{
                                      ...prevState.error,
                                      country: true
                                    }
                                  }));
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Country"
                                  fullWidth
                                  required
                                  error={this.state.error["country"]}
                                  helperText={
                                    this.state.error["country"] ? "required" : ""
                                  }
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {this.state.countriesOptionsLoading ? (
                                          <CircularProgress color="inherit" size={20} />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                          {this.state.country &&
                            this.state.country?.country === "India" ? (
                              <Autocomplete
                                loading={this.state.countriesOptionsLoading}
                                options={
                                  this.state.country
                                    ? State.getStatesOfCountry(
                                        this.state.country?.union_id
                                      )
                                    : []
                                }
                                getOptionLabel={(option) => {
                                  return option.name
                                }}
                                disabled={!this.state.country || this.state.countriesOptionsLoading}
                                value={this.state.state}
                                name="state"
                                onChange={(event, newVal) => {
                                  this.setState((prevState) => ({
                                    ...prevState,
                                    state: newVal,
                                  }));
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="State"
                                    fullWidth
                                    // InputProps={{
                                    //   endAdornment: (
                                    //     <React.Fragment>
                                    //       {this.state.countriesOptionsLoading ? (
                                    //         <CircularProgress color="inherit" size={20} />
                                    //       ) : null}
                                    //     </React.Fragment>
                                    //   ),
                                    // }}
                                  />
                                )}
                              />
                              ) : (
                              <TextField
                                className="local_template_textfield"
                                label="State"
                                key={this.state.countriesOptionsLoading}
                                name="state"
                                disabled={!this.state.country || this.state.countriesOptionsLoading}
                                value={this.state.stateUpdated ? this.state.state : (this.state.state || this.state.prevState)}
                                onChange={this.handleChange}
                                helperText={
                                  this.state.error["state"] ? "required" : ""
                                }
                                variant="outlined"
                                fullWidth
                              />
                              
                            )}
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="City"
                              name="city"
                              value={this.state.city}
                              onChange={this.handleChange}
                              helperText={
                                this.state.error["city"] ? "required" : ""
                              }
                              variant="outlined"
                              fullWidth 
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Contact Person"
                              name="contactPerson"
                              value={this.state.contactPerson}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              required
                              error={this.state.error["contactPerson"]}
                              helperText={
                                this.state.error["contactPerson"]
                                  ? "required"
                                  : ""
                              }
                              variant="outlined"
                              fullWidth 
                            />
                          </Grid>
                        {!this.props.currentVendorID && this.props.companyInfo?.common_vendor_login === "Y" && <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Global Login"
                              name="globalLogin"
                              value={this.state.globalLogin}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              required
                              error={this.state.error["globalLogin"]}
                              helperText={
                                this.state.error["globalLogin"]
                                  ? this.state.globalLogin.length
                                    ? "invalid global login"
                                    : "required"
                                  : ""
                              }
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Tooltip placement="left" title="Global login must contain @">
                                      <HelpOutlined sx={{ color: (theme) => theme.palette.grey[600] }} />
                                    </Tooltip>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>}
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Designation"
                              name="designation"
                              value={this.state.designation}
                              onChange={this.handleChange}
                              helperText={
                                this.state.error["designation"] ? "required" : ""
                              }
                              variant="outlined"
                              fullWidth 
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Email Contact Person"
                              type="email"
                              name="EmailContactPerson"
                              value={this.state.EmailContactPerson}
                              onChange={this.handleChange}
                              helperText={
                                this.state.error["EmailContactPerson"]
                                  ? "required"
                                  : ""
                              }
                              variant="outlined"
                              fullWidth 
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Address"
                              name="address"
                              value={this.state.address}
                              onChange={this.handleChange}
                              helperText={
                                this.state.error["address"] ? "required" : ""
                              }
                              variant="outlined"
                              fullWidth 
                            />
                          </Grid>
                          
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Pin Code"
                              name="pinCode"
                              value={this.state.pinCode}
                              onChange={this.handleChange}
                              helperText={
                                this.state.error["pinCode"] ? "required" : ""
                              }
                              variant="outlined"
                              fullWidth 
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Telephone 1"
                              name="telephone1"
                              value={this.state.telephone1}
                              onChange={this.handleChange}
                              helperText={
                                this.state.error["telephone1"] ? "required" : ""
                              }
                              variant="outlined"
                              fullWidth 
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Telephone 2"
                              name="telephone2"
                              value={this.state.telephone2}
                              onChange={this.handleChange}
                              helperText={
                                this.state.error["telephone2"] ? "required" : ""
                              }
                              variant="outlined"
                              fullWidth 
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Cell Phone"
                              name="cellPhone"
                              value={this.state.cellPhone}
                              onChange={this.handleChange}
                              helperText={
                                this.state.error["cellPhone"] ? "required" : ""
                              }
                              variant="outlined"
                              fullWidth 
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Fax"
                              name="fax"
                              value={this.state.fax}
                              onChange={this.handleChange}
                              helperText={
                                this.state.error["fax"] ? "required" : ""
                              }
                              variant="outlined"
                              fullWidth 
                            />
                          </Grid>
                          { (this.props.currentVendorID || (!this.props.currentVendorID && this.props.companyInfo?.common_vendor_login !== "Y")) && 
                            <Grid item lg={4} md={6} sm={6} xs={12} className="">
                              <TextField
                                className="local_template_textfield"
                                label="Login ID"
                                name="loginID"
                                value={this.state.loginID}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth 
                                error={this.state.error["loginID"]}
                                // onBlur={this.handleBlur}
                                helperText={
                                  this.state.error["loginID"]
                                    ? "Give login id or remove chat permission"
                                    : ""
                                }
                              />
                            </Grid>
                          }
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Certifications"
                              name="certification"
                              value={this.state.certification}
                              onChange={this.handleChange}
                              helperText={
                                this.state.error["certification"]
                                  ? "required"
                                  : ""
                              }
                              variant="outlined"
                              fullWidth 
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Bank Reference"
                              name="bankReference"
                              value={this.state.bankReference}
                              onChange={this.handleChange}
                              helperText={
                                this.state.error["bankReference"]
                                  ? "required"
                                  : ""
                              }
                              variant="outlined"
                              fullWidth 
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Company Type"
                              name="companyType"
                              value={this.state.companyType}
                              onChange={this.handleChange}
                              helperText={
                                this.state.error["companyType"] ? "required" : ""
                              }
                              variant="outlined"
                              fullWidth 
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <Grid container spacing={2}>
                              <Grid item lg={6} md={6} sm={6} xs={12}>
                                <FormControl fullWidth variant="outlined" >
                                  <InputLabel>Industry Type</InputLabel>
                                  <Select
                                    label="Industry Type"
                                    name="industryType"
                                    onChange={this.handleChange}
                                    value={this.state.industryType}
                                    helperText={
                                      this.state.error["industryType"]
                                        ? "required"
                                        : ""
                                    }
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <MenuItem value="">
                                      Select Industry Type
                                    </MenuItem>
                                    <MenuItem value="MSME">
                                      MSME
                                    </MenuItem>
                                    <MenuItem value="OTHER">
                                      OTHER
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item lg={6} md={6} sm={6} xs={12}>
                                <TextField
                                  className="local_template_textfield"
                                  label="MSME Number"
                                  name="msmeNumber"
                                  value={this.state.msmeNumber}
                                  onChange={this.handleChange}
                                  helperText={
                                    this.state.error["msmeNumber"]
                                      ? "required"
                                      : ""
                                  }
                                  variant="outlined"
                                  fullWidth 
                                  InputProps={{            
                                    endAdornment: this.getStatusIcon(this.state.msme_status, 'MSME'),
                                  }}                               
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Company Turnover"
                              name="companyTurnover"
                              value={this.state.companyTurnover}
                              onChange={this.handleChange}
                              helperText={
                                this.state.error["companyTurnover"]
                                  ? "required"
                                  : ""
                              }
                              variant="outlined"
                              fullWidth 
                            /> 
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Vendor for Product(s)"
                              name="vendorForProducts"
                              value={this.state.vendorForProducts}
                              onChange={this.handleChange}
                              helperText="(seperate products by ;)"
                              // helperText={
                              //   this.state.error["vendorForProducts"]
                              //     ? "required"
                              //     : ""
                              // }
                              variant="outlined"
                              fullWidth
                              multiline
                              maxRows={4} 
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Vendor for Category(s)"
                              name="vendorForCategory"
                              value={this.state.vendorForCategory}
                              onChange={this.handleChange}
                              helperText="(seperate categories by ;)"
                              // helperText={
                              //   this.state.error["vendorForCategory"]
                              //     ? "required"
                              //     : ""
                              // }
                              variant="outlined"
                              fullWidth
                              multiline
                              maxRows={4} 
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Other Details"
                              name="otherDetails"
                              value={this.state.otherDetails}
                              onChange={this.handleChange}
                              helperText={
                                this.state.error["otherDetails"] ? "required" : ""
                              }
                              variant="outlined"
                              fullWidth
                              multiline
                              maxRows={4} 
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="Your Remarks about this Vendor"
                              name="remarks"
                              value={this.state.remarks}
                              onChange={this.handleChange}
                              helperText={
                                this.state.error["remarks"] ? "required" : ""
                              }
                              variant="outlined"
                              multiline
                              maxRows={4}
                              fullWidth 
                            />
                          </Grid>
                          {this.props.accountModulePermission["Fetch Payment Term"]?.module_activated === "Y" &&
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                              <Autocomplete
                                options={this.state.paymentTermsCodeOptions}
                                loading={this.state.paymentTermsCodeLoading}
                                getOptionLabel={(option) => option.dropdown_element_name}
                                isOptionEqualToValue={(option, value) => option.row_id === value.row_id}
                                value={this.state.paymentTermsCode}
                                onChange={(event, newVal) => {
                                  this.setState((prevState) => ({
                                    ...prevState,
                                    paymentTermsCode: newVal,
                                  }));
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Payment Terms Code"
                                    fullWidth
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {this.state.paymentTermsCodeLoading ? (
                                            <CircularProgress color="inherit" size={20} />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          }
                          {this.props.accountModulePermission["Fetch Inco Term"]?.module_activated === "Y" &&
                            <Grid item lg={4} md={6} sm={6} xs={12} className="">
                              <Autocomplete
                                options={this.state.incoTermsCodeOptions}
                                loading={this.state.incoTermsCodeLoading}
                                getOptionLabel={(option) => option.dropdown_element_name}
                                filterOptions={(x) => x} 
                                value={this.state.incoTermsCode}
                                onChange={(event, newVal) => {
                                  this.setState((prevState) => ({
                                    ...prevState,
                                    incoTermsCode: newVal,
                                  }));
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Inco Terms Code"
                                    fullWidth
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {this.state.incoTermsCodeLoading ? (
                                            <CircularProgress color="inherit" size={20} />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                          </Grid>}
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="HSN Code"
                              name="hsnCode"
                              value={this.state.hsnCode}
                              onChange={this.handleChange}
                              helperText="Use comma (,) to enter multiple HSN Code"
                              // helperText={
                              //   this.state.error["hsnCode"] ? "required" : ""
                              // }
                              variant="outlined"
                              multiline
                              maxRows={4}
                              fullWidth 
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="SAC Code"
                              name="sacCode"
                              value={this.state.sacCode}
                              onChange={this.handleChange}
                              helperText="Use comma (,) to enter multiple SAC Code"
                              // helperText={
                              //   this.state.error["sacCode"] ? "required" : ""
                              // }
                              multiline
                              maxRows={4}
                              variant="outlined"
                              fullWidth 
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="PAN Number"
                              name="pan"
                              value={this.state.pan}
                              onChange={this.handleChange}
                              helperText="PAN Number should have Capital Letters."
                              // helperText={
                              //   this.state.error["pan"] ? "required" : ""
                              // }
                              variant="outlined"
                              fullWidth 
                              InputProps={{            
                                endAdornment: this.getStatusIcon(this.state.pan_status, 'PAN'),
                              }}
                            />
                          </Grid>
                          <Grid item lg={4} md={6} sm={6} xs={12} className="">
                            <TextField
                              className="local_template_textfield"
                              label="GSTIN Number"
                              name="gstIN"
                              value={this.state.gstIN}
                              onChange={this.handleChange}
                              helperText="GSTIN Number should have Capital Letters."
                              // helperText={
                              //   this.state.error["gstIN"] ? "required" : ""
                              // }
                              variant="outlined"
                              fullWidth 
                              InputProps={{         
                                endAdornment: this.getStatusIcon(this.state.gstin_status, 'GSTIN'),
                              }}
                            />
                          </Grid>
                          {!this.props.currentVendorID && <Grid
                            item
                            lg={4}
                            md={6}
                            sm={6}
                            xs={12}
                            className=""
                          >
                            <FormControl fullWidth variant="outlined" disabled={this.props.masterPermission?.vendor_approval !== "Y"} >
                              <InputLabel>Vendor Approval</InputLabel>
                              <Select
                                // variant="outlined"
                                label='Vendor Approval'
                                name="is_approved"
                                onChange={this.handleChange}
                                value={this.state.is_approved}
                              >
                                <MenuItem value="Y">
                                  Approved
                                </MenuItem>
                                <MenuItem value="N">
                                  Non Approved
                                </MenuItem>
                              </Select>
                            </FormControl>
                            {/* <FormControlLabel
                              control={
                                <Switch
                                  name="is_approved"
                                  onChange={this.handleChange}
                                  checked={this.state.is_approved}
                                  sx={{ml:'-8px'}}
                                />
                              }
                              label="Vendor Approval"
                            /> */}
                          </Grid>}
                        
                          
                          
                        </Grid>
                        <Box sx={{px:2}}>

                        <Divider sx={{my:2}}/>
                        </Box>
                        <Grid container>
                          <Grid
                            item
                            lg={4}
                            md={6}
                            sm={6}
                            xs={12}
                            className=""
                            style={{ paddingLeft: "35px" }}
                          >
                            <Tooltip title={ this.state.smsPermission ? "Turn off SMS permission" : "Turn on SMS permission"}>                                      
                              <FormControlLabel
                                control={
                                  <Switch
                                    name="smsPermission"
                                    onChange={this.handleChange}
                                    checked={this.state.smsPermission}
                                    sx={{ml:'-8px'}}
                                  />
                                }
                                label="SMS Permission"
                              />
                            </Tooltip>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={6}
                            sm={6}
                            xs={12}
                            className=""
                            style={{ paddingLeft: "35px" }}
                          >
                            <Tooltip title={ this.state.chatPermission ? "Turn off chat permission" : "Turn on chat permission"}>                                      
                              <FormControlLabel
                                control={
                                  <Switch
                                    name="chatPermission"
                                    onChange={this.handleChange}
                                    checked={this.state.chatPermission}
                                    sx={{ml:'-8px'}}
                                  />
                                }
                                label="Chat Permission"
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </Grid>
                    <Grid item lg={12}>
                      <TabPanel value={this.state.tabValue} index={1}>
                        <Grid container>
                          <Grid item lg={12} md={12} sm={12} xs={12} className="">
                            <div className="delivery_schedule_details_content">
                              <Grid container spacing={3} alignItems="Center">
                                <Grid
                                  item
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  className="mb-3"
                                >

                                  <div className="delivery_schedule_details_innercontent">
                                    <ContactPersonDetails 
                                      rows={this.state.contactPersonDetails}
                                      handleTab2Change={this.handleTab2Change}
                                      handleTab2Blur={this.handleTab2Blur}
                                      handleDelete={(index) => {
                                        this.state.contactPersonDetails.splice(index, 1);
                                        this.setState({
                                          contactPersonDetails: this.state.contactPersonDetails,
                                        }, () => {
                                          if (!this.state.contactPersonDetails.length){
                                            this.state.contactPersonDetails.push(
                                              {
                                                destination: {
                                                  value:[],
                                                  error:false
                                                },
                                                contactPersonName: {
                                                  value:"",
                                                  error:false
                                                },
                                                contactPersonEmail: {
                                                  value:"",
                                                  error:false
                                                },
                                                contactNumber: {
                                                  value:"",
                                                  error:false
                                                },
                                              }
                                            )
                                            this.setState({
                                              contactPersonDetails: this.state.contactPersonDetails,
                                            })
                                          }
                                        });
                                      }}
                                      destinationOptions={this.state.destinationOptions}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container spacing={0} className="add_more_row_btn">
                                <Grid item sm={12}>
                                  <Button
                                    onClick={() => {
                                      this.state.contactPersonDetails.push(
                                        {
                                          destination: {
                                            value:[],
                                            error:false
                                          },
                                          contactPersonName: {
                                            value:"",
                                            error:false
                                          },
                                          contactPersonEmail: {
                                            value:"",
                                            error:false
                                          },
                                          contactNumber: {
                                            value:"",
                                            error:false
                                          },
                                        }
                                      );
                                      this.setState({
                                        contactPersonDetails:
                                          this.state.contactPersonDetails,
                                      });
                                    }}
                                    startIcon={<AddCircleIcon />}
                                  >
                                    ADD More
                                  </Button>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={handleClose}>Cancel</Button>
            <Button variant='contained' autoFocus onClick={this.validateOnSubmit}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sessionId: state.sessionReducer.sessionId,
  accountModulePermission: state.permissionsReducer.accountModulePermission,
  selectedCategory: state.categoryReducer.selectedCategory,
  companyInfo: state.companyInfoReducer.companyInfo,
  masterPermission: state.permissionsReducer.masterPermission,
});
export default connect(mapStateToProps, {
  openSnackbar,
})(AddNewVendor);
