import * as React from "react";
import {
    Box,
    DialogContent,
    DialogTitle,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Stack,
    Avatar,
    Tooltip,
    IconButton,
    TableBody,
    styled,
    Tabs,
    Tab,
    CircularProgress,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import { tabsClasses } from "@mui/material/Tabs";
import { DialogMultiSolution } from "./multipleSolutionStyle";
import { devTemplateURi, get_Inquiries_data, templateURi } from "../../../config/apiUrl";
import axios from "axios";
import { NoVendorIcon } from "../../SvgIcon/SvgIcon";
import { URL_MAPPING } from "../../../config/host-mapping";
const currentHost = window.location.host;
const parentHostURI =
    URL_MAPPING[currentHost]["parent_host"] ||
    URL_MAPPING["default"]["parent_host"];
const ItemTabs = styled(Tabs)({
    minHeight: "32px",
    borderLeft: "1px solid var(--bluegrey-500)",
    "& .MuiTabs-indicator": {
        display: "none",
    },
});

const ItemTab = styled(Tab)({
    minHeight: "32px",
    width: "275px",
    textTransform: "none",
    color: "var(--secondary-600)",
    whiteSpace: "nowrap",
    alignItems: "start",
    borderRight: "1px solid var(--bluegrey-500)",
    ".MuiTypography-root": {
        width: "100%",
        textAlign: "left",
    },
    "&.Mui-selected": {
        background: "var(--primary-50)",
        ".MuiTypography-root": {
            color: "var(--primary-500)",
        },
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

export default function MultiSolutionDialog(props) {
    const { inquiryId, inquiryData, configAxiosGet, itemsList, userInfo, companyInfo } = props;
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [jsonLoading, setJsonLoading] = React.useState(true);
    const [openChildren, setOpenChildren] = React.useState({});
    const [multipleSolutionDetails, setMultipleSolutionDetails] = React.useState({});
    const [quotedItemIds, setQuotedItemIds] = React.useState([]);
    const [quotationJsonTemplates, setQuotationJsonTemplates] = React.useState({});
    const currentItemId = itemsList[value]?.id?.toString();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleExpandVendor = (vendorId) => {
        setOpenChildren(prev => ({
            ...prev,
            [currentItemId]: {
                ...prev[currentItemId],
                [vendorId]: !prev[currentItemId]?.[vendorId]
            }
        }));
    };

    React.useEffect(() => {
        const initialState = {};
        for (let i = 1; i <= 5; i++) {
            // Change 5 to your desired count
            initialState[`section${i}`] = true;
        }
        setOpenChildren(initialState);
    }, []);

    React.useEffect(() => {
        const fetchMultipleSolutionDetails = async () => {
            let url =
                get_Inquiries_data +
                inquiryId +
                "/vendors?embed[]=inquiry&embed[]=action&embed[]=item_solution";
            try {
                let response = await axios.get(url, configAxiosGet);
                let quotedItemKeys = [];
                if (response?.data && response?.data?.vendors) {
                    // Filter vendors where quotation.status is not "Pending"
                    // const filteredVendors = response.data.vendors.filter(
                    //     vendor => vendor.quotation.status !== "Pending"
                    // );
                    const filteredVendors = response?.data?.vendors
                        ?.filter((vendor) => {
                            const { quotation } = vendor;
                            if (quotation?.solution_data != undefined) {
                                quotedItemKeys.push(Object.keys(quotation?.solution_data))
                            }
                            return (
                                quotation?.status !== "Pending" &&
                                quotation?.solution_data)
                        }) || [];
                    setMultipleSolutionDetails({ ...response.data, vendors: filteredVendors });
                    let uniqueQuotedItemKeys = [...new Set(quotedItemKeys.flat().map(Number))];
                    setQuotedItemIds(uniqueQuotedItemKeys);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        if (inquiryId) {
            fetchMultipleSolutionDetails();
        }
    }, [inquiryId]);

    React.useEffect(() => {
        const fetchJSONTemplate = async (transaction_type, vendorType) => {
            if (
                !inquiryData ||
                !inquiryData.batch_id ||
                !inquiryData.template_id
            ) {
                return;
            }

            // Determine transaction_id
            let transaction_id;
            if (transaction_type === "Quotation") {
                transaction_id = 3;
            } else if (transaction_type === "Quotation" || transaction_type === "Requote") {
                transaction_id = 5;
            } else {
                console.error("Unknown type:", transaction_type);
                return;
            }

            const batchID = inquiryData.batch_id;
            const templateID = inquiryData.template_id;
            const currentHost = window.location.host;
            const parentHostURI =
                URL_MAPPING[currentHost]["parent_host"] ||
                URL_MAPPING["default"]["parent_host"];
            const template_url = URL_MAPPING[currentHost]["template_url"] || URL_MAPPING["default"]["template_url"];
            let templateURL = `${template_url}/template/${companyInfo?.display_name}/${userInfo?.division_id}/template_${templateID}_${batchID}_${transaction_id}${(vendorType === "Local" ? "_L" : "_I")}.json?version=` + Date.now();
            // let templateURL =
            //     process.env.NODE_ENV === "production"
            //         ? templateURi(
            //             userInfo?.division_id,
            //             companyInfo?.display_name,
            //             templateID,
            //             batchID,
            //             transaction_id + (vendorType === "Local" ? "_L" : "_I")
            //         )
            //         : devTemplateURi(
            //             userInfo?.division_id,
            //             companyInfo?.display_name,
            //             templateID,
            //             batchID,
            //             transaction_id + (vendorType === "Local" ? "_L" : "_I")
            //         );

            try {
                setJsonLoading(true);
                const response = await axios.get(templateURL);
                setQuotationJsonTemplates((prevTemplates) => ({
                    ...prevTemplates,
                    [`${transaction_type}_${vendorType}`]: response.data,
                }));
            } catch (err) {
                console.error("Error fetching template:", err);
            } finally {
                setJsonLoading(false);
            }
        };

        if (userInfo && companyInfo && Object.keys(userInfo).length > 0 && Object.keys(companyInfo).length > 0) {
            const combinations = [
                { transaction_type: "Quotation", vendorType: "Local" },
                { transaction_type: "Requote", vendorType: "Local" },
                { transaction_type: "Quotation", vendorType: "International" },
                { transaction_type: "Requote", vendorType: "International" },
            ];

            combinations.forEach(({ transaction_type, vendorType }) => {
                fetchJSONTemplate(transaction_type, vendorType);
            });
        }
    }, [inquiryData, userInfo, companyInfo]);

    React.useEffect(() => {
        if (multipleSolutionDetails?.vendors && currentItemId) {
            setOpenChildren(prev => {
                const currentItemVendors = multipleSolutionDetails.vendors;
                const currentItemState = prev[currentItemId] || {};

                // Create new state for current item's vendors
                const newVendorStates = currentItemVendors.reduce((acc, vendor) => {
                    // Preserve existing state or initialize to collapsed (false)
                    acc[vendor.id] = currentItemState[vendor.id] || true;
                    return acc;
                }, {});

                return {
                    ...prev,
                    [currentItemId]: newVendorStates
                };
            });
        }
    }, [multipleSolutionDetails, currentItemId]);

    return (
        <DialogMultiSolution
            fullScreen
            sx={{ m: 2 }}
            open={props.openMultipleSolution}
            onClose={props.closeMultipleSolution}
        >
            {loading && jsonLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress />
                </Box>
            ) :
                (
                    <>
                        <DialogTitle
                            component="div"
                            style={{
                                padding: "14px 16px",
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                                fontWeight={500}
                                color={"text.primary"}
                                component="h6"
                            >
                                Multiple Solution
                            </Typography>
                            <Tooltip title="Close">
                                <IconButton
                                    aria-label="close"
                                    onClick={props.closeMultipleSolution}
                                    sx={{ position: "absolute", right: 8, top: 13 }}
                                    size="small"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </DialogTitle>
                        <DialogContent sx={{ p: 0 }} dividers>
                            <Box className="MultiSolution-Box">
                                <Box className="ItemList-TabsHeader">
                                    <Typography
                                        component="h6"
                                        variant="body1"
                                        color={"text.primary"}
                                        className="MS-Tital"
                                    >
                                        Item List
                                    </Typography>
                                    <Box className="ItemTabs-Box">
                                        <ItemTabs
                                            value={value}
                                            variant="scrollable"
                                            scrollButtons
                                            allowScrollButtonsMobile
                                            sx={{
                                                [`& .${tabsClasses.scrollButtons}`]: {
                                                    "&.Mui-disabled": { display: "none" },
                                                },
                                                '.MuiButtonBase-root.Mui-selected .MuiTypography-caption': {
                                                    color: "var(--primary-300)"
                                                }
                                            }}
                                            onChange={handleChange}
                                        >
                                            {props.itemsList?.length > 0 && (
                                                props.itemsList.map((item) => (
                                                    (quotedItemIds.includes(parseInt(item.id)) ?
                                                        < ItemTab
                                                            label={
                                                                <>
                                                                    <Tooltip title={item.name} placement="bottom-start">
                                                                        <Typography variant="body2" color={"text.primary"} noWrap>
                                                                            {item.name}
                                                                        </Typography>
                                                                    </Tooltip>
                                                                    <Tooltip title={item.code} placement="bottom-start">
                                                                        <Typography variant="caption" color={"grey.500"} noWrap>
                                                                            {item.code}
                                                                        </Typography>
                                                                    </Tooltip>
                                                                </>
                                                            }
                                                        /> : null
                                                    )
                                                ))
                                            )}
                                        </ItemTabs>
                                    </Box>
                                </Box>
                                {/* // </Box> */}
                                <Box>
                                    <TabPanel value={value} index={value}>
                                        {multipleSolutionDetails?.vendors?.length > 0 ? (
                                            multipleSolutionDetails.vendors.map((vendor, index) => {
                                                const quotationStatus = vendor.quotation?.status;
                                                // const transactionType = quotationStatus === "Off. Requoted" ? "Requote" : "Quotation";
                                                const transactionType =
                                                    quotationStatus === "Off. Requoted" || quotationStatus === "Requoted"
                                                        ? "Requote"
                                                        : "Quotation";
                                                const vendorType = vendor.type; // Adjust based on actual vendor type property
                                                const templateKey = `${transactionType}_${vendorType}`;
                                                const template = quotationJsonTemplates[templateKey];

                                                if (!template) return null; // Handle loading or missing template

                                                const mainSection = (vendorType === 'Local' ? template.LOCAL : template.FOREIGN) || [];
                                                // const commonSection = template.COMMON || [];
                                                const itemizeSection = (template.ITEMIZE && template.ITEMIZE[0]) || [];
                                                // const combinedFields = [...mainSection, ...commonSection, ...itemizeSection]

                                                const combinedFields = [...itemizeSection].filter(
                                                    field =>
                                                        field.VisibilityFlag === "Y" ||
                                                        (field.DefaultValue && field.DefaultValue.Button === "Y" && field.ElementType === "D")
                                                );

                                                const solutionDataForItem = vendor.quotation.solution_data?.[currentItemId]?.data || [];

                                                return (
                                                    <Box className="ItemUser-List" key={`${vendor.id}-${index}`}>
                                                        {/* Vendor header and collapse button */}
                                                        <Box className="ItemUser-Box">
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => handleExpandVendor(vendor.id)}
                                                                aria-label="expand row"
                                                            >
                                                                {openChildren[currentItemId]?.[vendor.id] || false ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                                            </IconButton>
                                                            <Stack direction="row" justifyContent="start" alignItems="center" spacing={1}>
                                                                <Avatar
                                                                    size="small"
                                                                    sx={{
                                                                        color: "white",
                                                                        bgcolor: "var(--primary-500)",
                                                                        width: 32,
                                                                        height: 32,
                                                                        fontSize: "12px",
                                                                    }}
                                                                >
                                                                    {vendor.name?.toUpperCase().slice(0, 2)}
                                                                </Avatar>
                                                                <Box lineHeight={"normal"}>
                                                                    <Tooltip title={vendor.name}>
                                                                        <Typography variant="body2" fontWeight={500} sx={{ lineHeight: "normal" }} noWrap>
                                                                            {vendor.name}
                                                                        </Typography>
                                                                    </Tooltip>
                                                                    <Typography variant="caption" color="textSecondary" sx={{ lineHeight: "normal" }}>
                                                                        {vendor.code}
                                                                    </Typography>
                                                                </Box>
                                                            </Stack>
                                                        </Box>
                                                        <Collapse
                                                            in={openChildren[currentItemId]?.[vendor.id] || false}
                                                            timeout="auto"
                                                            unmountOnExit
                                                        >
                                                            <TableContainer className="ItemList-Table" sx={{ maxHeight: 200 }}>
                                                                <Table stickyHeader size="small">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>MULTIPLE SOLUTION NAME</TableCell>
                                                                            {combinedFields.map((field) =>
                                                                                field.FieldLabel === "Item *" ? null : (
                                                                                    <TableCell key={field.FieldId}>
                                                                                        {field.FieldLabel}
                                                                                    </TableCell>
                                                                                )
                                                                            )}
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {solutionDataForItem && solutionDataForItem.length > 0 ? (
                                                                            solutionDataForItem.map((dataEntry, dataIndex) => (
                                                                                <TableRow key={dataIndex}>
                                                                                    <TableCell>
                                                                                        <Tooltip title={dataEntry.solution_name} placement="bottom-start">
                                                                                            <Typography variant="body2" noWrap>{dataEntry.solution_name || ''}</Typography>
                                                                                        </Tooltip>
                                                                                    </TableCell>
                                                                                    {combinedFields.map((field) =>
                                                                                        field.FieldLabel === "Item *" ? null : (
                                                                                            <TableCell key={field.FieldId}>
                                                                                                <Tooltip title={dataEntry[field.FieldId] || 'NA'} placement="bottom-start">
                                                                                                    <Typography variant="body2" noWrap>{dataEntry[field.FieldId] || 'NA'}</Typography>
                                                                                                </Tooltip>
                                                                                            </TableCell>
                                                                                        )
                                                                                    )}
                                                                                </TableRow>
                                                                            ))
                                                                        ) : (
                                                                            <TableRow>
                                                                                <TableCell>
                                                                                    <Typography variant="body2" noWrap>NQ</Typography>
                                                                                </TableCell>
                                                                                {combinedFields.map((field) =>
                                                                                    field.FieldLabel === "Item *" ? null : (
                                                                                        <TableCell key={field.FieldId}>
                                                                                            <Typography variant="body2" noWrap>NQ</Typography>
                                                                                        </TableCell>
                                                                                    )
                                                                                )}
                                                                            </TableRow>
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Collapse>
                                                    </Box>
                                                );
                                            })
                                        ) : (
                                            <Box
                                                display={"flex"}
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                flexDirection={"column"}
                                                gap={2}
                                                sx={{
                                                    height: "calc(100vh - 160px)"
                                                }}
                                            >
                                                <NoVendorIcon sx={{ width: "167px", height: "75px" }} />
                                                <Typography variant="subtitle1"
                                                    color={"text.primary"}
                                                    fontWeight={400}
                                                    lineHeight={"normal"}
                                                    fontSize={"18px"}
                                                >
                                                    No Vendors Available
                                                </Typography>
                                            </Box>
                                        )}
                                    </TabPanel>
                                </Box>
                            </Box>
                        </DialogContent>
                    </>
                )
            }
        </DialogMultiSolution >
    );
}