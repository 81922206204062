import { combineReducers } from "redux";
import sessionReducer from "./sessionReducer";
import categoryReducer from "./categoryReducer";
import itemReducer from "./itemReducer";
import vendorReducer from "./vendorReducer";
import snackbarReducer from "./snackbarReducer";
import drawerValuesReducer from "./drawerValuesReducer";
import companyInfoReducer from "./companyInfoReducer";
import userInfoReducer from "./userInfoReducer";
import permissionsReducer from "./permissionsReducer";
import prrfqreducer from "./prrfqreducer";
import rfqListReducer from "./rfqListReducer";
import quotationInfoReducer from "./quotationInfoReducer"
import LinkUnlinkUserReducer from "./LinkUnlinkUserReducer";
import ImportQuotationReducer from "./ImportQuotationReducer"
import ImportExcelReducer from "./importExcelReducer";
import accountUsersReducer from "./accountUsersReducer";
import dropdownReducer from "./dropdownReducer";
const allReducers = combineReducers({
  sessionReducer,
  categoryReducer,
  itemReducer,
  vendorReducer,
  snackbarReducer,
  drawerValuesReducer,
  companyInfoReducer,
  userInfoReducer,
  permissionsReducer,
  prrfqreducer,
  rfqListReducer,
  quotationInfoReducer,
  LinkUnlinkUserReducer,
  ImportQuotationReducer,
  ImportExcelReducer,
  accountUsersReducer,
  dropdownReducer,

});

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    state = undefined;
  } else if (action.type === "RESET_CAT") {
    const {
      sessionReducer,
      snackbarReducer,
      companyInfoReducer,
      userInfoReducer,
      permissionsReducer,
    } = state;

    state = {
      sessionReducer,
      snackbarReducer,
      companyInfoReducer,
      userInfoReducer,
      permissionsReducer,
    };
  }
  return allReducers(state, action);
};

export default rootReducer;
