import { SET_ACCOUNT_USERS } from "../constants/type";

const initialState = {
  accountUsers: {},
};

const accountUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNT_USERS:
      return {
        ...state,
        accountUsers: action.payload, // Update state with new users
      };
    default:
      return state;
  }
};

export default accountUsersReducer;
