// export const PARENT_HOST = {
//     "default"                  : "https://vend-x.com",
//     "static.vend-x.com"        : "https://vend-x.com",
//     "static.ej-buy.com"        : "https://ej-buy.com",
//     "static-utcl.vend-x.com"   : "https://ultratechcement.vend-x.com",
//     "192.168.1.53:5353"        : 'http://192.168.1.53',
//     "testing-static.vend-x.com": "https://aws1.vend-x.com",
// };

// export const HOST_MAPPING = {
//     "default"                  : "http://192.168.1.158:3158",
//     "static.vend-x.com"        : "https://api.vend-x.com",
//     "static.ej-buy.com"        : "https://ejbuyapi.vend-x.com",
//     "static-utcl.vend-x.com"   : "https://utclapi.vend-x.com",
//     "192.168.1.53:5353"        : "http://192.168.1.53:5354",
//     "testing-static.vend-x.com": "https://alpha.vend-x.com",
// };

// export const URL_MAPPING = {
//     default: {
//         parent_host: "http://192.168.1.53",
//         api_host: "http://192.168.1.53:2158",
//         public_bucket: "https://local-app-data.vend-x.com",
//     },
//     "static.vend-x.com": {
//         parent_host: "https://vend-x.com",
//         api_host: "https://api.vend-x.com",
//         public_bucket: "https://app-data.vend-x.com",
//     },
//     "static.ej-buy.com": {
//         parent_host: "https://ej-buy.com",
//         api_host: "https://ejbuyapi.vend-x.com",
//         public_bucket: "https://app-data.ej-buy.com",
//     },
//     "static-utcl.vend-x.com": {
//         parent_host: "https://ultratechcement.vend-x.com",
//         api_host: "https://utclapi.vend-x.com",
//         public_bucket: "https://app-data-ultratechcement.vend-x.com",
//     },
//     // "192.168.1.161:8080": {
//     //     parent_host: "http://192.168.1.161",
//     //     api_host: "http://192.168.1.161:5541",
//     // },
//     // "192.168.1.161:3001": {
//     //     parent_host: "http://192.168.1.161",
//     //     api_host: "http://192.168.1.224:8006",
//     //     // api_host: "http://192.168.1.19:8000",
//     // },
//     "192.168.1.20": {
//         parent_host: "http://192.168.1.20",
//         api_host: "https://testing187-vendx2.vend-x.com",
//         // api_host: "http://192.168.1.19:8000",
//     },
//     "192.168.1.19:9000": {
//         parent_host: "http://192.168.1.20",
//         // api_host: "https://testing187-vendx2.vend-x.com",
//         api_host: "http://192.168.1.19:8000",
//     },
//     "192.168.1.161:3001": {
//         parent_host: "http://192.168.1.20",
//         api_host: "http://192.168.1.161:8888",
//     },
//     "192.168.1.249": {
//         parent_host: "http://192.168.1.249",
//         api_host: "https://testing187-vendx2.vend-x.com",
//         // api_host: "http://192.168.1.19:8000",
//     },
//     "testing187.vend-x.com": {
//         parent_host: "https://testing187.vend-x.com",
//         api_host: "https://testing187-vendx2.vend-x.com",
//         // api_host: "http://192.168.1.19:8000",
//     },
//     "testing-static.vend-x.com": {
//         parent_host: "https://testing182.vend-x.com",
//         //"api_host"      : "https://testingapi.vend-x.com",
//         api_host: "https://alpha.vend-x.com",
//         public_bucket: "https://app-data-testing182.vend-x.com",
//     },
// };

export const URL_MAPPING = {
  default: {
    parent_host: "http://192.168.1.53",
    api_host: "http://192.168.1.53:2158",
    public_bucket: "https://local-app-data.vend-x.com",
  },

  /* for vend-x.com urls */
  "vend-x.com": {
    parent_host: "https://vend-x.com",
    api_host: "https://api.vend-x.com",
    public_bucket: "https://app-data.vend-x.com",
    template_url: "https://static.vend-x.com",
    task_service_host: "https://task-scheduler.vend-x.com",
  },
  "static.vend-x.com": {
    parent_host: "https://vend-x.com",
    api_host: "https://api.vend-x.com",
    public_bucket: "https://app-data.vend-x.com",
    task_service_host: "https://task-scheduler.vend-x.com"
  },
  "vendor.vend-x.com": {
    parent_host: "https://vend-x.com",
    api_host: "https://api.vend-x.com",
    public_bucket: "https://app-data.vend-x.com",
  },

  /* for ej-buy.com urls */
  "ej-buy.com": {
    parent_host: "https://ej-buy.com",
    api_host: "https://ejbuyapi.ej-buy.com",
    public_bucket: "https://app-data.ej-buy.com",
    template_url: "https://static.ej-buy.com",
    task_service_host: "https://task-scheduler.vend-x.com",
  },
  "static.ej-buy.com": {
    parent_host: "https://ej-buy.com",
    api_host: "https://ejbuyapi.ej-buy.com",
    public_bucket: "https://app-data.ej-buy.com",
    task_service_host: "https://task-scheduler.vend-x.com",
  },
  "vendor.ej-buy.com": {
    parent_host: "https://ej-buy.com",
    api_host: "https://ejbuyapi.ej-buy.com",
    public_bucket: "https://app-data.ej-buy.com",
  },

  /* for ultratechcement.com urls */
  "static-utcl.vend-x.com": {
    parent_host: "https://ultratechcement.vend-x.com",
    api_host: "https://utclapi.vend-x.com",
    public_bucket: "https://app-data-ultratechcement.vend-x.com",
    task_service_host: "https://task-scheduler.vend-x.com",
  },
  "ultratechcement.vend-x.com": {
    parent_host: "https://ultratechcement.vend-x.com",
    api_host: "https://utclapi.vend-x.com",
    public_bucket: "https://app-data-ultratechcement.vend-x.com",
    template_url: "https://static-utcl.vend-x.com",
    task_service_host: "https://task-scheduler.vend-x.com",
  },
  "vendor-ultratechcement.vend-x.com": {
    parent_host: "https://ultratechcement.vend-x.com",
    api_host: "https://utclapi.vend-x.com",
    public_bucket: "https://app-data-ultratechcement.vend-x.com",
  },

  /* for  testing.vend-x.com url */
  "testing.vend-x.com": {
    parent_host: "https://testing.vend-x.com",
    api_host: "https://testing-vendx2.vend-x.com",
    public_bucket: "https://app-data-testing.vend-x.com",
  },
  "vendor-testing.vend-x.com": {
    parent_host: "https://testing.vend-x.com",
    api_host: "https://testing-vendx2.vend-x.com",
    public_bucket: "https://app-data-testing.vend-x.com",
  },
  "vendor-testing-static.vend-x.com": {
    parent_host: "https://testing.vend-x.com",
    api_host: "https://testing-api.vend-x.com",
    public_bucket: "https://app-data-testing.vend-x.com",
  },

  /* For QA URLs */
  "testing171.vend-x.com": {
    parent_host: "https://testing171.vend-x.com",
    api_host: "https://testing172-vendx2.vend-x.com",
    public_bucket: "https://local-app-data.vend-x.com/testing171",
  },
  "testing172.vend-x.com": {
    parent_host: "https://testing172.vend-x.com",
    api_host: "https://testing172-vendx2.vend-x.com",
    public_bucket: "https://local-app-data.vend-x.com/testing172",
  },
  "testing173.vend-x.com": {
    parent_host: "https://testing173.vend-x.com",
    api_host: "https://testing172-vendx2.vend-x.com",
    public_bucket: "https://local-app-data.vend-x.com/testing173",
  },
  "testing174.vend-x.com": {
    parent_host: "https://testing174.vend-x.com",
    api_host: "https://testing172-vendx2.vend-x.com",
    public_bucket: "https://local-app-data.vend-x.com/testing174",
  },
  "testing179.vend-x.com": {
    parent_host: "https://testing179.vend-x.com",
    api_host: "https://testing172-vendx2.vend-x.com",
    public_bucket: "https://local-app-data.vend-x.com/testing179",
  },
  "testing181.vend-x.com": {
    parent_host: "https://testing181.vend-x.com",
    api_host: "https://testing172-vendx2.vend-x.com",
    public_bucket: "https://local-app-data.vend-x.com/testing181",
  },
  "testing182.vend-x.com": {
    parent_host: "https://testing182.vend-x.com",
    // api_host: "https://testing172-vendx2.vend-x.com",
    api_host: "https://alpha.vend-x.com",
    public_bucket: "https://local-app-data.vend-x.com/testing182",
  },
  "testing-intas2021.vend-x.com": {
    parent_host: "https://testing-intas2021.vend-x.com",
    // api_host: "https://testing172-vendx2.vend-x.com",
    api_host: "https://alpha-intas2021.vend-x.com",
    public_bucket: "https://local-app-data.vend-x.com/testing182",
  },
  "static-intas2021.vend-x.com": {
    parent_host: "https://testing-intas2021.vend-x.com",
    // api_host: "https://testing172-vendx2.vend-x.com",
    api_host: "https://alpha-intas2021.vend-x.com",
    public_bucket: "https://local-app-data.vend-x.com/testing182",
  },
  "testing185.vend-x.com": {
    parent_host: "https://testing185.vend-x.com",
    api_host: "https://testing185-vendx2.vend-x.com",
    task_service_host: "https://testing-task-scheduler.vend-x.com",
    public_bucket: "https://local-app-data.vend-x.com/testing185",
  },
  "testing188.vend-x.com": {
    parent_host: "https://testing188.vend-x.com",
    api_host: "https://testing172-vendx2.vend-x.com",
    public_bucket: "https://local-app-data.vend-x.com/testing188",
  },
  "testing189.vend-x.com": {
    parent_host: "https://testing189.vend-x.com",
    api_host: "https://testing172-vendx2.vend-x.com",
    public_bucket: "https://local-app-data.vend-x.com/testing189",
  },
  "testing190.vend-x.com": {
    parent_host: "https://testing190.vend-x.com",
    api_host: "https://testing172-vendx2.vend-x.com",
    public_bucket: "https://local-app-data.vend-x.com/testing190",
  },

  "testing-static.vend-x.com": {
    parent_host: "https://testing182.vend-x.com",
    api_host: "https://alpha.vend-x.com",
    public_bucket: "https://local-app-data.vend-x.com/testing182",
  },

  "testing187.vend-x.com": {
    parent_host: "https://testing187.vend-x.com",
    api_host: "https://testing187-vendx2.vend-x.com",
    public_bucket: "https://local-app-data.vend-x.com/testing187",
  },
  "testing187-static.vend-x.com": {
    parent_host: "https://testing187.vend-x.com",
    api_host: "https://testing187-vendx2.vend-x.com",
    public_bucket: "https://local-app-data.vend-x.com/testing187",
  },
  "vendor-testing187.vend-x.com": {
    parent_host: "https://testing187.vend-x.com",
    api_host: "https://testing187-vendx2.vend-x.com",
    public_bucket: "https://local-app-data.vend-x.com/testing187",
  },

  /* Development URLs */
  "rohit.vendx.com": {
    parent_host: "http://rohit.vendx.com",
    api_host: "http://192.168.1.51:1051", // Common API URL
    public_bucket: "https://local-app-data.vend-x.com/rohit",
  },
  "192.168.1.203:3203": {
    parent_host: "http://192.168.1.203",
    api_host: "http://192.168.1.203:5203", // Common API URL
    public_bucket: "https://local-app-data.vend-x.com/192.168.1.203",
  },
  "192.168.1.203": {
    parent_host: "http://192.168.1.203",
    api_host: "http://192.168.1.203:5203", // Common API URL
    public_bucket: "https://local-app-data.vend-x.com/192.168.1.203",
  },
  "172.16.0.217:3163": {
    parent_host: "http://192.168.1.249",
    // parent_host: "https://testing189.vend-x.com",

    // api_host: "http://192.168.1.161:8888",
    // api_host: "https://testing-vendx2.vend-x.com",
    // api_host: "https://testing185-vendx2.vend-x.com",
    // api_host: "http://192.168.1.224:8020",

    api_host: "http://192.168.1.161:3164",
    task_service_host: "http://192.168.1.161:3163",
    // public_bucket: "https://local-app-data.vend-x.com/192.168.1.161",
    public_bucket: "https://local-app-data.vend-x.com/testing181",
  },
  "192.168.1.161": {
    parent_host: "http://192.168.1.249",
    // parent_host: "https://testing189.vend-x.com",

    // api_host: "http://192.168.1.161:8888",
    // api_host: "https://testing185-vendx2.vend-x.com",
    api_host: "http://192.168.1.224:8020",
    // api_host: "http://192.168.1.161:3164",
    public_bucket: "https://local-app-data.vend-x.com/192.168.1.161",
  },
};
