import React, { Component } from "react";
import { setSessionId } from "../redux/actions/sessionAction";
import { connect } from "react-redux";
import { setUserInfo } from "../redux/actions/setUserInfo";
import { setSelectedCategory } from "../redux/actions/categoryAction";
import { Backdrop, CircularProgress } from "@mui/material";
import { openSnackbar } from "../redux/actions/snackbarAction";
import NewFields from "../components/NewFields/NewFields";
import {
  getCompanyInfo,
  getInquiryDetails,
  getUserInfo,
} from "../config/apiUrl";
import axios from "axios";
export class SendToNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionID: this.props.sessionID || "",
      loading: false,
      userInfoLoading: false,
      vendorType: this.props.vendorType || "",
      inquiryDetails: null,
      companyInfo: {},
      division_id: {},
    };
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(this?.props?.location?.search);
    let sessionID = queryParams.get("q") || this.props.sessionID;
    let inquiry_id = queryParams.get("inquiry_id") || this.props.inquiry_id;
    let vendorType = queryParams.get("form_section") || this.props.vendorType;
    this.setState({
      vendorType,
      sessionID,
    });

    if (inquiry_id) {
      this.getUserAndCompanyInfo(sessionID);

      this.setState({
        loading: true,
      });

      axios.defaults.headers.common["Authorization"] = `Bearer ${sessionID}`;
      axios
        .get(getInquiryDetails(inquiry_id))
        .then((response) => {
          this.setState({
            loading: false,
            inquiryDetails: response.data.data,
          });
        })
        .catch((err) => {
          this.props.openSnackbar(
            "error",
            err.response?.data?.message
              ? err.response?.data?.message
              : "Provided Inquiry id is not valid"
          );
          this.setState({
            loading: false,
          });
        });
    } else {
      this.props.openSnackbar("error", "Provided Inquiry id is not valid");
    }
  }

  getUserAndCompanyInfo = (sessionID) => {
    this.setState({
      userInfoLoading: true,
    });
    axios.defaults.headers.common["Authorization"] = `Bearer ${sessionID}`;
    const url = getUserInfo;
    axios
      .get(url)
      .then((response) => {
        this.setState({
          division_id: response.data.data.division_id,
        });

        axios
          .get(getCompanyInfo(response.data.data.company_id))
          .then((res) => {
            this.setState({
              companyInfo: res.data.data,
              userInfoLoading: false,
            });
          })
          .catch((err) => {
            this.setState({
              userInfoLoading: false,
            });
            this.props.openSnackbar(
              "error",
              err.response?.data?.message
                ? err.response?.data?.message
                : "Something went wrong!"
            );
          });
      })
      .catch((err) => {
        this.setState({
          userInfoLoading: false,
        });
        this.props.openSnackbar(
          "error",
          err.response?.data?.message
            ? err.response?.data?.message
            : "Something went wrong!"
        );
      });
  };
  render() {
    return (
      <>
        <Backdrop
          style={{ color: "#fff", zIndex: "10000000000" }}
          open={this.state.loading || this.state.userInfoLoading}
        >:
          <Backdrop
            style={{ color: "#fff", zIndex: "10000000000" }}
            open={this.state.loading || this.state.userInfoLoading}
          ></Backdrop>
          <CircularProgress style={{ color: "#fff" }} />
        </Backdrop>

        {!this.state.loading && !this.state.userInfoLoading && this.state.inquiryDetails && (
          <NewFields
            vendorType={this.state.vendorType}
            sessionID={this.state.sessionID}
            inquiryDetails={this.state.inquiryDetails}
            division_id={this.state.division_id}
            companyInfo={this.state.companyInfo}
            closeDrawer={this?.props?.closeDrawer || (() => { })}
            handleSendRfqNewVendorsCategory={this?.props?.handleSendRfqNewVendorsCategory || (() => { })}
            fromWebpack={this?.props?.fromWebpack}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  userInfo: state.userInfoReducer.userInfo,
});

export default connect(mapStateToProps, {
  setSessionId,
  setUserInfo,
  setSelectedCategory,
  openSnackbar,
})(SendToNew);
