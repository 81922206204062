import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import ViewListGrid from "./ViewListGrid";
import CloseIcon from "@mui/icons-material/Close";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import EditNoteIcon from '@mui/icons-material/EditNote';
import CreateAutoRFQ from "./CreateAutoRFQ";
import { useDispatch, useSelector } from "react-redux";
import { autoRFQTaskById } from "../../config/apiUrl";
import { openSnackbarfunc } from "../../redux/actions/snackbarAction";
import axios from "axios";

export default function AutoRFQDialog({ AutoRFQOpen, AutoRFQClose, sessionId, activeStep, setActiveStep, isCreateRFQActive, setIsCreateRFQActive, taskData, setTaskData, initializeTaskData, editTask, setEditTask, autoRfqPermissionUsers}) {
  const steps = [
    "Set PR Rules",
    "Set RFQ Rules",
    "Set Template",
    // "Set RFQ Schedule",
  ];
  
  const Permisison = useSelector((state) => state.permissionsReducer)
  // const userInfo = useSelector((state) => state.userInfoReducer.userInfo);
  // const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch();

  const handleNext = () => {
    if (validateFields()) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  
  const [submit, setSubmit] = useState(false);
  const [loading, setIsLoading] = useState(true);

  const CreateAlertClick = (id) => {
    if (Number.isInteger(Number(id))) {
      getTaskById(id);
      setEditTask(true);
    }else{
      setIsCreateRFQActive(true);
    }
    
  };

  const handleSubmit = () => {
    setSubmit(!submit)
  };
  
  
  const getTaskById = async (id) => {
    
    setIsLoading(false);
    try {

      const url = autoRFQTaskById(id);
      const response = await axios.get(url, {
      });
      
      setTaskData((prev) => ({
        ...prev,
        ...response.data, 
      }));
      setIsCreateRFQActive(true);
      
    } catch (err) {
      console.error(err);
      dispatch(
        openSnackbarfunc(
          "error", 
          "An error occurred while fetching tasks"
        )
      );

      setIsLoading(false);
      return false;
    }
  };
  
  
  const handleTaskDataChange = (path, value) => {
    setTaskData((prevState) => {
      const newState = { ...prevState };
      let current = newState;
      const keys = path.split(".");
  
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          // Update the final key with the new value
          current[key] = value;
        } else {
          // Ensure intermediate objects exist before navigating further
          if (!current[key] || typeof current[key] !== "object") {
            current[key] = {};
          }
          current = current[key];
        }
      });
  
      return newState;
    });
  };
  
  const [errors, setErrors] = useState({});
  const validateFields = () => {

    const newErrors = {};
    const value = taskData['name'];
    if (!value || (typeof value === "string" && value.trim() === "")) {
      newErrors['name'] = "This field is required";
    }

    setErrors(newErrors);

    // Filter the rules to create finalRuleArray
    const finalRuleArray = taskData?.rules.filter(item => {
      const value = item.value;
      return (Array.isArray(value) && value.length > 0) || 
            (typeof value === 'number' && !isNaN(value)) || 
            (typeof value === 'string' && value.trim() !== '');
    });

    if (finalRuleArray.length === 0 && Object.keys(newErrors).length === 0) {
      dispatch(
        openSnackbarfunc(
          "error", 
          "Please select any one Purchase Group, Purchase ORG, Category, Destination or PR value"
        )
      );
    }
    
    let isPrValueRuleValid = validateMinMaxValueRule(finalRuleArray);

    if(finalRuleArray.length !== 0 && isPrValueRuleValid){
      handleTaskDataChange("rules", finalRuleArray);
    }
    
    //validation for Second Set RFQ Rules 
    if(activeStep === 1){ //Set RFQ Rules
        
        if(Permisison?.featurePermission?.project_selection?.feature_value == "Y"){
          dispatch(
            openSnackbarfunc(
              "error", 
              "Please Select Project"
            )
          );
          return false;
        } 
    }

    return Object.keys(newErrors).length === 0 && 
            finalRuleArray.length !== 0 && 
            isPrValueRuleValid; // Returns true if no errors
  };

  const validateMinMaxValueRule = (finalRuleArray) => {
    const budgetRules = finalRuleArray.filter(rule => rule.attribute_id === "budget_value") || [];
  
    const minValue = budgetRules.find(rule => rule.condition === "greater_than_equal_to")?.value ?? "";
    const maxValue = budgetRules.find(rule => rule.condition === "less_than_equal_to")?.value ?? "";
  
   // If both values are empty, skip validation
  if (minValue === "" && maxValue === "") return true;

    // Ensure both values are provided
    if (minValue === "") {
      dispatch(openSnackbarfunc("error", "Please provide Min PR value."));
      return false;
    }

    if (maxValue === "") {
      dispatch(openSnackbarfunc("error", "Please provide Max PR value."));
      return false;
    }
    const min = parseFloat(minValue);
    const max = parseFloat(maxValue);
  
    if (isNaN(min) || isNaN(max)) {
      dispatch(openSnackbarfunc("error", "Invalid numeric values provided."));
      return false;
    }
  
    if (min > max) {
      dispatch(openSnackbarfunc("error", "Min PR value cannot be greater than Max PR value."));
      return false;
    }
  
    return true;
  };
  
  
  useEffect(()=>{console.log('taskData',taskData)},[taskData]);
  return (
    <Dialog fullScreen open={AutoRFQOpen} onClose={AutoRFQClose}>
      <DialogTitle
        component="div"
        sx={{
          "&.MuiTypography-root": {
            padding: "14px 16px",
          },
        }}
      >
        {isCreateRFQActive ? (
          editTask ? (
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <EditNoteIcon />
              <Typography variant="subtitle1" fontWeight={500} color="grey.900" component="h6">
                Edit Auto RFQ
              </Typography>
            </Box>
          ) : (            
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <EditNoteIcon />
              <Typography variant="subtitle1" fontWeight={500} color="grey.900" component="h6">
                Create Auto RFQ
              </Typography>
            </Box>
          )
        ) : (
          <Typography
            variant="subtitle1"
            fontWeight={500}
            color={"grey.900"}
            component="h6"
          >
            Auto RFQ
          </Typography>
        )}
        <Tooltip title="Close">
          <IconButton
            aria-label="close"
            onClick={()=> AutoRFQClose(isCreateRFQActive)}
            sx={{ position: "absolute", right: 16, top: 12 }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }} dividers>
        {isCreateRFQActive ? (
          <CreateAutoRFQ 
            activeStep={activeStep} 
            steps={steps} 
            editTask={editTask}
            taskData={taskData}
            handleTaskDataChange={handleTaskDataChange}
            errors={errors}
            setErrors={setErrors}
            submit={submit}
            sessionId={sessionId}
            setIsCreateRFQActive={setIsCreateRFQActive}
            setActiveStep={setActiveStep}
            autoRfqPermissionUsers={autoRfqPermissionUsers}

          />
        ) : (
          <ViewListGrid 
            CreateAlertClick={CreateAlertClick} 
            activeStep={activeStep} 
            steps={steps}
            sessionId={sessionId}
            loading={loading}
            setIsLoading={setIsLoading}
          />
        )}
      </DialogContent>
      {isCreateRFQActive && (
        <DialogActions>
          <Box
            className={`StepperAction ${
              activeStep === steps.length - 1 ? "StepperAction-Last" : ""
            }`}
            display={"flex"}
            gap={1}
          >
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="contained"
              startIcon={<ArrowLeftIcon />}
            >
              Previous
            </Button>
            {activeStep === steps.length - 1 ? (
              editTask ? (
                <Button variant="contained" onClick={handleSubmit}>
                  Save
                </Button>
              ) : (
                 <Button variant="contained" onClick={handleSubmit}>
                  Submit
                </Button>
              )
            ) : (
              <Button
                variant="contained"
                endIcon={<ArrowRightIcon />}
                onClick={handleNext}
              >
                Next
              </Button>
            )}
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
}
