import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Switch,
  Tooltip,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../../redux/actions/setUserInfo";
import axios from "axios";
import {
  account_all_users,
  autoRfqLogs,
  autorfqLogsById,
  dropdown_detail,
  getCategories,
  pr_dropdown_mapping,
} from "../../config/apiUrl";
import { openSnackbarfunc } from "../../redux/actions/snackbarAction";
import CommonDataGridPro from "../RFQList/commonDataGridPro/commonDataGridPro";
import { setDropDownData } from "../../redux/actions/dropdownAction";
import moment from "moment";
import PRLogsStatusDrawer from "../PRLogsStatusDrawer/PRStatusLogsDrawer";

function CustomGridToolbar() {
  return (
    <>
      {/* Start Desktop View Code */}
      <GridToolbarContainer className="datagrid-toolbar">
        <Grid
          container
          spacing={0}
          sx={{
            width: "100%",
            // pt: 1,
            "&.MuiGrid2-root": {
              paddingTop: { xs: "0", sm: "0", md: "0", alignItems: "center" },
            },
          }}
        >
          <Grid xs={12} sm={4} md={4} xl={4}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <GridToolbarQuickFilter
                className="DataGrid-SearchBar"
                sx={{ pb: 0 }}
              />
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={8}
            md={8}
            xl={8}
            sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}
          >
            <Box
              className="toolbar-right"
              sx={{
                "&.toolbar-right": {
                  marginLeft: "auto",
                },
              }}
            >
              <Box className="dg-filter">
                <GridToolbarColumnsButton />
                <GridToolbarExport
                  printOptions={{ disableToolbarButton: true }}
                />
                <GridToolbarFilterButton />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </GridToolbarContainer>
      {/* Start Desktop View Code */}

      {/* Start Mobile View Code */}
      <GridToolbarContainer
        className="datagrid-toolbar"
        sx={{ display: { xs: "flex", sm: "none" } }}
      >
        <Grid
          container
          spacing={0}
          sx={{ width: "100%", alignItems: "center" }}
        >
          <Grid
            xs={12}
            sm={12}
            md={12}
            xl={12}
            // sx={{ textAlign: { xs: "right", sm: "right" } }}
          >
            <Box className="dg-filter">
              <GridToolbarColumnsButton />
              <GridToolbarExport />
              <GridToolbarFilterButton />
            </Box>
          </Grid>
        </Grid>
      </GridToolbarContainer>
      {/* End Mobile View Code */}
    </>
  );
}

export default function AutoRFQLogsDialog(props) {
  const dispatch = useDispatch();
  const [AutoRFQLogsOpen, setAutoRFQLogsOpen] = useState(false);
  const [AccountUsers, setAccountUsers] = useState({});
  const [loading, setIsLoading] = useState(true);
  const [logsRows, setLogsRows] = useState([]);
  const [Category, setCategory] = useState({});
  const [prLogs, setPrLogs] = useState({});
  const userInfo = useSelector((state) => state.userInfoReducer.userInfo);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const companyInfo = useSelector(
    (state) => state.companyInfoReducer.companyInfo
  );
  const dropdownData = useSelector(
    (state) => state.dropdownReducer.dropdownData
  );

  const fetchDropDownMappingData = async () => {
    const mapping_url = pr_dropdown_mapping(
      userInfo?.division_id,
      userInfo?.company_id
    );

    try {
      let mappingResponse = await fetch(mapping_url);

      const mappingData = await mappingResponse.json();

      const { plant, pgr, porg } = mappingData;

      Object.keys(mappingData).forEach(function (key) {
        if (key === "plant" || key === "pgr" || key === "porg") {
          fetchDropDownDetails(mappingData[key], key);
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDropDownDetails = async (id, type, action) => {
    let dropdown_detail_url = dropdown_detail(
      userInfo?.division_id,
      userInfo?.company_id,
      id
    );

    try {
      const response = await fetch(dropdown_detail_url);

      if (!response.ok) {
        throw new Error(`Failed to fetch ${type} data`);
      }

      const data = await response.json();

      dispatch(setDropDownData({ [type]: data }));
    } catch (error) {
      console.error(`Error fetching ${type} data:`, error);
    }
  };

  const getFormattedValues = (rules, attributeId) => {
    if (!rules || !Array.isArray(rules))
      return { displayText: "N/A", tooltipText: "N/A" };

    const ruleValues = rules
      .filter((rule) => rule.attribute_id === attributeId)
      .flatMap((rule) => rule.value);

    if (ruleValues.length === 0)
      return { displayText: "N/A", tooltipText: "N/A" };

    let displayNameArray = [];

    const attributeMap = {
      purchase_groups: "pgr",
      purchase_organizations: "porg",
    };

    const key = attributeMap[attributeId];

    if (key) {
      displayNameArray = ruleValues.map(
        (element) => dropdownData?.[key]?.[element]?.Dropdown_Element_Name
      );
    } else if (attributeId === "categories") {
      displayNameArray = ruleValues.map((element) => Category[element]);
    }

    const displayValue = displayNameArray[0];
    const extraCount = displayNameArray.length - 1;

    if (extraCount > 0) {
      return {
        displayText: (
          <PopoverList
            displayValue={displayValue}
            extraCount={extraCount}
            displayNameArray={displayNameArray}
          />
        ),
        exportValue : displayNameArray.join(", "), 
        
      };
    }
  
    return {
      displayText: displayValue,
      tooltipText: displayNameArray.join(", "),
      exportValue : displayNameArray.join(", "),
    };

    // return {
    //   displayText:
    //     extraCount > 0 ? `${displayValue} +${extraCount}` : displayValue,
    //   tooltipText: displayNameArray.join(", "),
    // };
  };

  const PopoverList = ({ displayValue, extraCount, displayNameArray }) => {
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
  
    return (
      <Box display={"flex"} alignItems={"center"} gap={"8px"} height={40}>
        <Typography
          variant="body2"
          component="a"
          color={"primary.500"}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={handleClick}
        >
          {displayValue} +{extraCount}
        </Typography>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                overflow: "visible",
                border: "1px solid var(--bluegrey-500)",
                mt: 1.5,
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: -1,
                  right: 10,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                  borderTop: "1px solid var(--bluegrey-500)",
                  borderLeft: "1px solid var(--bluegrey-500)",
                },
              },
            },
          }}
        >
          <List dense>
            {displayNameArray.map((name, index) => (
              <ListItem key={index}>
                <ListItemText primary={name} />
              </ListItem>
            ))}
          </List>
        </Popover>
      </Box>
    );
  };


  const queryParams = new URLSearchParams(props?.location?.search);
  let sessionId = queryParams.get("q");

  useEffect(() => {
    if (sessionId) {
      setAutoRFQLogsOpen(true);
      fetchAllUsersDetails();
      fetchCategory();
      dispatch(setUserInfo(sessionId));
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      if (userInfo.length !== 0) {
        fetchDropDownMappingData();
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (
      companyInfo?.display_name &&
      !Object.values(dropdownData).some(
        (value) => typeof value === "object" && Object.keys(value).length === 0
      )
    ) {
      fetchLogs();
    }
  }, [companyInfo, sessionId, dropdownData]);

  const fetchCategory = async () => {
    try {
      axios.defaults.headers["Authorization"] = `Bearer ${sessionId}`;

      const url = `${getCategories}?pagination=false`;
      const response = await axios.get(url);
      const categoryMap = {};
      response?.data?.data.forEach((category) => {
        categoryMap[category.id] = category.name;
      });
      setCategory(categoryMap);
    } catch (err) {
      console.error(err);
      dispatch(
        openSnackbarfunc("error", "An error occurred while fetching tasks")
      );
    }
  };

  const fetchLogs = async () => {
    try {
      // setIsLoading(true);

      axios.defaults.headers.common["x-public-key"] = sessionId;
      axios.defaults.headers.common["x-private-key"] = "task_service_key";

      const url = autoRfqLogs(
        `${companyInfo?.display_name}?client_account_id=${userInfo?.division_id}`
      );
      const response = await axios.get(url);

      setLogsRows(response.data);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      dispatch(
        openSnackbarfunc("error", "An error occurred while fetching tasks")
      );

      setIsLoading(false);
    }
  };

  const fetchAllUsersDetails = async () => {
    try {
      const userResponse = await axios.get(account_all_users, {
        headers: { Authorization: `Bearer ${sessionId}` },
      });
      if (userResponse.status === 200) {
        const accountUserData = userResponse.data.users;
        const userIdMap = {};
        accountUserData.forEach((user) => {
          userIdMap[user.UserID] = user.UserName;
        });

        setAccountUsers(userIdMap);
      } else {
        throw new Error("Network response for user data was not ok");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "NAME",
      width: 150,
      valueGetter: (params) => params.row?.task?.name,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value} placement="bottom-start">
            <Typography variant="body" noWrap>
              {params.value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "execute_at",
      headerName: "EXECUTION TIME",
      width: 160,
      valueFormatter: (params) => {
        return moment(params.value, "YYYY-MM-DD HH:mm:ss").format(
          "DD/MM/YYYY hh:mm:ss"
        );
      },
    },
    {
      field: "execute_as",
      headerName: "USER NAME",
      width: 120,
      valueGetter: (params) => AccountUsers[params.row?.execute_as] || "N/A",
      renderCell: (params) => (
        <Tooltip title={params.value || "N/A"} placement="bottom-start">
          <Typography variant="body" noWrap>
            {params.value || "N/A"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "purchaseGroup",
      headerName: "PURCHASE GROUP",
      width: 180,
      valueGetter: (params) => params.row._tooltipPurchaseGrp,
      renderCell: (params) => {
        const { displayText, tooltipText, exportValue } = getFormattedValues(
          params.row?.task.rules,
          "purchase_groups"
        );
        params.row._tooltipPurchaseGrp = exportValue;
        return (
          <Tooltip title={tooltipText} arrow>
            <Typography variant="body2" noWrap>
              {displayText}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "purchaseOrg",
      headerName: "PURCHASE ORGANIZATION",
      width: 220,
      valueGetter: (params) => params.row._tooltipPurchaseOrg,

      renderCell: (params) => {
        const { displayText, tooltipText, exportValue } = getFormattedValues(
          params.row?.task?.rules,
          "purchase_organizations"
        );
        params.row._tooltipPurchaseOrg = exportValue;

        return (
          <Tooltip title={tooltipText} arrow>
            <Typography variant="body2" noWrap>
              {displayText}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "category",
      headerName: "CATEGORY",
      width: 220,
      valueGetter: (params) => params.row._tooltipCategory,
      renderCell: (params) => {
        const { displayText, tooltipText, exportValue } = getFormattedValues(
          params.row?.task?.rules,
          "categories"
        );
        params.row._tooltipCategory = exportValue;

        return (
          <Tooltip title={tooltipText} arrow>
            <Typography variant="body2" noWrap>
              {displayText}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "prLogStatus",
      headerName: "PR LOG STATUS",
      width: 220,
      valueGetter: (params) =>
        `Success: ${params.row?.successed_count}, Failure: ${params.row?.failed_count}`,
      renderCell: (params) => {
        return (
          <Link
            component="button"
            variant="body2"
            color="text.primary"
            onClick={(e) =>
              (params.row?.successed_count != 0 ||
                params.row?.failed_count != 0) &&
              handlePRLogsStatusDrawer(e, params.row.id)
            }
          >
            {params.value}
          </Link>
        );
      },
    },
  ];

  // Start PR Logs Status Drawer script
  const [openPRLogsStatusDrawer, setOpenPRLogsStatusDrawer] = useState(false);

  const ClosePRLogsStatusDrawer = () => {
    setOpenPRLogsStatusDrawer(false);
    setPrLogs({});
  };

  const handlePRLogsStatusDrawer = (event, id) => {
    event.stopPropagation();
    setOpenPRLogsStatusDrawer(true);
    fetchExecutionLogsById(id);
  };

  const fetchExecutionLogsById = async (id) => {
    try {
      axios.defaults.headers.common["x-public-key"] = sessionId;
      axios.defaults.headers.common["x-private-key"] = "task_service_key";

      const url = autorfqLogsById(id);
      const response = await axios.get(url);
      setPrLogs(response.data);
      console.log("response", response);
    } catch (err) {
      console.error(err);
      dispatch(
        openSnackbarfunc("error", "An error occurred while fetching tasks")
      );
    }
  };

  // End PR Logs Status Drawer script
  return (
    <>
      <Dialog
        fullScreen
        open={AutoRFQLogsOpen}
        onClose={() => {
          setAutoRFQLogsOpen(false);
          window.parent.postMessage(
            { type: "AUTO_RFQ_CLOSE_ACTION", message: "Closing from React" },
            "*"
          );
        }}
        sx={{ zIndex: 1199 }}
      >
        <DialogTitle
          component="div"
          sx={{
            ".MuiDialog-root &.MuiTypography-root": {
              padding: "14px 16px",
            },
          }}
        >
          <Typography
            variant="subtitle1"
            fontWeight={500}
            color={"grey.900"}
            component="h6"
          >
            Auto RFQ Logs
          </Typography>
          <Tooltip title="Close">
            <IconButton
              aria-label="close"
              onClick={() => {
                setAutoRFQLogsOpen(false);
                window.parent.postMessage(
                  {
                    type: "AUTO_RFQ_CLOSE_ACTION",
                    message: "Closing from React",
                  },
                  "*"
                );
              }}
              sx={{ position: "absolute", right: 16, top: 12 }}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }} dividers>
          <div
            className="AutoRFQGrid"
            style={{
              height: "calc(100vh - 60px)",
            }}
          >
            <CommonDataGridPro
              loading={loading}
              rows={logsRows}
              columns={columns}
              className="fullScreenGrid"
              slots={{
                toolbar: CustomGridToolbar,
              }}
              headerFilters
              pagination={true}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              Toolbar={CustomGridToolbar}
              columnHeaderHeight={40}
              headerFilterHeight={40}
              rowHeight={36}
              sx={{
                border: "none",
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
      <PRLogsStatusDrawer
        openPRLogsStatusDrawer={openPRLogsStatusDrawer}
        ClosePRLogsStatusDrawer={ClosePRLogsStatusDrawer}
        prLogs={prLogs}
        sessionId={sessionId}
      />
    </>
  );
}
