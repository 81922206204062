import React, { useState, useEffect } from "react";
import {
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Checkbox,
  ListItem,
  Typography,
  Box,
  Skeleton,
} from "@mui/material";
import _ from "lodash";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";

import GroupIcon from "@mui/icons-material/GroupOutlined";
import { LinkVendorError, EditNote, PrIcon } from "../SvgIcon/SvgIcon";

import WebAssetOffIcon from "@mui/icons-material/WebAssetOffOutlined";
import WebAssetIcon from "@mui/icons-material/WebAssetOutlined";
import {
  getItemLinkedTechnicalBidTemplateDetails,
  getItemTBDefaultValues,
  getItemTBPreviousValues,
  getPRLinkedTechnicalBidTemplateDetails,
} from "../../config/apiUrl";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setCheckValidTechnicalBidItems } from "../../redux/actions/itemActions";
import { setPrDialog, setPrItems } from "../../redux/actions/prrfqactions";
import LinkUnlinkTemplateItemDrawer from "../LinkUnlinkTemplateForItem/LinkUnlinkTemplateItemDrawer";
const CartItem = ({
  item,
  onClick,
  checked,
  itemizeTB,
  handleAction,
  viewVendors,
  itemizeRFQ,
  isIncludedTB,
  addItemTB,
  removeItemTB,
  defaultTBValuePermission,
  isUpdated,
  setIsUpdated,
  tbStage,
  search_disable,
  includeTB,
  disabledtechnicalbid,
  TBTemplateValueIntegration,
  pr_has_tb,
  selectedCategory,
  sessionId
}) => {
  const [hover, setHover] = useState(false);
  const [tbDetails, setTbDetails] = useState({});
  const [previousTB, setPreviousTB] = useState({});
  const [defaultValue, setDefaultValue] = useState(false);
  const [singleLoad, setSingleLoad] = useState(false);
  const [openLinkUnlinkItemDrawer, setOpenLinkUnlinkItemDrawer] =
    useState(false);

  const dispatch = useDispatch();

  // const [isUpdated, setIsUpdated] = useState(false);
  const { userInfo } = useSelector((reducer) => reducer.userInfoReducer);
  const { masterPermission } = useSelector(
    (reducer) => reducer.permissionsReducer
  );
  const [loadedFirstTime, setLoadedFirstTime] = useState(true);
  useEffect(() => {
    if (isIncludedTB && itemizeTB && !disabledtechnicalbid) {
      fetchItemTBTemplateDetails();
    }
    if (disabledtechnicalbid && isIncludedTB && itemizeTB) {
      fetchPRTBTemplateDetails();
    }
  }, [itemizeTB, isUpdated, isIncludedTB, disabledtechnicalbid]);

  const handleLinkUnlinkItemDrawer = (event) => {
    setOpenLinkUnlinkItemDrawer(true);
    // handleClose();
  };

  const CloseLinkUnlinkItemDrawer = () => {
    setIsUpdated(false);
    setOpenLinkUnlinkItemDrawer(false);
  };

  const fetchPRTBTemplateDetails = () => {
    let url = getPRLinkedTechnicalBidTemplateDetails(item.prlineitems_ids[0]);
    axios
      .get(url)
      .then((res) => {
        setTbDetails(res.data.data);
        setDefaultValue(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchItemTBTemplateDetails = () => {
    // if(!loadedFirstTime){
    setSingleLoad(true);
    // }
    let url = getItemLinkedTechnicalBidTemplateDetails(item.id);
    axios
      .get(url)
      .then((res) => {
        setTbDetails(res.data.data);
        // add permission based api selection here
        if (loadedFirstTime) {
          setLoadedFirstTime(false);
        }
        if (defaultTBValuePermission) {
          fetchItemTBTemplateDefaultValue(res.data.data.id);
        } else {
          fetchItemTBTemplatePreviousValue(res.data.data.id);
        }
      })
      .catch((err) => {
        setIsUpdated(true);
        setTbDetails({});
        setSingleLoad(false);
      });
  };

  const fetchItemTBTemplatePreviousValue = (template_id) => {
    // if(!loadedFirstTime){
    // setSingleLoad(true);
    // }
    let url = getItemTBPreviousValues(item.id, template_id);
    axios
      .get(url)
      .then((res) => {
        if (loadedFirstTime) {
          setLoadedFirstTime(false);
        }
        setIsUpdated(true);
        setPreviousTB(res.data.data);
        setSingleLoad(false);
      })
      .catch((err) => {
        setIsUpdated(true);
        setPreviousTB({});
        setSingleLoad(false);
        // console.log(err, "fetchItemTBTemplatePreviousValue err");
      });
  };

  const prDialogOpen = (event, id) => {
    dispatch(setPrDialog(true));
    dispatch(setPrItems(item));
  };

  const fetchItemTBTemplateDefaultValue = (template_id) => {
    let url = getItemTBDefaultValues(item.id, template_id);
    axios
      .head(url)
      .then((res) => {
        if (parseInt(res.status) === 200) {
          setDefaultValue(true);
        } else {
          setDefaultValue(false);
        }

        setIsUpdated(true);
        setSingleLoad(false);
      })
      .catch((err) => {
        setDefaultValue(false);
        setIsUpdated(true);
        setSingleLoad(false);
        // console.log(err, "fetchItemTBTemplateDefaultValue err");
      });
  };
  let displayTxt = "";
  if (isIncludedTB) {
    if (!tbDetails?.id) {
      displayTxt = "TB not linked";
      // setDisplayTxt('TB not linked')
      dispatch(setCheckValidTechnicalBidItems({ [item.id]: "ERROR" }));
    } else {
      if (!tbDetails?.has_user_fields) {
        dispatch(setCheckValidTechnicalBidItems({ [item.id]: tbDetails?.id }));

        displayTxt = "TB buyer fileds doesn't exist";
        // setDisplayTxt('TB buyer fields does not exist');
      } else {
        if (
          ((defaultTBValuePermission && defaultValue) ||
            (TBTemplateValueIntegration === "Y" && pr_has_tb) ||
            (!defaultTBValuePermission &&
              previousTB?.pre_fetch_inquiry_id > 0)) &&
          parseInt(tbStage) !== 40
        ) {
          dispatch(
            setCheckValidTechnicalBidItems({ [item.id]: tbDetails?.id })
          );

          displayTxt = "Prefetched tb values";
          ////   setDisplayTxt("Prefetched tb values");
        } else {
          if (parseInt(tbStage) === 40) {
            dispatch(
              setCheckValidTechnicalBidItems({ [item.id]: tbDetails?.id })
            );
            displayTxt = "TB values updated";
            //  setDisplayTxt("TB values updated")
          } else {
            dispatch(setCheckValidTechnicalBidItems({ [item.id]: "ERROR" }));
            displayTxt = "TB values not filled";
            // setDisplayTxt("TB values not filled");
          }
        }
      }
    }
  }
  return (
    <ListItem
      disableGutters
      sx={{
        pr: 1,
        pl: 2,
        borderBottom: "1px solid",
        borderColor: "bluegrey.500",
        "& .MuiListItemSecondaryAction-root": {
          position: "static",
          transform: "none",
        },
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      secondaryAction={
        includeTB &&
        itemizeTB &&
        hover && (
          <Box
            sx={{
              pr: 1,
              display: "flex",
              // height: '57px',
            }}
          >
            {isIncludedTB ? (
              <>
                {tbDetails?.id &&
                  tbDetails?.has_user_fields &&
                  disabledtechnicalbid && (
                    <Tooltip title="Preview TB values">
                      <IconButton
                        onClick={() => {
                          handleAction(
                            "PreviewtechnicalBid",
                            item.id,
                            item.old_id,
                            tbDetails?.id,
                            tbDetails?.default_value_rfq_id
                          );
                        }}
                        sx={{ color: (theme) => theme.palette.grey[600] }}
                      >
                        <EditNote />
                      </IconButton>
                    </Tooltip>
                  )}
                {tbDetails?.id &&
                  tbDetails?.has_user_fields &&
                  !disabledtechnicalbid && (
                    <Tooltip title="Edit TB values">
                      <IconButton
                        onClick={() => {
                          handleAction(
                            "editItemizeTB",
                            item.id,
                            item.old_id,
                            tbDetails?.id,
                            previousTB?.pre_fetch_inquiry_id
                          );
                        }}
                        sx={{ color: (theme) => theme.palette.grey[600] }}
                      >
                        <EditNote />
                      </IconButton>
                    </Tooltip>
                  )}
                {(userInfo?.is_super_user === "Y" ||
                  masterPermission?.linking === "Y") &&
                  !disabledtechnicalbid && (
                    <Tooltip title="Link TB template">
                      <IconButton
                        onClick={() => handleLinkUnlinkItemDrawer()}
                        sx={{ color: (theme) => theme.palette.grey[600] }}
                      >
                        <InsertLinkOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                <Tooltip title="Exclude TB">
                  <IconButton
                    onClick={removeItemTB}
                    disabled={disabledtechnicalbid}
                    sx={{ color: (theme) => theme.palette.grey[600] }}
                  >
                    <WebAssetOffIcon />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <Tooltip title="Include TB">
                <IconButton
                  onClick={addItemTB}
                  sx={{ color: (theme) => theme.palette.grey[600] }}
                >
                  <WebAssetIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )
      }
    >
      {/* <ListItemButton sx={{ overflow: "hidden" }}> */}
      <ListItemIcon sx={{ minWidth: 30 }}>
        <Checkbox
          edge="start"
          checked={checked}
          onChange={onClick}
          disabled={search_disable}
        />
      </ListItemIcon>
      {item.prlineitems_ids !== undefined ? (
        <IconButton
          sx={{ color: "grey.600", mr: 1 }}
          size="small"
          onClick={(event) => prDialogOpen(event, item.id)}
        >
          <PrIcon />
        </IconButton>
      ) : null}

      {itemizeRFQ && (
        <ListItemIcon sx={{ minWidth: 30 }}>
          {parseInt(item.vendor_count?.total_count) > 0 ? (
            <Tooltip title="View Vendors">
              <IconButton
                sx={{ color: "grey.600", mr: 1 }}
                size="small"
                onClick={viewVendors}
              >
                <GroupIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="vendors not available">
              <IconButton sx={{ color: "error.main", mr: 1 }} size="small">
                <LinkVendorError />
              </IconButton>
            </Tooltip>
          )}
        </ListItemIcon>
      )}
      <ListItemText
        sx={{ m: 0, lineHeight: "normal" }}
        primary={
          <React.Fragment>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Tooltip
                title={_.unescape(item.name)}
                placement="bottom-start"
                disableInteractive
              >
                <Typography
                  noWrap
                  sx={{ fontWeight: 500, lineHeight: "normal" }}
                  maxWidth={
                    itemizeRFQ && item?.vendor_count?.total_count === 0
                      ? "calc(100% - 150px)"
                      : "100%"
                  }
                >
                  {_.unescape(item.name)}
                </Typography>
              </Tooltip>
              {((itemizeTB && !hover) || !itemizeTB) &&
                itemizeRFQ &&
                parseInt(item?.vendor_count?.total_count) === 0 && (
                  <Typography
                    variant="caption"
                    sx={{ color: "error.main", lineHeight: "normal" }}
                  >
                    vendors not available
                  </Typography>
                )}
            </Box>
          </React.Fragment>
        }
        secondary={
          includeTB &&
          itemizeTB &&
          (singleLoad ? (
            <Skeleton variant="text" width={100} />
          ) : isIncludedTB ? (
            !tbDetails?.id ? (
              <Typography
                variant="caption"
                sx={{
                  color: (theme) => theme.palette.error.main,
                }}
              >
                TB not linked
              </Typography>
            ) : !tbDetails?.has_user_fields ? (
              <Typography
                variant="caption"
                sx={{
                  color: (theme) => theme.palette.success.dark,
                }}
              >
                TB buyer fields does not exists
              </Typography>
            ) : ((defaultTBValuePermission && defaultValue) ||
              (TBTemplateValueIntegration === "Y" && pr_has_tb) ||
              (!defaultTBValuePermission &&
                previousTB?.pre_fetch_inquiry_id > 0)) &&
              parseInt(tbStage) !== 40 ? (
              <Typography
                variant="caption"
                sx={{
                  color: (theme) => theme.palette.success.dark,
                }}
              >
                {disabledtechnicalbid
                  ? "Prefetched tb values from PR"
                  : "Prefetched tb values"}
              </Typography>
            ) : parseInt(tbStage) === 40 ? (
              <Typography
                variant="caption"
                sx={{
                  color: (theme) => theme.palette.success.dark,
                }}
              >
                TB values updated
              </Typography>
            ) : (
              <Typography
                variant="caption"
                sx={{
                  color: (theme) => theme.palette.error.main,
                }}
              >
                TB values not filled
              </Typography>
            )
          ) : (
            <Typography variant="caption" sx={{}}>
              Exclude TB
            </Typography>
          ))
        }
      />
      {/* </ListItemButton> */}
      {/* new design for linking the template*/}
      <LinkUnlinkTemplateItemDrawer
        sessionId={sessionId}
        itemId={item?.id}
        selectedCategory={selectedCategory}
        // selectedItem={selectedItem}
        openLinkUnlinkItemDrawer={openLinkUnlinkItemDrawer}
        CloseLinkUnlinkItemDrawer={CloseLinkUnlinkItemDrawer}
      />
    </ListItem>
  );
};

export default CartItem;
