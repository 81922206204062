import React, { Component } from "react";
import {
  Typography,
  Grid,
  InputAdornment,
  IconButton,
  InputBase,
  Tooltip,
  Checkbox,
  Button,
  styled,
  Skeleton,
  LinearProgress,
  Switch,
  Drawer,
  MenuItem,
  Select,
  FormControl,
  Avatar,
  Divider,
  FormControlLabel,
} from "@mui/material";
import { connect } from "react-redux";
import {
  DataGridPro,
  GridActionsCellItem,
  gridColumnPositionsSelector,
  gridColumnsTotalWidthSelector,
  GridOverlay,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import {
  getVendors,
  filterVendors,
  setSelectedVendors,
  // setSelectAllVendors
} from "../../../redux/actions/vendorActions";
import SearchIcon from "@mui/icons-material/Search";
import "../../../pages/style.css";
import vendoremptyicon from "../../../assets/vendxAdminPanelAsstes/assetsnew/vendor_blank.svg";
import { resetVendors } from "../../../redux/actions/vendorActions";
import {
  getAllLinkedVendorsWithItemIds,
  getFilteredVendors,
  postSendRFQToNewVendor,
  searchCategoryVendorsByNameOrCode,
} from "../../../config/apiUrl";
import axios from "axios";
import { debounce } from "lodash";
import LoopIcon from "@mui/icons-material/Loop";
import ClearIcon from "@mui/icons-material/Clear";
import { getFilterOperators } from "../../Utils/datagridHelper";
import { Box } from "@mui/system";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { tooltipClasses } from "@mui/material/Tooltip";
import { getVendorGMPFlag } from "../../Vendors/VendorChip";
import EditIcon from '@mui/icons-material/Edit';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import AddLinkIcon from '@mui/icons-material/AddLink';
import SendToNew from "../../../pages/SendToNew";
import CustomSnackbar from "../../../utils/CustomSnackbar/CustomSnackbar";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const randomBetween = (seed, min, max) => {
  const random = mulberry32(seed);
  return () => min + (max - min) * random();
};

const mulberry32 = (a) => {
  return () => {
    /* eslint-disable */
    let t = (a += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    /* eslint-enable */
  };
};
const SkeletonCell = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const SkeletonLoadingOverlay = () => {
  const apiRef = useGridApiContext();

  const dimensions = apiRef.current?.getRootDimensions();
  const viewportHeight = dimensions?.viewportInnerSize.height ?? 0;

  const rowHeight = apiRef.current.unstable_getRowHeight();
  const skeletonRowsCount = Math.ceil(viewportHeight / rowHeight);

  const totalWidth = gridColumnsTotalWidthSelector(apiRef);
  const positions = gridColumnPositionsSelector(apiRef);
  const inViewportCount = React.useMemo(
    () => positions.filter((value) => value <= totalWidth).length,
    [totalWidth, positions]
  );
  const columns = apiRef.current.getVisibleColumns().slice(0, inViewportCount);

  const children = React.useMemo(() => {
    // reseed random number generator to create stable lines between renders
    const random = randomBetween(12345, 25, 75);
    const array = [];

    for (let i = 0; i < skeletonRowsCount; i += 1) {
      for (const column of columns) {
        const width = Math.round(random());
        array.push(
          <SkeletonCell
            key={`column-${i}-${column.field}`}
            sx={{ justifyContent: column.align }}
          >
            <Skeleton sx={{ mx: 1 }} width={`${width}%`} />
          </SkeletonCell>
        );
      }
      array.push(<SkeletonCell key={`fill-${i}`} />);
    }
    return array;
  }, [skeletonRowsCount, columns]);

  const rowsCount = apiRef.current.getRowsCount();
  return rowsCount > 0 ? (
    <LinearProgress />
  ) : (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `${columns
          .map(({ computedWidth }) => `${computedWidth}px`)
          .join(" ")} 1fr`,
        gridAutoRows: rowHeight
      }}
    >
      {children}
    </div>
  );
};

class SendRfqCategoryVendors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      total: 0,
      currentPage: 1,
      filteredTotal: 0,
      filteredRows: [],
      selectionModel: [],
      lastPage: 1,
      isFilterActive: false,
      filterDataLastPage: 1,
      filterDataCurrentPage: 1,
      vendor_name: "",
      vendor_name_operator: "",
      vendor_code: "",
      vendor_code_operator: "",
      industry_type: "",
      industry_type_operator: "",
      country: "",
      country_operator: "",
      email: "",
      email_operator: "",
      state: "",
      state_operator: "",
      contact_person: "",
      contact_person_operator: "",
      phone_no: "",
      phone_no_operator: "",
      address: "",
      address_operator: "",
      anchorEl: null,
      linkVendorToCategoryOpen: false,
      loading: false,
      VendorSearchInput: "",
      vendorSearchDebounceDelay: false,
      searchedRows: [],
      searchedDataCurrentPage: 1,
      searchedDataLastPage: 1,
      searchededTotal: 0,
      filterModel: {
        items: [],
      },
      pagination: true,
      mainDataWithoutPaginaion: [],
      mainSelectionModel: [],
      singleSelectionModel: [],
      showSelected: false,
      selectallvendors: false,
      selectallarea: false,
      isAllVendorSelected: false,
      showDrawer: false,
      showTemplateModal: false,
      templateMessage: "",
      vendorClassifications: {},
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarSeverity: "success",
      sendSms: "N"
    };
  }

  // Handle snackbar close
  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  // Show snackbar
  showSnackbar = (severity, message) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
      snackbarSeverity: severity,
    });
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  // handleClose = () => {
  //   this.setState({
  //     anchorEl: null,
  //   });
  // };

  componentDidMount() {
    if (this.props.updateNewVendorFlag && this.props.itemsList) {
      this.fetchVendors(1, this.props.itemIdsCSV);
    }
    this.setState(
      {
        pagination:
          this.props.featurePermission
            .restrict_users_to_deselect_linked_vendors_while_floating_rfq
            ?.feature_value !== "Y",
      },
      () => {
        if (
          Object.keys(this.props.defaultValues).length !== 0 &&
          this.props.defaultValues.selectAllVendors === true
        ) {
          this.setState({ selectallvendors: true }, () => this.fetchVendors(1, this.props.itemIdsCSV));
        } else {
          this.fetchVendors(1, this.props.itemIdsCSV);
        }
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.key !== this.props.key) {
      this.fetchVendors(1, this.props.itemIdsCSV);
    }

    if (this.state.rfqSent && !prevState.rfqSent) {
      this.setState({ rfqSent: false }, () => {
        this.fetchVendors(1, this.props.itemIdsCSV);
      });
    }

    if (this.state.showSelected && !this.state.mainSelectionModel.length) {
      this.setState({ showSelected: false });
    }

    // if (!this.state.mainSelectionModel.length && this.props.selectedVendors.length) {
    //   this.setState({ mainSelectionModel: this.props.selectedVendors });
    // }

    if (
      prevProps.newlyVendorIdToItem !== this.props.newlyVendorIdToItem &&
      this.props.newlyVendorIdToItem
    ) {
      this.setState({ mainSelectionModel: [...this.props.newlyVendorIdToItem] });
    }

    if (this.props.cancelCounter !== prevProps.cancelCounter) {
      this.handleCancel();
    }
  }

  //for vendors pagination
  handleScrollEnd = () => {
    if (this.state.isFilterActive) {
      if (this.state.filterDataCurrentPage !== this.state.filterDataLastPage) {
        this.setState(
          {
            filterDataCurrentPage: this.state.filterDataCurrentPage + 1,
          },
          () => {
            this.fetchFilterVendors(this.state.filterDataCurrentPage);
          }
        );
      }
    } else {
      if (this.state.VendorSearchInput.trim() === "") {
        if (this.state.currentPage !== this.state.lastPage) {
          this.setState(
            {
              currentPage: this.state.currentPage + 1,
            },
            () => {
              this.fetchVendors(this.state.currentPage, this.props.itemIdsCSV);
            }
          );
        }
      } else {
        if (
          this.state.searchedDataCurrentPage !== this.state.searchedDataLastPage
        ) {
          this.setState(
            {
              searchedDataCurrentPage: this.state.searchedDataCurrentPage + 1,
            },
            () => {
              this.searchVendors();
              // this.fetchVendors(this.state.currentPage);
            }
          );
        }
      }
    }
  };

  handleFilterModelChange = (filterModel) => {
    let vendor_name = "";
    let vendor_name_operator = "";
    let vendor_code = "";
    let vendor_code_operator = "";
    let country = "";
    let country_operator = "";
    let industry_type = "";
    let industry_type_operator = "";
    let email = "";
    let email_operator = "";
    let state = "";
    let state_operator = "";
    let contact_person = "";
    let contact_person_operator = "";
    let phone_no = "";
    let phone_no_operator = "";
    let address = "";
    let address_operator = "";
    this.setState({
      filterModel: filterModel,
    });
    if (this.state.pagination) {
      filterModel.items.forEach((filter) => {
        if (filter.value) {
          if (filter.field === "vendor_name") {
            vendor_name = filter.value;
            vendor_name_operator = filter.operator;
          } else if (filter.field === "vendor_code") {
            vendor_code = filter.value;
            vendor_code_operator = filter.operator;
          } else if (filter.field === "country") {
            country = filter.value;
            country_operator = filter.operator;
          } else if (filter.field === "industry_type") {
            industry_type = filter.value;
            industry_type_operator = filter.operator;
          } else if (filter.field === "email") {
            email = filter.value;
            email_operator = filter.operator;
          } else if (filter.field === "state") {
            state = filter.value;
            state_operator = filter.operator;
          } else if (filter.field === "contact_person") {
            contact_person = filter.value;
            contact_person_operator = filter.operator;
          } else if (filter.field === "phone_no") {
            phone_no = filter.value;
            phone_no_operator = filter.operator;
          } else if (filter.field === "address") {
            address = filter.value;
            address_operator = filter.operator;
          }
        }
      });
      //api call
      if (
        vendor_name ||
        vendor_code ||
        country ||
        industry_type ||
        email ||
        state ||
        contact_person ||
        phone_no ||
        address
      ) {
        this.setState(
          {
            isFilterActive: true,
            vendor_name,
            vendor_name_operator,
            vendor_code,
            vendor_code_operator,
            country,
            country_operator,
            industry_type,
            industry_type_operator,
            email,
            email_operator,
            state,
            state_operator,
            contact_person,
            contact_person_operator,
            phone_no,
            phone_no_operator,
            address,
            address_operator,
            filterDataCurrentPage: 1,
            filteredRows: [],
            filterDataLastPage: 1,
            filteredTotal: 0,
            VendorSearchInput: "",
            selectionModel: [],
          },
          () => {
            this.fetchFilterVendors(1);
          }
        );
      } else {
        this.setState({
          isFilterActive: false,
          vendor_name: "",
          vendor_code: "",
          country: "",
          industry_type: "",
          filterDataCurrentPage: 1,
          filteredRows: [],
          filterDataLastPage: 1,
        });
      }
    }
  };

  updateSelectedVendors = (newSelectionModel, finalData) => {
    this.setState(
      {
        selectionModel: newSelectionModel,
      },
      () => {
        // Determine which vendor list to use:
        // If finalData is provided, use it; otherwise, filter mainSelectionModel.
        const vendorsToUpdate = finalData
          ? finalData
          : this.state.mainSelectionModel.filter((vendor) =>
            newSelectionModel.includes(vendor.vendor_id || vendor.id)
          );

        // Add vendor_location to each vendor object.
        const updatedVendors = vendorsToUpdate.map((vendor) => ({
          ...vendor,
          vendor_location:
            this.state.vendorClassifications[vendor.vendor_id || vendor.id] || ''
        }));

        // Propagate the updated vendor list.
        this.props.setSelectedVendors(updatedVendors);
      }
    );
  };

  handleReload = () => {
    this.props.handleCategoryVendorsReload();
  };

  handleCancel = () => {
    this.props.setSelectedVendors([]);
    this.props.setCancelFlag(false);
    this.setState({
      rows: [],
      mainSelectionModel: [],
      singleSelectionModel: [],
      selectionModel: [],
    });
  };

  getVendorLocation = (country) => {
    if (this.props.accountlevelInfo?.country === country) {
      return "L";
    } else return "I";
  };

  fetchVendors = (page, itemIdsCSV) => {
    const url = getAllLinkedVendorsWithItemIds(
      this.props.selectedCategory?.new_category_id,
      page,
      // this.state.selectallvendors ? false : this.state.pagination,
      false,
      itemIdsCSV
    );

    this.setState({
      loading: true,
    });

    axios
      .get(url)
      .then((res) => {
        // Eliminate vendors that match with this.props.vendorsDetails
        const existingVendorIds = this.props.vendorsDetails.map(
          (vendor) => parseInt(vendor.id)
        );
        // Combine the current rows with the filtered vendors
        let finalRows = [...this.state.rows, ...res.data.data];
        finalRows = finalRows.filter(
          (vendor) => !existingVendorIds.includes(parseInt(vendor.vendor_id))
        );
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueRows = Array.from(uniqueSet).map(JSON.parse);
        let updatedVendorClassifications = { ...this.state.vendorClassifications };
        uniqueRows.forEach((vendor) => {
          if (!updatedVendorClassifications[vendor.vendor_id]) {
            updatedVendorClassifications[vendor.vendor_id] = this.getVendorLocation(vendor.country);
          }
          return {
            ...vendor,
            vendor_location: updatedVendorClassifications[vendor.vendor_id],
          };
        });
        this.setState(
          {
            loading: false,
            mainDataWithoutPaginaion: uniqueRows,
            rows: uniqueRows,
            // currentPage: res.data.meta?.current_page,
            // lastPage: res.data.meta?.last_page,
            // ...(!this.state.total && {
            //   total: res.data.meta.total - this.props.countVendor
            //   // this.state.pagination && !this.state.selectallvendors
            //   //   ? res.data.meta.total - this.props.countVendor
            //   //   : uniqueRows.length,
            // }),
            vendorClassifications: updatedVendorClassifications,
          },
          () => {
            if (this.state.selectallvendors) {
              const value = this.getSelectableRows(uniqueRows);
              this.setState({ mainSelectionModel: value });
              this.updateSelectedVendors(value.map((vendor) => vendor.id));
            }
            if (!this.state.pagination) {
              this.updateSelectedVendors(
                this.getSelectableRows(uniqueRows).map((vendor) => vendor.id)
              );
            }
            // this.handleScrollEnd();
          }
        );
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log("fetch vendors err", err);
      });
  };


  fetchFilterVendors = (page) => {
    const url = getFilteredVendors(
      this.props.selectedCategory?.new_category_id,
      this.state.vendor_name.trim(),
      this.state.vendor_name_operator,
      this.state.vendor_code.trim(),
      this.state.vendor_code_operator,
      this.state.country.trim(),
      this.state.country_operator,
      this.state.industry_type.trim(),
      this.state.industry_type_operator,
      this.state.email.trim(),
      this.state.email_operator,
      this.state.state.trim(),
      this.state.state_operator,
      this.state.contact_person.trim(),
      this.state.contact_person_operator,
      this.state.phone_no.trim(),
      this.state.phone_no_operator,
      this.state.address.trim(),
      this.state.address_operator,

      page
    );
    this.setState({
      loading: true,
    });
    axios
      .get(url)
      .then((res) => {
        if (page === 1) {
          this.setState({
            loading: false,
            filteredRows: res.data.data,
            filterDataCurrentPage: res.data.meta.current_page,
            filterDataLastPage: res.data.meta.last_page,
            filteredTotal: res.data.meta.total,
          });
        } else {
          let finalRows = [...this.state.filteredRows, ...res.data.data];
          let jsonObject = finalRows.map(JSON.stringify);
          let uniqueSet = new Set(jsonObject);
          let uniqueRows = Array.from(uniqueSet).map(JSON.parse);
          let updatedVendorClassifications = { ...this.state.vendorClassifications };
          uniqueRows.forEach((vendor) => {
            if (!updatedVendorClassifications[vendor.vendor_id]) {
              updatedVendorClassifications[vendor.vendor_id] = this.getVendorLocation(vendor.country);
            }
            return {
              ...vendor,
              vendor_location: updatedVendorClassifications[vendor.vendor_id],
            };
          });
          this.setState({
            loading: false,
            filteredRows: uniqueRows,
            filterDataCurrentPage: res.data.meta.current_page,
            filterDataLastPage: res.data.meta.last_page,
            vendorClassifications: updatedVendorClassifications,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log("fetch vendors err", err);
      });
  };

  searchVendors = () => {
    const url = searchCategoryVendorsByNameOrCode(
      this.props.selectedCategory?.new_category_id,
      this.state.VendorSearchInput.trim(),
      this.state.searchedDataCurrentPage
    );

    this.setState({
      loading: true,
    });

    axios
      .get(url)
      .then((res) => {
        if (this.state.searchedDataCurrentPage === 1) {
          let jsonObject = res.data.data.map(JSON.stringify);
          let uniqueRows = Array.from(jsonObject).map(JSON.parse);
          let updatedVendorClassifications = { ...this.state.vendorClassifications };
          uniqueRows.forEach((vendor) => {
            if (!updatedVendorClassifications[vendor.vendor_id]) {
              updatedVendorClassifications[vendor.vendor_id] = this.getVendorLocation(vendor.country);
            }
            return {
              ...vendor,
              vendor_location: updatedVendorClassifications[vendor.vendor_id],
            };
          });
          this.setState({
            loading: false,
            searchedRows: res.data.data,
            searchedDataCurrentPage: res.data.meta.current_page,
            searchedDataLastPage: res.data.meta.last_page,
            searchededTotal: res.data.meta.total,
            vendorClassifications: updatedVendorClassifications,
          });
        } else {
          let finalRows = [...this.state.searchedRows, ...res.data.data];
          let jsonObject = finalRows.map(JSON.stringify);
          let uniqueSet = new Set(jsonObject);
          let uniqueRows = Array.from(uniqueSet).map(JSON.parse);
          let updatedVendorClassifications = { ...this.state.vendorClassifications };
          uniqueRows.forEach((vendor) => {
            if (!updatedVendorClassifications[vendor.vendor_id]) {
              updatedVendorClassifications[vendor.vendor_id] = this.getVendorLocation(vendor.country);
            }
            return {
              ...vendor,
              vendor_location: updatedVendorClassifications[vendor.vendor_id],
            };
          });
          this.setState({
            loading: false,
            searchedRows: uniqueRows,
            searchedDataCurrentPage: res.data.meta.current_page,
            searchedDataLastPage: res.data.meta.last_page,
            vendorClassifications: updatedVendorClassifications,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log("searched vendors err", err);
      });
  };

  handleSearchChange = (evt) => {
    this.setState({
      VendorSearchInput: evt.target.value,
    });
    if (evt.target.value.trim() !== "") {
      this.setState(
        {
          isFilterActive: false,
          vendorSearchDebounceDelay: true,
          loading: this.state.pagination,
          searchedRows: [],
          selectionModel: [],
          searchedDataCurrentPage: 1,
          searchedDataLastPage: 1,
          searchededTotal: 0,
          filterModel: {
            items: [],
          },
        },
        () => {
          this.getSearchApi();
        }
      );
    } else {
      this.setState(
        {
          isFilterActive: false,
          vendor_name: "",
          vendor_code: "",
          country: "",
          industry_type: "",
          filterDataCurrentPage: 1,
          filteredRows: [],
          filterDataLastPage: 1,
        },
        () => this.getSearchApi()
      );
    }
  };

  getApi = debounce(() => {
    this.setState({
      isFilterActive: true,
      vendorSearchDebounceDelay: false,
    });
    this.fetchFilterVendors(1);
  }, 1000);

  escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  getSearchApi = debounce(() => {
    this.setState({
      vendorSearchDebounceDelay: false,
    });

    if (this.state.pagination) {
      this.searchVendors();
    } else {
      if (this.state.VendorSearchInput.trim() === "") {
        this.setState(
          {
            selectionModel: [],
          },
          () => {
            this.setState(
              {
                rows: this.state.mainDataWithoutPaginaion,
              },
              () => {
                this.setState({
                  selectionModel: this.state.mainDataWithoutPaginaion.map(
                    (vendor) => vendor.id
                  ),
                });
              }
            );
          }
        );
        return;
      }

      const searchRegex = new RegExp(
        this.escapeRegExp(this.state.VendorSearchInput.trim()),
        "i"
      );
      const filteredRows = this.state.mainDataWithoutPaginaion.filter((row) => {
        return searchRegex.test(row["vendor_name"].toString());
      });
      this.setState(
        {
          rows: filteredRows,
          // selectionModel:this.state.mainDataWithoutPaginaion.map(vendor =>vendor.id),
        },
        () => {
          this.setState({
            selectionModel: this.state.rows.map((vendor) => vendor.id),
          });
        }
      );

      // setRows(filteredRows);
    }
  }, 1000);

  isVendorSelected = (row) => {
    return this.state.mainSelectionModel.some((e) => e.id === row.id);
  };

  handleVendorClick = (vendor, isSingleSelection) => {
    if (isSingleSelection) {
      // Handle single vendor selection
      this.props.handlelinkVendorToItemOpen();
      this.setState(
        {
          singleSelectionModel: vendor, // Update the state with the selected single vendor
        },
        () => {
          // Perform any additional logic or API calls
          this.updateSelectedVendors([vendor.id], [vendor]); // Optional: If needed to sync with other systems
        }
      );
    } else {
      // Handle multiple vendor selection (checkboxes)
      const selectedIndex = this.state.mainSelectionModel
        .map((ob) => ob.id)
        .indexOf(vendor.id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(this.state.mainSelectionModel, vendor);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(this.state.mainSelectionModel.slice(1));
      } else if (selectedIndex === this.state.mainSelectionModel.length - 1) {
        newSelected = newSelected.concat(
          this.state.mainSelectionModel.slice(0, -1)
        );
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          this.state.mainSelectionModel.slice(0, selectedIndex),
          this.state.mainSelectionModel.slice(selectedIndex + 1)
        );
      }

      this.setState(
        {
          mainSelectionModel: newSelected,
        },
        () => {
          this.updateSelectedVendors(
            this.state.mainSelectionModel.map((cv) => cv.id),
            this.state.mainSelectionModel
          );
        }
      );
    }
  };

  getSelectableRows = (data) => {
    return data.filter((params) => {
      if (!params.email?.length) {
        return false;
      }
      // if inco is enable and vendor has no inco  
      if (this.props.inquiryData.permission?.is_inco_term === "Y") {
        if (!params.inco_term_info.inco_term_code) return false;
      }
      if (this.props.inquiryData.permission?.is_payment_term === "Y") {
        if (!params.payment_term_info.payment_term_code) return false;
      }
      if (
        this.props.accountModulePermission[
          "Plant Specific Categroy Item Vendor Linking"
        ]?.module_activated === "Y" &&
        this.props.inquiryData?.item_specific_vendor &&
        this.props.inquiryData?.group_id &&
        params?.linked_plants == null
      ) {
        return false;
      }

      if (this.props.inquiryData?.item_specific_vendor && params?.item_count == 0) {
        return false;
      }
      return true;
    });
  };

  handleSelectAllClick = (event) => {
    if (this.state.rows.length !== this.state.total && event.target.checked) {
      this.setState({ selectallarea: event.target.checked });
    } else {
      this.setState({ selectallarea: false });
    }

    if (!this.state.pagination) {
      return;
    }
    if (this.state.showSelected) {
      this.setState(
        {
          mainSelectionModel: [],
        },
        () => {
          this.updateSelectedVendors([]);
        }
      );
      return;
    }
    let newSelecteds = [];
    if (this.state.isFilterActive) {
      let newRows = [
        ...this.getSelectableRows(this.state.filteredRows),
        ...this.state.mainSelectionModel,
      ];
      let finalRows = newRows;
      let jsonObject = finalRows.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      newSelecteds = Array.from(uniqueSet).map(JSON.parse);
    } else {
      if (this.state.VendorSearchInput.trim() === "") {
        // let newRows = [...this.state.rows, ...this.state.mainSelectionModel];
        let newRows = [
          ...this.getSelectableRows(this.state.rows),
          ...this.state.mainSelectionModel,
        ];
        let finalRows = newRows;
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        newSelecteds = Array.from(uniqueSet).map(JSON.parse);
      } else {
        let newRows = [
          ...this.getSelectableRows(this.state.searchedRows),
          ...this.state.mainSelectionModel,
        ];
        let finalRows = newRows;
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        newSelecteds = Array.from(uniqueSet).map(JSON.parse);
      }
    }

    if (event.target.checked) {
      this.setState(
        {
          mainSelectionModel: newSelecteds,
        },
        () => {
          this.updateSelectedVendors(newSelecteds.map((vendor) => vendor.id));
        }
      );
      return;
    }

    let update = [];
    if (this.state.isFilterActive) {
      update = this.state.mainSelectionModel.filter(
        (el) => !this.state.filteredRows.map((ob) => ob.id).includes(el.id)
      );
    } else {
      if (this.state.VendorSearchInput.trim() === "") {
        //removing the rows from the main selection model
        update = this.state.mainSelectionModel.filter(
          (el) => !this.state.rows.map((ob) => ob.id).includes(el.id)
        );
      } else {
        //removing the searched:inkedItems from the checked linked items
        update = this.state.mainSelectionModel.filter(
          (el) => !this.state.searchedRows.map((ob) => ob.id).includes(el.id)
        );
      }
    }
    this.setState(
      {
        mainSelectionModel: update,
      },
      () => {
        this.updateSelectedVendors(
          update.map((vendor) => vendor.id),
          this.state.mainSelectionModel
        );
      }
    );
  };

  commonItems = (arr1, arr2) => {
    return arr1.filter(function (o1) {
      return arr2.some(function (o2) {
        return o1.id === o2.id; // return the ones with equal id
      });
    });
  };

  getFirstColumn = () => {
    if (this.state.showSelected) {
      return {
        field: "id",
        width: 20,
        disableColumnMenu: true,
        align: "left",
        headerAlign: "left",
        editable: false,
        sortable: false,
        filterable: false,
        renderHeader: () => (
          <Tooltip title="remove all vendors">
            <IconButton
              onClick={(event) => this.handleSelectAllClick(event)}
              color="error">
              <RemoveCircleIcon />
            </IconButton>
          </Tooltip>
        ),
        renderCell: (params) => {
          return (
            <Tooltip title="remove vendor">
              <IconButton
                onClick={() => this.handleVendorClick(params.row, false)}
                color="error">
                <RemoveCircleIcon />
              </IconButton>
            </Tooltip>
          );
        },
      };
    } else {
      return {
        field: "id",
        width: 42,
        disableColumnMenu: true,
        align: "left",
        headerAlign: "left",
        editable: false,
        sortable: false,
        filterable: false,
        renderHeader: () => (
          <Checkbox
            indeterminate={
              this.state.isFilterActive
                ? this.commonItems(
                  this.state.mainSelectionModel,
                  this.getSelectableRows(this.state.filteredRows)
                )?.length > 0 &&
                this.commonItems(
                  this.state.mainSelectionModel,
                  this.getSelectableRows(this.state.filteredRows)
                )?.length <
                this.getSelectableRows(this.state.filteredRows).length
                : this.state.VendorSearchInput.trim() === ""
                  ? this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(this.state.rows)
                  )?.length > 0 &&
                  this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(this.state.rows)
                  )?.length < this.getSelectableRows(this.state.rows).length
                  : this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(this.state.searchedRows)
                  )?.length > 0 &&
                  this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(this.state.searchedRows)
                  )?.length <
                  this.getSelectableRows(this.state.searchedRows).length
            }
            checked={
              this.state.pagination
                ? this.state.isFilterActive
                  ? this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(this.state.filteredRows)
                  )?.length > 0 &&
                  this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(this.state.filteredRows)
                  )?.length ===
                  this.getSelectableRows(this.state.filteredRows).length
                  : this.state.VendorSearchInput.trim() === ""
                    ? this.commonItems(
                      this.state.mainSelectionModel,
                      this.getSelectableRows(this.state.rows)
                    )?.length > 0 &&
                    this.commonItems(
                      this.state.mainSelectionModel,
                      this.getSelectableRows(this.state.rows)
                    )?.length === this.getSelectableRows(this.state.rows).length
                    : this.commonItems(
                      this.state.mainSelectionModel,
                      this.getSelectableRows(this.state.searchedRows)
                    )?.length > 0 &&
                    this.commonItems(
                      this.state.mainSelectionModel,
                      this.getSelectableRows(this.state.searchedRows)
                    )?.length ===
                    this.getSelectableRows(this.state.searchedRows).length
                : true
            }
            onChange={(event) => this.handleSelectAllClick(event)}
          />
        ),
        renderCell: (params) => {
          if (params.row.item_count >= 1 && this.props.inquiryData?.item_specific_vendor) {
            if (
              (this.props.inquiryData.permission?.is_payment_term === "Y") &&
              (this.props.inquiryData.permission?.is_inco_term === "Y") &&
              !params.row.inco_term_info.inco_term_code &&
              !params.row.payment_term_info.payment_term_code
              // (Array.isArray(params.row.payment_term_info) && params.row.payment_term_info.length === 0) &&
              // (Array.isArray(params.row.inco_term_info) && params.row.inco_term_info.length === 0)
            ) {
              return <GridActionsCellItem
                className="dropdown_menu_with_icon"
                icon={
                  <Tooltip title="Payment Terms and Inco Terms are not available for this vendor">
                    <WarningAmberIcon color="warning" />
                  </Tooltip>
                }
                label="Payment Terms and Inco Terms are not available for this vendor"
              />
            } else if ((this.props.inquiryData.permission?.is_payment_term === "Y") &&
              // Array.isArray(params.row.payment_term_info) &&
              // params.row.payment_term_info.length === 0
              !params.row.payment_term_info.payment_term_code
            ) {
              return <GridActionsCellItem
                className="dropdown_menu_with_icon"
                icon={
                  <Tooltip title="Payment Terms not available for this vendor">
                    <WarningAmberIcon color="warning" />
                  </Tooltip>
                }
                label="Payment Terms not available for this vendor"
              />
            } else if ((this.props.inquiryData.permission?.is_inco_term === "Y") &&
              // Array.isArray(params.row.inco_term_info) &&
              // params.row.inco_term_info.length === 0
              !params.row.inco_term_info.inco_term_code
            ) {
              return <GridActionsCellItem
                className="dropdown_menu_with_icon"
                icon={
                  <Tooltip title="Inco Terms not available for this vendor">
                    <WarningAmberIcon color="warning" />
                  </Tooltip>
                }
                label="Inco Terms not available for this vendor"
              />
            }
          }
          if (!params.row.email?.length) {
            return "";
          }
          // if inco is enable and vendor has no inco  
          if (this.props.inquiryData.permission?.is_inco_term === "Y") {
            // if (this.props.featurePermission?.change_payment_terms?.feature_value === "Y") {
            // if (Array.isArray(params.row.inco_term_info) && params.row.inco_term_info.length === 0) return "";
            if (!params.row.inco_term_info.inco_term_code) return "";
          }
          // if payment is enable and vendor has no payment
          if (this.props.inquiryData.permission?.is_payment_term === "Y") {
            // if (Array.isArray(params.row.payment_term_code) && params.row.payment_term_code.length === 0) return "";

            if (!params.row.payment_term_info.payment_term_code) return "";
          }
          if (
            this.props.accountModulePermission[
              "Plant Specific Categroy Item Vendor Linking"
            ]?.module_activated === "Y" &&
            this.props.inquiryData?.item_specific_vendor &&
            this.props.inquiryData?.group_id &&
            params.row?.linked_plants == null
          ) {

            return "";
          }

          if (this.props.inquiryData?.item_specific_vendor && params.row?.item_count == 0) {
            return "";
          }
          return (
            <Checkbox
              checked={
                this.state.pagination ? this.isVendorSelected(params.row) : true
                // this.state.pagination ? this.handleVendorClick(params.row, false)  : true
              }
            />
          );
        },
      };
    }
  };

  handleSendRfqNewVendorsCategory = () => {
    this.setState({
      loading: true
    })
    const url = postSendRFQToNewVendor(this.props.selectedId || this.props.inquiryData.id);
    let vendors = [];
    let vendor_classification = {};
    const vendorIds = this.state.mainSelectionModel.map((vendor) => vendor.vendor_id);
    this.state.mainSelectionModel?.forEach((vendor) => {
      vendor_classification[vendor.vendor_id] = this.state.vendorClassifications[vendor.vendor_id];
    });
    axios
      .post(url, {
        vendor_ids: vendorIds,
        batch_id: this.props.inquiryData.batch_id,
        template_id: this.props.inquiryData.template_id,
        vendor_location: vendor_classification,
        send_sms: this.state.sendSms
      })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          this.showSnackbar("success", "RFQ mail sent successfully"); // Use showSnackbar
          // Reset selection and refresh vendor list
          this.setState({
            mainSelectionModel: [],
            singleSelectionModel: [],
            VendorSearchInput: "",
            filterModel: { items: [] },
            currentPage: 1,
            isFilterActive: false,
            selectallvendors: false,
            selectallarea: false,
            loading: false,
            selectionModel: [],
            sendSms: "N"
          }, () => {
            this.props.fetchVendorsDetails();
            this.props.setSelectedVendors([]);
            setTimeout(() => {
              this.fetchVendors(1, this.props.itemIdsCSV);
            }, 1000);
            // this.props.fetchData();
          });
        }
      })
      .catch((error) => {
        console.error("Error sending RFQ:", error);
        // Extract error message from response if available
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Error sending RFQ";
        this.showSnackbar("error", errorMessage);
        this.setState({ loading: false });
      });
  };

  handleClassificationChange = (vendorId, newClassification) => {
    this.setState((prevState) => {
      const updatedVendorClassifications = {
        ...prevState.vendorClassifications,
        [vendorId]: newClassification,
      };
      const updatedSelectedVendors = prevState.mainSelectionModel.map(vendor => {
        return { ...vendor, vendor_location: updatedVendorClassifications[vendor.vendor_id] };
      });
      return {
        vendorClassifications: updatedVendorClassifications,
        mainSelectionModel: updatedSelectedVendors,
      };
    }, () => {
      this.props.setSelectedVendors(this.state.mainSelectionModel);
    }
    );
  };

  // Send button click handler
  handleSendClick = () => {
    const { mainSelectionModel } = this.state;
    const { uniqueVendor, inquiryJsonTemplates } = this.props;
    // Get all vendor types from selected vendors
    // const vendorTypes = mainSelectionModel.map(vendor => vendor.vendor_type);
    const vendorTypes = mainSelectionModel.map(vendor =>
      this.state.vendorClassifications[vendor.vendor_id] == "L" ? "Local" : "International"
    );
    // Case 1: No unique vendor specified
    if (!uniqueVendor) {
      this.handleSendRfqNewVendorsCategory();
      return;
    }

    // Check if there are vendors of different types
    const hasMixedVendors = vendorTypes.some(type => type !== uniqueVendor);

    // Case 2: All selected vendors match uniqueVendor type
    if (!hasMixedVendors) {
      this.handleSendRfqNewVendorsCategory();
      return;
    }

    // Case 3: Handle mixed vendor types
    let requiredTemplateType = '';
    let templateMessage = '';

    if (uniqueVendor === "Local") {
      requiredTemplateType = "FOREIGN";
      // templateMessage = "Template is not available for Foreign vendors";
    }
    else if (uniqueVendor === "International" || uniqueVendor === "Foreign") {
      requiredTemplateType = "LOCAL";
      // templateMessage = "Template is not available for Local vendors";
    }

    // Check template availability
    const templateExists = inquiryJsonTemplates[requiredTemplateType]?.length >= 0;

    if (templateExists) {
      // Show drawer when template exists
      this.setState({ showDrawer: true });
    } else {
      // Show error when template is missing
      this.showSnackbar("error", "Template is not available");
      // this.setState({ 
      //   showTemplateModal: true,
      //   // templateMessage: templateMessage
      // });
    }
  };

  selectallbuttonclick = () => {
    const { total, rows } = this.state;
    if (rows.length === total) {
      const mainrows = this.getSelectableRows(rows);
      this.setState({ mainSelectionModel: mainrows });
      this.updateSelectedVendors(mainrows.map((vendor) => vendor.id));
    } else {
      this.setState({ selectallvendors: true }, () => this.fetchVendors(1, this.props.itemIdsCSV));
      // this.props.setSelectAllVendors(true)
    }
  };

  handleSendSmsChange = (event) => {
    const isChecked = event.target.checked;
    this.setState({ sendSms: isChecked ? "Y" : "N" });
  };
  render() {
    const { isCancelAction } = this.props;

    // Check if cancel action should be triggered
    if (isCancelAction) {
      this.handleCancel(); // Call handleCancel when the flag is true
    }
    const columns = [
      this.getFirstColumn(),
      {
        field: "actions",
        type: "actions",
        headerName: "",
        width: 85,
        align: "left",
        resizable: false,
        hide: !(
          this.props.userInfo?.is_super_user === "Y" ||
          this.props.masterPermission.vendor_master === "Y"
        ),
        getActions: (params) => {
          const actions = [];
          // if (params.row.is_approved === "approved") {
          if (params.row.is_approved) {
            actions.push(
              <GridActionsCellItem
                className="dropdown_menu_with_icon"
                icon={<Tooltip title="Edit Vendor Details"><EditIcon sx={{ color: "text.primary" }} /></Tooltip>}
                label="Edit Vendor Details"
                onClick={() => {
                  this.props.handleAddVendorDialogOpen(
                    params.id,
                    params.row.vendor_name
                  );
                }}
              />
            );
          }

          if (params.row.email && this.props.itemSpecificVendorFlag) {
            actions.push(
              <GridActionsCellItem
                className="dropdown_menu_with_icon"
                icon={<Tooltip title="Link Unlink Items"><AddLinkIcon sx={{ color: "text.primary" }} /></Tooltip>}
                label="Link Unlink Items"
                // onClick={() => {
                //   this.handlelinkVendorToItemOpen(
                //     params.row.vendor_id
                //   );
                onClick={() => {
                  this.handleVendorClick(
                    params.row, true
                  );
                }}
              />
            );
          }

          // if (!params.row.email) {
          //   actions.push(
          //     <GridActionsCellItem
          //       className="dropdown_menu_with_icon"
          //       icon={<Tooltip title="Enter Mail ID"><UnsubscribeIcon sx={{ color: "error.500" }} /></Tooltip>}
          //       label="Enter Mail ID"
          //       onClick={() => {
          //         this.props.handleAddVendorDialogOpen(
          //           params.id,
          //           params.row.vendor_name
          //         );
          //       }}
          //     />
          //   );
          // }
          return actions;
        },
      },
      {
        field: "vendor_code",
        headerName: "",
      },
      {
        field: "vendor_name",
        headerName: "VENDOR NAME",
        width: 185,
        editable: false,
        // filterable: this.state.VendorSearchInput.trim() === "",
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return (
            <React.Fragment>
              <Avatar
                sx={{
                  width: 32, height: 32,
                  color: "text.primary",
                  fontSize: "12px",
                  bgcolor: "var(--grey-300)"
                }}
              >
                {params.row.vendor_name.slice(0, 1)}
              </Avatar>
              <Box sx={{ width: "calc(100% - 32px)", lineHeight: "normal", ml: 1 }}>
                <Tooltip
                  title={params.row.vendor_name}
                  disableInteractive
                  placement="bottom-start">
                  <Typography variant="body2" color="text.primary" lineHeight='normal' noWrap>
                    {params.row.vendor_name}
                  </Typography>
                </Tooltip>
                <Tooltip
                  title={params.row.vendor_code}
                  disableInteractive
                  placement="bottom-start"
                >
                  <Typography variant="caption" component="span" display="block" color="grey.500" lineHeight='normal' noWrap>
                    {params.row.vendor_code}
                  </Typography>
                </Tooltip>
              </Box>
            </React.Fragment>
          );
        },
      },
      {
        field: "vendor_status",
        headerName: "VENDOR STATUS",
        width: 150,
        editable: false,
        // filterable: this.state.VendorSearchInput.trim() === "",
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return (
            <React.Fragment>
              <Tooltip
                title=""
                disableInteractive
                placement="bottom-start">
                <Typography variant="body2" fontWeight={500}>
                  {params.row.is_approved === "approved" ?
                    getVendorGMPFlag("Y", "A", "Approved", 'A', "") :
                    getVendorGMPFlag("Y", "N", "Non-Approved", 'N', "")
                  }
                  {getVendorGMPFlag(this.props.companyInfo.gst_verification, params.row.gstin_no, params.row.gstin_status, 'G', "GSTIN")}
                  {params.row.industry_type === "MSME" ?
                    getVendorGMPFlag(this.props.companyInfo.msme_verification, params.row.industry_type_value, params.row.msme_status, 'M', "MSME") :
                    getVendorGMPFlag(this.props.companyInfo.msme_verification, "", params.row.msme_status, 'M', "MSME")
                  }
                  {getVendorGMPFlag(this.props.companyInfo.pan_verification, params.row.pan_no, params.row.pan_status, 'P', "PAN")}
                  {/* {params.row.vendor_type === "Local" ?
                    getVendorGMPFlag("Y", "L", "Local", 'L', "") :
                    getVendorGMPFlag("Y", "I", "International", 'I', "")
                  } */}
                </Typography>
              </Tooltip>
            </React.Fragment>
          );
        },
        // flex:1,
        // sortable: false,
      },
      {
        field: "vendor_classification",
        headerName: "VENDOR CLASSIFICATION",
        width: 160,
        editable: false,
        // filterable: this.state.VendorSearchInput.trim() === "",
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          const vendorClassification = this.state.vendorClassifications[params.row.vendor_id];
          return (
            <React.Fragment>
              <FormControl size="small" fullWidth>
                <Select
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  readOnly={this.props.masterPermission.allow_vendor_type_modification_for_rfq !== 'Y'}
                  value={vendorClassification}
                  onChange={(event) => this.handleClassificationChange(params.row.vendor_id, event.target.value)}

                >
                  <MenuItem value={'L'}>Local</MenuItem>
                  <MenuItem value={'I'}>International</MenuItem>
                </Select>
              </FormControl>
            </React.Fragment>
          );
        },
      },
      {
        field: "email",
        headerName: "Email",
        // filterable: this.state.VendorSearchInput.trim() === "",
        width: 236,
        editable: false,
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return (
            <Tooltip
              title={params.row.email}
              disableInteractive
              placement="bottom-start">
              <Typography variant="body2" noWrap>{params.row.email}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "contact_person",
        headerName: "Contact Person",
        // filterable: this.state.VendorSearchInput.trim() === "",
        width: 180,
        editable: false,
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return (
            <Tooltip
              title={params.row.contact_person}
              disableInteractive
              placement="bottom-start"
            >
              <Typography variant="body2" noWrap>{params.row.contact_person}</Typography>
            </Tooltip>
          )
        },
      },

    ];
    // function CustomGridToolbar() {
    //   return (
    //     <React.Fragment>
    //       <GridToolbarContainer className="datagrid-toolbar">
    //         <Grid
    //           container
    //           spacing={0}
    //           sx={{
    //             width: "100%",
    //             "&.MuiGrid2-root": {
    //               paddingTop: { xs: "0", sm: "0", md: "0", alignItems: "center" },
    //             },
    //           }}
    //         >
    //           <Grid xs={12} sm={12} md={3} xl={4}>
    //             <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
    //               <GridToolbarQuickFilter
    //                 className="DataGrid-SearchBar"
    //                 sx={{ pb: 0 }}
    //               />
    //             </Box>
    //           </Grid>
    //           {/* <Grid
    //             xs={12}
    //             sm={12}
    //             md={9}
    //             xl={8}
    //             sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
    //           >
    //             <Box className="toolbar-right">
    //               <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
    //                 &nbsp;
    //               </Box>
    //               <Box className="dg-filter">
    //                 <GridToolbarColumnsButton />
    //                 <GridToolbarExport
    //                   printOptions={{ disableToolbarButton: true }}
    //                   csvOptions={{
    //                     fileName: 'PR Details Report',
    //                   }} />
    //                 <GridToolbarFilterButton />
    //               </Box>
    //             </Box>
    //           </Grid> */}
    //         </Grid>
    //       </GridToolbarContainer>


    //     </React.Fragment>
    //   );
    // }
    const CustomGridToolbar = React.memo(() => {

      return (
        <GridToolbarQuickFilter
          className="DataGrid-SearchBar"
          sx={{
            width: "100%",
            pb: 0,
            "& .MuiInputBase-root": {
              backgroundColor: "var(--grey-50)",
              width: "100%",
              height: "40px",
              borderRadius: "0",
              borderBottom: "1px solid var(--bluegrey-500)",
              padding: "0 16px",
              "& > .MuiSvgIcon-root": {
                color: (theme) => theme.palette.grey[600],
              },
              "& .MuiButtonBase-root .MuiSvgIcon-root": {
                color: (theme) => theme.palette.grey[500],
              },
              "& input": {
                marginLeft: "8px"
              },
              "&.MuiInput-underline:before, &.MuiInputBase-root:after": {
                display: "none"
              }
            }
          }}
        />
      );
    });
    return (
      <React.Fragment>

        {/* <InputBase
          sx={{
            backgroundColor: "var(--grey-50)",
            width: "100%",
            height: "40px",
            borderRadius: "0",
            borderBottom: "1px solid var(--bluegrey-500)",
            padding: "0 16px",
            "& .MuiInputAdornment-positionStart .MuiSvgIcon-root": {
              color: (theme) => theme.palette.grey[600],
            },
            "& .search-clear svg": {
              width: "18px",
              height: "18px",
              color: (theme) => theme.palette.grey[500],
            },
          }}
          placeholder="Search Vendor Name"
          value={this.state.VendorSearchInput}
          onChange={this.handleSearchChange}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          }
          endAdornment={
            <IconButton
              style={{ color: `grey.600` }}
              size="small"
              onClick={() => {
                this.setState({
                  VendorSearchInput: "",
                  isFilterActive: false,
                  vendor_name: "",
                  vendor_code: "",
                  country: "",
                  industry_type: "",
                  filterDataCurrentPage: 1,
                  filteredRows: [],
                  filterDataLastPage: 1,
                });

                if (!this.state.pagination) {
                  this.setState(
                    {
                      selectionModel: [],
                    },
                    () => {
                      this.setState(
                        {
                          rows: this.state.mainDataWithoutPaginaion,
                        },
                        () => {
                          this.setState({
                            selectionModel:
                              this.state.mainDataWithoutPaginaion.map(
                                (vendor) => vendor.id
                              ),
                          });
                        }
                      );
                    }
                  );
                  return;
                }
              }}>
              <ClearIcon fontSize="small" />
            </IconButton>
          }
        /> */}



        <div className="RfqNewVendor-card">
          {this.state.selectallarea && (
            <Box sx={{ pt: 1, pb: 1, textAlign: "center" }}>
              <Typography component="span">
                {this.state.mainSelectionModel.length} vendor on this page
                are selected.
              </Typography>
              <Button onClick={this.selectallbuttonclick} variant="text">
                Select all {this.state.total} Vendors
              </Button>
            </Box>
          )}
          <Box
            sx={{
              height: {
                xs: "calc(100vh - 206px)",
                sm: "calc(100vh - 188px)",
                md: "calc(100vh - 206px)",
              },
              width: "100%"
            }}
          // className="position-relative"                           
          >
            {/* <DataGridVendor */}
            {this.state.loading || this.state.rows.length > 0 ? (
              <React.Fragment>
                {this.state.showSelected ? (
                  <DataGridPro
                    sx={{
                      zIndex: "0",
                      border: "none",
                      "& .MuiDataGrid-columnHeaders": {
                        border: 0,
                      },
                      "& .Mui-disabled": {
                        visibility: "hidden",
                      },
                      ".MuiDataGrid-columnHeaderTitleContainer": {
                        padding: 0,
                      },
                    }}
                    rows={this.state.mainSelectionModel}
                    rowSelectionModel={this.state.mainSelectionModel.map(
                      (ob) => ob.id
                    )}
                    columns={columns}
                    disableColumnSelector
                    disableColumnReorder
                    hideFooterPagination
                    slots={{
                      noRowsOverlay: () => {
                        return (
                          <GridOverlay>
                            <Typography>
                              {this.props.error
                                ? this.props.error
                                : "Vendors not available"}
                            </Typography>
                          </GridOverlay>
                        );
                      },
                      footer: () => {
                        return (
                          <div
                            style={{
                              textAlign: "right",
                              margin: "15px 10px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}>
                            <Typography>
                              {this.state.mainSelectionModel?.length > 0
                                ? this.state.mainSelectionModel.length +
                                " Selected"
                                : ""}
                            </Typography>
                          </div>
                        );
                      },
                    }}
                    isRowSelectable={(params) => {
                      if (!params.row.email?.length) {
                        return false;
                      }
                      // if inco is enable and vendor has no inco
                      if (
                        this.props.rfqSettingValues?.vendorMasterPaymentTerm
                      ) {
                        if (!params.row.payment_term_info.payment_term_code)
                          return false;
                      }
                      // if payment is enable and vendor has no payment
                      if (
                        this.props.rfqSettingValues?.vendorMasterIncoTerm
                      ) {
                        if (!params.row.inco_term_info.inco_term_code)
                          return false;
                      }
                      return true;
                    }}
                    disableColumnPinning
                  />
                ) : (
                  <DataGridPro
                    sx={{
                      border: "none",
                      "& .Mui-disabled": {
                        visibility: "hidden",
                      },
                    }}
                    headerHeight={40}
                    columnHeaderHeight={40}
                    rowHeight={48}
                    columnVisibilityModel={{
                      vendor_code: false
                    }}
                    // rows={
                    //   this.state.pagination
                    //     ? this.state.isFilterActive
                    //       ? this.state.filteredRows
                    //       : this.state.VendorSearchInput.trim() === ""
                    //         ? this.state.rows
                    //         : this.state.searchedRows
                    //     : this.state.rows
                    // }
                    rows={this.state.rows}
                    loading={this.state.loading}
                    rowSelectionModel={
                      this.state.pagination
                        ? this.state.isFilterActive
                          ? this.state.mainSelectionModel
                            .filter((el) =>
                              this.state.filteredRows
                                .map((ob) => ob.id)
                                .includes(el.id)
                            )
                            .map((row) => row.id)
                          : this.state.VendorSearchInput.trim() === ""
                            ? this.state.mainSelectionModel
                              .filter((el) =>
                                this.state.rows
                                  .map((ob) => ob.id)
                                  .includes(el.id)
                              )
                              .map((row) => row.id)
                            : this.state.mainSelectionModel
                              .filter((el) =>
                                this.state.searchedRows
                                  .map((ob) => ob.id)
                                  .includes(el.id)
                              )
                              .map((row) => row.id)
                        : this.state.selectionModel
                    }

                    onRowsScrollEnd={() => {
                      if (this.state.pagination) {
                        this.handleScrollEnd();
                      }
                    }}
                    onRowClick={(params) => {

                      if (this.state.pagination) {
                        if (!params.row.email?.length) {
                          return;
                        }

                        if (this.props.inquiryData?.item_specific_vendor && params.row?.item_count == 0) {
                          return
                        }
                        if (
                          this.props.accountModulePermission[
                            "Plant Specific Categroy Item Vendor Linking"
                          ]?.module_activated === "Y" &&
                          this.props.inquiryData?.item_specific_vendor &&
                          this.props.inquiryData?.group_id &&
                          params.row?.linked_plants == null
                        ) {
                          return;
                        }
                        // if inco is enable and vendor has no inco
                        // if (
                        //   this.props.rfqSettingValues
                        //   ?.vendorMasterPaymentTerm
                        //   ) {
                        if (
                          this.props.inquiryData.permission?.is_payment_term ===
                          "Y"
                        ) {
                          if (!params.row.payment_term_info.payment_term_code) {
                            return;
                          }
                        }
                        // }
                        // if payment is enable and vendor has no payment
                        // if (
                        //   this.props.rfqSettingValues?.vendorMasterIncoTerm
                        //   ) {
                        if (
                          this.props.inquiryData.permission?.is_inco_term ===
                          "Y"
                        ) {
                          if (!params.row.inco_term_info.inco_term_code) {
                            return;
                          }
                        }
                        // }
                        this.handleVendorClick(params.row, false);
                      }
                    }}
                    // filterModel={this.state.filterModel}
                    // onFilterModelChange={this.handleFilterModelChange}
                    columns={columns}

                    // disableColumnSelector
                    // disableColumnReorder
                    // hideFooterPagination
                    pageSizeOptions={[25, 50, 100]}
                    pagination={true}
                    // filterMode={this.state.pagination ? "server" : "client"}
                    filterMode={"client"}
                    slots={{
                      // noRowsOverlay: () => {
                      //   console.log("NoRowsOverlay")
                      //   return (
                      //     <GridOverlay>
                      //       <Typography>
                      //         {this.props.error
                      //           ? this.props.error
                      //           : "Vendors not available 2"}
                      //       </Typography>
                      //     </GridOverlay>
                      //   );
                      // },
                      //showing Skeleton while data is loading
                      toolbar: CustomGridToolbar,
                      loadingOverlay: () => SkeletonLoadingOverlay(),
                      // footer: () => {
                      //   return (
                      //     <Box
                      //       sx={{
                      //         textAlign: "right",
                      //         padding: "16px",
                      //         display: "flex",
                      //         justifyContent: "space-between",
                      //         borderTop: "1px solid var(--bluegrey-500)"
                      //       }}>
                      //       <Typography>
                      //         {this.state.mainSelectionModel?.length > 0
                      //           ? this.state.mainSelectionModel.length +
                      //           " Selected"
                      //           : ""}
                      //       </Typography>
                      //       <Typography className="grid_footer_text">
                      //         {this.state.isFilterActive
                      //           ? `Showings ${this.state.filteredRows.length} / ${this.state.filteredTotal}`
                      //           : this.state.VendorSearchInput.trim() === ""
                      //             ? `Showing ${this.state.rows.length} / ${this.state.total}`
                      //             : `Showing ${this.state.searchedRows.length} / ${this.state.searchededTotal}`}
                      //       </Typography>
                      //     </Box>
                      //   );
                      // },
                    }}
                    isRowSelectable={(params) => {
                      if (!params.row.email?.length) {
                        return false;
                      }
                      // if inco is enable and vendor has no inco
                      if (
                        this.props.rfqSettingValues?.vendorMasterPaymentTerm
                      ) {
                        if (!params.row.payment_term_info.payment_term_code)
                          return false;
                      }
                      // if payment is enable and vendor has no payment
                      if (
                        this.props.rfqSettingValues?.vendorMasterIncoTerm
                      ) {
                        if (!params.row.inco_term_info.inco_term_code)
                          return false;
                      }
                      return true;
                    }}
                    disableColumnPinning
                  />
                )}
              </React.Fragment>
            ) : (

              <React.Fragment>
                {this.props.error ? (
                  <Typography>{this.props.error}</Typography>
                ) : (
                  <div className="no-vendor vendor_not_available_main" style={{ height: "100%" }}>
                    <img src={vendoremptyicon} alt="Vendor Empty Icon" />
                    <Typography variant="h5" className="mt-15">
                      Vendors not available
                    </Typography>
                    <Typography variant="caption">

                      {(this.props.userInfo?.is_super_user === "Y" ||
                        this.props.masterPermission.linking === "Y") && (
                          <React.Fragment>
                            <span
                              onClick={() => {
                                this.props.handlelinkVendorToCategoryOpen();

                                // this.setState({
                                //   linkVendorToCategoryOpen: true,
                                // });
                              }}>
                              Link vendors &nbsp;
                            </span>
                            with category
                          </React.Fragment>
                        )}
                    </Typography>
                  </div>
                )}
              </React.Fragment>
              // </Grid>
            )}
          </Box>
        </div>
        <Divider />
        <Box
          display="flex"
          alignItems="center"
          gap={{ xs: 1, md: 2 }}
          p={2}
          flexWrap="wrap"
        >
          <Typography
            variant="caption"
            color={"error"}
            lineHeight="normal"
            sx={{
              flex: { xs: "0 0 100%", md: "unset" }
            }}
          >
            *Note: Vendor(s) not having Email ID&nbsp;
            {this.props.inquiryData.permission?.is_inco_term === "Y" && " or Inco Terms"}
            {this.props.inquiryData.permission?.is_payment_term === "Y" && " or Payment Terms "}
            are shown without checkbox
            {this.props.inquiryData?.item_specific_vendor && " and item vendor linking action "}
            in Vendor List
          </Typography>
          {/* <Box sx={{ display: 'flex', alignItems: 'center', gap:'8px' }}>
            <Typography>Send SMS</Typography>
            <Switch
              checked={this.state.sendSms}
              onChange={this.handleSendSmsChange}
              color="primary"
              name="sendSms"
              inputProps={{
                'aria-label': 'primary checkbox',
              }}
            />
          </Box> */}
          <FormControlLabel
            control={<Switch
              checked={this.state.sendSms === "Y"}
              onChange={this.handleSendSmsChange}
              color="primary"
              name="sendSms"
              size="small"
              inputProps={{
                'aria-label': 'primary checkbox',
              }}
            />}
            label={
              <Typography
                variant="body2"
                color={"secondery.900"}
                lineHeight={"normal"}
              >
                Send SMS
              </Typography>
            }
            labelPlacement="start"
            sx={{
              marginLeft: { xs: 0, md: 'auto' },
              marginRight: '0'
            }}
          />

          <Box
            display="flex"
            alignItems="center"
            gap="8px"
            ml={{ xs: "auto", md: "8px" }}
          >
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => {
                this.handleCancel();
                this.props.handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              // onClick={this.handleSendRfqNewVendorsCategory}
              onClick={this.handleSendClick}
              disabled={this.state.mainSelectionModel.length <= 0}
              size="small"
            >
              Send
            </Button>
          </Box>
        </Box>

        {/* Drawer to show the recipient templates based on vendor type */}
        <Drawer
          anchor="right"
          open={this.state.showDrawer}
          // onClose={() => this.setState({ showDrawer: false })}
          sx={{
            ".MuiPaper-root": {
              boxShadow: "none",
              borderRadius: "0",
            },
            '& .MuiDrawer-paper': {
              width: {
                xs: '100%',
                sm: '375px'
              },
            },
            "& .MuiCard-root": {
              height: "100%",
              display: "flex",
              flexDirection: "column",
              border: "none"
            },
            '& .MuiCardContent-root': {
              flex: "1 1 auto",
              overflow: "auto"
            }
          }}
        >

          {/* {((this.props.master_type === "category" && this.props.uniqueVendor === "International") && */}
          {((this.props.uniqueVendor) &&
            <SendToNew
              sessionID={this.props.sessionID}
              vendorType={this.props.uniqueVendor === "Local" ? "foreign" : "local"}
              inquiry_id={this.props.inquiryData.id}
              closeDrawer={() => this.setState({ showDrawer: false })}
              handleSendRfqNewVendorsCategory={this.handleSendRfqNewVendorsCategory}
              fromWebpack={true}
            />
          )}

        </Drawer>
        <CustomSnackbar
          open={this.state.snackbarOpen}
          message={this.state.snackbarMessage}
          severity={this.state.snackbarSeverity}
          onClose={this.handleSnackbarClose}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedCategory: state.categoryReducer.selectedCategory,
    selectedItems: state.itemReducer.selectedItems,
    sessionId: state.sessionReducer.sessionId,
    vendors: state.vendorReducer.vendors,
    vendorsLoading: state.vendorReducer.loading,
    vendorsError: state.vendorReducer.error,
    meta: state.vendorReducer.meta,
    filteredVendors: state.vendorReducer.filteredVendors,
    filteredVendorsLoading: state.vendorReducer.filteredVendorsLoading,
    filteredVendorsError: state.vendorReducer.filteredVendorsError,
    filteredVendorsMeta: state.vendorReducer.filteredVendorsMeta,
    selectedVendors: state.vendorReducer.selectedVendors || [],
    userInfo: state.userInfoReducer.userInfo,
    masterPermission: state.permissionsReducer.masterPermission,
    accountlevelInfo: state.permissionsReducer.accountlevelInfo,
    rfqSettingValues: state.drawerValuesReducer.rfqSettingValues,
    featurePermission: state.permissionsReducer.featurePermission,
    companyInfo: state.companyInfoReducer.companyInfo,
    defaultValues: state.prrfqreducer.defaultValues,
    ImportExcelRfq: state.ImportExcelReducer.excelRespons,
    accountModulePermission: state.permissionsReducer.accountModulePermission,
  };
};

export default connect(mapStateToProps, {
  getVendors,
  filterVendors,
  setSelectedVendors,
  resetVendors,
  // setSelectAllVendors
})(SendRfqCategoryVendors);