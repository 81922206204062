import axios from "axios";
import { SET_RFQ_LIST, SET_RFQ_LIST_ERROR } from "../constants/type";
import { get_Inquiries } from "../../config/apiUrl";

export const setRfqListInfo = (sessionId, ranges) => async (dispatch) => {


  const configAxios = {
    headers: { Authorization: `Bearer ${sessionId}` }
  };

  try {
    const results = [];
    const period  = ranges[0];
      const queryParams = new URLSearchParams({
        start_date: period.start,
        end_date: period.end
      });

      const url = `${get_Inquiries}?${queryParams.toString()}`;

      try {
        if (period.start === null && period.end === null){
          return;
        } 
        const res = await axios.get(url, configAxios);
        if (res.status === 404) {
          results.push([]); // Push an empty array for this period
        } else if (res.data && Array.isArray(res.data.inquires) && res.data.inquires.length > 0) {
          results.push(...res.data.inquires);
        } else {
          console.warn(`Empty or unexpected response for range ${period.start} to ${period.end}:`, res.data);
        }
      } catch (err) {
        console.error(`Error fetching data for range ${period.start} to ${period.end}:`, err);
        dispatch({
          type: SET_RFQ_LIST_ERROR,
          payload: `Error fetching data for range ${period.start} to ${period.end}: ${err.message}`,
        });
        return;
      }
    dispatch({
      type: SET_RFQ_LIST,
      payload: results,
    });

  } catch (err) {
    console.error(`General error fetching inquiries:`, err);
    dispatch({
      type: SET_RFQ_LIST_ERROR,
      payload: err.message,
    });
  }
};
