import React, { useState, useEffect, Fragment } from "react";
import {
    Box,
    Typography,
    Stack,
    Tooltip,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
    GridToolbarQuickFilter,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridToolbarExport,
    GridOverlay,
} from "@mui/x-data-grid-pro";
import CloseIcon from "@mui/icons-material/Close";
import {
    account_all_users,
    getExtendValidityThreadData,
} from "../../../config/apiUrl";
import CommonDataGridPro from "../commonDataGridPro/commonDataGridPro";
import axios from "axios";
import moment from "moment";
import { EmptyMsgIcon } from "../../SvgIcon/SvgIcon";

function ExtendValidityThreads({
    sessionID,
    selectedId,
    inquiryNumber,
    openDialogueBox,
    setOpenDialogueBox
}) {
    const [extendValidityThreadData, setExtendValidityThreadData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25,
        page: 0,
    });

    const configAxios = {
        headers: { Authorization: `Bearer ${sessionID}` },
    };

    const handleCloseDialogue = () => {
        setOpenDialogueBox(false);
    };

    function CustomGridToolbar() {
        return (
            <Fragment>
                <GridToolbarContainer className="datagrid-toolbar">
                    <Grid
                        container
                        spacing={0}
                        sx={{
                            width: "100%",
                            pt: 1,
                            "&.MuiGrid2-root": {
                                paddingTop: { xs: "0", sm: "0", md: "0", alignItems: "center" },
                            },
                        }}
                    >
                        <Grid xs={6} sm={6} md={4} xl={4}>
                            <Box>
                                <GridToolbarQuickFilter
                                    className="DataGrid-SearchBar"
                                    sx={{ p: 0 }}
                                />
                            </Box>
                        </Grid>
                        <Grid xs={6} sm={6} md={8} xl={8} sx={{ textAlign: "right" }}>
                            <Box className="toolbar-right">
                                <Box className="dg-filter">
                                    <GridToolbarColumnsButton />
                                    <GridToolbarExport
                                        printOptions={{ disableToolbarButton: true }}
                                        csvOptions={{
                                            fileName: "Extend Validity Thread Report",
                                        }}
                                    />
                                    <GridToolbarFilterButton />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </GridToolbarContainer>
            </Fragment>
        );
    }

    const columns = [
        {
            field: "user_name",
            headerName: "USER NAME",
            width: 300,
        },
        {
            field: "validity_date",
            headerName: "VALIDITY DATE",
            width: 300,
            type: "date",
            valueFormatter: (params) => {
                const { value } = params;
                return (
                    moment(value, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss") +
                    " IST"
                );
            }
        },
        {
            field: "justification",
            headerName: "JUSTIFICATION",
            width: 300,
        },
        {
            field: "created_at",
            headerName: "VALIDITY EXTENDED ON",
            width: 300,
            valueFormatter: (params) => {
                const { value } = params;
                return (
                    moment(value, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss") +
                    " IST"
                );
            }
        },
    ];

    const CustomNoRowsOverlay = () => (
        <GridOverlay>
            <EmptyMsgIcon sx={{ maxWidth: "225px", width: "100%", height: "auto", mb: 1 }} />
        </GridOverlay>
    );

    const fetchAccountAllUsers = async () => {
        try {
            const response = await axios.get(account_all_users, configAxios);
            return response.data; // Array of user data
        } catch (error) {
            console.error("Error fetching account_all_users:", error);
            return [];
        }
    };

    const fetchExtendValidityThreadData = async () => {
        let url = getExtendValidityThreadData(selectedId);
        try {
            const response = await axios.get(url, configAxios);
            const threadData = response.data;

            // Fetch user details from account_all_users
            const allUsers = await fetchAccountAllUsers();

            // Map UserNames to extendValidityThreadData
            const enrichedData = threadData.map((item) => {
                const user = allUsers?.users.find((allUsers) => allUsers.UserID === item.user_id);
                return {
                    ...item,
                    user_name: user ? user.UserName : "Unknown User", // Default to "Unknown User" if not found
                };
            });
            setExtendValidityThreadData(enrichedData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchExtendValidityThreadData();
    }, []);

    return (
        <React.Fragment>
            <Dialog
                fullScreen
                sx={{
                    margin: { xs: "0", sm: "0", md: "20px" }
                }}
                open={openDialogueBox}
                onClose={handleCloseDialogue}
                className="RFQ-VendorDialog"
            >
                <DialogTitle
                    sx={{
                        position: "sticky",
                        top: 0,
                        padding: "11px 15px !important"
                    }}
                >
                    <Stack
                        className="VendorItem-Title"
                        direction={{ sm: "column", md: "row" }}
                        spacing={{ xs: 0, sm: 0, md: 1 }}
                        alignItems={{ sm: "start", md: "center" }}
                        sx={{
                            flex: 1,
                            "@media screen and (max-width:991px)": {
                                width: "calc(100% - 25px)"
                            }
                        }}
                    >
                        <Tooltip title={inquiryNumber}>
                            <Typography
                                sx={{ color: "#000000" }}
                                variant="body2"
                                fontWeight={700}
                                component="div"
                                noWrap
                            >
                                Extend Validity Thread
                            </Typography>
                            <Typography>{inquiryNumber}</Typography>
                        </Tooltip>
                    </Stack>
                    <IconButton
                        edge="end"
                        color="var(--bluegrey-500)"
                        size="small"
                        onClick={() => {
                            handleCloseDialogue();
                            // setSelectedItem(null);
                        }}
                        aria-label="close"
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ p: 0 }} dividers>
                    <Box className="DataGFullScreen-wrap">
                        <CommonDataGridPro
                            // loading={loading}
                            rows={extendValidityThreadData}
                            columns={columns}
                            getRowId={(row) => `${row.user_id}-${row.created_at}`}
                            headerHeight={40}
                            columnHeaderHeight={40}
                            getRowHeight={() => "25px"}
                            pagination={true}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            Toolbar={CustomGridToolbar}
                            NoRowsOverlay={CustomNoRowsOverlay}
                            disableRowSelectionOnClick
                            className="fullScreenGrid"
                            sx={{
                                "&.MuiDataGrid-root": {
                                    border: 0,
                                },
                                "& .MuiDataGrid-virtualScrollerContent--overflowed .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                                {
                                    borderBottomColor: "bluegray.500",
                                },
                            }}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment >
    );
}

export default ExtendValidityThreads;
