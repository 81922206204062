import * as React from "react";
import {
    Box,
    DialogContent,
    DialogTitle,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Stack,
    Avatar,
    Tooltip,
    IconButton,
    TableBody,
    styled,
    Tab,
    Tabs,
    CircularProgress,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { DialogSlabRange } from "./SlabRangeStyle";
import CloseIcon from "@mui/icons-material/Close";
import { tabsClasses } from "@mui/material/Tabs";
import { devTemplateURi, get_Inquiries_data, templateURi } from "../../../config/apiUrl";
import axios from "axios";
import { NoVendorIcon } from "../../SvgIcon/SvgIcon";
import { URL_MAPPING } from "../../../config/host-mapping";

const ItemTabs = styled(Tabs)({
    minHeight: "32px",
    "& .MuiTabs-indicator": {
        display: "none",
    },
});

const ItemTab = styled(Tab)({
    minHeight: "32px",
    width: "275px",
    textTransform: "none",
    color: "var(--secondary-600)",
    whiteSpace: "nowrap",
    alignItems: "start",
    borderRight: "1px solid var(--bluegrey-500)",
    padding: "8px 16px",
    ".MuiTypography-root": {
        width: "100%",
        textAlign: "left",
    },
    "&.Mui-selected": {
        background: "var(--primary-50)",
        ".MuiTypography-root": {
            color: "var(--primary-500)",
        },
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

export default function SlabRangeDialog(props) {
    const { inquiryId, inquiryData, configAxiosGet, itemsList, userInfo, companyInfo } = props;
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [minLoaderDone, setMinLoaderDone] = React.useState(false);
    const [jsonLoading, setJsonLoading] = React.useState(true);
    const [openChildren, setOpenChildren] = React.useState({});
    const [slabDetails, setSlabDetails] = React.useState({});
    const [quotationJsonTemplates, setQuotationJsonTemplates] = React.useState({});
    const currentItemId = itemsList[value]?.id?.toString();
    const [quotedItemIds, setQuotedItemIds] = React.useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getSortedSolutionData = (solutionData) => {
        // Create a shallow copy so we don't mutate the original array
        return [...solutionData].sort((a, b) => {
            // Extract the first number from the range string
            const parseRangeStart = (rangeStr) => {
                if (!rangeStr) return Number.MAX_SAFE_INTEGER;
                const match = rangeStr.match(/^(\d+)/);
                if (match) return parseInt(match[1], 10);
                // If no starting number is found, push it to the end
                return Number.MAX_SAFE_INTEGER;
            };

            const aStart = parseRangeStart(a._range);
            const bStart = parseRangeStart(b._range);
            return aStart - bStart;
        });
    };

    const getDynamicTableData = (solutionData, templateFields) => {
        if (!solutionData || !templateFields) return [];

        // Sort solution data if needed
        const sortedSolutionData = getSortedSolutionData(solutionData);

        return templateFields
            .filter(
                (field) =>
                    field.VisibilityFlag === "Y" ||
                    (field.DefaultValue?.Button === "Y" && field.ElementType === "D")
            )
            .map((field) => {
                const row = { name: field.FieldLabel, id: field.FieldId };

                // If no solution data, default to 'NQ' in "range1"
                if (sortedSolutionData.length === 0) {
                    row["range1"] = "NQ";
                } else {
                    // Otherwise, build up each slab range value
                    const keysToCheck = [
                        field.FieldId, // e.g. "251753"
                        field.HTMLAttributeName?.replace("f", ""), // e.g. "f251753" → "251753"
                    ].filter(Boolean);

                    sortedSolutionData.forEach((slab, index) => {
                        let fieldValue = "NA";

                        for (const key of keysToCheck) {
                            if (slab[key] !== undefined && slab[key] !== null) {
                                fieldValue = slab[key];
                                break;
                            }
                        }

                        row[`range${index + 1}`] = fieldValue;
                    });
                }

                return row;
            });
    };

    const getRangeHeaders = (solutionData) => {
        // If no solution data, only show one column labeled "NQ"
        if (!solutionData || solutionData.length === 0) {
            return [{ name: "NQ" }];
        }

        const sortedSolutionData = getSortedSolutionData(solutionData);
        return sortedSolutionData.map((slab) => ({
            name: slab._range.replace('-', ' - '),
            key: slab._range
        }));
    };

    // Handle expanding/collapsing individual vendors
    const handleExpandVendor = (vendorId) => {
        setOpenChildren(prev => ({
            ...prev,
            [currentItemId]: {
                ...prev[currentItemId],
                [vendorId]: !prev[currentItemId]?.[vendorId]
            }
        }));
    };

    React.useEffect(() => {
        if (slabDetails?.vendors && currentItemId) {
            setOpenChildren(prev => {
                const currentItemVendors = slabDetails.vendors;
                const currentItemState = prev[currentItemId] || {};

                // Create new state for current item's vendors
                const newVendorStates = currentItemVendors.reduce((acc, vendor) => {
                    // Preserve existing state or initialize to collapsed (false)
                    acc[vendor.id] = currentItemState[vendor.id] || true;
                    return acc;
                }, {});

                return {
                    ...prev,
                    [currentItemId]: newVendorStates
                };
            });
        }
    }, [slabDetails, currentItemId]);

    React.useEffect(() => {
        const fetchSlabDetails = async () => {
            let url =
                get_Inquiries_data +
                inquiryId +
                "/vendors?embed[]=inquiry&embed[]=action&embed[]=item_solution";
            try {
                let response = await axios.get(url, configAxiosGet);
                let quotedItemKeys = [];
                if (response?.data && response?.data?.vendors) {
                    // Filter vendors where quotation.status is not "Pending"
                    // const filteredVendors = response.data.vendors.filter(
                    //     vendor => vendor.quotation.status !== "Pending"
                    // );
                    const filteredVendors = response?.data?.vendors
                        ?.filter((vendor) => {
                            const { quotation } = vendor;
                            if (quotation?.solution_data != undefined) {
                                quotedItemKeys.push(Object.keys(quotation?.solution_data))
                            }
                            return (
                                quotation?.status !== "Pending" &&
                                quotation?.solution_data)
                        }) || [];
                    setSlabDetails({ ...response.data, vendors: filteredVendors });
                    let uniqueQuotedItemKeys = [...new Set(quotedItemKeys.flat().map(Number))];
                    setQuotedItemIds(uniqueQuotedItemKeys);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        if (inquiryId) {
            fetchSlabDetails();
        }
    }, [inquiryId]);

    React.useEffect(() => {
        const fetchJSONTemplate = async (transaction_type, vendorType) => {
            if (
                !inquiryData ||
                !inquiryData.batch_id ||
                !inquiryData.template_id
            ) {
                return;
            }

            // Determine transaction_id
            let transaction_id;
            if (transaction_type === "Quotation") {
                transaction_id = 3;
            } else if (transaction_type === "Quotation" || transaction_type === "Requote" || transaction_type === "Negotiation") {
                transaction_id = 5;
            } else {
                console.error("Unknown type:", transaction_type);
                return;
            }

            const batchID = inquiryData.batch_id;
            const templateID = inquiryData.template_id;
            const currentHost = window.location.host;
            const parentHostURI =
                URL_MAPPING[currentHost]["parent_host"] ||
                URL_MAPPING["default"]["parent_host"];
            const template_url = URL_MAPPING[currentHost]["template_url"] || URL_MAPPING["default"]["template_url"];
            let templateURL = `${template_url}/template/${companyInfo?.display_name}/${userInfo?.division_id}/template_${templateID}_${batchID}_${transaction_id}${(vendorType === "Local" ? "_L" : "_I")}.json?version=` + Date.now();
            // let templateURL =
            //     process.env.NODE_ENV === "production"
            //         ? templateURi(
            //             userInfo?.division_id,
            //             companyInfo?.display_name,
            //             templateID,
            //             batchID,
            //             transaction_id + (vendorType === "Local" ? "_L" : "_I")
            //         )
            //         : devTemplateURi(
            //             userInfo?.division_id,
            //             companyInfo?.display_name,
            //             templateID,
            //             batchID,
            //             transaction_id + (vendorType === "Local" ? "_L" : "_I")
            //         );

            try {
                setJsonLoading(true);
                const response = await axios.get(templateURL);
                setQuotationJsonTemplates((prevTemplates) => ({
                    ...prevTemplates,
                    [`${transaction_type}_${vendorType}`]: response.data,
                }));
            } catch (err) {
                console.error("Error fetching template:", err);
            } finally {
                setJsonLoading(false);
            }
        };

        if (userInfo && companyInfo && Object.keys(userInfo).length > 0 && Object.keys(companyInfo).length > 0) {
            const combinations = [
                { transaction_type: "Quotation", vendorType: "Local" },
                { transaction_type: "Requote", vendorType: "Local" },
                { transaction_type: "Quotation", vendorType: "International" },
                { transaction_type: "Requote", vendorType: "International" },
            ];

            combinations.forEach(({ transaction_type, vendorType }) => {
                fetchJSONTemplate(transaction_type, vendorType);
            });
        }
    }, [inquiryData, userInfo, companyInfo]);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setMinLoaderDone(true);
        }, 2000); // Ensures the loader shows for at least 500ms
        return () => clearTimeout(timer);
    }, []);

    return (
        <DialogSlabRange
            fullScreen
            sx={{ m: 2 }}
            open={props.openSlabRange}
            onClose={props.closeSlabRange}
        >
            {loading && jsonLoading && !minLoaderDone ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress />
                </Box>
            ) :
                (<>
                    <DialogTitle
                        component="div"
                        style={{
                            padding: "14px 16px",
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            fontWeight={500}
                            color={"text.primary"}
                            component="h6"
                        >
                            Slab Range
                        </Typography>
                        <Tooltip title="Close">
                            <IconButton
                                aria-label="close"
                                onClick={props.closeSlabRange}
                                sx={{ position: "absolute", right: 8, top: 13 }}
                                size="small"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </DialogTitle>
                    <DialogContent sx={{ p: 0 }} dividers>
                        <Box className="SlabRange-Box">
                            <Typography
                                component="h6"
                                variant="body1"
                                color={"text.primary"}
                                className="MS-Tital"
                            >
                                Item List
                            </Typography>
                            <Box className="ItemList-TabsHeader">
                                <Box className="ItemTabs-Box">
                                    <ItemTabs
                                        value={value}
                                        variant="scrollable"
                                        scrollButtons
                                        allowScrollButtonsMobile
                                        sx={{
                                            [`& .${tabsClasses.scrollButtons}`]: {
                                                "&.Mui-disabled": { display: "none" },
                                            },
                                            '.MuiButtonBase-root.Mui-selected .MuiTypography-caption': {
                                                color: "var(--primary-300)"
                                            }
                                        }}
                                        onChange={handleChange}
                                    >
                                        {props.itemsList?.length > 0 && (
                                            props.itemsList.map((item) => (
                                                (quotedItemIds.includes(parseInt(item.id)) ?

                                                    <ItemTab
                                                        label={
                                                            <>
                                                                <Tooltip title={item.name} placement="bottom-start">
                                                                    <Typography variant="body2" color={"text.primary"} noWrap>
                                                                        {item.name}
                                                                    </Typography>
                                                                </Tooltip>
                                                                <Tooltip title={item.code} placement="bottom-start">
                                                                    <Typography variant="caption" color={"grey.500"}>
                                                                        {item.code}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </>
                                                        }
                                                    /> : null
                                                )
                                            ))
                                        )}
                                    </ItemTabs>
                                </Box>
                            </Box>
                            <Box>
                                <TabPanel value={value} index={value}>
                                    <TableContainer key={value}>
                                        <Table stickyHeader>
                                            {slabDetails?.vendors?.length > 0 ? (
                                                slabDetails.vendors.map((vendor, index) => {
                                                    // Identify transaction type & vendor type
                                                    const quotationStatus = vendor.quotation?.status;
                                                    const transactionType =
                                                        quotationStatus === "Off. Requoted" || quotationStatus === "Requoted"
                                                            ? "Requote"
                                                            : "Quotation";
                                                    const vendorType = vendor.type; // e.g. "Local" or "Foreign"

                                                    // Pick the correct template
                                                    const templateKey = `${transactionType}_${vendorType}`;
                                                    const template = quotationJsonTemplates[templateKey];
                                                    if (!template) return null; // no template available

                                                    // Combine fields from your template sections
                                                    const mainSection = (vendorType === "Local" ? template.LOCAL : template.FOREIGN) || [];
                                                    const itemizeSection = (template.ITEMIZE && template.ITEMIZE[0]) || [];
                                                    const combinedFields = [...itemizeSection].filter(
                                                        (field) =>
                                                            field.VisibilityFlag === "Y" ||
                                                            (field.DefaultValue?.Button === "Y" && field.ElementType === "D")
                                                    );

                                                    // Get the solution data for this item
                                                    const solutionDataForItem = vendor.quotation.solution_data?.[currentItemId]?.data || [];

                                                    // Generate table data & headers
                                                    const dynamicTableData = getDynamicTableData(solutionDataForItem, combinedFields);
                                                    const rangeHeaders = getRangeHeaders(solutionDataForItem);

                                                    return (
                                                        <TableRow>
                                                            <TableCell className="HiddentCell">
                                                                <Table className="mainTable slabSubTable" stickyHeader>
                                                                    <TableHead className="SlabRang-Head">
                                                                        <TableRow>
                                                                            <TableCell colSpan={5}>
                                                                                <Box className="ItemUser-Box">
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        onClick={() => handleExpandVendor(vendor.id)}
                                                                                        aria-label="expand row"
                                                                                    >
                                                                                        {openChildren[currentItemId]?.[vendor.id] || false ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                                                                    </IconButton>
                                                                                    <Stack
                                                                                        direction="row"
                                                                                        justifyContent="start"
                                                                                        alignItems="center"
                                                                                        spacing={1}
                                                                                        overflow="hidden"
                                                                                    >
                                                                                        <Avatar
                                                                                            size="small"
                                                                                            sx={{
                                                                                                color: "white",
                                                                                                bgcolor: "var(--primary-500)",
                                                                                                width: 32,
                                                                                                height: 32,
                                                                                                fontSize: "12px",
                                                                                            }}
                                                                                        >
                                                                                            {vendor.name?.toUpperCase().slice(0, 2)}
                                                                                        </Avatar>
                                                                                        <Box lineHeight={"normal"}>
                                                                                            <Tooltip title="Farhan Mansuri">
                                                                                                <Typography variant="body2" fontWeight={500} noWrap>
                                                                                                    {vendor.name}
                                                                                                </Typography>
                                                                                            </Tooltip>
                                                                                            <Typography variant="caption" color="textSecondary">
                                                                                                {vendor.code}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Stack>
                                                                                </Box>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>

                                                                    <TableBody className="SlabRang-Body">
                                                                        <TableRow>
                                                                            <TableCell className="HiddentCell" colSpan={5}>
                                                                                {/* <Collapse in={openChildren.section1} timeout="auto" unmountOnExit> */}
                                                                                <Collapse
                                                                                    in={openChildren[currentItemId]?.[vendor.id] || false}
                                                                                    timeout="auto"
                                                                                    unmountOnExit
                                                                                >
                                                                                    <Table stickyHeader size="small">
                                                                                        <TableHead>
                                                                                            <TableRow>
                                                                                                {/* The first column is always the label, e.g. "Item Code", "HSN", etc. */}
                                                                                                <TableCell>Slab Range Name</TableCell>
                                                                                                {/* Dynamically render range headers */}
                                                                                                {rangeHeaders.map((header, idx) => (
                                                                                                    <TableCell key={idx}>{header.name}</TableCell>
                                                                                                ))}
                                                                                                <TableCell></TableCell>
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {dynamicTableData
                                                                                                .filter((row) => row.name !== "Item *") // example filter
                                                                                                .map((row, rowIndex) => (
                                                                                                    <TableRow key={rowIndex}>
                                                                                                        <TableCell scope="row">{row.name}</TableCell>
                                                                                                        {rangeHeaders.map((header, idx) => {
                                                                                                            const cellValue = row[`range${idx + 1}`];
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <TableCell key={idx}>
                                                                                                                        {cellValue || "NA"}
                                                                                                                    </TableCell>
                                                                                                                </>
                                                                                                            );
                                                                                                        })}
                                                                                                        <TableCell></TableCell>
                                                                                                    </TableRow>
                                                                                                ))}
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </Collapse>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            ) : (
                                                <Box
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    justifyContent={"center"}
                                                    flexDirection={"column"}
                                                    gap={2}
                                                    sx={{
                                                        height: "calc(100vh - 188px)"
                                                    }}
                                                >
                                                    <NoVendorIcon sx={{ width: "167px", height: "75px" }} />
                                                    <Typography variant="subtitle1"
                                                        color={"text.primary"}
                                                        fontWeight={400}
                                                        lineHeight={"normal"}
                                                        fontSize={"18px"}
                                                    >
                                                        No Vendors Available
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                            </Box>
                        </Box>
                    </DialogContent>
                </>)}
        </DialogSlabRange >
    );
}