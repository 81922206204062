import { URL_MAPPING } from "./host-mapping";

const currentHost = window.location.host;
const CurrentPorto = window.location.protocol;
const ApiUrlString =
  URL_MAPPING[currentHost]["api_host"] || URL_MAPPING["default"]["api_host"];
const taskServiceApiUrlString =
  URL_MAPPING[currentHost]["task_service_host"] ||
  URL_MAPPING["default"]["task_service_host"];
const parentHostURI =
  URL_MAPPING[currentHost]["parent_host"] ||
  URL_MAPPING["default"]["parent_host"];
const publicbucketURI =
  URL_MAPPING[currentHost]["public_bucket"] ||
  URL_MAPPING["default"]["public_bucket"];
const ApiObject = {
  Api: ApiUrlString,
  taskServiceApi: taskServiceApiUrlString,
  env: "development",
};

console.log(ApiObject);

const s3_version = Date.now();

export const masterHistoryRedirectUri = (division_id) =>
  `${parentHostURI}/cgi-bin/vendx/masters/masters_index.cgi?vendx_action=masterconfig&vendx_master_action=logmaster&buyerid=${division_id}&accountid=1`;

// export const TBIframeUri = `${CurrentPorto}//${currentHost}/cgi-bin/vendx/iframe_pp.cgi`; // this is final

export const TBIframeUri = `${parentHostURI}/cgi-bin/vendx/iframe_pp.cgi`; // this is set for dev purpose

export const sendMailUri = `${parentHostURI}/cgi-bin/vendx/index.cgi`;

export const sendMailUriRfqViewForm = `${parentHostURI}/cgi-bin/vendx/mail_sender.cgi`;

export const reSendMailUriRfqViewForm = `${parentHostURI}/cgi-bin/vendx/InquiryResendMail.cgi`;

export const sendMailUriQuotation = `${parentHostURI}/cgi-bin/vendx/mail.cgi`;

export const sendMailDataWriter = `${parentHostURI}/cgi-bin/vendx/transactionmaildataWriter.cgi`;

export const activitySubmitUri = `${parentHostURI}/cgi-bin/vendx/index.cgi`;

const parent_domain = parentHostURI.split("//")[1];
const sub_domain = parent_domain.split(".")[0];

let template_url = `${CurrentPorto}//${window.location.hostname}`;
if (sub_domain.includes("testing")) {
  template_url = `https://testing-static.vend-x.com/${sub_domain}`;
  console.log("domain ======= ", parent_domain, sub_domain, template_url);
}

export const templateURi = (
  division_id,
  displayName,
  template_id,
  batch_id,
  trans_state_id = 1
) =>
  `${template_url}/template/${displayName}/${division_id}/template_${template_id}_${batch_id}_${trans_state_id}.json?version=` +
  s3_version;

export const devTemplateURi = (
  division_id = 157,
  displayName = "VendxLite",
  template_id,
  batch_id,
  trans_state_id = 1
) =>
  // `https://testing-static.vend-x.com/testing181/template/${displayName}/${division_id}/template_${template_id}_${batch_id}_${trans_state_id}.json?version=` + s3_version;
  // `https://testing-static.vend-x.com/${parent_domain}/template/${displayName}/${division_id}/template_${template_id}_${batch_id}_${trans_state_id}.json?version=` + s3_version;
  `https://testing-static.vend-x.com/${sub_domain}/template/${displayName}/${division_id}/template_${template_id}_${batch_id}_${trans_state_id}.json?version=` + s3_version;
// `${ApiObject.Api}/v1/download-template?q=/opt/uploaded_Files/asp/${displayName}/template_json/${division_id}/template_${template_id}_${batch_id}_${trans_state_id}.json?version=` + s3_version;

export const getCategories = ApiObject.Api + "/v1/categories";
export const getCategoryItems =
  ApiObject.Api + "/v1/categories?embed=division&name[like]=";
export const postCategory = ApiObject.Api + "/v1/categories";
export const getCategoryDetails = (id) =>
  `${ApiObject.Api}/v1/categories/${id}`;

export const getInquiryDetails = (id) => `${ApiObject.Api}/v1/rfq/${id}`;
export const getGrfqDetails = (id) => `${ApiObject.Api}/v1/rfq/${id}/grfq`;

export const searchCategoriesByNameOrCode = (keyword) =>
  `${ApiObject.Api}/v1/categories?code[like]=${encodeURIComponent(
    keyword
  )}%25&or[name][like]=${encodeURIComponent(keyword)}%25`;

export const getAllUnits = ApiObject.Api + "/v1/units";
export const linkUnlinkItem = (category_id) =>
  `${ApiObject.Api}/v1/categories/${category_id}/items`;
export const getAllLinkedItems = (
  category_id,
  page = 1,
  files,
  plant_code = ""
) => {
  let file_ids = "";
  if (files && files.length > 0) {
    files.forEach((file, i) => {
      if (typeof file === "object") {
        file_ids = file_ids + "id[]=" + file.id + "&";
      } else {
        file_ids = file_ids + "id[]=" + file + "&";
      }
    });
  }
  // return `${ApiObject.Api}/v1/categories/${category_id}/items?type=linked&page=${page}&${file_ids}&plant_code=${plant_code}`;
  let url = `${ApiObject.Api}/v1/categories/${category_id}/items?type=linked&page=${page}&${file_ids}`;
  if (plant_code) url += `&plant_code=${plant_code}`;
  return url;
};
export const getAllUnlinkedItems = (category_id, page = 1) =>
  `${ApiObject.Api}/v1/categories/${category_id}/items?type=unlinked&page=${page}`;

export const searchLinkedItem = (category_id, keyword, page = 1) =>
  `${ApiObject.Api
  }/v1/categories/${category_id}/items?name[like]=%25${encodeURIComponent(
    keyword
  )}%25&type=linked&page=${page}`;

export const searchLinkedItemByNameOrCode = (category_id, keyword, page = 1) =>
  `${ApiObject.Api
  }/v1/categories/${category_id}/items?code[like]=%25${encodeURIComponent(
    keyword
  )}%25&or[name][like]=%25${encodeURIComponent(
    keyword
  )}%25&type=linked&page=${page}`;

export const searchUnlinkedItem = (category_id, keyword, page = 1) =>
  `${ApiObject.Api
  }/v1/categories/${category_id}/items?code[like]=%25${encodeURIComponent(
    keyword
  )}%25&or[name][like]=%25${encodeURIComponent(
    keyword
  )}%25&type=unlinked&page=${page}`;

export const filterItems = (
  category_id,
  item_name,
  item_name_operator,
  item_code,
  item_code_operator,
  item_unit,
  item_unit_operator,
  page
) =>
  `${ApiObject.Api}/v1/categories/${category_id}/items?${item_name &&
  `${item_name_operator === "contains"
    ? `name[like]=%25${encodeURIComponent(item_name)}%25&`
    : item_name_operator === "equals"
      ? `name=${encodeURIComponent(item_name)}&`
      : item_name_operator === "startsWith"
        ? `name[like]=${encodeURIComponent(item_name)}%25&`
        : item_name_operator === "endsWith"
          ? `name[like]=%25${encodeURIComponent(item_name)}&`
          : `name[like]=%25${encodeURIComponent(item_name)}%25&`
  }`
  }${item_code &&
  `${item_code_operator === "contains"
    ? `code[like]=%25${encodeURIComponent(item_code)}%25&`
    : item_code_operator === "equals"
      ? `code=${encodeURIComponent(item_code)}&`
      : item_code_operator === "startsWith"
        ? `code[like]=${encodeURIComponent(item_code)}%25&`
        : item_code_operator === "endsWith"
          ? `code[like]=%25${encodeURIComponent(item_code)}&`
          : `code[like]=%25${encodeURIComponent(item_code)}%25&`
  }`
  }${item_unit &&
  `${item_unit_operator === "contains"
    ? `unit[like]=%25${encodeURIComponent(item_unit)}%25&`
    : item_unit_operator === "equals"
      ? `unit=${encodeURIComponent(item_unit)}&`
      : item_unit_operator === "startsWith"
        ? `unit[like]=${encodeURIComponent(item_unit)}%25&`
        : item_unit_operator === "endsWith"
          ? `unit[like]=%25${encodeURIComponent(item_unit)}&`
          : `unit[like]=%25${encodeURIComponent(item_unit)}%25&`
  }`
  }type=linked&page=${page}`;
export const postItems = ApiObject.Api + "/v1/items";

export const getAllLinkedVendors = (category_id, page, pagination) =>
  `${ApiObject.Api}/v1/categories/${category_id}/vendors?${pagination ? `page=${page}` : `pagination=false`
  }`;

export const getMultipleItemsVendors = (
  category_id,
  locality,
  page,
  term,
  search
) =>
  `${ApiObject.Api
  }/v1/categories/${category_id}/items-vendors?locality=${locality}${term ? `&term=${term}` : ""
  }&page=${page}${search
    ? `&vendor_code[like]=${encodeURIComponent(
      search
    )}%25&or[vendor_name][like]=${encodeURIComponent(search)}%25`
    : ""
  }`;

export const getItemsByVendorSearch = (category_id, search, page) =>
  `${ApiObject.Api
  }/v1/categories/${category_id}/items-vendors?resources=items&${page ? `page=${page}` : `pagination=false`
  }&vendor_code[like]=%25${encodeURIComponent(
    search
  )}%25&or[vendor_name][like]=%25${encodeURIComponent(search)}%25`;

export const linkVendors = (category_id) =>
  `${ApiObject.Api}/v1/categories/${category_id}/vendors`;

export const getUnlinkedVendors = (category_id, page = 1) =>
  `${ApiObject.Api}/v1/categories/${category_id}/vendors?linking_stage=20&page=${page}`;

export const searchUnlinkedVendors = (category_id, keyword, page = 1) =>
  `${ApiObject.Api}/v1/categories/${category_id}/vendors?vendor_name[like]=%25${keyword}%25&linking_stage=20&page=${page}&or[vendor_code][like]=%25${keyword}%25`;

export const getApprovedVendors = (category_id, page = 1) =>
  `${ApiObject.Api}/v1/categories/${category_id}/vendors?linking_stage=10&approved=Y&page=${page}`;

export const searchApprovedVendors = (category_id, keyword, page = 1) =>
  `${ApiObject.Api
  }/v1/categories/${category_id}/vendors?vendor_name[like]=%25${encodeURIComponent(
    keyword
  )}%25&linking_stage=10&approved=Y&page=${page}&or[vendor_code][like]=%25${encodeURIComponent(
    keyword
  )}%25`;

export const getUnpprovedVendors = (category_id, page = 1) =>
  `${ApiObject.Api}/v1/categories/${category_id}/vendors?linking_stage=10&approved=N&page=${page}`;

export const searchUnapprovedVendors = (category_id, keyword, page = 1) =>
  `${ApiObject.Api
  }/v1/categories/${category_id}/vendors?vendor_name[like]=%25${encodeURIComponent(
    keyword
  )}%25&linking_stage=10&approved=N&page=${page}&or[vendor_code][like]=%25${encodeURIComponent(
    keyword
  )}%25`;

export const getCompanyVendors = (page = 1) =>
  `${ApiObject.Api}/v1/company-vendors?imported=N&page=${page}`;

export const postCompanyVendors = `${ApiObject.Api}/v1/company-vendors`;

export const searchCompanyVendors = (keyword, page = 1) =>
  `${ApiObject.Api
  }/v1/company-vendors?imported=N&vendor_name[like]=%25${encodeURIComponent(
    keyword
  )}%25&page=${page}`;

export const searchCompanyVendorsByNameOrCode = (keyword, page = 1) =>
  `${ApiObject.Api
  }/v1/company-vendors?imported=N&vendor_code[like]=${encodeURIComponent(
    keyword
  )}%25&or[vendor_name][like]=${encodeURIComponent(keyword)}%25&page=${page}`;

export const companyToAccountImport = `${ApiObject.Api}/v1/import-vendors`;

// export const getCompanyVendors = `${ApiObject.Api}/v1/company-vendors`;

export const getFilteredVendors = (
  category_id,
  vendor_name,
  vendor_name_operator,
  vendor_code,
  vendor_code_operator,
  country,
  country_operator,
  industry_type,
  industry_type_operator,
  email,
  email_operator,
  state,
  state_operator,
  contact_person,
  contact_person_operator,
  phone_no,
  phone_no_operator,
  address,
  address_operator,
  page
) =>
  `${ApiObject.Api
  }/v1/categories/${category_id}/vendors?${generateVendorFilterURL(
    vendor_name,
    vendor_name_operator,
    vendor_code,
    vendor_code_operator,
    country,
    country_operator,
    industry_type,
    industry_type_operator,
    email,
    email_operator,
    state,
    state_operator,
    contact_person,
    contact_person_operator,
    phone_no,
    phone_no_operator,
    address,
    address_operator,
    page
  )}`;

export const searchCategoryVendorsByNameOrCode = (
  category_id,
  keyword,
  page = 1
) =>
  `${ApiObject.Api
  }/v1/categories/${category_id}/vendors?vendor_code[like]=%25${encodeURIComponent(
    keyword
  )}%25&or[vendor_name][like]=%25${encodeURIComponent(
    keyword
  )}%25&type=linked&page=${page}`;

export const searchCategoryUnlinkVendorsByNameOrCode = (
  category_id,
  keyword,
  page = 1
) =>
  `${ApiObject.Api
  }/v1/categories/${category_id}/vendors?linking_stage=20&vendor_code[like]=%25${encodeURIComponent(
    keyword
  )}%25&or[vendor_name][like]=%25${encodeURIComponent(keyword)}%25`;

export const getLinkedItemizedVendors = (
  itemID,
  category_id,
  page = 1,
  plant = undefined
) => {
  let url = `${ApiObject.Api}/v1/categories/${category_id}/items/${itemID}/vendors?linking_stage=10&page=${page}`;
  if (plant) url += `&plant=${plant}`;
  return url;
};

export const getUnLinkedItemizedVendors = (
  itemID,
  category_id,
  page = 1,
  plant = undefined
) => {
  let url = `${ApiObject.Api}/v1/categories/${category_id}/items/${itemID}/vendors?linking_stage=20&page=${page}`;
  if (plant) url += `&plant=${plant}`;
  return url;
};

export const linkVendorsToItem = (category_id) =>
  `${ApiObject.Api}/v1/categories/${category_id}/items/vendors?category_link=true`;

export const fetchLinkDetails = (category_id) =>
  `${ApiObject.Api}/v1/categories/${category_id}/items-vendors-mapping`;

export const itemizeInquiryURL = (inquiryID) =>
  `${ApiObject.Api}/v1/inquiries/${inquiryID}/itemize-details`;

export const unlinkItemVendors = (item_id, category_id) =>
  `${ApiObject.Api}/v1/categories/${category_id}/items/${item_id}/vendors`;

const generateVendorFilterURL = (
  vendor_name,
  vendor_name_operator,
  vendor_code,
  vendor_code_operator,
  country,
  country_operator,
  industry_type,
  industry_type_operator,
  email,
  email_operator,
  state,
  state_operator,
  contact_person,
  contact_person_operator,
  phone_no,
  phone_no_operator,
  address,
  address_operator,
  page
) =>
  `${vendor_name &&
  `${vendor_name_operator === "contains"
    ? `vendor_name[like]=%25${encodeURIComponent(vendor_name)}%25&`
    : vendor_name_operator === "equals"
      ? `vendor_name=${encodeURIComponent(vendor_name)}&`
      : vendor_name_operator === "startsWith"
        ? `vendor_name[like]=${encodeURIComponent(vendor_name)}%25&`
        : vendor_name_operator === "endsWith"
          ? `vendor_name[like]=%25${encodeURIComponent(vendor_name)}&`
          : `vendor_name[like]=%25${encodeURIComponent(vendor_name)}%25&`
  }`
  }${vendor_code &&
  `${vendor_code_operator === "contains"
    ? `vendor_code[like]=%25${encodeURIComponent(vendor_code)}%25&`
    : vendor_code_operator === "equals"
      ? `vendor_code=${encodeURIComponent(vendor_code)}&`
      : vendor_code_operator === "startsWith"
        ? `vendor_code[like]=${encodeURIComponent(vendor_code)}%25&`
        : vendor_code_operator === "endsWith"
          ? `vendor_code[like]=%25${encodeURIComponent(vendor_code)}&`
          : `vendor_code[like]=%25${encodeURIComponent(vendor_code)}%25&`
  }`
  }${country &&
  `${country_operator === "contains"
    ? `country[like]=%25${country}%25&`
    : country_operator === "equals"
      ? `country=${country}&`
      : country_operator === "startsWith"
        ? `country[like]=${country}%25&`
        : country_operator === "endsWith"
          ? `country[like]=%25${country}&`
          : `country[like]=%25${country}%25&`
  }`
  }${industry_type &&
  `${industry_type_operator === "contains"
    ? `industry_type[like]=%25${industry_type}%25&`
    : industry_type_operator === "equals"
      ? `industry_type=${industry_type}&`
      : industry_type_operator === "startsWith"
        ? `industry_type[like]=${industry_type}%25&`
        : industry_type_operator === "endsWith"
          ? `industry_type[like]=%25${industry_type}&`
          : `industry_type[like]=%25${industry_type}%25&`
  }`
  }${email &&
  `${email_operator === "contains"
    ? `email[like]=%25${email}%25&`
    : email_operator === "equals"
      ? `email=${email}&`
      : email_operator === "startsWith"
        ? `email[like]=${email}%25&`
        : email_operator === "endsWith"
          ? `email[like]=%25${email}&`
          : `email[like]=%25${email}%25&`
  }`
  }${state &&
  `${state_operator === "contains"
    ? `state[like]=%25${state}%25&`
    : state_operator === "equals"
      ? `state=${state}&`
      : state_operator === "startsWith"
        ? `state[like]=${state}%25&`
        : state_operator === "endsWith"
          ? `state[like]=%25${state}&`
          : `state[like]=%25${state}%25&`
  }`
  }${contact_person &&
  `${contact_person_operator === "contains"
    ? `contact_person[like]=%25${contact_person}%25&`
    : contact_person_operator === "equals"
      ? `contact_person=${contact_person}&`
      : contact_person_operator === "startsWith"
        ? `contact_person[like]=${contact_person}%25&`
        : contact_person_operator === "endsWith"
          ? `contact_person[like]=%25${contact_person}&`
          : `contact_person[like]=%25${contact_person}%25&`
  }`
  }${phone_no &&
  `${phone_no_operator === "contains"
    ? `phone_no[like]=%25${phone_no}%25&`
    : phone_no_operator === "equals"
      ? `phone_no=${phone_no}&`
      : phone_no_operator === "startsWith"
        ? `phone_no[like]=${phone_no}%25&`
        : phone_no_operator === "endsWith"
          ? `phone_no[like]=%25${phone_no}&`
          : `phone_no[like]=%25${phone_no}%25&`
  }`
  }${address &&
  `${address_operator === "contains"
    ? `address[like]=%25${address}%25&`
    : address_operator === "equals"
      ? `address=${address}&`
      : address_operator === "startsWith"
        ? `address[like]=${address}%25&`
        : address_operator === "endsWith"
          ? `address[like]=%25${address}&`
          : `address[like]=%25${address}%25&`
  }`
  }type=linked&page=${page}`;

export const getFilteredItemizedVendors = (
  itemID,
  category_id,
  vendor_name,
  vendor_name_operator,
  vendor_code,
  vendor_code_operator,
  country,
  country_operator,
  industry_type,
  industry_type_operator,
  email,
  email_operator,
  state,
  state_operator,
  contact_person,
  contact_person_operator,
  phone_no,
  phone_no_operator,
  address,
  address_operator,
  page
) =>
  `${ApiObject.Api
  }/v1/categories/${category_id}/items/${itemID}/vendors?${generateVendorFilterURL(
    vendor_name,
    vendor_name_operator,
    vendor_code,
    vendor_code_operator,
    country,
    country_operator,
    industry_type,
    industry_type_operator,
    email,
    email_operator,
    state,
    state_operator,
    contact_person,
    contact_person_operator,
    phone_no,
    phone_no_operator,
    address,
    address_operator,
    page
  )}`;

export const searchItemizedVendorsByNameOrCode = (
  itemID,
  category_id,
  keyword,
  page = 1
) =>
  `${ApiObject.Api
  }/v1/categories/${category_id}/items/${itemID}/vendors?vendor_code[like]=${encodeURIComponent(
    keyword
  )}%25&or[vendor_name][like]=${encodeURIComponent(
    keyword
  )}%25&type=linked&page=${page}`;

export const _searchItemizedVendorsByNameOrCode = (
  itemID,
  category_id,
  keyword,
  page = 1
) =>
  `${ApiObject.Api
  }/v1/categories/${category_id}/items/${itemID}/vendors?vendor_code[like]=%25${encodeURIComponent(
    keyword
  )}%25&or[vendor_name][like]=%25${encodeURIComponent(
    keyword
  )}%25&type=linked&page=${page}`;

export const getVendorDetails = (id) => `${ApiObject.Api}/v1/vendors/${id}`;

export const addVendor = `${ApiObject.Api}/v1/vendors`;

export const getPaymentTermsOptions = () =>
  `${ApiObject.Api}/v1/vendor-payment-terms`;

export const getIncoTermsOptions = () =>
  `${ApiObject.Api}/v1/vendor-inco-terms`;

export const getAllItems = ApiObject.Api + "/v1/categories";

export const getTemplates = ApiObject.Api + "/v1/templates";

export const activityTemplateListUri =
  ApiObject.Api + "/v1/templates?type=service";

export const getDropdownOptions = (identifier, templateID) =>
  `${ApiObject.Api}/v1/dropdowns/${identifier}/values?template_id=${templateID}`;

export const getProjectList = ApiObject.Api + "/v1/projects";

export const getSubCategoryList = (category_id) =>
  `${ApiObject.Api}/v1/categories/${category_id}/sub-categories`;

export const getCompanyInfo = (company_id) =>
  `${ApiObject.Api}/v1/companies/${company_id}`;

export const getAccountLevelInfo = (division_id) =>
  `${ApiObject.Api}/v1/divisions/${division_id} `;

export const getAccountModulePermission = (division_id) =>
  `${ApiObject.Api}/v1/division-permissions/${division_id}`;

export const getMasterPermission = (division_id) =>
  `${ApiObject.Api}/v1/master-permissions/${division_id}`;

export const getFeaturePermission = (division_id) =>
  `${ApiObject.Api}/v1/feature-permissions`;

export const getUserInfo = `${ApiObject.Api}/v1/users/me`;

export const accountvendorInfo = `${ApiObject.Api}/v1/vendor/account/me`;

export const rfqSubmit = `${ApiObject.Api}/v1/submit-rfq`;

export const prRfqSubmit = `${ApiObject.Api}/v1/submit-pr-rfq`;
export const groupRfqs = `/v1/grouprfqs`;
export const groupRfqMail = `${parentHostURI}/cgi-bin/vendx/pr/send_group_rfq_mail`;
export const insertPrRfq = `${parentHostURI}/cgi-bin/vendx/insert_pr_rfq`;
export const postFileSpecifications = `${ApiObject.Api}/v1/file-specifications`;
export const deleteFileSpecifications = `${ApiObject.Api}/v1/delete-file-specifications`;
export const getTempUploadedFilesUri = (
  master_type,
  form_random_id,
  product_id
) =>
  `${ApiObject.Api}/v1/get-temp-uploaded-files?product_id=${product_id}&master_type=${master_type}`;

export const getFileSpecifications = (files) => {
  let file_ids = "";
  console.log("files == ", files);
  if (files.length > 0) {
    files.forEach((file, i) => {
      console.log("fills =", file);
      if (typeof file === "object") {
        file_ids = file_ids + "file_ids[]=" + file.file_id + "&";
      } else {
        file_ids = file_ids + "file_ids[]=" + file + "&";
      }
    });
  }

  console.log(file_ids);
  return `${ApiObject.Api}/v1/file-specifications?${file_ids}`;
};

export const updateFileSpecifications = (product_id, product_type) =>
  `${ApiObject.Api}/v1/${product_type}${product_id ? `/${product_id}` : ""
  }/specification-files`;

export const updateFileSpecificationswithmultiplefiles = (
  files,
  product_type
) => {
  let file_ids = "";
  // console.log("files == ",files);
  if (files.length > 0) {
    files.forEach((file, i) => {
      // console.log("fills =",file);
      if (typeof file === "object") {
        file_ids = file_ids + "id[]=" + file.id + "&";
      }
    });
  }
  return `${ApiObject.Api}/v1/${product_type}/specification-files?${file_ids}`;
};

// v1/file-specifications/WFiMYfjJXk1633498788.txt?random_form_id=1260357405262640.0000000000000&master_type=item&product_id=40140

export const getFileSpecificationsDownloadLink = (
  file_id,
  product_id,
  master_type
) =>
  `${ApiObject.Api}/v1/file-specifications/${file_id}?master_type=${master_type}&product_id=${product_id}`;

export const getJsonTemplate = (template_id, batch_id) => `${ApiObject.Api}
/v1/template-json/${template_id}?batch_id=${batch_id}&trans_state_id=1`;

// export const fetchPreviousValues = () => {console.log("fetchPreviousValues");`${ApiObject.Api}/v1/previous-rfq`};

export const fetchPreviousValues = () => {
  console.log("fetchPreviousValues");
  return `${ApiObject.Api}/v1/previous-rfq`;
};

export const getIdentifiersDynamicValues = (identifier, item_id, category_id) =>
  `${ApiObject.Api}/v1/identifiers/${identifier}?item_id=${item_id}&category_id=${category_id}`;

export const getArtworkDescription = (item_code, batch_no) =>
  `${ApiObject.Api
  }/v1/identifiers/ArtworkDescription?material_code=${item_code}${batch_no && `&batch_no=${batch_no}`
  }`;

export const getmatspecghclfile = (
  transaction_id,
  item_code,
  transaction_type,
  pr_dest_code
) => {
  let matspecghclfile_url = `${ApiObject.Api}/v1/identifiers/matspecghclfile?transaction_id=${transaction_id}&item_code=${item_code}&transaction_type=${transaction_type}`;
  if (pr_dest_code) matspecghclfile_url += "&pr_dest_code=" + pr_dest_code;
  return matspecghclfile_url;
};

export const getMaterialSpecificationFile = (item_id, destination_id) =>
  `${ApiObject.Api}/v1/identifiers/MaterialSpecificationFile?item_id=${item_id}&destination_id=${destination_id}`;

export const getPaymentTermIncoterm = (id) =>
  `${ApiObject.Api}/v1/dropdowns/${id}`;

export const getAllowMultipleSolutionOptionLabel = `${ApiObject.Api}/v1/display-options`;

export const uploadAttachmentFiles = `${ApiObject.Api}/v1/attachment-files`;

export const downloadAttachmentFiles = (timestamp_file_name) =>
  `${ApiObject.Api}/v1/attachment-files/${timestamp_file_name}`;

export const QPlusUrl = `http://192.168.1.76/cgi-bin/vendx/qplus_index.cgi`;

export const getItemDetialsByCode = (code) =>
  `${ApiObject.Api}/v1/items?code=${code}`;

export const changeItemVendorStatus = (category_id, item_id, vendor_id) =>
  `${ApiObject.Api}/v1/categories/${category_id}/items/${item_id}/vendors/${vendor_id}`;

export const importItem = (item_code) =>
  `${ApiObject.Api}/v1/items/${item_code}/divisions`;

export const importCategory = (category_code) =>
  `${ApiObject.Api}/v1/categories/${category_code}/divisions`;

export const getTermsAndConditions = (category_id) =>
  `${ApiObject.Api}/v1/categories/${category_id}/terms-and-conditions`;

export const getVendorsDetails = (inq_id) =>
  `${ApiObject.Api}/v1/inquiries/${inq_id}/vendors`;

export const getCountries = `${ApiObject.Api}/v1/countries`;

export const getDestinations = `${ApiObject.Api}/v1/destinations`;

export const getContactDetails = (vendor_id) =>
  `${ApiObject.Api}/v1/vendors/${vendor_id}/contact-details`;

export const modifyRfqDetailUri = (inquiry_id) =>
  `${ApiObject.Api}/v1/rfq/${inquiry_id}/new-vendors`;

export const getPrCombinationUrl = () => `/v1/prlineitems/combinations`;

export const getCategoryLinkedTechnicalBidTemplateDetails = (category_id) =>
  `${ApiObject.Api}/v1/categories/${category_id}/technical-bid-templates`;

export const getItemLinkedTechnicalBidTemplateDetails = (item_id) =>
  `${ApiObject.Api}/v1/items/${item_id}/technical-bid-templates`;

export const getPRLinkedTechnicalBidTemplateDetails = (prlineitemid) =>
  `${ApiObject.Api}/v1/prlineitems/${prlineitemid}/technical-bid-templates`;

export const getCategoryTBPreviousValues = (category_id, template_id) =>
  `${ApiObject.Api}/v1/categories/${category_id}/technical-bid-templates/${template_id}/previous-values`;

export const getItemTBPreviousValues = (item_id, template_id) =>
  `${ApiObject.Api}/v1/items/${item_id}/technical-bid-templates/${template_id}/previous-values`;

export const getCategoryTBDefaultValues = (category_id, template_id) =>
  `${ApiObject.Api}/v1/categories/${category_id}/technical-bid-templates/${template_id}/default-values`;

export const getItemTBDefaultValues = (item_id, template_id) =>
  `${ApiObject.Api}/v1/items/${item_id}/technical-bid-templates/${template_id}/default-values`;

export const getPrLineItems = () => `/v1/prlineitems`;

export const geteditable_fields_json =
  publicbucketURI + "/0/editable_fields.json?version=" + s3_version;

//export const public_bucket_uri = publicbucketURI;
export const pr_dropdown_mapping = (division_id, company_id) =>
  publicbucketURI +
  `/${company_id}/v2/pr/${division_id}/mapping.json?version=` +
  s3_version;

export const dropdown_detail = (division_id, company_id, dropdown_id) =>
  publicbucketURI +
  `/${company_id}/v2/dropdown/${division_id}/${dropdown_id}.json?version=` +
  s3_version;

export const pr_status_integration = (
  division_id,
  company_id,
  inquiry_id,
  pr_ids
) => {
  var loc = new URL(parentHostURI);
  return `${parentHostURI}/cgi-bin/vendx/integration.cgi?company_id=${company_id}&buyer_id=${division_id}&integration_type=70&pr_id=${pr_ids}&inquiry_id=${inquiry_id}&host=${loc.host}`;
};

export const modify_edit_qcs_values = (inquiry_id) =>
  `${ApiObject.Api}/v1/rfq/${inquiry_id}/qcs-edit-values`;

//--------------------RFQ List Laravel---------------------//

export const downloadmaterialDescription = `${ApiObject.Api}/v1/downloadMaterialDescriptionAllItems`;

export const get_RFQ_List = `${ApiObject.Api}/v1/inquiries`;

export const get_Inquiries_data = `${ApiObject.Api}/v1/inquiries/`;

export const get_Inquiries_Item_data = (inquiry_id) =>
  `${ApiObject.Api}/v1/inquiries/${inquiry_id}/items`;

export const get_Inquiries_Vendor_data = (inquiry_id) =>
  `${ApiObject.Api}/v1/inquiries/${inquiry_id}/vendors`;

export const get_Vendors_Data = `${ApiObject.Api}/v1/vendors/`;

export const get_All_Inquiries_Data = `${ApiObject.Api}/v1/inquiries`;

export const all_templates = `${ApiObject.Api}/v1/all_templates`;

export const users_Data = `${ApiObject.Api}/v1/users`;

export const get_Inquiries = `${ApiObject.Api}/v1/inquiries`;

export const account_all_users = `${ApiObject.Api}/v1/account_all_users `;

export const validity_date_update = (id) =>
  `${ApiObject.Api}/v1/inquiries/${id}/update_validity_date`;

//--------------------RFQ List Perl Pop Up---------------------//

export const extend_validity = `${parentHostURI}/cgi-bin/vendx/index.cgi`;

export const vendor_QPlus = `${parentHostURI}/cgi-bin/vendx/QuotationForm.cgi`;

export const send_RFQ_to_new_vendor = `${parentHostURI}/cgi-bin/vendx/qplus_index.cgi`;

export const r2a_reports = `${parentHostURI}/cgi-bin/vendx/qplus_index.cgi`;

export const inquiry_logs = `${parentHostURI}/cgi-bin/vendx/qplus_index.cgi`;

export const q_plus_screen = `${parentHostURI}/cgi-bin/vendx/qplus_index.cgi`;

export const vendx_connect = `${parentHostURI}/cgi-bin/vendx/communication_chain.cgi`;

export const multiple_solution = `${parentHostURI}/cgi-bin/vendx/multiple_solution.cgi`;

export const extend_validity_thread = `${parentHostURI}/cgi-bin/vendx/qplus_index.cgi`;

export const multiple_inquiry_jqgrid = `${parentHostURI}/cgi-bin/vendx/multiple_inquiry_jqgrid.cgi`;

export const get_material_specifications = `${parentHostURI}/cgi-bin/vendx/index.cgi?vendx_action=get_material_specifications&UID=`;

//--------------------Quotation Form Laravel---------------------//

export const specificationFiles = (categoryId) =>
  `${ApiObject.Api}/v1/categories/${categoryId}/specification-files`;

export const getSpecificationFiles = `${ApiObject.Api}/v1/items/specification-files`;

export const getDropDownCurrencyList = `${ApiObject.Api}/v1/dropdowns-currency-list`;

export const getBuyers = (created_by) =>
  `${ApiObject.Api}/v1/buyers/${created_by}`;

export const getFileSpecificationsDownloadLinkWithFileId = (file_id) =>
  `${ApiObject.Api}/v1/file-specifications/${file_id}`;

export const getTermsAndConditionsDetails = (category_id) =>
  `${ApiObject.Api}/v1/categories/${category_id}/terms-and-conditions`;

export const getTransactionDetails = (inq_id, vendor_id) =>
  `${ApiObject.Api}/v1/inquiries/${inq_id}/vendors/${vendor_id}/transactions?embed[]=Last`;

export const getQuotationReminders = (inq_id) =>
  `${ApiObject.Api}/v1/inquiries/${inq_id}/quotation_reminders`;

export const getGroupRfq = (group_rfq_id) =>
  `${ApiObject.Api}/v1/inquiries/grouprfq/${group_rfq_id}`;

export const getTransactionDetailsView = (inq_id, vendor_id, transactionId) =>
  `${ApiObject.Api}/v1/inquiries/${inq_id}/vendors/${vendor_id}/transactions?transaction_id=${transactionId}`;

export const uploadOfflineAttachmentFiles = `${ApiObject.Api}/v1/offline-attachment-files`;

export const quotationSubmit = `${ApiObject.Api}/v1/quotation`;

export const negotiationSubmit = `${ApiObject.Api}/v1/negotiation`;

export const feature_permission = `${ApiObject.Api}/v1/feature-permissions`;

export const industry_type = `${ApiObject.Api}/v1/vendor/industry-details`;

export const updateRegretJustificiation = (inqId, vendorId, tlGuid) =>
  `${ApiObject.Api}/v1/inquiries/${inqId}/vendors/${vendorId}/transaction/${tlGuid}/regret`;

export const getRegretJustification = (tlGuid) =>
  `${ApiObject.Api}/v1/regret/${tlGuid}`;

export const submitRegret = `${ApiObject.Api}/v1/regret`;

export const quotationValidation = (inqId, vendorId, tlGuid, tranType) =>
  `${ApiObject.Api}/v1/inquiries/${inqId}/vendors/${vendorId}/transactions/${tlGuid}/validations/${tranType}`;

export const HOSTMAP = parentHostURI;

export const vendorMenu = `${ApiObject.Api}/v1/vendor-menu`;

export const Logout = `${ApiObject.Api}/v1/logout`;

export const prviewTB = `${parentHostURI}/cgi-bin/vendx/masters/masters_index.cgi`;

export const technicalBid = `${parentHostURI}/cgi-bin/vendx/masters/master.cgi`;

export const tbQuotedVendor = `${parentHostURI}/cgi-bin/vendx/multiple_inquiry_jqgrid.cgi`;

export const fetchcompanywisedateDatas =
  publicbucketURI + `/Defaultdata/Defaultsetting.json?version=` + s3_version;

export const downloadQuotationExcel = `${parentHostURI}/cgi-bin/vendx/transaction.cgi`;

export const importRfqExcel = `${ApiObject.Api}/v1/rfq-excel`;

export const RfqExcelURi = (division_id, displayName, name) =>
  `${template_url}/template/${displayName}/${division_id}/${name}.xlsx`;

export const devRfqExcelURi = (
  division_id = 157,
  displayName = "VendxLite",
  name = "AIA_CONTRACT_GST_R2A_1.0_categorized"
) =>
  `https://testing-static.vend-x.com/${sub_domain}/template/${displayName}/${division_id}/${name}.xlsx`;

export const parentHost = parentHostURI;

export const previewActivity = `${parentHostURI}/cgi-bin/vendx/vend-x.cgi/Service/QuotationExcel/create_sample_file/`;

export const exchangeRates = (inqId, vendorId) =>
  `${ApiObject.Api}/v1/inquiries/${inqId}/vendors/${vendorId}/currency/exchange-rate`;

export const massValidation = (inqId, tranType, vendorIds) =>
  `${ApiObject.Api}/v1/inquiries/${inqId}/massvalidations/${tranType}?vendorIds=${vendorIds}`;

export const getTransactionVendors = (inqId, tranTypeGuid) =>
  `${ApiObject.Api}/v1/inquiries/${inqId}/transaction/${tranTypeGuid}/vendors`;

export const sendMailUriViewForm = `${parentHostURI}/cgi-bin/vendx/mail_sender.cgi`;

export const reSendMailUriViewForm = `${parentHostURI}/cgi-bin/vendx/InquiryResendMail.cgi`;

//--------------------R2A Report Laravel---------------------//

export const getR2aDetails = (inqId, r2aId) =>
  `${ApiObject.Api}/v1/inquiry/${inqId}/r2a/${r2aId}`;
export const downloadExcelPDFR2aReport = `${parentHostURI}/cgi-bin/vendx/qplus_index.cgi`;

//--------------------Sealed Negotiation Laravel API---------------------//
export const getTransactionVendorDetails = (inqId, transaction_type) =>
  `${ApiObject.Api}/v1/inquiries/${inqId}/transactions/${transaction_type}`;

// for vendor classification
export const categoryItemLinkedVendors = (
  category_id,
  item_ids,
  page,
  plant = undefined
) => {
  const baseUrl = `${ApiObject.Api}/v1/categories/${category_id}/items-vendors`;
  const queryString = item_ids
    .map((item) => `items[]=${encodeURIComponent(item)}`)
    .join("&");
  let url = `${baseUrl}?${queryString}&page=${page}`;
  if (plant) url += `&plant=${plant}`;
  return url;
};

export const getItemsLinkedVendorBySearch = (
  category_id,
  search,
  page,
  item_ids,
  plant
) => {
  const baseUrl = categoryItemLinkedVendors(category_id, item_ids, page, plant);
  const queryParams = [
    `vendor_code[like]=%25${encodeURIComponent(search)}%25`,
    `or[vendor_name][like]=%25${encodeURIComponent(search)}%25`,
  ];
  const queryString = queryParams.join("&");
  return `${baseUrl}&${queryString}`;
};

export const getFilteredItemLinkedVendors = (
  item_ids,
  category_id,
  vendor_name,
  vendor_name_operator,
  vendor_code,
  vendor_code_operator,
  country,
  country_operator,
  industry_type,
  industry_type_operator,
  email,
  email_operator,
  state,
  state_operator,
  contact_person,
  contact_person_operator,
  phone_no,
  phone_no_operator,
  address,
  address_operator,
  page
) => {
  const baseUrl = `${ApiObject.Api}/v1/categories/${category_id}/items-vendors`;
  const queryString = item_ids
    .map((item) => `items[]=${encodeURIComponent(item)}`)
    .join("&");
  return `${baseUrl}?${queryString}&${generateVendorFilterURL(
    vendor_name,
    vendor_name_operator,
    vendor_code,
    vendor_code_operator,
    country,
    country_operator,
    industry_type,
    industry_type_operator,
    email,
    email_operator,
    state,
    state_operator,
    contact_person,
    contact_person_operator,
    phone_no,
    phone_no_operator,
    address,
    address_operator,
    page
  )}`;
};
//auto-rfq-configuarations
export const getAllAutoRFQTask = (params) =>
  `${ApiObject.taskServiceApi}/v1/tasks/list/${params}`;

export const autoRFQTaskById = (id) =>
  `${ApiObject.taskServiceApi}/v1/tasks/${id}`;

export const postAutoRFQTask = `${ApiObject.taskServiceApi}/v1/tasks`;

//auto rfq logs
export const autoRfqLogs = (client) =>
  `${ApiObject.taskServiceApi}/v1/tasks-execution-logs/list/${client}`;

export const getPrDetails = `${ApiObject.Api}/v1/prlineitems`;

export const autorfqLogsById = (id) =>
  `${ApiObject.taskServiceApi}/v1/tasks-execution-logs/${id}`;

export const getUsersByPermissionsURL = `${ApiObject.Api}/v1/get-users-by-permissions`;

export const getItemsV2 = `${ApiObject.Api}/v2/items`;
//--------------------RFQ Miscellaneous---------------------//

export const getInquiryLogsData = (inquiryId) =>
  `${ApiObject.Api}/v1/inquiries/${inquiryId}/inquiry-logs`;
export const getExtendValidityThreadData = (inquiryId) =>
  `${ApiObject.Api}/v1/inquiries/${inquiryId}/extend-validity-thread`;
export const postSendRFQToNewVendor = (inq_id) =>
  `${ApiObject.Api}/v1/inquiries/${inq_id}/send-rfq-newvendor`;
export const getVendorDetailsTB = (inq_id) =>
  `${ApiObject.Api}/v1/inquiries/${inq_id}/vendors?embed[]=tb`;
export const getInquiryData = (inq_id) =>
  `${ApiObject.Api}/v1/inquiries/${inq_id}/transactions/inquiry`;

export const getAllLinkedVendorsWithItemIds = (
  category_id,
  page,
  pagination,
  itemIds
) => {
  const baseUrl = `${ApiObject.Api}/v1/categories/${category_id}/vendors`;
  const paginationParam = pagination ? `page=${page}` : `pagination=false`;
  const itemIdsParam =
    itemIds !== undefined && itemIds !== null
      ? `&itemIds=${encodeURIComponent(JSON.stringify(itemIds))}`
      : "";

  return `${baseUrl}?${paginationParam}${itemIdsParam}`;
};

export const sendReadReceipt = (inq_id, guid, vendor_id) =>
  `${ApiObject.Api}/v1/inquiries/${inq_id}/transaction/${guid}/read?recepientId=${vendor_id}&readFlag=1`;

export const getTechnicalBidTemplate = (cat_id) =>
  `${ApiObject.Api}/v2/categories/${cat_id}/technical-bid-templates`;

export const linkUnlinkTechnicalBidTemplate = (cat_id, link_id, linkUnlink) =>
  `${ApiObject.Api
  }/v1/categories/${cat_id}/technical-bid-templates/${link_id}/${linkUnlink === "link" ? "link" : "unlink"
  }`;

export const getTechnicalBidTemplateItemize = (item_id) =>
  `${ApiObject.Api}/v2/items/${item_id}/technical-bid-templates`;

export const linkUnlinkTechnicalBidTemplateItemize = (
  cat_id,
  link_id,
  linkUnlink
) =>
  `${ApiObject.Api}/v1/items/${cat_id}/technical-bid-templates/${link_id}/${linkUnlink === "link" ? "link" : "unlink"
  }`;
