import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";

export const LinkUnlinkItemDrawer = styled(Drawer)(({ theme }) => ({
  "&.SidebarDrawer": {
    ".MuiPaper-root": {
      boxShadow: "none",
      borderRadius: "0",
    },
    "& .MuiDrawer-paper": {
      width: "600px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .MuiCard-root": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    "& .MuiCardContent-root": {
      padding: 0,
      flex: "1 1 auto",
      overflow: "auto",
    },
    "& .TemplateSearchBox, & .TemplateName-Title": {
      position: "sticky",
      top: "0",
      zIndex: "1",
    },
    "& .MuiFilledInput-root": {
      background: "var(--grey-100)",
      ".MuiFilledInput-input": {
        padding: "10px 0",
      },
      ".MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)": {
        marginTop: 0,
      },
      "::before": {
        borderColor: "var(--bluegrey-500)",
      },
    },
    "& .TemplateName-List": {
      padding: 0,
      ".MuiListItem-root": {
        padding: "0 4px",
        ".MuiListItemIcon-root": {
          minWidth: "44px",
        },
      },
      ".TemplateName-Title": {
        height: "40px",
        top: "40px",
        background: "var(--grey-100)",
        borderBottom: "1px solid var(--bluegrey-500)",
        ".MuiListItemText-root": {
          margin: 0,
        },
        "& .MuiListItemIcon-root": {
          visibility: "hidden",
        },
      },
    },
  },
}));
