import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Backdrop,
  CircularProgress
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import ExpandLess from "@mui/icons-material/KeyboardArrowDown";
import ExpandMore from "@mui/icons-material/KeyboardArrowUp";
import { SelectTemplateIcon } from "../SvgIcon/SvgIcon";
import SelectTemplateImg from "../../assets/images/select-template.svg";
import { makeStyles } from "@mui/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import _, { debounce } from "lodash";
import dayjs from "dayjs";
import { openSnackbarfunc } from "../../redux/actions/snackbarAction";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  getTemplates,
  getCategoryDetails,
  templateURi,
  devTemplateURi,
  linkUnlinkItem,
  activityTemplateListUri,
  geteditable_fields_json
} from "../../config/apiUrl";
// import AutoRFQTemplate from "../../components/AccountTemplate/AutoRFQTemplate";
import AutoRFQTemplate from "./AutoRFQTemplate";
const useStyles = makeStyles({
  TemplateTable: {
    "& .MuiTableContainer-root": {
      maxHeight: "400px",
    },
    "& .MuiTable-root": {
      "& .MuiTableHead-root": {
        "& .MuiTableCell-root": {
          lineHeight: "normal",
          background: "var(--grey-100)",
          "&:nth-child(1), &:nth-child(2)": {
            zIndex: "4",
          },
        },
      },
      "& .MuiTableCell-root": {
        minWidth: "200px",
        maxWidth: "200px",
        background: "var(--grey-0)",
        "@media (min-width:900px)": {
          "&:nth-child(1), &:nth-child(2)": {
            position: "sticky",
            left: "0",
            zIndex: "3",
          },
          "&:nth-child(2)": {
            left: "200px",
          },
        },
      },
    },
  },
});

export default function SetTemplate(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  // Initialize state dynamically
  const [expandedCards, setExpandedCards] = React.useState({
    template: true,
    localRecipients: true,
    internationalRecipients: true,
    commonFields: true,
  });
  
  // Function to toggle specific card expand state
  const toggleCardExpand = (cardId) => {
    setExpandedCards((prev) => ({
      ...prev,
      [cardId]: !prev[cardId],
    }));
  };


  const [state, setState] = useState({
    isScrollActive: false,
  });

  const [templateData, setTemplateData] = useState([]);
  const [ selectedTemplateData, setSelectedTemplateData ] = useState([]);
  const [jsonTemplateloading, setJsonTemplateloading] = useState(false);
  const [accountTemplatKey, setAccountTemplatKey] = useState(1);
  
  const userInfo = useSelector((state) => state.userInfoReducer.userInfo);

  const fetchTemplates = async () => {
    try {
      const res = await axios.get(getTemplates);
     
      setTemplateData(res.data.data);
    } catch (err) {
      dispatch(openSnackbarfunc('error', 'Error while fetching templates'));
      console.error(err);
    }
  };
  

  useEffect(() => {

    if(templateData.length === 0) return;
    if(props.taskData?.action_document_data?.template?.id && props.editTask){
      const editSelecteTemplate = templateData.filter(item => item.id == props.taskData?.action_document_data?.template?.id);
      handleTemplateChange(editSelecteTemplate[0])
    }

  }, [templateData]); 
  

  useEffect(() => {
    fetchTemplates();
    editTableJson();
  }, []); 
  
  const handleTemplateChange = (selectedTemplate) => {
    props.setTemplate(selectedTemplate);
    setJsonTemplateloading(true);
    setAccountTemplatKey((prevKey) => prevKey + 1);
    fetchJSONTemplate(selectedTemplate);
  };

  const fetchJSONTemplate = (selectedTemplate) => {
    
    if (!selectedTemplate) return;
    
    const { id, batch_id } = selectedTemplate;
    
    // Determine API URL
    const templateURL =
      process.env.NODE_ENV === "production"
        ? templateURi(userInfo.division_id, userInfo?.company_name, id, batch_id)
        : devTemplateURi(userInfo.division_id, userInfo?.company_name, id, batch_id);
  
    // Fetch template data
    axios
      .get(templateURL)
      .then((response) => {
        props.setJsonTemplate(response.data);
        setJsonTemplateloading(false);
      })
      .catch((err) => {
        console.error("Fetch JSON error:", err);
        dispatch(
          openSnackbarfunc("error", err.response?.data?.message || "Failed to fetch template")
        );
        setJsonTemplateloading(false);
        props.setJsonTemplate(null);
      });
  };
  
  const editTableJson = async () => {
    try {
      const response = await fetch(`${geteditable_fields_json}`);
      const responseJSON = await response.json();
      
      props.setdropdown_values(responseJSON);
    } catch (error) {
      console.error("Error fetching editable fields JSON:", error);
    }
  };

  return (
    <Box>
      <Card
        variant="outlined"
        sx={{
          borderBottom:
            props.template.length !== 0 &&
            Object.keys(props.jsonTemplate).length !== 0
              ? "0"
              : "1px solid var(--bluegrey-500)",
          borderBottomRightRadius:
            props.template.length !== 0 &&
            Object.keys(props.jsonTemplate).length !== 0
              ? "0"
              : "4px",
          borderBottomLeftRadius:
            props.template.length !== 0 &&
            Object.keys(props.jsonTemplate).length !== 0
              ? "0"
              : "4px",
        }}
      >
        <CardHeader
          title={
            <Typography
              variant="subtitle2"
              component="h5"
              color={"grey.900"}
              textTransform="uppercase"
              fontWeight={500}
            >
              Template
            </Typography>
          }
          action={
            <>
              <Backdrop
                style={{ color: "#fff", zIndex: 10000000000 }}
                open={jsonTemplateloading}
              >
                <CircularProgress style={{ color: "#fff" }} />
              </Backdrop>

              <Autocomplete
                options={templateData}
                getOptionLabel={(option) => option.name || ""} // Ensure a valid string is returned
                value={props.template || null} // Handle null case properly
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                } // Handle undefined values
                onChange={(event, newValue) => handleTemplateChange(newValue)}
                renderInput={(params) => (
                  <TextField {...params} label="Select Template" />
                )}
                sx={{ width: { xs: 175, sm: 300 }, fontSize: "14px" }}
                fullWidth
                size="small"
                disableClearable
              />
            </>
          }
        />
        {props.template.length == 0 && (
          <Box
            className="select-template-msg"
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={2}
            margin={"0 auto"}
            p={5}
            pb={2}
            sx={{borderTop:"1px solid var(--bluegrey-500)"}}
          >
            <img src={SelectTemplateImg} />
            <Typography
              variant="subtitle1"
              color={"text.primary"}
              fontWeight={400}
              lineHeight={"normal"}
              fontSize={"18px"}
            >
              Select Template
            </Typography>
          </Box>
          // <CardContent >
          //   <div >
          //     <img src={SelectTemplateImg} alt="Template Icon" />
          //     <h5>Select Template</h5>
          //   </div>
          // </CardContent>
        )}
      </Card>

      {props.template.length !== 0 &&
        Object.keys(props.jsonTemplate).length !== 0 && (
          <AutoRFQTemplate
            jsonTemplateloading={jsonTemplateloading}
            setJsonTemplateloading={setJsonTemplateloading}
            jsonTemplate={props.jsonTemplate}
            template={props.template}
            dropdown_values={props.dropdown_values}
            handleTaskDataChange={props.handleTaskDataChange}
            taskData={props.taskData}
            submit={props.submit}
            sessionId={props.sessionId}
            editTask={props.editTask}
            setIsCreateRFQActive={props.setIsCreateRFQActive}
            setActiveStep={props.setActiveStep}
            rfqExpiryIntervalDays={props.rfqExpiryIntervalDays}
            setRfqExpiryIntervalDays={props.setRfqExpiryIntervalDays}
          />
        )}
    </Box>
  );
}






