import * as React from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slider,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  FormHelperText
} from "@mui/material";
import RupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { searchCategoriesByNameOrCode, getCategoryDetails, getCategories } from "../../config/apiUrl";
import axios from "axios";
import { openSnackbarfunc } from "../../redux/actions/snackbarAction";


export default function SetPrRules(props) {

  const dispatch = useDispatch();

  //For category Dropdown
  const [inputValue, setInputValue] = useState("");
  const [categoryItems, setCategoryItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const accountUsers = useSelector((state) => state.accountUsersReducer.accountUsers);
  const dropdownData = useSelector((state) => state.dropdownReducer.dropdownData);

  const transformDropdownData = (data) =>
    Object.entries(data).map(([id, item]) => ({
      id,
      name: item.Dropdown_Element_Name,
    }));

  // // rule state 
  // const [selectedPgrValues, setSelectedPgrValues] = useState([]);
  // const [selectedPorgValues, setSelectedPorgValues] = useState([]);
  // const [selectedPlantValues, setSelectedPlantValues] = useState([]);
  // const [selectedCategories, setSelectedCategories] = useState([]);

  const [pgrOptions, setPgrOptions] = useState([]);
  const [porgOptions, setPorgOptions] = useState([]);
  const [plantOptions, setPlantOptions] = useState([]);
  useEffect(() => {
    if (dropdownData && props.taskData) {
      setPgrOptions(transformDropdownData(dropdownData.pgr));
      setPorgOptions(transformDropdownData(dropdownData.porg));
      setPlantOptions(transformDropdownData(dropdownData.plant));
    }
  }, [dropdownData])

  useEffect(() => {

    if (dropdownData && props.taskData && props.editTask) {

      const defaultPgrValues = props.taskData.rules
        .find(rule => rule.attribute_id === "purchase_groups")?.value || [];
      const defaultPorgValues = props.taskData.rules
        .find(rule => rule.attribute_id === "purchase_organizations")?.value || [];
      const defaultPlantValues = props.taskData.rules
        .find(rule => rule.attribute_id === "destination")?.value || [];

      props.setSelectedPgrValues(pgrOptions.filter(option => defaultPgrValues.includes(option.id)));
      props.setSelectedPorgValues(porgOptions.filter(option => defaultPorgValues.includes(option.id)));
      props.setSelectedPlantValues(plantOptions.filter(option => defaultPlantValues.includes(option.id)));

      const minValue = props.taskData.rules.find(rule => rule.attribute_id === "budget_value" && rule.condition === "greater_than_equal_to")?.value;
      const maxValue = props.taskData.rules.find(rule => rule.attribute_id === "budget_value" && rule.condition === "less_than_equal_to")?.value;

      props.setPrValues({ min: minValue, max: maxValue });

      const defaultcategoryIds = props.taskData.rules.find(rule => rule.attribute_id === "categories")?.value || [];
      if (defaultcategoryIds.length > 0) {
        getCategoryByIds(defaultcategoryIds);
      }

    }
  }, [dropdownData, pgrOptions, porgOptions, plantOptions]);

  // const handleSliderChange = (event, newValue) => {
  //   const [newMin, newMax] = newValue;
  //   props.setPrValues({ min: newMin, max: newMax });
  //   updatesliderDataRules(newMin, newMax);
  // };

  // const handleMinChange = (event) => {
  //   const newMin = Number(event.target.value);
  //   if (newMin <= props.PrValues.max) {
  //     // props.setPrValues((prev) => ({ ...prev, min: newMin }));
  //     updatesliderDataRules(newMin, props.PrValues.max);
  //   }
  // };

  // const handleMaxChange = (event) => {
  //   const newMax = Number(event.target.value);
  //   if (newMax >= props.PrValues.min) {
  //     // props.setPrValues((prev) => ({ ...prev, max: newMax }));
  //     updatesliderDataRules(props.PrValues.min, newMax);
  //   }
  // };


  const handleMinChange = (event) => {
    const value = event.target.value;
    const newMin = value === "" ? "" : Number(value);

    props.setPrValues((prev) => ({ ...prev, min: newMin }));
    updatesliderDataRules(newMin, props.PrValues.max);
  };

  const handleMaxChange = (event) => {
    const value = event.target.value;
    const newMax = value === "" ? "" : Number(value);

    props.setPrValues((prev) => ({ ...prev, max: newMax }));
    updatesliderDataRules(props.PrValues.min, newMax);
  };

  const updatesliderDataRules = (min, max) => {
    props.handleTaskDataChange(
      "rules",
      (() => {
        let updatedRules = [...props.taskData.rules];
        let hasMinRule = false;
        let hasMaxRule = false;

        updatedRules = updatedRules.map(rule => {
          if (rule.attribute_id === "budget_value") {
            if (rule.condition === "greater_than_equal_to") {
              hasMinRule = true;
              return { ...rule, value: min };
            }
            if (rule.condition === "less_than_equal_to") {
              hasMaxRule = true;
              return { ...rule, value: max };
            }
          }
          return rule;
        });

        // If rules for min/max don't exist, add them
        if (!hasMinRule) {
          updatedRules.push({
            attribute_id: "budget_value",
            condition: "greater_than_equal_to",
            value: min,
          });
        }

        if (!hasMaxRule) {
          updatedRules.push({
            attribute_id: "budget_value",
            condition: "less_than_equal_to",
            value: max,
          });
        }

        return updatedRules;
      })()
    );
  };

  // Fetch categories dynamically
  const fetchCategories = (value, page = 1) => {
    if (!value) return;

    setLoading(page === 1); // Show loading only for the first fetch
    setPaginationLoading(page > 1);

    axios.defaults.headers.common["Authorization"] = `Bearer ${props.sessionId}`;
    const url = searchCategoriesByNameOrCode(value);

    axios
      .get(`${url}&page=${page}`)
      .then((response) => {
        // if (page === 1) {
        setCategoryItems((prevItems) => {
          const updatedItems = [
            ...prevItems,
            ...response.data.data.filter(
              (newItem) => !prevItems.some((item) => item.id === newItem.id)
            ),
          ];
          return updatedItems;
        });
        // } 
        // else {
        //     setCategoryItems((prev) => [...prev, ...response.data.data]);
        // }
        setLastPage(response.data.meta.last_page);
        setCurrentPage(response.data.meta.current_page);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
        setCategoryItems([]);
      })
      .finally(() => {
        setLoading(false);
        setPaginationLoading(false);
      });
  };

  // Handle input changes and fetch data
  const handleInputChange = (event, value) => {
    setInputValue(value);
    setCurrentPage(1); // Reset page
    fetchCategories(value, 1);
  };

  // Handle scrolling to fetch more categories
  const handleScroll = (event) => {
    const listboxNode = event.target;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight &&
      currentPage < lastPage &&
      !paginationLoading
    ) {
      fetchCategories(inputValue, currentPage + 1);
    }
  };


  const getCategoryByIds = async (categoryIds) => {

    const url = `${getCategories}?id[]=${categoryIds.join('&id[]=')}`;

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${props.sessionId}`;

    try {
      const response = await axios.get(url);

      const newCategories = response.data.data;

      setCategoryItems((prevItems) => {
        const updatedItems = [
          ...prevItems,
          ...newCategories.filter(
            (newItem) => !prevItems.some((item) => item.id === newItem.id)
          ),
        ];
        return updatedItems;
      });

      props.setSelectedCategories((prevSelected) => {
        const updatedSelected = [
          ...prevSelected,
          ...newCategories.filter(
            (newItem) => !prevSelected.some((item) => item.id === newItem.id)
          ),
        ];
        return updatedSelected;
      });

    } catch (err) {
      console.error(err);
      dispatch(
        openSnackbarfunc(
          "error",
          "An error occurred while Categories"
        )
      );

      // setIsLoading(false);
      return false;
    }

  }


  const updateRuleValue = (attributeId, condition, selectedValues) => {
    const existingRules = props.taskData.rules || [];
    const ruleExists = existingRules.find(rule => rule.attribute_id === attributeId);

    if (ruleExists) {
      // Update existing rule value
      props.handleTaskDataChange("rules",
        existingRules.map(rule =>
          rule.attribute_id === attributeId
            ? { ...rule, value: selectedValues }
            : rule
        )
      );
    } else {
      // Add new rule if it doesn't exist
      props.handleTaskDataChange("rules", [
        ...existingRules,
        {
          attribute_id: attributeId,
          condition: condition,
          value: selectedValues
        }
      ]);
    }
  };


  // End PR Value Slider function
  return (
    <Box
      sx={{
        maxWidth: "755px",
        margin: "0 auto",
      }}
    >
      <Typography variant="body2" color="grey.900" mb={1.5}>
        User Selection &nbsp;
        <Typography variant="caption" component="span" color="secondary.500">
          (Selecting a user allows you to configure specific rules)
        </Typography>
      </Typography>
      {/* <Box component="form" sx={{ "& > :not(style)": { mb: 3 } }}>
        <FormControl fullWidth size="small" error={!!props?.errors?.execute_as}>
          <InputLabel id="userNameLabel">Select Users</InputLabel>
          <Select
            id="userName"
            value={props.taskData.execute_as}
            label="Select Users"
            onChange={(event) =>{
              props.setErrors({ ...props?.errors, execute_as: "" });
              props.handleTaskDataChange("execute_as", event.target.value);
            }}
          >

          {Object.keys(accountUsers).map((userId) => (
              <MenuItem key={userId} value={userId}>
                {accountUsers[userId]}
              </MenuItem>
          ))}
           </Select>
           {props?.errors?.execute_as && <FormHelperText>{props?.errors?.execute_as}</FormHelperText>}
        </FormControl>
      </Box> */}
      <Box component="form" sx={{ "& > :not(style)": { mb: 3 } }}>
        <Autocomplete
          id="userName"
          options={
            props.autoRfqPermissionUsers.map((userId) => ({
              label: accountUsers[userId],
              value: userId,
            }))
          }
          getOptionLabel={(option) => option.label}
          value={
            props.autoRfqPermissionUsers
              .map((userId) => ({ label: accountUsers[userId], value: userId }))
              .find((option) => option.value == props.taskData.execute_as) || null
          }
          onChange={(event, newValue) => {
            props.setErrors({ ...props.errors, execute_as: "" });
            props.handleTaskDataChange("execute_as", newValue ? newValue.value : "");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Users"
              size="small"
              fullWidth
              error={!!props?.errors?.execute_as}
              helperText={props?.errors?.execute_as}
            />
          )}
        />
      </Box>
      <Typography variant="body2" color="grey.900" mb={1.5}>
        Set PR Rules
      </Typography>
      <Box component="form" sx={{ "& > :not(style)": { mb: 3 } }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Set Rule Name"
          value={props?.taskData?.name}
          onChange={(event) => {
            props.handleTaskDataChange("name", event.target.value)
            props.setErrors({ ...props?.errors, name: "" });

          }}
          size="small"
          error={!!props?.errors?.name}
          helperText={props?.errors?.name}
        />
        <Autocomplete
          multiple
          limitTags={2}
          options={pgrOptions}
          getOptionLabel={(option) => option.name}
          value={props.selectedPgrValues}
          defaultValue={props.selectedPgrValues}
          isOptionEqualToValue={(option, value) => option.id === value.id} // Ensures correct selection
          onChange={(event, newValue) => {
            const selectedValues = newValue.map(option => option.id);
            updateRuleValue('purchase_groups', 'in', selectedValues);
            props.setSelectedPgrValues(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select"
              label="Purchase Group"
            />
          )}
          fullWidth
          size="small"
        />
        <Autocomplete
          multiple
          limitTags={2}
          options={porgOptions}
          getOptionLabel={(option) => option.name} // Display name based on id
          defaultValue={props.selectedPorgValues} // Pre-select values
          value={props.selectedPorgValues} // Controlled state
          isOptionEqualToValue={(option, value) => option.id === value.id} // Ensures correct selection
          onChange={(event, newValue) => {

            const selectedValues = newValue.map(option => option.id);
            updateRuleValue('purchase_organizations', 'in', selectedValues);
            props.setSelectedPorgValues(newValue)
          }} //
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select"
              label="Purchase Organization"
            />
          )}
          fullWidth
          size="small"
        />

        <Autocomplete
          multiple
          limitTags={2}
          options={categoryItems}
          getOptionLabel={(option) => option.name}
          defaultValue={props.selectedCategories}
          value={props.selectedCategories}
          onInputChange={handleInputChange}
          ListboxProps={{ onScroll: handleScroll }} // Infinite scrolling
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, newValue) => {
            const selectedValues = newValue.map(option => option.id);
            updateRuleValue('categories', 'in', selectedValues);
            props.setSelectedCategories(newValue)
          }} //
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Category"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          fullWidth
          size="small"
        />
        {/* <Autocomplete
          multiple
          limitTags={2}
          options={GetegoryList}
          getOptionLabel={(option) => option.title}
          defaultValue={[GetegoryList[1], GetegoryList[2]]}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select"
              label="Material Group"
            />
          )}
          fullWidth
          size="small"
        /> */}
        <Autocomplete
          multiple
          limitTags={2}
          options={plantOptions}
          getOptionLabel={(option) => option.name} // Display name based on id
          defaultValue={props.selectedPlantValues} // Pre-select values
          value={props.selectedPlantValues} // Controlled state
          isOptionEqualToValue={(option, value) => option.id === value.id} // Ensures correct selection
          onChange={(event, newValue) => {
            const selectedValues = newValue.map(option => option.id);
            updateRuleValue('destination', 'in', selectedValues);
            props.setSelectedPlantValues(newValue)
          }} //
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select"
              label="Destination Name"
            />
          )}
          fullWidth
          size="small"
        />
        <Box>
          <Typography variant="body">PR Value</Typography>
          {/* <Slider
            getAriaLabel={() => "Temperature range"}
            value={[props.PrValues.min, props.PrValues.max]}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
          /> */}
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            spacing={1}
            sx={{
              "& .MuiInputBase-root": {
                pl: 1,
                pr: 0,
              },
              "& .MuiInputAdornment-positionCenter": {
                background: "var(--grey-100)",
                width: "70px",
                height: "38px",
                maxHeight: "38px",
                borderLeft: "1px solid var(--bluegrey-500)",
                justifyContent: "center",
              },
            }}
          >
            <FormControl fullWidth>
              <OutlinedInput
                size="small"
                type="number"
                value={props.PrValues.min}
                onChange={handleMinChange}
                startAdornment={
                  <InputAdornment position="start">
                    <RupeeIcon sx={{ fontSize: 16 }} />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="center">Min</InputAdornment>
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <OutlinedInput
                size="small"
                type="number"
                value={props.PrValues.max}
                onChange={handleMaxChange}
                startAdornment={
                  <InputAdornment position="start">
                    <RupeeIcon sx={{ fontSize: 16 }} />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="center">Max</InputAdornment>
                }
              />
            </FormControl>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
