import React, { useEffect, useState } from "react";
import Home from "./layout/Home";
import { styled } from "@mui/material/styles";
import MainHomePage from "./pages/MainHomePage";
import "./assets/css/main.css";
import { CssBaseline } from "@mui/material";
// import { LicenseInfo } from "@mui/x-data-grid-pro";
// import { LicenseInfo } from '@mui/x-license';
import { LicenseInfo } from "@mui/x-license-pro";
import { ThemeProvider } from "@mui/system";
import SendToNew from "./pages/SendToNew";
import RfqDataGrid from "./pages/RFQList/rfqGridView/rfqDataGrid";
import LinkVendorItem from "./pages/RFQList/LinkVendorToItem/linkVendorItem";
import VendorViewGrid from "./pages/RFQList/VendorGridView/vendorViewGrid";
import ShowCombination from "./pages/ShowCombination";
import QuotationDetails from "./pages/QuotationForm/quotationForm";
import NegotiationForm from "./pages/NegotiationForm/negotiationForm";
import withClearCache from "./ClearCache";
import { Routes, Route, useLocation, useParams } from "react-router-dom";
import theme from "./theme/theme";
import { AppMainStyle } from "./theme/AppMainStyle";

import Header from "./layout/header/Header";
import LeftSidebar from "./layout/sidebar/LeftSidebar";
import Footer from "./layout/footer/Footer";
import NegotiationFormLI from "./pages/NegotiationForm/NegotiaionFormLI";
import R2aReportComponent from "./components/R2aReport/R2aReportComponent";
import RfqForm from "./pages/RfqFormView/rfqFormView";
import RfqFormView from "./pages/RfqFormView/rfqFormView";
import AutoRFQ from "./pages/AutoRFQ/AutoRFQ";
import AutoRFQLogsDialog from "./components/AutoRFQLogsDialog/AutoRFQLogs";
const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  const location = useLocation();
  const params = useParams();
  const match = { params };
  const queryParams = new URLSearchParams(location.search);

  console.log("paramsURL", queryParams.get("inquiry_id"));
  // useEffect(() => {

  LicenseInfo.setLicenseKey(
    "c42f1be096f64f1d6adf1a67cfb3c7aeTz0xMDY3NjksRT0xNzcwNTk1MTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI="
  );
  // }, []);

  // This code for Sidebar Open close

  var body = document.body;
  const [open, setOpen] = useState(false);
  const onchange = () => {
    setOpen((e) => !e);
  };
  React.useEffect(() => {
    if (open) {
      body.classList.add("open");
    } else {
      body.classList.remove("open");
    }
  }, [open]);

  // End This code for Sidebar Open close
  const MainLayoutDiv = styled("div")(({ theme }) => ({
    display: "flex",
    flex: "1 1 auto",
    maxWidth: "100%",
    //paddingTop: 'var(--headerHeight)', // Use with Header
    paddingTop: 0, // Use without Header
    paddingLeft: 0, // Use without Sidebar
    //paddingLeft: 64, // Use with Sidebar
    flexGrow: 1,
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        {AppMainStyle}
        {/* <Header openchange={onchange}/> */}
        {/* <LeftSidebar open={open}/> */}

        <Home>
          <MainLayoutDiv className="main-layout">
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <MainHomePage location={location} match={match} {...props} />
                }
              />
              <Route path="rfq-list-data-grid" element={<RfqDataGrid />} />
              <Route
                path="vendor-view-data-grid"
                element={<VendorViewGrid />}
              />
              <Route
                path="r2a-report-data"
                element={
                  <R2aReportComponent
                    selctedRFQInquiryId={queryParams.get("inquiry_id")}
                    auctionR2AId={queryParams.get("selected_auction_rfq_id")}
                    sessionID={queryParams.get("q")}
                    // openR2ADialog={queryParams.get("open_dialog")}
                    handleCloseOpenR2A={queryParams.get("close_dialog")}
                  />
                }
              />

              <Route
                exact
                path="/send-to-new-vendor"
                element={
                  <SendToNew location={location} match={match} {...props} />
                }
              />
              <Route
                exact
                path="/prcombination"
                element={
                  <ShowCombination
                    location={location}
                    match={match}
                    {...props}
                  />
                }
              />
              <Route
                path="/quotation-form"
                element={<QuotationDetails />}
                location={location}
                match={match}
                {...props}
              />
              <Route
                path="/negotiation-form"
                element={<NegotiationForm />}
                location={location}
                match={match}
                {...props}
              />
              <Route
                path="/negotiation-LI-form"
                element={<NegotiationFormLI />}
                location={location}
                match={match}
                {...props}
              />
              <Route
                path="/rfq-form"
                element={<RfqFormView />}
                location={location}
                match={match}
                {...props}
              />
              <Route path="link-vendor-item" element={<LinkVendorItem />} />
              <Route
                path="/auto-rfq"
                element={<AutoRFQ location={location} {...props}/>}
              />
              <Route
                path="/auto-rfq-logs"
                element={<AutoRFQLogsDialog location={location} {...props}/>}
              />
            </Routes>
          </MainLayoutDiv>
        </Home>
        {!(
          location?.pathname === "/quotation-form" ||
          location?.pathname === "/negotiation-LI-form" ||
          location?.pathname === "/negotiation-form" ||
          location?.pathname === "/rfq-form"
        ) && <Footer />}
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
